import { useQuery } from '@tanstack/react-query';
import {
	BatteryResultStatus,
	GetBatteryResultsByParticipantFilterField,
	GetBatteryResultsByParticipantFilterOperator,
	GetBatteryResultsByParticipantSortField,
	GetBatteryResultsByParticipantSortOrder,
	PaginatedBatteryResults,
} from 'api/battery';
import { getBatteryService } from 'api/getBatteryService';
import { isNonEmptyString } from '../../utils/stringUtils';
import { QueryKey } from '../query';

export async function fetchBatteryResultsByParticipant(
	participantId: string | null
): Promise<PaginatedBatteryResults> {
	if (!participantId) {
		throw new Error("useParticipantQuery: participantId can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getBatteryResultsByParticipant({
		participantId,
		sortField: [GetBatteryResultsByParticipantSortField.EndTime],
		sortOrder: [GetBatteryResultsByParticipantSortOrder.Desc],
		filterField: [GetBatteryResultsByParticipantFilterField.Status],
		filterOperator: [GetBatteryResultsByParticipantFilterOperator.In],
		filterValue: [
			[
				BatteryResultStatus.AnalysisComplete,
				BatteryResultStatus.DataComplete,
				BatteryResultStatus.ParticipantComplete,
			].join(','),
		],
	});
	return data;
}

export function useGetBatteryResultsByParticipant(
	participantId: string | null
) {
	const isValidParticipantId = isNonEmptyString(participantId);
	return useQuery({
		enabled: isValidParticipantId,
		meta: {
			errorMessage: `Error fetching battery results ${participantId}`,
		},
		queryKey: [QueryKey.BatteryResultsByParticipant, participantId],
		queryFn: () => fetchBatteryResultsByParticipant(participantId),
		staleTime: 0, // We don't really have a cue as to when to refetch this data
	});
}
