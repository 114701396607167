import styled, { css } from 'styled-components';

import { StyledCard } from '../../../../shared/StyledCard';
import { H4, P1 } from '../../../../shared/designSystem';

export const TableHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 24px;
`;

export const IconWrapper = styled.div`
	margin-left: 16px;
	display: flex;
	flex-direction: row;
`;
export const TableHeader = styled(H4)`
	font-weight: 500;
`;

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
	`
);

export const Column = styled.div`
	display: inline-block;
`;

export const Selected = styled.div(
	({ theme: { colors, spacing } }) => css`
		min-width: 50px;
		min-height: 15px;
		margin-right: ${spacing.xxs};
		background-color: ${colors.gray_80};
		border: 1px solid ${colors.gray_60};
		border-radius: ${spacing.xs};
	`
);

export const LineDivider = styled.div(
	({ theme: { color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin-top: 24px;
	`
);

export const Selection = styled(P1)<{
	$selected?: boolean;
	$unselectedCorrect?: boolean;
}>(
	({ $selected, $unselectedCorrect, theme }) => css`
		font-weight: ${($selected || $unselectedCorrect) && '600'};
		color: ${!$selected && theme.color.graphTextSecondary};
	`
);

export const Divider = styled.div`
	margin-top: 12px;
`;

export const FooterText = styled(P1)(
	({ theme }) => css`
		margin-top: 24px;
		display: flex;
		justify-content: center;
		color: ${theme.color.graphTextSecondary};
	`
);
