// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const LeftPointerArrow = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 16 12'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='LeftPointerArrow-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'LeftPointerArrow'}</title>
			<path
				d='M6.87211 1.0203C7.06737 0.825039 7.06737 0.508457 6.87211 0.313195C6.67685 0.117932 6.36027 0.117932 6.16501 0.313195L0.996322 5.48188C0.896192 5.57331 0.833374 5.70492 0.833374 5.8512C0.833374 5.85132 0.833374 5.85108 0.833374 5.8512C0.83328 5.97929 0.882096 6.10776 0.979821 6.20549L6.16501 11.3907C6.36027 11.5859 6.67685 11.5859 6.87211 11.3907C7.06737 11.1954 7.06737 10.8788 6.87211 10.6836L2.53975 6.3512H14.6667C14.9428 6.3512 15.1667 6.12734 15.1667 5.8512C15.1667 5.57506 14.9428 5.3512 14.6667 5.3512H2.54121L6.87211 1.0203Z'
				fill='current'
			/>
		</svg>
	);
};
