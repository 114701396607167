import {
	OrganizationService,
	OrganizationServiceConfiguration,
} from 'api/organization';

import { config } from '../config';
import { useAuthStore } from 'store';

export async function getOrganizationService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new OrganizationService(
			new OrganizationServiceConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
