export enum ConfidenceScoreRange {
	NO_LOSS_OF_CONFIDENCE = 'NO_LOSS_OF_CONFIDENCE',
	SLIGHT_LOSS_OF_CONFIDENCE = 'SLIGHT_LOSS_OF_CONFIDENCE',
	SOME_LOSS_OF_CONFIDENCE = 'SOME_LOSS_OF_CONFIDENCE',
	FAIR_LOSS_OF_CONFIDENCE = 'FAIR_LOSS_OF_CONFIDENCE',
	COMPLETE_LOSS_OF_CONFIDENCE = 'COMPLETE_LOSS_OF_CONFIDENCE',
}

export const ConfidenceScoreRangeKeys = Object.keys(ConfidenceScoreRange);

export const ConfidenceScoreRangeBgColorMap: Record<
	ConfidenceScoreRange,
	{
		container: string;
		textContainer: string;
	}
> = {
	[ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE]: {
		container: '#A4D48333',
		textContainer: '#A4D483',
	},
	[ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE]: {
		container: '#F7F8E7',
		textContainer: '#D9DC89',
	},
	[ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE]: {
		container: '#FFD75E33',
		textContainer: '#FFD75E',
	},
	[ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE]: {
		container: '#FFB47533',
		textContainer: '#FFB475',
	},
	[ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE]: {
		container: '#FB806633',
		textContainer: '#FB8066',
	},
};

export enum ConfidenceScoreRangeTransKeyMap {
	NO_LOSS_OF_CONFIDENCE = 'noLossOfConfidence',
	SLIGHT_LOSS_OF_CONFIDENCE = 'slightLossOfConfidence',
	SOME_LOSS_OF_CONFIDENCE = 'someLossOfConfidence',
	FAIR_LOSS_OF_CONFIDENCE = 'fairLossOfConfidence',
	COMPLETE_LOSS_OF_CONFIDENCE = 'completeLossOfConfidence',
}

export const confidenceScoreRangeValues = {
	[ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE]: {
		start: 21,
		end: 25,
	},
	[ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE]: {
		start: 16,
		end: 20,
	},
	[ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE]: {
		start: 11,
		end: 15,
	},
	[ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE]: {
		start: 6,
		end: 10,
	},
	[ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE]: {
		start: 1,
		end: 5,
	},
};
