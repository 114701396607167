import { ReportCard } from './components/ReportCard';
import { RatingsTable } from './components/RankedAndRatedAnswers';
import { Space } from '@mantine/core';
import { getConfidenceChangeData } from '../../LongitudinalEpsom.helpers';

type Props = {
	data: ReturnType<typeof getConfidenceChangeData>;
};

const ChangeInConfidenceTab = ({ data }: Props) => {
	if (!data) return null;
	return (
		<div>
			<Space h='md' />
			<ReportCard {...data} />
			<RatingsTable answers={data.rankedChanges} />
		</div>
	);
};

export { ChangeInConfidenceTab };
