import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import { Label, fetchLabelData } from '../../utils/label';
import { Icon } from '../shared/designSystem';

const RegulatoryLabel = (): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [label, setLabel] = useState<Label>();

	useEffect(() => {
		async function setLabelData() {
			const data = await fetchLabelData();
			setLabel(data);
		}
		setLabelData();
	}, []);

	const regulatoryTitle = t`research.regulatory.title`;
	const coreCognitiveEvaluation = t`web.shared.batteries.coreCognitiveEvaluationName`;

	return (
		<StyledContainer>
			<StyledHeader>{regulatoryTitle}</StyledHeader>
			<StyledRow>
				<img
					src='/img/regulatory/ref.png'
					alt={t`web.regulatory.imageAltText.referenceNumber`}
				/>
				<StyledLabel>{label && label.ref}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/lot.png'
					alt={t`web.regulatory.imageAltText.lotNumber`}
				/>
				<StyledLabel>{label && label.lotNumber}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/ManufactureDate.png'
					alt={t`web.regulatory.imageAltText.dateOfManufacture`}
				/>
				<StyledLabel>{label && label.manufactureDate}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/Manufacturer.png'
					alt={t`web.regulatory.imageAltText.manufacturer`}
				/>
				<StyledAddress>
					<StyledAddressLine>
						<Trans i18nKey={'research.regulatory.address'} />
					</StyledAddressLine>
				</StyledAddress>
			</StyledRow>
			<PaddedLogoContainer>
				<img
					src='/img/regulatory/EURepresentative.svg'
					alt={t`web.regulatory.imageAltText.euRep`}
				/>
			</PaddedLogoContainer>
			<StyledRow>
				<StyledAddress marginLeft={'0'}>
					<StyledAddressLine>
						<Trans
							i18nKey={'research.regulatory.euAuthRepAddress'}
						/>
					</StyledAddressLine>
				</StyledAddress>
			</StyledRow>
			<StyledBoldTitle>
				<Trans i18nKey={`research.regulatory.responsibleTitle`} />
			</StyledBoldTitle>
			<StyledConsultant>
				<StyledAddressLine>
					<Trans
						i18nKey={`research.regulatory.responsibleDescription`}
					/>
				</StyledAddressLine>
			</StyledConsultant>
			<StyledRow>
				<img
					src='/img/regulatory/UDI.png'
					alt={t`web.regulatory.imageAltText.udi`}
				/>
				{label?.barcode && (
					<BarcodeImage
						src={`data:image/svg+xml,${encodeURIComponent(
							label.barcode
						)}`}
						alt={t`web.regulatory.imageAltText.barcode`}
					/>
				)}
			</StyledRow>
			<div>
				<StyledSectionTitle>
					{<>{t`research.regulatory.intendedUse`}</>}
				</StyledSectionTitle>
				<Trans i18nKey={'research.regulatory.intendedUseDescription'} />
				<SegmentsIntendedUseContainer>
					<Trans
						i18nKey={'research.regulatory.researchOnlySegments'}
					/>
				</SegmentsIntendedUseContainer>
			</div>
			<div>
				<StyledSectionTitle>
					{<>{t`research.regulatory.warningsPrecautions`}</>}
				</StyledSectionTitle>
				<Trans
					i18nKey={'web.regulatory.warningsAndPrecautions.content'}
					tOptions={{ context: 'RESEARCH' }}
					values={{
						coreCognitiveEvaluation: coreCognitiveEvaluation,
					}}
				/>
			</div>
			<StyledRow>
				<BottomRowImg
					src='/img/regulatory/MD.png'
					alt={t`web.regulatory.imageAltText.medicalDevice`}
				/>
				<StyledIconContainer>
					<BottomRowImg
						src='/img/regulatory/CE.svg'
						alt={t`web.regulatory.imageAltText.ce`}
					/>
				</StyledIconContainer>
				<StyledIconContainer>
					<Icon
						icon={icons.UKCA}
						color={theme.color.black}
						title={t`web.shared.iconAltText.ukca`}
					/>
				</StyledIconContainer>
				<BottomRowImg
					src='/img/regulatory/rxOnly.png'
					alt={t`web.regulatory.imageAltText.rxOnly`}
				/>
				<BottomRowImg
					src='/img/regulatory/ConsultIFU.png'
					alt={t`web.regulatory.imageAltText.consultInstructions`}
				/>
				<StyledLink to='/about/instructions-for-use'>
					{<>{t`web.regulatory.instructionsForUseLink`}</>}
				</StyledLink>
			</StyledRow>
		</StyledContainer>
	);
};

export { RegulatoryLabel };

const StyledIconContainer = styled.div`
	margin: 0 12px;
`;

const StyledContainer = styled.div(
	({ theme: { color, spacing } }) => `
	background-color: ${color.white};
	border-radius: 20px;
	padding: ${spacing.xl};
	margin: 0 auto ${spacing.xl};
`
);

const StyledLabel = styled.span`
	margin-left: 12px;
`;
const StyledAddress = styled.span(
	({ marginLeft }: { marginLeft?: string }) => `
	width: 190px;
	margin-left: ${marginLeft || '12px'};
	display: flex;
	flex-direction: column;
`
);

const StyledAddressLine = styled.span``;

interface StyledConsultantProps {
	theme: DefaultTheme;
	marginBottom?: string;
}

const StyledConsultant = styled.span(
	({ theme: { spacing }, marginBottom }: StyledConsultantProps) => `
  margin-top: ${spacing.sm};
  margin-bottom: ${marginBottom || spacing.lg};
  display: flex;
  flex-direction: column; 
  white-space: pre-line;
`
);

const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledHeader = styled.div(
	({ theme: { fontSize, spacing } }) => `
	font-size: ${fontSize._32};
	margin-bottom: ${spacing.md};
`
);

const StyledBoldTitle = styled.div(
	({ theme: { weight, spacing } }) => `
	display: flex;
	font-weight: ${weight.bold};
	margin-top: ${spacing.xl};
  img {
    margin-right: ${spacing.sm}
  }
`
);

const StyledSectionTitle = styled.div(
	({ theme: { color, weight, spacing } }) => `
  background-color: ${color.regulatoryTitleBar};
  padding: ${spacing.sm} ${spacing.md};
  font-weight: ${weight.bold};
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.sm};
`
);

const BottomRowImg = styled.img(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 12px;
`
);

const BarcodeImage = styled.img(
	({ theme: { spacing } }) => `
  margin: 0 ${spacing.lg};
  box-sizing: border-box;
`
);

const StyledLink = styled(Link)`
	text-decoration: underline;
`;

const SegmentsIntendedUseContainer = styled.div(
	({ theme: { color, fontSize, weight, borderRadius } }) => `
	background: ${color.alertInfoSegmentBg};
	border-radius: ${borderRadius.md};
	padding: 12px;
	color: ${color.alertInfoSegmentText};
	font-size: ${fontSize._16};
	font-weight: ${weight.semi};
`
);

const PaddedLogoContainer = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.xl} 0 ${spacing.sm} 0`
);
