import { useContext } from 'react';
import { FeatureType } from '../../../generated/graphql';
import { UserContext } from '../../../context/UserContext';

export const useRestartSegment = (): boolean => {
	const { currentUser } = useContext(UserContext);
	return currentUser.organizationFeatures.some(
		(feature) => feature === FeatureType.RestartSegment
	);
};
