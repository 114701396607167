import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import { UserNavigationMenu } from './UserNavigationMenu';
import { UserContext } from '../../context/UserContext';
import { LoadingDots } from '../shared/LoadingDots';
import { truncateString } from '../../utils/stringUtils';
import { Avatar } from '../shared/avatar/Avatar';
import { LinusTooltip } from '../shared/LinusTooltip';
import { icons } from '../../enums/icons';
import { Icon } from '../shared/designSystem';

type UserNavigationProps = {
	isCollapsed?: boolean;
	setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const UserNavigation = ({
	isCollapsed,
	setIsCollapsed,
}: UserNavigationProps): JSX.Element => {
	const [showProfileControls, setShowProfileControls] =
		useState<boolean>(false);

	//Open the menu if it's closed onclick
	const openMenu = () => {
		setIsCollapsed(!isCollapsed);
	};

	useEffect(() => {
		if (isCollapsed) {
			setShowProfileControls(false);
		}
	}, [isCollapsed]);

	const handleMenu = () => setShowProfileControls(!showProfileControls);

	const { currentUser } = useContext(UserContext);

	if (!currentUser) return <LoadingDots />;
	const { avatarUrl, firstName, lastName } = currentUser;

	const truncatedName = `${truncateString(firstName, 10)} ${truncateString(
		lastName,
		10
	)}`;

	return (
		<StyledNavBar>
			<StyledNavBarContent
				isCollapsed={isCollapsed}
				onClick={isCollapsed ? openMenu : handleMenu}
				data-testid='navbar_content'>
				<Avatar
					imageSrc={avatarUrl}
					firstName={firstName}
					lastName={lastName}
				/>
				{!isCollapsed && (
					<>
						<StyledFullName data-testid='styled_full_name'>
							{firstName.length > 10 || lastName.length > 10 ? (
								<LinusTooltip
									overlay={
										<div>{firstName + ' ' + lastName}</div>
									}
									tooltipIcon={<div>{truncatedName}</div>}
								/>
							) : (
								firstName + ' ' + lastName
							)}
						</StyledFullName>
						{showProfileControls ? (
							<>
								<StyledArrow
									data-testid='close_nav_arrow'
									icon={icons.ArrowUp}
									width={14}
									height={8}
								/>
								<UserNavigationMenu />
							</>
						) : (
							<StyledArrow
								data-testid='open_nav_arrow'
								icon={icons.ArrowDown}
								width={14}
								height={8}
							/>
						)}
					</>
				)}
			</StyledNavBarContent>
		</StyledNavBar>
	);
};

export { UserNavigation };

const StyledNavBar = styled.div`
	margin-top: auto;
	width: 100%;
	position: relative;
`;

type StyledNavbarContentProps = {
	isCollapsed?: boolean;
};

const StyledNavBarContent = styled.div<StyledNavbarContentProps>(
	({ isCollapsed }) => `
	display: flex;
	flex-direction: row;
	justify-content: ${isCollapsed ? 'center' : 'space-between'};
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`
);

const StyledFullName = styled.div(
	({ theme: { fontSize, color } }) => `
    width: 110px; 
    font-size: ${fontSize._16};
    color: ${color.menuText};
	user-select: none;
`
);

const StyledArrow = styled(Icon)`
	user-select: none;
`;
