import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getBatteryService } from 'api/getBatteryService';
import { DeepBatteryResult } from 'api/battery';
import { isNonEmptyString } from '../../utils/stringUtils';

type FetchDeepBatteryResultInput = {
	batteryResultId: string | null;
};

export async function fetchDeepBatteryResult(
	input: FetchDeepBatteryResultInput
): Promise<DeepBatteryResult> {
	if (!input?.batteryResultId) {
		throw new Error(
			'fetchBatteryResult: participantId/organization IDs cannot be null'
		);
	}

	const service = await getBatteryService();
	const { data } = await service.getDeepBatteryResult({
		withMetrics: true,
		batteryResultId: input.batteryResultId,
	});
	return data;
}

export function useGetDeepBatteryResult(input: FetchDeepBatteryResultInput) {
	return useQuery({
		enabled: isNonEmptyString(input?.batteryResultId),
		meta: {
			errorMessage: `Error fetching battery result ${input?.batteryResultId}`,
		},
		queryKey: [QueryKey.DeepBatteryResult, input?.batteryResultId],
		queryFn: () => fetchDeepBatteryResult(input),
		staleTime: Infinity,
	});
}
