/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/** Logging Functions */
export function LOG(message: string, data?: any): void {
	console.log(
		`${message}: ${
			data && typeof data === 'object'
				? JSON.stringify(data, null, 4)
				: data || ''
		}`
	);
}

export function INFO(message: string, data?: any): void {
	console.info(
		`${message}: ${
			data && typeof data === 'object'
				? JSON.stringify(data, null, 4)
				: data || ''
		}`
	);
}

export function DEBUG(message: string, data?: any): void {
	console.debug(
		`${message}: ${
			data && typeof data === 'object'
				? JSON.stringify(data, null, 4)
				: data || ''
		}`
	);
}

export function WARN(message: string, data?: any): void {
	console.warn(
		`${message}: ${
			data && typeof data === 'object'
				? JSON.stringify(data, null, 4)
				: data || ''
		}`
	);
}

export function ERROR(message: string, data?: any): void {
	console.error(
		`${message}: ${
			data && typeof data === 'object'
				? JSON.stringify(data, null, 4)
				: data || ''
		}`
	);
}
