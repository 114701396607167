import { AnswerValue } from '@lh/eng-lhq-questions-common';
import styled, { useTheme } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { H4, P1, P2 } from '../../../../../shared/designSystem/TextComponents';
import { icons } from '../../../../../../enums/icons';
import { Icon } from '../../../../../shared/designSystem/Icon';
import { EnumItem } from '../../../../../../enums/enumeration';
import { LinusTooltip } from '../../../../../shared/LinusTooltip';

type QuestionnaireQuestionProps = {
	name: string;
	text: string;
	patientAnswer?: AnswerValue;
	correctAnswer: AnswerValue;
	width?: string;
	icon?: EnumItem;
	pillar: EnumItem;
};

const QuestionnaireQuestion = ({
	name,
	text,
	patientAnswer,
	correctAnswer,
	width,
	icon,
	pillar,
}: QuestionnaireQuestionProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const answerIsYes = patientAnswer;
	const answerIsNo = !patientAnswer;

	//Display the patient's answer and grade it against correct answer
	const displayGradedAnswer = () => {
		const isCorrect = correctAnswer === patientAnswer;
		const patientAnswerPresent = patientAnswer !== undefined;
		const yes =
			answerIsYes && patientAnswerPresent && isCorrect ? (
				<StyledIcon>
					<Icon
						dataId='YesCorrect'
						icon={icons.CheckmarkSolid}
						color={theme.color.alertSuccess}
						iconWidth={20}
						iconHeight={20}
						title={t`web.report.lifeAndHealth.answers.yes`}
					/>
				</StyledIcon>
			) : answerIsYes && patientAnswerPresent && !isCorrect ? (
				<StyledIcon>
					<Icon
						dataId='YesIncorrect'
						icon={icons.CloseSolid}
						color={theme.color.alertError}
						iconWidth={20}
						iconHeight={20}
						title={t`web.report.lifeAndHealth.answers.no`}
					/>
				</StyledIcon>
			) : (
				<StyledEmpty />
			);

		const no =
			answerIsNo && patientAnswerPresent && isCorrect ? (
				<StyledIcon>
					<Icon
						dataId='NoCorrect'
						icon={icons.CheckmarkSolid}
						color={theme.color.alertSuccess}
						iconWidth={20}
						iconHeight={20}
						title={t`web.report.lifeAndHealth.answers.yes`}
					/>
				</StyledIcon>
			) : answerIsNo && patientAnswerPresent && !isCorrect ? (
				<StyledIcon>
					<Icon
						dataId='NoIncorrect'
						icon={icons.CloseSolid}
						color={theme.color.alertError}
						iconWidth={20}
						iconHeight={20}
						title={t`web.report.lifeAndHealth.answers.no`}
					/>
				</StyledIcon>
			) : (
				<StyledEmpty />
			);

		const risk = isCorrect ? null : (
			<StyledRisk>
				({t`web.report.lifeAndHealth.answers.riskFactorIdentified`})
			</StyledRisk>
		);

		return (
			<StyledSelectionContainer>
				{yes}
				<StyledSelection>{t`web.report.lifeAndHealth.answers.yes`}</StyledSelection>
				{no}
				<StyledSelection>{t`web.report.lifeAndHealth.answers.no`}</StyledSelection>
				{risk}
			</StyledSelectionContainer>
		);
	};

	return (
		<StyledContainer width={width} data-testid={name}>
			<StyledTitleContainer>
				<StyledTitle>{name}</StyledTitle>
				<StyledLine />
				<LinusTooltip
					overlay={
						<StyledToolTip>
							<StyledTooltipTitle>
								<Trans i18nKey={pillar.display} />
							</StyledTooltipTitle>
							<StyledTooltipText>
								<Trans
									i18nKey={`web.report.basicactionplan.${pillar.key}.tooltip`}
								/>
							</StyledTooltipText>
						</StyledToolTip>
					}
					tooltipIcon={
						<Icon icon={icon} iconWidth={32} iconHeight={32} />
					}
				/>
			</StyledTitleContainer>
			<StyledQuestion>{text}</StyledQuestion>
			{displayGradedAnswer()}
		</StyledContainer>
	);
};

export { QuestionnaireQuestion };

type StyledContainerProps = {
	width?: string;
};

const StyledContainer = styled.div<StyledContainerProps>(
	({ theme: { spacing, color }, width }) => `
			display: flex;
			flex-direction: column;
			background-color: ${color.white};
			width: ${width || '100%'};
			align-self: stretch;
			padding: ${spacing.lg} ${spacing.lg} ${spacing.lg} 0; 
	`
);

const StyledSelectionContainer = styled.div(
	({ theme: { spacing } }) => `
			padding-top:${spacing.md};
			display: flex;
			flex-direction: row;
			align-items: end;
			height:100%;
	`
);

const StyledQuestion = styled(H4)(
	({ theme: { spacing } }) => `
			margin-top: ${spacing.md};
	`
);

const StyledSelection = styled(P1)(
	({ theme: { spacing } }) => `
			margin: 0px ${spacing.xl} 0px ${spacing.sm};
	`
);

const StyledIcon = styled.div`
	border-radius: 50%;
	margin: 0;
	padding-top: 5px;
`;

const StyledEmpty = styled.div`
	height: 15px;
	width: 15px;
`;

const StyledRisk = styled(P1)(
	({ theme: { color } }) => `
			margin-bottom: 0;
			color: ${color.bodyTextSecondary};
	`
);

const StyledTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledTitle = styled(P1)(
	({ theme: { spacing, weight } }) => `
	margin-right: ${spacing.md};
	font-weight: ${weight.semi}
`
);

const StyledLine = styled.div(
	({ theme: { spacing, color } }) => `
	background-color: ${color.menuBorder};
	width: 2px;
	height: ${spacing.lg};
	margin-right: ${spacing.md};
`
);

const StyledTooltipTitle = styled(P2)(
	({ theme: { spacing, color } }) => `
	margin: 0 ${spacing.xxs} ${spacing.md} ${spacing.xxs};
	color: ${color.white};
`
);

const StyledTooltipText = styled(P2)(
	({ theme: { spacing, color } }) => `
	margin: ${spacing.sm} ${spacing.xs} 0 ${spacing.xs};
	color: ${color.white};
`
);

const StyledToolTip = styled.div`
	width: 300px;
	text-align: left;
`;
