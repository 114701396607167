// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Kebab = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 4 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Kebab-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Kebab'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.5 2C3.5 1.17492 2.82507 0.5 2 0.5C1.17493 0.5 0.5 1.17492 0.5 2C0.5 2.82508 1.17493 3.5 2 3.5C2.82507 3.5 3.5 2.82508 3.5 2ZM3.5 14C3.5 13.1749 2.82507 12.5 2 12.5C1.17493 12.5 0.5 13.1749 0.5 14C0.5 14.8251 1.17493 15.5 2 15.5C2.82507 15.5 3.5 14.8251 3.5 14ZM2 6.5C2.82507 6.5 3.5 7.17492 3.5 8C3.5 8.82508 2.82507 9.5 2 9.5C1.17493 9.5 0.5 8.82508 0.5 8C0.5 7.17492 1.17493 6.5 2 6.5Z'
				fill='current'
			/>
		</svg>
	);
};
