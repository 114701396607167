import { Space, Divider, Flex } from '@mantine/core';
import styled from 'styled-components';
import {
	getImportantAspectUpdates,
	AspectUpdate,
} from '../../LongitudinalEpsom.helpers';
import { Card as DSCard } from '../../../../../../designSystem/Card';
import { GenericReportCardHeader } from '../../../../../common/GenericReportCard/components/GenericReportCardHeader/GenericReportCardHeader';
import { joiner } from '../../../../../../../utils/joiner';
import { NoResponseDecorative } from '../../../../../../../components/shared/designSystem/Icons';
import { useTranslation } from 'react-i18next';

type Props = { data: ReturnType<typeof getImportantAspectUpdates> };

export const ImportantAspectUpdatesTab = (props: Props) => {
	const { t } = useTranslation();
	if (!props.data) return null;
	const columns = props.data.reduce(
		(acc, v, i) => {
			acc[i % 2].push(v);
			return acc;
		},
		[[], []] as [AspectUpdate[], AspectUpdate[]]
	);

	const maxRows = Math.max(...columns.map((v) => v.length));
	const rows = new Array(maxRows).fill(0).map((_, i) => {
		return (
			<Flex direction='row' key={`aspect-update-row-${i}`}>
				<Flex
					direction='column'
					key={`generic-report-card-component-col1-${i + 1}`}
					style={{
						flex: 0.5,
					}}>
					<Aspect>{`${columns[0][i].aspect}:`}</Aspect>
					<AspectDescription>
						{columns[0][i].description}
					</AspectDescription>
				</Flex>
				{columns[1][i] && (
					<Flex
						direction='column'
						key={`generic-report-card-component-col2-${i + 1}`}
						style={{
							flex: 0.5,
						}}>
						<Aspect>{`${columns[1][i].aspect}:`}</Aspect>
						<AspectDescription>
							{columns[1][i].description}
						</AspectDescription>
					</Flex>
				)}
			</Flex>
		);
	});
	return (
		<div>
			<Space h={'md'} />
			<Card>
				<GenericReportCardHeader
					headerTitle={t(
						'web.report.longEpsom.importantAspect.title'
					)}
				/>
				{rows.length > 0 ? (
					<Subheading>
						{t('web.report.longEpsom.importantAspect.subtitle')}
					</Subheading>
				) : null}
				<Divider my='md' />
				{rows.length ? joiner(rows, <Divider my='md' />) : <Fallback />}
			</Card>
		</div>
	);
};

function Fallback() {
	const { t } = useTranslation();
	return (
		<StyledContainer>
			<NoResponseDecorative width={200} height={150} />
			<FallbackTitle>
				{t('web.report.longEpsom.importantAspect.fallback.nothingHere')}
			</FallbackTitle>
			<FallbackContent>
				{t('web.report.longEpsom.importantAspect.fallback.subtitle')}
			</FallbackContent>
		</StyledContainer>
	);
}

const FallbackTitle = styled.p(
	({ theme }) => `
  font-size: ${theme.fontSize._21};
  padding-bottom: ${theme.spacing.xs};
  color: ${theme.color.textBlue};
  font-weight: ${theme.weight.regular};
  margin-bottom: ${theme.spacing.lg};
`
);
const FallbackContent = styled.p(
	({ theme }) => `
  font-size: ${theme.fontSize._16};
  padding-bottom: ${theme.spacing.xs};
  color: ${theme.color.bodyTextSecondary};
  font-weight: ${theme.weight.regular};
  max-width: 35%;
`
);

const Card = styled(DSCard)(
	({ theme: { spacing } }) => `
	padding: ${spacing.xl};
	display: flex;
	flex-direction: column;
`
);

const Aspect = styled.p(
	({ theme }) => `
  font-size: ${theme.fontSize._14};
  padding-bottom: ${theme.spacing.xs};
  color: ${theme.color.bodyTextSecondary};
  font-weight: ${theme.weight.medium};
`
);
const AspectDescription = styled.p(
	({ theme }) => `
  font-size: ${theme.fontSize._16};
  font-weight: ${theme.weight.regular};
`
);
const Subheading = styled.p(
	({ theme }) => `
  font-size: ${theme.fontSize._16};
  font-weight: ${theme.weight.regular};
  color: ${theme.color.bodyTextSecondary};
  padding: ${theme.spacing.xs} 0;
`
);

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: column;
		align-items: center;
    justify-content: center;
    text-align: center;
		padding: ${spacing.xxxl};

	`
);
