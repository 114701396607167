const enum DeactivateUserEvents {
	INIT = 'DEACTIVATE_USER_INIT',
	SUCCESS = 'DEACTIVATE_USER_SUCCESS',
	FAILURE = 'DEACTIVATE_USER_FAILURE',
}

const enum InviteUserEvents {
	INIT = 'INVITE_NEW_USER_INIT',
	SUCCESS = 'INVITE_NEW_USER_SUCCESS',
	FAILURE = 'INVITE_NEW_USER_FAILURE',
}

const enum ActivateUserEvents {
	INIT = 'ACTIVATE_USER_INIT',
	SUCCESS = 'ACTIVATE_USER_SUCCESS',
	FAILURE = 'ACTIVATE_USER_FAILURE',
}

const enum ResendInvitationUserEvents {
	INIT = 'RESEND_INVITATION_INIT',
	SUCCESS = 'RESEND_INVITATION_SUCCESS',
	FAILURE = 'RESEND_INVITATION_FAILURE',
}

const enum UpdateUserEvents {
	INIT = 'UPDATE_USER_INIT',
	SUCCESS = 'UPDATE_USER_SUCCESS',
	FAILURE = 'UPDATE_USER_FAILURE',
}

export {
	DeactivateUserEvents,
	InviteUserEvents,
	ActivateUserEvents,
	ResendInvitationUserEvents,
	UpdateUserEvents,
};
