import { theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IndicativesKeys } from './ScoreClassification.utils';

interface IndicativeProps {
	ellipsisColor?: string;
	highlightColor?: string;
	indicative: string;
	indicativeTextColor?: string;
	isLastElement?: boolean;
	height?: number | string;
}

export function Indicative({
	ellipsisColor = Theme.colors.green_light,
	height,
	highlightColor = Theme.colors.green_lightest,
	indicative,
	indicativeTextColor = Theme.colors.green_darkest,
	isLastElement,
}: IndicativeProps) {
	const { t } = useTranslation();

	return (
		<IndicativeContainer $height={height} $isLastElement={isLastElement}>
			<Typography.P2 color={Theme.colors.gray_30}>
				{t('web.report.cohort.classification.scoreExplanation')}
			</Typography.P2>
			<IndicativeHighlight $backgroundColor={highlightColor}>
				<Ellipsis $backgroundColor={ellipsisColor} />
				<Typography.P1 weight='500' color={indicativeTextColor}>
					{t(indicative)}
				</Typography.P1>
			</IndicativeHighlight>
			{indicative.includes(
				IndicativesKeys.Dysexecutive.toLowerCase()
			) && (
				<Typography.P2 color={Theme.colors.gray_30}>
					{t(
						'web.report.cohort.classification.dysexecutive.explanation'
					)}
				</Typography.P2>
			)}
		</IndicativeContainer>
	);
}

export const Ellipsis = styled.div<{ $backgroundColor: string }>(
	({ $backgroundColor }) => `
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background-color: ${$backgroundColor};
	margin-right: 8px;
`
);
export const IndicativeHighlight = styled.div<{ $backgroundColor: string }>(
	({ $backgroundColor }) => `
	display: flex;
	background-color: ${$backgroundColor};
	align-items: center;
	border-radius: 8px;
	padding: 0 8px;
	width: fit-content;
	margin-bottom: 12px;
`
);

export const IndicativeContainer = styled.div<{
	$height?: string | number;
	$isLastElement?: boolean;
}>(
	({ $height = 69, $isLastElement }) => `
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 16px;
	border-bottom: ${!$isLastElement && `1px solid ${Theme.colors.gray_60}`};
    height: ${$height ? $height : undefined}px;
`
);
