// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Restart = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Restart-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Restart'}</title>
			<path
				d='M12 20.0001C9.86306 20.0001 7.85407 19.168 6.34321 17.657C4.83215 16.146 4 14.1368 4 12.0003C4 9.86369 4.83215 7.85437 6.34308 6.34351C7.85414 4.83244 9.86293 4.0003 11.9998 4.0003C14.616 4.0003 17.1651 5.27687 18.6522 7.33158C18.8514 7.60691 18.7899 7.99168 18.5144 8.19089C18.2391 8.39011 17.8543 8.32843 17.6551 8.0531C16.3954 6.3123 14.2282 5.23099 12 5.23099C8.26749 5.23099 5.23092 8.26752 5.23092 12C5.23092 15.7326 8.26758 18.7691 12 18.7691C15.7325 18.7691 18.769 15.7324 18.769 12C18.769 11.6602 19.0445 11.3846 19.3845 11.3846C19.7243 11.3846 19.9999 11.6601 19.9999 12C19.9999 14.1368 19.1677 16.1459 17.6568 17.6568C16.1457 19.1679 14.1366 20 12 20L12 20.0001Z'
				fill='#676671'
			/>
			<path
				d='M18.1538 8.30769H15.0768C14.737 8.30769 14.4614 8.03224 14.4614 7.69227C14.4614 7.35242 14.7369 7.07685 15.0768 7.07685H17.5384V4.61542C17.5384 4.27557 17.8139 4 18.1538 4C18.4937 4 18.7693 4.27545 18.7693 4.61542V7.69227C18.7691 8.03223 18.4937 8.30769 18.1538 8.30769Z'
				fill='#676671'
			/>
		</svg>
	);
};
