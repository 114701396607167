import styled from 'styled-components';
import { Space } from '@mantine/core';

import { Typography } from '@lh/eng-web-mosaic-common';

import { Card } from '../../../designSystem/Card';
import { AudioWidget } from '../../../shared/AudioWidget';

export type AudioCardProps = {
	text: {
		cardHeading: string;
		cardSubHeadings?: string[];
	};
	audio?: {
		source?: string;
		fileType?: string;
	};
	renderCardContent?: () => string | React.ReactNode;
};

export const AudioCard = (props: AudioCardProps) => {
	if (!props?.audio?.source || !props?.audio?.fileType) return null;
	return (
		<StyledAudioCard data-testid='audio-card'>
			<Typography.P1 weight={'600'}>
				{props?.text?.cardHeading}
			</Typography.P1>
			<Space h='sm' />
			{props?.text?.cardSubHeadings?.length &&
				props?.text?.cardSubHeadings?.map((subheadingText: string) => {
					return (
						<Typography.P2 key={`${subheadingText}`} weight={'500'}>
							{subheadingText}
						</Typography.P2>
					);
				})}
			<Space h={12} />
			{props?.renderCardContent ? (
				<>
					{props?.renderCardContent()}
					<Space h={12} />
				</>
			) : null}
			<AudioWidget
				srcUrl={props?.audio?.source}
				fileFormat={props?.audio?.fileType}
			/>
		</StyledAudioCard>
	);
};

const StyledAudioCard = styled(Card)`
	padding: ${(props) => props.theme.spacing.lg};
	display: flex;
	flex-direction: column;
`;
