import { createContext, useContext } from 'react';

export const FeatureFlagsContext = createContext<
	SplitIO.IClient | null | undefined
>(undefined);

export function useFeatureFlagsContext() {
	const context = useContext(FeatureFlagsContext);

	if (context === undefined) {
		throw new Error(
			'FeatureFlagContext must be used within <FeatureFlagsProvider />'
		);
	}

	return context;
}
