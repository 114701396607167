const isPartOf = <V, O extends V[]>(value: V, options: O) => {
	return options.includes(value);
};

const getValidValue = <V>(value: unknown, options: V[]): V => {
	if (isPartOf(value, options)) {
		return value as V;
	}
	// create a specific format for these type guards as I can see them being pretty common
	throw new Error(
		`Value must be one of the following: ${options.join(', ')}`
	);
};

const coerceToBoolean = function (
	value: undefined | null | string | number | boolean
): boolean {
	if (!value) return !!value;
	if (typeof value === 'string') {
		if (value === 'true') return true;
		if (['false', 'null', 'undefined', '0'].includes(value)) return false;
	}
	return true;
};

export { getValidValue, coerceToBoolean };
