import { Flex, Space } from '@mantine/core';
import styled from 'styled-components';
import { P1 } from '../../../../../shared/designSystem';

type TotalScoreTextProps = {
	totalScoreText: string;
	totalScoreValue: number;
};

function TotalScoreText(props: TotalScoreTextProps) {
	const { totalScoreText, totalScoreValue } = props;

	return (
		<Flex direction={'row'} justify={'flex-end'} data-testid='total-score'>
			<StyledP1SecondaryText>{`${totalScoreText}:`}</StyledP1SecondaryText>
			<Space w='sm' />
			<StyledP1RegularText>{totalScoreValue}</StyledP1RegularText>
		</Flex>
	);
}

export { TotalScoreText };

const StyledP1RegularText = styled(P1)(
	({ theme: { color, weight } }) => `
    weight: ${weight.medium};
    color: ${color.bodyText};
`
);

const StyledP1SecondaryText = styled(P1)(
	({ theme: { color } }) => `
    color: ${color.bodyTextSecondary};
`
);
