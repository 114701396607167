import React, { useMemo } from 'react';
import { FeatureFlag, useFeatureFlag } from '../feature-flags';
import { createAuthService } from './createAuthService';
import { AuthServiceContext } from './useAuthServiceContext';

interface AuthServiceProviderProps {
	children: React.ReactNode;
}

export function AuthServiceProvider({ children }: AuthServiceProviderProps) {
	const isAuth0Universal = useFeatureFlag(FeatureFlag.Auth0Universal);
	const auth = useMemo(
		() => createAuthService(isAuth0Universal).build(),
		[isAuth0Universal]
	);
	return (
		<AuthServiceContext.Provider value={auth}>
			{children}
		</AuthServiceContext.Provider>
	);
}
