import { useContext } from 'react';

import { first } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useGetParticipant } from 'api/participant';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { AnalyticsAction } from '../../../generated/graphql';
import { useResolveBatteryResult } from '../../../hooks/batteryResults';
import { batteryDataTransformer } from '../../../providers/globalDataProvider/BatteryDataTransformer';
import { GlobalDataContext } from '../../../providers/globalDataProvider/GlobalDataProvider';
import { mapBatteryName } from './Breadcrumbs.helpers';

const Breadcrumbs = (): JSX.Element | null => {
	const { t } = useTranslation();
	const params = useParams();
	const { participantId, reportId } = params;

	const { currentUser } = useContext(UserContext);
	const { batteries } = useContext(GlobalDataContext);

	const { data: participant, isFetching: isLoadingParticipantData } =
		useGetParticipant({
			participantId: participantId ?? '',
			organizationId: currentUser?.organizationId,
		});

	const { data: restBatteryResult, loading: isLoadingBatteryResult } =
		useResolveBatteryResult({
			batteryResultId: reportId ?? '',
		});

	const battery = first(
		batteryDataTransformer([restBatteryResult?.batteryId || ''], batteries)
	);

	const batteryName = battery?.displayKey
		? t(battery.displayKey)
		: battery?.name;
	const batteryNameDisplay = mapBatteryName(batteryName);

	const onClickHandler = () => {
		sendEventData({
			eventType: AnalyticsAction.EscapedCceCdsFullArticle,
			eventProperties: {
				clickedComponent: 'Breadcrumbs',
			},
		});
	};

	if (isLoadingParticipantData || isLoadingBatteryResult) return null;

	return (
		<StyledBreadCrumbContainer>
			<StyledBreadCrumb isLink={true}>
				<StyledLink>
					<Link to={'/results'} onClick={onClickHandler}>
						{t`web.enums.routes.results` as string}
					</Link>
					<StyledSlash>{` / `}</StyledSlash>
				</StyledLink>
			</StyledBreadCrumb>
			<StyledBreadCrumb isLink={true}>
				<StyledLink>
					<Link
						to={`/participants/${participantId}`}
						onClick={onClickHandler}>
						{participant?.externalId}
					</Link>
					<StyledSlash>{` / `}</StyledSlash>
				</StyledLink>
			</StyledBreadCrumb>
			<StyledBreadCrumb isLink={false} data-testid='cceReportText'>
				{batteryNameDisplay}
			</StyledBreadCrumb>
		</StyledBreadCrumbContainer>
	);
};

const StyledBreadCrumbContainer = styled.div(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.lg} ${spacing.xl} 0;
	display: flex;
	flex-direction: row;
	padding-left: 2px;
`
);

type StyledBreadCrumbProps = {
	isLink: boolean;
};

const StyledBreadCrumb = styled.div<StyledBreadCrumbProps>(
	({ theme: { spacing, weight, fontSize, color }, isLink }) => `
	margin-right: ${spacing.sm};
	font-weight: ${weight.medium};
	font-size: ${fontSize._16};
	line-height: 19px;
	letter-spacing: 0.5px;
	display: flex;
	flex-direction: row;
	display: inline;
	color: ${isLink ? color.link : color.linkDisabled};
`
);

const StyledLink = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledSlash = styled.div(
	({ theme: { color } }) => `
	color: ${color.linkDisabled} !important;
	margin-left: 5px
`
);

export { Breadcrumbs };
