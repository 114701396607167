import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Formik, FormikHelpers } from 'formik';
import styled, { css } from 'styled-components';

import { Gender, Handedness, useCreateParticipant } from 'api/participant';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { Enumeration, VLEnum } from '../../../enums/enumeration';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { handedness } from '../../../enums/participantEnums/handedness';
import {
	AnalyticsAction,
	FeatureType,
	ValueListType,
} from '../../../generated/graphql';
import { t } from '../../../i18n';
import { ERROR } from '../../../logging/linusLogger';
import {
	AddParticipantModel,
	addParticipantSchema,
	nullOParticipant,
} from '../../../schemas/forms/addParticipantSchema';
import { DropdownOption } from '../../../types';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { ButtonLg } from '../../shared/designSystem';
import { messages } from '../../shared/errorMessages';
import { CONTACT_PREFERENCES, getGendersList } from '../participantHelpers';
import { ParticipantTableData } from 'app/src/schemas/table/participantSchema';

type YearOption = {
	value: string;
	display: string;
};

export const buildLanguageDisplayOptions = (
	valueListLanguageOptions: Enumeration<VLEnum>
): DropdownOption[] => {
	return valueListLanguageOptions?.toOptions()?.map((option) => {
		return {
			value: option?.value,
			display: t(option?.display),
		};
	});
};
const sortByValueDescending = (a: YearOption, b: YearOption) => {
	return parseInt(b.value) - parseInt(a.value);
};
const LOWER_BOUND_YEAR = 1900;
export const yearOptions = Array(
	new Date().getFullYear() - LOWER_BOUND_YEAR + 1
)
	.fill(0)
	.map((_, index) => ({
		value: `${LOWER_BOUND_YEAR + index}`,
		display: `${LOWER_BOUND_YEAR + index}`,
	}))
	.sort(sortByValueDescending);

type AddParticipantFormProps = {
	onFinish: () => void;
	onCancel: () => void;
	setParticipant: (p: ParticipantTableData) => void;
};

export const AddParticipantForm = ({
	onFinish,
	onCancel,
	setParticipant,
}: AddParticipantFormProps) => {
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const { mutateAsync: createParticipant } = useCreateParticipant();

	const languageDropdownOptions = buildLanguageDisplayOptions(
		currentUser?.organizationParticipantLanguages
	);
	const hasRemoteAssessmentsFlag = currentUser.organizationFeatures?.includes(
		FeatureType.RemoteAssessment
	);

	const onSubmit = async (
		values: AddParticipantModel,
		{ setSubmitting, setFieldError }: FormikHelpers<AddParticipantModel>
	) => {
		let contactPreference = null;
		if (values.contactEmail && !values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.EMAIL_ONLY;
		}
		if (!values.contactEmail && values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.PHONE_ONLY;
		}
		if (values.contactEmail && values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.EMAIL_AND_PHONE;
		}

		try {
			const response = await createParticipant({
				organizationId: currentUser.organizationId,
				participant: {
					birthYear: parseInt(values.birthYear),
					contactEmail: values.contactEmail || '',
					contactPhone: values.contactPhone || '',
					...(contactPreference !== null && {
						contactPreference: contactPreference,
					}),
					educationIds: values?.educationId
						? [values?.educationId]
						: undefined,
					ethnicityIds: values.ethnicityIds
						? values.ethnicityIds.split(/, ?/)
						: undefined,
					raceIds: values.raceId ? [values.raceId] : undefined,
					externalId: values.externalId?.trim() || '',
					gender: (values.gender as unknown as Gender) || undefined,
					handedness:
						(values.handedness as unknown as Handedness) ||
						undefined,
					language: values.language,
					notes: values.notes,
				},
			});

			if (!response?.data) {
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
				return;
			}
			const { id, externalId } = response.data;
			setParticipant({
				id,
				newPatient: true,
				externalId,
			});
			sendEventData({ eventType: AnalyticsAction.AddedNewPatient });
			onFinish && onFinish();
			setSubmitting(false);
			//instanceof AxiosError not performing as expected so optional chaining instead
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			ERROR('Create participant error', err);
			if (err.response?.data?.errorCode === 'EXTERNAL_ID_EXISTS') {
				setFieldError(
					'externalId',
					t('web.participant.forms.errors.externalId.duplicate')
				);
			} else {
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
			}
		}
	};

	return (
		<Formik
			initialValues={nullOParticipant}
			validationSchema={addParticipantSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				const genders = getGendersList();

				return (
					<StyledForm>
						<Row>
							<LinusInput
								name='externalId'
								label={t(
									'research.participantModal.participantId',
									{
										context:
											currentUser.organizationType.value,
									}
								)}
							/>
							<LinusInput
								name='birthYear'
								type='select'
								label={`${t(
									'web.participant.forms.birthYear'
								)}`}
								dropdownOptions={yearOptions}
							/>
						</Row>
						<Row>
							<LinusInput
								name='language'
								type='select'
								label={t`web.patients.forms.languageLabel`}
								dropdownOptions={languageDropdownOptions}
								preferDisplayLength='short'
							/>
							<LinusInput
								name='gender'
								type='select'
								label={`${t(
									'web.patients.forms.genderLabel'
								)} ${t('web.shared.forms.optional')}`}
								dropdownOptions={genders}
							/>
						</Row>
						<Row>
							<LinusInput
								name='raceId'
								type='select'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Race
								]?.toOptions()}
								label={t`web.patients.forms.raceLabel`}
							/>
							<LinusInput
								name='ethnicityIds'
								type='multi'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Ethnicity
								]?.toOptions()}
								label={t`web.patients.forms.ethnicityLabel`}
							/>
						</Row>
						<Row>
							<LinusInput
								name='educationId'
								type='select'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Education
								]?.toOptions()}
								label={`${t(
									'web.patients.forms.educationLevelLabel'
								)} ${t('web.shared.forms.optional')}`}
							/>
							<LinusInput
								name='handedness'
								type='select'
								dropdownOptions={handedness?.toOptions()}
								label={`${t(
									'web.patients.forms.handednessLabel'
								)} ${t('web.shared.forms.optional')}`}
							/>
						</Row>
						{hasRemoteAssessmentsFlag && (
							<Row>
								<LinusInput
									name='contactEmail'
									label={t`web.patients.forms.email`}
								/>
								<LinusInput
									label={t`web.patients.forms.phone`}
									name='contactPhone'
									type='phone'
								/>
							</Row>
						)}
						<Row>
							<LinusInput
								label={t`web.patients.forms.notesLabel`}
								name='notes'
								type='textarea'
								width='667px'
								rows={2}
							/>
						</Row>
						<ButtonAndErrorRow>
							<StyledInfoMessage>
								<InfoMessage
									messageEnum={serverSideMessage}
									showIf={!!serverSideMessage}
								/>
							</StyledInfoMessage>
							<ButtonRow>
								<ButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.patients.forms.cancelCTA`}
										width='200px'
									/>
								</ButtonWrapper>
								<ButtonLg
									primary
									disabled={
										!(isValid && dirty) ||
										isSubmitting ||
										!currentUser?.organizationValueLists
									}
									loading={isSubmitting}
									text={t`research.participantModal.addCTA`}
									type='submit'
									width='200px'
								/>
							</ButtonRow>
						</ButtonAndErrorRow>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 667px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ButtonRow = styled.div`
	display: flex;
	justify-content: center;
`;

const ButtonWrapper = styled.div`
	margin: 0 25px 0 0;
`;

const ButtonAndErrorRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: ${spacing.xxxl} ${spacing.md} 0;
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => css`
		padding-bottom: ${spacing.md};
	`
);
