import { Flex, Space } from '@mantine/core';
import styled from 'styled-components';
import { EpsomScores } from '../../../../../../../../types/epsomTypes';
import { ConfidenceScoreHeader } from './ConfidenceScoreHeader';
import { ConfidenceScoreLegend } from './ConfidenceScoreLegend';
import { ConfidenceScoreResult } from './ConfidenceScoreResult';
import {
	FeatureFlag,
	useFeatureFlag,
} from '../../../../../../../../features/feature-flags';

type ConfidenceScoreCardProps = {
	epsomScores: EpsomScores;
};

function ConfidenceScoreCard({
	epsomScores,
}: Readonly<ConfidenceScoreCardProps>) {
	const shouldDisplayConfidenceScore = useFeatureFlag(
		FeatureFlag.EpsomConfidenceScore
	);

	if (!shouldDisplayConfidenceScore) return null;
	if (!epsomScores) return null;

	return (
		<StyledWrapper data-testid='confidence-score-card'>
			<PaddedContainer>
				<ConfidenceScoreHeader />
			</PaddedContainer>

			<StyledSeparator data-testid='confidence-header-separator' />

			<PaddedContainer>
				<Flex direction={'row'}>
					<ConfidenceScoreResult totalScore={epsomScores.total} />
					<Space w='lg' />
					<ConfidenceScoreLegend />
				</Flex>
			</PaddedContainer>
		</StyledWrapper>
	);
}

const PaddedContainer = styled.div(
	({ theme: { spacing } }) => `
		padding: ${spacing.xl};			
	`
);

const StyledWrapper = styled.div(
	({ theme: { color, spacing } }) => `
		background: ${color.white};
		box-shadow: 0px 2px 10px rgba(23, 24, 32, 0.1);
		border-radius: 20px;
		margin-top: ${spacing.xl};
	`
);

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	position: relative;
	width: 100%;  
	border-bottom: 1px solid ${color.infoSeparator};	
`
);

export { ConfidenceScoreCard };
