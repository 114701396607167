import { useQuery } from '@tanstack/react-query';
import {
	GetBatteryResultsByOrganizationRequest,
	PaginatedBatteryResults,
} from 'api/battery';
import { getBatteryService } from 'api/getBatteryService';
import { CacheTimes, QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';

export async function fetchBatteryResultsByOrgId(
	requestOptions: GetBatteryResultsByOrganizationRequest
): Promise<PaginatedBatteryResults> {
	if (!requestOptions?.organizationId) {
		throw new Error("useParticipantQuery: organizationId can't be null");
	}
	const service = await getBatteryService();
	const { data } = await service.getBatteryResultsByOrganization(
		requestOptions
	);
	return data;
}

export function useGetOrgBatteryResults(
	requestOptions: GetBatteryResultsByOrganizationRequest
) {
	const organizationId = requestOptions?.organizationId;
	const isValidOrgId = isNonEmptyString(organizationId);
	return useQuery({
		enabled: isValidOrgId,
		meta: {
			errorMessage: `Error fetching battery results ${organizationId}`,
		},
		queryKey: [
			QueryKey.BatteryResultsByOrg,
			requestOptions?.page,
			requestOptions?.filterValue,
			requestOptions?.filterField,
			requestOptions?.sortField,
			requestOptions?.sortOrder,
		],
		queryFn: () => fetchBatteryResultsByOrgId(requestOptions),
		// Why not use an infinite staleTime value here? 5 mins feels like enough time for a result to process and short enough that the user can expect it to come in if they are waiting for something in particular.
		// https://github.com/linushealth/eng-frontend-research-app/pull/761#discussion_r1666937990
		staleTime: CacheTimes.FiveMinutes,
	});
}
