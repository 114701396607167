// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const ExternalLink = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 16 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='ExternalLink-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'ExternalLink'}</title>
			<path
				d='M3.99984 3.99984C3.63165 3.99984 3.33317 4.29831 3.33317 4.6665V11.9998C3.33317 12.368 3.63165 12.6665 3.99984 12.6665H11.3332C11.7014 12.6665 11.9998 12.368 11.9998 11.9998V8.6665C11.9998 8.48241 12.1491 8.33317 12.3332 8.33317C12.5173 8.33317 12.6665 8.48241 12.6665 8.6665V11.9998C12.6665 12.7362 12.0695 13.3332 11.3332 13.3332H3.99984C3.26346 13.3332 2.6665 12.7362 2.6665 11.9998V4.6665C2.6665 3.93012 3.26346 3.33317 3.99984 3.33317H7.33317C7.51727 3.33317 7.6665 3.48241 7.6665 3.6665C7.6665 3.8506 7.51727 3.99984 7.33317 3.99984H3.99984Z'
				fill='#087DAE'
			/>
			<path
				d='M9.05726 2.6665C8.87554 2.6665 8.72823 2.81381 8.72823 2.99553C8.72823 3.17725 8.87554 3.32456 9.05726 3.32456L12.2098 3.32456L6.6665 8.86786C6.53801 8.99635 6.53801 9.20468 6.6665 9.33317C6.795 9.46166 7.00332 9.46166 7.13182 9.33317L12.6751 3.78981L12.6751 6.94246C12.6751 7.12418 12.8224 7.27149 13.0042 7.27149C13.1859 7.27149 13.3332 7.12418 13.3332 6.94246L13.3332 2.99553C13.3332 2.81446 13.1869 2.66756 13.0061 2.66651'
				fill='#087DAE'
			/>
			<path d='M9.05726 2.6665L13.0038 2.6665Z' fill='#087DAE' />
		</svg>
	);
};
