/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

//################################################################################
//Math utilities
//################################################################################
export const dctMath = new (function DctMath() {
	//Fit a rectangle to another rectangle
	this.fitRectangle = function (
		arg_width,
		arg_height,
		arg_max_width,
		arg_max_height,
		arg_round,
		arg_padding
	) {
		//Padding
		var padding = arg_padding || 0;
		var real_max_width = arg_max_width - 2 * padding;
		var real_max_height = arg_max_height - 2 * padding;

		//Calculate using width
		var ratio = arg_width / (arg_height || 1); //Divide by zero protection
		var width = real_max_width;
		if (arg_round) width = Math.round(width);
		var height = width / ratio;
		if (arg_round) height = Math.round(height);

		//Calculate using height if too big
		if (height > real_max_height) {
			height = real_max_height;
			if (arg_round) height = Math.round(height);
			width = height * (ratio || 1);
			if (arg_round) width = Math.round(width);
		}

		//Calculate scale
		var scale = width / (arg_width || 1);

		//Apply padding
		width += 2 * padding;
		height += 2 * padding;

		//What would it take to center this?
		var cleft = arg_max_width / 2 - width / 2;
		var ctop = arg_max_height / 2 - height / 2;

		//Return result
		return {
			width: width,
			height: height,
			center_left: cleft,
			center_top: ctop,
			scale: scale,
			padding: padding,
		};
	};

	/*
  Date of birth is a UTC timestamp, plus a few milliseconds to denote uncertainty levels.

  If the timestamp of date of birth is:

  0 milliseconds after midnight UTC
    This is a full, actual birthdate.
    Example:  "3/4/1922" -> UTC "3/4/1922 + 0ms"

  1 millisecond after midnight, 1st of january UTC
    This is a year of birth.
    Example:  "1922" -> UTC "1/1/1922 + 1ms"

  2 milliseconds after midnight, 1st of any month UTC:
    This is a month-only date of birth.
    Example:  "3/1922" -> UTC "3/1/1922 + 2ms"

  3 milliseconds after midnight UTC:
    This is a date of birth derived from an age.
    Example:  "81" -> UTC "5/17/1935 + 3ms" (assuming test was done on 5/17/2016)

  4 milliseconds after midnight UTC:
    This is a date of birth derived from an capped age (90+).
    Example:  "90+" -> UTC "5/17/1926 + 4ms" (assuming test was done on 5/17/2016)
  */
	this.MS_OFFSET_YEAR_ONLY = 1; //Constants are repeated in multiple locations, do not change!
	this.MS_OFFSET_MONTH_ONLY = 2; //Constants are repeated in multiple locations, do not change!
	this.MS_OFFSET_FROM_AGE = 3; //Constants are repeated in multiple locations, do not change!
	this.MS_OFFSET_FROM_AGE_CAP = 4; //Constants are repeated in multiple locations, do not change!

	//Get a number range array
	this.range = function (arg_min, arg_max, arg_step) {
		var step = arg_step || 1;
		var a = [];
		for (var i = arg_min; i <= arg_max; i += step) a.push(i);
		return a;
	};

	//Calculate their age
	this.calculateAge = function (arg_birth_date, arg_as_of) {
		//Make date objects
		var birthdate = new Date();
		birthdate.setTime(arg_birth_date);
		var activedate = new Date();
		activedate.setTime(arg_as_of);

		//Difference
		var diff = activedate.getFullYear() - birthdate.getFullYear();

		//Before their birthday?
		if (
			activedate.getMonth() < birthdate.getMonth() ||
			(activedate.getMonth() === birthdate.getMonth() &&
				activedate.getDate() < birthdate.getDate())
		) {
			diff--;
		}

		//Return the age
		return diff;
	};

	//Calculate age capped
	this.calculateAgeCappedAt90 = function (arg_birth_date, arg_as_of) {
		var result = this.calculateAge(arg_birth_date, arg_as_of);
		if (result >= 90) return '90+';
		return result;
	};

	//Age to timestamp
	this.ageToTimestamp = function (arg_age, arg_as_of) {
		//MS offset
		var ms_offset = this.MS_OFFSET_FROM_AGE;

		var match;
		if (
			typeof arg_age === 'string' &&
			(match = arg_age.match(/^(\d+)(\+)$/))
		) {
			arg_age = parseInt(match[1], 10);
			if (match[2]) ms_offset = this.MS_OFFSET_FROM_AGE_CAP;
		}

		//Make sure arg_as_of is a date object (will convert timestamps to dates, or clone date objects)
		var result_date = new Date(arg_as_of);

		//Add ms offset, just to ensure that margin does not cause an age error
		result_date.setMilliseconds(result_date.getMilliseconds() + ms_offset);

		//Subtract age in years, so birth date will be exactly that many years ago, minus UTC margin.
		result_date.setFullYear(result_date.getFullYear() - arg_age);

		//Now zero-out UTC date properties
		result_date.setUTCHours(0);
		result_date.setUTCMinutes(0);
		result_date.setUTCSeconds(0);
		result_date.setUTCMilliseconds(ms_offset);

		//Return the timestamp
		return result_date.getTime();
	};
})();
