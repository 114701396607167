import { theme as Theme } from '@lh/eng-web-mosaic-common';
import cdf from '@stdlib/stats/base/dists/normal/cdf';
import { icons } from '../../../../../../enums/icons';
import i18n from '../../../../../../i18n';
import { MeasuresProps, ScoresProps } from './Breakdown.types';
import {
	DeepBatteryResult,
	MetricItem,
} from '@lh/eng-platform-battery-service-rest-client';

export function parseMetricItems(br: DeepBatteryResult | null) {
	const metricItems: MetricItem[] = [
		{ algorithmVersion: '', id: '', key: '', value: '' },
	];
	let resultMetrics: MetricItem[] = [];
	if (br) {
		resultMetrics = br.assessmentResults.flatMap((ar) =>
			ar.segmentResults.flatMap((sr) => sr.metricItems)
		);
	}

	return metricItems.concat(resultMetrics);
}

export function percentileSuffix(n: number) {
	const lastDigit = n % 10,
		lastTwoDigits = n % 100;

	if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
		return 'th';
	}

	if (lastDigit === 1) {
		return 'st';
	}

	if (lastDigit === 2) {
		return 'nd';
	}

	if (lastDigit === 3) {
		return 'rd';
	}

	return 'th';
}

enum MetricsKeys {
	COMComponentPlacement_s = 'COMComponentPlacement_s',
	COPComponentPlacement_s = 'COPComponentPlacement_s',
	COMLatencyVariability_s = 'COMLatencyVariability_s',
	COPLatencyVariability_s = 'COPLatencyVariability_s',
	PvltRecallScore = 'pvlt_recall_score',
	PvltRecognitionScore = 'pvlt_recognition_score',
	BdstAverageSerial = 'backward_digit_span_avg_ser_order_score',
	SemanticFluency = 'semantic_fluency_total_score',
}

enum ImpairedTresholds {
	Visuoconstructionals = '-1',
	PvltRecall = '0',
	PvltRecognition = '2',
	BdstAverage = '3.535',
	SemanticFluency = '12',
}

interface MetricsKeysProps {
	title: string;
	impairedTreshold: ImpairedTresholds;
	mean: number;
	standardDeviation: number;
}

const VisuoconstructionalKeys: Record<string, MetricsKeysProps> = {
	[MetricsKeys.COMComponentPlacement_s]: {
		title: 'web.report.cohort.breakdown.expanded.dcrCom',
		impairedTreshold: ImpairedTresholds.Visuoconstructionals,
		mean: 0,
		standardDeviation: 1,
	},
	[MetricsKeys.COPComponentPlacement_s]: {
		title: 'web.report.cohort.breakdown.expanded.dcrCop',
		impairedTreshold: ImpairedTresholds.Visuoconstructionals,
		mean: 0,
		standardDeviation: 1,
	},
	[MetricsKeys.COMLatencyVariability_s]: {
		title: 'web.report.cohort.breakdown.expanded.dcrComLatency',
		impairedTreshold: ImpairedTresholds.Visuoconstructionals,
		mean: 0,
		standardDeviation: 1,
	},
	[MetricsKeys.COPLatencyVariability_s]: {
		title: 'web.report.cohort.breakdown.expanded.dcrCopLatency',
		impairedTreshold: ImpairedTresholds.Visuoconstructionals,
		mean: 0,
		standardDeviation: 1,
	},
};

const EpisodicMemoryKeys: Record<string, MetricsKeysProps> = {
	[MetricsKeys.PvltRecallScore]: {
		title: 'web.report.cohort.breakdown.expanded.pvltRecall',
		impairedTreshold: ImpairedTresholds.PvltRecall,
		mean: 2.466,
		standardDeviation: 1.959,
	},
	[MetricsKeys.PvltRecognitionScore]: {
		title: 'web.report.cohort.breakdown.expanded.pvltRecognition',
		impairedTreshold: ImpairedTresholds.PvltRecognition,
		mean: 4.733,
		standardDeviation: 1.751,
	},
};

const ExecutiveControlKeys: Record<string, MetricsKeysProps> = {
	[MetricsKeys.BdstAverageSerial]: {
		title: 'web.report.cohort.breakdown.expanded.bdstAverage',
		impairedTreshold: ImpairedTresholds.BdstAverage,
		mean: 4.125,
		standardDeviation: 0.59,
	},
	[MetricsKeys.SemanticFluency]: {
		title: 'web.report.cohort.breakdown.expanded.semanticFluency',
		impairedTreshold: ImpairedTresholds.SemanticFluency,
		mean: 16.83,
		standardDeviation: 3.838,
	},
};

function getPercentile(value: number, mean: number, standardDeviation: number) {
	return cdf(value, mean, standardDeviation) * 100;
}

export function getMetricsInterpretation(metricItems: MetricItem[]) {
	const visuoconstructional: ScoresProps[] = [];
	const episodicMemory: ScoresProps[] = [];
	const executiveControl: ScoresProps[] = [];

	const VISUOCONSTRUCTIONAL = Object.keys(VisuoconstructionalKeys);
	const EPISODIC_MEMORY = Object.keys(EpisodicMemoryKeys);
	const EXECUTIVE_CONTROL = Object.keys(ExecutiveControlKeys);

	metricItems.forEach((item) => {
		const isVisuoconstructional = VISUOCONSTRUCTIONAL.find(
			(value) => value === item.key
		);
		const isEpisodicMemory = EPISODIC_MEMORY.find(
			(value) => value === item.key
		);
		const isExecutiveControl = EXECUTIVE_CONTROL.find(
			(value) => value === item.key
		);

		if (isVisuoconstructional) {
			visuoconstructional.push({
				key: item.key,
				percentile: getPercentile(
					Number(item.value),
					VisuoconstructionalKeys[item.key].mean,
					VisuoconstructionalKeys[item.key].standardDeviation
				),
				value: item.value,
				title: i18n.t(VisuoconstructionalKeys[item.key].title),
				isImpaired:
					Number(item.value) <=
					Number(VisuoconstructionalKeys[item.key].impairedTreshold),
			});
		}

		if (isEpisodicMemory) {
			episodicMemory.push({
				key: item.key,
				percentile: getPercentile(
					Number(item.value),
					EpisodicMemoryKeys[item.key].mean,
					EpisodicMemoryKeys[item.key].standardDeviation
				),
				value: item.value,
				title: i18n.t(EpisodicMemoryKeys[item.key].title),
				isImpaired:
					Number(item.value) <=
					Number(EpisodicMemoryKeys[item.key].impairedTreshold),
			});
		}

		if (isExecutiveControl) {
			executiveControl.push({
				key: item.key,
				percentile: getPercentile(
					Number(item.value),
					ExecutiveControlKeys[item.key].mean,
					ExecutiveControlKeys[item.key].standardDeviation
				),
				value: item.value,
				title: i18n.t(ExecutiveControlKeys[item.key].title),
				isImpaired:
					Number(item.value) <=
					Number(ExecutiveControlKeys[item.key].impairedTreshold),
			});
		}
	});

	return { visuoconstructional, episodicMemory, executiveControl };
}

const PercentileTresholds = {
	High: { min: 75 },
	Medium: { min: 50, max: 74 },
	Low: { min: 25, max: 49 },
	VeryLow: { max: 24 },
};

export function getScoreInterpretation(percentile: number, score?: string) {
	if (percentile >= PercentileTresholds.High.min) {
		return {
			icon: icons.ScoreCurveHigh,
			score: i18n.t('web.report.cohort.breakdown.expanded.sd', {
				value: parseInt(score ?? '').toFixed(1),
			}),
		};
	}

	if (
		percentile >= PercentileTresholds.Medium.min &&
		percentile <= PercentileTresholds.Medium.max
	) {
		return {
			icon: icons.ScoreCurveMedium,
			score: i18n.t('web.report.cohort.breakdown.expanded.sd', {
				value: parseInt(score ?? '').toFixed(1),
			}),
		};
	}

	if (
		percentile > PercentileTresholds.Low.min &&
		percentile <= PercentileTresholds.Low.max
	) {
		return {
			icon: icons.ScoreCurveLow,
			score: i18n.t('web.report.cohort.breakdown.expanded.sd', {
				value: parseInt(score ?? '').toFixed(1),
			}),
		};
	}

	return {
		icon: icons.ScoreCurveVeryLow,
		score: i18n.t('web.report.cohort.breakdown.expanded.sd', {
			value: parseInt(score ?? '').toFixed(1),
		}),
	};
}

export function getImpairedMeasures(
	visuoconstructional?: ScoresProps[],
	episodicMemory?: ScoresProps[],
	executiveControl?: ScoresProps[]
) {
	let visuoconstructionalMeasures = undefined;
	let episodicMemoryMeasures = undefined;
	let executiveControlMeasures = undefined;
	let totalImpairedMeasures = undefined;

	if (episodicMemory && episodicMemory.length > 0) {
		episodicMemoryMeasures = episodicMemory.filter(
			(score) => Number(score.value) < 0
		).length;
	}

	if (executiveControl && executiveControl.length > 0) {
		executiveControlMeasures = executiveControl.filter(
			(score) => Number(score.value) < 0
		).length;
	}

	if (visuoconstructional && visuoconstructional.length > 0) {
		visuoconstructionalMeasures = visuoconstructional.filter(
			(score) => Number(score.value) < 0
		).length;
	}

	if (
		visuoconstructionalMeasures &&
		episodicMemoryMeasures &&
		executiveControlMeasures
	)
		totalImpairedMeasures =
			visuoconstructionalMeasures +
			episodicMemoryMeasures +
			executiveControlMeasures;

	return {
		visuoconstructionalMeasures,
		episodicMemoryMeasures,
		executiveControlMeasures,
		totalImpairedMeasures,
	};
}

export enum IndicativeKeys {
	Normal = 'Normal',
	Subtle = 'Subtle',
	SingleDomain = 'SingleDomain',
	Dysexecutive = 'Dysexecutive',
	MixedMild = 'MixedMild',
	AmnesticModerate = 'AmnesticModerate',
	NonAmnestic = 'NonAmnestic',
	AssignDCR = 'AssignDCR',
	NotAvailable = 'NotAvailable',
}

export interface IndicativeProps {
	colors: {
		ellipsis: string;
		container: string;
	};
	indicativeText: string;
	key: IndicativeKeys;
}

const Indicatives: Record<IndicativeKeys, IndicativeProps> = {
	[IndicativeKeys.Normal]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.normal.indicative'
		),
		colors: {
			ellipsis: Theme.colors.green_light,
			container: Theme.colors.green_lightest,
		},
		key: IndicativeKeys.Normal,
	},
	[IndicativeKeys.Subtle]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.subtle.indicative'
		),
		colors: {
			ellipsis: Theme.colors.green_light,
			container: Theme.colors.green_lightest,
		},
		key: IndicativeKeys.Subtle,
	},
	[IndicativeKeys.SingleDomain]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.singleDomain.indicative'
		),
		colors: {
			ellipsis: Theme.colors.yellow_light,
			container: Theme.colors.yellow_lightest,
		},
		key: IndicativeKeys.SingleDomain,
	},
	[IndicativeKeys.Dysexecutive]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.dysexecutive.indicative'
		),
		colors: {
			ellipsis: Theme.colors.yellow_light,
			container: Theme.colors.yellow_lightest,
		},
		key: IndicativeKeys.Dysexecutive,
	},
	[IndicativeKeys.MixedMild]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.mixedMild.indicative'
		),
		colors: {
			ellipsis: Theme.colors.yellow_light,
			container: Theme.colors.yellow_lightest,
		},
		key: IndicativeKeys.MixedMild,
	},
	[IndicativeKeys.AmnesticModerate]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.amnesticModerate.indicative'
		),
		colors: {
			ellipsis: Theme.colors.orange_light,
			container: Theme.colors.orange_lightest,
		},
		key: IndicativeKeys.AmnesticModerate,
	},
	[IndicativeKeys.NonAmnestic]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.nonAmnestic.indicative'
		),
		colors: {
			ellipsis: Theme.colors.orange_light,
			container: Theme.colors.orange_lightest,
		},
		key: IndicativeKeys.NonAmnestic,
	},
	[IndicativeKeys.AssignDCR]: {
		indicativeText: i18n.t('web.report.cohort.classification.assignDCR'),
		colors: {
			ellipsis: Theme.colors.gray_80,
			container: Theme.colors.gray_80,
		},
		key: IndicativeKeys.AssignDCR,
	},
	[IndicativeKeys.NotAvailable]: {
		indicativeText: i18n.t(
			'web.report.cohort.classification.notAvailable.title'
		),
		colors: {
			ellipsis: Theme.colors.gray_80,
			container: Theme.colors.gray_80,
		},
		key: IndicativeKeys.NotAvailable,
	},
};

interface CalculateIndicativeProps {
	normalIndicativeCheck: boolean;
	nonAmnesticIndicativeCheck: boolean;
	subtleIndicativeCheck: boolean;
	singleDomainIndicativeCheck: boolean;
	dysexecutiveIndicativeCheck: boolean;
	mixedMildIndicativeCheck: boolean;
	amnesticModerateIndicativeCheck: boolean;
	assignDCRIndicativeCheck: boolean;
}

function calculateIndicatives({
	amnesticModerateIndicativeCheck,
	assignDCRIndicativeCheck,
	dysexecutiveIndicativeCheck,
	mixedMildIndicativeCheck,
	nonAmnesticIndicativeCheck,
	normalIndicativeCheck,
	singleDomainIndicativeCheck,
	subtleIndicativeCheck,
}: CalculateIndicativeProps) {
	if (nonAmnesticIndicativeCheck) {
		return Indicatives[IndicativeKeys.NonAmnestic];
	}

	if (amnesticModerateIndicativeCheck) {
		return Indicatives[IndicativeKeys.AmnesticModerate];
	}

	if (mixedMildIndicativeCheck) {
		return Indicatives[IndicativeKeys.MixedMild];
	}

	if (dysexecutiveIndicativeCheck) {
		return Indicatives[IndicativeKeys.Dysexecutive];
	}

	if (singleDomainIndicativeCheck) {
		return Indicatives[IndicativeKeys.SingleDomain];
	}

	if (subtleIndicativeCheck) {
		return Indicatives[IndicativeKeys.Subtle];
	}

	if (normalIndicativeCheck) {
		return Indicatives[IndicativeKeys.Normal];
	}

	if (assignDCRIndicativeCheck) {
		return Indicatives[IndicativeKeys.AssignDCR];
	}

	return Indicatives[IndicativeKeys.NotAvailable];
}

export function getIndicativeInterpretation(
	measures: MeasuresProps
): IndicativeProps {
	const {
		episodicMemoryMeasures,
		executiveControlMeasures,
		visuoconstructionalMeasures,
		totalImpairedMeasures,
	} = measures;

	const _visuoconstructionalMeasures = visuoconstructionalMeasures ?? -1;
	const _episodicMemoryMeasures = episodicMemoryMeasures ?? -1;
	const _executiveControlMeasures = executiveControlMeasures ?? -1;
	const _totalImpairedMeasures = totalImpairedMeasures ?? -1;

	const normalIndicativeCheck =
		((_totalImpairedMeasures === 0 || _totalImpairedMeasures === 1) &&
			_visuoconstructionalMeasures >= 0 &&
			_episodicMemoryMeasures >= 0 &&
			_executiveControlMeasures >= 0) ||
		(_episodicMemoryMeasures === 0 &&
			_executiveControlMeasures === 0 &&
			_totalImpairedMeasures === -1);

	const subtleIndicativeCheck =
		_totalImpairedMeasures === 2 &&
		((_visuoconstructionalMeasures === 1 &&
			_episodicMemoryMeasures === 1) ||
			(_episodicMemoryMeasures === 1 &&
				_executiveControlMeasures === 1) ||
			(_visuoconstructionalMeasures === 1 &&
				_executiveControlMeasures === 1));

	const singleDomainIndicativeCheck =
		_visuoconstructionalMeasures === 0 &&
		_episodicMemoryMeasures === 2 &&
		_executiveControlMeasures === 0;

	const dysexecutiveIndicativeCheck =
		_executiveControlMeasures === 2 &&
		_visuoconstructionalMeasures === 0 &&
		_episodicMemoryMeasures === 0;

	const mixedMildIndicativeCheck =
		_totalImpairedMeasures === 3 &&
		((_episodicMemoryMeasures === 2 &&
			(_visuoconstructionalMeasures === 1 ||
				_executiveControlMeasures === 1)) ||
			(_executiveControlMeasures === 2 &&
				(_visuoconstructionalMeasures === 1 ||
					_episodicMemoryMeasures === 1)) ||
			(_visuoconstructionalMeasures === 2 &&
				(_episodicMemoryMeasures === 1 ||
					_executiveControlMeasures === 1)) ||
			(_visuoconstructionalMeasures === 1 &&
				_episodicMemoryMeasures === 1 &&
				_executiveControlMeasures === 1));

	const amnesticModerateIndicativeCheck =
		_totalImpairedMeasures === 4 &&
		_episodicMemoryMeasures === 2 &&
		(_executiveControlMeasures === 2 ||
			_visuoconstructionalMeasures === 2 ||
			(_executiveControlMeasures === 1 &&
				_visuoconstructionalMeasures === 1) ||
			(_executiveControlMeasures === 0 &&
				_visuoconstructionalMeasures === 2) ||
			(_executiveControlMeasures === 2 &&
				_visuoconstructionalMeasures === 0));

	const nonAmnesticIndicativeCheck =
		_episodicMemoryMeasures === 0 &&
		_executiveControlMeasures + _visuoconstructionalMeasures >= 4 &&
		_visuoconstructionalMeasures >= 2 &&
		_executiveControlMeasures >= 2;

	const assignDCRIndicativeCheck =
		_visuoconstructionalMeasures < 0 &&
		_episodicMemoryMeasures >= 0 &&
		_executiveControlMeasures >= 0 &&
		_totalImpairedMeasures > -1;

	return calculateIndicatives({
		amnesticModerateIndicativeCheck,
		assignDCRIndicativeCheck,
		dysexecutiveIndicativeCheck,
		mixedMildIndicativeCheck,
		nonAmnesticIndicativeCheck,
		normalIndicativeCheck,
		singleDomainIndicativeCheck,
		subtleIndicativeCheck,
	});
}

export function groupIntoTwos<T>(arr: T[]): T[][] {
	return arr.reduce((result: T[][], _: T, index: number) => {
		if (index % 2 === 0) {
			result.push(arr.slice(index, index + 2));
		}
		return result;
	}, []);
}

export function getBreakdownData(br: DeepBatteryResult | null) {
	const items = parseMetricItems(br);

	const { episodicMemory, executiveControl, visuoconstructional } =
		getMetricsInterpretation(items);

	return {
		episodicMemory,
		executiveControl,
		visuoconstructional,
	};
}
