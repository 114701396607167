import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { P2 } from '../../designSystem/Text';
import { Pause, Play } from '../designSystem/Icons';
import AudioPlayer from '../../../features/audio-player';
import { AudioWidgetProgressBar } from './AudioWidgetProgressBar';
import { AudioWidgetSkipButtons } from './AudioWidgetSkipButtons';
import { formatSecondsToMmSs } from '../../../utils/dateTimeFormatter';

type Props = {
	srcUrl: string;
	fileFormat: string;
	doOnAudioLoadError?: () => void;
};

export const AudioWidget = (props: Props) => {
	const { srcUrl, fileFormat = 'webm' } = props;

	const progressBarRef = useRef<HTMLInputElement>(null);
	const [audioPlayer, setAudioPlayer] = useState<AudioPlayer>();
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [totalDurationInSeconds, setTotalDurationInSeconds] =
		useState<number>(0);
	const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState<number>(0);

	// Create sound player
	useEffect(() => {
		setAudioPlayer(new AudioPlayer(srcUrl, fileFormat));
	}, [fileFormat, srcUrl]);

	useEffect(() => {
		return () => {
			// Unload audio player on unmount
			audioPlayer?.unload();
		};
	}, [audioPlayer]);

	// Track audio progress
	useEffect(() => {
		const interval = setInterval(() => {
			const isAudioPlaying = audioPlayer?.getIsPlaying() ?? false;
			setIsPlaying(isAudioPlaying);
			setElapsedTimeInSeconds(
				audioPlayer?.getElapsedTimeInSeconds() ?? 0
			);
			setTotalDurationInSeconds(audioPlayer?.getDurationInSeconds() ?? 0);
		}, 300);
		return () => {
			clearInterval(interval);
		};
	}, [audioPlayer]);

	// Progress UI update handlers
	const setProgressBarSeekWidth = useCallback(
		(progressPercentage: number) => {
			progressBarRef?.current?.style.setProperty(
				'--seek-before-width',
				`${progressPercentage}%`
			);
		},
		[]
	);

	const getCurrentProgressValue = useCallback(() => {
		if (progressBarRef?.current?.value) {
			return Number(progressBarRef?.current?.value);
		} else {
			return 0;
		}
	}, []);

	const setProgressBarValue = useCallback((value?: number) => {
		if (progressBarRef?.current?.value) {
			const currentProgressValue = Math.floor(value as number) ?? 0;
			progressBarRef.current.value = String(currentProgressValue ?? 0);
		}
	}, []);

	const syncSeekWithProgressBar = useCallback(() => {
		const seekPosition = getCurrentProgressValue();
		audioPlayer?.seekToPosition(seekPosition);
	}, [audioPlayer, getCurrentProgressValue]);

	// Publish Audio Progress
	useEffect(() => {
		const publishAudioProgress = () => {
			const progressPercentage =
				(elapsedTimeInSeconds / totalDurationInSeconds) * 100;
			setProgressBarValue(elapsedTimeInSeconds);
			setProgressBarSeekWidth(progressPercentage);
		};
		publishAudioProgress();
	}, [
		setProgressBarValue,
		setProgressBarSeekWidth,
		audioPlayer,
		elapsedTimeInSeconds,
		totalDurationInSeconds,
	]);

	return (
		<StyledAudioWidgetContainer data-testid='audioWidget'>
			<StyledProgressBarContainer>
				<StyledPlayButton onClick={audioPlayer?.togglePlayPause}>
					{isPlaying ? <Pause /> : <Play />}
				</StyledPlayButton>
				<AudioWidgetProgressBar
					progressBarRef={progressBarRef}
					max={audioPlayer?.getDurationInSeconds() ?? 0}
					min={0}
					onChange={syncSeekWithProgressBar}
				/>
			</StyledProgressBarContainer>
			<StyledControlsContainer>
				{/* current audio time */}
				<P2>{formatSecondsToMmSs(elapsedTimeInSeconds)}</P2>
				{/* audio skip buttons */}
				<AudioWidgetSkipButtons
					onSkipBack={() => {
						audioPlayer?.seekBackward(10);
					}}
					onSkipFwd={() => {
						audioPlayer?.seekForward(10);
					}}
				/>
				{/* duration */}
				<P2>{formatSecondsToMmSs(totalDurationInSeconds)}</P2>
			</StyledControlsContainer>
		</StyledAudioWidgetContainer>
	);
};

const StyledAudioWidgetContainer = styled.div`
	width: 100%;
`;

const StyledProgressBarContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

const StyledPlayButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;
	cursor: pointer;
	padding: 0;
`;

const StyledControlsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 36px;
`;
