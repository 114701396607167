import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DctClockRecording } from '../DctClockRecording';
import { DctClockDrawingScore } from '../dctClockDrawingScore';
import { FormattedSegmentResult } from '../../common';
import { H2 } from '../../../shared/designSystem';
import { Card } from '../../../designSystem/Card';
import { SectionHeading } from '../../DigitalClockAndRecall(DCR)/components/shared/SectionHeading';

export type ClockDrawingProps = {
	clockSegment?: FormattedSegmentResult;
	contentLoaded: () => boolean;
};

export const ClockDrawing = ({
	clockSegment,
	contentLoaded,
}: ClockDrawingProps): JSX.Element | null => {
	const { t } = useTranslation();
	if (!clockSegment) return null;
	return (
		<>
			<StyledSectionTitle>{t`Scores` as string}</StyledSectionTitle>
			<StyledCard>
				<SectionHeading text={t`Clock Drawing` as string} />
				<DctClockDrawingScore dctClockData={clockSegment} />
			</StyledCard>
			<StyledSectionTitle>{t`Recordings` as string}</StyledSectionTitle>
			<StyledRecordingContainer>
				<StyledColumn>
					<DctClockRecording
						title={t`web.dcrReport.recordings.clock.command.title`}
						clockType={'COMMAND'}
						tooltipText={t`web.dcrReport.recordings.clock.command.tooltipText`}
						segmentId={clockSegment.id}
						contentLoaded={contentLoaded}
					/>
				</StyledColumn>
				<StyledColumn>
					<DctClockRecording
						title={t`web.dcrReport.recordings.clock.copy.title`}
						clockType={'COPY'}
						tooltipText={t`web.dcrReport.recordings.clock.copy.tooltipText`}
						segmentId={clockSegment.id}
						contentLoaded={contentLoaded}
					/>
				</StyledColumn>
			</StyledRecordingContainer>
		</>
	);
};

const StyledRecordingContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
  flex-direction: row;
  align-content: center;
  gap: ${spacing.md};
`
);

const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	width: 100%;
`;

const StyledSectionTitle = styled(H2)(
	({ theme: { spacing } }) => `
  margin: ${spacing.lg} 0;
`
);

const StyledCard = styled(Card)(
	({ theme: { spacing } }) => `
	display: inline-block;
	padding: ${spacing.lg};
	min-width: 300px;
	justify-content: center;
`
);
