// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const WarningSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 25'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='WarningSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'WarningSolid'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.4771 5.82001L20.7674 18.3745C21.4302 19.5296 20.597 20.9498 19.2715 20.9498H4.70983C3.40325 20.9498 2.57007 19.5106 3.23283 18.3745L10.5231 5.82001C11.167 4.68386 12.8144 4.68386 13.4771 5.82001ZM12.9184 10.0754C12.9757 10.2285 13.014 10.3815 13.014 10.5536C12.9949 11.0319 12.9566 11.5292 12.9184 12.0074C12.8992 12.3804 12.8753 12.7582 12.8514 13.136C12.8275 13.5138 12.8036 13.8916 12.7845 14.2646C12.7653 14.5132 12.7653 14.7237 12.7653 14.9723C12.7462 15.374 12.4401 15.6801 12.0384 15.6801C11.6367 15.6801 11.3307 15.3932 11.3116 14.9915C11.2829 14.408 11.2494 13.8246 11.2159 13.2412C11.1824 12.6578 11.149 12.0744 11.1203 11.4909C11.1011 11.1849 11.082 10.8788 11.0629 10.5536C11.0629 10.0372 11.3498 9.61633 11.8089 9.48244C12.2489 9.40592 12.7271 9.63546 12.9184 10.0754ZM12.9757 17.3634C12.9949 17.8799 12.5358 18.3198 12.0193 18.3198C11.4837 18.3198 11.0438 17.8799 11.0438 17.3443C11.0438 16.8087 11.4837 16.3687 12.0193 16.3687C12.5549 16.3687 13.014 16.8087 12.9757 17.3634Z'
				fill='current'
			/>
		</svg>
	);
};
