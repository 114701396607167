import {
	Box,
	Collapse,
	Flex,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MutableRefObject, forwardRef } from 'react';

import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { theme } from '../../providers/styleProvider';

interface Props {
	opened?: boolean;
	shouldScroll?: boolean;
	children: React.ReactNode;
	styles?: React.CSSProperties;
	title: React.ReactNode;
	/**
	 * A function that is called every time the accordion button is clicked.
	 */
	onClick?: () => void;
	/**
	 * A function that is called only when the accordion button is clicked to open.
	 */
	onOpen?: () => void;
}

function _Accordion(props: Props, ref: React.ForwardedRef<HTMLDivElement>) {
	const {
		opened: _opened = false,
		shouldScroll = false,
		styles,
		title,
		onClick,
		onOpen,
		children,
	} = props;

	const [rendered, render] = useDisclosure(_opened);
	const [opened, handler] = useDisclosure(_opened, { onOpen: handleOpen });

	function handleOpen() {
		render.open();
		if (shouldScroll) {
			const timer = setTimeout(() => {
				if (ref && (ref as MutableRefObject<HTMLDivElement>).current) {
					(
						ref as MutableRefObject<HTMLDivElement>
					).current.scrollIntoView({ behavior: 'smooth' });
				}
				clearTimeout(timer);
			}, 100);
		}
	}

	function handleClick() {
		if (!opened) {
			onOpen?.();
			handleOpen();
		}

		onClick?.();
		handler.toggle();
	}

	return (
		<Flex
			ref={ref}
			className='accordion'
			style={{
				flexDirection: 'column',
				backgroundColor: theme.colors.gray_90,
				borderRadius: 6,
				...styles,
			}}>
			<UnstyledButton
				py={8}
				px={8}
				className='accordion__title'
				onClick={handleClick}>
				<Group gap={8} wrap='nowrap'>
					<Icon
						icon={opened ? icons.Subtract : icons.Add}
						iconWidth={24}
						iconHeight={24}
						color={theme.colors.blue}
					/>
					<Box style={{ flex: 1 }}>{title}</Box>
				</Group>
			</UnstyledButton>
			<Collapse
				in={opened}
				onTransitionEnd={() => !opened && render.close()}>
				{rendered && (
					<Stack
						className='accordion__content'
						style={{ padding: '0px 12px 12px 40px' }}>
						{children}
					</Stack>
				)}
			</Collapse>
		</Flex>
	);
}

export const Accordion = forwardRef(_Accordion);
