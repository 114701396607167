import { Trans, useTranslation } from 'react-i18next';
import semver from 'semver';
import styled, { useTheme } from 'styled-components';
import ScoreRangeContent from '../../../../common/scoring/ScoreRangeContent';

type ScoringRangeProps = {
	version: string;
};

const DcrScoringRange = ({ version }: ScoringRangeProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	const contentVersion =
		semver.valid(version) && semver.lt(version, '1.3.1')
			? '1.0.0'
			: '1.1.0';

	return (
		<StyledScoringContainer>
			<ScoreRangeContent
				minScore={4}
				maxScore={5}
				scoreColor={theme.color.alertSuccess}>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.cognitivelyUnimpaired.text_${contentVersion}`
					)}
					components={{ a: <span /> }}
				/>
			</ScoreRangeContent>
			<ScoreRangeContent
				minScore={2}
				maxScore={3}
				scoreColor={theme.color.alertWarningText}>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.likelyCognitivelyUnimpaired.text_${contentVersion}`
					)}
					components={{ a: <span /> }}
				/>
			</ScoreRangeContent>
			<ScoreRangeContent
				minScore={0}
				maxScore={1}
				scoreColor={theme.color.alertError}>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.cognitivelyImpaired.text_${contentVersion}`
					)}
					components={{ a: <span /> }}
				/>
			</ScoreRangeContent>
		</StyledScoringContainer>
	);
};

const StyledScoringContainer = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export default DcrScoringRange;
