import React from 'react';
import styled from 'styled-components';
import { H2, P2 } from '../../../../../designSystem/Text';
import { ScoreType } from '../../types';

type ScoreTagProps = {
	showTotalScoreHeader?: boolean;
	isUnanalyzable?: boolean;
	score: ScoreType;
	highlightColor?: string | undefined;
	textColor?: string | undefined;
};
const ScoreTag = (props: ScoreTagProps) => {
	const achievedScore = props?.isUnanalyzable ? '--' : props?.score?.achieved;
	return (
		<ColumnWrapper
			data-testid={'score-tag'}
			style={{ backgroundColor: props?.highlightColor }}>
			{props?.showTotalScoreHeader && (
				<StyledHeading data-testid={'score-tag-header'}>
					{'TOTAL SCORE'}
				</StyledHeading>
			)}
			<StyledValue
				style={{ color: props?.textColor }}
				data-testid={
					'score-tag-value'
				}>{`${achievedScore}/${props?.score?.total}`}</StyledValue>
		</ColumnWrapper>
	);
};

export default ScoreTag;

const ColumnWrapper = styled.div`
	flex-direction: column;
	text-align: center;
	justify-content: center;
`;

const StyledHeading = styled(P2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium}
`
);

const StyledValue = styled(H2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
	text-align: center;
`
);
