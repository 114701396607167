import styled from 'styled-components';
import { ButtonSm } from '../../shared/designSystem';

type CloseButtonPropsType = {
	text: string;
	onClick: () => void;
};

export const CloseButton = (props: CloseButtonPropsType) => {
	return (
		<StyledButtonWrapper>
			<ButtonSm
				text={props?.text}
				width='158px'
				onClick={props?.onClick}
			/>
		</StyledButtonWrapper>
	);
};

const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		justify-content: space-around;
		margin-top: ${spacing.lg};
	`
);
