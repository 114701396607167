/* eslint-disable */
import { dctJSON } from './json';
import { dctStrings } from './dctStrings';
import { dctMath } from './math';
import playButton from './playButton.svg';

function paper(paper_address, section_id) {
	return ``;
}

function player_json_data(test) {
	var omit_fields = {
		metric_map: 1,
		hidden_metric_map: 1,
		metric_alerts: 1,
		report_spec: 1,
	};
	return `
  <script class="player_json_data" language="application/json">
  ${dctJSON.stringifyScriptTag(test._raw, function (key, value) {
		return Object.prototype.hasOwnProperty.call(omit_fields, key)
			? '[REDACTED]'
			: value;
  })}
  </script>
  `;
}

function player_splitinfo(position) {
	return `
    <div class="dct_player_splitinfo" data-splitinfo-position="${position}">&nbsp;</div>
  `;
}

function player_controlbar(params) {
	return `
<div class="dct_player_controlbar controlbar">
  <button class="controlbar_link controlbar_link_playpause"><img class="controlbar_playbutton" src="${playButton}" alt='play button'/></button>
  <div class="controlbar_spacer"></div>
  ${
		params.split
			? `
    <button class="controlbar_link controlbar_link_scissor_left"><var>&#xf060;<var></button>
    <var class="controlbar_scissor">&#xf504;</var>
    <button class="controlbar_link controlbar_link_scissor_right"><var>&#xf061;<var></button>
    <div class="controlbar_spallspacer"></div>
    `
			: ''
  }
  <div class="controlbar_spallspacer"></div>
  <button class="controlbar_link controlbar_link_speed" data-speed="4">4&times;</button>
  <button class="controlbar_link controlbar_link_speed" data-speed="2">2&times;</button>
  <button class="controlbar_link controlbar_link_speed" data-speed="1">1&times;</button>
  <button class="controlbar_link controlbar_link_speed" data-speed=".5">&#xBD;&times;</button>
  <button class="controlbar_link controlbar_link_speed" data-speed=".25">&#xBC;&times;</button>
  <button class="controlbar_link controlbar_link_speed" data-speed=".125">&#x215B;&times;</button>
  ${
		params.zoomable
			? `
    <div class="controlbar_spallspacer"></div>
    <button class="controlbar_link controlbar_link_zoom"><var>&#xF00E;</var></button>
    `
			: ''
  }
</div>
`;
}

export function player_timebar_segments(
	section,
	segments,
	thumb_width,
	thumb_height,
	split_mode
) {
	const total_time = section.getDuration() || 1;
	return segments
		.map((seg, segindex) => {
			const width =
				(100 *
					(seg.getStopTime_Section() - seg.getStartTime_Section())) /
				total_time;
			const left = 100 * (seg.getStartTime_Section() / total_time);
			return `
    <div
    class="timebar_segment ${
		seg.isDown() ? 'timebar_segment_on' : 'timebar_segment_off'
	} ${!split_mode && 'timebar_segment_clickable'}"
    style="left: ${left.toFixed(4)}%; width: ${width.toFixed(4)}%"
    data-segment-index="${segindex}">

    <div class="timebar_segment_detail">
      <div class="timebar_segment_thumb">
        ${
			seg.isDown()
				? `<img class="timebar_thumb" src="${seg.getThumbnail(
						thumb_width,
						thumb_height
				  )}">` //eslint-disable-line
				: ''
		}
      </div>
     ${
			split_mode && !seg.isDown()
				? `<div class="timebar_segment_scissor"></div>`
				: ''
		}
      </div>
    </div>
  </div>`;
		})
		.join(' ');
}

function player_drawstack(section, params) {
	//TODO, hard numbers here, need to store them somewhere.
	//TODO, refactor elsewhere, this should not be here!

	var drawstack_style;
	var paper_style;
	var bounds = section.getPageBounds();
	var height_presets = {
		xxl: 800,
		xl: 512,
		l: 480,
		xm: 380,
		m: 320,
		s: 256,
		xs: 192,
	};
	var max_widths = { xxl: 1200, xs: 400, __default: 800 };
	if (params.size === 'fill') {
		var height_ratio =
			(100 * bounds.getHeight()) / (bounds.getWidth() || 1);
		drawstack_style =
			'width: 100%; padding-bottom: ' + height_ratio.toFixed(1) + '%;';
		paper_style = 'width: 100%; height: 100%';
	} else {
		var max_width = params.narrow
			? max_widths['xs']
			: max_widths[params.size] || max_widths['__default'];
		var max_height = height_presets[params.size] || height_presets['xl'];
		var size = dctMath.fitRectangle(
			bounds.getWidth(),
			bounds.getHeight(),
			max_width,
			max_height,
			true
		);

		drawstack_style =
			'min-width: ' + size.width + 'px;  height: ' + size.height + 'px;';
		paper_style =
			'width: ' + size.width + 'px; height: ' + size.height + 'px;';
	}
	return `
<div class="dct_player_drawstack" style="${drawstack_style}" data-draw-mode="${
		params.draw_mode || 'sketch'
	}">
  <div class="drawstack_paper" style="${paper_style}">${paper(
		section.getPageAddress(),
		section.getId()
	)}</div>
  <canvas class="drawstack_canvas"></canvas>
  <div class="drawstack_nib dct_player_nib"></div>

  ${
		params.microtitle
			? `<div class="microtitle"> ${dctStrings.section_titles(
					section.getId()
			  )}</div>` //eslint-disable-line
			: ''
  }
  ${
		params.microcontrols
			? `<div class="dct_player_microcontrolbar microcontrolbar">
      <button class="microcontrolbar_play"><var>&nbsp;</var></button>
      <span class="microcontrolbar_time">&nbsp;<span>
    </div>`
			: ''
  }
</div>
  `;
}

export function player_drawstack_annotation(
	arg_stroke_num,
	arg_stroke,
	arg_pause
) {
	return `
<div class="drawstack_annotation">
  <span class="drawstack_annotation_num">${dctStrings.player_stroke_num(
		arg_stroke_num
  )}</span>
  <span class="drawstack_annotation_time">${dctStrings.player_stroke_time(
		arg_stroke.getDuration()
  )}</span>
  <span class="drawstack_annotation_pause">
    ${
		arg_pause
			? dctStrings.player_stroke_pause(arg_pause.getDuration())
			: dctStrings.player_stroke_end()
	}
  </span>
  </div>
`;
}

export function section_players(title, test, params = {}) {
	return `
  <!-- Sections -->
  <section class="dct_test_data_wrapper">
  
    <!-- Section header -->
    ${title && `<h2>${title}</h2>`}
  
    <!-- Player -->
    ${test.getSections().map((section) => player_object(section, params))}
  
    <!-- Data -->
    ${player_json_data(test)}
  </section>
  
  `;
}

export function single_player(
	section_name,
	test,
	params = {
		size: 's',
	}
) {
	return `
  <!-- Sections -->
  <section class="dct_test_data_wrapper">
  
    <!-- Player -->
    ${test
		.getSections()
		.filter((v) => v.getId() == section_name)
		.map((section) => player_object(section, params))}
  
    <!-- Data -->
    ${player_json_data(test)}
  </section>
  
  `;
}

export function whole_test_player(test, params = {}, add_metadata_section) {
	return `
  <div class="dct_test_data_wrapper player_list">
    <!-- Player -->
    ${player_object(test.getCombinedSection(), params)}
  
    ${
		add_metadata_section &&
		(() => {
			const metadata_section = test.getSectionById('metadata');
			return metadata_section
				? player_object(metadata_section, params)
				: '';
		})()
	}
  
    <!-- Data -->
    ${player_json_data(test)}
  </div>
  
  `;
}

function section_sketch(title, help, section, params) {
	return `
  <div>
    <h2><mark dct-help="${help}">${title}</mark></h2>
    ${
		section
			? player_object(section, params)
			: dctStrings.report_missing_sketch()
	}
  </div>
  `;
}

export function section_player_split(test, section_defs = [], params = {}) {
	return `
  <section class="dct_test_data_wrapper pagesplit pagesplit_equal hide_patient">
    ${player_json_data(test)}
    ${section_defs.map((section_def) =>
		section_sketch(
			section_def.title,
			section_def.glossary,
			section_def.section,
			params
		)
	)}
  </section>
  `;
}

function player_object(section, params = {}) {
	return `
    <div class="dct_player ${params.zoomable && 'dct_player_zoomable'} ${
		params.stretch && 'dct_player_stretch'
	}"
      ${params.split && `data-split-mode="1"`}
      data-section-id="${section.getId()}">

      
      
      ${
			params.split
				? (function () {
						params.draw_mode = 'split_before';
						const html1 = `<div>${player_splitinfo(
							'before'
						)} ${player_drawstack(section, params)}</div>`;
						params.draw_mode = 'split_before';
						const html2 = `<div>${player_splitinfo(
							'after'
						)} ${player_drawstack(section, params)}</div>`;
						return `<div class="drawstack_split"> ${
							html1 + html2
						}</div>`;
						//eslint-disable-next-line
				  })()
				: player_drawstack(section, params)
		}
      ${params.timebar !== false && player_timebar(section, params)}
      ${params.controlbar !== false && player_controlbar(params)}
    </div>
  `;
}

function player_timebar(section, params) {
	return `
  <div class="dct_player_timebar timebar ${
		params.split && 'dct_player_timebar_split'
  }">
    <div class="timebar_track">
      <div class="timebar_arrow timebar_arrow_down"><span class="timebar_time"></span></div>
      <div class="timebar_ghost_arrow"></div>
    </div>
    <div class="timebar_segments"></div>
    <div class="timebar_bar"></div>
    <div class="timebar_clickcatcher"></div>
  </div>
  `;
}

export function loading_placeholder(inactive) {
	return `<div class="area_loading ${!inactive && 'active'}"></div>`;
}
