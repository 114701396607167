import { isEmpty } from 'lodash';
import { useGetEpsomJsonData } from '../../../../../../../hooks/useGetEpsomJsonData';
import { EpsomScores } from '../../../../../../../types/epsomTypes';
import { FormattedSegmentResult } from '../../../../../common';
import { ConfidenceScoreCard } from './ConfidenceScore/ConfidenceScoreCard';
import { RankedAndRatedAnswers } from './RankedAndRatedAnswers/RankedAndRatedAnswers';
import { useEffect, useState } from 'react';
import {
	generateEpsomScores,
	mergeEpsomScores,
} from 'components/report/Epsom/common/helpers/epsomScorer';
import { LoadingDots } from 'components/shared/LoadingDots';

type EpsomRankingRatingProps = {
	segmentResult: FormattedSegmentResult;
};

function EpsomRankingRating({
	segmentResult,
}: Readonly<EpsomRankingRatingProps>) {
	const segmentResultId = segmentResult.id;
	const { loading, data, error } = useGetEpsomJsonData({
		segmentResultId,
	});
	const [epsomDataWithScore, setEpsomDataWithScore] = useState<EpsomScores>();
	useEffect(() => {
		if (data) {
			const epsomDataWithScore = isEmpty(segmentResult.metricItems) //For backwards compat ie: baseline epsom tests from before engine was released
				? generateEpsomScores(data)
				: mergeEpsomScores(data, segmentResult);

			setEpsomDataWithScore(epsomDataWithScore);
		}
	}, [data, segmentResult]);

	if (loading) return <LoadingDots />;

	if (error) return <p>{error + ''}</p>;

	if (!data || !epsomDataWithScore) return null;
	return (
		<>
			<ConfidenceScoreCard epsomScores={epsomDataWithScore} />
			<br />
			<RankedAndRatedAnswers epsomScores={epsomDataWithScore} />
		</>
	);
}

export { EpsomRankingRating };
