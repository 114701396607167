import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Label } from './Label';
import { ErrorMessage, useField } from 'formik';
import { FormElementProps } from '../../../../types';
import { P2 } from '../../designSystem/TextComponents';
import { CheckboxLabel } from './CheckboxLabel';

export const LinusInput = <T,>(props: FormElementProps<T>): JSX.Element => {
	const [field, meta, { setError, setTouched }] = useField(props.name);
	const errorRef = useRef<string | undefined>();
	useEffect(() => {
		if (props.error && !meta.error) {
			errorRef.current = props.error;
			//error passed in from above
			setError(props.error);
			if (!meta.touched) setTouched(true);
		} else if (
			!props.error &&
			meta.error &&
			errorRef.current === meta.error
		) {
			//error cleared from above
			setError(undefined);
			errorRef.current = undefined;
		}
	}, [props.error, meta.error, meta.touched, setTouched, setError]);
	const showLabel = props.showLabel === false ? props.showLabel : true;
	return (
		<StyledContainer {...props}>
			{props.type === 'checkbox' || props.type === 'radio' ? (
				<CheckboxLabel>
					<Input {...props} error={meta.error} />
					{props.label || props.placeholder}
				</CheckboxLabel>
			) : (
				<>
					{showLabel && <Label {...props} value={field.value} />}
					<Input
						{...props}
						value={field.value}
						error={meta.touched && meta.error}
					/>
					{props.subtext && !(meta.touched && meta.error) ? (
						<StyledSubtext>{props.subtext}</StyledSubtext>
					) : null}
				</>
			)}
			<ErrorMessage name={field.name}>
				{(msg) => (
					<StyledErrorText data-id={`input-error-${props.name}`}>
						{msg}
					</StyledErrorText>
				)}
			</ErrorMessage>
		</StyledContainer>
	);
};

const StyledContainer = styled.div<{ width?: string; height?: string }>(
	({ width, height }) => `
		position: relative;
		top: 0;
		margin: 16px 0;
		width: ${width ? width : '317px'};
		height: ${height ? height : '48px'};
	`
);

const StyledErrorText = styled(P2)(
	({ theme: { spacing, color } }) => `
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${color.formError};
	padding: ${spacing.xs} 0 0 ${spacing.md};
	font-style: italic;
	`
);

const StyledSubtext = styled(P2)(
	({ theme: { spacing, color } }) => `
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${color.formText};
	padding: ${spacing.xs} 0 0 ${spacing.md};
	font-style: italic;
	`
);
