import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Participant } from 'api/participant';

import { icons } from '../../../enums/icons';
import { LinusModal } from '../../shared/LinusModal';
import { EditParticipantForm } from '../EditParticipantForm';
import { ParticipantInfoDetails } from '../ParticipantInfoDetails';
import { participantStrings } from '../participantStrings';

type Props = {
	data?: Participant;
};

const ParticipantInfo = ({ data }: Props): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [isEditParticipantModalOpen, setIsEditParticipantModalOpen] =
		useState(false);

	const toggleEditModal = () => {
		setIsEditParticipantModalOpen(!isEditParticipantModalOpen);
	};

	if (!data) {
		return null;
	}

	return (
		<>
			{isEditParticipantModalOpen && (
				<LinusModal
					title={t(participantStrings['participantModalTitleEdit'])}
					onClose={toggleEditModal}
					titleIcon={icons.UserSolid}
					titleIconColor={theme.color.iconAddUserSolid}>
					<EditParticipantForm
						participantInfo={data}
						onCancel={toggleEditModal}
					/>
				</LinusModal>
			)}
			<ParticipantInfoDetails
				participantInfo={data}
				toggleEditModal={toggleEditModal}
			/>
		</>
	);
};
export { ParticipantInfo };
