import * as yup from 'yup';
import i18n from '../../i18n';

export type LoginModel = {
	username: string;
	password: string;
};

export const getModel = (): LoginModel => {
	return { username: '', password: '' };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validationSchema = () => {
	return yup.object().shape({
		username: yup
			.string()
			.email(i18n.t('web.authErrors.invalidEmail'))
			.required(i18n.t('web.authErrors.emailRequired')),
		password: yup
			.string()
			.required(i18n.t('web.authErrors.passwordRequired')),
	});
};
