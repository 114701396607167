import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
	DctClockCompositeScales,
	DctClockScoreInfo,
	DctClockScoreProgressBar,
} from '../DctClockSubscore';
import { MetricHash } from '../../metrics';
import { LinusModal } from '../../../shared/LinusModal';
import { P1 } from '../../../designSystem/Text';

type FormattedSegmentResults = {
	id: string;
	rawDataUrl?: string;
	name: string;
	segmentType: string;
	metricItems: MetricHash;
};

export type DctScoreClassification =
	| 'outside'
	| 'indeterminate'
	| 'inside'
	| 'unanalyzable';

export type DctClockDrawingScoreProps = {
	dctClockData?: FormattedSegmentResults;
};

const DctClockDrawingScore = ({
	dctClockData,
}: DctClockDrawingScoreProps): JSX.Element | null => {
	const [showCompositeScales, setShowCompositeScales] = useState(false);
	const { t } = useTranslation();
	if (!dctClockData) {
		return null;
	}
	const metricData = dctClockData.metricItems;
	const score = Math.floor(Number(metricData['DCTScore']?.value));
	const classification = metricData['DCTClassificationString']
		?.value as DctScoreClassification;

	const toggleCompositeScalesModalVisibility = () => {
		setShowCompositeScales(!showCompositeScales);
	};

	return (
		<StyledContainer>
			{classification !== 'unanalyzable' ? (
				<DctClockScoreProgressBar
					dctScoreClassification={classification}
					score={score}
					radius={60}
				/>
			) : (
				<UnanalyzableTextContainer
					data-testid={'unanalyzable-text-container'}>
					<StyledDash>{'--'}</StyledDash>
					<StyledTextContainer>
						<Trans i18nKey='web.dcrReport.subscores.DCTClock.requiresClinicianReview' />
					</StyledTextContainer>
				</UnanalyzableTextContainer>
			)}
			<StyledScoreDetails>
				<DctClockScoreInfo dctScoreClassification={classification} />
				<StyledLinkWrapper
					data-testid='compositeScalesButton'
					onClick={toggleCompositeScalesModalVisibility}>
					<Trans i18nKey='web.report.DCTClock.subscore.info.compositeScalesButton.text' />
				</StyledLinkWrapper>
				{showCompositeScales && (
					<LinusModal
						overflow='scroll'
						title={t`web.report.DCTClock.subscore.info.compositeScalesModal.title`}
						onClose={toggleCompositeScalesModalVisibility}>
						<DctClockCompositeScales
							metricData={metricData}
							onClose={toggleCompositeScalesModalVisibility}
						/>
					</LinusModal>
				)}
			</StyledScoreDetails>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.md};
	margin-bottom: ${spacing.lg};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`
);

const StyledLinkWrapper = styled.a(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.md};
	display: block;
	@media print {
		display: none;
	}
`
);

const UnanalyzableTextContainer = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.lg};
	margin-bottom: ${spacing.xxxl};
	display: flex;
	flex-direction: column;
`
);

const StyledTextContainer = styled(P1)(
	({ theme: { weight, color } }) => `
	margin: auto;
	color: ${color.bodyTextSecondary};
	font-weight: ${weight.bold};
	text-align: center;
`
);

const StyledDash = styled(P1)(
	({ theme: { spacing, weight, color, fontSize } }) => `
	margin-top: ${spacing.md};
	margin-bottom: ${spacing.md};
	color: ${color.bodyTextSecondary};
	font-size: ${fontSize._32};
	font-weight: ${weight.bold};
	text-align: center;
`
);

const StyledScoreDetails = styled.div`
	width: 100%;
`;

export { DctClockDrawingScore };
