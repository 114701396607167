import { sendEventData } from '../../../analytics/amplitude';
import { t } from '../../../i18n';
import { AnalyticsAction } from '../../../generated/graphql';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';

type TermsOfUseLinkProps = {
	termsOfUseUrl?: string;
	text?: string;
};

const TermsOfUseLink = ({
	termsOfUseUrl = 'https://linushealth.com/terms-of-use',
	text = t`web.shared.terms` as string,
}: TermsOfUseLinkProps): JSX.Element => {
	return (
		<ExternalLinkWithIcon
			text={text}
			href={termsOfUseUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedPrivacyPolicy,
				})
			}
		/>
	);
};

export { TermsOfUseLink };
