import * as yup from 'yup';
import i18n from '../../i18n';

export type CreateAccountModel = {
	firstName: string;
	lastName: string;
	password: string;
	repeatPassword: string;
	email: string;
};

export const getModel = (params: CreateAccountModel): CreateAccountModel => ({
	firstName: params.firstName,
	lastName: params.lastName,
	password: '',
	repeatPassword: '',
	email: params.email,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validationSchema = () => {
	return yup.object().shape({
		firstName: yup
			.string()
			.required(i18n.t('web.shared.forms.schemas.required')),
		lastName: yup
			.string()
			.required(i18n.t('web.shared.forms.schemas.required')),
		email: yup
			.string()
			.email(i18n.t('web.authErrors.invalidEmail'))
			.required(i18n.t('web.authErrors.emailRequired')),
		password: yup
			.string()
			.min(8, i18n.t('web.authErrors.passwordTooShort'))
			.matches(/[a-z]/, i18n.t('web.password.lowercaseRequired'))
			.matches(/[A-Z]/, i18n.t('web.password.uppercaseRequired'))
			.matches(/\d/, i18n.t('web.password.numberRequired'))
			.matches(
				/[$&+,~_`:#;=?[\]@|{}'<>".^*()%!/\\-]/,
				i18n.t('web.authErrors.passwordOneSpecialChar')
			)
			.required(i18n.t('web.authErrors.passwordRequired')),
		repeatPassword: yup
			.string()
			.required(i18n.t('web.authErrors.passwordRequired'))
			.oneOf(
				[yup.ref('password'), null],
				i18n.t('web.authErrors.passwordNotMatching')
			),
	});
};
