import React from 'react';
import { Link } from '../../../../../shared/designSystem';

type ScoringWidgetLinkProps = {
	text: string;
	onClick: () => void;
};
const ScoringWidgetLink = (props: ScoringWidgetLinkProps) => {
	return (
		<Link
			style={{
				cursor: 'pointer',
			}}
			onClick={props?.onClick}
			data-testid={`scoring-widget-link`}>
			{props?.text}
		</Link>
	);
};

export default ScoringWidgetLink;
