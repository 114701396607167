import { useCallback, useContext } from 'react';

import { FeatureType, useGetOrganization } from 'api/organization';
import { UserContext } from '../context/UserContext';

type GetOrgEntitlementsType = {
	hasFeatureEntitlement: (featureType: FeatureType) => boolean;
};

const useGetOrgEntitlements = (): GetOrgEntitlementsType => {
	const { currentUser } = useContext(UserContext);
	const { data: organizationData } = useGetOrganization(
		currentUser?.organizationId
	);

	const hasFeatureEntitlement = useCallback(
		(featureType: FeatureType) => {
			return (
				organizationData?.preferences?.features?.some(
					(feature) => feature === featureType
				) ?? false
			);
		},
		[organizationData?.preferences?.features]
	);

	return { hasFeatureEntitlement };
};

export { useGetOrgEntitlements };
