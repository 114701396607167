import { Trans, useTranslation } from 'react-i18next';
import { theme, Typography } from '@lh/eng-web-mosaic-common';

type RcrInterpretationProps = {
	interpretationTransKey: string;
};

export function RcrInterpretation(props: RcrInterpretationProps) {
	const { t } = useTranslation();
	return (
		<Typography.P2 style={{ fontWeight: theme.weight.medium }}>
			<Trans
				i18nKey='web.report.verbalFluency.reviewRequired.rcr'
				components={{
					b: <strong />,
				}}
			/>{' '}
			{t('web.report.verbalFluency.reviewRequired.couldNotBeScored')}{' '}
			{t(props?.interpretationTransKey)}
		</Typography.P2>
	);
}
