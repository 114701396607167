// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const NonModifiableRiskFactorLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='NonModifiableRiskFactorLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'NonModifiableRiskFactorLHQ'}</title>
			<rect x='0.5' width='32' height='32' rx='16' fill='#F2F1F3' />
			<path
				d='M16.8466 12.412C16.8995 12.553 16.9348 12.6941 16.9348 12.8528C16.9172 13.2936 16.8819 13.7521 16.8466 14.1929C16.829 14.5367 16.807 14.885 16.7849 15.2332C16.7629 15.5815 16.7408 15.9297 16.7232 16.2736C16.7056 16.5028 16.7056 16.6967 16.7056 16.926C16.6879 17.2963 16.4058 17.5784 16.0355 17.5784C15.6652 17.5784 15.3831 17.3139 15.3655 16.9436C15.339 16.4058 15.3082 15.868 15.2773 15.3302C15.2464 14.7924 15.2156 14.2546 15.1891 13.7168C15.1715 13.4347 15.1539 13.1525 15.1362 12.8528C15.1362 12.3767 15.4007 11.9888 15.8239 11.8653C16.2295 11.7948 16.6703 12.0064 16.8466 12.412Z'
				fill='#676671'
			/>
			<path
				d='M16.8995 19.1301C16.9172 19.6062 16.494 20.0118 16.0179 20.0118C15.5242 20.0118 15.1186 19.6062 15.1186 19.1125C15.1186 18.6188 15.5242 18.2132 16.0179 18.2132C16.5116 18.2132 16.9348 18.6188 16.8995 19.1301Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.3334 16.0001C25.3334 21.1547 21.1547 25.3334 16.0001 25.3334C10.8454 25.3334 6.66675 21.1547 6.66675 16.0001C6.66675 10.8454 10.8454 6.66675 16.0001 6.66675C21.1547 6.66675 25.3334 10.8454 25.3334 16.0001ZM24.2964 16.0001C24.2964 20.582 20.582 24.2964 16.0001 24.2964C11.4182 24.2964 7.70379 20.582 7.70379 16.0001C7.70379 11.4182 11.4182 7.70379 16.0001 7.70379C20.582 7.70379 24.2964 11.4182 24.2964 16.0001Z'
				fill='#676671'
			/>
		</svg>
	);
};
