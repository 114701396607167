import { Typography } from '@lh/eng-web-mosaic-common';
import { Divider } from '@mantine/core';
import styled from 'styled-components';

export const Container = styled.div(
	({ theme: { colors } }) => `
    display: flex;
    flex: 1;
    border: 1px solid ${colors.gray_40};
    border-radius: 10px;
`
);

export const ClassificationContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 500px;
`;

export const MeasuresHeader = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	justify-content: space-between;
	padding: 20px 34px;
    background-color: ${colors.gray_70};
    border-top-left-radius: 10px;
    border-bottom: 1px solid ${colors.gray_40};
`
);

export const ClassificationHeader = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 16px;
	padding-top: 20px;
	padding-bottom: 20px;
    background-color: ${colors.gray_70};
    border-top-right-radius: 10px;
    border-bottom: 1px solid ${colors.gray_40};
`
);

export const ClassificationContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MeasuresContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MeasuresContainer = styled.div<{ $backgroundColor?: string }>(
	({ theme: { colors }, $backgroundColor }) => `
	display: flex;
	flex-direction: column;
	padding: 12px 34px;
    border-bottom: 1px solid ${colors.gray_60};
    background-color: ${$backgroundColor};
`
);

export const NonAmnesticMeasuresContainer = styled.div<{
	$backgroundColor?: string;
}>(
	({ $backgroundColor }) => `
		display: flex;
		flex-direction: column;
		padding: 12px 34px;
    	background-color: ${$backgroundColor};
		border-bottom-left-radius: 8px;
`
);

export const NormalMeasuresContent = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const MeasuresSeparator = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 0px;
	width: 100%;
`;

export const MeasureCell = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ExpandedMeasureCellContainer = styled.div<{
	$width?: number | string;
}>(
	({ $width = '100%' }) => `
	    display: flex;
	    width: ${$width};
`
);
export const ExpandedMeasureCellContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const ExpandedMeasureCell = styled.div(
	({ theme: { colors } }) => `
        display: flex;
        justify-content: center;
        background-color: ${colors.gray_70};
        width: 100%;
        border-radius: 8px;
        padding: 4px 0;
    `
);

export const ExpandedMeasureCellLabel = styled(Typography.P1)(
	({ theme: { colors } }) => `
        color: ${colors.gray_10};
    `
);

export const MeasuresStyledDivider = styled(Divider)`
	width: 100%;
`;

export const MeasuresDividerText = styled(Typography.P1)(
	({ theme: { colors } }) => `
        color: ${colors.gray_30};
	    padding: 0 10px;
`
);
