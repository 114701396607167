import { AuthProvider } from '../AuthProvider';
import { SessionTimeoutProvider } from '../SessionTimeoutProvider/SessionTimeoutProvider';
import { UserContextProvider as UserContextProviderCognito } from './UserContextProvider.cognito';
import { UserContextProvider as UserContextProviderSSO } from './UserContextProvider.sso';

type UserContextProviderProps = {
	children: React.ReactNode;
};

export function UserContextProvider({ children }: UserContextProviderProps) {
	if (AuthProvider.isCognito) {
		return (
			<UserContextProviderCognito>
				<SessionTimeoutProvider>{children}</SessionTimeoutProvider>
			</UserContextProviderCognito>
		);
	}

	return (
		<UserContextProviderSSO>
			<SessionTimeoutProvider>{children}</SessionTimeoutProvider>
		</UserContextProviderSSO>
	);
}
