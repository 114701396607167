import { P1 } from '../../../designSystem/Text';
import { ReactNode } from 'react';
import styled from 'styled-components';

type ScoreRangeContentProps = {
	minScore: number;
	maxScore: number;
	scoreColor: string;
	children: ReactNode;
};

const ScoreRangeContent = ({
	minScore,
	maxScore,
	scoreColor,
	children,
}: ScoreRangeContentProps): JSX.Element => {
	return (
		<StyledContentRow>
			<StyledColorRange>
				<StyledScoreColor scoreColor={scoreColor} />
				<StyledScoreText>{`${minScore}-${maxScore}`}</StyledScoreText>
			</StyledColorRange>
			<P1>{children}</P1>
		</StyledContentRow>
	);
};

const StyledScoreColor = styled.div<{ scoreColor: string }>(
	({ scoreColor }) => `
		background-color: ${scoreColor};
		border-radius: 50%;
		width: 8px;
		height: 8px;
		border-color: ${scoreColor}
	`
);

const StyledScoreText = styled(P1)(
	({ theme: { weight } }) => `
		font-weight: ${weight.medium};
	`
);

const StyledContentRow = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		gap: ${spacing.sm};
	`
);

const StyledColorRange = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

export default ScoreRangeContent;
