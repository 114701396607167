import semver from 'semver';

export function getScoreBreakdownTitleTransKey(
	scoreValue: number,
	algorithmVersion = ''
): string {
	let key = '';

	if (inRange(scoreValue, 0, 1)) {
		key = `cognitivelyImpaired`;
	}
	if (inRange(scoreValue, 2, 3)) {
		key = `likelyCognitivelyUnimpaired`;
	}
	if (inRange(scoreValue, 4, 5)) {
		key = `cognitivelyUnimpaired`;
	}

	function inRange(value: number, from: number, to: number): boolean {
		return value >= from && value <= to;
	}

	const rootKey = `web.dcrReport.overview.scoringDescription`;
	const contentVersion = getContentVersion(algorithmVersion);

	return `${rootKey}.${key}.text_${contentVersion}`;
}

function getContentVersion(version: string): string {
	if (semver.valid(version) && semver.lt(version, '1.3.1')) {
		return '1.0.0';
	}

	return '1.1.0';
}
