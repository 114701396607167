import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import { LinusSearchBar } from '../../shared/inputs/LinusSearchBar';
import { OrganizationStatus } from '../../../generated/graphql';
import SingleOrganization from './SingleOrganization';

const SEARCH_DEBOUNCE_DELAY = 200;

const FUSE_OPTIONS = {
	keys: ['name'],
	threshold: 0,
};

export interface Organization {
	id: string;
	name: string;
	status: OrganizationStatus;
	isDefault?: boolean;
}

interface SwitchOrganizationProps {
	organizations: Organization[];
	onSwitchOrgListItemClick: (orgId: string) => void;
	onSetDefault: (orgId: string) => void;
}

function getLastLiveIndex(orderedOrganizations: Organization[]) {
	const testIndex = (() => {
		let lastLiveIndex = -1;
		for (let i = orderedOrganizations.length - 1; i > -1; i--) {
			if (orderedOrganizations[i].status === OrganizationStatus.Live) {
				lastLiveIndex = i + 1;
				break;
			}
		}
		return lastLiveIndex;
	})();
	const hasTestOrgs = !!orderedOrganizations.find(
		(element) => element.status === OrganizationStatus.Test
	);
	return !hasTestOrgs || testIndex < 0 ? -1 : testIndex - 1;
}

export const SwitchOrganization = ({
	organizations,
	onSwitchOrgListItemClick,
	onSetDefault,
}: SwitchOrganizationProps): JSX.Element => {
	const { t } = useTranslation();
	const [filteredOrgs, setFilteredOrgs] = useState(organizations);

	const fuseRef = useRef<Fuse<Organization>>(
		new Fuse(organizations, FUSE_OPTIONS)
	);

	const handleSearchChange = (items: Organization[]) => {
		setFilteredOrgs(items);
	};

	const filterOrgs = (input: string, candidate: Organization) => {
		if (!input) return true;
		return !!fuseRef.current
			.search(input)
			.find((match) => match.item.id === candidate.id);
	};

	const lastLiveIndex = getLastLiveIndex(filteredOrgs);

	return (
		<StyledSwitchOrganization>
			<StyledSearchBarContainer>
				<LinusSearchBar
					placeholder={t`research.switchStudySite.searchPlaceholder`}
					searchableList={organizations}
					filterFunction={filterOrgs}
					onChange={debounce(
						handleSearchChange,
						SEARCH_DEBOUNCE_DELAY
					)}
					setIsFilteringData={() => null}
					searchComponentWidth='100%'
				/>
			</StyledSearchBarContainer>

			<StyledDivider />

			<OrganizationsContainer>
				{!filteredOrgs.length ? (
					<StyledNoMatch>{t`research.switchStudySite.noSearchResults`}</StyledNoMatch>
				) : (
					filteredOrgs.map((organization, index) => (
						<SingleOrganization
							key={organization.id}
							organization={organization}
							onSelect={onSwitchOrgListItemClick}
							onSetDefault={onSetDefault}
							bottomSeparator={
								organization.isDefault ||
								lastLiveIndex === index
							}
						/>
					))
				)}
			</OrganizationsContainer>
		</StyledSwitchOrganization>
	);
};

const StyledNoMatch = styled.div(
	({ theme: { spacing, fontSize, color } }) => css`
		color: ${color.switchOrgNoResult};
		margin-top: ${spacing.lg};
		font-size: ${fontSize._16};
	`
);

const StyledSwitchOrganization = styled.div`
	@media (max-width: 600px) {
		width: 100%;
	}
	@media (min-width: 600px) {
		min-width: 600px;
	}
	height: 535px;
`;

const StyledSearchBarContainer = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.md};
	`
);

const StyledDivider = styled.hr(
	({ theme: { spacing, color } }) => css`
		color: ${color.infoSeparator};
		margin-top: ${spacing.lg};
		margin-bottom: 0;
		// couter margin to take full modal width
		margin-left: -32px;
		margin-right: -32px;
	`
);

const OrganizationsContainer = styled.div`
	max-height: 90%;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 2px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
