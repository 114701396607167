import styled from 'styled-components';
import { Space } from '@mantine/core';
import { P1 } from '../../../../../../../shared/designSystem';
import { AnswerRow, AnswerRowProps } from './AnswerRow';
import { RankedAndRatedAnswers } from '../../RankedAndRatedAnswers/RankedAndRatedAnswers';
import { Caption } from '../../../../../../../designSystem/Text';
import {
	AlertShieldOutline,
	AlertShieldSolid,
} from '../../../../../../../shared/designSystem/Icons';
import { theme } from '../../../../../../../../providers/styleProvider';
import { useTranslation } from 'react-i18next';

type Props = { answers: AnswerRowProps[] };

const RatingsTable = (props: Props) => {
	const rows = props.answers;
	const { t } = useTranslation();

	return (
		<RankedAndRatedAnswers
			sectionHeaderProps={{
				cardHeading: t(
					'web.report.longEpsom.changeInConfidence.rankedRated.title'
				),
				cardHeadingDescriptions: [
					<>
						<CenteredDiv
							data-testid={'header-description-svg-icon'}>
							<AlertShieldSolid
								height={12}
								width={12}
								color={theme.color.alertError}
							/>
						</CenteredDiv>
						<Space w={4} />
						{/* Header Description text */}
						<StyledCaption
							style={{
								color: theme.color.textAlert,
								alignContent: 'center',
							}}
							data-testid={'header-description-text'}>
							{t(
								'web.report.longEpsom.changeInConfidence.rankedRated.badges.decline'
							)}
						</StyledCaption>
					</>,
					<Space w={10} />,
					<>
						<CenteredDiv
							data-testid={'header-description-svg-icon'}>
							<AlertShieldOutline
								height={12}
								width={12}
								color={theme.color.alertSuccessText}
							/>
						</CenteredDiv>
						<Space w={4} />
						{/* Header Description text */}
						<StyledCaption
							style={{
								color: theme.color.graphGreenDarkest,
								alignContent: 'center',
							}}
							data-testid={'header-description-text'}>
							{t(
								'web.report.longEpsom.changeInConfidence.rankedRated.badges.increase'
							)}
						</StyledCaption>
					</>,
				],
				subheading: t(
					'web.report.longEpsom.changeInConfidence.rankedRated.subtitle'
				),
			}}
			renderHeader={() => <TableHeader />}
			renderRows={() =>
				rows.map((row, i) => (
					<AnswerRow key={`answer-row-key-${i}`} {...row} />
				))
			}
		/>
	);
};

export { RatingsTable };

function TableHeader() {
	const { t } = useTranslation();
	return (
		<StyledRow>
			<div style={{ flex: 1 }}>
				<StyledP1Medium data-testid='col1-header'>
					{t('web.report.longEpsom.columns.participantAnswer')}
				</StyledP1Medium>
			</div>
			<div
				style={{
					flex: 1,
					textAlign: 'start',
				}}>
				<StyledP1Medium data-testid='col2-header'>
					{t('web.report.longEpsom.columns.answer')}
				</StyledP1Medium>
			</div>
			<div style={{ flex: 1 }}>
				<StyledP1Medium data-testid='col3-header'>
					{t('web.report.longEpsom.columns.rate')}
				</StyledP1Medium>
			</div>
		</StyledRow>
	);
}

const StyledP1Medium = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
  text-transform: uppercase;
    
`
);

const StyledRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: ${spacing.sm};    
`
);

const CenteredDiv = styled.div(
	() => `
    display: flex;
    justify-content: center;
    align-items: center; 
  `
);

const StyledCaption = styled(Caption)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.bodyText};
`
);
