import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { H4 } from '../../../../components/shared/designSystem';
import { Maybe } from '../../../../generated/graphql';
import { theme } from '../../../../providers/styleProvider';
import { AssessmentStatusDescription } from './AssessmentStatusDescription';
import { assessmentStrategy } from './assessmentStatusStrategy';

export type AssessmentStatus = {
	assessmentName: string;
	dateTaken?: string;
	tasks?: string;
	taskDuration?: string;
	mistakeCount?: number;
	participantName?: string;
	type?: Maybe<string>;
	visitType?: string;
	webEnabled?: boolean;
};

export type AssessmentStatusProps = {
	assessmentStatuses: AssessmentStatus[];
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssessmentStatuses = ({
	assessmentStatuses,
}: AssessmentStatusProps): JSX.Element => {
	return (
		<StyledWrapper>
			<H4 style={{ fontWeight: theme.weight.medium }}>
				<Trans i18nKey='web.report.assessmentStatus.title' />
			</H4>
			{assessmentStatuses?.map((assessment) => (
				<AssessmentStatusDescription
					key={assessment.assessmentName}
					assessment={assessment}
					transKey={assessmentStrategy(assessment)}
				/>
			))}
		</StyledWrapper>
	);
};

const StyledWrapper = styled.div(
	({ theme: { color, spacing } }) => `
		background: ${color.white};
		box-shadow: 0px 2px 10px rgba(23, 24, 32, 0.1);
		border-radius: 20px;
		padding: ${spacing.xl};
		margin-top: ${spacing.xl};
	`
);
