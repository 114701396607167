import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { FileNotFound } from '../../components/FileNotFound';
import { LoadingDots } from '../../components/shared/LoadingDots';
import { UserContext } from '../../context/UserContext';
import { RouteEnum, routeEnum } from '../../enums/routeEnum';
import { FeatureFlag, useFeatureFlag } from '../../features/feature-flags';
import { hasOperation } from '../../hasOperation';
import { AuthProvider } from '../AuthProvider';

type AuthenticatedRouteProps = {
	children: ReactNode;
};

export const RoleBasedRoute = ({
	children,
}: AuthenticatedRouteProps): JSX.Element => {
	const { pathname } = useLocation();

	// this is required to get the org specific string values in the menu
	const { currentUser } = useContext(UserContext);
	const isAuth0Provider = AuthProvider.isAuth0;
	const isUniversalLoginEnabled = useFeatureFlag(FeatureFlag.Auth0Universal);
	const isAuth0Universal = isAuth0Provider && isUniversalLoginEnabled;
	const enumeration = routeEnum(currentUser, isAuth0Universal);

	// This will need to be more robust when we begin to have nested routes
	const routeInfo = enumeration.find((route: RouteEnum) =>
		pathname.toLowerCase().includes(route.path)
	);

	if (!routeInfo) {
		return <FileNotFound />;
	}

	if (currentUser?.id && hasOperation(currentUser, routeInfo.operations)) {
		return <>{children}</>;
	}

	if (!hasOperation(currentUser, routeInfo.operations)) {
		return <LoadingDots />;
	}

	return (
		<Navigate
			to={`/auth/login?redirect=${routeInfo.path}`}
			state={{ from: pathname }}
			replace
		/>
	);
};
