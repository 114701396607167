import { enumeration, EnumItem } from './enumeration';

export type { EnumItem };

export type SessionStorageKey = {
	ImpersonatedOrg: EnumItem;
};

const SessionStorageKeys = {
	ImpersonatedOrg: { value: 'IMPERSONATED_ORG' },
};

export const SessionStorageEnum =
	enumeration<typeof SessionStorageKeys>(SessionStorageKeys);
