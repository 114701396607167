// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const UserSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 15 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='UserSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'UserSolid'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.066 14.1926C14.066 11.9229 11.951 10.0472 9.75448 10.0472H4.31131C1.94506 10.0472 0 12.0354 0 14.5129V17.1819C0 17.6447 0.367663 17.9989 0.78875 17.9989L13.2773 18C13.724 18 14.066 17.6192 14.066 17.183C14.066 14.4668 14.066 16.8525 14.066 14.1926Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.3577 4.46568C11.3577 6.94312 9.41262 8.93135 7.04637 8.93135C4.65354 8.93135 2.73403 6.94326 2.73506 4.46568C2.73506 1.98823 4.68012 0 7.04637 0C9.43817 0 11.3577 1.98703 11.3577 4.46568Z'
				fill='current'
			/>
		</svg>
	);
};
