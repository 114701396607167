import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../../metrics';
import { TFunction } from 'react-i18next';

export const getInformationProcessingDataSet = (
	dataMap: MetricHash,
	t: TFunction
): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const informationProcessingMap = {
		commandClock: [
			{
				x: t(infoProcessingStrings.percentThinkTime),
				y: dataMap?.COMPercentThinkTime_s?.value,
			},
			{
				x: t(infoProcessingStrings.averageLatency),
				y: dataMap?.COMAverageLatency_s?.value,
			},
			{
				x: t(infoProcessingStrings.latencyVariability),
				y: dataMap?.COMLatencyVariability_s?.value,
			},
			{
				x: t(infoProcessingStrings.relativeLongLatency),
				y: dataMap?.COMRelativeLongLatency_s?.value,
			},
			{
				x: t(infoProcessingStrings.longLatencyCount),
				y: dataMap?.COMLongLatencyCount_s?.value,
			},
			{
				x: t(infoProcessingStrings.longestLatency),
				y: dataMap?.COMLongestLatency_s?.value,
			},
		],
		copyClock: [
			{
				x: t(infoProcessingStrings.percentThinkTime),
				y: dataMap?.COPPercentThinkTime_s?.value,
			},
			{
				x: t(infoProcessingStrings.averageLatency),
				y: dataMap?.COPAverageLatency_s?.value,
			},
			{
				x: t(infoProcessingStrings.latencyVariability),
				y: dataMap?.COPLatencyVariability_s?.value,
			},
			{
				x: t(infoProcessingStrings.relativeLongLatency),
				y: dataMap?.COPRelativeLongLatency_s?.value,
			},
			{
				x: t(infoProcessingStrings.longLatencyCount),
				y: dataMap?.COPLongLatencyCount_s?.value,
			},
			{
				x: t(infoProcessingStrings.longestLatency),
				y: dataMap?.COPLongestLatency_s?.value,
			},
		],
	};

	return informationProcessingMap as DataSet;
};

export const infoProcessingStrings = {
	percentThinkTime: 'web.report.segmentUtils.percentThinkTime',
	averageLatency: 'web.report.segmentUtils.averageLatency',
	latencyVariability: 'web.report.segmentUtils.latencyVariability',
	relativeLongLatency: 'web.report.segmentUtils.relativeLongLatency',
	longLatencyCount: 'web.report.segmentUtils.longLatencyCount',
	longestLatency: 'web.report.segmentUtils.longestLatency',
};
