/* eslint-disable */
/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

//################################################################################
//Listener class
//################################################################################
export function dctListener() {
	/* Empty constructor */
}

//Add a listener
dctListener.prototype.addEventListener = function addEventListener(
	arg_event,
	arg_function,
	arg_context
) {
	if (!this._listeners) this._listeners = {};
	if (!this._listeners[arg_event])
		this._listeners[arg_event] = [arg_function];
	else this._listeners[arg_event].push(arg_function);

	//Context?
	if (arg_context) {
		arg_context.__listener_funcs = arg_context.__listener_funcs || [];
		arg_context.__listener_funcs.push([arg_event, arg_function]);
	}
};
//Remove a listener
dctListener.prototype.removeEventListener = function removeEventListener(
	arg_event,
	arg_function
) {
	if (!this._listeners) return;
	if (!this._listeners[arg_event]) return;
	this._listeners[arg_event] = this._listeners[arg_event].filter(function (
		f
	) {
		return f !== arg_function;
	});
};

//Remove all listeners from a context
dctListener.prototype.removeAllFromContext = function (arg_context) {
	//Not defined?
	if (!arg_context.__listener_funcs) return;

	//Remove all
	for (var i = 0; i < arg_context.__listener_funcs.length; i++) {
		this.removeEventListener.apply(this, arg_context.__listener_funcs[i]);
	}

	//Remove reference
	delete arg_context.__listener_funcs;
};

//Trigger a listener
dctListener.prototype.triggerEventListener = function triggerEventListener(
	arg_event,
	arg_arguments
) {
	if (!this._listeners) return;
	var list = this._listeners[arg_event];
	if (!list) return;
	for (var i = 0; i < list.length; i++) {
		list[i].apply(this, arg_arguments);
	}
};
//Extend a class to have listener properties
dctListener.extendClass = function (arg_func) {
	for (var k in dctListener.prototype)
		arg_func.prototype[k] = dctListener.prototype[k];
};
//Extend a class to have listener properties
dctListener.extendObject = function (arg_obj) {
	for (var k in dctListener.prototype) arg_obj[k] = dctListener.prototype[k];
};
