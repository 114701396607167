// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const ArrowFlag = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 40 40'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='ArrowFlag-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'ArrowFlag'}</title>
			<path
				d='M6.66675 10H25.0001L33.3334 20L25.0001 30H6.66675L13.3334 20L6.66675 10Z'
				fill='current'
			/>
		</svg>
	);
};
