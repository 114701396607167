import styled, { useTheme } from 'styled-components';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryContainer,
	VictoryGroup,
} from 'victory';
import { BarCapLabel } from './BarCapLabel';
import { ChartConcerns } from './ChartConcerns';
import { CompositeScalesLegend } from './CompositeScalesLegend';

export type DataSet = {
	commandClock: DataScore[];
	copyClock: DataScore[];
};
export type DataScore = {
	x: string;
	y: number;
};
export type CompositeScalesProps = {
	chartTitle?: string;
	subtitle?: string;
	dataSet: DataSet;
	width: number;
	height: number;
	barWidth: number;
	barDistance: number;
	xAxisRange: [number, number];
	yAxisRange: [number, number];
	squeezeEdgesX?: [number, number];
	squeezeEdgesY?: [number, number];
	slideDataX?: number;
	slideDataY?: number;
	slideBottomAxisY?: number;
	customYAxisTickValues?: number[];
	makeResponsive?: boolean;
	hideLegend?: boolean;
	concerns?: string[];
};

const CompositeScales = (props: CompositeScalesProps): JSX.Element => {
	const theme = useTheme();
	const {
		chartTitle,
		subtitle,
		dataSet,
		width,
		height,
		barWidth,
		barDistance,
		xAxisRange,
		yAxisRange,
		squeezeEdgesX = [0, 0],
		squeezeEdgesY = [0, 0],
		slideDataX = 0,
		slideDataY = 0,
		customYAxisTickValues = undefined,
		makeResponsive = false,
		hideLegend = false,
		concerns,
	} = props;
	const { color, weight } = theme;
	const capColors: string[] = [color.graphBlue, color.graphGreen];

	return (
		<StyledWrapper>
			{chartTitle && <StyledSpan>{chartTitle}</StyledSpan>}
			{subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
			{concerns && <ChartConcerns concerns={concerns} />}
			<VictoryChart
				containerComponent={
					<StyledVictoryContainer responsive={makeResponsive} />
				}
				width={width}
				height={height}
				style={{
					background: {
						fill: 'none',
						padding: '0',
					},
				}}
				domainPadding={{ x: squeezeEdgesX, y: squeezeEdgesY }}
				domain={{ x: xAxisRange, y: yAxisRange }}>
				<VictoryAxis
					// The vertical y-axis at the left side of the graph
					dependentAxis={true}
					tickValues={customYAxisTickValues}
					style={{
						grid: {
							stroke: color.graphGrid,
							strokeWidth: 1,
							transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
						},
						tickLabels: { fill: color.graphTextPrimary },
						ticks: { stroke: 'transparent' },
						axis: {
							stroke: 'transparent',
						},
					}}
					offsetX={24}
				/>
				<VictoryGroup offset={barDistance} colorScale={'qualitative'}>
					<VictoryBar
						barWidth={barWidth}
						cornerRadius={4}
						style={{
							data: {
								fill: color.graphBlue,
								opacity: 0.2,
								transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
							},
							labels: {
								fontFamily: 'IBM Plex Sans',
								fontSize: 14,
								fill: color.graphTextSecondary,
							},
						}}
						data={dataSet.commandClock}
						labels={(datum) => datum.datum._y}
						labelComponent={
							<BarCapLabel
								width={barWidth}
								colors={capColors}
								translateX={slideDataX}
							/>
						}
					/>

					<VictoryBar
						barWidth={barWidth}
						cornerRadius={4}
						style={{
							data: {
								fill: color.graphGreen,
								opacity: 0.2,
								transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
							},
							labels: {
								fontFamily: 'IBM Plex Sans',
								fontSize: 14,
								fill: color.graphTextSecondary,
							},
						}}
						data={dataSet.copyClock}
						labels={(datum) => datum.datum._y}
						labelComponent={
							<BarCapLabel
								width={barWidth}
								colors={capColors}
								translateX={slideDataX}
							/>
						}
					/>
				</VictoryGroup>
				<VictoryAxis
					// The x-axis at 0
					style={{
						axis: {
							stroke: 'black',
							transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
						},
						ticks: {
							stroke: 'transparent',
						},
						tickLabels: {
							fill: 'transparent',
						},
					}}
				/>
				<VictoryAxis
					// This axis represents the labels positioned under the graph
					offsetY={40}
					style={{
						axis: {
							stroke: 'transparent',
							transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
						},
						ticks: {
							stroke: 'transparent',
						},
						tickLabels: {
							transform: `translate(${slideDataX})`,
							fontFamily: 'IBM Plex Sans',
							fontSize: 14,
							fontWeight: weight.medium,
						},
					}}
				/>
			</VictoryChart>
			{!hideLegend && (
				<CompositeScalesLegend
					colors={capColors}></CompositeScalesLegend>
			)}
		</StyledWrapper>
	);
};

export { CompositeScales };

const StyledVictoryContainer = styled(VictoryContainer)`
	@media print {
		height: auto !important;
	}
`;

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `
	padding: 0 0 ${spacing.md} 0;
	overflow-x: auto;
	@media print {
    break-inside: avoid;
	}
`
);
const StyledSpan = styled.span(
	({ theme: { fontSize, weight, spacing } }) => `
	font-size: ${fontSize._16};
  font-weight: ${weight.semi};
  @media print {
    padding-top: ${spacing.md}
  }
`
);
const StyledSubtitle = styled.span(
	({ theme: { fontSize } }) => `
	font-size: ${fontSize._14};
`
);
