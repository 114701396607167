import { useMemo } from 'react';
import styled from 'styled-components';

import { Json } from '../../../artifacts/ReportArtifactNames';
import { LoadingDots } from '../../../components/shared/LoadingDots';
import { Maybe } from '../../../generated/graphql';
import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import { Alert } from '../../shared/Alert';
import { ArtifactFormats, useGetReportData } from '../../shared/hooks';
import { AssessmentStatus, AssessmentStatuses } from '../Assessment';
import { FormattedSegmentResult } from '../common';
import { AudioCardGrid } from '../common/AudioCardGrid/AudioCardGrid';
import { mapDataToProps } from './ComplexPictureDescription.helpers';

type ComplexPictureDescriptionProps = {
	segmentData: FormattedSegmentResult | undefined;
	contentLoaded: () => boolean;
	externalId: string;
	type: Maybe<string>;
};

const ComplexPictureDescription = ({
	segmentData,
	externalId,
	type,
}: ComplexPictureDescriptionProps) => {
	const requestParams = useMemo(
		() => ({
			segmentResultId: segmentData?.id as string,
			fileName: Json.ComplexPictureDescription,
			fileType: ArtifactFormats.JSON,
		}),
		[segmentData?.id]
	);

	const {
		error,
		loading,
		metadata: reportData,
		binaryArtifactData: audioData,
	} = useGetReportData(requestParams);

	const assessmentName = 'Complex Picture Description';
	const taskDuration = '90 seconds';
	const dateTaken = segmentData?.endTime
		? dateFormatter(segmentData.endTime, "MMM d', ' yyyy ' at 't")
		: undefined;

	const assessmentStatus: AssessmentStatus = {
		participantName: `Participant ${externalId}`,
		assessmentName,
		dateTaken,
		taskDuration,
		type,
	};

	const layoutProps = useMemo(() => mapDataToProps(audioData), [audioData]);

	if (loading) return <LoadingDots />;

	if (error) {
		return (
			<StyledAlertContainer>
				<Alert severity='info'>
					<>{error}</>
				</Alert>
			</StyledAlertContainer>
		);
	}

	return (
		<>
			<StyledAlertContainer>
				<Alert severity='info'>
					<>
						This section is for Research Use Only. Not for
						Diagnostic Procedures.
					</>
				</Alert>
			</StyledAlertContainer>
			<AssessmentStatuses assessmentStatuses={[assessmentStatus]} />
			{reportData ? (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={layoutProps?.audioCardData}
				/>
			) : null}
		</>
	);
};

export { ComplexPictureDescription };

const StyledAlertContainer = styled.div(
	({ theme: { spacing } }) => `
  margin-top: ${spacing.xl};
`
);
