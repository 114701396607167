import { useContext, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import styled, { useTheme } from 'styled-components';
import * as Sentry from '@sentry/browser';
import {
	getModel,
	removeBatteryModel,
	RemoveBatteryProps,
	removeBatterySchema,
} from '../../../schemas/forms/removeBatterySchema';
import {
	AnalyticsAction,
	IssueContext,
	IssueType,
	useDeleteAssignmentForParticipantMutation,
	useRegisterAssignmentIssueForParticipantMutation,
} from '../../../generated/graphql';
import { RemoveBatteryForParticipantHeader } from './RemoveBatteryForParticipantHeader';
import { Trans, useTranslation } from 'react-i18next';
import { buildIssueOptions } from './RemoveBatteryForParticipant.helpers';
import { UserContext } from '../../../context/UserContext';
import { sendEventData } from '../../../analytics/amplitude';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { ERROR } from '../../../logging/linusLogger';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { ButtonSm, P1 } from '../../shared/designSystem';
import { messages } from '../../shared/errorMessages';
import { icons } from '../../../enums/icons';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'api/query';

export type RemoveBatteryForParticipantProps = {
	onCancel: () => void;
	participant: RemoveBatteryProps;
};

const RemoveBatteryForParticipant = ({
	onCancel,
	participant,
}: RemoveBatteryForParticipantProps): JSX.Element => {
	const client = useQueryClient();
	const theme = useTheme();
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const issueOptions = buildIssueOptions(currentUser.organizationType.value);
	const [
		deleteAssignmentForParticipantMutation,
		{ error: deleteAssignmentError },
	] = useDeleteAssignmentForParticipantMutation({ errorPolicy: 'all' });
	const [
		registerAssignmentIssueForParticipantMutation,
		{ error: registerIssueError },
	] = useRegisterAssignmentIssueForParticipantMutation({
		errorPolicy: 'all',
	});

	const onSubmit = async (
		values: removeBatteryModel,
		{ setSubmitting }: FormikHelpers<removeBatteryModel>
	) => {
		const { issues, otherIssues } = values;
		// If there are no chips selected (or notes provided), we return valid issue type
		const participantIssues =
			!issues.length && !otherIssues.length
				? [IssueType.NoResponse]
				: issues;
		if (!participant.id || !participant?.assignmentInfo?.id) {
			ERROR(
				'Cannot remove battery, Missing participantID or assignmentID'
			);
			return;
		}
		try {
			const deleteAssignmentResponse =
				await deleteAssignmentForParticipantMutation({
					variables: {
						DeleteAssignmentInput: {
							id: participant?.assignmentInfo?.id,
						},
						orgId: currentUser.organizationId,
					},
				});
			const registerIssueResponse =
				await registerAssignmentIssueForParticipantMutation({
					variables: {
						issueInput: {
							assignmentId: participant?.assignmentInfo?.id,
							issueContext: IssueContext.AssignmentRemoved,
							options: participantIssues as IssueType[],
							note: otherIssues,
						},
					},
				});
			if (
				deleteAssignmentResponse.data?.deleteAssignment?.success &&
				registerIssueResponse.data?.registerAssignmentIssue.success
			) {
				await client.invalidateQueries({
					queryKey: [QueryKey.Assignment, participant.id],
				});
				sendEventData({
					eventType: AnalyticsAction.RemovedBattery,
					eventProperties: {
						sessionIssues: participantIssues,
						otherIssues: !!otherIssues,
					},
				});
				setShowSuccessModal(true);
				setSubmitting(false);
			} else {
				deleteAssignmentError &&
					ERROR(deleteAssignmentError.message, deleteAssignmentError);
				registerIssueError &&
					ERROR(registerIssueError.message, registerIssueError);
				setServerSideMessage(
					messageEnum.error(messages.mutationHookError)
				);
			}
		} catch (err) {
			Sentry.captureException(err);
		}
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={removeBatterySchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid }) => {
				return showSuccessModal ? (
					<LinusModalDialog
						onClose={onCancel}
						title={t`web.shared.removeBatteryModal.title`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonText={t`web.shared.close`}
						acceptButtonCallback={onCancel}>
						<StyledSuccessMessage>
							<Trans
								i18nKey='web.shared.removeBatteryModal.batteryRemoved'
								values={{
									firstName: 'Participant',
									lastName: participant?.externalId || '',
								}}
								components={{
									b: <strong />,
								}}
							/>
						</StyledSuccessMessage>
					</LinusModalDialog>
				) : (
					<StyledFormContainer>
						<RemoveBatteryForParticipantHeader
							participantExternalId={participant?.externalId}
						/>
						<StyledForm>
							<StyledIssues>
								<LinusInput
									headerTitle='web.shared.removeBatteryModal.issuesToNote'
									label=''
									name='issues'
									type='chipSelect'
									options={issueOptions}
									customLineBreak={[2, 3]}
									width='580px'
								/>
								<StyledNotes>
									<StyledSubtitle>
										{
											t`web.shared.removeBatteryModal.otherIssuesToNote` as string
										}
									</StyledSubtitle>
									<LinusInput
										label=''
										showLabel={false}
										placeholder={t`web.shared.removeBatteryModal.writeOtherReasons`}
										name='otherIssues'
										type='textarea'
										width='550px'
										rows={2}
									/>
								</StyledNotes>
							</StyledIssues>
							<StyledInfoMessage>
								<InfoMessage
									messageEnum={serverSideMessage}
									showIf={!!serverSideMessage}
								/>
							</StyledInfoMessage>
							<StyledActionRow>
								<ButtonSm
									text={t`web.shared.removeBatteryModal.cancel`}
									width='158px'
									onClick={onCancel}
									dataId='add-battery-cancel-button'
								/>
								<StyledSpacer />
								<ButtonSm
									disabled={!isValid || isSubmitting}
									text={t`web.shared.removeBatteryModal.removeBattery`}
									type='submit'
									width='158px'
									primary
									dataId='add-battery-submit-button'
								/>
							</StyledActionRow>
						</StyledForm>
					</StyledFormContainer>
				);
			}}
		</Formik>
	);
};

export { RemoveBatteryForParticipant };

const StyledFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 548px;
	height: 100%;
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledIssues = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const StyledNotes = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 100px;
`;

const StyledSubtitle = styled(P1)(
	({ theme: { spacing, color, weight } }) => `
	padding-top: ${spacing.sm};
	color: ${color.textSubtitle};
	font-weight: ${weight.medium};
`
);

const StyledActionRow = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 32px;
	flex-grow: 1;
	align-items: flex-end;
`;

const StyledSpacer = styled.span(
	({ theme: { spacing } }) => `
	width: ${spacing.xl};
`
);

const StyledSuccessMessage = styled.div`
	max-width: 548px;
`;

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.lg}
`
);
