/* eslint-disable */
/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/
import { dctJSON } from './json';
import { loading_placeholder } from './gen_html';
// import { dctTemplates } from './templates';
//################################################################################
//Component Utilities
//################################################################################
const dctComponents = new (function () {
	//########################################
	//Variables
	//########################################
	//Component classes
	var component_classes = {};

	//Components initialized
	var components_initialized = [];

	//The query
	var component_query = null;

	//########################################
	//Public functions
	//########################################
	//Register a component to auto-initialize
	this.makeComponent = function (arg_class) {
		var f = function (el, params) {
			this.element = el;
			this.params = params;
		};
		component_classes[arg_class] = f;
		component_query = null;
		f.className = arg_class;
		f.propertyName = '__dct_' + arg_class;
		f.dotClassName = '.' + arg_class;
		return f;
	};

	//Fill a node with HTML - and initialize things in it!
	this.fill = function (arg_dom, arg_html) {
		//Animated?
		var animated = arg_dom.classList.contains('fill_animated');

		//Fill it (extra classes add animation on .fill_animated class
		if (animated) arg_dom.innerHTML = '';
		if (animated) arg_dom.classList.add('fill_begin');
		arg_dom.innerHTML = arg_html;
		if (animated) var _ = arg_dom.offsetWidth; //Trigger a restyle
		if (animated) arg_dom.classList.remove('fill_begin');

		//Garbage collect since we might have just clobbered old components
		this.garbageCollectComponents();

		//And now initialize new ones
		this.initializeComponents(arg_dom);
	};

	//Fill with loading
	this.fillLoading = function (arg_dom) {
		//Already loading?  Do nothing.
		if (
			arg_dom.firstElementChild &&
			arg_dom.firstElementChild.classList.contains('area_loading')
		)
			return;

		this.fill(arg_dom, loading_placeholder());
	};

	//Initialize all components
	this.initializeComponents = function (arg_dom) {
		//Re-do selector on demand
		if (component_query === null) {
			component_query = Object.keys(component_classes)
				.map(function (n) {
					return '.' + n;
				})
				.join(',');
		}

		//Find anything to auto-initialize
		var call_init = [];
		var tags = arg_dom.querySelectorAll(component_query);
		for (let i = 0; i < tags.length; i++) {
			for (
				var class_index = 0;
				class_index < tags[i].classList.length;
				class_index++
			) {
				//Class name
				var class_name = tags[i].classList[class_index];
				var class_func = component_classes[class_name];
				if (!class_func) continue;

				//Build it if it's not there already
				if (tags[i][class_func.propertyName]) continue;

				//Get parameters
				var params_text = tags[i].getAttribute('data-component-params');
				var params =
					(params_text ? dctJSON.parseOrNull(params_text) : null) ||
					{};

				//Create it
				var component = new class_func(tags[i], params);
				tags[i][class_func.propertyName] = component;
				components_initialized.push(component);

				//Append to array
				call_init.push(component);

				//Pre-init?
				if (component.preInit) component.preInit();
			}
		}

		//Call initialize on them
		for (let i = 0; i < call_init.length; i++) {
			var result = call_init[i].init();
		}
	};

	//Garbage collect components
	this.garbageCollectComponents = function () {
		//Apply an array filter
		components_initialized = components_initialized.filter(function (
			component
		) {
			//Element is no longer in the DOM...
			if (!document.documentElement.contains(component.element)) {
				//Call a desconstructor if available
				if (component.destroyed) component.destroyed();

				//Exclude from results
				return false;
			}
			//Otherwise, it's still there.
			else {
				return true;
			}
		});
	};

	//Find a component upwards
	this.findUpwards = function getComponentUpwards(arg_start, arg_class_func) {
		for (var t = arg_start; t && t !== document; t = t.parentNode) {
			if (t[arg_class_func.propertyName])
				return t[arg_class_func.propertyName];
		}
		return null;
	};

	//Find one components - matches querySelector, but with a component argument at the end, and returns components
	this.queryComponent = function queryComponent(
		arg_start,
		arg_query,
		arg_class_func
	) {
		var found = arg_start.querySelector(
			arg_query + arg_class_func.dotClassName
		);
		if (!found) return null;
		return found[arg_class_func.propertyName];
	};

	//Find one components - matches querySelector, but with a component argument at the end, and returns components
	this.getComponent = function getComponent(arg_element, arg_class_func) {
		return arg_element[arg_class_func.propertyName] || null;
	};

	//Find all components - matches querySelectorAll, but with a component argument at the end, and returns components
	this.queryComponents = function queryComponents(
		arg_start,
		arg_query,
		arg_class_func
	) {
		var found = arg_start.querySelectorAll(
			arg_query + arg_class_func.dotClassName
		);
		var result = [];
		if (!found.length) return found;
		for (var i = 0; i < found.length; i++) {
			result.push(found[i][arg_class_func.propertyName]);
		}
		return result;
	};
})();

//Convenience since we use this so damn often
const dctFill = function () {
	dctComponents.fill.apply(dctComponents, arguments);
};

export { dctFill, dctComponents };
