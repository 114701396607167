/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	LinusPrincipal,
	User,
} from '@lh/eng-platform-organization-service-rest-client';
import { createContext } from 'react';

export type UserOnlyContextProps = {
	user: User | null;
	principal: LinusPrincipal | null;
	refetchUser: () => void;
	logout: (urlRedirect?: string) => void;
	login: (user: string, pass: string) => Promise<boolean>;
};

export const UserOnlyContext = createContext<UserOnlyContextProps>({
	user: null,
	principal: null,
	refetchUser: () => undefined,
	logout: (urlRedirect?: string) => undefined,
	login: async (user, pass) => false,
});
