import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../artifacts/ReportArtifactNames';
import { ArtifactFormats, useGetReportData } from '../../shared/hooks';
import { FormattedSegmentResult } from '../common';
import { mapDataToProps } from './SemanticFluency.helpers';
import { SemanticFluencyLayout } from './SemanticFluency.layout';

type SemanticFluencyPropsType = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayback?: boolean;
};

export const SemanticFluency = ({
	data: segmentResult,
	showAudioPlayback = false,
}: SemanticFluencyPropsType) => {
	const { t: translationFn } = useTranslation();

	const segmentResultId = useMemo(
		() => segmentResult?.id,
		[segmentResult?.id]
	);

	const {
		error,
		loading,
		binaryArtifactData: audioData,
	} = useGetReportData({
		segmentResultId,
		fileName: Json.SemanticFluencyJSON,
		fileType: ArtifactFormats.JSON,
	});

	const layoutProps = mapDataToProps({
		segmentResult,
		audioData,
		translationFn,
	});

	if (!segmentResult || loading || error || !layoutProps) return null;

	return (
		<SemanticFluencyLayout
			{...layoutProps}
			showAudioPlayers={showAudioPlayback}
		/>
	);
};
