import { Auth } from '@lh/eng-shared-auth';

import { locale } from 'enums/locale';
import { ReportType } from 'generated/graphql';
import { CurrentUser } from '../types';

import { pollPromiseForPDFLink } from './pdfDownloadService';
import { AnalyticsReportName } from './reportAnalytics';

export const MAX_ATTEMPTS = 10; // Retry attempts tick after the first POLLING_INTERVAL
export const POLLING_INTERVAL = 10000; // 10 seconds

type ReportInput = {
	organizationId: string;
	batteryResultId: string;
	language: string;
	reportType: ReportType;
};

const TO_BE_COLLECTED = 'TO_BE_COLLECTED_AT_TIME_OF_TESTING';
const REPORT_FALLBACK_LANGUAGE = locale.EnUs;

export function getReportName(reportType: ReportType) {
	if (reportType === ReportType.RdacReport) {
		return AnalyticsReportName.DAC_REPORT;
	}

	return AnalyticsReportName.FALLBACK;
}

export function determineReportLanguage(participantLanguage = TO_BE_COLLECTED) {
	return participantLanguage !== TO_BE_COLLECTED
		? participantLanguage
		: REPORT_FALLBACK_LANGUAGE.display ?? '';
}

export function onGetReportData(
	auth: Auth,
	batteryResultId: string,
	currentUser: CurrentUser,
	partipantLanguage?: string,
	onRetrieveInput?: (input: ReportInput) => void
) {
	const language = determineReportLanguage(partipantLanguage);

	const reportInput: ReportInput = {
		organizationId: currentUser.organizationId,
		batteryResultId,
		reportType: ReportType.RdacReport,
		language: language,
	};

	onRetrieveInput?.(reportInput);

	return pollPromiseForPDFLink(auth, reportInput);
}
