// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CloseSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CloseSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CloseSolid'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM6.53077 5.46971C6.2379 5.17679 5.76302 5.17676 5.47011 5.46963C5.1772 5.76251 5.17716 6.23738 5.47004 6.53029L7.93978 9.00037L5.46967 11.4705C5.17678 11.7634 5.17678 12.2382 5.46967 12.5311C5.76256 12.824 6.23744 12.824 6.53033 12.5311L9.00037 10.0611L11.4698 12.5308C11.7626 12.8238 12.2375 12.8238 12.5304 12.5309C12.8233 12.238 12.8234 11.7632 12.5305 11.4703L10.061 9.00044L12.5303 6.53114C12.8232 6.23825 12.8232 5.76337 12.5303 5.47048C12.2374 5.17758 11.7626 5.17758 11.4697 5.47048L9.00044 7.93971L6.53077 5.46971Z'
				fill='current'
			/>
		</svg>
	);
};
