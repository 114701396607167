import React from 'react';
import semver from 'semver';
import styled from 'styled-components';
import { P1 } from '../../../../../designSystem/Text';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonSm } from '../../../../../shared/designSystem';

type ScoringDetailsProps = {
	onClose: () => void;
	algorithmVersion: string;
	rangesComponent: React.ReactNode;
};

export const DcrScoringDetails = ({
	onClose,
	algorithmVersion,
	rangesComponent,
}: ScoringDetailsProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledScoringDetailsWrapper>
			<StyledDetails>
				{t`web.dcrReport.overview.scoringDetails.modal.description`}
			</StyledDetails>
			<StyledOl>
				<StyledStatement>
					{t`web.dcrReport.overview.scoringDetails.modal.immediateRecallStatement`}
				</StyledStatement>
				<StyledStatement>
					<Trans
						i18nKey={
							'web.dcrReport.overview.scoringDetails.modal.clockScoringStatement'
						}
						components={[<StyledIndent />]}
					/>
				</StyledStatement>
				<StyledStatement>
					{t`web.dcrReport.overview.scoringDetails.modal.delayedRecallStatement`}
				</StyledStatement>
				{!semver.valid(algorithmVersion) ||
				semver.gt(algorithmVersion, '1.2.4') ? null : (
					<StyledStatement>
						{t`web.dcrReport.overview.scoringDetails.modal.finalStatement`}
					</StyledStatement>
				)}
			</StyledOl>
			<Divider />
			<RangesTitle>
				<Trans i18nKey='web.dcrReport.overview.scoringDetails.modal.interpretationRanges' />
			</RangesTitle>
			{rangesComponent}
			<StyledButtonWrapper>
				<ButtonSm
					text={t`web.dcrReport.overview.scoringDetails.modal.closeButton.content`}
					width='158px'
					onClick={onClose}
				/>
			</StyledButtonWrapper>
		</StyledScoringDetailsWrapper>
	);
};

const StyledScoringDetailsWrapper = styled.div(
	({ theme: { spacing } }) => `
		width: 700px;
		padding-top: ${spacing.md};
	`
);

const StyledOl = styled.ol(
	({ theme: { spacing } }) => `
		margin-left: ${spacing.lg};
		padding-bottom: ${spacing.xs};
		color: #171820;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.5px;
	`
);

const StyledStatement = styled.li(
	({ theme: { spacing } }) => `
		margin-bottom: ${spacing.md};
	`
);

const StyledDetails = styled(P1)(
	({ theme: { spacing } }) => `
		margin-bottom: ${spacing.md};
	`
);

const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		justify-content: space-around;
		margin-top: ${spacing.lg};
	`
);

const StyledIndent = styled(P1)(
	({ theme: { spacing } }) => `
		margin: 0;
		padding-left: ${spacing.sm};
	`
);

const Divider = styled.div(
	({ theme: { spacing } }) => `
		height: 1px;
		margin: ${spacing.sm} 0;
		background-color: #d9d8dc;
	`
);

const RangesTitle = styled(P1)(
	({ theme: { weight, spacing } }) => `
		padding: ${spacing.sm} 0;
		margin-bottom: ${spacing.sm};
		font-weight: ${weight.medium};
	`
);
