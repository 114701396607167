import styled, { css } from 'styled-components';

import { StyledLinkButton } from '../../../../../shared/StyledLinkButton';

export const BreakdownContainer = styled.div(
	({ theme: { breakpoints, spacing } }) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		${breakpoints.responsive.up(1350)} {
			width: calc(50% - ${spacing.sm} * 2);
		}
	`
);

export const CurveContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const BreakdownInfoContainer = styled.div`
	padding: 12px;
`;

export const Header = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
	background-color: ${colors.gray_90};
	border-bottom: 1px solid ${colors.gray_60};
	padding: 0 12px;
`
);

export const StyledButton = styled(StyledLinkButton)(
	({ theme: { colors } }) => `
	font-size: 14px;
	font-weight: 600;
	color: ${colors.blue}
`
);
