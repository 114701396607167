import { theme } from '../../../../providers/styleProvider';
import { ScoreBgColorPair } from '../../common/scoring/types';

const addOpacityToColor = (hexColor: string, opacityPercent: number) => {
	return `${hexColor}${opacityPercent}`;
};

export function getDcrScoreTagColors(DCRScore: number) {
	const colors: ScoreBgColorPair = { solid: '', light: '' };

	handleDCR(DCRScore, {
		unanalyzable: () => {
			colors.solid = theme.colors.gray_60;
			colors.light = addOpacityToColor(theme.colors.gray_60, 20);
		},
		_0_1: () => {
			colors.solid = theme.colors.orange_light;
			colors.light = addOpacityToColor(theme.colors.orange_lightest, 20);
		},
		_2_3: () => {
			colors.solid = theme.colors.yellow_light;
			colors.light = addOpacityToColor(theme.colors.yellow_lightest, 20);
		},
		_4_5: () => {
			colors.solid = theme.colors.green_light;
			colors.light = addOpacityToColor(theme.colors.green_lightest, 20);
		},
	});

	return colors;
}

export function handleDCR(
	score: number,
	callback: {
		unanalyzable(): void;
		_0_1(): void;
		_2_3(): void;
		_4_5(): void;
	}
) {
	if (score === -1) return callback.unanalyzable();
	if (inRange(score, 0, 1)) return callback._0_1();
	if (inRange(score, 2, 3)) return callback._2_3();
	if (inRange(score, 4, 5)) return callback._4_5();
}

function inRange(value: number, from: number, to: number): boolean {
	return value >= from && value <= to;
}
