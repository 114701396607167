import { Field } from 'formik';
import styled from 'styled-components';
import { LinusInputChildrenProps } from '../../../../types';

export const RadioButton = <T,>({
	name,
	value,
}: LinusInputChildrenProps<T>): JSX.Element => {
	return <StyledRadio name={name} type='radio' value={value} />;
};

const StyledRadio = styled(Field)`
	margin-right: 10px;
	width: 20px;
	height: 20px;
`;
