import styled from 'styled-components';
import { AssessmentStatuses } from '../Assessment';
import { FormattedSegmentResult } from '../common';
import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import { Alert } from '../../shared/Alert';
import { Maybe } from '../../../generated/graphql';

type TrailsSegments = {
	trails_a: FormattedSegmentResult | undefined;
	trails_b: FormattedSegmentResult | undefined;
};

type TrailsProps = {
	trailsSegments: TrailsSegments | undefined;
	contentLoaded: () => boolean;
	externalId: string;
	type: Maybe<string>;
};

// TODO: add this to shared utils
export const titleCase = (s: string) =>
	s
		.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
		.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

const buildTrailsAssessmentStatuses = (
	trailsData: TrailsSegments | undefined,
	externalId: string,
	type: Maybe<string>
) => {
	const taskDuration = '90 seconds';
	return Object.entries(trailsData || {}).map(([key, trailData]) => {
		const dateTaken = trailData?.endTime
			? dateFormatter(trailData.endTime, "MMM d', ' yyyy ' at 't")
			: undefined;
		// TODO: get circle and mistake count from metric items when available
		return {
			participantName: `Participant ${externalId}`,
			assessmentName: titleCase(key),
			dateTaken,
			taskDuration,
			type,
		};
	});
};

const Trails = ({ trailsSegments, externalId, type }: TrailsProps) => {
	const assessmentStatuses = buildTrailsAssessmentStatuses(
		trailsSegments,
		externalId,
		type
	);
	const withDatesOnly = assessmentStatuses.filter((AS) => AS.dateTaken);
	return (
		<>
			<StyledAlertContainer>
				<Alert severity='info'>
					<span>
						This section is for Research Use Only. Not for
						Diagnostic Procedures.
					</span>
				</Alert>
			</StyledAlertContainer>
			<AssessmentStatuses assessmentStatuses={withDatesOnly} />
		</>
	);
};

export { Trails };

const StyledAlertContainer = styled.div(
	({ theme: { spacing } }) => `
  margin-top: ${spacing.xl};
`
);
