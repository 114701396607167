import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	Participant,
	UpdateParticipantInput as SubjectServiceUpdateParticipantInput,
} from 'api/participant';

import { getSubjectService } from '../getSubjectService';
import { QueryKey } from 'api/query';
import { AxiosError } from 'axios';

type UpdateParticipantInput = {
	organizationId: string;
	participantId: string;
	participant: SubjectServiceUpdateParticipantInput;
};

async function updateParticipant({
	organizationId,
	participant,
	participantId,
}: UpdateParticipantInput) {
	const service = await getSubjectService();
	return await service.updateParticipant({
		subjectId: participantId,
		updateParticipantInput: { ...participant },
		organizationId,
	});
}

export function useUpdateParticipant() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error updating participant',
		},
		mutationFn: updateParticipant,
		onMutate: async ({ participant, participantId }) => {
			const queryTuple = [QueryKey.Participant, participantId];
			await queryClient.cancelQueries({
				queryKey: queryTuple,
			});
			const existing = await queryClient.getQueryData<Participant>(
				queryTuple
			);
			const newParticipant = {
				...existing,
				...participant,
			};
			queryClient.setQueryData(queryTuple, newParticipant);
			return { previous: existing, current: newParticipant };
		},
		onError: (
			_: AxiosError<{ errorCode: string }>,
			updateParams,
			context
		) => {
			queryClient.setQueryData(
				[QueryKey.Participant, updateParams.participantId],
				context?.previous
			);
		},
		onSettled: (result) => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.Participant, result?.data?.id],
			});
		},
	});
}
