import React from 'react';
import { Skip10sAhead, Skip10sBack } from '../designSystem/Icons';
import styled from 'styled-components';

type AudioWidgetSkipButtonsProps = {
	onSkipBack: () => void;
	onSkipFwd: () => void;
};
export const AudioWidgetSkipButtons = ({
	onSkipBack,
	onSkipFwd,
}: AudioWidgetSkipButtonsProps): JSX.Element => {
	return (
		<StyledSkipButtonsContainer>
			<StyledSkipButton onClick={onSkipBack}>
				<Skip10sBack />
			</StyledSkipButton>
			<StyledSkipButton onClick={onSkipFwd}>
				<Skip10sAhead />
			</StyledSkipButton>
		</StyledSkipButtonsContainer>
	);
};

const StyledSkipButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 18px;
`;

const StyledSkipButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;
	cursor: pointer;
	padding: 0;
`;
