/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
	BatteryResultState,
	DeepBatteryResult,
	useGetBatteryResultsByParticipant,
} from 'api/battery';
import { Participant, useGetParticipant } from 'api/participant';

import { fetchDeepBatteryResult } from 'api/battery';
import { QueryKey } from 'api/query';
import { UserContext } from '../../../context/UserContext';
import { batteryDataTransformer } from '../../../providers/globalDataProvider/BatteryDataTransformer';
import { GlobalDataContext } from '../../../providers/globalDataProvider/GlobalDataProvider';
import {
	TableData,
	isProcessing,
	showNotification,
} from '../../../schemas/table/participantDetailsSchema';

export type ParticipantDataWithResults = {
	participantInfo: Participant | undefined;
	batteryResults: TableData[];
	cohortResults?: TableData[] | undefined;
	deepBatteryResults: DeepBatteryResult[];
};

type GetParticipantDetailsType = {
	loading: boolean;
	data: ParticipantDataWithResults;
	error: unknown;
};

const useGetParticipantDetails = (
	participantId: string | null
): GetParticipantDetailsType => {
	const { t } = useTranslation();
	const { orgBatteries, orgUsers } = useContext(GlobalDataContext);
	const { currentUser } = useContext(UserContext);

	const [error, setError] = useState<null | unknown>(null);
	const [participantBatteryResults, setParticipantBatteryResults] = useState<
		TableData[]
	>([]);
	const [deepBatteryResults, setDeepBatteryResults] = useState<
		DeepBatteryResult[]
	>([]);
	const [cohortResults, setCohortResults] = useState<
		ParticipantDataWithResults['cohortResults']
	>([]);
	const [isProcessingBatteryResults, setIsProcessingBatteryResults] =
		useState<boolean>(false);

	const queryClient = useQueryClient();

	const participantData = useGetParticipant({
		participantId,
		organizationId: currentUser?.organizationId,
	});

	const isLoadingParticipantDetails = useMemo(
		() => participantData?.isLoading,
		[participantData?.isLoading]
	);

	const participantResults = useGetBatteryResultsByParticipant(participantId);

	const isLoadingBatteryResults = useMemo(
		() => participantResults?.isLoading ?? null,
		[participantResults?.isLoading]
	);

	const batteryResultsData = useMemo(
		() => participantResults?.data ?? null,
		[participantResults?.data]
	);

	const getProctorFullName = useCallback(
		(proctorId?: string): string => {
			if (!proctorId) return '';
			const orgUser = first(
				orgUsers.filter((orgUser) => orgUser.id === proctorId)
			);
			return `${orgUser?.firstName} ${orgUser?.lastName}`;
		},
		[orgUsers]
	);

	useEffect(() => {
		const processBatteryResults = async () => {
			try {
				if (!batteryResultsData?.results) return;
				setIsProcessingBatteryResults(true);
				const batteryIdsFromResults = batteryResultsData?.results?.map(
					(batteryResult) => batteryResult.batteryId
				);
				const batteries = batteryDataTransformer(
					batteryIdsFromResults,
					orgBatteries
				);
				const processedResults: TableData[] =
					batteryResultsData?.results?.map((result) => {
						const batteryInfo = first(
							batteries.filter(
								(battery) => battery.id === result?.batteryId
							)
						);
						return {
							organizationId: result.organizationId,
							id: result?.id,
							participantId: result?.participantId,
							batteryId: batteryInfo?.id || '',
							battery: t(batteryInfo?.displayKey ?? ''),
							date: result?.endTime ?? '',
							administratorId: result.proctorId ?? '',
							processing: isProcessing(result),
							status: result?.statusContext?.state,
							visitType: result?.visitType ?? '',
							delayed:
								result?.statusContext?.state ===
								BatteryResultState.ProcessingError,
							notification: showNotification(result),
						};
					});
				setParticipantBatteryResults(processedResults);
				setIsProcessingBatteryResults(false);
			} catch (error) {
				setError(error);
			}
		};
		processBatteryResults();
	}, [
		batteryResultsData,
		currentUser.organizationId,
		getProctorFullName,
		orgBatteries,
		queryClient,
		t,
	]);

	useEffect(() => {
		const cohortResults = participantBatteryResults?.filter(
			(result) => result?.status === BatteryResultState.AnalysisComplete
		);
		setCohortResults(cohortResults);
	}, [participantBatteryResults]);

	useEffect(() => {
		if (batteryResultsData?.results) {
			(async () => {
				const deepResults = await Promise.all(
					batteryResultsData?.results.map((br) => {
						return queryClient.fetchQuery({
							queryKey: [QueryKey.DeepBatteryResult, br.id],
							queryFn: () =>
								fetchDeepBatteryResult({
									batteryResultId: br.id,
								}),
							staleTime: 100000,
						});
					})
				);
				setDeepBatteryResults(deepResults);
			})();
		}
	}, [batteryResultsData, queryClient]);

	return {
		loading:
			isProcessingBatteryResults &&
			isLoadingParticipantDetails &&
			isLoadingBatteryResults,
		data: {
			participantInfo: participantData?.data,
			batteryResults: participantBatteryResults,
			deepBatteryResults,
			cohortResults,
		},
		error: error,
	};
};

export { useGetParticipantDetails };
