import { P1 } from 'components/shared/designSystem';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 24px;
`;

export const FooterText = styled(P1)(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		color: ${theme.color.graphTextSecondary};
	`
);
