import styled from 'styled-components';

export const Container = styled.div(
	({ theme: { colors } }) => `
    display: flex;
    flex: 1;
    border: 1px solid ${colors.gray_40};
    border-radius: 10px;
`
);

export const ClassificationHeader = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0 20px 16px;
    background-color: ${colors.gray_70};
    border-top-right-radius: 10px;
    border-bottom: 1px solid ${colors.gray_40};
`
);

export const ClassificationContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ClassificationContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 500px;
`;

export const MeasuresContainer = styled.div(
	({ theme: { colors } }) => `
	display: flex;
    flex-direction: column;
	border-right: 1px solid ${colors.gray_40};
    width: 700px;
`
);

export const MeasuresHeader = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	justify-content: space-between;
	padding: 20px 34px;
    background-color: ${colors.gray_70};
    border-top-left-radius: 10px;
    border-bottom: 1px solid ${colors.gray_40};
`
);

export const MeasuresContent = styled.div`
	display: flex;
	flex-direction: column;
`;
