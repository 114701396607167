import { DataSet } from '../graphs/CompositeScales';
import { compositeScalesStrings } from './clockData/compositeScales';
import { getDrawingEfficiencyDataSet } from './clockData/drawingEfficiency';
import { getInformationProcessingDataSet } from './clockData/informationProcessing';
import { getMotorDataSet } from './clockData/simpleAndComplexMotor';
import { getSpatialReasoningDataSet } from './clockData/spatialReasoning';
import { MetricHash } from '../metrics';
import { TFunction } from 'react-i18next';

// Create some information about the dataSet that can be used for dynamic props
type DataSetCharacteristics = {
	min: number;
	max: number;
	yTickValues: number[];
};
export const getDataSetCharacteristics = (
	dataSet: DataSet
): DataSetCharacteristics => {
	//Calc the min and max, defaulting to -3, 3 unless values are larger/smaller
	const fullList = [...dataSet.commandClock, ...dataSet.copyClock];
	const numList = fullList.map((item) => {
		return Number(item.y);
	});
	const yMin = Math.floor(Math.min(...numList, -2) - 1);
	const yMax = Math.ceil(Math.max(...numList, 2) + 1);

	//Calc the tick values
	const tickValues = [];
	for (let i = yMax; i >= yMin; i--) {
		tickValues.push(i);
	}

	return {
		min: yMin,
		max: yMax,
		yTickValues: tickValues,
	};
};

//Get a list of any concerning metrics where score is >= 1 std below 0
export const getConcerningScores = (
	data: MetricHash,
	t: TFunction
): string[] => {
	//Helper methods
	const flattenList = (list: DataSet) => {
		return [...list.commandClock, ...list.copyClock];
	};
	const findConcerningScores = (dataSet: DataSet) => {
		return flattenList(dataSet).filter((item) => item.y <= -1);
	};

	//Get all the subscale datasets
	const drawingEfficiency = findConcerningScores(
		getDrawingEfficiencyDataSet(data, t)
	);
	const motor = findConcerningScores(getMotorDataSet(data, t));
	const infoProcessing = findConcerningScores(
		getInformationProcessingDataSet(data, t)
	);
	const spatialReasoning = findConcerningScores(
		getSpatialReasoningDataSet(data, t)
	);

	//Create and return array of the top-level metrics with concerning subscale scores
	const concerns = [];
	if (drawingEfficiency.length > 0) {
		concerns.push(compositeScalesStrings.drawingEfficiency);
	}
	if (motor.length > 0) {
		concerns.push(compositeScalesStrings.motor);
	}
	if (infoProcessing.length > 0) {
		concerns.push(compositeScalesStrings.infoProcessing);
	}
	if (spatialReasoning.length > 0) {
		concerns.push(compositeScalesStrings.spatialReasoning);
	}
	return concerns;
};
