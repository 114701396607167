/*eslint-disable no-mixed-spaces-and-tabs */
import { filter, first, get } from 'lodash';
import { compose } from 'lodash/fp';

import { FormattedSegmentResult } from '../../../../../components/report/common';
import { MetricHash } from '../../../../../components/report/metrics';
import {
	Answer,
	EpsomData,
	EpsomScores,
	QuestionIdPattern,
} from '../../../../../types/epsomTypes';
import { coerceToBoolean } from '../../../../../utils/coerceToBoolean';
import { questionIdPatterns } from '../constants/epsomQuestionIds';

function pullAnswersByQuestion<T extends (typeof questionIdPatterns)[number]>(
	pattern: T
): (args: EpsomData['answers']) => Answer<T>[] {
	const regex = new RegExp(pattern);
	return (answers) => {
		return filter(answers, (answer) => {
			return answer.questionId.match(regex) && answer.type === 'ANSWERED';
		}) as Answer<T>[];
	};
}

const getScoreMapper = (
	rating: Array<Answer<'rating'>>,
	metrics?: MetricHash
) => {
	return metrics
		? (v: string, i: number) => {
				const val = Number(get(metrics, `item_${i + 1}_rating.value`));
				const warning = coerceToBoolean(
					get(metrics, `item_${i + 1}_warning_badge.value`)
				);
				return {
					input: v,
					score: val,
					warning,
				};
		  }
		: (v: string, i: number) => {
				const val =
					rating.find(
						(rating) => rating.questionId === `rating_${i + 1}`
					)?.value || 0; //defaulting for TS, this shouldnt happen based on the data contract
				return {
					input: v,
					score: val + 1,
					warning: val + 1 < 3,
				};
		  };
};

function mapScores(
	answers: EpsomData['answers'],
	metrics?: FormattedSegmentResult['metricItems']
) {
	const ranking = pullAnswersByQuestion('ranking')(answers);
	const rating = pullAnswersByQuestion('rating')(answers);
	if (!ranking.length) return []; //defaulting for TS, this shouldnt happen based on the data contract
	const ordered = ranking[0].value;
	return ordered.map(getScoreMapper(rating, metrics));
}

function mapStarred(answers: Answer[], ranking: Answer<'ranking'>) {
	return answers.map((v) => {
		return {
			input: v.value,
			starred: !!ranking.value.includes(v.value as string),
		};
	});
}

const getDailyTasks = pullAnswersByQuestion('daily_task');
const getLifeEnjoyment = pullAnswersByQuestion('life_enjoyment');
const getSenseOfSelf = pullAnswersByQuestion('sense_of_self');
const getRelationship = pullAnswersByQuestion('relationship');
const getThinkingAbility = pullAnswersByQuestion('thinking_ability');
const getOther = pullAnswersByQuestion('other');
const getRanking = compose(first, pullAnswersByQuestion('ranking'));

function formatRawData(rawData: EpsomData) {
	const ranks = getRanking(rawData.answers);
	const dailyTask = mapStarred(getDailyTasks(rawData.answers), ranks);
	const lifeEnjoyment = mapStarred(getLifeEnjoyment(rawData.answers), ranks);
	const senseOfSelf = mapStarred(getSenseOfSelf(rawData.answers), ranks);
	const relationship = mapStarred(getRelationship(rawData.answers), ranks);
	const thinkingAbility = mapStarred(
		getThinkingAbility(rawData.answers),
		ranks
	);
	const other = mapStarred(getOther(rawData.answers), ranks);
	return {
		[QuestionIdPattern.daily_task]: dailyTask,
		[QuestionIdPattern.life_enjoyment]: lifeEnjoyment,
		[QuestionIdPattern.sense_of_self]: senseOfSelf,
		[QuestionIdPattern.relationship]: relationship,
		[QuestionIdPattern.thinking_ability]: thinkingAbility,
		[QuestionIdPattern.other]: other,
	};
}
export function mergeEpsomScores(
	rawData: EpsomData,
	segmentResult: FormattedSegmentResult
): EpsomScores {
	const scores = mapScores(rawData.answers, segmentResult.metricItems);
	return {
		total: Number(get(segmentResult.metricItems, 'total_confidence.value')),
		[QuestionIdPattern.ranking]: scores,
		...formatRawData(rawData),
	};
}
export function generateEpsomScores(data: EpsomData): EpsomScores {
	const scores = mapScores(data.answers);
	return {
		total: scores.reduce((acc, v) => v.score + acc, 0),
		[QuestionIdPattern.ranking]: scores,
		...formatRawData(data),
	};
}
