import {
	OrganizationType,
	PaginatedOrganization,
	User,
	UserOrganizationsRoleStatus,
} from '@lh/eng-platform-organization-service-rest-client';
import { isEmpty } from 'lodash';

import { RoleKeys } from '../../constants/roles';

export type ValidResearchOrgParams = {
	userOrganizations?: PaginatedOrganization[];
	defaultOrganizationId?: string;
};

export type UserNavigationOutput = 'FORBIDDEN' | 'ONBOARD' | 'ACTIVE';

/**
 * These specific roles are not allowed to log into any of the portals UNLESS its coming from /remoteAuth (which is
 * the landing zone for impersonation attempts from MGMT portal)
 * */
export const logoutDueToRole = (roleKey: string) => {
	if (isEmpty(roleKey)) return true;

	const nonLoginLinusRoles = [
		RoleKeys.LINUS_INTEGRATIONS_ADMIN.toString(),
		RoleKeys.LINUS_ITSEC.toString(),
		RoleKeys.LINUS_READ_ONLY.toString(),
	];

	return (
		nonLoginLinusRoles.includes(roleKey) &&
		!window.location.pathname.includes('remoteAuth')
	);
};

export function getActiveUserBasedOnRole(user: User) {
	let organizationId = '';
	let redirectSymbol = null;
	let role = null;

	const primaryRole = user.roles.find((e) => e.primary);

	if (
		primaryRole &&
		primaryRole.status === UserOrganizationsRoleStatus.Active &&
		primaryRole.organizationType === OrganizationType.Research
	) {
		organizationId = primaryRole.organizationId;
		role = primaryRole;
	} else {
		redirectSymbol = 'r';

		const activeRole = user.roles.find(
			(role) =>
				role.status === UserOrganizationsRoleStatus.Active &&
				role.organizationType === OrganizationType.Research
		);
		if (activeRole) {
			role = activeRole;
			organizationId = activeRole.organizationId;
		} else {
			redirectSymbol =
				primaryRole?.organizationType === OrganizationType.Linus
					? 'm'
					: 'c';
		}
	}

	return { organizationId, primaryRole, redirectSymbol, role };
}
