import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ScoreWithInterpretation, Typography } from '@lh/eng-web-mosaic-common';

import { ConfidenceScoreRange } from '../../../../common/constants/confidenceScore';
import {
	getConfidenceScoreDescriptionTransKey,
	getConfidenceScoreRange,
} from '../../../../common/helpers/confidenceScoreUtils';

type Props = {
	totalScore?: number;
};

export const ConfidenceScoreWithInterpretation = (props: Props) => {
	const achievedScore = props?.totalScore;

	const { t } = useTranslation();

	const scoreToDisplay = `${achievedScore}/25`;

	const scoreRange = getConfidenceScoreRange(achievedScore ?? 0);

	const variant = useMemo(() => {
		switch (scoreRange) {
			case ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE:
				return 'GREEN';
			case ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE:
				return 'LIME';
			case ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE:
				return 'YELLOW';
			case ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE:
				return 'ORANGE';
			case ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE:
				return 'RED';
			default:
				return 'DEFAULT';
		}
	}, [scoreRange]);

	const scoreInterpretation = useMemo(
		() =>
			scoreRange !== null
				? getConfidenceScoreDescriptionTransKey(scoreRange)
				: '',
		[scoreRange]
	);

	return (
		<div>
			<ScoreWithInterpretation
				score={scoreToDisplay}
				title={undefined}
				variant={variant}
				renderInterpretationText={() => (
					<Typography.H4 weight='400'>
						{t(scoreInterpretation)}
					</Typography.H4>
				)}
			/>
		</div>
	);
};
