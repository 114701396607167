import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { DeactivateUserEvents } from 'components/team/events';
import { DEBUG, ERROR } from 'logging/linusLogger';
import { UpdateOrgUserStatusInput, UserOrganizationsRoleStatus } from './types';

export async function deactivateOrgUser(
	input: UpdateOrgUserStatusInput
): Promise<void> {
	if (!input?.status || !input?.userId || !input.organizationId) {
		throw new Error('deactivateOrgUser: invalid API request params');
	}
	const service = await getOrganizationService();
	await service.updateUserOrganizationsRoleStatus({
		...input,
		status: UserOrganizationsRoleStatus.Deactivated,
	});
}

export function useDeactivateOrgUser() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error deactivating user',
		},
		mutationFn: deactivateOrgUser,
		onMutate: () => {
			DEBUG(DeactivateUserEvents.INIT);
		},
		onSuccess: () => {
			DEBUG(DeactivateUserEvents.SUCCESS);
			queryClient.invalidateQueries({ queryKey: [QueryKey.Users] });
		},
		onError: (error) => {
			ERROR(DeactivateUserEvents.FAILURE, error);
		},
	});
}
