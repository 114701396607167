import { useState } from 'react';

import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { UserStatus } from 'api/organization';
import { icons } from '../../../enums/icons';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { EditUserModel } from '../editUserForm';
import { ChangeStatusSuccessModal } from './ChangeStatusSuccessModal';

export type PayloadType = { status: UserStatus; action?: string };

type ChangeStatusModalProps = {
	firstname: string;
	lastname: string;
	onCancelModal: () => void;
	onSubmit: (values: EditUserModel, payload: PayloadType) => void;
	statusAction: string;
};

const ChangeStatusModal = ({
	firstname,
	lastname,
	onCancelModal,
	onSubmit,
	statusAction,
}: ChangeStatusModalProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { values } = useFormikContext();
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

	let title;
	let body;
	let icon;
	let iconColor;
	let payload: PayloadType;
	switch (statusAction) {
		case 'deactivateUser': {
			title = t`web.team.changeStatusModal.deactivateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.deactivateUser.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong key={'strong_text_deactivate'} />]}
					/>
				</div>
			);
			icon = icons.RemoveUserSolid;
			iconColor = theme.color.alertError;
			payload = { status: UserStatus.Deactivated };
			break;
		}
		case 'cancelInvitation': {
			title = t`web.team.changeStatusModal.cancelInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.cancelInvitation.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong key={'strong_text_cancel'} />]}
					/>
				</div>
			);
			icon = icons.InviteOutlined;
			iconColor = theme.color.iconInvite;
			payload = { status: UserStatus.Deactivated };

			break;
		}
		case 'resendInvitation': {
			title = t`web.team.changeStatusModal.resendInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.resendInvitation.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong key={'strong_text_resend'} />]}
					/>
				</div>
			);
			icon = icons.InviteOutlined;
			iconColor = theme.color.iconInvite;

			payload = { status: UserStatus.Invited };

			break;
		}
		default: {
			title = t`web.team.changeStatusModal.activateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.activateUser.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong key={'strong_text_activate'} />]}
					/>
				</div>
			);
			icon = icons.AddUserSolid;
			iconColor = theme.color.alertSuccess;
			payload = { status: UserStatus.Active };
		}
	}

	const handleOnSubmit = (): void => {
		onSubmit(values as EditUserModel, payload);
		setShowSuccessModal(true);
	};

	const onCloseSuccessModal = () => {
		setShowSuccessModal(false);
		onCancelModal();
	};
	return (
		<>
			{showSuccessModal ? (
				<ChangeStatusSuccessModal
					firstname={firstname}
					lastname={lastname}
					onCancelModal={onCloseSuccessModal}
					statusAction={statusAction}
				/>
			) : (
				<LinusModalDialog
					onClose={onCancelModal}
					title={title}
					titleIcon={icon}
					titleIconColor={iconColor}
					declineButtonCallback={onCancelModal}
					declineButtonText={t`web.team.sharedModal.cancelButton`}
					acceptButtonCallback={handleOnSubmit}
					acceptButtonText={title}
					width={'500px'}>
					{body()}
				</LinusModalDialog>
			)}
		</>
	);
};

export { ChangeStatusModal };
