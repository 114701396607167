/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from '@lh/eng-shared-auth';

import { ReportType } from 'generated/graphql';
import { DEBUG, ERROR } from 'logging/linusLogger';
import { getConfig } from '../../src/config';

import { koaPost } from './koaRestUtils';

export type PdfGenerateInput = {
	organizationId?: string;
	batteryResultId: string;
	reportType: ReportType;
	language: string;
};

export type PdfDownloadInput = PdfGenerateInput & {
	revision?: number;
};

export type PDFDownloadLinkResponse = {
	status: number;
	downloadLink?: string;
};

type KoaPDFResponse = {
	status: number;
	data: {
		reportUrl: string | undefined;
	};
};

const GET_REPORT_ENDPOINT = '/report';
const GENERATE_REPORT_ENDPOINT = '/generateReport';

const getBaseEndpoint = () => {
	const { apiProtocol, apiHost, apiPort } = getConfig();
	return `${apiProtocol}://${apiHost}:${apiPort}`;
};
export const getPDFDownloadLink = async (
	auth: Auth,
	input: PdfDownloadInput
): Promise<PDFDownloadLinkResponse> => {
	try {
		DEBUG('Getting PDF download link with input:');
		const response: KoaPDFResponse = await koaPost(auth, {
			url: `${getBaseEndpoint()}${GET_REPORT_ENDPOINT}`,
			body: input,
		});

		DEBUG('Response for PDF download link request recieved');
		if (response.status === 200) {
			return {
				status: response.status,
				downloadLink: response.data.reportUrl,
			};
		}

		DEBUG(`Unexpected status received: ${response.status}`);
		return { status: response.status };
	} catch (e: any) {
		ERROR('Error getting PDF download link:', e);
		if (e?.response?.status && e?.response?.status === 404) {
			return { status: 404 };
		}
		throw e;
	}
};

export const generatePDF = async (
	auth: Auth,
	input: PdfGenerateInput
): Promise<PDFDownloadLinkResponse> => {
	try {
		DEBUG('Generating PDF');
		const response: KoaPDFResponse = await koaPost(auth, {
			url: `${getBaseEndpoint()}${GENERATE_REPORT_ENDPOINT}`,
			body: input,
		});

		// Should return 201
		DEBUG('Response for PDF generation request recieved');
		return { status: response.status };
	} catch (e: any) {
		// Generation in progress
		ERROR('Error generating PDF:', e);
		if (e?.response?.status && e?.response?.status === 409) {
			DEBUG('PDF generation already in progress');
			return { status: 409 };
		}

		throw e;
	}
};

export const pollPromiseForPDFLink = async (
	auth: Auth,
	input: PdfGenerateInput
): Promise<PDFDownloadLinkResponse> => {
	return getPDFDownloadLink(auth, input)
		.then((pdfDownloadResponse) => {
			DEBUG('Polling for PDF download link with input:', input);

			if (pdfDownloadResponse.status === 404) {
				DEBUG('Triggering PDF generation');
				return generatePDF(auth, { ...input }).then(({ status }) => {
					if (status === 201 || status === 409) {
						// No-op to continue polling
						DEBUG('PDF generation in progress continue polling');
						throw new Error('Continue polling');
					}
					// This should never occur, it'll short-circuit polling but isn't inherently bad
					DEBUG(`Unexpected status during PDF generation: ${status}`);
					return { status };
				});
			}
			DEBUG('PDF download link found:', pdfDownloadResponse);
			return pdfDownloadResponse;
		})
		.catch((e) => {
			ERROR('Error polling for PDF link:', e.message);
			throw e;
		});
};
