import { EnumItem } from '../enums/sessionStorageKeysEnum';

// TODO: enforce that the key is of type SessionStorageKey, TS was giving me lots of issues

export const setSessionStorage = (
	data: Record<string, unknown> | string,
	key: EnumItem
): void => {
	window.sessionStorage.setItem(key.value, JSON.stringify(data));
};
export const getSessionStorage = (
	key: EnumItem
): Record<string, unknown> | null => {
	const sessionData = window.sessionStorage.getItem(key.value);
	return sessionData ? JSON.parse(sessionData) : null;
};

export const clearSessionStorage = (key: EnumItem): void => {
	window.sessionStorage.removeItem(key.value);
};
