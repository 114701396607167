import { Trials, Typography } from '@lh/eng-web-mosaic-common';
import { Space } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { theme } from '../../../../../../../providers/styleProvider';
import { Link } from '../../../../../../shared/designSystem';
import { ConfidenceScoreData } from '../../LongitudinalEpsom.helpers';
import { ConfidenceScoringDetailsModal } from '../ConfidenceScoringDetailsModal/ConfidenceScoringDetailsModal';

type Props = {
	breakdown?: ConfidenceScoreData['breakdown'];
};

const ConfidenceScoreBreakdown = (props: Props) => {
	const { t } = useTranslation();
	const viewScoringDetailsLinkText = t(
		'research.epsomConfidenceScore.scoringDetails.linkText'
	);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	return (
		<>
			<ConfidenceScoringDetailsModal
				visible={isModalVisible}
				onClose={() => setIsModalVisible(false)}
			/>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<span style={{ marginLeft: theme?.spacing?.sm }}>
					<Typography.P2
						weight='500'
						color={theme?.color?.bodyTextSecondary}>
						{t('web.report.longEpsom.confidenceScore.breakdown')}
					</Typography.P2>
				</span>

				<div
					style={{
						cursor: 'pointer',
					}}>
					<Link onClick={() => setIsModalVisible(true)}>
						{viewScoringDetailsLinkText}
					</Link>
				</div>
			</div>
			<Space h='md' />
			<div>
				{props.breakdown
					?.filter((data) => data?.value !== 0) // The breakdown in this case is the "delta" - we won't show it if delta = 0 (i.e. no change detected)
					.map(({ value, suffix }) => {
						return (
							<div key={suffix}>
								<Trials
									score={value?.toString()}
									responseText={t(suffix)}
									title={''}
								/>
								<Space h={'xs'} />
							</div>
						);
					})}
			</div>
			<Space h='md' />
		</>
	);
};

export { ConfidenceScoreBreakdown };
