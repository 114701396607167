import styled, { useTheme } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { icons } from '../../../enums/icons';
import { participantStrings } from '../participantStrings';
import { H2, Icon, P1 } from '../../shared/designSystem';

interface AddBatteryForParticipantHeaderProps {
	externalId: string;
	hasRemoteAssessmentsFlag?: boolean;
}

const AddBatteryForParticipantHeader = ({
	externalId,
	hasRemoteAssessmentsFlag,
}: AddBatteryForParticipantHeaderProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<StyledHeader
			style={{ width: hasRemoteAssessmentsFlag ? '90%' : '548px' }}>
			<StyledTitle>
				<StyledTitleIcon>
					<Icon
						icon={icons.AddSolid}
						width={40}
						height={40}
						title={t`web.patients.forms.addBattery`}
						color={theme.color.iconAddBattery}
					/>
				</StyledTitleIcon>
				<StyledH2>
					<Trans i18nKey='web.patients.forms.addBattery'></Trans>
				</StyledH2>
			</StyledTitle>
			{hasRemoteAssessmentsFlag ? (
				<StyledSubTitle>
					{t(participantStrings['addBatteryHeader'])}
				</StyledSubTitle>
			) : (
				<StyledSubTitle>
					{`Assign Participant ${externalId} a battery. Please note, activities are unavailable in some languages.`}
				</StyledSubTitle>
			)}
		</StyledHeader>
	);
};

export { AddBatteryForParticipantHeader };

const StyledHeader = styled.div`
	width: 100%;
`;

const StyledTitle = styled.div`
	display: flex;
	justify-content: flex-start;
`;
const StyledSubTitle = styled(P1)(
	({ theme: { spacing } }) => `
	padding-top: ${spacing.sm};
	margin: 0 0 0 0;
`
);

const StyledTitleIcon = styled.div(
	({ theme: { spacing } }) => `
		padding-right:${spacing.md};
		align-self: center;
`
);
const StyledH2 = styled(H2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);
