import axios, { ResponseType } from 'axios';

enum ArtifactResponseType {
	BLOB = 'blob',
	JSON = 'json',
}

export type ArtifactResponse = {
	assetData: string;
	assetCleanup: () => void;
};

type GetArtifactResponse = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	abortController: AbortController;
};

const artifactCleanup = (
	abortController: AbortController,
	blobUrl: string
): boolean => {
	URL.revokeObjectURL(blobUrl);
	abortController.abort();
	return true;
};

const getArtifact = async (
	presignedURL: string,
	responseType: string
): Promise<GetArtifactResponse> => {
	const abortController = new AbortController();
	return axios
		.get(presignedURL, {
			responseType: responseType as ResponseType,
			signal: abortController.signal,
		})
		.then((axiosResponse) => {
			return {
				data: axiosResponse.data,
				abortController: abortController,
			};
		});
};

export const getJsonArtifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.JSON).then(
		(assetResponse) => {
			const assetData = JSON.stringify(assetResponse.data);
			return {
				assetData,
				assetCleanup: () => {
					assetResponse.abortController.abort();
				},
			};
		}
	);
};

export const getPngArtifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.BLOB).then(
		(assetData) => {
			const blob = new Blob([assetData.data], {
				type: 'image/png',
			});
			const blobUrl = URL.createObjectURL(blob);
			return {
				assetData: blobUrl,
				assetCleanup: () => {
					artifactCleanup(assetData.abortController, blobUrl);
				},
			};
		}
	);
};

export const getWebmArtifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.BLOB).then(
		async (assetData) => {
			const blob = new Blob([assetData.data], {
				type: 'audio/webm',
			});
			const blobUrl = URL.createObjectURL(blob);
			return {
				assetData: blobUrl,
				assetCleanup: () => {
					artifactCleanup(assetData.abortController, blobUrl);
				},
			};
		}
	);
};

export const getMp4Artifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.BLOB).then(
		(assetData) => {
			const blob = new Blob([assetData.data], {
				type: 'audio/mp4',
			});
			const blobUrl = URL.createObjectURL(blob);
			return {
				assetData: blobUrl,
				assetCleanup: () => {
					artifactCleanup(assetData.abortController, blobUrl);
				},
			};
		}
	);
};

export const getWavArtifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.BLOB).then(
		(assetData) => {
			const blob = new Blob([assetData.data], {
				type: 'audio/wav',
			});
			const blobUrl = URL.createObjectURL(blob);
			return {
				assetData: blobUrl,
				assetCleanup: () => {
					artifactCleanup(assetData.abortController, blobUrl);
				},
			};
		}
	);
};
