// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const StayTrueToPurposeLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='StayTrueToPurposeLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'StayTrueToPurposeLHQ'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.2855 7.42857C17.2855 8.21755 16.6459 8.85714 15.857 8.85714C15.068 8.85714 14.4284 8.21755 14.4284 7.42857C14.4284 6.63959 15.068 6 15.857 6C16.6459 6 17.2855 6.63959 17.2855 7.42857ZM13.357 11.7147L11.9284 8.14323C11.9284 8.14323 14.0712 9.5718 15.857 9.5718C17.6427 9.5718 19.7855 8.14323 19.7855 8.14323L18.357 11.7147H13.357ZM19.7855 12.4284V13.1427H11.9284V12.4284H19.7855ZM12.6432 22.4282H19.0718L18.3575 13.8568H13.3575L12.6432 22.4282ZM10.5 24.5716H21.2143V26.0002H10.5V24.5716ZM19.7855 23.1432H11.9284V23.8575H19.7855V23.1432Z'
				fill={color}
			/>
		</svg>
	);
};
