// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const KeepHealthInCheckLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='KeepHealthInCheckLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'KeepHealthInCheckLHQ'}</title>
			<path
				d='M21.9623 16.8172L21.9663 15.6129L23.8291 15.6192C25.2046 9.81083 17.8848 6.28763 15.9368 11.7744C13.6558 6.29108 6.91709 9.69424 8.14916 15.4031L11.7857 15.3844L13.2836 13.4086C13.681 13.0527 14.0265 13.0974 14.3201 13.5428L16.0393 17.7116L17.5208 14.5983C17.7874 14.2069 18.1112 14.1518 18.4922 14.4331L19.6512 15.6023L20.7658 15.613L20.7542 16.8172L19.3921 16.8042C19.2335 16.8026 19.082 16.7386 18.9703 16.626L18.2402 15.8895L16.5438 19.4544C16.3211 19.9223 15.6439 19.9115 15.4434 19.4252L13.6078 14.9745L12.5658 16.3489C12.4527 16.4982 12.2764 16.5863 12.0891 16.5873L8.51819 16.6056C9.4338 18.9102 11.6534 21.489 15.8838 23.968H15.895C20.1895 21.5618 22.465 19.0673 23.4283 16.8222L21.9623 16.8172Z'
				fill={color}
			/>
		</svg>
	);
};
