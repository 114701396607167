/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { fetchSegmentResultArtifact } from 'api/battery';
import { QueryKey } from 'api/query';
import {
	ArtifactResponse,
	getJsonArtifact,
	getMp4Artifact,
	getPngArtifact,
	getWavArtifact,
	getWebmArtifact,
} from '../../../artifacts/ArtifactService';
import { ArtifactFormats } from './types';

export const useGetReportArtifact = () => {
	const queryClient = useQueryClient();

	const getFetchArtifactHandler = useCallback((type: ArtifactFormats) => {
		let fetchArtifact: (presignedUrl: string) => Promise<ArtifactResponse>;
		switch (type) {
			case ArtifactFormats.PNG: {
				fetchArtifact = getPngArtifact;
				break;
			}
			case ArtifactFormats.MP4: {
				fetchArtifact = getMp4Artifact;
				break;
			}
			case ArtifactFormats.JSON: {
				fetchArtifact = getJsonArtifact;
				break;
			}
			case ArtifactFormats.WAV: {
				fetchArtifact = getWavArtifact;
				break;
			}
			case ArtifactFormats.WEBM: {
				fetchArtifact = getWebmArtifact;
				break;
			}
		}
		return fetchArtifact;
	}, []);

	const getReportArtifact = useCallback(
		async ({ segmentResultId, fileName, fileType }: any) => {
			try {
				const presignedUrl = await queryClient.fetchQuery({
					queryKey: [
						QueryKey.SegmentArtifact,
						segmentResultId,
						fileName,
					],
					queryFn: () =>
						fetchSegmentResultArtifact({
							segmentResultId,
							fileName,
						}),
				});
				const artifactFetcher = await getFetchArtifactHandler(fileType);
				const response = await artifactFetcher(presignedUrl);
				return response;
			} catch (error) {
				console.error(error);
			}
		},
		[getFetchArtifactHandler, queryClient]
	);

	return { getReportArtifact };
};
