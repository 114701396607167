// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Star = ({
	title,
	width = '24',
	height = '24',
	color = '#FFCA28',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 16 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Star-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Star'}</title>
			<path
				d='M11.7228 6.80476L9.46692 6.47341C9.39857 6.47341 9.35296 6.42603 9.33023 6.35504L8.30488 4.17756C8.19092 3.94081 7.84919 3.94081 7.73522 4.17756L6.70987 6.30762C6.68714 6.37861 6.6188 6.40222 6.57318 6.42599L4.27179 6.78095C4.02113 6.82833 3.90717 7.15968 4.08948 7.34903L5.7301 9.00576C5.77572 9.05314 5.79845 9.12414 5.77572 9.19513L5.38837 11.5856C5.34275 11.8459 5.6163 12.0589 5.84406 11.9405L7.87205 10.8281C7.91767 10.8045 7.98601 10.8045 8.05436 10.8281L10.128 11.9642C10.3559 12.0825 10.6293 11.8932 10.5837 11.6092L10.1963 9.26601C10.1963 9.19501 10.1963 9.12402 10.2419 9.07664L11.9053 7.37253C12.0874 7.18349 11.9962 6.85216 11.7228 6.80474V6.80476Z'
				fill={color}
			/>
		</svg>
	);
};
