import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import styled, { useTheme } from 'styled-components';

type CloseIconProps = {
	size: string;
};
export const CloseIcon = ({ size }: CloseIconProps) => {
	const theme = useTheme();

	return (
		<ContainerDiv className='close-icon-cmp'>
			<Icon
				icon={icons.Close}
				width={size}
				height={size}
				color={theme.colors.orange}
			/>
		</ContainerDiv>
	);
};

const ContainerDiv = styled.div`
	padding-right: 3px;
	margin-left: 4px;
`;
