import { EpsomDataWithoutScores } from 'app/src/types/epsomTypes';
import styled from 'styled-components';
import { AnswersGroupedByCategory } from './AnswersGroupedByCategory';
import { ParticipantResponsesHeader } from './ParticipantResponsesHeader';

type ParticipantWrittenResponsesProps = {
	epsomWrittenResponses: EpsomDataWithoutScores;
};

function ParticipantWrittenResponses({
	epsomWrittenResponses,
}: Readonly<ParticipantWrittenResponsesProps>) {
	return (
		<StyledWrapper>
			<ParticipantResponsesHeader />
			<br />
			<br />
			<AnswersGroupedByCategory groupedAnswers={epsomWrittenResponses} />
		</StyledWrapper>
	);
}

const StyledWrapper = styled.div(
	({ theme: { color, spacing } }) => `
		background: ${color.white};
		box-shadow: 0px 2px 10px rgba(23, 24, 32, 0.1);
		border-radius: 20px;
		padding: ${spacing.xl};
		margin-top: ${spacing.xl};
	`
);

export { ParticipantWrittenResponses };
