/* eslint-disable no-mixed-spaces-and-tabs */

export enum AnswerType {
	ANSWERED = 'ANSWERED',
	'NOT_ANSWERED' = 'NOT_ANSWERED',
}

export enum QuestionIdPattern {
	'daily_task' = 'daily_task',
	'life_enjoyment' = 'life_enjoyment',
	'sense_of_self' = 'sense_of_self',
	'relationship' = 'relationship',
	'thinking_ability' = 'thinking_ability',
	'other' = 'other',
	'rating' = 'rating',
	'ranking' = 'ranking',
}

export const QuestionIdPatternTransKeyMap = {
	[QuestionIdPattern.daily_task]: 'dailyTasks',
	[QuestionIdPattern.life_enjoyment]: 'lifeEnjoyment',
	[QuestionIdPattern.sense_of_self]: 'senseOfSelf',
	[QuestionIdPattern.relationship]: 'relationship',
	[QuestionIdPattern.thinking_ability]: 'thinkingAbility',
	[QuestionIdPattern.other]: 'other',
	[QuestionIdPattern.rating]: 'rating',
	[QuestionIdPattern.ranking]: 'ranking',
};

export type Answer<T = string> = T extends 'ranking'
	? {
			questionId: 'ranking';
			type: AnswerType;
			value: string[];
	  }
	: T extends 'rating'
	? {
			questionId: string;
			type: AnswerType;
			value: number;
	  }
	: {
			questionId: string;
			type: AnswerType;
			value: string;
	  };

export type EpsomAnswer = {
	input: string;
	starred: boolean;
};
type EpsomScore = {
	input: string;
	score: number;
	warning: boolean;
};

export type EpsomData = {
	id: string;
	answers: Answer[];
	endTime: string;
	locale: string;
	startTime: string;
	timezone: string;
	version: string;
};

export type EpsomScores = {
	total: number;
	[QuestionIdPattern.daily_task]: EpsomAnswer[];
	[QuestionIdPattern.life_enjoyment]: EpsomAnswer[];
	[QuestionIdPattern.sense_of_self]: EpsomAnswer[];
	[QuestionIdPattern.thinking_ability]: EpsomAnswer[];
	[QuestionIdPattern.relationship]: EpsomAnswer[];
	[QuestionIdPattern.other]: EpsomAnswer[];
	[QuestionIdPattern.ranking]: EpsomScore[];
};

export type EpsomDataWithoutScores = Omit<
	EpsomScores,
	'ranking' | 'rating' | 'total'
>;

export type CategoryPattern = QuestionIdPattern | string;
export type CategoryDisplayName = typeof QuestionIdPatternTransKeyMap | string;
