import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from '@mantine/core';

import { ScoreWithInterpretation, Typography } from '@lh/eng-web-mosaic-common';

import { RcrInterpretation } from '../Interpretation/RcrInterpretation';
import { AudioCardProps } from '../common/AudioCard/AudioCard';
import { AudioCardGrid } from '../common/AudioCardGrid/AudioCardGrid';
import { GenericReportCard } from '../common/GenericReportCard/GenericReportCard';
import {
	BackwardsDigitSpanTable,
	BackwardsDigitSpanTableProps,
} from './components/BackwardsDigitSpan.table';

export type BackwardsDigitSpanLayoutProps = {
	header: {
		title: string;
		tooltipText?: string;
	};
	score: {
		requiresClinicianReview: boolean;
		quartileText: string | undefined | null;
		total: {
			title: string;
			value: string;
		};
		breakdown: BackwardsDigitSpanTableProps['data'];
	};
	showAudioPlayers?: boolean;
	audioCardData: AudioCardProps[];
};

const BackwardsDigitSpanLayout = (props: BackwardsDigitSpanLayoutProps) => {
	const { t } = useTranslation();

	const renderScoreComponent = useMemo(() => {
		return (
			<ScoreWithInterpretation
				score={props?.score?.total?.value}
				title={props?.score?.total?.title}
				renderInterpretationText={function (): JSX.Element {
					if (props?.score?.requiresClinicianReview) {
						return (
							<RcrInterpretation
								interpretationTransKey={
									'web.report.verbalFluency.reviewRequired.listenToRecording'
								}
							/>
						);
					} else {
						// e.g. Performance is in the <b>2nd quartile (25th to 49th percentile)</b>
						if (props?.score?.quartileText) {
							return (
								<Typography.P2 weight='500'>
									{t(
										'web.report.verbalFluency.performancePrefix'
									)}{' '}
									<Typography.P2 weight='700'>
										{props?.score?.quartileText}
									</Typography.P2>
								</Typography.P2>
							);
						} else {
							return <></>;
						}
					}
				}}
			/>
		);
	}, [
		props?.score?.quartileText,
		props?.score?.requiresClinicianReview,
		props?.score?.total?.title,
		props?.score?.total?.value,
		t,
	]);

	return (
		<div data-testid='bdst-layout-container'>
			<Space h={'md'} />
			<GenericReportCard
				header={{
					titleText: props?.header?.title,
					tooltipText: props?.header?.tooltipText,
				}}
				content={[
					{
						weight: 1,
						component: () => renderScoreComponent,
					},
					{
						weight: 1.5,
						component: () => (
							<BackwardsDigitSpanTable
								data={props?.score?.breakdown}
							/>
						),
					},
				]}
			/>
			<Space h={'sm'} />
			{props?.showAudioPlayers && (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={props?.audioCardData}
				/>
			)}
		</div>
	);
};

export { BackwardsDigitSpanLayout };
