import { Flex } from '@mantine/core';
import { P1 } from '../../../../../../shared/designSystem';
import { Star } from '../../../../../../shared/designSystem/Icons';
import { theme } from '../../../../../../../providers/styleProvider';
import { EpsomAnswer } from '../../../../../../../types/epsomTypes';
import styled from 'styled-components';

const ANSWER_CHARACTER_LIMIT = 50; // Already enforced from epSOM side of things (RA) but added here as a safety measure anyways

type StarredAnswerProps = {
	answer: EpsomAnswer;
};

function StarredAnswer(props: StarredAnswerProps) {
	if (!props) return null;

	const { answer } = props;
	const trimmedAnswer = answer?.input?.slice?.(0, ANSWER_CHARACTER_LIMIT);

	return (
		<StyledP1RegularText>
			<Flex direction={'row'}>
				{/* Answer "value" (as input by user) */}
				<span data-testid={`answer-text`}>{trimmedAnswer ?? ''}</span>
				{/* Star for important answers */}
				{answer?.starred ? (
					<div data-testid={`answer-svg-icon`}>
						<Star
							color={theme.colors.blue}
							height={18}
							width={18}
						/>
					</div>
				) : null}
			</Flex>
		</StyledP1RegularText>
	);
}

export { StarredAnswer };

const StyledP1RegularText = styled(P1)(
	({ theme: { color, weight } }) => `
    margin: 0 0 0 0;
    font-weight: ${weight.regular};
    color: ${color.bodyText};
`
);
