// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const PyramidArrowUp = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 8 5'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='PyramidArrowUp-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'PyramidArrowUp'}</title>
			<path d='M7.55554 4.74073C7.7353 4.74073 7.89736 4.62522 7.96616 4.44807C8.03495 4.27092 7.99692 4.06702 7.86981 3.93143L4.31427 0.138853C4.1407 -0.0462842 3.8593 -0.0462842 3.68573 0.138853L0.130187 3.93143C0.00307759 4.06702 -0.0349472 4.27092 0.0338443 4.44807C0.102635 4.62522 0.264695 4.74072 0.444456 4.74072L7.55554 4.74073Z' />
		</svg>
	);
};
