// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const LogoStacked = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 284 161'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='LogoStacked-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'LogoStacked'}</title>

			<g clipPath='url(#clip0_93_101)'>
				<path
					d='M0 133.855C0 132.497 1.07571 131.415 2.47721 131.415C3.83568 131.415 4.91139 132.497 4.91139 133.855V155.111H17.5802C18.8219 155.111 19.8177 156.107 19.8177 157.349C19.8177 158.591 18.8219 159.586 17.5802 159.586H2.47721C1.07571 159.586 0 158.511 0 157.109V133.855Z'
					fill='#007DAD'
				/>
				<path
					d='M24.4094 133.855C24.4094 132.497 25.4851 131.415 26.8866 131.415C28.2451 131.415 29.327 132.497 29.327 133.855V157.349C29.327 158.707 28.2513 159.789 26.8866 159.789C25.4913 159.789 24.4094 158.713 24.4094 157.349V133.855Z'
					fill='#007DAD'
				/>
				<path
					d='M35.1973 133.935C35.1973 132.577 36.273 131.458 37.6745 131.458H38.1908C39.3895 131.458 40.0656 132.054 40.7479 132.896L54.7322 150.999V133.818C54.7322 132.503 55.8079 131.421 57.1295 131.421C58.488 131.421 59.5698 132.503 59.5698 133.818V157.312C59.5698 158.67 58.531 159.746 57.1725 159.746H56.9697C55.814 159.746 55.0948 159.15 54.4125 158.265L40.0287 139.646V157.386C40.0287 158.707 38.953 159.783 37.6314 159.783C36.273 159.783 35.1973 158.707 35.1973 157.386V133.935V133.935Z'
					fill='#007DAD'
				/>
				<path
					d='M64.7271 147.723V133.855C64.7271 132.497 65.8089 131.415 67.2043 131.415C68.5627 131.415 69.6446 132.497 69.6446 133.855V147.52C69.6446 152.714 72.3185 155.474 76.7135 155.474C81.0717 155.474 83.7456 152.88 83.7456 147.723V133.855C83.7456 132.497 84.8213 131.415 86.2228 131.415C87.5813 131.415 88.657 132.497 88.657 133.855V147.483C88.657 155.831 83.9423 160.029 76.6275 160.029C69.3618 160.029 64.7271 155.831 64.7271 147.723Z'
					fill='#007DAD'
				/>
				<path
					d='M93.2918 156.47C92.7324 156.07 92.3329 155.394 92.3329 154.595C92.3329 153.316 93.3717 152.315 94.6503 152.315C95.3264 152.315 95.769 152.511 96.0887 152.751C98.406 154.589 100.883 155.628 103.92 155.628C106.956 155.628 108.874 154.189 108.874 152.112V152.032C108.874 150.034 107.756 148.952 102.561 147.754C96.605 146.315 93.2488 144.557 93.2488 139.4V139.32C93.2488 134.525 97.2443 131.206 102.801 131.206C106.317 131.206 109.157 132.128 111.671 133.8C112.23 134.12 112.747 134.796 112.747 135.761C112.747 137.039 111.708 138.041 110.429 138.041C109.95 138.041 109.55 137.918 109.151 137.685C106.993 136.283 104.916 135.564 102.715 135.564C99.8383 135.564 98.1602 137.046 98.1602 138.884V138.963C98.1602 141.121 99.4387 142.08 104.836 143.358C110.749 144.797 113.786 146.918 113.786 151.552V151.632C113.786 156.869 109.667 159.986 103.797 159.986C100.004 159.986 96.4083 158.787 93.2918 156.47Z'
					fill='#007DAD'
				/>
				<path
					d='M130.77 133.855C130.77 132.497 131.845 131.415 133.247 131.415C134.605 131.415 135.687 132.497 135.687 133.855V143.248H149.032V133.855C149.032 132.497 150.108 131.415 151.466 131.415C152.868 131.415 153.943 132.497 153.943 133.855V157.349C153.943 158.707 152.868 159.789 151.466 159.789C150.108 159.789 149.032 158.713 149.032 157.349V147.797H135.687V157.349C135.687 158.707 134.611 159.789 133.247 159.789C131.851 159.789 130.77 158.713 130.77 157.349V133.855Z'
					fill='#007DAD'
				/>
				<path
					d='M159.537 157.109V134.095C159.537 132.694 160.613 131.618 162.014 131.618H178.279C179.478 131.618 180.48 132.614 180.48 133.818C180.48 135.06 179.484 136.019 178.279 136.019H164.455V143.291H176.484C177.683 143.291 178.685 144.293 178.685 145.528C178.685 146.727 177.689 147.686 176.484 147.686H164.455V155.197H178.482C179.681 155.197 180.683 156.193 180.683 157.398C180.683 158.634 179.687 159.599 178.482 159.599H162.02C160.619 159.586 159.537 158.511 159.537 157.109Z'
					fill='#007DAD'
				/>
				<path
					d='M184.709 156.39L195.097 133.216C195.656 131.974 196.658 131.218 198.054 131.218H198.293C199.695 131.218 200.654 131.974 201.213 133.216L211.602 156.39C211.798 156.746 211.884 157.109 211.884 157.429C211.884 158.744 210.889 159.783 209.567 159.783C208.411 159.783 207.606 159.101 207.17 158.068L204.889 152.831H191.304L188.95 158.228C188.551 159.224 187.708 159.789 186.67 159.789C185.391 159.789 184.389 158.793 184.389 157.509C184.389 157.152 184.512 156.789 184.709 156.39ZM203.008 148.479L198.091 137.169L193.173 148.479H203.008Z'
					fill='#007DAD'
				/>
				<path
					d='M215.794 133.855C215.794 132.497 216.869 131.415 218.271 131.415C219.629 131.415 220.705 132.497 220.705 133.855V155.111H233.374C234.616 155.111 235.611 156.107 235.611 157.349C235.611 158.591 234.616 159.586 233.374 159.586H218.271C216.869 159.586 215.794 158.511 215.794 157.109V133.855Z'
					fill='#007DAD'
				/>
				<path
					d='M242.601 136.173H235.808C234.53 136.173 233.528 135.134 233.528 133.892C233.528 132.657 234.523 131.612 235.808 131.612H254.39C255.632 131.612 256.628 132.651 256.628 133.892C256.628 135.134 255.632 136.173 254.39 136.173H247.555V157.349C247.555 158.707 246.436 159.789 245.078 159.789C243.719 159.789 242.601 158.713 242.601 157.349V136.173V136.173Z'
					fill='#007DAD'
				/>
				<path
					d='M260.826 133.855C260.826 132.497 261.902 131.415 263.303 131.415C264.662 131.415 265.744 132.497 265.744 133.855V143.248H279.089V133.855C279.089 132.497 280.164 131.415 281.523 131.415C282.924 131.415 284 132.497 284 133.855V157.349C284 158.707 282.924 159.789 281.523 159.789C280.164 159.789 279.089 158.713 279.089 157.349V147.797H265.744V157.349C265.744 158.707 264.668 159.789 263.303 159.789C261.908 159.789 260.826 158.713 260.826 157.349V133.855Z'
					fill='#007DAD'
				/>
				<path
					d='M189 88.4179V88.4179C189 96.5377 182.581 103.063 174.593 103.063H109.407C101.419 103.063 95 96.5377 95 88.4179V88.4179C95 80.2981 101.419 73.7733 109.407 73.7733H174.593C182.581 73.7733 189 80.2981 189 88.4179Z'
					fill='#86C65A'
				/>
				<path
					d='M109.407 103.063V103.063C101.419 103.063 95 96.5377 95 88.4179V16.6446C95 8.52484 101.419 2 109.407 2V2C117.395 2 123.813 8.52484 123.813 16.6446V88.4179C123.813 96.5377 117.395 103.063 109.407 103.063Z'
					fill='#087DAE'
				/>
				<path
					d='M95 88.1279V88.7079C95 96.6827 101.276 103.063 109.121 103.063H109.692C117.537 103.063 123.813 96.6827 123.813 88.7079V88.1279C123.813 84.213 123.813 73.7733 123.813 73.7733C123.813 73.7733 113.543 73.7733 109.692 73.7733H109.121C101.276 73.7733 95 80.1531 95 88.1279Z'
					fill='#009EA1'
				/>
			</g>
			<defs>
				<clipPath id='clip0_93_101'>
					<rect width='284' height='160.029' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
