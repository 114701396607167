import { t } from '../../i18n';
import * as yup from 'yup';

export type CreateInvitedAccount = {
	firstName: string;
	lastName: string;
	email: string;
};

export const getModel = (
	params: CreateInvitedAccount
): CreateInvitedAccount => ({
	firstName: params.firstName,
	lastName: params.lastName,
	email: params.email,
});

export const createInvitedAccountSchema = yup.object().shape({
	firstName: yup.string().required(t`web.shared.forms.schemas.required`),
	lastName: yup.string().required(t`web.shared.forms.schemas.required`),
	email: yup
		.string()
		.email(t`web.authErrors.invalidEmail`)
		.required(t`web.authErrors.emailRequired`),
});
