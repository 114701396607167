export {
	DefaultApi as RemoteAssessmentsService,
	Configuration as RAConfiguration,
	GetSendReceiptsFilterField,
	GetSendReceiptsFilterOperator,
	GetSendReceiptsLogicalOperator,
	type PaginatedReceipts,
	type PostIndividualLinkBody as CreateLinkInput,
	type Link,
	type SendReceipt,
} from '@lh/eng-prd-remote-assessments-service-rest-client';
