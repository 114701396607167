import React, { RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { isNonEmptyString } from 'utils/stringUtils';

export type TabItem = {
	name: string;
	component: React.ReactNode;
};
export type TabbedLayoutProps = {
	tabs: TabItem[];
};
const TabbedLayout = ({ tabs }: TabbedLayoutProps): JSX.Element => {
	const [activeTab, setActiveTab] = useState<TabItem>(tabs[0]);
	const hasMultipleTabs = tabs.length > 1;

	const location = useLocation();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const _deeplinkTab = (location?.state as any)?.activeTab;
		if (isNonEmptyString(_deeplinkTab)) {
			const matchingTab = tabs.find(
				(tabItem) => tabItem.name === _deeplinkTab
			);
			setActiveTab(matchingTab ?? tabs[0]);
		} else {
			setActiveTab(tabs[0]);
		}
	}, [location?.state, tabs]);

	return (
		<StyledContainer>
			{hasMultipleTabs && (
				<StyledTabMenu data-testid='tabbed_layout_tab_menu'>
					<StyledList>
						{tabs?.map((tab, idx) => {
							const isTabActive = tab.name === activeTab?.name;
							return (
								<StyledTab
									data-testid={`tab_menu_${tab.name}`}
									active={isTabActive}
									key={tab.name}
									onClick={() => setActiveTab(tabs[idx])}>
									<StyledSpan active={isTabActive}>
										{tab.name}
									</StyledSpan>
								</StyledTab>
							);
						})}
					</StyledList>
				</StyledTabMenu>
			)}
			<StyledWrapper>{activeTab?.component ?? null}</StyledWrapper>
		</StyledContainer>
	);
};

export { TabbedLayout };

export const scrollToRefByOffset = (offsetRef?: RefObject<HTMLDivElement>) => {
	if (offsetRef) {
		offsetRef.current?.parentElement?.parentElement?.scrollTo({
			top: offsetRef.current?.clientHeight || 0,
			behavior: 'smooth',
		});
	}
};

const StyledContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: none;
`;
const StyledTabMenu = styled.div(
	({ theme: { color } }) => `
	width: 100%;
	height: 57px;
	color: ${color.bodyText};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${color.menuBorder};
`
);
const StyledList = styled.ul`
	list-style: none;
	display: flex;
	align-items: center;
`;
type StyledTabProps = {
	active: boolean;
};
const StyledTab = styled.li<StyledTabProps>(
	({ active, theme: { spacing, color } }) => `
	height: 100%;
	padding: ${spacing.md} ${spacing.lg};
	border-radius: 8px 8px 0 0;
	border: 2px solid ${color.menuBorder};
	border-bottom: 2px solid ${active ? color.menuBorderActive : 'rgba(0,0,0,0)'};
	&:hover {
		background: ${color.cellHoverBg};
		cursor: pointer;

	};
	`
);
const StyledSpan = styled.span<StyledTabProps>(
	({ active, theme: { color, weight } }) => `
	font-weight: ${active ? weight.semi : weight.medium};
	padding: 0;
	margin: 0;
	color: ${active ? color.bodyText : color.menuText};
	`
);
const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;
`;
