import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

import {
	BatteryResult,
	BatteryResultState,
	GetBatteryResultsByParticipantSortField as ResearchResultTableSortField,
	MetricItem,
} from 'api/battery';
import { OperationToken } from 'api/organization';
import { TableColumn } from '../../components/shared/DataTable/DataTable';
import { Notification } from '../../components/shared/DataTable/columnComponents/Notification';
import { ViewResultButton } from '../../components/shared/DataTable/columnComponents/ViewResultButton';
import { ShowIfAuthorized } from '../../components/shared/ShowIfAuthorized';
import { Icon } from '../../components/shared/designSystem';
import { icons } from '../../enums/icons';
import { SortDir } from '../../generated/graphql';
import i18n, { t } from '../../i18n';
import { dateTimeFormatter } from '../../stringStrategy/dateFormatStringStrategy';
import { METRIC_ITEM_CONCERNS } from './participantDetailsSchema';

export type BatteryResultsTableData = {
	firstName?: string;
	lastName?: string;
	externalId?: string;
	avatarUrl?: string;
	batteryName?: string;
	date: string;
	administrator?: string;
	id: string;
	notification: boolean;
	participantId: string;
	processing?: boolean;
	delayed?: boolean;
	visitType: string;
};

export const TOTAL_RESULTS_PER_PAGE = 10;

export const isBatteryResultProcessing = (
	batteryResult: BatteryResult
): boolean => {
	if (!batteryResult) return false;
	return (
		batteryResult.statusContext.state !==
		BatteryResultState.AnalysisComplete
	);
};

export const isBatteryResultConcerning = (
	batteryResult: BatteryResult
): boolean => {
	if (!batteryResult) return false;
	return (
		(!isBatteryResultProcessing(batteryResult) &&
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(batteryResult as any)?.metricItems?.some(
				(mi: MetricItem) =>
					mi.key === METRIC_ITEM_CONCERNS && mi.value === 'true'
			)) ||
		false
	);
};

const columns = (
	theme: DefaultTheme,
	dateFormat: string,
	showVisitTypes = false
): TableColumn<BatteryResultsTableData>[] => {
	const tableColumns: TableColumn<BatteryResultsTableData>[] = [
		{
			propertyName: 'externalId',
			headerDisplay: i18n.t`research.participantModal.participantId`,
			minWidth: '199.5px',
			sortable: false,
			rowColumnComponent: ({ row }) => (
				<Link to={`/participants/${row?.participantId}`}>
					{row?.externalId}
				</Link>
			),
		},
		{
			propertyName: 'batteryName',
			headerDisplay: i18n.t`web.shared.battery`,
			minWidth: '199.5px',
			sortable: false,
		},
		{
			propertyName: 'administrator',
			headerDisplay: i18n.t`web.shared.administrator`,
			minWidth: '199.5px',
			sortable: false,
		},
		{
			propertyName: 'date',
			headerDisplay: i18n.t`web.shared.dateTaken`,
			minWidth: '162px',
			formatProperty: ({ value }) => {
				return dateTimeFormatter(
					value as string,
					dateFormat,
					navigator.language
				);
			},
			sortable: true,
			sortProperty: ResearchResultTableSortField.EndTime,
			sortDir: SortDir.Desc,
		},
		{
			propertyName: 'report',
			headerDisplay: i18n.t`web.shared.report`,
			minWidth: '170px',
			sortable: false,
			rowColumnComponent: ({ row }) =>
				row?.delayed ? (
					<StyledResultsDelayed>
						<Notification
							text={i18n.t`web.shared.resultsTable.resultsDelayedTip`}>
							<Icon
								icon={icons.InfoOutlined}
								title={i18n.t`web.shared.notification`}
								color={theme.color.alertError}
							/>
						</Notification>
						<StyledDelayed>
							<Trans i18nKey='web.shared.resultsTable.resultsDelayed' />
						</StyledDelayed>
					</StyledResultsDelayed>
				) : (
					<ShowIfAuthorized operations={[OperationToken.ViewReport]}>
						<ViewResultButton
							disabled={row?.processing}
							text={
								row?.processing
									? i18n.t`web.shared.resultsTable.processing`
									: i18n.t`web.shared.view`
							}
							batteryResultId={row?.id}
							participantId={row?.participantId}
						/>
					</ShowIfAuthorized>
				),
		},
		{
			propertyName: 'notification',
			headerDisplay: ' ',
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ value }) => {
				return value ? (
					<StyledWrapper>
						<Notification
							text={t`web.shared.resultsTable.concerns`}>
							<Icon
								icon={icons.AlertSolid}
								color={theme.color.alertError}
								title={t`web.shared.notification`}
							/>
						</Notification>
					</StyledWrapper>
				) : (
					<StyledBlank>{` `}</StyledBlank>
				);
			},
		},
	];
	// Add the "Visit" column conditionally
	if (showVisitTypes === true) {
		const dateColumnIndex = tableColumns.findIndex(
			(column) => column?.propertyName === 'date'
		);
		const visitTypeColumn = {
			propertyName: 'visitType',
			headerDisplay: i18n.t`web.visitTypes.visit`,
			minWidth: '162px',
			sortable: true,
			sortProperty: ResearchResultTableSortField.VisitType,
		};
		tableColumns.splice(dateColumnIndex, 0, visitTypeColumn);
	}
	return tableColumns;
};

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const batteryResultsSchema = { columns };
export { batteryResultsSchema };

const StyledBlank = styled.span`
	width: 24px;
`;

const StyledResultsDelayed = styled.div`
	display: flex;
	align-items: center;
`;

const StyledDelayed = styled.span(({ theme: { color, spacing } }) => {
	return `
    color: ${color.deactivatedText};
    padding-left: ${spacing.xs};
    `;
});
