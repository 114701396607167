// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Organization = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Organization-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Organization'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.9875 15.7416V12.9864H15.7426C16.9869 12.9864 18 11.9539 18 10.6852V7.3144C18 6.04567 16.9869 5.0132 15.7426 5.0132H8.50084V12H2.25805C1.5 12 1 11.5 1 10.6862V7.31449C1 6.5 1.5 6 2.25805 6H6V2C6 1.5 6.5 1 7.31366 1H10.6853C11.5 1 12 1.5 12 2V3.66025H12.9866V1.99916C12.9866 1 11.9541 0 10.6854 0L7.31457 0.000843516C6.04584 0.000843516 5.01423 1 5.01423 2V5.01335H2.25823C1.01313 5.01335 0 6.04587 0 7.31456V10.6862C0 11.955 1.01313 12.9874 2.25823 12.9874H9.5L9.50084 6H16C16.4986 6 17 6.79147 17 7.31447V10.6862C17 11.2092 16.4994 12 16 12H12V15.7418C12 16.2412 11.2102 17 10.6872 17H7.31463C6.79162 17 6 16.2412 6 15.7418V14.3398H5.01423V15.7418C5.01423 16.9869 6.04675 18 7.31457 18H10.6863C11.9541 17.9992 12.9875 16.9867 12.9875 15.7416Z'
				fill='current'
			/>
		</svg>
	);
};
