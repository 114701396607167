import styled from 'styled-components';
import { P1 } from '../../../../../shared/designSystem';

type TableHeaderProps = {
	textToDisplay: {
		header: {
			col1: string;
			col2: string;
			col3: string;
		};
	};
};

function TableHeader(props: TableHeaderProps) {
	const headerTextData = props?.textToDisplay?.header ?? null;

	if (!headerTextData) return null;

	return (
		<StyledRow>
			<div style={{ flex: 1 }}>
				<StyledP1Medium data-testid='col1-header'>
					{headerTextData?.col1?.toUpperCase()}
				</StyledP1Medium>
			</div>
			<div
				style={{
					flex: 2,
					textAlign: 'start',
				}}>
				<StyledP1Medium data-testid='col2-header'>
					{headerTextData?.col2?.toUpperCase()}
				</StyledP1Medium>
			</div>
			<div style={{ flex: 1, textAlign: 'end' }}>
				<StyledP1Medium data-testid='col3-header'>
					{headerTextData?.col3?.toUpperCase()}
				</StyledP1Medium>
			</div>
		</StyledRow>
	);
}

export { TableHeader };

const StyledP1Medium = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};	
    
`
);

const StyledRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: ${spacing.sm};    
`
);
