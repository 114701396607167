import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../artifacts/ReportArtifactNames';
import { ArtifactFormats, useGetReportData } from '../../shared/hooks';
import { FormattedSegmentResult } from '../common';
import { mapDataToProps } from './BackwardsDigitSpan.helpers';
import { BackwardsDigitSpanLayout } from './BackwardsDigitSpan.layout';

type BackwardsDigitSpanPropsType = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayback?: boolean;
};

export const BackwardsDigitSpan = ({
	data: segmentResult,
	showAudioPlayback = false,
}: BackwardsDigitSpanPropsType) => {
	const { t: translationFn } = useTranslation();

	const segmentResultId = useMemo(
		() => segmentResult?.id,
		[segmentResult?.id]
	);

	const {
		error,
		loading,
		binaryArtifactData: audioData,
	} = useGetReportData({
		segmentResultId,
		fileName: Json.BackwardsDigitSpanJSON,
		fileType: ArtifactFormats.JSON,
	});

	const layoutProps = useMemo(
		() => mapDataToProps({ segmentResult, audioData, translationFn }),
		[audioData, segmentResult, translationFn]
	);

	if (!segmentResult || loading || error || !layoutProps) return null;

	return (
		<BackwardsDigitSpanLayout
			{...layoutProps}
			showAudioPlayers={showAudioPlayback}
		/>
	);
};
