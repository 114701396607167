import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getSubjectService } from '../getSubjectService';

import { isNonEmptyString } from 'utils/stringUtils';
import {
	PaginatedParticipants,
	ParticipantApiGetParticipantsByOrgRequest,
} from './types';

export async function fetchParticipants(
	requestOptions: ParticipantApiGetParticipantsByOrgRequest
): Promise<PaginatedParticipants> {
	if (!requestOptions?.organizationId) {
		throw new Error("useParticipantsQuery: organizationId can't be null");
	}
	const subjectService = await getSubjectService();
	const { data } = await subjectService.getParticipantsByOrg(requestOptions);

	return data;
}

export function useGetPaginatedParticipants(
	requestOptions: ParticipantApiGetParticipantsByOrgRequest
) {
	const orgId = requestOptions?.organizationId;
	return useQuery({
		enabled: isNonEmptyString(orgId),
		meta: {
			errorMessage: `Error fetching participants for org ${orgId}`,
		},
		queryKey: [
			QueryKey.Participants,
			orgId,
			requestOptions?.page,
			requestOptions?.pageSize,
			requestOptions?.sortOrder,
			requestOptions?.sortField,
			requestOptions?.filterValue,
			requestOptions?.filterOperator,
		],
		queryFn: () => fetchParticipants(requestOptions),
		placeholderData: keepPreviousData,
		staleTime: Infinity,
	});
}
