import { CellContext } from '@tanstack/react-table';
import styled, { css } from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';

import { P1 } from 'components/shared/designSystem';
import { SingleSelectChoice } from '../../../common/components/SingleSelectChoice';
import { SingleSelectRowType } from '../../../common/types/CustomQnr.types';
import { Space } from '@mantine/core';
import { TFunction } from 'react-i18next';

export type PHQ8QnrTableData = {
	question: string;
	selections: JSX.Element;
};

export const getPHQ8TableColumns = (t: TFunction) => [
	{
		accessorKey: 'question',
		header: () => (
			<Typography.P1 style={{ textTransform: 'uppercase' }} weight='500'>
				{t('web.report.phq8.table.question')}
			</Typography.P1>
		),
		cell: (props: CellContext<PHQ8QnrTableData, unknown>) => {
			const value = props.getValue<React.ReactNode>();
			return (
				<div data-testid='question-text'>
					<Typography.P1>{`Q.${value}`}</Typography.P1>
				</div>
			);
		},
	},
	{
		accessorKey: 'selections',
		header: () => (
			<Typography.P1 style={{ textTransform: 'uppercase' }} weight='500'>
				{t('web.report.phq8.table.selections')}
			</Typography.P1>
		),
		cell: (props: CellContext<PHQ8QnrTableData, unknown>) => {
			const value = props.getValue<React.ReactNode>();
			return <Column>{value}</Column>;
		},
	},
];

export const buildPHQ8QnrTableData = (
	singleSelectRows: SingleSelectRowType[]
) => {
	if (!singleSelectRows) return [];
	return singleSelectRows?.map((rowData) => {
		const { questionText, answerChoices } = rowData;
		return {
			question: questionText,
			selections: (
				<Column data-testid={'individual-answer-column'}>
					{answerChoices.map((option, _index) => (
						<div key={option?.choiceText?.toString()}>
							<SingleSelectChoice
								key={`${option}_${_index}`}
								selected={option?.isSelected === true}>
								<Selection
									$selected={option?.isSelected === true}>
									{option?.choiceText}
								</Selection>
							</SingleSelectChoice>
							<Space h='xs' />
						</div>
					))}
				</Column>
			),
		};
	});
};

export const Column = styled.div`
	display: inline-block;
`;

export const Selection = styled(P1)<{
	$selected?: boolean;
	$unselectedCorrect?: boolean;
}>(
	({ $selected, $unselectedCorrect, theme }) => css`
		font-weight: ${($selected || $unselectedCorrect) && '600'};
		color: ${!$selected && theme.color.graphTextSecondary};
	`
);
