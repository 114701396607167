import { TFunction } from 'react-i18next';
import { convertToSentenceCase, isNonEmptyString } from 'utils/stringUtils';
import { BinaryArtifactData } from '../../../components/shared/hooks/useGetReportData';
import { FormattedSegmentResult } from '../common';
import { AudioCardProps } from '../common/AudioCard/AudioCard';
import { PhonemicFluencyContract } from './PhonemicFluency.types';

type PhonemicScores = {
	percentile: number;
	correctResponses: string;
	epochScore1: string;
	epochScore2: string;
	epochScore3: string;
	epochScore4: string;
};

// See metric items schema - https://github.com/linushealth/an-data-schemas/blob/main/an_data_schemas/phonemic_fluency/phonemic_fluency_engine_metric_output_data_contract_schema.json
export const getPhonemicFluencyScoresFromMetrics = (
	segmentData: FormattedSegmentResult | undefined
): PhonemicScores => {
	const segmentMetricItems = segmentData?.metricItems ?? {};
	const percentileMetric = Number(
		segmentData?.metricItems?.[
			'phonemic_fluency_percentile'
		]?.value?.toString() ?? 0
	);
	return {
		percentile: isNaN(percentileMetric) ? 0 : percentileMetric,
		correctResponses:
			segmentMetricItems?.[
				'phonemic_fluency_total_score'
			]?.value?.toString() ?? '0',
		epochScore1:
			segmentMetricItems[
				'phonemic_fluency_epoch1_score'
			]?.value?.toString() ?? '0',
		epochScore2:
			segmentMetricItems[
				'phonemic_fluency_epoch2_score'
			]?.value?.toString() ?? '0',
		epochScore3:
			segmentMetricItems[
				'phonemic_fluency_epoch3_score'
			]?.value?.toString() ?? '0',
		epochScore4:
			segmentMetricItems[
				'phonemic_fluency_epoch4_score'
			]?.value?.toString() ?? '0',
	};
};

const getPhonemicPrompt = (
	translationFunction: TFunction,
	promptKey: string
) => {
	if (isNonEmptyString(promptKey)) {
		return convertToSentenceCase(
			`Prompt: ${translationFunction(
				'web.report.phonemicFluencyReport.prompt'
			)} ${promptKey}`
		);
	}
	return '';
};

const buildAudioCardData = (
	translationFn: TFunction,
	metadata: PhonemicFluencyContract,
	binaryData: BinaryArtifactData[]
): AudioCardProps[] => {
	const promptFromData =
		(metadata as PhonemicFluencyContract)?.data?.phonemicLetter ?? '';
	return binaryData.map((data) => {
		return {
			audio: { source: data?.source, fileType: data?.fileType },
			text: {
				cardHeading: 'Phonemic Fluency',
				cardSubHeadings: [
					getPhonemicPrompt(translationFn, promptFromData),
				],
			},
		};
	});
};

type MapDataToProps = {
	scores: PhonemicScores;
	audioCardData: AudioCardProps[];
};

type MapDataToPropsInput = {
	translationFn: TFunction;
	segmentResult: FormattedSegmentResult | undefined;
	metadata: PhonemicFluencyContract | unknown;
	audioData?: BinaryArtifactData[] | null | undefined;
};

const mapDataToProps = ({
	translationFn,
	segmentResult,
	metadata,
	audioData,
}: MapDataToPropsInput): MapDataToProps => {
	return {
		scores: getPhonemicFluencyScoresFromMetrics(segmentResult),
		audioCardData: audioData
			? buildAudioCardData(
					translationFn,
					metadata as PhonemicFluencyContract,
					audioData
			  )
			: [],
	};
};

export { mapDataToProps };
