import { Chip, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	ExpandedMeasureCell,
	ExpandedMeasureCellContainer,
	ExpandedMeasureCellContent,
	ExpandedMeasureCellLabel,
	MeasureCell,
	MeasuresContainer,
	NormalMeasuresContent,
} from '../styles';

export function AmnesticModerateMeasures() {
	const { t } = useTranslation();

	return (
		<MeasuresContainer>
			<NormalMeasuresContent data-testid='amnestic-moderate-measures-content'>
				<MeasureCell>
					<Chip variant='Gray' label='2' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<ExpandedMeasureCellContainer $width='62%'>
					<ExpandedMeasureCellContent data-testid='amnestic-moderate-measures-expanded'>
						<ExpandedMeasureCell>
							<ExpandedMeasureCellLabel weight='600'>
								2
							</ExpandedMeasureCellLabel>
						</ExpandedMeasureCell>
						<Typography.P2>
							{t('web.report.cohort.breakdown.impairedMeasures')}
						</Typography.P2>
					</ExpandedMeasureCellContent>
				</ExpandedMeasureCellContainer>
			</NormalMeasuresContent>
		</MeasuresContainer>
	);
}
