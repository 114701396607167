import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import { Footer } from '../shared/Footer';
import { Icon } from '../shared/designSystem';
import { RegulatoryLabel } from './RegulatoryLabel';

const RegulatoryPage = (): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<>
			<StyledContainer>
				<StyledHeader>{'Linus Health'}</StyledHeader>
				<StyledLink>
					<Icon
						icon={icons.LeftPointerArrow}
						color={theme.color.link}
						title={t`web.shared.iconAltText.leftArrow`}
					/>
					<Link to={'/about'}>
						{<>{t`research.termsAndPrivacy.backButton`}</>}
					</Link>
				</StyledLink>
				<RegulatoryLabel />
			</StyledContainer>
			<Footer />
		</>
	);
};

export { RegulatoryPage };

const StyledHeader = styled.h2(
	({ theme: { fontSize, spacing } }) => `
	font-size: ${fontSize._36};
	margin: ${spacing.lg} 0 ${spacing.sm};
	line-height: ${spacing.xxxxl};
`
);
const StyledLink = styled.div(
	({ theme: { spacing, weight } }) => `
	display: flex;
	align-items: center;
	font-weight: ${weight.bold};
	width: 130px;
	margin-bottom: ${spacing.xl};
`
);
const StyledContainer = styled.div(
	() => `
	display: flex;
	flex-direction: column;
	width: 1338px;
	margin: 0 auto;
`
);
