import { SplitFactory } from '@splitsoftware/splitio-react';
import React from 'react';
import { config } from '../../config';
import { FeatureFlagsContext } from './useFeatureFlagsContext';

interface FeaturesProviderProps {
	children: React.ReactNode;
	mocked?: boolean;
}

export function FeatureFlagsProvider({
	children,
	mocked,
}: FeaturesProviderProps) {
	const splitConfig = {
		core: {
			authorizationKey: config.splitIoApiKey,
			key: 'default',
		},
	};

	return (
		<SplitFactory config={splitConfig} updateOnSdkTimedout={true}>
			{({ isReady, client }) => {
				if (mocked) {
					return (
						<FeatureFlagsContext.Provider value={client}>
							{children}
						</FeatureFlagsContext.Provider>
					);
				} else {
					if (!isReady || !client) return null;
					return (
						<FeatureFlagsContext.Provider value={client}>
							{children}
						</FeatureFlagsContext.Provider>
					);
				}
			}}
		</SplitFactory>
	);
}
