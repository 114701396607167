// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const UKCA = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 40 40'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='UKCA-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'UKCA'}</title>
			<g clipPath='url(#clip0_9594_69128)'>
				<path
					d='M11.8961 18.3354H6.41964C2.88005 18.3354 0 15.4913 0 11.9957V0H4.16287V11.9373C4.16287 13.1661 5.19985 14.1662 6.47558 14.1662H11.8401C13.1159 14.1662 14.1528 13.1661 14.1528 11.9373V0H18.3157V11.9933C18.3157 15.4889 15.4357 18.3354 11.8961 18.3354Z'
					fill='current'
				/>
				<path
					d='M39.9999 0H34.6165L25.8085 6.93838V0H21.6456V18.333H25.8085V11.3489L34.6165 18.333H39.9763L28.3899 9.14522L39.9999 0Z'
					fill='current'
				/>
				<path
					d='M18.3157 39.9991H6.33375C2.84144 39.9991 0 37.0611 0 33.5173V28.1477C0 24.6048 2.84144 21.666 6.33375 21.666H18.3157V25.8328H6.38969C5.16203 25.8328 4.16287 26.8716 4.16287 28.1477V33.5173C4.16287 34.7943 5.16203 35.833 6.38969 35.833H18.3157V39.9991Z'
					fill='current'
				/>
				<path
					d='M33.545 21.666H28.0686C24.529 21.666 21.6489 24.5101 21.6489 28.0058V39.9991H25.8118V35.8322H35.7994V39.9991H39.9615V28.0058C39.9615 24.5101 37.0822 21.666 33.545 21.666ZM25.8086 31.6646V28.0602C25.8086 26.8313 26.8464 25.8313 28.1214 25.8313H33.4859C34.7616 25.8313 35.7994 26.8313 35.7994 28.0602V31.6646H25.8086Z'
					fill='current'
				/>
			</g>
			<defs>
				<clipPath id='clip0_9594_69128'>
					<rect width='40' height='40' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};
