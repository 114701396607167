import * as yup from 'yup';
import { Gender, Handedness } from '../../generated/graphql';
import i18n from '../../i18n';
import { maxCharacterLimits } from '../../utils/stringUtils';
import { contactPhoneValidator } from '../../utils/validationHelpers';

export type AddParticipantModel = {
	avatarUrl?: string;
	birthYear: string;
	gender?: typeof Gender;
	ethnicityIds?: string;
	raceId?: string;
	educationId?: string;
	handedness?: Handedness;
	contactEmail?: string | null;
	contactPhone?: string | null;
	language: string;
	externalId?: string;
	notes?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addParticipantSchema = () => {
	return yup.object().shape({
		birthYear: yup
			.number()
			.required(i18n.t('web.shared.forms.schemas.required'))
			.min(1900)
			.max(new Date().getFullYear()),
		gender: yup.string().nullable(),
		raceId: yup.string(),
		educationId: yup.string(),
		handedness: yup.string().nullable(),
		externalId: yup
			.string()
			.test(
				'is-whitespace-only',
				i18n.t('web.shared.forms.schemas.required'),
				(value) => {
					return value ? !value.match(/^\s{1,}$/i) : !!value;
				}
			)
			.required(i18n.t('web.shared.forms.schemas.required'))
			.min(1, i18n.t('web.shared.forms.schemas.required'))
			.max(
				maxCharacterLimits.externalId,
				i18n.t('web.shared.forms.schemas.maxExternalId')
			),
		notes: yup
			.string()
			.max(
				maxCharacterLimits.notes,
				i18n.t('web.shared.forms.schemas.maxCharLimit') +
					` (${maxCharacterLimits.notes.toLocaleString()})`
			),
		language: yup
			.string()
			.required(i18n.t('web.shared.forms.schemas.required')),
		contactPhone: yup
			.string()
			.optional()
			.nullable()
			.concat(contactPhoneValidator()),
		contactEmail: yup
			.string()
			.optional()
			.nullable()
			.email(i18n.t('web.shared.forms.schemas.validEmail')),
	});
};

export const nullOParticipant: AddParticipantModel = {
	birthYear: '',
	educationId: undefined,
	handedness: undefined,
	gender: undefined,
	contactEmail: undefined,
	contactPhone: undefined,
	ethnicityIds: undefined,
	raceId: undefined,
	language: '',
	externalId: '',
	notes: '',
};

export type AddBatteryForResidentModel = {
	battery: string;
	provider: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addBatteryForResidentSchema = () => {
	return yup.object().shape({
		battery: yup
			.string()
			.required(i18n.t('web.shared.forms.schemas.required')),
		provider: yup
			.string()
			.required(i18n.t('web.shared.forms.schemas.required')),
	});
};

export const nullOBatteryForResident: AddBatteryForResidentModel = {
	battery: '',
	provider: '',
};
