import styled from 'styled-components';
import { Card } from '../../designSystem/Card';
import { FormattedAssessmentResult, FormattedSegmentResult } from '../common';
import { DcrRecordingsWidget } from './components/dcrRecordings/DcrRecordingsWidget';
import DcrScoringWidget from './components/dcrScore/DcrScoringWidget';
import { DcrSubscoresWidget } from './components/dcrSubscores/DcrSubscoresWidget';

type SegmentResultData = FormattedSegmentResult | undefined;

export type DcrReportProps = {
	data: {
		assessmentData: FormattedAssessmentResult | undefined;
		clock: SegmentResultData;
		recall: {
			immediate: SegmentResultData;
			delayed: SegmentResultData;
		};
	};
	showAmyloidWidget?: boolean;
	showAudioPlayback?: boolean;
};

const DcrReport = (props: DcrReportProps) => {
	return (
		<div>
			<StyledCard>
				<DcrScoringWidget
					data={props?.data}
					showAmyloidWidget={props?.showAmyloidWidget}
				/>
			</StyledCard>
			<StyledCard>
				<DcrSubscoresWidget data={props?.data} />
			</StyledCard>
			<div>
				<DcrRecordingsWidget
					data={props?.data}
					showAudioPlayback={props?.showAudioPlayback}
				/>
			</div>
		</div>
	);
};

export default DcrReport;

const StyledCard = styled(Card)(
	({ theme: { spacing } }) => `
	margin-bottom:${spacing.lg}
`
);
