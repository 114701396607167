import { Space } from '@mantine/core';

import { ScoreTag, ScoreWithInterpretation } from '@lh/eng-web-mosaic-common';

import { RcrInterpretation } from '../../Interpretation/RcrInterpretation';
import { AudioCardProps } from '../../common/AudioCard/AudioCard';
import { AudioCardGrid } from '../../common/AudioCardGrid/AudioCardGrid';
import { GenericReportCard } from '../../common/GenericReportCard/GenericReportCard';

export type DelayedRecall6LayoutProps = {
	header: {
		title: string;
		tooltipText?: string;
	};
	audioCardData: AudioCardProps[];
	score: {
		requiresClinicianReview: boolean;
		total: {
			title: string;
			value: string;
		};
	};
	showAudioPlayers?: boolean;
};

function DelayedRecall6Layout(props: DelayedRecall6LayoutProps) {
	const renderScoreComponent = (requiresReview?: boolean) => {
		return requiresReview
			? {
					weight: 1 / 3,
					component: () => (
						<ScoreWithInterpretation
							score={props?.score?.total?.value}
							title={props?.score?.total?.title}
							renderInterpretationText={() => (
								<RcrInterpretation
									interpretationTransKey={
										'web.report.verbalFluency.reviewRequired.listenToRecording'
									}
								/>
							)}
						/>
					),
			  }
			: {
					component: () => (
						<ScoreTag
							roundedBorders
							title={props?.score?.total?.title}
							score={props?.score?.total?.value}
						/>
					),
			  };
	};

	return (
		<div data-testid='delayed-recall-six-word-container'>
			<Space h={'md'} />
			<GenericReportCard
				header={{
					titleText: props?.header?.title,
					tooltipText: props?.header?.tooltipText,
				}}
				content={[
					renderScoreComponent(props?.score?.requiresClinicianReview),
				]}
			/>
			<Space h={'sm'} />
			{props?.showAudioPlayers && (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={props?.audioCardData}
				/>
			)}
		</div>
	);
}

export default DelayedRecall6Layout;
