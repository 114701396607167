// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const BuildYourVillageUntapped = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
	colors = ['#F2F1F3', '#14B2F4'],
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 36 36'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='BuildYourVillageUntapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'BuildYourVillageUntapped'}</title>
			<rect
				x='1.25'
				y='1.25'
				width='33.5'
				height='33.5'
				rx='16.75'
				fill={(colors && colors[0]) || '#F2F1F3'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.8468 10.4459C17.8468 8.82362 19.1704 7.5 20.7927 7.5C22.4149 7.5 23.7386 8.82362 23.7386 10.4391C23.7386 12.0614 22.4149 13.385 20.7927 13.385C20.4601 13.385 20.1343 13.3307 19.822 13.2221L18.2812 13.4936H18.2473C18.1794 13.4936 18.1251 13.4665 18.0843 13.4189C18.0368 13.3646 18.03 13.2832 18.0572 13.2153L18.4713 12.2514C18.2948 12.0274 18.1522 11.7831 18.0504 11.5184C17.9147 11.179 17.8468 10.8192 17.8468 10.4459ZM19.7609 10.8804C19.9709 10.8804 20.1411 10.7102 20.1411 10.5002C20.1411 10.2903 19.9709 10.1201 19.7609 10.1201C19.551 10.1201 19.3808 10.2903 19.3808 10.5002C19.3808 10.7102 19.551 10.8804 19.7609 10.8804ZM21.1728 10.5002C21.1728 10.7102 21.0026 10.8804 20.7927 10.8804C20.5828 10.8804 20.4126 10.7102 20.4126 10.5002C20.4126 10.2903 20.5828 10.1201 20.7927 10.1201C21.0026 10.1201 21.1728 10.2903 21.1728 10.5002ZM21.8244 10.8804C22.0343 10.8804 22.2045 10.7102 22.2045 10.5002C22.2045 10.2903 22.0343 10.1201 21.8244 10.1201C21.6145 10.1201 21.4443 10.2903 21.4443 10.5002C21.4443 10.7102 21.6145 10.8804 21.8244 10.8804ZM14.7083 19.0334C16.4566 19.0334 17.8889 17.6011 17.8889 15.8528C17.8889 14.1045 16.4566 12.6721 14.7083 12.6721C12.96 12.6721 11.5276 14.1045 11.5276 15.8528C11.5487 17.6011 12.96 19.0334 14.7083 19.0334ZM9 22.9302V25.5H20.3955V22.9302C20.3955 21.3715 19.1949 20.1708 17.6361 20.1708H11.7804C10.2849 20.1708 9 21.3925 9 22.9302ZM24.6925 16.8218C24.6925 18.1698 23.5972 19.3073 22.207 19.3073C20.8168 19.3073 19.7215 18.1698 19.7215 16.8218C19.7215 15.4737 20.8168 14.3362 22.207 14.3362C23.5551 14.3362 24.6925 15.4737 24.6925 16.8218ZM24.5029 20.4025H20.2059C20.9221 21.0134 21.3644 21.9402 21.3644 22.9513L21.4697 25.5H26.6936V22.5721C26.6936 21.3715 25.7246 20.4025 24.5029 20.4025Z'
				fill={color}
			/>
			<circle
				cx='18'
				cy='18'
				r='16.75'
				fill='none'
				stroke={(colors && colors[1]) || '#14B2F4'}
				stroke-width='1.5'
				stroke-dasharray='3 4'
			/>
		</svg>
	);
};
