import {
	AssignmentType,
	DeepBatteryResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';
import { first } from 'lodash';
import { AssessmentStatuses } from '../Assessment';
import { BackwardsDigitSpan } from '../BackwardsDigitSpan/BackwardsDigitSpan';
import { ComplexPictureDescription } from '../ComplexPictureDescription';
import { PHQ8 } from '../CustomQnr/PHQ-8/PHQ-8';
import { ClockDrawing } from '../DctClock';
import DelayedRecognition from '../DelayedRecognition/DelayedRecognition';
import { DigitSymbol } from '../DigitSymbol';
import DcrReport from '../DigitalClockAndRecall(DCR)/DcrReport';
import { PhonemicFluency } from '../PhonemicFluency/PhonemicFluency';
import { DelayedRecallSixWord } from '../Recall/DelayedRecall6/DelayedRecall6';
import { ImmediateRecallSixWord } from '../Recall/ImmediateRecall6/ImmediateRecall6';
import { WordRecall } from '../Recall/WordRecall';
import { SemanticFluency } from '../SemanticFluency/SemanticFluency';
import { Trails } from '../Trails';
import { LHQ15 } from '../CustomQnr/LHQ-15';
import { ReportBatteryTabs } from './batteryTabs';
import {
	BatteryParser,
	FormattedBatteryResult,
	FormattedSegmentResult,
	QnrIds,
	restBatteryToFormatted,
} from './parsingHelper';
import { EpsomWrittenResponses } from '../Epsom/features/BaselineEpsom/components/WrittenResponses/EpsomWrittenResponses';
import { TabItem } from 'components/TabbedLayout';
import { EpsomRankingRating } from '../Epsom/features/BaselineEpsom/components/RankingsAndRatings/EpsomRankingRating';
import { LongitudinalEpsomTabs } from '../Epsom/features/LongitudinalEpsom/LongitudinalEpsomTabs';
export function genericReportBuilder(
	batteryResult: FormattedBatteryResult,
	batteryParser: BatteryParser,
	batteryTabs: TabItem[],
	assignmentType: AssignmentType
) {
	const segmentsResults: FormattedSegmentResult[] = [];

	const { assessmentResults } = batteryResult;

	assessmentResults.forEach((assessment) => {
		segmentsResults.push(...assessment.segmentResults);
	});

	segmentsResults.forEach((segment) => {
		const {
			segment: { segmentType, isExpectingMetrics, isExpectingResults },
		} = segment;
		// Note to Future PRD: Removed a check between segment and assessment names here. Don't know why
		// we were doing it, and it was causing explosions. - SF
		if (segmentType && (isExpectingMetrics || isExpectingResults)) {
			const generic = batteryParser.getGenericParseOfSegment(
				segmentType as SegmentType,
				assignmentType
			);

			if (generic) {
				batteryTabs.push({
					name: generic.assessmentName,
					component: (
						<AssessmentStatuses assessmentStatuses={[generic]} />
					),
				});
			}
		}
	});
}

type SpeechScreenerTabArgs = {
	batteryParser: BatteryParser;
	batteryResultId: string;
	batteryResult: FormattedBatteryResult;
	showAudioPlayers: boolean;
};

function getSpeechScreenerTabs({
	batteryParser,
	batteryResultId,
	batteryResult,
	showAudioPlayers = false,
}: SpeechScreenerTabArgs): TabItem[] {
	const batteryTabs: TabItem[] = [];
	// Note: Add tabs in the same sort order as configured for the Speech Screener
	// Immediate Recall (6 word)
	if (batteryParser.hasImmediateRecall6()) {
		batteryTabs.push({
			name: ReportBatteryTabs.IMMEDIATE_RECALL_6,
			component: (
				<ImmediateRecallSixWord
					data={batteryParser.getImmediateRecall6()}
					showAudioPlayers={showAudioPlayers}
				/>
			),
		});
	}
	// Category Naming (Animals)
	if (batteryParser.hasSemanticFluency()) {
		batteryTabs.push({
			name: ReportBatteryTabs.SEMANTIC_FLUENCY,
			component: (
				<SemanticFluency
					data={batteryParser?.getSemanticFluency()}
					showAudioPlayback={showAudioPlayers}
				/>
			),
		});
	}
	// BDS
	if (batteryParser.hasBackwardsDigitSpan()) {
		batteryTabs.push({
			name: ReportBatteryTabs.BACKWARDS_DIGIT_SPAN,
			component: (
				<BackwardsDigitSpan
					data={batteryParser?.getBackwardsDigitSpan()}
					showAudioPlayback={showAudioPlayers}
				/>
			),
		});
	}

	// PHQ-8 Custom QNR
	if (batteryParser.hasCustomQnrSegment(QnrIds.PHQ8)) {
		const phq8SegmentResult = batteryParser.getCustomQnrSegment(
			QnrIds.PHQ8
		);
		if (phq8SegmentResult) {
			batteryTabs.push({
				name: ReportBatteryTabs.PHQ8,
				component: (
					<PHQ8
						segmentData={phq8SegmentResult}
						batteryResultId={batteryResultId}
					/>
				),
			});
		}
	}
	if (batteryParser.hasCustomQnrSegment(QnrIds.LHQ15)) {
		const lhq15egmentResult = batteryParser.getCustomQnrSegment(
			QnrIds.LHQ15
		);
		if (lhq15egmentResult) {
			batteryTabs.push({
				name: ReportBatteryTabs.LHQ15,
				component: (
					<LHQ15
						segmentData={lhq15egmentResult}
						batteryResultId={batteryResultId}
						batteryResult={batteryResult}
					/>
				),
			});
		}
	}

	// Delayed Recall (6 word)
	if (batteryParser.hasDelayedRecall6()) {
		batteryTabs.push({
			name: ReportBatteryTabs.DELAYED_RECALL_6,
			component: (
				<DelayedRecallSixWord
					showAudioPlayers={showAudioPlayers}
					data={batteryParser.getDelayedRecall6()}
				/>
			),
		});
	}
	// Delayed Recognition
	if (batteryParser.hasDelayedRecognition()) {
		batteryTabs.push({
			name: ReportBatteryTabs.DELAYED_RECOGNITION,
			component: (
				<DelayedRecognition
					data={batteryParser.getDelayedRecognition()}
				/>
			),
		});
	}
	return batteryTabs;
}

type GenerateTabsArgsType = {
	batteryResult: FormattedBatteryResult;
	batteryResultId: string;
	externalId: string;
	assignmentType: AssignmentType;
	featureFlags: {
		showAudioPlayback?: boolean;
		showAmyloidWidget?: boolean;
	};
	relatedResults?: DeepBatteryResult[] | null;
};

export function generateTabs(args: GenerateTabsArgsType) {
	const batteryParser = new BatteryParser(args?.batteryResult).mutable();
	const batteryTabs: TabItem[] = [];

	if (batteryParser.hasEpsomSegment()) {
		const segmentResult = batteryParser.getEpsomSegment();

		if (segmentResult) {
			batteryTabs.push({
				name: ReportBatteryTabs.EPSOM_WRITTEN_RESPONSES,
				component: (
					<EpsomWrittenResponses
						segmentResult={segmentResult}
						assignmentType={
							args.assignmentType ?? AssignmentType.Web
						}
					/>
				),
			});
			batteryTabs.push({
				name: ReportBatteryTabs.EPSOM_RANKING_RATING,
				component: <EpsomRankingRating segmentResult={segmentResult} />,
			});
		}
	}

	// This is a one-off until we come up with a more robust solution for building the tabs dynamically
	if (batteryParser.isDCRBattery() || batteryParser.hasDCRAssessment()) {
		const recall = batteryParser.getWordRecall();
		batteryTabs.push({
			name: ReportBatteryTabs.DCR,
			component: (
				<>
					<DcrReport
						showAudioPlayback={
							args?.featureFlags?.showAudioPlayback
						}
						showAmyloidWidget={
							args?.featureFlags?.showAmyloidWidget ?? false
						}
						data={{
							assessmentData:
								batteryParser.getDCRAssessmentData(),
							clock: batteryParser.getClockSegment(),
							recall: {
								immediate: recall?.immediate_recall,
								delayed: recall?.delayed_recall,
							},
						}}
					/>
				</>
			),
		});
	} else {
		if (batteryParser.hasClock()) {
			batteryTabs.push({
				name: ReportBatteryTabs.DCT_CLOCK,
				component: (
					<ClockDrawing
						clockSegment={batteryParser.getClockSegment()}
						contentLoaded={() => true}
					/>
				),
			});
		}

		if (batteryParser.hasWordRecall()) {
			const recall = batteryParser.getWordRecall();
			batteryTabs.push({
				name: ReportBatteryTabs.WORD_RECALL,
				component: (
					<WordRecall
						delayed_recall={recall?.delayed_recall}
						immediate_recall={recall?.immediate_recall}
					/>
				),
			});
		}
	}

	if (batteryParser.hasTrailsV2()) {
		batteryTabs.push({
			name: ReportBatteryTabs.TRAILS,
			component: (
				<Trails
					trailsSegments={batteryParser.getTrailsV2Segments()}
					contentLoaded={() => true}
					externalId={args.externalId}
					type={args.assignmentType}
				/>
			),
		});
	} else {
		if (batteryParser.hasTrails()) {
			batteryTabs.push({
				name: ReportBatteryTabs.TRAILS,
				component: (
					<Trails
						trailsSegments={batteryParser.getTrailsSegments()}
						contentLoaded={() => true}
						externalId={args.externalId}
						type={args.assignmentType}
					/>
				),
			});
		}
	}

	if (batteryParser.hasDigitSymbol()) {
		batteryTabs.push({
			name: ReportBatteryTabs.DIGIT_SYMBOL,
			component: (
				<DigitSymbol
					digitSymbolData={batteryParser.getDigitSymbol()}
					contentLoaded={() => true}
					externalId={args.externalId}
					type={args.assignmentType}
				/>
			),
		});
	}

	batteryTabs.push(
		...getSpeechScreenerTabs({
			batteryParser,
			batteryResultId: args?.batteryResultId,
			batteryResult: args.batteryResult,
			showAudioPlayers: args?.featureFlags?.showAudioPlayback ?? false,
		})
	);
	if (batteryParser.hasComplexPictureDescription()) {
		batteryTabs.push({
			name: ReportBatteryTabs.COMPLEX_PICTURE_DESCRIPTION,
			component: (
				<ComplexPictureDescription
					segmentData={batteryParser.getComplexPictureDescription()}
					contentLoaded={() => true}
					externalId={args.externalId}
					type={args.assignmentType}
				/>
			),
		});
	}

	if (batteryParser.hasPhonemicFluency()) {
		batteryTabs.push({
			name: ReportBatteryTabs.PHONEMIC_FLUENCY,
			component: (
				<PhonemicFluency
					data={batteryParser?.getPhonemicFluency()}
					showAudioPlayback={args?.featureFlags?.showAudioPlayback}
				/>
			),
		});
	}

	if (
		batteryParser.hasLongitudinalEpsomSegment() &&
		!batteryParser.isLongEpsomBattery()
	) {
		const formattedRelatedResults = (args.relatedResults ?? [])
			.map((r) => new BatteryParser(restBatteryToFormatted(r)))
			.filter(
				(b) => b.hasEpsomSegment() || b.hasLongitudinalEpsomSegment()
			)
			.sort((a) => (a.hasEpsomSegment() ? -1 : 1)); //sort baseline to front
		const initialBatteryResultId = first(formattedRelatedResults)?.id || '';
		const formattedRelatedSegments = formattedRelatedResults.map(
			(b) =>
				(b.hasEpsomSegment()
					? b.getEpsomSegment()
					: b.getLongitudinalEpsomSegment()) as FormattedSegmentResult //TS *eye-roll*
		);
		const [baseline, ...longitudinals] = formattedRelatedSegments;
		const current = batteryParser.getLongitudinalEpsomSegment();
		if (!baseline || !current) throw new Error('Missing Epsom Results');
		batteryTabs.push({
			name: ReportBatteryTabs.LONGITUDINAL_EPSOM,
			component: (
				<LongitudinalEpsomTabs
					segmentResult={current}
					initialBatteryResultId={initialBatteryResultId}
					relatedResults={{
						baseline,
						longitudinals,
					}}
				/>
			),
		});
	}

	genericReportBuilder(
		args.batteryResult,
		batteryParser,
		batteryTabs,
		args.assignmentType
	);

	return batteryTabs;
}
