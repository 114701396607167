// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const HeartShapedPersonOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 34 34'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='HeartShapedPersonOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'HeartShapedPersonOutlined'}</title>
			<path
				d='M3.12618 24.8244L3.61008 24.9502L3.66179 24.7514L3.55874 24.5736L3.12618 24.8244ZM1 33L0.516096 32.8741L0.298532 33.7107L1.13219 33.4822L1 33ZM9.16082 30.7629L9.40498 30.3266L9.22621 30.2265L9.02864 30.2807L9.16082 30.7629ZM32.5 16.8994C32.5 25.4012 25.5634 32.2987 17 32.2987V33.2987C26.1097 33.2987 33.5 25.9594 33.5 16.8994H32.5ZM17 1.5C25.5634 1.5 32.5 8.3975 32.5 16.8994H33.5C33.5 7.83928 26.1097 0.5 17 0.5V1.5ZM1.5 16.8994C1.5 8.3975 8.43661 1.5 17 1.5V0.5C7.89028 0.5 0.5 7.83928 0.5 16.8994H1.5ZM3.55874 24.5736C2.24917 22.3146 1.5 19.6946 1.5 16.8994H0.5C0.5 19.8757 1.2982 22.6681 2.69361 25.0751L3.55874 24.5736ZM1.4839 33.1258L3.61008 24.9502L2.64227 24.6985L0.516096 32.8741L1.4839 33.1258ZM9.02864 30.2807L0.867813 32.5178L1.13219 33.4822L9.29301 31.2451L9.02864 30.2807ZM17 32.2987C14.2394 32.2987 11.6489 31.5822 9.40498 30.3266L8.91667 31.1992C11.3061 32.5363 14.0643 33.2987 17 33.2987V32.2987Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.0464 15.9314C19.4126 15.9314 21.3577 13.9431 21.3577 11.4657C21.3577 8.98703 19.4382 7 17.0464 7C14.6801 7 12.7351 8.98823 12.7351 11.4657C12.734 13.9433 14.6535 15.9314 17.0464 15.9314ZM13.7351 11.4661C13.7343 13.4243 15.2385 14.9314 17.0464 14.9314C18.8313 14.9314 20.3577 13.4204 20.3577 11.4657C20.3577 9.50605 18.8532 8 17.0464 8C15.2615 8 13.7351 9.5114 13.7351 11.4661ZM19.5988 17C18.7463 17 17.8663 17.4017 17.2475 18.0665C17.1512 18.1634 17.0687 18.2465 17 18.3296C16.9313 18.2465 16.8488 18.1634 16.7525 18.0665C16.1338 17.4017 15.2538 17 14.4013 17C12.7513 17 11.5 18.2465 11.5 19.9224C11.5 21.5706 12.2425 22.4017 13.4662 23.6343C14.847 25.0252 16.3767 26.4391 16.6432 26.6854L16.6432 26.6854L16.6838 26.723L16.9863 27L17.2887 26.723L17.3293 26.6854C17.5107 26.5178 18.2778 25.8087 19.1849 24.9341C19.1644 24.8584 19.1535 24.7788 19.1535 24.6964V24.3187H18.7758C18.6974 24.3187 18.6212 24.3087 18.5483 24.2898C17.9011 24.9109 17.331 25.445 17 25.7535C16.45 25.241 15.24 24.1053 14.1125 22.9695C12.875 21.723 12.4075 21.1413 12.4075 19.9086C12.4075 18.7452 13.2463 17.9141 14.3875 17.9141C14.9925 17.9141 15.6113 18.205 16.065 18.6898C16.4225 19.0776 16.6562 19.313 16.6562 19.313L16.9863 19.6593L17.3162 19.313C17.3162 19.313 17.5363 19.0776 17.9075 18.6898C18.3612 18.205 18.9938 17.9141 19.585 17.9141C20.74 17.9141 21.565 18.759 21.565 19.9086C21.5669 20.0839 21.5593 20.2461 21.5415 20.3988C21.7822 20.562 21.9418 20.8376 21.9418 21.1527V21.5304H22.184C22.3902 21.069 22.5 20.5561 22.5 19.9224C22.5 18.2465 21.2488 17 19.5988 17Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.1918 21.1527C21.1918 21.0641 21.1188 20.9922 21.0312 20.9922H20.0641C19.9754 20.9922 19.9035 21.0651 19.9035 21.1527V22.2805H18.7758C18.6871 22.2805 18.6152 22.3534 18.6152 22.441V23.4082C18.6152 23.4969 18.6882 23.5687 18.7758 23.5687H19.9035V24.6965C19.9035 24.7851 19.9765 24.857 20.0641 24.857H21.0312C21.1199 24.857 21.1918 24.7841 21.1918 24.6965V23.5687H22.3195C22.4082 23.5687 22.4801 23.4958 22.4801 23.4082V22.441C22.4801 22.3524 22.4071 22.2805 22.3195 22.2805H21.1918V21.1527Z'
				fill='#68DCDC'
			/>
		</svg>
	);
};
