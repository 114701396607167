// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const IndeterminateNew = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 21 21'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='IndeterminateNew-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'IndeterminateNew'}</title>
			<path
				d='M7.31333 8.99564C6.67847 8.99564 6.25 9.54945 6.25 10.1206C6.25 10.6918 6.67847 11.2456 7.31333 11.2456H13.6867C14.3215 11.2456 14.75 10.6918 14.75 10.1206C14.75 9.54945 14.3215 8.99564 13.6867 8.99564H7.31333Z'
				fill='#FFCA28'
				stroke='#676671'
			/>
		</svg>
	);
};
