// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Frailty = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 29'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Frailty-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Frailty'}</title>
			<path
				d='M23.4547 13.6231H20.3123C20.045 13.6231 19.829 13.4067 19.829 13.1398C19.829 12.873 20.0455 12.6565 20.3123 12.6565H23.4547C23.7221 12.6565 23.9381 12.873 23.9381 13.1398C23.9381 13.4067 23.7218 13.6231 23.4547 13.6231Z'
				fill='current'
			/>
			<path
				d='M12.4811 4.10882C12.2143 4.10882 11.9978 3.89237 11.9978 3.62551V0.483312C11.9978 0.216455 12.2143 0 12.4811 0C12.748 0 12.9644 0.216449 12.9644 0.483312V3.62574C12.9644 3.89237 12.748 4.10883 12.4811 4.10883V4.10882Z'
				fill='current'
			/>
			<path
				d='M19.7761 9.0791C19.5833 9.0791 19.4009 8.96281 19.3259 8.77222C19.2282 8.52372 19.3507 8.24315 19.5988 8.14521L22.3757 7.05323C22.6242 6.95595 22.905 7.07755 23.0029 7.32606C23.1006 7.57457 22.9781 7.85514 22.7301 7.95307L19.953 9.04528C19.8948 9.06805 19.8349 9.07911 19.7761 9.07911L19.7761 9.0791Z'
				fill='current'
			/>
			<path
				d='M17.1893 5.4785C17.0923 5.4785 16.9948 5.44976 16.9099 5.38918C16.6919 5.23486 16.6408 4.93307 16.7953 4.7153L18.6141 2.15303C18.7691 1.93525 19.0708 1.88418 19.2882 2.0385C19.5062 2.19283 19.5572 2.49462 19.4027 2.71238L17.584 5.27466C17.4896 5.40798 17.3408 5.47851 17.1893 5.47851L17.1893 5.4785Z'
				fill='current'
			/>
			<path
				d='M14.6357 21.459C13.3354 19.9964 18.7597 18.8107 18.7597 13.1399C18.7597 5.54368 13.2295 5.45029 12.0668 5.45029C10.9043 5.45029 5.37386 6.12416 5.37386 13.1399C5.37386 18.8107 10.7979 19.9964 9.49789 21.459C7.78422 23.3863 1.85861 20.9593 1.85861 28.444H22.2753C22.2755 20.9598 16.3499 23.3865 14.636 21.459H14.6357ZM12.0582 15.0762C11.3971 14.7121 9.60628 13.5215 9.50282 11.9718C9.3821 10.1613 11.7082 10.4036 12.0582 11.745C12.4082 10.4034 14.7343 10.1615 14.6136 11.9718C14.5101 13.5213 12.7194 14.7121 12.0582 15.0762Z'
				fill='current'
			/>
			<path
				d='M3.68778 13.6231H0.545812C0.278955 13.6231 0.0625 13.4067 0.0625 13.1398C0.0625 12.873 0.27895 12.6565 0.545812 12.6565H3.68778C3.95464 12.6565 4.17109 12.873 4.17109 13.1398C4.17109 13.4067 3.95465 13.6231 3.68778 13.6231Z'
				fill='current'
			/>
			<path
				d='M4.22466 9.08092C4.16562 9.08092 4.10571 9.07008 4.04778 9.04731L1.27088 7.95533C1.02237 7.8576 0.900106 7.57682 0.998052 7.32831C1.096 7.07981 1.37656 6.95754 1.62507 7.05549L4.40197 8.14747C4.65047 8.24519 4.77274 8.52598 4.6748 8.77448C4.59963 8.9644 4.41722 9.08092 4.22465 9.08092L4.22466 9.08092Z'
				fill='current'
			/>
			<path
				d='M7.76291 5.16223C7.61146 5.16223 7.46244 5.09126 7.36825 4.95838L5.54953 2.39611C5.39476 2.17833 5.44628 1.87654 5.66405 1.72223C5.88205 1.56746 6.18362 1.61898 6.33793 1.83675L8.15665 4.39903C8.31142 4.61681 8.2599 4.9186 8.04213 5.07291C7.95745 5.13348 7.85973 5.16223 7.76289 5.16223L7.76291 5.16223Z'
				fill='current'
			/>
		</svg>
	);
};
