import styled from 'styled-components';
import {
	EpsomScores,
	QuestionIdPattern,
} from '../../../../../../../../../types/epsomTypes';
import { ConfidenceRatingTableTranslations } from '../../../../../../common/helpers/confidenceScoreUtils';
import { AnswerRow } from '../../../../../BaselineEpsom/components/AnswerRow/AnswerRow';
import { TableHeader } from '../../../../../../common/components/table/TableHeader';

type RankedAndRatedAnswersListProps = {
	rankedAnswers: EpsomScores[QuestionIdPattern.ranking];
	textToDisplay: ConfidenceRatingTableTranslations['card']['list'];
};

function RankedAndRatedAnswersList(props: RankedAndRatedAnswersListProps) {
	const { rankedAnswers, textToDisplay } = props;
	return (
		<>
			<TableHeader textToDisplay={textToDisplay} />
			<StyledSeparator />
			{rankedAnswers.map((rankedAnswer, index) => {
				return (
					<AnswerRow
						key={`${index}-${rankedAnswer?.score}`}
						itemNumber={index + 1}
						participantAnswer={rankedAnswer?.input}
						score={{ current: rankedAnswer?.score }}
						rating={{
							isLowConfidenceIndicator: rankedAnswer?.warning,
						}}
					/>
				);
			})}
		</>
	);
}

export { RankedAndRatedAnswersList };

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	position: relative;
	width: 100%;  
	border-bottom: 1px solid ${color.infoSeparator};	
`
);
