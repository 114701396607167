import styled from 'styled-components';
import { Subtitle } from '../../designSystem/TextComponents';
import { useTranslation } from 'react-i18next';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../designSystem/Icon';
import { EnumItem } from '../../../../enums/enumeration';

interface NoMatchFoundTableContentProps {
	notFoundTitle: string;
	notFoundSubtitle: string;
	noDataIcon?: EnumItem;
}

const NoMatchFoundTableContent = ({
	notFoundTitle,
	notFoundSubtitle,
	noDataIcon,
}: NoMatchFoundTableContentProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledContainer>
			<Icon
				icon={noDataIcon ? noDataIcon : icons.EmptyClipboardSearch}
				title={t`web.shared.search.noMatchFoundIcon`}
			/>
			<StyledTitle>{notFoundTitle}</StyledTitle>
			<StyledSubtitle>{notFoundSubtitle}</StyledSubtitle>
		</StyledContainer>
	);
};

export { NoMatchFoundTableContent };

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${spacing.xxxl};
	`
);

const StyledTitle = styled(Subtitle)(
	({ theme: { fontSize, spacing, color } }) => `
	font-size: ${fontSize._24};
	margin: ${spacing.md} 0;
	white-space: pre-wrap;
	color: ${color.textBlue};
`
);
const StyledSubtitle = styled(Subtitle)(
	({ theme: { fontSize, spacing } }) => `
	font-size: ${fontSize._21};
	margin: ${spacing.sm} 0;
	white-space: pre-wrap;
`
);
