// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AlertSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AlertSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'AlertSolid'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.90133 5.9651C9.90133 5.81207 9.86732 5.67605 9.81631 5.54002C9.64628 5.14895 9.2212 4.94491 8.83013 5.01292C8.42205 5.13195 8.167 5.50602 8.167 5.9651C8.184 6.25402 8.201 6.52597 8.21799 6.79789L8.21801 6.79826C8.24352 7.31686 8.27327 7.83545 8.30303 8.35405C8.33279 8.87265 8.36254 9.39124 8.38805 9.90984C8.40505 10.2669 8.6771 10.522 9.03417 10.522C9.39123 10.522 9.66329 10.2499 9.68029 9.89282C9.68029 9.67178 9.68029 9.48475 9.69729 9.26372C9.71429 8.93231 9.73553 8.59665 9.75677 8.26098L9.7568 8.26052C9.77806 7.92471 9.79931 7.5889 9.81631 7.25734C9.85032 6.83226 9.88433 6.39018 9.90133 5.9651ZM9.01716 12.8684C9.47625 12.8684 9.88433 12.4773 9.86732 12.0182C9.90133 11.5251 9.49325 11.1341 9.01716 11.1341C8.54107 11.1341 8.15 11.5251 8.15 12.0012C8.15 12.4773 8.54107 12.8684 9.01716 12.8684Z'
				fill='current'
			/>
		</svg>
	);
};
