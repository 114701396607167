import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from '@mantine/core';

import {
	ScoreTag,
	ScoreWithInterpretation,
	Typography,
} from '@lh/eng-web-mosaic-common';

import { ordinalSuffixPerformance } from '../../../utils/ordinalSuffix';
import { RcrInterpretation } from '../Interpretation/RcrInterpretation';
import { AudioCardProps } from '../common/AudioCard/AudioCard';
import { AudioCardGrid } from '../common/AudioCardGrid/AudioCardGrid';
import {
	ScoreBreakdown,
	ScoreBreakdownProps,
} from '../common/Breakdown/Breakdown';
import { GenericReportCard } from '../common/GenericReportCard/GenericReportCard';

export type SemanticFluencyLayoutProps = {
	header: {
		title: string;
		tooltipText?: string;
	};
	audioCardData: AudioCardProps[];
	score: {
		percentile: number;
		requiresClinicianReview: boolean;
		total: {
			title: string;
			value: string;
		};
		breakdown: ScoreBreakdownProps['breakdown'];
	};
	showAudioPlayers?: boolean;
};

export const SemanticFluencyLayout = (props: SemanticFluencyLayoutProps) => {
	const { t } = useTranslation();
	const renderScoreComponent = useMemo(() => {
		const showInterpretation =
			props?.score?.percentile !== 0 ||
			props?.score?.requiresClinicianReview;
		if (showInterpretation) {
			return (
				<ScoreWithInterpretation
					score={props?.score?.total?.value}
					title={props?.score?.total?.title}
					renderInterpretationText={() => {
						if (props?.score?.requiresClinicianReview) {
							return (
								<RcrInterpretation interpretationTransKey='web.report.verbalFluency.reviewRequired.listenToRecording' />
							);
						} else {
							// e.g. Performance is in the <b>92nd percentile</b>
							return (
								<Typography.P2 weight='500'>
									{t(
										'web.report.verbalFluency.performancePrefix'
									)}{' '}
									<Typography.P2 weight='700'>
										{ordinalSuffixPerformance(
											props?.score?.percentile
										)}{' '}
										{t(
											'web.report.verbalFluency.percentile'
										)}
									</Typography.P2>
								</Typography.P2>
							);
						}
					}}
				/>
			);
		} else {
			return (
				<ScoreTag
					roundedBorders
					title={props?.score?.total?.title}
					score={props?.score?.total?.value}
				/>
			);
		}
	}, [
		props?.score?.percentile,
		props?.score?.requiresClinicianReview,
		props?.score?.total?.title,
		props?.score?.total?.value,
		t,
	]);

	return (
		<div data-testid='semantic-fluency-layout-container'>
			<Space h={'md'} />
			<GenericReportCard
				header={{
					titleText: props?.header?.title,
					tooltipText: props?.header?.tooltipText,
				}}
				content={[
					{
						weight: 1,
						component: () => renderScoreComponent,
					},
					{
						weight: 2,
						component: () => (
							<ScoreBreakdown
								breakdown={props?.score?.breakdown}
							/>
						),
					},
				]}
			/>
			<Space h={'sm'} />
			{props?.showAudioPlayers && (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={props?.audioCardData}
				/>
			)}
		</div>
	);
};
