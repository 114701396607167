import { enumeration, EnumItem } from './enumeration';
export type { EnumItem };

export type TLocale = {
	EnUs: EnumItem;
	EnGb: EnumItem;
	EsMx: EnumItem;
};

const Locale = {
	EnUs: { value: 'en-US', display: 'EN_US' },
	EnGb: { value: 'en-GB', display: 'EN_GB' },
	EsMx: { value: 'es-MX', display: 'ES_MX' },
};

export const locale = enumeration<typeof Locale>(Locale);
