import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../../../../../shared/designSystem';
import { ConfidenceScoreRange } from '../../../../../common/constants/confidenceScore';
import ConfidenceScoreLegendRow from './ConfidenceScoreLegendRow';
import { useState } from 'react';
import { ConfidenceScoreModal } from './ConfidenceScoreModal';

function ConfidenceScoreLegend() {
	const confidenceRangesOrderedByScore =
		Object.keys(ConfidenceScoreRange).reverse();

	const [isDescriptionModalVisible, setIsDescriptionModalVisible] =
		useState<boolean>(false);

	const { t } = useTranslation();

	if (confidenceRangesOrderedByScore.length === 0) return null;

	const viewScoringDetailsLinkText = t(
		'research.epsomConfidenceScore.scoringDetails.linkText'
	);

	return (
		<div>
			{isDescriptionModalVisible && (
				<ConfidenceScoreModal
					onClose={() => setIsDescriptionModalVisible(false)}
				/>
			)}
			<Flex direction={'column'}>
				{confidenceRangesOrderedByScore.map(
					(confidenceRangesOrderedByScore): JSX.Element | null => (
						<ConfidenceScoreLegendRow
							key={confidenceRangesOrderedByScore?.toString()}
							scoreRange={
								confidenceRangesOrderedByScore as ConfidenceScoreRange
							}
						/>
					)
				)}
				<div style={{ alignSelf: 'flex-end', cursor: 'pointer' }}>
					<Link
						onClick={() => setIsDescriptionModalVisible(true)}
						data-testid='view-scoring-details-link'>
						{viewScoringDetailsLinkText}
					</Link>
				</div>
			</Flex>
		</div>
	);
}

export { ConfidenceScoreLegend };
