import {
	GetUsersFilterField,
	GetUsersFilterOperator,
	GetUsersLogicalOperator,
} from 'api/organization';
import { isNonEmptyString } from 'utils/stringUtils';

interface SearchCriteria {
	filterField: GetUsersFilterField[] | undefined;
	filterValue: string[] | undefined;
	filterOperator: GetUsersFilterOperator[] | undefined;
	logicalOperator: GetUsersLogicalOperator[] | undefined;
}

enum WordCount {
	NONE,
	ONE,
	MORE_THAN_ONE,
}

const checkWordCount = (inputStr?: string | null | undefined): WordCount => {
	if (!isNonEmptyString(inputStr)) return WordCount.NONE;
	const words = inputStr.split(' ');
	const nonEmptyWords = words.filter((word) => word.length > 0);
	if (nonEmptyWords.length === 1) {
		return WordCount.ONE;
	}
	return WordCount.MORE_THAN_ONE;
};

export const getPaginatedUsersQueryFilterOptions = (
	val?: string | null | undefined
): SearchCriteria | Record<string, never> => {
	if (!isNonEmptyString(val)) return {};
	const searchValue = val.replace(/ +/, ' '); //trim consecutive spaces to 1
	const wordCount = checkWordCount(searchValue);
	switch (wordCount) {
		// Single word search - search either by first name OR by last name
		case WordCount.ONE:
			return {
				filterField: [
					GetUsersFilterField.FirstName,
					GetUsersFilterField.LastName,
				],
				filterOperator: [
					GetUsersFilterOperator.Ilike,
					GetUsersFilterOperator.Ilike,
				],
				filterValue: [searchValue, searchValue],
				logicalOperator: [GetUsersLogicalOperator.Or],
			};
		// Multiple word search - assume "full name" search - look for matching first name AND last name
		case WordCount.MORE_THAN_ONE:
			{
				const [firstName, lastName] = searchValue.split(' ');
				if (lastName) {
					return {
						filterField: [
							GetUsersFilterField.FirstName,
							GetUsersFilterField.LastName,
						],
						filterOperator: [
							GetUsersFilterOperator.Ilike,
							GetUsersFilterOperator.Ilike,
						],
						filterValue: [firstName, lastName],
						logicalOperator: [GetUsersLogicalOperator.And],
					};
				}
			}
			return {};
		case WordCount.NONE:
		default: {
			return {};
		}
	}
};
