/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

/*

  DCT JSON utility.

A few useful utilities for working with JSON.

*/
export const dctJSON = new (function () {
	//Stringify some JSON, but prevent script injection attacks in HTML script tags
	this.stringifyScriptTag = function (/* same as JSON.stringify */) {
		return JSON.stringify.apply(JSON, arguments).replace(/</g, '\\u003C');
	};

	//Parse JSON.  If there's an error, log it, and return null.
	this.parseOrNull = function (arg_string) {
		try {
			return JSON.parse(arg_string);
		} catch (e) {
			console.warn(e.stack);
			return null;
		}
	};

	//Parse an optional parameter
	this.parseOptional = function (arg_string) {
		if (arg_string === undefined) return null;
		return this.parseOrNull(arg_string);
	};
})();
