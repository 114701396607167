import {
	useGetParticipantsCountQuery,
	useGetResultsCountQuery,
} from '../../../generated/graphql';

type Counts = { [key: string]: number };

type GetMenuItemCountsProps = {
	orgId: string;
};

type MenuItemCounts = {
	counts: Counts;
	loading: boolean;
};

const useGetMenuItemCounts = ({
	orgId,
}: GetMenuItemCountsProps): MenuItemCounts => {
	// TODO: Eventually migrate this to a dedicated count endpoint, this is just a workaround
	const { data: participantsData, loading: participantsLoading } =
		useGetParticipantsCountQuery({
			variables: {
				searchInput: {
					limit: 1,
					offset: 0,
				},
				orgId: orgId,
			},
		});
	// TODO: Eventually migrate this to a dedicated count endpoint, this is just a workaround
	const { data: resultsData, loading: resultsLoading } =
		useGetResultsCountQuery({
			variables: {
				searchInput: {
					limit: 1,
					offset: 0,
					search: {
						prop: 'BATTERY_RESULT_STATUS',
						value: 'ANALYSIS_COMPLETE,DATA_COMPLETE,PARTICIPANT_COMPLETE',
					},
				},
				orgId: orgId,
			},
		});

	const counts = {
		Participants:
			participantsData?.researchParticipantTableSearch?.total || 0,
		Results: resultsData?.researchResultsTableSearch?.total || 0,
	} as Counts;

	return {
		counts,
		loading: participantsLoading || resultsLoading,
	};
};

export { useGetMenuItemCounts };
