import { IndividualScore } from '../../common/scoring/types';
import { RiskLevel as DementiaRiskLevel } from '../components/dementiaRisk';
import { DcrReportProps } from '../DcrReport';
import { DcrMetricItemKeys, DcrScoreData } from '../types';
import { getDcrScoreTagColors } from './dcrScoringColorHelpers';

const INVALID_SCORE_VALUE = -1;

const getNumericScoreValue = (score: unknown): number => {
	if (isNaN(Number(score))) return INVALID_SCORE_VALUE;
	return Number(score);
};

export const getDcrScoreData = (data: DcrReportProps['data']): DcrScoreData => {
	// Get DCR Score from Metrics
	const totalDCRScore = getNumericScoreValue(
		data?.assessmentData?.metricItems?.[DcrMetricItemKeys.DCR_SCORE]?.value
	);

	// Get DCR Interpretation from Metrics
	const dcrInterpretationValueFromMetrics =
		data?.assessmentData?.metricItems?.[
			DcrMetricItemKeys.DCR_INTERPRETATION
		]?.value;
	const dcrInterpretation: string = dcrInterpretationValueFromMetrics
		? dcrInterpretationValueFromMetrics?.toString()
		: '';

	const dcrScoreTagColors = getDcrScoreTagColors(totalDCRScore);

	// Calculate Delayed Recall Score from metrics
	const totalDelayedRecallScore = getNumericScoreValue(
		data?.recall?.delayed?.metricItems?.[
			DcrMetricItemKeys.EXPECTED_WORDS_COUNT
		]?.value
	);
	const achievedDelayedRecallScore = getNumericScoreValue(
		data?.recall?.delayed?.metricItems?.[
			DcrMetricItemKeys.CORRECT_WORDS_COUNT
		]?.value
	);

	// Calculate DCT Clock Score
	const TOTAL_DCT_CLOCK_SCORE = 2;
	// As per current logic, DCT Clock Score = DCR score - delayed recall score (Immediate Recall has no impact on the score)
	const achievedDctClockScore = totalDCRScore - achievedDelayedRecallScore;

	const combinedScoreData: IndividualScore[] = [
		{
			scoreCategory: 'Clock Drawing',
			scoreValues: {
				total: TOTAL_DCT_CLOCK_SCORE,
				achieved: achievedDctClockScore,
			},
		},
		{
			scoreCategory:
				data?.recall?.delayed?.segment?.name ?? 'Delayed Recall',
			scoreValues: {
				total: totalDelayedRecallScore,
				achieved: achievedDelayedRecallScore,
			},
		},
	];

	const isUnanalyzable = totalDCRScore === INVALID_SCORE_VALUE;

	return {
		isUnanalyzable: isUnanalyzable,
		totalScore: { achieved: totalDCRScore, total: 5 },
		scoreData: isUnanalyzable ? [] : combinedScoreData,
		scoreTagBackgroundColors: dcrScoreTagColors,
		scoreInterpretation: dcrInterpretation,
	};
};

export function getMmsePrediction(
	data: DcrReportProps['data'],
	hasMmseFlag: boolean,
	isUnanalyzable?: boolean
) {
	if (!hasMmseFlag) {
		return null;
	}

	const mmsePrediction =
		data.assessmentData?.metricItems?.['mmse_prediction']?.value;

	if (mmsePrediction?.toString()) {
		return mmsePrediction.toString();
	}

	if (isUnanalyzable) {
		return '--';
	}

	return null;
}

export function getDementiaRisk(
	data: DcrReportProps['data'],
	hasFeatureEntitlement: boolean
): DementiaRiskLevel | null {
	if (!hasFeatureEntitlement) {
		return null;
	}

	const dctClockScore = Number(data.clock?.metricItems?.['DCTScore']?.value);

	if (isNaN(dctClockScore)) {
		return 'Unanalyzable';
	}

	if (dctClockScore >= 60) {
		return 'NotElevated';
	}

	if (dctClockScore >= 0) {
		return 'Elevated';
	}

	return 'Unanalyzable';
}
