import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { theme } from '../../../../../providers/styleProvider';
import { CloseButton } from '../../../../designSystem/Button/CloseButton';
import { P1 } from '../../../../designSystem/Text';
import { IconAdd, IconUnion } from '../../../../shared/designSystem/Icons';
import { getAmyloidColorForInterpretation } from '../../helpers/amyloidHelpers';
import { AmyloidInterpretation } from '../../types';

type AmyloidLikelihoodDetailsProps = {
	closeButton: {
		text: string;
		onClick: () => void;
	};
};

export const AmyloidLikelihoodDetails = (
	props: AmyloidLikelihoodDetailsProps
) => {
	const [isReferencesSectionCollapsed, setIsReferenceSectionCollapsed] =
		useState<boolean>(true);

	const CollapsibleSectionIcon = useMemo(() => {
		if (isReferencesSectionCollapsed) {
			return () => <IconAdd color={theme.colors?.blue} />;
		} else {
			return () => <IconUnion color={theme.colors?.blue} />;
		}
	}, [isReferencesSectionCollapsed]);

	const getColors = useCallback((likelihood: AmyloidInterpretation) => {
		return getAmyloidColorForInterpretation(likelihood, false);
	}, []);

	const lowLikelihoodColors = useMemo(
		() => getColors(AmyloidInterpretation.LOW_LIKELIHOOD),
		[getColors]
	);

	const moderateLikelihoodColors = useMemo(
		() => getColors(AmyloidInterpretation.MODERATE_LIKELIHOOD),
		[getColors]
	);

	const highLikelihoodColors = useMemo(
		() => getColors(AmyloidInterpretation.HIGH_LIKELIHOOD),
		[getColors]
	);

	return (
		<Container data-testid='amyloid-likelihood-details'>
			<br />
			<p data-testid='amyloid-likelihood-info-text-part-1'>
				<Trans
					i18nKey={'web.report.amyloidLikelihood.modal.info.part1'}
				/>
			</p>
			<p data-testid='amyloid-likelihood-info-text-part-2'>
				<Trans
					i18nKey={'web.report.amyloidLikelihood.modal.info.part2'}
				/>
			</p>
			<hr />
			<P1Medium data-testid='interpretation-ranges-title-text'>
				<Trans
					i18nKey={
						'web.report.amyloidLikelihood.interpretationRanges.title'
					}
				/>
			</P1Medium>
			<br />
			{/* Low Likelihood */}
			<Row>
				<ProbabilityTag
					data-testid='low-likelihood-range-tag'
					style={{
						backgroundColor: lowLikelihoodColors?.background,
						color: lowLikelihoodColors?.text,
					}}>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.lowLikelihood.tagText'
						}
					/>
				</ProbabilityTag>
				<RangeValueText data-testid='low-likelihood-range-value-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.lowLikelihood.rangeValue'
						}
					/>
					{':'}
				</RangeValueText>
				<span data-testid='low-likelihood-range-description-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.lowLikelihood.rangeDescription'
						}
					/>
				</span>{' '}
				<Trans
					i18nKey={
						'web.report.amyloidLikelihood.interpretationRanges.rangeTextCommon'
					}
				/>
			</Row>
			{/* Moderate Likelihood */}
			<Row>
				<ProbabilityTag
					data-testid='moderate-likelihood-range-tag'
					style={{
						backgroundColor: moderateLikelihoodColors?.background,
						color: moderateLikelihoodColors?.text,
					}}>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.moderateLikelihood.tagText'
						}
					/>
				</ProbabilityTag>
				<RangeValueText data-testid='moderate-likelihood-range-value-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.moderateLikelihood.rangeValue'
						}
					/>
					{':'}
				</RangeValueText>
				<span data-testid='moderate-likelihood-range-description-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.moderateLikelihood.rangeDescription'
						}
					/>
				</span>{' '}
				<Trans
					i18nKey={
						'web.report.amyloidLikelihood.interpretationRanges.rangeTextCommon'
					}
				/>
			</Row>
			{/* High Likelihood */}
			<Row>
				<ProbabilityTag
					data-testid='high-likelihood-range-tag'
					style={{
						backgroundColor: highLikelihoodColors?.background,
						color: highLikelihoodColors?.text,
					}}>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.highLikelihood.tagText'
						}
					/>
				</ProbabilityTag>
				<RangeValueText data-testid='high-likelihood-range-value-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.highLikelihood.rangeValue'
						}
					/>
					{':'}
				</RangeValueText>
				<span data-testid='high-likelihood-range-description-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.interpretationRanges.highLikelihood.rangeDescription'
						}
					/>
				</span>{' '}
				<Trans
					i18nKey={
						'web.report.amyloidLikelihood.interpretationRanges.rangeTextCommon'
					}
				/>
			</Row>
			<br />
			<ReferenceSectionHeader
				data-testid='references-section-header'
				onClick={() =>
					setIsReferenceSectionCollapsed(
						!isReferencesSectionCollapsed
					)
				}>
				<CollapsibleSectionIcon />
				<RightMargin />
				<P1Medium data-testid='references-section-header-title-text'>
					<Trans
						i18nKey={
							'web.report.amyloidLikelihood.modal.references.title'
						}
					/>
				</P1Medium>
			</ReferenceSectionHeader>
			{!isReferencesSectionCollapsed && (
				<CollapsibleSection>
					<SecondaryText data-testid='references-section-body-text'>
						<Trans
							i18nKey={
								'web.report.amyloidLikelihood.modal.references.bodyText'
							}
						/>
					</SecondaryText>
				</CollapsibleSection>
			)}
			<CloseButton
				data-testid='close-button'
				text={props?.closeButton?.text}
				onClick={props?.closeButton?.onClick}
			/>
		</Container>
	);
};

const Container = styled.div`
	max-width: 826px;
	background-color: #fff;
`;

const ReferenceSectionHeader = styled.div(
	({ theme: { colors, spacing } }) => `
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
	background: ${colors.gray_90};
	cursor: pointer;
	padding: ${spacing.sm};
`
);

const P1Medium = styled(P1)(
	({ theme: { weight, spacing } }) => `
	padding: ${spacing.sm} 0;
	font-weight: ${weight.medium};
	`
);

const Row = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: ${spacing.sm};
	align-self: stretch;
	margin-top: ${spacing.sm};
`
);

const ProbabilityTag = styled.span(
	({ theme: { spacing, weight } }) => `
	padding: ${spacing.xs} ${spacing.sm};
	margin-top: -${spacing.xs};
	justify-content: center;
	align-items: center;
	gap: ${spacing.xxs};
	font-weight: ${weight.medium};
	border-radius: ${spacing.sm};
	min-width: 77px;
	text-align: center;
`
);

const RangeValueText = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
	`
);

const CollapsibleSection = styled.div(
	({ theme: { colors, spacing } }) => `
	background: ${colors.gray_90};
	padding: ${spacing.md};
`
);

const SecondaryText = styled.div(
	({ theme: { color } }) => `
	color: ${color.bodyTextSecondary};
`
);

const RightMargin = styled.span(
	({ theme: { spacing } }) => `
	margin-right: ${spacing.md};
`
);
