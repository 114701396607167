import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DctClockRecording } from '../../../DctClock';
import { DcrReportProps } from '../../DcrReport';
import { WidgetHeader } from '../shared/WidgetHeader';
import { RecallRecording } from './components/RecallRecording';

type DcrRecordingsProps = {
	data: DcrReportProps['data'];
	showAudioPlayback?: boolean;
};

const DcrRecordingsWidget = (props: DcrRecordingsProps) => {
	const { t } = useTranslation();

	return (
		<>
			<WidgetHeader titleText={'Recordings'} />
			<StaggeredGrid>
				<Column data-testid={'column-1'}>
					{/* Immediate Recall (Audio) Recording */}
					<RecallRecording
						data={props?.data?.recall?.immediate}
						showAudioPlayback={props?.showAudioPlayback}
					/>
					<VerticalSpacing />
					{/* Copy Clock (Video) Recording */}
					<DctClockRecording
						title={t`web.dcrReport.recordings.clock.copy.title`}
						clockType={'COPY'}
						tooltipText={t`web.dcrReport.recordings.clock.copy.tooltipText`}
						segmentId={props?.data?.clock?.id}
						contentLoaded={() => true}
					/>
				</Column>
				<Column data-testid={'column-2'}>
					{/* Command Clock (Video) Recording */}
					<DctClockRecording
						title={t`web.dcrReport.recordings.clock.command.title`}
						clockType={'COMMAND'}
						tooltipText={t`web.dcrReport.recordings.clock.command.tooltipText`}
						segmentId={props?.data?.clock?.id}
						contentLoaded={() => true}
					/>
					<VerticalSpacing />
					{/* Delayed Recall (Audio) Recording */}
					<RecallRecording
						data={props?.data?.recall?.delayed}
						showAudioPlayback={props?.showAudioPlayback}
					/>
				</Column>
			</StaggeredGrid>
		</>
	);
};

const StaggeredGrid = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex: 1;
	margin-top: ${spacing.md};
`
);

const Column = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-right: ${spacing.md};
`
);

const VerticalSpacing = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.md};
	margin-bottom: ${spacing.md};
`
);

export { DcrRecordingsWidget };
