import styled from 'styled-components';
import { AssessmentStatus, AssessmentStatuses } from '../Assessment';
import { FormattedSegmentResult } from '../common';
import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import { Alert } from '../../shared/Alert';
import { Maybe } from '../../../generated/graphql';

type DigitSymbolProps = {
	digitSymbolData: FormattedSegmentResult | undefined;
	contentLoaded: () => boolean;
	externalId: string;
	type: Maybe<string>;
};
const DigitSymbol = ({
	digitSymbolData,
	externalId,
	type,
}: DigitSymbolProps) => {
	const assessmentName = 'Digit Symbol';
	const taskDuration = '90 seconds';
	const dateTaken = digitSymbolData?.endTime
		? dateFormatter(digitSymbolData.endTime, "MMM d', ' yyyy ' at 't")
		: undefined;
	const digitCount =
		digitSymbolData?.metricItems?.digit_symbol_overall_predicted_digit_count
			?.value;
	// We don't display tasks if we can't find data
	const tasks = digitCount ? `${digitCount} boxes` : undefined;
	const assessmentStatus: AssessmentStatus = {
		participantName: `Participant ${externalId}`,
		assessmentName,
		dateTaken,
		tasks,
		taskDuration,
		type,
	};
	return (
		<>
			<StyledAlertContainer>
				<Alert severity='info'>
					<>
						This section is for Research Use Only. Not for
						Diagnostic Procedures.
					</>
				</Alert>
			</StyledAlertContainer>
			<AssessmentStatuses assessmentStatuses={[assessmentStatus]} />
		</>
	);
};

export { DigitSymbol };

const StyledAlertContainer = styled.div(
	({ theme: { spacing } }) => `
  margin-top: ${spacing.xl};
`
);
