import {
	CategoryPattern,
	QuestionIdPattern,
	QuestionIdPatternTransKeyMap,
} from '../../../../../types/epsomTypes';

const optionalCategories: string[] = [QuestionIdPattern.other];

export const isOptionalCategory = (categoryPattern: string) => {
	return optionalCategories.includes(categoryPattern);
};

const DEFAULT_CATEGORY_NAME = 'Unknown Category';

/**
 *
 * @param pattern
 * @returns
 */
export const getCategoryNameTransKey = (pattern: CategoryPattern) => {
	const transKeyPrefix =
		'research.participantsWrittenResponse.categoryDisplayKeys';
	switch (pattern) {
		case QuestionIdPattern.daily_task:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.daily_task}`;
		case QuestionIdPattern.life_enjoyment:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.life_enjoyment}`;
		case QuestionIdPattern.sense_of_self:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.sense_of_self}`;
		case QuestionIdPattern.relationship:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.relationship}`;
		case QuestionIdPattern.thinking_ability:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.thinking_ability}`;
		case QuestionIdPattern.other:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.other}`;
		case QuestionIdPattern.rating:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.rating}`;
		case QuestionIdPattern.ranking:
			return `${transKeyPrefix}.${QuestionIdPatternTransKeyMap.ranking}`;
		default:
			return DEFAULT_CATEGORY_NAME;
	}
};

/**
 *
 * @param pattern
 * @returns
 */
export const getCategoryTooltipTextTransKey = (pattern: CategoryPattern) => {
	const prefix = 'research.participantsWrittenResponse.tooltipText';
	switch (pattern) {
		case QuestionIdPattern.daily_task:
			return `${prefix}.dailyTasks`;
		case QuestionIdPattern.life_enjoyment:
			return `${prefix}.lifeEnjoyment`;
		case QuestionIdPattern.sense_of_self:
			return `${prefix}.senseOfSelf`;
		case QuestionIdPattern.relationship:
			return `${prefix}.relationship`;
		case QuestionIdPattern.thinking_ability:
			return `${prefix}.thinkingAbility`;
		case QuestionIdPattern.other:
			return `${prefix}.other`;
		default:
			return DEFAULT_CATEGORY_NAME;
	}
};
