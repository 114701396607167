// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const BuildVillage = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='BuildVillage-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'BuildVillage'}</title>
			<rect width='24' height='24' rx='12' fill='#065E83' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.8851 6.33442C11.8851 5.11771 12.8778 4.125 14.0945 4.125C15.3112 4.125 16.3039 5.11771 16.3039 6.32933C16.3039 7.54604 15.3112 8.53875 14.0945 8.53875C13.8451 8.53875 13.6007 8.49802 13.3665 8.41657L12.2109 8.6202H12.1854C12.1345 8.6202 12.0938 8.59984 12.0633 8.5642C12.0276 8.52348 12.0225 8.46239 12.0429 8.41148L12.3534 7.68858C12.2211 7.52058 12.1142 7.33731 12.0378 7.13877C11.936 6.88423 11.8851 6.61442 11.8851 6.33442ZM13.3207 6.66027C13.4782 6.66027 13.6058 6.53263 13.6058 6.37518C13.6058 6.21773 13.4782 6.09009 13.3207 6.09009C13.1633 6.09009 13.0356 6.21773 13.0356 6.37518C13.0356 6.53263 13.1633 6.66027 13.3207 6.66027ZM14.3796 6.37518C14.3796 6.53263 14.252 6.66027 14.0945 6.66027C13.9371 6.66027 13.8094 6.53263 13.8094 6.37518C13.8094 6.21773 13.9371 6.09009 14.0945 6.09009C14.252 6.09009 14.3796 6.21773 14.3796 6.37518ZM14.8683 6.66027C15.0258 6.66027 15.1534 6.53263 15.1534 6.37518C15.1534 6.21773 15.0258 6.09009 14.8683 6.09009C14.7109 6.09009 14.5832 6.21773 14.5832 6.37518C14.5832 6.53263 14.7109 6.66027 14.8683 6.66027ZM9.53121 12.775C10.8424 12.775 11.9167 11.7008 11.9167 10.3896C11.9167 9.07835 10.8424 8.0041 9.53121 8.0041C8.21999 8.0041 7.14573 9.07835 7.14573 10.3896C7.16153 11.7008 8.21999 12.775 9.53121 12.775ZM5.25 15.6976V17.625H13.7966V15.6976C13.7966 14.5286 12.8962 13.6281 11.7271 13.6281H7.33531C6.21367 13.6281 5.25 14.5444 5.25 15.6976ZM17.0194 11.1163C17.0194 12.1274 16.1979 12.9805 15.1553 12.9805C14.1126 12.9805 13.2911 12.1274 13.2911 11.1163C13.2911 10.1053 14.1126 9.25217 15.1553 9.25217C16.1663 9.25217 17.0194 10.1053 17.0194 11.1163ZM16.8772 13.8019H13.6544C14.1916 14.26 14.5233 14.9551 14.5233 15.7134L14.6023 17.625H18.5202V15.4291C18.5202 14.5286 17.7935 13.8019 16.8772 13.8019Z'
				fill='white'
			/>
		</svg>
	);
};
