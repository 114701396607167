import { DeepBatteryResult } from 'api/battery';
import {
	getPHQ8DepressionScoreBreakdown,
	isPHQ8IndicatingDepression,
} from 'components/report/CustomQnr/PHQ-8/PHQ-8.helpers';
import { createMapFromMetricsArray } from 'components/report/metrics';

export const checkPHQ8ForDepression = (
	deepBatteryResult: DeepBatteryResult | undefined
): boolean => {
	if (!deepBatteryResult) return false;
	const hasRDACBattery =
		deepBatteryResult?.battery?.displayKey?.includes('DAC');
	const assessmentResults = deepBatteryResult?.assessmentResults;
	const hasPHQ8SegmentResult = assessmentResults?.some((assessmentResult) =>
		assessmentResult?.segmentResults?.some((segmentResult) =>
			segmentResult?.segment?.displayKey?.includes('PHQ-8')
		)
	);
	// Depression Condition 1 - The battery must be of type rDAC and must contain a PHQ-8 segment result.
	if (!hasRDACBattery && !hasPHQ8SegmentResult) {
		return false;
	}
	const phq8SegmentResult = assessmentResults
		?.flatMap((assessmentResult) => assessmentResult?.segmentResults)
		?.find((segmentResult) =>
			segmentResult?.segment?.displayKey?.includes('PHQ-8')
		);
	const metricItemsMap = createMapFromMetricsArray(
		phq8SegmentResult?.metricItems ?? []
	);
	// Depression Condition 2 - PHQ-8 scores must be as appropriated by a specific logic.
	const phq8DepressionScoreBreakdown =
		getPHQ8DepressionScoreBreakdown(metricItemsMap);
	return isPHQ8IndicatingDepression(phq8DepressionScoreBreakdown);
};

export const getMostRecentResult = (
	batteryResults: DeepBatteryResult[],
	batteryDisplayKey: string
): DeepBatteryResult | undefined => {
	return (
		batteryResults
			?.filter((result) =>
				result.battery?.displayKey?.includes(batteryDisplayKey)
			)
			?.at(0) ?? undefined
	);
};
