// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Notebook = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 25 28'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Notebook-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Notebook'}</title>
			<path
				d='M18.7475 0.666748C20.0684 0.666748 21.1617 1.70734 21.315 3.0445C20.8433 3.24654 20.4074 3.54423 20.0404 3.91151V3.36717C20.0404 2.62118 19.4618 2.01696 18.7475 2.01696H2.58586C1.87152 2.01696 1.29293 2.62118 1.29293 3.36717V24.633C1.29293 25.379 1.87152 25.9832 2.58586 25.9832H18.7475C19.4618 25.9832 20.0404 25.379 20.0404 24.633V16.0677L21.3333 14.1346V24.633C21.3333 26.1216 20.1729 27.3334 18.7475 27.3334H2.58586C1.1604 27.3334 0 26.1216 0 24.633V3.36717C0 1.87856 1.1604 0.666748 2.58586 0.666748H18.7475Z'
				fill='#087DAE'
			/>
			<path
				d='M4.66667 9.3334H16.3203L15.3816 10.7369H4.66667C4.29848 10.7369 4 10.4384 4 10.0702V10.0001C4 9.63188 4.29848 9.3334 4.66667 9.3334Z'
				fill='#087DAE'
			/>
			<path
				d='M4.66667 13.3334H13.6449L12.7062 14.7369H4.66667C4.29848 14.7369 4 14.4384 4 14.0702V14.0001C4 13.6319 4.29848 13.3334 4.66667 13.3334Z'
				fill='#087DAE'
			/>
			<path
				d='M4.66667 17.3334H10.9938C10.9189 17.4874 10.8623 17.6499 10.8262 17.8173L10.6235 18.7369H4.66667C4.29848 18.7369 4 18.4384 4 18.0702V18.0001C4 17.6319 4.29848 17.3334 4.66667 17.3334Z'
				fill='#087DAE'
			/>
			<path
				d='M4.66667 21.3334H10.1143C10.1122 21.8089 10.237 22.2744 10.4959 22.6667H4.66667C4.29848 22.6667 4 22.3683 4 22.0001C4 21.6319 4.29848 21.3334 4.66667 21.3334Z'
				fill='#087DAE'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.4466 21.3776C11.4463 21.363 11.4463 21.3483 11.4464 21.3334C11.4477 21.24 11.459 21.1416 11.4816 21.039L12.1291 18.1007C12.1497 18.0038 12.1903 17.9066 12.2502 17.817L20.6462 5.26415C21.3939 4.14623 22.7759 3.75889 23.7329 4.399C24.6899 5.03911 24.8596 6.46427 24.1119 7.58219L15.7159 20.135C15.6556 20.2252 15.5806 20.3003 15.4984 20.3564L13.0315 22.0757C12.3033 22.5831 11.5429 22.2325 11.4548 21.495C11.4486 21.443 11.4457 21.3891 11.4464 21.3334M20.1588 7.86787L21.8916 9.02689L15.504 18.5769C15.3427 18.309 15.1074 18.0348 14.7655 17.8061C14.4237 17.5775 14.0804 17.4648 13.7712 17.4179L20.1588 7.86787ZM22.6329 7.91854L23.2455 7.00268C23.6193 6.44372 23.5345 5.73114 23.056 5.41109C22.5775 5.09103 21.8865 5.2847 21.5126 5.84366L20.9 6.75952L22.6329 7.91854ZM14.5936 19.664C14.4938 19.457 14.3127 19.1964 13.9831 18.9759C13.6535 18.7555 13.3435 18.6876 13.1141 18.6744L12.588 21.0617L14.5936 19.664Z'
				fill='#087DAE'
			/>
			<path
				d='M4 6.00007C4 5.63188 4.29848 5.3334 4.66667 5.3334H16.6667C17.0349 5.3334 17.3333 5.63188 17.3333 6.00007V6.07024C17.3333 6.43843 17.0349 6.73691 16.6667 6.73691H4.66667C4.29848 6.73691 4 6.43843 4 6.07024V6.00007Z'
				fill='#087DAE'
			/>
		</svg>
	);
};
