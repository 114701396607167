import { useAuthStore } from './useAuthStore';
import { useBreakdownMeasuresStore } from './useBreakdownMeasuresStore';
import { useOrganizationStore } from './useOrganizationStore';
import { useCallback } from 'react';
import { useUserStore } from './useUserStore';

export function useStoreReset() {
	const { reset: resetBreakdownMeasuresStore } = useBreakdownMeasuresStore();
	const { reset: resetAuthStore } = useAuthStore();
	const { reset: resetOrgStore } = useOrganizationStore();
	const { reset: resetUserStore } = useUserStore();

	return useCallback(() => {
		resetAuthStore();
		resetBreakdownMeasuresStore();
		resetOrgStore();
		resetUserStore();
	}, [
		resetOrgStore,
		resetAuthStore,
		resetUserStore,
		resetBreakdownMeasuresStore,
	]);
}
