import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../enums/icons';

import { Icon } from '../shared/designSystem/Icon';
import { H2 } from '../shared/designSystem/TextComponents';
import { ButtonSm } from '../shared/designSystem/ButtonSm';

const UserNotFound = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function handleClick() {
		navigate('/', { replace: true });
	}

	return (
		<StyledPage data-testid='user-not-found-page'>
			<StyledFormContainer>
				<Icon icon={icons.NotFoundUser} />
				<StyledH2 data-testid='user-not-found-txt-title'>
					{t`web.shared.userNotFound.title`}
				</StyledH2>
				<StyledBodyText data-testid='user-not-found-txt-body'>
					{t`web.shared.userNotFound.body`}
				</StyledBodyText>
				<StyledButtonContainer>
					<ButtonSm
						data-testid='user-not-found-btn-redirect'
						primary
						width='200px'
						text={t`web.shared.userNotFound.backToLoginLink`}
						onClick={handleClick}
					/>
				</StyledButtonContainer>
			</StyledFormContainer>
		</StyledPage>
	);
};

const StyledPage = styled.div(
	() => css`
		display: flex;
		justify-content: center;
		padding: 60px 164px 0px 164px;
		//tablet size
		@media screen and (max-width: 834px) {
			padding: 60px 72px 0px 72px;
		}
		// iphone size
		@media screen and (max-width: 550px) {
			padding: 60px 28px 0px 28px;
		}
	`
);

const StyledFormContainer = styled.div(
	({ theme: { color, boxShadow, spacing } }) => css`
		display: flex;
		flex-direction: column;
		text-align: center;
		background: ${color.white};
		border-radius: ${spacing.xl};
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};

		padding: 72px ${spacing.xl};
		//tablet size
		@media screen and (max-width: 834px) {
			padding: 72px 96px;
		}
		// iphone size
		@media screen and (max-width: 550px) {
			padding: 72px 24px;
		}
	`
);

const StyledH2 = styled(H2)(
	({ theme: { fontSize, color, spacing } }) => css`
		font-size: ${fontSize._32};
		line-height: 40px;
		letter-spacing: 0.2px;
		color: ${color.textBlue};
		font-weight: 500;
		margin: ${spacing.xl} 0px;
	`
);

const StyledBodyText = styled.div(
	({ theme: { fontSize, color, spacing } }) => css`
		color: ${color.textInfo};
		font-size: ${fontSize._21};
		text-align: initial;
		line-height: 19px;
		letter-spacing: 0.5px;
		font-weight: 400;
		margin-bottom: ${spacing.xxxxxl};
	`
);

const StyledButtonContainer = styled.div(
	() => css`
		display: flex;
		justify-content: center;
	`
);

export { UserNotFound };
