import styled, { useTheme } from 'styled-components';
import { CheckmarkInternal } from './CheckmarkInternal';

type CheckmarkIconProps = {
	size: string;
};
export const CheckmarkIcon = ({ size }: CheckmarkIconProps) => {
	const theme = useTheme();
	return (
		<ContainerDiv className='checkmark-icon-cmp'>
			<CheckmarkInternal
				width={size}
				height={size}
				color={theme.colors.green}
			/>
		</ContainerDiv>
	);
};

const ContainerDiv = styled.div`
	margin-right: -1px;
`;
