import { getOrganizationService } from 'api/getOrganizationService';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'api/query';

export const getUserOrganizations = async (
	organizationId: string,
	userId: string
) => {
	const orgService = await getOrganizationService();
	return await orgService
		.getUserOrganizations({ organizationId, userId })
		.then((res) => res.data);
};

export const useGetUserOrganizations = (organizationId = '', userId = '') => {
	const enabled = !isEmpty(organizationId) && !isEmpty(userId);
	return useQuery({
		queryKey: [QueryKey.UserOrganizations, { organizationId, userId }],
		queryFn: async () => getUserOrganizations(organizationId, userId),
		enabled,
		staleTime: Infinity,
	});
};
