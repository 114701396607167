import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Container = styled.div`
	max-width: 826px;
	min-height: 450px;
	background-color: #fff;
`;

export const ProbabilityTag = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

export const Tag = styled.div<{ $bgColor: string }>(
	({ theme: { spacing }, $bgColor }) => css`
		padding: ${spacing.xs} ${spacing.sm};
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: ${spacing.sm};
		background-color: ${$bgColor};
		min-width: 117px;
	`
);

export const TagText = styled(P1)<{ $color: string }>(
	({ $color, theme: { weight } }) => css`
		font-weight: ${weight.semi};
		color: ${$color};
		letter-spacing: 0.5px;
	`
);

export const P1Medium = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const TagInfo = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xs};
		align-items: center;
	`
);

export const CollapsibleSection = styled.div(
	({ theme: { colors, spacing } }) => css`
		background: ${colors.gray_90};
		padding: ${spacing.md};
	`
);

export const SecondaryText = styled(P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

export const Info = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);
