import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../artifacts/ReportArtifactNames';
import { LoadingDots } from '../../../components/shared/LoadingDots';
import { ArtifactFormats, useGetReportData } from '../../shared/hooks';
import { FormattedSegmentResult } from '../common';
import { mapDataToProps } from './PhonemicFluency.helpers';
import { PhonemicFluencyLayout } from './PhonemicFluencyLayout';

type PhonemicFluencyPropsType = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayback?: boolean;
};

export const PhonemicFluency = ({
	data: segmentResult,
	showAudioPlayback = false,
}: PhonemicFluencyPropsType) => {
	const { t } = useTranslation();
	const [segmentResultId, setSegmentResultId] = useState<string>('');

	useEffect(() => {
		const segmentResultId =
			segmentResult?.rawDataUrl?.split('segments/')[1] || '';
		setSegmentResultId(segmentResultId);
	}, [segmentResult, setSegmentResultId]);

	const {
		metadata,
		error,
		loading,
		binaryArtifactData: audioData,
	} = useGetReportData({
		segmentResultId: segmentResultId,
		fileName: Json.PhonemicFluencyJSON,
		fileType: ArtifactFormats.JSON,
	});

	const layoutProps = useMemo(
		() =>
			mapDataToProps({
				translationFn: t,
				segmentResult,
				metadata,
				audioData,
			}),
		[audioData, metadata, segmentResult, t]
	);

	if (loading && !error && !metadata) return <LoadingDots />;

	if (!segmentResult || !metadata) return null;

	return (
		<PhonemicFluencyLayout
			requiresReview={layoutProps?.scores?.correctResponses === '0'}
			segmentResultId={segmentResultId}
			correctResponses={layoutProps?.scores?.correctResponses}
			percentile={layoutProps?.scores?.percentile}
			tooltipText={t('web.report.phonemicFluencyReport.subTitle')}
			breakdownScores={{
				epoch1: layoutProps?.scores?.epochScore1,
				epoch2: layoutProps?.scores?.epochScore2,
				epoch3: layoutProps?.scores?.epochScore3,
				epoch4: layoutProps?.scores?.epochScore4,
			}}
			showAudioPlayer={showAudioPlayback}
			audioCardData={layoutProps?.audioCardData}
			segmentNameDisplayKey={'Phonemic Fluency'}
		/>
	);
};
