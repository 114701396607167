import { TFunction } from 'i18next';
import { convertToSentenceCase } from '../../../utils/stringUtils';
import { MetricHash } from '../metrics';
import { BackwardsDigitSpanLayoutProps } from './BackwardsDigitSpan.layout';
import { MapDataToPropsArgs } from './BackwardsDigitSpan.types';
import { BackwardsDigitSpanTableProps } from './components/BackwardsDigitSpan.table';

const minimumAchievableScore = 0;
const maximumScorePerTrial = 5;
const maximumTotalScore = 15;
const unanalyzableScoreToDisplay = '--';

const adjustSpacingBetweenCommas = (commaSeparatedStrings: string): string => {
	const listOfWords = commaSeparatedStrings.split(',').join(', ');
	return convertToSentenceCase(listOfWords);
};

const getCardSubheadings = (
	metricItems: MetricHash,
	translationFn: TFunction,
	count: number
): string[] => {
	const lines: string[] = [];
	const expectedDigits =
		metricItems?.[
			`backward_digit_span_trial_${count}_expected_digits`
		]?.value?.toString() ?? '';
	// The prompt is just the expected digits manually put backwards so the clinician can easily reference it when listening to the audio.
	const promptDigits = expectedDigits?.split(',')?.reverse()?.join(', ');
	const promptDisplayKey = `${translationFn(
		'web.report.verbalFluency.prompt'
	)}`;
	lines.push(`${promptDisplayKey}: ${promptDigits}`);
	const backwardsDisplayKey = `${translationFn(
		'web.report.verbalFluency.backwards'
	)}`;
	lines.push(
		`${backwardsDisplayKey}: ${adjustSpacingBetweenCommas(expectedDigits)}`
	);
	return lines;
};

const buildAudioCardData = (
	segmentName: string,
	metricItems: MetricHash,
	translationFn: TFunction,
	audioData: MapDataToPropsArgs['audioData']
) => {
	try {
		if (audioData?.length === 4) {
			// For BDST, the 1st recorded audio is a practice audio that we don't need to display
			audioData?.shift();
		}
		const info = audioData?.map((audioSourceInfo, index) => {
			return {
				text: {
					cardHeading: segmentName,
					cardSubHeadings: getCardSubheadings(
						metricItems,
						translationFn,
						index + 1
					),
				},
				audio: {
					source: audioSourceInfo?.source ?? '',
					fileType: audioSourceInfo?.fileType ?? '',
				},
			};
		});
		return info ?? [];
	} catch (error) {
		console.warn('Failed to build audio card data', error);
		return [];
	}
};

const getQuartileScore = (metricItems: MetricHash): string | null => {
	const quartileMetricValue = metricItems?.[
		'backward_digit_span_total_ser_order_score_quartile'
	]?.value as string;
	if (quartileMetricValue === 'NotAvailable') {
		return null;
	}
	return quartileMetricValue;
};

const checkIfTotalAchievedScoreIsZero = (metricItems: MetricHash): boolean => {
	const totalScoreAchieved =
		(metricItems?.['backward_digit_span_total_ser_order_score']
			?.value as number) ?? minimumAchievableScore;
	return totalScoreAchieved === 0;
};

const getTotalScoreToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): BackwardsDigitSpanLayoutProps['score']['total'] => {
	const totalScoreAchieved =
		(metricItems?.['backward_digit_span_total_ser_order_score']
			?.value as number) ?? minimumAchievableScore;
	const totalScoreToDisplay = `${
		totalScoreAchieved === 0
			? unanalyzableScoreToDisplay
			: totalScoreAchieved
	}/${maximumTotalScore}`;
	const scoreTitle = translationFn(
		'web.report.verbalFluency.correctResponses'
	)?.toUpperCase();
	return { value: totalScoreToDisplay, title: scoreTitle };
};

const getScoreInfo = (
	metricItems: MetricHash,
	trialNumber: number,
	type: 'order' | 'match'
) => {
	const metricsKey = `backward_digit_span_trial_${trialNumber}_${type}_score`;
	const scoreAchieved = metricItems?.[metricsKey]?.value ?? 0;
	const isZeroScore = scoreAchieved === 0;
	const scoreText = !isZeroScore
		? `${scoreAchieved}/${maximumScorePerTrial}`
		: unanalyzableScoreToDisplay;
	return { text: scoreText, requiresReview: isZeroScore };
};

const getIndividualScoreBreakdown = (
	metricItems: MetricHash,
	translationFn: TFunction,
	segmentName: string
): BackwardsDigitSpanTableProps['data'] => {
	return [
		{
			trialName: `${translationFn(
				'web.report.verbalFluency.bdst.trials.first'
			)}: ${segmentName}`,
			score: {
				anyOrder: getScoreInfo(metricItems, 1, 'match'),
				serialOrder: getScoreInfo(metricItems, 1, 'order'),
			},
		},
		{
			trialName: `${translationFn(
				'web.report.verbalFluency.bdst.trials.second'
			)}: ${segmentName}`,
			score: {
				anyOrder: getScoreInfo(metricItems, 2, 'match'),
				serialOrder: getScoreInfo(metricItems, 2, 'order'),
			},
		},
		{
			trialName: `${translationFn(
				'web.report.verbalFluency.bdst.trials.third'
			)}: ${segmentName}`,
			score: {
				anyOrder: getScoreInfo(metricItems, 3, 'match'),
				serialOrder: getScoreInfo(metricItems, 3, 'order'),
			},
		},
	];
};

const getCumulativeScoreInfo = (
	metricItems: MetricHash,
	translationFn: TFunction,
	segmentName: string
): BackwardsDigitSpanLayoutProps['score'] => {
	return {
		quartileText: getQuartileScore(metricItems),
		requiresClinicianReview: checkIfTotalAchievedScoreIsZero(metricItems),
		total: getTotalScoreToDisplay(metricItems, translationFn),
		breakdown: getIndividualScoreBreakdown(
			metricItems,
			translationFn,
			segmentName
		),
	};
};

const mapDataToProps = ({
	segmentResult,
	audioData,
	translationFn,
}: MapDataToPropsArgs): BackwardsDigitSpanLayoutProps | null => {
	if (!segmentResult) return null;
	if (!audioData) return null;

	const segmentName =
		segmentResult?.segment?.displayKey ?? 'Backwards Digit Span';

	const metricItems = segmentResult?.metricItems;

	const audioCardData = buildAudioCardData(
		segmentName,
		metricItems,
		translationFn,
		audioData
	);

	return {
		header: {
			title: segmentName,
			tooltipText: translationFn(
				'web.report.verbalFluency.bdst.tooltipText'
			),
		},
		audioCardData: audioCardData,
		score: getCumulativeScoreInfo(metricItems, translationFn, segmentName),
	};
};

export { mapDataToProps };
