// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const NoResponseDecorative = ({
	title,
	width = '24',
	height = '24',
	color = 'none',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 164 100'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='NoResultsDecorative-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title ?? 'NoResultsDecorative'}</title>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M100.5 30.5C100.5 41.2696 91.9934 50 81.5 50C71.0066 50 62.5 41.2696 62.5 30.5C62.5 19.7304 71.0066 11 81.5 11C91.9934 11 100.5 19.7304 100.5 30.5ZM98 30.5C98 39.9505 90.5519 47.5 81.5 47.5C72.4481 47.5 65 39.9505 65 30.5C65 21.0495 72.4481 13.5 81.5 13.5C90.5519 13.5 98 21.0495 98 30.5Z'
				fill='#676671'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M112.5 86.4144C112.5 88.3287 110.993 90.0001 109.023 90.0001L53.9766 89.9954C52.1206 89.9954 50.5 88.4407 50.5 86.4097V74.6955C50.5 63.8223 59.0734 55.0962 69.5033 55.0962H93.4956C103.177 55.0962 112.5 63.3282 112.5 73.2898V86.4144ZM110 73.2898V87.5046L53 87.5001V74.6955C53 64.9753 60.3884 57.5001 69.5033 57.5001H93.4956C101.5 57.5001 110 65.0001 110 73.2898Z'
				fill='#676671'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M108.5 22.6862C108.5 15.1493 114.649 9 122.186 9C129.723 9 135.872 15.1493 135.872 22.6546C135.872 30.1915 129.723 36.3408 122.186 36.3408C120.641 36.3408 119.127 36.0885 117.677 35.584L110.518 36.8454H110.361C110.045 36.8454 109.793 36.7192 109.604 36.4985C109.383 36.2462 109.351 35.8678 109.478 35.5525L111.401 31.0745C110.581 30.0338 109.919 28.8986 109.446 27.6687C108.815 26.092 108.5 24.4206 108.5 22.6862ZM119.159 22.9387C119.159 23.914 118.368 24.7046 117.393 24.7046C116.418 24.7046 115.627 23.914 115.627 22.9387C115.627 21.9634 116.418 21.1727 117.393 21.1727C118.368 21.1727 119.159 21.9634 119.159 22.9387ZM123.952 22.9387C123.952 23.914 123.162 24.7047 122.186 24.7047C121.211 24.7047 120.42 23.914 120.42 22.9387C120.42 21.9634 121.211 21.1727 122.186 21.1727C123.162 21.1727 123.952 21.9634 123.952 22.9387ZM126.98 24.7047C127.955 24.7047 128.746 23.914 128.746 22.9387C128.746 21.9634 127.955 21.1727 126.98 21.1727C126.004 21.1727 125.214 21.9634 125.214 22.9387C125.214 23.914 126.004 24.7047 126.98 24.7047Z'
				fill='#087DAE'
			/>
			<path
				d='M31.6846 26C39.2437 35.0013 42.1181 35.3299 51.7946 28.2987C42.1167 35.3299 41.7661 38 49.3224 47C41.7661 37.9987 38.8889 37.6701 29.2124 44.7013C38.8903 37.6701 39.2437 35.0013 31.6846 26Z'
				fill='#B1E5FB'
			/>
			<path
				d='M29.5342 81.615C36.9066 84.0883 38.6106 83.4292 41.4433 77C38.6106 83.4286 39.3661 84.9107 46.7397 87.3829C39.3661 84.9107 37.6608 85.5719 34.8293 92C37.6614 85.5724 36.9066 84.0883 29.5342 81.615Z'
				fill='#C3E3AC'
			/>
			<path
				d='M131.5 51.615C138.872 54.0883 140.576 53.4292 143.409 47C140.576 53.4286 141.332 54.9107 148.705 57.3829C141.332 54.9107 139.627 55.5719 136.795 62C139.627 55.5724 138.872 54.0883 131.5 51.615Z'
				fill='#C3E3AC'
			/>
			<line
				x1='42.25'
				y1='67.25'
				x2='1.75'
				y2='67.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='42.25'
				y1='73.25'
				x2='19.75'
				y2='73.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='168.25'
				y1='74.25'
				x2='119.75'
				y2='74.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='148.25'
				y1='68.25'
				x2='120.75'
				y2='68.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
		</svg>
	);
};
