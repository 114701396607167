import { useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { isNonEmptyString } from '../../utils/stringUtils';
import { QueryKey } from '../query';
import { GetPaginatedUsersInput, PaginatedUsers } from './types';

export async function fetchPaginatedUsers(
	input: GetPaginatedUsersInput
): Promise<PaginatedUsers> {
	if (!input.organizationId) {
		throw new Error('fetchPaginatedUsers: organization ID cannot be null');
	}
	const service = await getOrganizationService();
	const { data } = await service.getUsers(input);
	return data;
}

export function useGetPaginatedUsers(input: GetPaginatedUsersInput) {
	const queryParamValues = Object.values(input);
	return useQuery({
		enabled: isNonEmptyString(input.organizationId),
		meta: {
			errorMessage: `Error fetching users in organization: ${input.organizationId}`,
		},
		queryKey: [QueryKey.Users, ...queryParamValues],
		queryFn: () => fetchPaginatedUsers(input),
		staleTime: Infinity,
	});
}
