// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const ArrowCircleLeft = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='ArrowCircleLeft-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'ArrowCircleLeft'}</title>

			<path
				d='M7.70225 5.8132C7.87523 5.59794 7.84095 5.28322 7.6257 5.11025C7.41044 4.93727 7.09572 4.97155 6.92275 5.1868L4.11025 8.6868C3.96325 8.86973 3.96325 9.13027 4.11025 9.3132L6.92275 12.8132C7.09572 13.0285 7.41044 13.0627 7.6257 12.8898C7.84095 12.7168 7.87523 12.4021 7.70225 12.1868L5.54322 9.5H13.5C13.7761 9.5 14 9.27614 14 9C14 8.72386 13.7761 8.5 13.5 8.5H5.54322L7.70225 5.8132Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
				fill='current'
			/>
		</svg>
	);
};
