import styled, { css } from 'styled-components';

type LineDividerProps = {
	/**
	 * Determines the orientation of the line divider.
	 * - 'horizontal': Displays the line horizontally.
	 * - 'vertical': Displays the line vertically.
	 * @default 'horizontal'
	 */
	orientation?: 'vertical' | 'horizontal';
	spacing?: number;
};
export function LineDivider({
	orientation = 'horizontal',
	spacing = 0,
}: LineDividerProps) {
	return orientation === 'horizontal' ? (
		<HorizontalLine
			style={{
				marginTop: `${spacing} px`,
				marginBottom: `${spacing} px`,
			}}
		/>
	) : (
		<VerticalLine
			style={{
				marginLeft: `${spacing} px`,
				marginRight: `${spacing} px`,
			}}
		/>
	);
}

const HorizontalLine = styled.div(
	({ theme: { color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
	`
);

const VerticalLine = styled.div(
	({ theme: { color } }) => css`
		height: 100%;
		width: 1px;
		background-color: ${color.infoSeparator};
	`
);
