import styled from 'styled-components';
import { LoadingDots } from '../LoadingDots';
import { ButtonLgText } from './TextComponents';
import { ButtonProps } from '../../../types';
import { Icon } from './Icon';
import { theme } from '../../../providers/styleProvider';

/**
 * We can use this Icon commonly wherever Primary Styled Button is needed.
 * This will maintain consistency across all Primary Styled Buttons.
 */
export const ButtonLg = ({
	onClick,
	type = 'button',
	disabled,
	loading,
	width,
	text,
	primary,
	IconRight,
	IconCenter,
	IconLeft,
	dataId,
	dataTestId,
}: ButtonProps): JSX.Element => {
	let fillColor = theme.color.white;
	if (primary) {
		fillColor = theme.color.primaryButtonBg;
	}
	if (disabled) {
		fillColor = theme.color.buttonDisabled;
	}

	let hoverFillColor = theme.color.white;
	if (primary) {
		hoverFillColor = theme.color.primaryButtonHover;
	}

	if (disabled) {
		hoverFillColor = theme.color.white;
	}

	let iconColor = theme.color.white;
	if (disabled) {
		iconColor = theme.color.textDisabled;
	}
	if (!primary) {
		iconColor = theme.color.secondaryButtonColor;
	}
	const showLoading = loading && type;

	const showIconLeft = () => {
		if (showLoading || !IconLeft) {
			return null;
		}
		return <Icon icon={IconLeft} color={iconColor} />;
	};
	const showCenter = () => {
		if (IconCenter) {
			return <Icon icon={IconCenter} color={iconColor} />;
		} else if (showLoading === 'submit') {
			return <LoadingDots />;
		} else {
			return (
				<ButtonLgText disabled={disabled} primary={primary}>
					{text}
				</ButtonLgText>
			);
		}
	};
	const showIconRight = () => {
		if (showLoading || !IconRight) {
			return null;
		}
		return <Icon icon={IconRight} color={iconColor} />;
	};

	return (
		<StyledButtonLg
			width={width}
			onClick={onClick}
			type={type || 'button'}
			disabled={disabled}
			fillColor={fillColor}
			hoverFillColor={hoverFillColor}
			primary={primary}
			data-id={dataId}
			data-testid={dataTestId}>
			{showIconLeft()}
			{showCenter()}
			{showIconRight()}
		</StyledButtonLg>
	);
};

type StyledButtonLgProps = {
	width?: string;
	primary?: boolean;
	fillColor: string;
	hoverFillColor: string;
};
const StyledButtonLg = styled.button<StyledButtonLgProps>(
	({
		primary,
		hoverFillColor,
		fillColor,
		width,
		disabled,
		theme: { color, boxShadow },
	}) => `
	border: ${
		disabled
			? 'none'
			: primary
			? 'none'
			: `2px solid ${color.secondaryButtonBorder}`
	};
	border-radius: 30px;
	height: 56px;
	width: ${width || 'auto'};
	cursor: ${disabled ? 'not-allowed' : 'auto'};
	background: ${fillColor};
	display: flex;
	align-items: center;
    justify-content: space-evenly;
	transition: 0.10s ease all;
	color: ${
		disabled
			? color.textDisabled
			: primary
			? color.white
			: color.secondaryButtonColor
	};

	&:hover {
		background: ${disabled ? fillColor : hoverFillColor};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
		box-shadow:${disabled ? 'none' : boxShadow.button};
	}

	&:focus {
		outline: none;
		background: ${primary ? color.primaryButtonHover : color.white};
		border:${
			primary
				? ' 2px solid ' + color.primaryButtonFocusBorder
				: '1.5px solid ' + color.secondaryButtonFocusBorder
		};
		color: ${
			primary
				? color.primaryButtonFocusText
				: color.secondaryButtonFocusText
		};
	}
	`
);
