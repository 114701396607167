import ProgressBar from 'react-customizable-progressbar';
import { Trans } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { DctScoreClassification, dctScoreMap } from './dctScoreMap';

type DctClockScoreProgressBarProps = {
	dctScoreClassification: DctScoreClassification;
	score: number;
	radius?: number;
};

export const DctClockScoreProgressBar = ({
	dctScoreClassification,
	score,
	radius = 65,
}: DctClockScoreProgressBarProps): JSX.Element => {
	const theme = useTheme();
	const { dctScoreColor } = dctScoreMap(theme)[dctScoreClassification];
	return (
		<StyledProgressBar>
			<ProgressBar
				radius={radius}
				progress={score}
				strokeWidth={6}
				strokeColor={dctScoreColor}
				trackStrokeWidth={4}
				pointerRadius={8}
				pointerFillColor={dctScoreColor}
				pointerStrokeWidth={4}
				pointerStrokeColor={dctScoreColor}
				initialAnimation={false}
			/>
			<StyledProgressScore data-testid='dct-clock-subscore-display'>
				<Trans
					i18nKey={'web.report.DCTClock.subscore.progressBar.score'}
					values={{ score }}
				/>
			</StyledProgressScore>
		</StyledProgressBar>
	);
};

const StyledProgressBar = styled.div`
	min-height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const StyledProgressScore = styled.div(
	({ theme: { fontSize, spacing, weight, color } }) => `
	font-size: ${fontSize._32};
	line-height: ${spacing.xxxxl};
	text-align: center;
	font-weight: ${weight.medium};
	color: ${color.graphTextSecondary};
	position: absolute;
  padding-bottom: 10px;
  @media print {
    top: 0;
    transform: translateY(calc(50% + 16px));
  }
`
);
