// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const KeepHealthInCheck = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='KeepHealthInCheck-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'KeepHealthInCheck'}</title>
			<rect width='24' height='24' rx='12' fill='#B82405' />
			<path
				d='M16.4717 12.6129L16.4747 11.7097L17.8718 11.7144C18.9035 7.35813 13.4136 4.71572 11.9526 8.8308C10.2419 4.71831 5.18782 7.27068 6.11187 11.5523L8.83926 11.5383L9.96268 10.0565C10.2607 9.78952 10.5198 9.82305 10.7401 10.1571L12.0295 13.2837L13.1406 10.9487C13.3406 10.6552 13.5834 10.6138 13.8692 10.8248L14.7384 11.7017L15.5743 11.7097L15.5656 12.6129L14.5441 12.6031C14.4252 12.602 14.3115 12.5539 14.2277 12.4695L13.6801 11.9171L12.4078 14.5908C12.2408 14.9417 11.7329 14.9336 11.5825 14.5689L10.2059 11.2309L9.42437 12.2617C9.33951 12.3736 9.20733 12.4397 9.06681 12.4404L6.38865 12.4542C7.07535 14.1826 8.74005 16.1167 11.9128 17.976H11.9212C15.1421 16.1713 16.8488 14.3005 17.5712 12.6166L16.4717 12.6129Z'
				fill='white'
			/>
		</svg>
	);
};
