import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FeatureType } from 'generated/graphql';

import { Pillar } from '../../../../../enums/Pillar';
import { LinusTooltip } from '../../../../shared/LinusTooltip';
import { StyledCard } from '../../../../shared/StyledCard';
import { H1, P1, P2 } from '../../../../shared/designSystem/TextComponents';

import { ProcessedLifestyleFactors } from '../../../patientReport/actionPlan/ActionPlan/actionPlanLogic';
import { ActionPlanPillarPie } from '../../../patientReport/actionPlan/ActionPlanPillarPie';
import { PillarToolTip } from '../../../PillarToolTip';

import { QuestionnaireElevatedRisk } from './QuestionnaireElevatedRisk';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { UserContext } from '../../../../../context/UserContext';

type QuestionnaireHeaderProps = {
	title: string;
	tooltipCopy: string;
	tooltipMeasures: string;
	subHeader: string;
	categoriesWithRisks?: ProcessedLifestyleFactors;
	elevatedRisk?: boolean;
};
const QuestionnaireHeader = ({
	title,
	tooltipCopy,
	tooltipMeasures,
	subHeader,
	categoriesWithRisks,
	elevatedRisk,
}: QuestionnaireHeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	const organizationFeatures = currentUser.organizationFeatures;

	const dementiaRiskEnabled = organizationFeatures.includes(
		FeatureType.DementiaRiskEstimateReports
	);

	const measuresStr = t`web.report.lifeAndHealth.measures`;
	const pillarList = [
		Pillar.EatGreenAndLean,
		Pillar.KeepHealthInCheck,
		Pillar.MoveEveryDay,
		Pillar.StayTrueToPurpose,
		Pillar.LearnSomethingNew,
		Pillar.BuildYourVillage,
		Pillar.GetGoodRest,
		Pillar.BeMindful,
	];

	return (
		<StyledWrapper>
			<StyledTitleWrapper>
				<StyledHeader>{title}</StyledHeader>

				<LinusTooltip
					tooltipIcon={
						<StyledIcon>
							<Icon
								icon={icons.InfoOutlined}
								iconWidth={40}
								iconHeight={40}
							/>
						</StyledIcon>
					}
					overlay={
						<StyledTooltip>
							<StyledP2>{tooltipCopy}</StyledP2>
							<StyledP2Bold>{`${measuresStr} ${tooltipMeasures}`}</StyledP2Bold>
						</StyledTooltip>
					}
				/>
			</StyledTitleWrapper>
			{!!dementiaRiskEnabled && elevatedRisk && (
				<>
					<StyledPrintOnlyInfoWrapper>
						<StyledCaption>{tooltipCopy}</StyledCaption>
						<br />
						<StyledCaption>
							<strong>{`${measuresStr} `}</strong>
							{`${tooltipMeasures}`}
						</StyledCaption>
						<StyledSeparator />
					</StyledPrintOnlyInfoWrapper>
					<QuestionnaireElevatedRisk />
					<StyledPrintOnlyInfoWrapper>
						<StyledTextWrapper>
							<StyledCaption>
								<Trans
									i18nKey={`web.report.lifeAndHealth.elevatedRisk.tooltip`}
									components={{ newLine: <br /> }}
								/>
							</StyledCaption>
						</StyledTextWrapper>
					</StyledPrintOnlyInfoWrapper>
				</>
			)}
			<StyledSubHeader>
				<Trans i18nKey={subHeader} />
			</StyledSubHeader>
			<StyledPie>
				<ActionPlanPillarPie
					lhqData={categoriesWithRisks}
					width='926px'
					height='280px'
					longLineWidth='90px'
					shortLineWidth='35px'
					fontSize='18px'
					subtitleFontSize='14px'
					pieSize='277px'
				/>
			</StyledPie>
			<StyledLine />
			<StyledPillarContainer>
				{pillarList.map((item) => (
					<StyledPillarToolTip key={item.key}>
						<PillarToolTip pillar={item} />
					</StyledPillarToolTip>
				))}
			</StyledPillarContainer>
		</StyledWrapper>
	);
};

export { QuestionnaireHeader };

const StyledTitleWrapper = styled.div`
	display: flex;
	justify-content: left;
	align-items: baseline;
	height: 70px;
	margin-right: 2px;
	padding-right: 2px;
`;

const StyledWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => `
	margin: ${spacing.xl} 0;
	padding: ${spacing.xl};
`
);

const StyledPrintOnlyInfoWrapper = styled.div`
	display: none;
	@media print {
		display: block;
	}
`;

const StyledHeader = styled(H1)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.light};
	padding: 0;
	margin: 0 0 ${spacing.md} 0;
	display: flex;
	align-items: center;
`
);
const StyledTooltip = styled.div(
	({ theme: { fontSize } }) => `
	width: 200px;
	font-size: ${fontSize._16};
`
);
const StyledP2 = styled(P2)(
	({ theme: { color, spacing } }) => `
	color: ${color.white};
	margin: 0 0 ${spacing.md} 0;
	@media print {
		color: black;
	}
`
);

const StyledCaption = styled.div(
	({ theme: { color, fontSize, weight } }) => `
		font-size: ${fontSize._8};
		line-height: 10px;
		color: ${color.headerTextSecondary};
		letter-spacing: 0.4px;
		font-weight: ${weight.regular};
`
);
const StyledP2Bold = styled(P2)(
	({ theme: { color, weight, spacing } }) => `
	color: ${color.white};
	weight: ${weight.bold};
	margin: ${spacing.md} 0 0 0;
	@media print {
		color: black;
	}
`
);

const StyledPie = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
`;

const StyledSubHeader = styled(P1)(
	({ theme: { spacing } }) => `
	padding: ${spacing.lg} 0;
`
);

const StyledLine = styled.div`
	background-color: #d9d8dc;
	height: 2px;
	margin: 10px;
`;

const StyledPillarContainer = styled.div(
	({ theme: { spacing } }) => `
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: ${spacing.md};
	margin-left: ${spacing.md}
`
);

const StyledPillarToolTip = styled.div`
	width: 46%;
`;

const StyledSeparator = styled.div(
	({ theme: { color, spacing } }) => `
		position: relative;
		width: 100%;
		border-bottom: 1px solid ${color.infoSeparator};
		margin: ${spacing.md} 0;
	`
);

const StyledTextWrapper = styled.div(
	({ theme: { spacing } }) => `
		padding: 0 ${spacing.md} 0 ${spacing.xxxl};
	`
);

const StyledIcon = styled.div(
	({ theme: { spacing } }) => `
        margin: ${spacing.sm};
        width: 18px;
    `
);
