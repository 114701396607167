import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/shared/designSystem/Icon';
import { icons } from 'enums/icons';

export const ActionPlanPillarFocusArea = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<Text data-testid='pillarFocusAreaText'>
				{t('web.report.basicactionplan.focusArea')}
			</Text>
			<Icon icon={icons.FocusAreaStar} />
		</Container>
	);
};

const Container = styled.div(
	({ theme: { color } }) => css`
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		min-width: 90px;
		max-height: 16px;
		background-color: ${color.focusAreaBackground};
		border-radius: 100px;
		padding: 1px 4px 1px 8px;
	`
);

const Text = styled.span(
	({ theme: { baseFont, color, weight } }) => css`
		color: ${color.focusAreaText};
		font-family: ${baseFont};
		font-size: 11px;
		line-height: 13.5px;
		letter-spacing: 0.4px;
		font-weight: ${weight.regular};
		margin-right: 8px;
	`
);
