import styled, { CSSProperties } from 'styled-components';
import { theme } from '../../../providers/styleProvider/theme';
import { P1 } from '../Text';

type RadioButtonProps = {
	checked?: boolean;
	disabled?: boolean;
	color: string;
	label: string;
	testId: string;
	labelStyle?: CSSProperties;
	onPress?: () => void;
};

const RadioButton = ({
	checked,
	disabled,
	color,
	label,
	labelStyle = {},
	testId,
	onPress,
}: RadioButtonProps) => {
	const textColor = checked ? theme.color.bodyText : theme.color.textDisabled;
	const fontWeight = checked ? theme.weight.semi : theme.weight.regular;
	return (
		<StyledRow
			data-testid={`${testId}${checked ? '-checked' : ''}`}
			onClick={() => {
				if (!disabled) onPress?.();
			}}>
			<StyledOuterCircle
				data-testid={`${testId}-outer-circle${
					checked ? '-checked' : ''
				}`}
				style={{
					border: `2px solid ${color}`,
					opacity: disabled ? 0.5 : 1,
					cursor: disabled ? 'not-allowed' : 'pointer',
				}}>
				<StyledInnerCircle
					data-testid={`${testId}-inner-circle${
						checked ? '-checked' : ''
					}`}
					style={{
						backgroundColor: checked ? color : 'white',
					}}
				/>
			</StyledOuterCircle>
			<StyledLabel
				style={{
					fontWeight: fontWeight,
					color: textColor,
					...labelStyle,
				}}>
				{label}
			</StyledLabel>
		</StyledRow>
	);
};

export { RadioButton };

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 4px;
`;

const StyledOuterCircle = styled.div(
	() => `
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; 
  `
);

const StyledInnerCircle = styled.div(
	() => `
    width: 8px;
    height: 8px;
    border-radius: 50%;
    align-self: center;
  `
);

const StyledLabel = styled(P1)(
	({ theme: { spacing } }) => `
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-left: ${spacing.sm};
    padding-top: ${spacing.xs};
    padding-bottom: ${spacing.xs};
  `
);
