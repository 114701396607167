import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../../metrics';
import { TFunction } from 'react-i18next';

export const getMotorDataSet = (dataMap: MetricHash, t: TFunction): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const motorMap = {
		commandClock: [
			{
				x: t(motorStrings.percentInkTime),
				y: dataMap?.COMPercentInkTime_s?.value,
			},
			{
				x: t(motorStrings.averageSpeed),
				y: dataMap?.COMAverageSpeed_s?.value,
			},
			{ x: t(motorStrings.maxSpeed), y: dataMap?.COMMaxSpeed_s?.value },
			{
				x: t(motorStrings.initiationSpeed),
				y: dataMap?.COMInitiationSpeed_s?.value,
			},
			{
				x: t(motorStrings.terminationSpeed),
				y: dataMap?.COMTerminationSpeed_s?.value,
			},
			{
				x: t(motorStrings.oscillaryMotion),
				y: dataMap?.COMOscillatoryMotion_s?.value,
			},
		],
		copyClock: [
			{
				x: t(motorStrings.percentInkTime),
				y: dataMap?.COPPercentInkTime_s?.value,
			},
			{
				x: t(motorStrings.averageSpeed),
				y: dataMap?.COPAverageSpeed_s?.value,
			},
			{ x: t(motorStrings.maxSpeed), y: dataMap?.COPMaxSpeed_s?.value },
			{
				x: t(motorStrings.initiationSpeed),
				y: dataMap?.COPInitiationSpeed_s?.value,
			},
			{
				x: t(motorStrings.terminationSpeed),
				y: dataMap?.COPTerminationSpeed_s?.value,
			},
			{
				x: t(motorStrings.oscillaryMotion),
				y: dataMap?.COPOscillatoryMotion_s?.value,
			},
		],
	};

	return motorMap as DataSet;
};

export const motorStrings = {
	percentInkTime: 'web.report.segmentUtils.percentInkTime',
	averageSpeed: 'web.report.segmentUtils.averageSpeed',
	maxSpeed: 'web.report.segmentUtils.maxSpeed',
	initiationSpeed: 'web.report.segmentUtils.initiationSpeed',
	terminationSpeed: 'web.report.segmentUtils.terminationSpeed',
	oscillaryMotion: 'web.report.segmentUtils.oscillaryMotion',
};
