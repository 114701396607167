import { isEmpty } from 'lodash';

export const maxCharacterLimits = {
	firstname: 125,
	lastname: 125,
	notes: 2500,
	externalId: 250,
};

export const truncateString = (str: string, num: number): string => {
	if (str.length > num) {
		return str.slice(0, num) + '...';
	}
	return str;
};

export const convertToSentenceCase = (
	stringWithSpecialChar: string,
	charToReplace = ' '
) => {
	// https://lodash.com/docs/4.17.15#capitalize
	if (!stringWithSpecialChar) return '';
	const stringWithSpaces = stringWithSpecialChar.replaceAll(
		charToReplace,
		' '
	);
	return titleCase(stringWithSpaces);
};

function titleCase(inputString: string) {
	// https://lodash.com/docs/4.17.15#capitalize
	return inputString
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			if (!word) return '';
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(' ');
}

function removeAllSpaces(inputString: string) {
	return inputString?.replaceAll(' ', '');
}

export const isNonEmptyString = (
	inputString?: string | null | undefined
): inputString is string => {
	return !isEmpty(removeAllSpaces(inputString ?? ''));
};
