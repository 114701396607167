import { motion } from 'framer-motion';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useSwitchOrganization } from '../switchOrganization/useSwitchOrganization';
import { sendEventData } from '../../analytics/amplitude';
import { icons } from '../../enums/icons';
import { AnalyticsAction } from '../../generated/graphql';
import { loginLandingPage } from '../../providers/routeProvider';
import { Icon } from '../shared/designSystem';
import { SwitchOrganizationModal } from '../switchOrganization';
import { UserNavigation } from './UserNavigation';
import { Menu } from '../shared/menu/Menu';
import { UserContext } from '../../context/UserContext';

const SideBar = (): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [isSwitchOrgModalOpen, setIsSwitchOrgModalOpen] = useState(false);
	const navigate = useNavigate();

	const { switchOrganization, setPrimaryOrganizationId, organizations } =
		useSwitchOrganization();

	const { currentUser } = useContext(UserContext);

	const handleCollapse = () => {
		sendEventData({
			eventType: AnalyticsAction.ClickedSidebarHideArrow,
		});

		setIsCollapsed(!isCollapsed);
	};

	const handleSwitchOrgListItemClick = useCallback(
		async (orgId: string) => {
			setIsSwitchOrgModalOpen(false);
			switchOrganization(orgId);
			sendEventData({ eventType: AnalyticsAction.SwitchedOrg });
			const landingPage = loginLandingPage(currentUser);
			navigate(landingPage);
		},
		[currentUser, navigate, switchOrganization]
	);

	return (
		<>
			<SwitchOrganizationModal
				isOpen={isSwitchOrgModalOpen}
				onClose={() => setIsSwitchOrgModalOpen(false)}
				itemClickHandler={handleSwitchOrgListItemClick}
				organizations={organizations}
				setPrimaryOrgHandler={setPrimaryOrganizationId}
			/>
			<StyledNavContainer isCollapsed={isCollapsed}>
				<StyledArrowWrapper onClick={handleCollapse}>
					<StyledImage isCollapsed={isCollapsed}>
						<Icon
							icon={icons.ArrowLeft}
							color={theme.color.menuHoverText}
							title={t`web.shared.toggleSidebar`}
						/>
					</StyledImage>
				</StyledArrowWrapper>
				<StyledLogoWrapper>
					{isCollapsed && (
						<motion.div
							initial={{ opacity: 0, scale: 2.5, rotate: -10 }}
							animate={{ opacity: 1, scale: 1, rotate: 0 }}
							transition={{
								type: 'spring',
								duration: 0.5,
							}}>
							<Icon
								icon={icons.LogoSmall}
								iconWidth={32}
								iconHeight={32}
								title={t`web.shared.logo`}
							/>
						</motion.div>
					)}
					{!isCollapsed && (
						<motion.div
							initial={{ opacity: 0.25, scale: 0.75, rotate: -2 }}
							animate={{ opacity: 1, scale: 1, rotate: 0 }}
							transition={{
								type: 'spring',
								duration: 0.5,
							}}>
							<Icon
								icon={icons.LogoStacked}
								iconWidth={160}
								iconHeight={91}
								title={t`web.shared.logo`}
							/>
						</motion.div>
					)}
				</StyledLogoWrapper>
				<Menu
					isCollapsed={isCollapsed}
					onViewSwitchOrgModalClick={() =>
						setIsSwitchOrgModalOpen(true)
					}
				/>
				<UserNavigation
					isCollapsed={isCollapsed}
					setIsCollapsed={setIsCollapsed}
				/>

				<StyledOverlay isCollapsed={isCollapsed} />
			</StyledNavContainer>
		</>
	);
};

export { SideBar };

type StyledNavContainerProps = {
	isCollapsed: boolean;
};
const StyledNavContainer = styled.div<StyledNavContainerProps>(
	({ isCollapsed, theme: { color, spacing } }) => `
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: ${isCollapsed ? '75px' : '264px'};
	height: 100%;
	background: ${color.white};
	padding: ${spacing.xl} ${isCollapsed ? '0' : spacing.xl};
	box-sizing: border-box;
	border-right: 1px solid ${color.menuBorder};
	position: relative;
	transition: 0.2s ease all;
	z-index: ${isCollapsed ? 2 : 10};
`
);
const StyledImage = styled.div<StyledNavContainerProps>(
	({ isCollapsed }) => `
	transform: ${isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: 0.4s ease all;
`
);
const StyledLogoWrapper = styled(motion.div)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.xl} 0;
	box-sizing: border-box;
`
);
const StyledArrowWrapper = styled.div(
	({ theme: { color, boxShadow } }) => `
	position: absolute;
	right: -15px;
	top: 95px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	background: ${color.white};
	padding: 0;
	margin: 0;
	box-shadow: ${boxShadow.button};
	transition: 0.10s ease all;

	&:hover {
		transform: scale(1.1);
		cursor: pointer;
	}
`
);

const StyledOverlay = styled.div<StyledNavContainerProps>(
	({ isCollapsed, theme: { breakpoints } }) => `
	${breakpoints.tabletDown} {
		width: 100vw;
		height: 100%;
		position: ${isCollapsed ? 'fixed' : 'absolute'};
		top: 0;
		left: 100%;
		z-index: -1;
		background: black;
		opacity: ${isCollapsed ? '0' : '0.40'};
		transition: 0.3s ease all;
	}
`
);
