import { Buffer } from 'buffer';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { useAuthServiceContext } from '../../../features/auth-service';
import { useFinishSignupMutation } from '../../../generated/graphql';
import {
	CreateAccountModel,
	getModel,
	validationSchema,
} from '../../../schemas/forms/createAccountSchema';
import { CurrentUser } from '../../../types';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { LinusPasswordInput } from '../../shared/Forms/Components/LinusPasswordInput';
import { LinusPasswordInputWithStrength } from '../../shared/Forms/Components/LinusPasswordInputWithStrength';
import { InfoMessage } from '../../shared/InfoMessage';
import { PrivacyPolicyLink } from '../../shared/PrivacyPolicyLink';
import { TermsOfUseLink } from '../../shared/TermsOfUseLink';
import { ButtonLg, H2, Icon } from '../../shared/designSystem';

const CreateAccountFormCognito = (): JSX.Element => {
	const { t } = useTranslation();
	const theme = useTheme();
	const auth = useAuthServiceContext();

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const [finishSignupMutation, { loading, error: finishSignUpError }] =
		useFinishSignupMutation({
			errorPolicy: 'all',
		});

	const messages = {
		finishSignUpError: t`web.auth.shared.messages.finishSignUpError`,
		invalidInvitationError: t`web.auth.shared.messages.invalidInvitationError`,
	};
	const { setCurrentUser } = useContext(UserContext);

	if (finishSignUpError && !serverSideMessage) {
		setServerSideMessage(
			messageEnum.error(
				messages.finishSignUpError,
				icons.AlertShieldSolid
			)
		);
	}

	const navigate = useNavigate();
	// If you need to change these param names, it must also be changed in the AWS trigger
	const params = new URLSearchParams(useLocation().search);

	const json = Buffer.from(params.get('payload') as string, 'hex').toString();
	const payload = JSON.parse(json);

	const onSubmit = async (
		values: CreateAccountModel,
		{ setSubmitting }: FormikHelpers<CreateAccountModel>
	) => {
		// Resetting the error state on submit click
		setServerSideMessage(undefined);
		try {
			const success = await auth.finishSignup({
				username: values.email,
				tempPass: payload.tempPass,
				newPass: values.password,
			});
			if (!success) {
				setServerSideMessage(
					messageEnum.error(
						messages.finishSignUpError,
						icons.AlertShieldSolid
					)
				);
			}
		} catch (err) {
			setServerSideMessage(
				messageEnum.error(
					messages.invalidInvitationError,
					icons.AlertShieldSolid,
					<ErrorContainer>
						<Trans
							i18nKey={messages.invalidInvitationError}
							components={[
								<Link href='mailto:support@linus.health'></Link>,
							]}
						/>
					</ErrorContainer>
				)
			);
			return;
		}

		const newUser = await finishSignupMutation({
			variables: {
				FinishSignupInput: {
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
				},
			},
		});

		const currentUser = newUser?.data?.finishSignup
			?.currentUser as CurrentUser;

		if (currentUser) {
			setCurrentUser && setCurrentUser(currentUser);
			navigate('/landingPage');
		} else {
			if (newUser.errors) {
				newUser.errors.forEach((el) =>
					console.error('ERROR: ', el.message)
				);
			}

			setServerSideMessage(
				messageEnum.error(
					messages.finishSignUpError,
					icons.AlertShieldSolid
				)
			);
		}
		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel(payload)}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledContainer>
						<StyledMessageContainer>
							{!serverSideMessage && (
								<>
									<Icon
										icon={icons.InviteOutlined}
										width={40}
										height={40}
										color={theme.color.iconInvite}
										title={t`web.auth.forms.inviteMail`}
									/>
									<StyledInviteMessage>
										<Trans
											i18nKey='web.auth.shared.fragments.invited'
											values={{
												firstName:
													payload.inviterFirstName,
												lastName:
													payload.inviterLastName,
											}}
											components={[
												<strong
													key={`styled-invite-strong`}
												/>,
												<StyledSpacing
													key={`styled-invite-spacing`}
												/>,
											]}></Trans>
										<strong>{payload.orgName}</strong>
									</StyledInviteMessage>
								</>
							)}
						</StyledMessageContainer>
						<H2>
							<Trans i18nKey={'web.auth.forms.title'} />
						</H2>
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='360px'
									name='firstName'
									data-id='setup-new-pass-first-name'
									label={t`web.shared.firstName`}
								/>
								<LinusInput
									width='360px'
									name='lastName'
									data-id='setup-new-pass-last-name'
									label={t`web.shared.lastName`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									data-id='setup-new-pass-email'
									name='email'
									type='email'
									width='100%'
									disabled
									label={t`web.shared.email`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusPasswordInputWithStrength
									width='360px'
									name='password'
									label={t`web.shared.password`}
								/>
								<LinusPasswordInput
									width='360px'
									name='repeatPassword'
									label={t`web.shared.repeatPassword`}
								/>
							</StyledRow>
							<InfoMessage
								showIf={!!serverSideMessage}
								messageEnum={serverSideMessage}
							/>
							<StyledAgreementContainer>
								<StyledAgreementText>
									<Trans
										i18nKey='web.auth.forms.agreement'
										components={[
											<PrivacyPolicyLink
												key={'privacy_policy_link'}
												text={t`web.shared.LHPP`}
												underline={false}
											/>,
											<TermsOfUseLink
												key={'terms_of_use_link'}
												underline={false}
											/>,
										]}></Trans>
								</StyledAgreementText>
							</StyledAgreementContainer>
							<StyledSubmitButtonWrapper>
								<ButtonLg
									dataId='createAccountBtn'
									disabled={
										!(isValid && dirty) || isSubmitting
									}
									text={t`web.auth.forms.createAccountBtn`}
									type='submit'
									width='360px'
									primary={true}
									loading={loading}
								/>
							</StyledSubmitButtonWrapper>
						</StyledForm>
					</StyledContainer>
				);
			}}
		</Formik>
	);
};

export { CreateAccountFormCognito };

const StyledContainer = styled.div`
	width: 100%;
	height: 760px;
	padding: 56px 120px;
	transition: 0.4s ease all;
	text-align: center;
	justify-content: space-between;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const StyledMessageContainer = styled.div(
	({ theme: { spacing } }) => `
        display: flex;
        align-items: center;
		margin: ${spacing.xl} 0;
    `
);

const StyledForm = styled(Form)(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);
const StyledAgreementContainer = styled.div`
	display: flex;
`;

const StyledAgreementText = styled.span(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);

const StyledSubmitButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.md} 0;
	display: flex;
	justify-content: center;
	`
);

const StyledInviteMessage = styled.span(
	({ theme: { spacing } }) => `
	text-align: center;
	margin: 0 0 0 ${spacing.md};
	`
);

const StyledSpacing = styled.span(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.xs}
`
);

const ErrorContainer = styled.div(
	({ theme: { color, spacing } }) => `
	color: ${color.formError};
	text-align: left;
	margin-top: ${spacing.md}
	`
);

const Link = styled.a(
	({ theme: { color } }) => `
    color: ${color.link}	;
`
);
