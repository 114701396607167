// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Clipboard = ({
	title,
	width = 19,
	height = 18,
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});

	return (
		<svg
			viewBox={`0 0 19 18`}
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Clipboard-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Clipboard'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.69105 0.7C6.45331 0.281539 6.00894 0 5.5 0C4.99106 0 4.54669 0.281539 4.30895 0.7L1.33333 0.700007C0.598333 0.700007 0 1.3044 0 2.04684V12.9679C0 13.7104 0.598333 14.3147 1.33333 14.3147H6V13.3147H1.66667C1.29833 13.3147 1 13.0134 1 12.6413V2.36158C1 1.98952 1.29833 1.68816 1.66667 1.68816H2.75V2.97624C2.75 3.36284 3.0578 3.67624 3.4375 3.67624H7.5625C7.9422 3.67624 8.25 3.36284 8.25 2.97624V1.68816H9.33333C9.70167 1.68816 10 1.98952 10 2.36158V3H11V2.04684C11 1.3044 10.4017 0.700007 9.66667 0.700007L6.69105 0.7ZM3.95608 2.67624C3.86116 2.67624 3.7842 2.59789 3.7842 2.50124V1.71474H4.8125C4.8125 1.32814 5.1203 1.01474 5.5 1.01474C5.8797 1.01474 6.1875 1.32814 6.1875 1.71474H7.17188V2.50124C7.17188 2.59789 7.09492 2.67624 7 2.67624H3.95608Z'
				fill={color}
			/>
			<path
				d='M9.12506 6.99999C9.12506 6.72385 9.34892 6.49999 9.62506 6.49999H15.6251C15.9012 6.49999 16.1251 6.72385 16.1251 6.99999C16.1251 7.27613 15.9012 7.49999 15.6251 7.49999H9.62506C9.34892 7.49999 9.12506 7.27613 9.12506 6.99999Z'
				fill={color}
			/>
			<path
				d='M9.12506 8.99999C9.12506 8.72385 9.34892 8.49999 9.62506 8.49999H15.6251C15.9012 8.49999 16.1251 8.72385 16.1251 8.99999C16.1251 9.27613 15.9012 9.49999 15.6251 9.49999H9.62506C9.34892 9.49999 9.12506 9.27613 9.12506 8.99999Z'
				fill={color}
			/>
			<path
				d='M9.62506 10.5C9.34892 10.5 9.12506 10.7238 9.12506 11C9.12506 11.2761 9.34892 11.5 9.62506 11.5H15.6251C15.9012 11.5 16.1251 11.2761 16.1251 11C16.1251 10.7238 15.9012 10.5 15.6251 10.5H9.62506Z'
				fill={color}
			/>
			<path
				d='M9.12506 15C9.12506 14.7239 9.34892 14.5 9.62506 14.5H15.6251C15.9012 14.5 16.1251 14.7239 16.1251 15C16.1251 15.2761 15.9012 15.5 15.6251 15.5H9.62506C9.34892 15.5 9.12506 15.2761 9.12506 15Z'
				fill={color}
			/>
			<path
				d='M9.62506 12.5C9.34892 12.5 9.12506 12.7239 9.12506 13C9.12506 13.2761 9.34892 13.5 9.62506 13.5H15.6251C15.9012 13.5 16.1251 13.2761 16.1251 13C16.1251 12.7239 15.9012 12.5 15.6251 12.5H9.62506Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.6263 4.00001L8.37374 4C7.61647 4 7 4.6362 7 5.41772V16.5823C7 17.3638 7.61647 18 8.37374 18L16.6263 18C17.3835 18 18 17.3638 18 16.5823V5.41773C18 4.63621 17.3835 4.00001 16.6263 4.00001ZM17.0486 16.2861C17.0486 16.6777 16.7413 16.9949 16.3618 16.9949H8.68687C8.30737 16.9949 8 16.6777 8 16.2861V5.99999C8 5.5 8 4.99999 8.68687 4.99999H16C17 4.99999 17.0486 5.5 17.0486 5.99999V16.2861Z'
				fill={color}
			/>
		</svg>
	);
};
