// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AddUser = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AddUser-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'AddUser'}</title>
			<path
				d='M12.9993 21.4995L5.78875 21.4989C5.36766 21.4989 5 21.1447 5 20.6819V18.0129C5 15.5354 6.94506 13.5472 9.31131 13.5472H14.7545C15.0797 13.5472 15.4032 13.5883 15.7183 13.6658C15.0621 13.8393 14.4589 14.1429 13.9377 14.5472H9.31131C7.52642 14.5472 6 16.0582 6 18.0129V20.499L12.4158 20.4995C12.5718 20.8565 12.7682 21.1918 12.9993 21.4995Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.3577 7.96568C16.3577 10.4431 14.4126 12.4314 12.0464 12.4314C9.65354 12.4314 7.73403 10.4433 7.73506 7.96568C7.73506 5.48823 9.68012 3.5 12.0464 3.5C14.4382 3.5 16.3577 5.48703 16.3577 7.96568ZM8.73506 7.96568C8.73506 6.01099 10.2615 4.5 12.0464 4.5C13.8532 4.5 15.3577 6.00605 15.3577 7.96568C15.3577 9.92037 13.8313 11.4314 12.0464 11.4314C10.2385 11.4314 8.73425 9.92385 8.73506 7.96568Z'
				fill='current'
			/>
			<path
				d='M19 19C19.2761 19 19.5 18.7761 19.5 18.5C19.5 18.2239 19.2761 18 19 18H17.5V16.5C17.5 16.2239 17.2761 16 17 16C16.7239 16 16.5 16.2239 16.5 16.5V18H15C14.7239 18 14.5 18.2239 14.5 18.5C14.5 18.7761 14.7239 19 15 19H16.5V20.5C16.5 20.7761 16.7239 21 17 21C17.2761 21 17.5 20.7761 17.5 20.5V19H19Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21 18.5C21 20.7091 19.2091 22.5 17 22.5C14.7909 22.5 13 20.7091 13 18.5C13 16.2909 14.7909 14.5 17 14.5C19.2091 14.5 21 16.2909 21 18.5ZM20 18.5C20 20.1569 18.6569 21.5 17 21.5C15.3431 21.5 14 20.1569 14 18.5C14 16.8431 15.3431 15.5 17 15.5C18.6569 15.5 20 16.8431 20 18.5Z'
				fill='current'
			/>
		</svg>
	);
};
