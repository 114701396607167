import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Assignment, CreateAssignmentInput, AssignmentStatus } from './types';
import { getBatteryService } from '../getBatteryService';
import { QueryKey } from 'api/query';
import { AxiosError } from 'axios';

type CreateAssignmentArgs = {
	assignment: CreateAssignmentInput;
};

async function createAssignment({ assignment }: CreateAssignmentArgs) {
	const service = await getBatteryService();
	return await service.createAssignment({
		createAssignmentInputV2: { ...assignment },
	});
}

export function useCreateAssignment() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error creating assignment',
		},
		mutationFn: createAssignment,
		onMutate: async ({ assignment }) => {
			const queryTuple = [QueryKey.Assignment, assignment.participantId];
			await queryClient.cancelQueries({
				queryKey: queryTuple,
			});
			const existing = await queryClient.getQueryData<Assignment>(
				queryTuple
			);
			const newAssignment = {
				...existing,
				...assignment,
				assignmentStatus: AssignmentStatus.Assigned,
			};
			queryClient.setQueryData(queryTuple, newAssignment);
			return { previous: existing, current: newAssignment };
		},
		onError: (
			_: AxiosError<{ errorCode: string }>,
			updateParams,
			context
		) => {
			queryClient.setQueryData(
				[QueryKey.Assignment, updateParams.assignment.participantId],
				context?.previous
			);
		},
		onSettled: (result) => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.Assignment, result?.data.participantId],
			});
		},
	});
}
