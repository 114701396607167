import { AssessmentResultFragment } from '../../generated/graphql';

export type Metric = {
	value: number | string;
	version: string;
};
export type MetricHash = {
	[key: string]: Metric;
};

type MetricItem = AssessmentResultFragment['metricItems']['nodes'][0];

//Create a map from the array of metric nodes
export const createMapFromMetricsArray = (arr: MetricItem[]): MetricHash => {
	const dataMap = arr.reduce((map: MetricHash, metric) => {
		const val = Number(metric.value);
		//map the key/value pairs while narrowing the type
		if (isNaN(val)) {
			map[metric.key] = {
				value: metric.value,
				version: metric.algorithmVersion,
			};
		} else {
			map[metric.key] = {
				// Composite scales expect 1 decimal place. Round down
				value: Number(Math.floor(val * 10) / 10),
				version: metric.algorithmVersion,
			};
		}

		return map;
	}, {});

	return dataMap;
};
