import { DateTime } from 'luxon';

export const dateFormatter = (
	date: string,
	dateFormat: string,
	timezone?: string
): string => {
	return timezone
		? DateTime.fromISO(date).setZone(timezone).toFormat(dateFormat)
		: DateTime.fromISO(date).toFormat(dateFormat);
};

export const dateTimeFormatter = (
	date: string,
	dateFormat: string,
	locale: string,
	timezone?: string
): string => {
	const dateTimeComponents = [];
	if (timezone) {
		dateTimeComponents.push(dateFormatter(date, dateFormat, timezone));
		dateTimeComponents.push(
			DateTime.fromISO(date)
				.setLocale(locale)
				.setZone(timezone)
				.toLocaleString(DateTime.TIME_SIMPLE)
		);
	} else {
		dateTimeComponents.push(dateFormatter(date, dateFormat));
		dateTimeComponents.push(
			DateTime.fromISO(date)
				.setLocale(locale)
				.toLocaleString(DateTime.TIME_SIMPLE)
		);
	}
	return dateTimeComponents.join(', ');
};

// WEB-2434
export const utcDateFormatter = (date: string, dateFormat: string): string => {
	return DateTime.fromISO(date, { zone: 'utc' }).toFormat(dateFormat);
};
