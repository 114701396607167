import { ReactNode, useState } from 'react';
import styled from 'styled-components';

export function Notification({
	children,
	text,
}: {
	children: ReactNode;
	text: string;
}): JSX.Element {
	const [hover, setHover] = useState(false);
	return (
		<StyledNotification
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}>
			{children}
			{hover && <StyledOverlay>{text as string}</StyledOverlay>}
		</StyledNotification>
	);
}

const StyledNotification = styled.span`
	padding-right: 5px;
`;

const StyledOverlay = styled.span(({ theme: { color, spacing, fontSize } }) => {
	return `
    position: fixed;
    z-index: 2;
    transform: translate(-250px, 32px);
    width: 264px;
    padding: ${spacing.md};
    font-size: ${fontSize._14};
    background-color: ${color.textInfo};
    border-radius: 6px;
    color: ${color.white};
    box-shadow: -3px 3px 3px 0 rgb(204 204 204 / 40%);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 248px;
      box-sizing: border-box;
      border: 7px solid ${color.textInfo};
      border-color: transparent transparent ${color.textInfo} ${color.textInfo};
      -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(322deg);
      -ms-transform: rotate(322deg);
      transform: rotate(135deg);
      box-shadow: -3px 3px 3px 0 rgb(204 204 204 / 40%);
      pointer-events: none;
    }
`;
});
