// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CheckmarkSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckmarkSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CheckmarkSolid'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM14.0725 6.48446C14.3401 6.16825 14.3007 5.69502 13.9845 5.42746C13.6683 5.1599 13.195 5.19934 12.9275 5.51554L7.99632 11.3433L5.56944 8.51191C5.29988 8.19741 4.8264 8.16099 4.51191 8.43056C4.19741 8.70012 4.16099 9.1736 4.43056 9.48809L7.43056 12.9881C7.57359 13.155 7.78261 13.2507 8.00239 13.25C8.22217 13.2493 8.43058 13.1522 8.57254 12.9845L14.0725 6.48446Z'
				fill='current'
			/>
		</svg>
	);
};
