import { Form, Formik, FormikHelpers } from 'formik';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sendEventData } from '../../analytics/amplitude';
import { UserContext } from '../../context/UserContext';
import { MessageEnumItem, messageEnum } from '../../enums/messageEnum';
import { useAuthServiceContext } from '../../features/auth-service';
import {
	AnalyticsAction,
	User,
	ValueListType,
	useGetRolesForOrgLazyQuery,
	useUserIsThirdPartyQuery,
	useUpdateProfileMutation,
} from '../../generated/graphql';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import { hasOperation } from '../../hasOperation';
import { ERROR } from '../../logging/linusLogger';
import {
	UserProfileModel,
	getModel,
	validationSchema,
} from '../../schemas/forms/userProfileSchema';
import { CurrentUser } from '../../types';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import { Header } from '../shared/Header';
import { InfoMessage } from '../shared/InfoMessage';
import { StyledCard } from '../shared/StyledCard';
import { ButtonLg, H2 } from '../shared/designSystem';
import { LoadingDots } from '../shared/LoadingDots';
import { SSOTag } from '../shared/LinusModal';
import { useOrganizationStore } from 'store';

const UserProfile = (): JSX.Element | null => {
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedProfile });
	}, []);

	const { currentUser, clearImpersonation, setCurrentUser } =
		useContext(UserContext);
	const { t } = useTranslation();

	const { currentOrganizationId } = useOrganizationStore();

	// Primary Org ID = default org
	const orgId = currentOrganizationId ?? currentUser.organizationId;
	const userId = currentUser.id;
	const { data: thirdPartyData, loading } = useUserIsThirdPartyQuery({
		errorPolicy: 'all',
		variables: {
			orgId: currentUser.organizationId,
			userId: currentUser.id,
		},
		skip: !orgId || !userId,
	});

	const isThirdPartyManaged =
		thirdPartyData?.organization?.userById?.isThirdPartyManaged;

	const auth = useAuthServiceContext();

	const [showMessage, setShowMessage] = useState<MessageEnumItem>();
	const [
		updateProfileMutation,
		{ loading: updateUserLoading, error: updateUserError },
	] = useUpdateProfileMutation();
	const [rolesForOrgQuery, { data, loading: rolesLoading }] =
		useGetRolesForOrgLazyQuery({
			variables: {
				orgId,
			},
		});

	const roles = data?.organization?.roles?.nodes;
	const isAdmin = hasOperation(currentUser, [OperationToken.EditUser]);

	useEffect(() => {
		clearImpersonation();
		rolesForOrgQuery();
		// only run on load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		loading ||
		rolesLoading ||
		!thirdPartyData?.organization ||
		!data?.organization
	) {
		return <LoadingDots />;
	}

	if (updateUserError) {
		ERROR(updateUserError.message, updateUserError);
	}

	const handleResetPassword = async () => {
		const success = await auth.requestPasswordReset(currentUser?.email);

		setShowMessage(
			success
				? messageEnum.success(t`web.profile.emailSent`)
				: messageEnum.error(t`web.profile.resetPasswordError`)
		);
		if (!updateUserError) {
			sendEventData({ eventType: AnalyticsAction.ResetPassword });
		}
	};

	const updateCurrentUser = (
		user: User,
		values: UserProfileModel,
		role?: { id: string }
	) => {
		setCurrentUser({
			...currentUser,
			firstName: user?.firstName || values.firstName,
			lastName: user?.lastName || values.lastName,
			phoneNumber: user?.phoneNumber || values.phoneNumber,
			suffix: user?.suffix || values?.suffixIds.split(', '),
			roleId: user?.role?.id || role?.id || currentUser.roleId,
			operations:
				user?.role?.operations?.map((o) => o.name) ||
				currentUser.operations,
		} as CurrentUser);
	};

	const onSubmit = async (
		values: UserProfileModel,
		{ setSubmitting }: FormikHelpers<UserProfileModel>
	) => {
		// Only Admins are allowed to update their roles.
		const role = isAdmin ? { id: values.roleId } : undefined;
		// TODO: figure out how to submit new avatar through mutation
		// Thinking it would require sending it to an S3 bucket and then saving the URL
		const result = await updateProfileMutation({
			variables: {
				UpdateProfileInput: {
					id: values.id,
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					suffixIds: values?.suffixIds.split(', '),
					role,
				},
				orgId,
			},
		});
		if (!result.data?.updateProfile.success) {
			setShowMessage(messageEnum.error(t`web.profile.changesError`));
			setSubmitting(false);
			return;
		} else {
			setShowMessage(messageEnum.success(t`web.profile.changesSuccess`));
			sendEventData({ eventType: AnalyticsAction.SavedAccountChanges });
			const user = result.data.updateProfile.user;
			updateCurrentUser(user as User, values, role);
		}
		setSubmitting(false);
	};
	return (
		<Fragment data-testid='user-not-found-page'>
			<Header display={t`web.profile.displayTitle`} />
			<Formik
				initialValues={getModel(currentUser)}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize={true}>
				{({ isSubmitting, isValid, dirty }) => {
					return (
						<>
							<StyledFormContainer>
								<StyledTitle>
									<StyledH2>
										<Trans i18nKey='web.profile.accountInformation' />
									</StyledH2>
									{isThirdPartyManaged && (
										<SSOTag>{t`web.shared.ssoUser`}</SSOTag>
									)}
								</StyledTitle>
								<StyledFormContent>
									<StyledForm>
										<StyledRow>
											<LinusInput
												data-id='firstName-input'
												name='firstName'
												label={t`web.shared.firstName`}
											/>
											<LinusInput
												data-id='lastName-input'
												name='lastName'
												label={t`web.shared.lastName`}
											/>
											<LinusInput
												width='200px'
												name='suffixIds'
												label={t`web.team.sharedModal.suffixLabel`}
												disabled={
													!currentUser?.organizationValueLists
												}
												type='multi'
												preferDisplayLength='long'
												dropdownOptions={currentUser?.organizationValueLists?.[
													ValueListType?.Suffix
												]?.toOptions()}
											/>
										</StyledRow>
										<StyledRow>
											<LinusInput
												data-id='emailAddress-input'
												width='49%'
												name='email'
												label={t`web.shared.emailAddress`}
												type='email'
												disabled
											/>
											<LinusInput
												data-id='phoneNumber-input'
												width='49%'
												name='phoneNumber'
												label={t`web.team.sharedModal.phoneNumberLabel`}
											/>
										</StyledRow>
										<StyledRow>
											<LinusInput
												data-id='userRole-input'
												name='roleId'
												type='select'
												label={t`web.team.sharedModal.userRoleLabel`}
												width='100%'
												dropdownOptions={(
													roles || []
												).map((r) => ({
													display: t(r.displayKey),
													value: r.id,
												}))}
												disabled={!isAdmin}
											/>
										</StyledRow>
										{!!showMessage && (
											<StyledInfoMessage>
												<InfoMessage
													messageEnum={showMessage}
													showIf={!!showMessage}
												/>
											</StyledInfoMessage>
										)}
										<StyledButtonRow>
											{!isThirdPartyManaged && (
												<StyledButtonWrapper>
													<ButtonLg
														onClick={
															handleResetPassword
														}
														text={t`web.shared.resetPassword`}
														primary={false}
														width='200px'
														dataId='resetPassword-button'
													/>
												</StyledButtonWrapper>
											)}

											<ButtonLg
												primary={true}
												// TODO: after making one change to field and saving, without refreshing the page,
												// we are unable to revert change and save with original value
												disabled={
													!(isValid && dirty) ||
													isSubmitting
												}
												text={t`web.profile.saveChangesButton`}
												type='submit'
												dataId='saveChanges-button'
												width='200px'
												loading={updateUserLoading}
											/>
										</StyledButtonRow>
									</StyledForm>
								</StyledFormContent>
							</StyledFormContainer>
						</>
					);
				}}
			</Formik>
		</Fragment>
	);
};

const StyledFormContainer = styled(StyledCard)(
	({ theme: { spacing } }) => `
	padding: ${spacing.xxl} ${spacing.xl};
	width: 1000px;
`
);

const StyledFormContent = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: ${spacing.lg};
`
);

const StyledForm = styled(Form)`
	width: 900px;
	margin-left: 36px;
`;
const StyledTitle = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

const StyledH2 = styled(H2)(
	({ theme: { color, weight, fontSize } }) => `
	color: ${color.formText};
	font-weight: ${weight.regular};
	display: flex;
	align-items: center;
	font-size: ${fontSize._32};
	line-height: 44px;
	`
);

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: start;
	padding-top: ${spacing.lg};
	`
);
const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.xl} 0 0;
`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.lg} 0 0 0;
	display: flex;
	justify-content: start;
`
);

export { UserProfile };
