import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../../artifacts/ReportArtifactNames';
import { ArtifactFormats } from '../../../shared/hooks';
import { useGetReportData } from '../../../shared/hooks/useGetReportData';
import { FormattedSegmentResult } from '../../common';
import { mapDataToProps } from './ImmediateRecall6.helpers';
import ImmediateRecall6Layout from './ImmediateRecall6Layout';

export type ImmediateRecallSixWordProps = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayers?: boolean;
};

const ImmediateRecallSixWord = ({
	data: segmentResult,
	showAudioPlayers = false,
}: ImmediateRecallSixWordProps) => {
	const { t: translationFn } = useTranslation();
	const segmentResultId = useMemo(
		() => segmentResult?.id,
		[segmentResult?.id]
	);

	const {
		error,
		loading,
		binaryArtifactData: audioData,
	} = useGetReportData({
		segmentResultId,
		fileName: Json.ImmediateRecall6,
		fileType: ArtifactFormats.JSON,
	});

	const layoutProps = useMemo(
		() => mapDataToProps({ segmentResult, audioData, translationFn }),
		[audioData, segmentResult, translationFn]
	);

	if (error) {
		console.warn(error);
		return null;
	}

	if (loading || !layoutProps) return null;

	return (
		<ImmediateRecall6Layout
			{...layoutProps}
			showAudioPlayers={showAudioPlayers}
		/>
	);
};

export { ImmediateRecallSixWord };
