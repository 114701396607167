import {
	DefaultApi as QnrService,
	Configuration as QnrServiceConfiguration,
} from '@lh/qnr-svc-rest-client';

import { useAuthStore } from 'store';
import { config } from '../config';

export async function getQnrService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new QnrService(
			new QnrServiceConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
