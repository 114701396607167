// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const BuildYourVillageTapped = ({
	title,
	width = '24',
	height = '24',
	color = '#14B2F4',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 33 33'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='BuildYourVillageTapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'BuildYourVillageTapped'}</title>
			<rect x='0.5' y='0.5' width='32' height='32' rx='16' fill={color} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.3468 8.94589C16.3468 7.32362 17.6704 6 19.2927 6C20.9149 6 22.2386 7.32362 22.2386 8.93911C22.2386 10.5614 20.9149 11.885 19.2927 11.885C18.9601 11.885 18.6343 11.8307 18.322 11.7221L16.7812 11.9936H16.7473C16.6794 11.9936 16.6251 11.9665 16.5843 11.9189C16.5368 11.8646 16.53 11.7832 16.5572 11.7153L16.9713 10.7514C16.7948 10.5274 16.6522 10.2831 16.5504 10.0184C16.4147 9.67897 16.3468 9.31922 16.3468 8.94589ZM18.2609 9.38035C18.4709 9.38035 18.6411 9.21017 18.6411 9.00024C18.6411 8.79031 18.4709 8.62012 18.2609 8.62012C18.051 8.62012 17.8808 8.79031 17.8808 9.00024C17.8808 9.21017 18.051 9.38035 18.2609 9.38035ZM19.6728 9.00024C19.6728 9.21017 19.5026 9.38035 19.2927 9.38035C19.0828 9.38035 18.9126 9.21017 18.9126 9.00024C18.9126 8.79031 19.0828 8.62012 19.2927 8.62012C19.5026 8.62012 19.6728 8.79031 19.6728 9.00024ZM20.3244 9.38035C20.5343 9.38035 20.7045 9.21017 20.7045 9.00024C20.7045 8.79031 20.5343 8.62012 20.3244 8.62012C20.1145 8.62012 19.9443 8.79031 19.9443 9.00024C19.9443 9.21017 20.1145 9.38035 20.3244 9.38035ZM13.2083 17.5334C14.9566 17.5334 16.3889 16.1011 16.3889 14.3528C16.3889 12.6045 14.9566 11.1721 13.2083 11.1721C11.46 11.1721 10.0276 12.6045 10.0276 14.3528C10.0487 16.1011 11.46 17.5334 13.2083 17.5334ZM7.5 21.4302V24H18.8955V21.4302C18.8955 19.8715 17.6949 18.6708 16.1361 18.6708H10.2804C8.78489 18.6708 7.5 19.8925 7.5 21.4302ZM23.1925 15.3218C23.1925 16.6698 22.0972 17.8073 20.707 17.8073C19.3168 17.8073 18.2215 16.6698 18.2215 15.3218C18.2215 13.9737 19.3168 12.8362 20.707 12.8362C22.0551 12.8362 23.1925 13.9737 23.1925 15.3218ZM23.0029 18.9025H18.7059C19.4221 19.5134 19.8644 20.4402 19.8644 21.4513L19.9697 24H25.1936V21.0721C25.1936 19.8715 24.2246 18.9025 23.0029 18.9025Z'
				fill='white'
			/>
		</svg>
	);
};
