import { useQuery } from '@tanstack/react-query';

import { GetSegmentResultArtifactRequest } from 'api/battery';
import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';

export async function fetchSegmentResultArtifact(
	requestOptions: GetSegmentResultArtifactRequest
): Promise<string> {
	if (!isNonEmptyString(requestOptions?.segmentResultId)) {
		throw new Error('useGetSegmentResultArtifact: segmentResultId missing');
	}
	const service = await getBatteryService();
	const { data } = await service.getSegmentResultArtifact(requestOptions);
	return data;
}

export function useGetSegmentResultArtifact(
	requestOptions: GetSegmentResultArtifactRequest
) {
	const segmentResultId = requestOptions?.segmentResultId;
	const isValidSegmentResultId = isNonEmptyString(segmentResultId);
	return useQuery({
		enabled: isValidSegmentResultId,
		meta: {
			errorMessage: `Error fetching data for ${segmentResultId}`,
		},
		queryKey: [
			QueryKey.SegmentArtifact,
			requestOptions?.segmentResultId,
			requestOptions?.fileName,
		],
		queryFn: () => fetchSegmentResultArtifact(requestOptions),
		refetchOnMount: true,
	});
}
