import { motion, AnimatePresence } from 'framer-motion';
import { Route, useLocation, Routes } from 'react-router-dom';
import { ForgotPasswordForm } from './forgottenPassword/ForgotPasswordForm';
import { ResetPasswordForm } from './resetPassword/ResetPasswordForm';
import { CreateAccountForm } from './createAccountForm';
import { LoginForm } from './loginForm';

export const PublicRoutes = (): JSX.Element => {
	const location = useLocation();

	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route
					path='/auth/reset-password'
					element={<ResetPasswordForm />}
				/>
				<Route
					path='/auth/finish-signup'
					element={<CreateAccountForm />}
				/>
				<Route
					path='/auth/forgotPassword'
					element={
						<motion.div
							initial='initial'
							animate='in'
							exit='out'
							variants={forgotPasswordVariants}
							transition={pageTransition}>
							<ForgotPasswordForm />
						</motion.div>
					}
				/>
				<Route
					path='*'
					element={
						<motion.div
							initial='initial'
							animate='in'
							exit='out'
							variants={loginVariants}
							transition={pageTransition}>
							<LoginForm
								// this is for redirecting to original route after
								// successful login.
								pathname={
									location.pathname !== '/auth/login'
										? location.pathname
										: undefined
								}
							/>
						</motion.div>
					}
				/>
			</Routes>
		</AnimatePresence>
	);
};

const loginVariants = {
	initial: {
		opacity: 0,
		x: '-400px',
	},
	in: {
		opacity: 1,
		x: 0,
	},
	out: {
		opacity: 0,
		x: '-400px',
	},
};
const forgotPasswordVariants = {
	initial: {
		opacity: 0,
		x: '400px',
	},
	in: {
		opacity: 1,
		x: 0,
	},
	out: {
		opacity: 0,
		x: '400px',
	},
};

const pageTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.5,
};
