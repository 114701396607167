import { useCallback, useEffect, useReducer } from 'react';

import { getArtifactFormatFromExt } from '../../../utils/getArtifactFormatFromExt';
import { ArtifactFormats } from './types';
import { useGetReportArtifact } from './useGetReportArtifact';

export type BinaryArtifactData = { source: string; fileType: string };

type GetReportDataType = {
	error?: string | null;
	loading: boolean;
	metadata?: unknown;
	binaryArtifactData?: BinaryArtifactData[] | null;
};

type GetReportDataArgs = {
	segmentResultId?: string;
	fileName: string;
	fileType: ArtifactFormats;
};

const enum Actions {
	INIT = 'INIT',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

type State = {
	loading: boolean;
	metadata: unknown | null;
	binaryArtifactData: BinaryArtifactData[] | null;
	error: string | null;
};

type MetaDataType = unknown;

type Action =
	| { type: Actions.INIT }
	| {
			type: Actions.COMPLETED;
			payload: {
				binaryArtifactData: BinaryArtifactData[];
				metadata: MetaDataType;
			};
	  }
	| { type: Actions.FAILED; payload: string };

const initialState: State = {
	loading: false,
	metadata: null,
	binaryArtifactData: [],
	error: null,
};

function reducer(state: State, action: Action) {
	switch (action.type) {
		case Actions.INIT:
			return { ...state, loading: true, error: null };
		case Actions.COMPLETED:
			return {
				...state,
				loading: false,
				error: null,
				binaryArtifactData: action?.payload?.binaryArtifactData,
				metadata: action?.payload?.metadata,
			};
		case Actions.FAILED:
			return { ...state, loading: false, error: action?.payload };
		default:
			return initialState;
	}
}

const isJsonObject = (value: unknown) => {
	try {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		JSON.parse(value as any);
		return true;
	} catch (error) {
		return false;
	}
};

const useGetReportData = ({
	segmentResultId,
	fileName,
	fileType,
}: GetReportDataArgs): GetReportDataType => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { getReportArtifact: getJsonArtifact } = useGetReportArtifact();
	const { getReportArtifact: getBinaryArtifact } = useGetReportArtifact();

	const fetchBinaryArtifacts = useCallback(
		async (
			dataItems: { filePath?: string }[]
		): Promise<BinaryArtifactData[]> => {
			const binaryArtifactsList: BinaryArtifactData[] = [];
			for (const data of dataItems) {
				// Skip processing data that has no binary artifact file paths
				if (!data?.filePath) continue; // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/continue
				const fileName = data?.filePath;
				const fileType = getArtifactFormatFromExt(fileName);
				const binaryArtifact = await getBinaryArtifact({
					segmentResultId,
					fileName,
					fileType,
				});
				if (!binaryArtifact) {
					throw new Error(
						`No binary artifact found for file ${fileName}`
					);
				}
				binaryArtifactsList.push({
					source: binaryArtifact?.assetData,
					fileType,
				});
			}
			return binaryArtifactsList;
		},
		[getBinaryArtifact, segmentResultId]
	);

	const getReportArtifactsAsync = useCallback(async () => {
		dispatch({ type: Actions.INIT });
		try {
			const response = await getJsonArtifact({
				segmentResultId,
				fileName,
				fileType,
			});
			if (!response) {
				throw new Error(
					`No artifact data found for segment result with ID ${segmentResultId}`
				);
			}
			// There might be cases where the "artifact" on S3 is not a JSON, but a simple string.
			// In such cases, we'll return the fetched data as is, without trying to parse it.
			if (!isJsonObject(response?.assetData)) {
				dispatch({
					type: Actions.COMPLETED,
					payload: {
						metadata: response?.assetData,
						binaryArtifactData: [],
					},
				});
				return;
			}
			const jsonArtifact = JSON.parse(response?.assetData);
			const artifactData = jsonArtifact?.data;
			// A segment result could have no binary data associated with it
			if (!artifactData) {
				dispatch({
					type: Actions.COMPLETED,
					payload: {
						metadata: jsonArtifact,
						binaryArtifactData: [],
					},
				});
				return;
			}
			const binaryArtifacts = await fetchBinaryArtifacts(
				Array.isArray(artifactData) ? artifactData : [artifactData]
			);
			dispatch({
				type: Actions.COMPLETED,
				payload: {
					metadata: jsonArtifact,
					binaryArtifactData: binaryArtifacts,
				},
			});
		} catch (error: unknown) {
			console.warn(error);
			dispatch({
				type: Actions.FAILED,
				payload: (error as Error)?.message,
			});
		}
	}, [
		fileName,
		fileType,
		fetchBinaryArtifacts,
		getJsonArtifact,
		segmentResultId,
	]);

	useEffect(() => {
		if (!fileName || !fileType || !segmentResultId) return;
		getReportArtifactsAsync();
	}, [
		fileName,
		fileType,
		getBinaryArtifact,
		getJsonArtifact,
		getReportArtifactsAsync,
		segmentResultId,
	]);

	return state;
};

export { useGetReportData };
