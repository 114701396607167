// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const IconArrowLeft = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 20 20'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='IconArrowLeft-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'IconArrowLeft'}</title>
			<path
				d='M17.0283 5.49465L19 3.52376L16.4781 1L14.5068 2.97089M17.0283 5.49465L5.52749 16.9929M17.0283 5.49465L14.5068 2.97089M14.5068 2.97089L3.00562 14.4692M5.52749 16.9929L1 19L3.00562 14.4692M5.52749 16.9929L3.00562 14.4692'
				stroke='current'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};
