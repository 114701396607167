import { Chip, theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	ExpandedMeasureCell,
	ExpandedMeasureCellContainer,
	ExpandedMeasureCellContent,
	ExpandedMeasureCellLabel,
	MeasureCell,
	MeasuresDividerText,
	MeasuresSeparator,
	MeasuresStyledDivider,
	MeasuresContainer,
	NormalMeasuresContent,
} from '../styles';

export function NormalMeasures() {
	const { t } = useTranslation();

	return (
		<MeasuresContainer $backgroundColor={Theme.colors.gray_90}>
			<NormalMeasuresContent data-testid='normal-measures-content'>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
			</NormalMeasuresContent>
			<MeasuresSeparator>
				<MeasuresStyledDivider />
				<MeasuresDividerText>OR</MeasuresDividerText>
				<MeasuresStyledDivider />
			</MeasuresSeparator>
			<ExpandedMeasureCellContainer>
				<ExpandedMeasureCellContent data-testid='normal-measures-expanded'>
					<ExpandedMeasureCell>
						<ExpandedMeasureCellLabel weight='600'>
							1
						</ExpandedMeasureCellLabel>
					</ExpandedMeasureCell>
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</ExpandedMeasureCellContent>
			</ExpandedMeasureCellContainer>
		</MeasuresContainer>
	);
}
