import { Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Typography, theme } from '@lh/eng-web-mosaic-common';
import { LineDivider } from 'components/shared/LineDivider';

type Props = { totalScore: string };

const PHQ8Footer = ({ totalScore }: Props) => {
	const { t } = useTranslation();
	return (
		<CenteredContainer>
			<Row>
				<Typography.P1 color={theme.color.bodyText} weight='500'>
					{t('web.report.phq8.footer.totalScore')}
				</Typography.P1>
				<Space w={'md'} />
				<Typography.P1 color={theme.color.bodyText} weight='600'>
					{totalScore}
				</Typography.P1>
			</Row>
			<Space h={'md'} />
			<LineDivider />
			<Space h={'md'} />
			<Typography.P1 color={theme.color.bodyTextSecondary}>
				{t('web.report.phq8.footer.endOfQuestionnaire')}
			</Typography.P1>
		</CenteredContainer>
	);
};

export { PHQ8Footer };

const CenteredContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-content: center;
	align-items: center;
`;

const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
