import { useState, useCallback } from 'react';
import { DeepBatteryResult } from '@lh/eng-platform-battery-service-rest-client';
import { useRestServices } from '../../components/shared/hooks/useRestServices';
import { ERROR } from '../../logging/linusLogger';

export function useLazyResolveRelatedResults(
	batteryResult?: {
		id: string;
		relatedResults?: { batteryResultId: string }[];
	} | null
) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<unknown | null>(null);
	const [apiResponse, setApiResponse] = useState<DeepBatteryResult[] | null>(
		null
	);
	const { createBatteryService } = useRestServices();
	const fetchRelatedResults = useCallback(async () => {
		if (batteryResult?.relatedResults?.length) {
			setLoading(true);

			const batteryClient = await createBatteryService();
			try {
				const results = await Promise.all(
					batteryResult.relatedResults.map(({ batteryResultId }) => {
						return batteryClient.getDeepBatteryResult({
							batteryResultId,
							withMetrics: true,
						});
					})
				);
				setApiResponse(results.map(({ data }) => data));
				setLoading(false);
			} catch (e) {
				ERROR(
					'Failed to collect related results for battery result: ' +
						batteryResult.id
				);
				setLoading(false);
				setError(e);
			}
		}
	}, [batteryResult, createBatteryService]);

	return [
		fetchRelatedResults,
		{ data: apiResponse, loading, error },
	] as const;
}
