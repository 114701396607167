import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { truncateString } from '../../../utils/stringUtils';
import { Avatar } from './Avatar';

interface AvatarNameProps {
	firstName: string;
	lastName: string;
	avatarUrl?: string;
	linkTo?: string;
}

const AvatarName = ({
	firstName,
	lastName,
	avatarUrl,
	linkTo,
}: AvatarNameProps): JSX.Element => {
	const name = truncateString(`${lastName}, ${firstName}`, 20);

	return (
		<StyledWrapper>
			<Avatar
				imageSrc={avatarUrl}
				firstName={firstName}
				lastName={lastName}
			/>
			{linkTo ? (
				<StyledLink to={linkTo}>{name}</StyledLink>
			) : (
				<StyledSpan>{name}</StyledSpan>
			)}
		</StyledWrapper>
	);
};

export { AvatarName };

const StyledWrapper = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const StyledSpan = styled.span`
	padding: 0 0 0 8px;
`;

const StyledLink = styled(Link)`
	padding: 0 0 0 8px;
`;
