// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const ArrowUp = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 14 8'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='ArrowUp-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'ArrowUp'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.3536 7.35355C13.1583 7.54882 12.8417 7.54882 12.6464 7.35355L7 1.70711L1.35355 7.35355C1.15829 7.54881 0.841708 7.54881 0.646445 7.35355C0.451183 7.15829 0.451183 6.84171 0.646445 6.64645L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447L13.3536 6.64645C13.5488 6.84171 13.5488 7.15829 13.3536 7.35355Z'
				fill='current'
			/>
		</svg>
	);
};
