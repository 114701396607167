import { useContext, useEffect, useMemo, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { fetchBatteryResultsByOrgId } from 'api/battery';
import { useGetPaginatedParticipants } from 'api/participant';
import { CacheTimes, QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';
import { getPaginatedParticipantsFilterOptions } from '../../components/participant/Participants/Participants.helpers';
import { GlobalDataContext } from '../../providers/globalDataProvider/GlobalDataProvider';
import { BatteryResultsTableData } from '../../schemas/table/batteryResultsSchema';
import {
	getBatteryResultsTableDataFilterOptions,
	mapBatteryResultToTableData,
} from './BatteryResults.helpers';
import { GetBatteryResultsTableDataInput } from './BatteryResults.types';

type GetBatteryResultsTableDataType = {
	hasExceededMaximumQueryComplexity: boolean;
	data: BatteryResultsTableData[];
	totalCount: number;
	error: Error | null;
	loading: boolean;
};

// Reference - https://developers.cloudflare.com/workers/platform/limits/#request-limits
const QUERY_FILTER_VALUES_UPPER_LIMIT = 30;

const useGetBatteryResultsTableData = (
	input: GetBatteryResultsTableDataInput
): GetBatteryResultsTableDataType => {
	const [
		hasExceededMaximumQueryComplexity,
		setHasExceededMaximumQueryComplexity,
	] = useState<boolean>(false);
	const [error, setError] = useState<Error | null>(null);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [activeTableData, setActiveTableData] = useState<
		BatteryResultsTableData[]
	>([]);
	const { t } = useTranslation();

	const { orgBatteries, orgUsers } = useContext(GlobalDataContext);

	const filterOptions = useMemo(
		() => getPaginatedParticipantsFilterOptions(input?.searchValue),
		[input.searchValue]
	);

	const getParticipantsQueryResponse = useGetPaginatedParticipants({
		organizationId: input?.organizationId,
		...filterOptions,
	});

	const isFetchingParticipants = useMemo(
		() => getParticipantsQueryResponse?.isFetching,
		[getParticipantsQueryResponse?.isFetching]
	);

	const participantIdsToFilter: string[] = useMemo(() => {
		const emptyArray: string[] = [];
		if (isNonEmptyString(input?.searchValue)) {
			return (
				getParticipantsQueryResponse?.data?.results?.map(
					(participant) => participant?.id
				) ?? emptyArray
			);
		}
		// If there are no search filters, we don't need to filter by matching ALL participant IDs; instead we'll just skip having "participantID" as a filter option
		return emptyArray;
	}, [getParticipantsQueryResponse?.data?.results, input?.searchValue]);

	useEffect(() => {
		setHasExceededMaximumQueryComplexity(
			isFetchingParticipants
				? true
				: participantIdsToFilter?.length >
						QUERY_FILTER_VALUES_UPPER_LIMIT
		);
	}, [isFetchingParticipants, participantIdsToFilter]);

	const getDependentBatteryResultQuery = useMemo(() => {
		const canFetchBatteryResults =
			!isFetchingParticipants && !hasExceededMaximumQueryComplexity;

		if (!canFetchBatteryResults) {
			return [];
		}
		return [
			{
				enabled: canFetchBatteryResults,
				queryKey: [
					QueryKey.BatteryResultsByOrg,
					input?.page,
					input?.pageSize,
					input?.organizationId,
					input?.searchValue,
					input?.sort?.sortField,
					input?.sort?.sortOrder,
					...participantIdsToFilter,
				],
				staleTime: CacheTimes.FiveMinutes,
				queryFn: () =>
					fetchBatteryResultsByOrgId({
						organizationId: input?.organizationId,
						page: input?.page,
						pageSize: input?.pageSize,
						...getBatteryResultsTableDataFilterOptions(
							participantIdsToFilter
						),
						...input?.sort,
					}),
			},
		];
	}, [
		hasExceededMaximumQueryComplexity,
		input?.organizationId,
		input?.page,
		input?.pageSize,
		input?.searchValue,
		input?.sort,
		isFetchingParticipants,
		participantIdsToFilter,
	]);

	const {
		data: combinedBatteryResultsResponse,
		error: batteryResultApiError,
		isFetching: isFetchingBatteryResults,
	} = useQueries({
		queries: getDependentBatteryResultQuery,
		combine: (results) => {
			return {
				data: results.map((result) => result.data),
				isFetching: results.some((result) => result.isFetching),
				error: results.map((result) => result.error),
			};
		},
	});

	const isLoadingData = useMemo(
		() => isFetchingParticipants || isFetchingBatteryResults,
		[isFetchingBatteryResults, isFetchingParticipants]
	);

	useEffect(() => {
		const processBatteryResults = () => {
			try {
				const batteryResultsApiResponse =
					combinedBatteryResultsResponse?.at(0) ?? undefined;
				const totalCount = batteryResultsApiResponse?.totalCount ?? 0;
				setTotalCount(totalCount);
				const results = batteryResultsApiResponse;
				const mappedData = mapBatteryResultToTableData({
					orgBatteryResults: results,
					orgParticipants: getParticipantsQueryResponse?.data,
					orgBatteries: orgBatteries,
					orgUsers: orgUsers,
					t,
				});
				const dataWithNonEmptyExternalIds = mappedData?.filter(
					(data) => !!data?.externalId
				);
				setActiveTableData(dataWithNonEmptyExternalIds);
			} catch (error) {
				setError(error as Error);
			}
		};
		processBatteryResults();
	}, [
		getParticipantsQueryResponse?.data,
		input.page,
		input.pageSize,
		input.searchValue,
		input?.sort,
		orgBatteries,
		combinedBatteryResultsResponse,
		orgUsers,
		t,
	]);

	useEffect(() => {
		const handleApiErrors = () => {
			if (batteryResultApiError?.[0]) {
				setError(batteryResultApiError?.[0]);
			} else if (getParticipantsQueryResponse?.error) {
				setError(getParticipantsQueryResponse?.error);
			} else {
				setError(null);
			}
		};
		handleApiErrors();
	}, [getParticipantsQueryResponse?.error, batteryResultApiError]);

	return {
		hasExceededMaximumQueryComplexity,
		loading: isLoadingData,
		error: error,
		data: activeTableData,
		totalCount: totalCount,
	};
};

export { useGetBatteryResultsTableData };
