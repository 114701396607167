import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../../../designSystem/Text';

type SectionHeadingProps = {
	text: string;
};

const SectionHeading = (props: SectionHeadingProps) => {
	const sectionHeadingKebabCase = `${props?.text
		?.toLowerCase()
		?.split(' ')
		?.join('-')}`;

	return (
		<StyledHeadingText
			data-testid={`section-heading-${sectionHeadingKebabCase}`}>
			{props?.text}
		</StyledHeadingText>
	);
};

export { SectionHeading };

const StyledHeadingText = styled(H4)(
	({ theme: { weight } }) => `
	font-weight: ${weight.regular};
`
);
