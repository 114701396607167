import React, { useContext, useCallback } from 'react';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import { hasOperation } from '../../../hasOperation';
import { UserContext } from '../../../context/UserContext';

// using "any" here as per the type of useCallback
export const useAuthorization = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	callback: (...args: any[]) => any,
	operations: OperationToken[],
	dependencies: React.DependencyList
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	const { currentUser } = useContext(UserContext);
	if (!hasOperation(currentUser, operations)) {
		// TODO create more comprehensive error system
		throw new Error(
			`This User does not have permission to perform this operation: ${operations.join(
				', '
			)}`
		);
	}
	return useCallback(callback, [callback, ...dependencies]);
};
