import axios, { AxiosResponse, ResponseType } from 'axios';
import { Auth } from '@lh/eng-shared-auth';
import { getConfig } from '../config';

export type fetchProps = {
	url: string;
	responseType?: ResponseType;
	signal?: AbortSignal;
	auth?: Auth;
};

export const fetch = async <T>({
	url,
	responseType = 'json',
	signal,
	auth,
}: fetchProps): Promise<AxiosResponse<T>> => {
	const headers: {
		[key: string]: string;
	} = {};
	if (auth) {
		const jwt = await auth.currentIdToken();
		headers['authorization'] = jwt?.token ? `Bearer ${jwt.token}` : '';
	}
	return (await axios.get(url, {
		responseType,
		signal,
		headers,
	})) as AxiosResponse<T>;
};

export const prefix = (): string => {
	const { apiProtocol, apiHost, apiPort } = getConfig();
	return `${apiProtocol}://${apiHost}:${apiPort}/`;
};

export type FetchUtilProps = {
	url: string;
	signal?: AbortSignal;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onError?: (error: any) => void;
	responseType?: ResponseType;
	auth: Auth;
};

export const fetchUtil = async ({
	url,
	signal,
	onError,
	responseType = 'json',
	auth,
}: FetchUtilProps) => {
	try {
		const resp = await fetch({
			url,
			signal,
			responseType,
			auth,
		});
		return resp;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const error = e.message;
		if (error.name !== 'AbortError') {
			//TODO: Sentry handling should go here
			onError && onError(e);
		}
	}
};
