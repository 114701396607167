import { t } from 'i18n';

import { RoleKeys } from '../../../constants/roles';
import { CurrentUser } from '../../../types';

export const loginLandingPage = (currentUser: CurrentUser): string => {
	const administratorRoleNames = [
		t(RoleKeys.RESEARCH_ADMINISTRATOR),
		t(RoleKeys.RESEARCH_SPONSOR),
		t(RoleKeys.LINUS_ADMIN),
		t(RoleKeys.LINUS_LTD_ADMIN),
	].map((role) => role.toUpperCase());

	if (administratorRoleNames.includes(t(currentUser.role).toUpperCase())) {
		return '/organization';
	} else {
		return '/participants';
	}
};
