// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Pause = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Pause-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Pause'}</title>
			<rect width='32' height='32' rx='16' fill='#B1E5FB' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.1283 10H13.6327C14.2559 10 14.7611 10.5341 14.7611 11.193V20.807C14.7611 21.4659 14.2559 22 13.6327 22H12.1283C11.5052 22 11 21.4659 11 20.807V11.193C11 10.5341 11.5052 10 12.1283 10ZM18.3673 10H19.8717C20.4948 10 21 10.5341 21 11.193V20.807C21 21.4659 20.4948 22 19.8717 22H18.3673C17.7441 22 17.2389 21.4659 17.2389 20.807V11.193C17.2389 10.5341 17.7441 10 18.3673 10Z'
				fill='#087DAE'
			/>
		</svg>
	);
};
