import React from 'react';
import styled from 'styled-components';
import { ExternalLink as ExternalLinkIcon } from '../../shared/designSystem/Icons';

type ExternalLinkProps = {
	text: string | number;
	href: string;
	target?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/**
 * This component will be used to render external links which will
 * leads you to some website.
 */
const ExternalLinkWithIcon = ({
	text,
	href,
	onClick,
	target = '_blank',
}: ExternalLinkProps): JSX.Element => {
	return (
		<StyledLink
			onClick={onClick}
			href={href}
			target={target}
			hasUnderline={false}>
			{text}
			<ExternalLinkIcon width={20} height={20} />
		</StyledLink>
	);
};

export { ExternalLinkWithIcon };

type StyledLinkPropsProps = {
	hasUnderline: boolean;
};

const StyledLink = styled.a<StyledLinkPropsProps>(
	({ hasUnderline }) => `
	text-decoration: ${hasUnderline && 'underline'};
`
);
