import { DeepBatteryResult } from 'api/battery';
import { useGetResultFlagsConfig } from '../helpers/useGetResultFlagsConfig';
import { getMostRecentResult } from '../helpers/indicationWidgetHelpers';

type Props = {
	batteryResults: DeepBatteryResult[];
};

export const IndicationWidgetRenderer = (props: Props) => {
	const resultFlagsConfig = useGetResultFlagsConfig();
	return (
		<div data-testid='indication-widgets-container'>
			{/* Check for indications in the most recent battery results for a given battery (by display key) */}
			{resultFlagsConfig?.map(({ batteryDisplayKey, indications }) => {
				const mostRecentBatteryResult = getMostRecentResult(
					props?.batteryResults,
					batteryDisplayKey
				);
				return indications?.map?.(
					({ isIndicative, renderIndicationWidget }) =>
						isIndicative(mostRecentBatteryResult) ? (
							<div key={mostRecentBatteryResult?.endTime}>
								{renderIndicationWidget(
									mostRecentBatteryResult
								)}
							</div>
						) : null
				);
			})}
		</div>
	);
};
