import { useTranslation } from 'react-i18next';
import { GenericReportCard } from '../../../../../common/GenericReportCard/GenericReportCard';

import { ConfidenceScoreData } from '../../LongitudinalEpsom.helpers';
import { ConfidenceScoreBreakdown } from '../ConfidenceScoreBreakdown/ConfidenceScoreBreakdown';
import { ConfidenceScoreWithInterpretation } from '../ConfidenceScoreWithInterpretation/ConfidenceScoreWithInterpretation';

type Props = {
	totalScore?: number;
	breakdown?: ConfidenceScoreData['breakdown'];
};

export const ConfidenceScoreCard = (props: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<GenericReportCard
				header={{
					titleText: t('web.report.longEpsom.tabs.confidenceScore'),
				}}
				content={[
					{
						weight: 1 / 3,
						component: () => (
							<ConfidenceScoreWithInterpretation
								totalScore={props?.totalScore}
							/>
						),
					},
					{
						weight: 2 / 3,
						component: () => (
							<ConfidenceScoreBreakdown
								breakdown={props?.breakdown}
							/>
						),
					},
				]}
			/>
		</>
	);
};
