import {
	IndividualScore,
	ScoreBgColorPair,
	ScoreType,
} from '../common/scoring/types';
import { FormattedSegmentResult } from '../common';

export const enum DcrMetricItemKeys {
	DCR_SCORE = 'dcr_score',
	DCR_INTERPRETATION = 'dcr_interpretation',
	CORRECT_WORDS_COUNT = 'correct_words_count',
	EXPECTED_WORDS_COUNT = 'expected_words_count',
	AMYLOID_INTERPRETATION = 'amyloid_interpretation',
}

export type DcrScoreData = {
	isUnanalyzable: boolean;
	totalScore: ScoreType;
	scoreTagBackgroundColors?: ScoreBgColorPair;
	scoreData: IndividualScore[] | [];
	scoreInterpretation: string;
};

export type DualColor = {
	background: string;
	text: string;
};

export type DcrSubscoreData = {
	recall: {
		immediate: {
			segmentName: string;
			score: ScoreType;
			scoreColor: DualColor;
		};
		delayed: {
			segmentName: string;
			score: ScoreType;
			scoreColor: DualColor;
		};
	};
	clock: FormattedSegmentResult | undefined;
};

// Source - https://github.com/linushealth/an-data-schemas/blob/main/an_data_schemas/amyloid_prediction/amyloid_prediction_engine_output_data_contract_schema.json#L11
export const enum AmyloidInterpretation {
	LOW_LIKELIHOOD = 'likely.negative',
	MODERATE_LIKELIHOOD = 'indeterminate',
	HIGH_LIKELIHOOD = 'likely.positive',
	UNANALYZABLE = '--',
}
