import styled from 'styled-components';
import { LinusModal } from '../../../../../../../shared/LinusModal';
import { ButtonLg, P1 } from '../../../../../../../shared/designSystem';
import { useTranslation } from 'react-i18next';
import { Flex } from '@mantine/core';

type ConfidenceScoreModalProps = {
	onClose: () => void;
};

function ConfidenceScoreModal(props: Readonly<ConfidenceScoreModalProps>) {
	const { t } = useTranslation();

	const scoringDescriptionText = t(
		'research.epsomConfidenceScore.scoringDetails.modal.descriptionText'
	);
	const closeButtonText = t('web.team.sharedModal.closeButton');

	return (
		<LinusModal
			hideCloseButton
			title={'Scoring Details'}
			onClose={props?.onClose}>
			<br />
			<StyledDescriptionText data-testid='scoring-details-modal-description-text'>
				{scoringDescriptionText}
			</StyledDescriptionText>
			<br />
			<Flex justify={'center'}>
				<ButtonLg
					dataTestId='scoring-details-modal-close-button'
					onClick={props?.onClose}
					text={closeButtonText}
					width='158px'
				/>
			</Flex>
		</LinusModal>
	);
}

const StyledDescriptionText = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.regular};		
    white-space: pre;    
`
);

export { ConfidenceScoreModal };
