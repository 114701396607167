// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const LearnSomethingNewLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='LearnSomethingNewLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'LearnSomethingNewLHQ'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.3239 6V18.3239V18.3449H19.1693L20.0526 20.5741H20.9359C21.2303 20.5741 21.4826 20.8265 21.4826 21.1209C21.4826 21.4154 21.2513 21.6677 20.9359 21.6677H20.4942L21.9033 25.2429C22.0294 25.5373 21.8822 25.8528 21.6088 25.9579C21.5913 25.9623 21.5746 25.9667 21.5587 25.9709C21.4984 25.9868 21.4485 26 21.3985 26C21.1882 26 20.9779 25.8528 20.8938 25.6425L19.3165 21.6677H13.1546L11.5563 25.6004C11.4721 25.8318 11.2618 25.9579 11.0515 25.9579C10.9674 25.9579 10.9043 25.9369 10.8412 25.9159C10.5468 25.8107 10.3996 25.4742 10.5258 25.2008L11.9558 21.6467H11.5352C11.2408 21.6467 10.9884 21.3943 10.9884 21.0999C10.9884 20.8055 11.2198 20.5531 11.5352 20.5531H12.4185L13.3018 18.3239H10V6H22.3239ZM21.3144 7.03049H11.0305V17.3144H21.3144V7.03049ZM15.6782 18.3239H14.4795L13.5962 20.5531H15.6782V18.3239ZM16.7928 20.5531H18.8749L17.9916 18.3239H16.7928V20.5531ZM12.9228 13.4654L11.5768 14.7693V7.57682H20.7251V13.192L19.6105 11.9301C19.1268 11.4044 18.3066 11.4044 17.8439 11.9301L15.6778 14.4959L14.6052 13.4654C14.1425 13.0027 13.3854 13.0027 12.9228 13.4654ZM15.1941 9.95327C15.1941 9.25926 14.6262 8.71247 13.9533 8.71247C13.2593 8.71247 12.7125 9.25926 12.7125 9.95327C12.7125 10.6473 13.2593 11.1941 13.9533 11.1941C14.6473 11.1941 15.1941 10.6262 15.1941 9.95327Z'
				fill={color}
			/>
		</svg>
	);
};
