import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { icons } from '../../../enums/icons';
import { Footer } from '../../shared/Footer';
import { Icon } from '../../shared/designSystem';
import { PublicRoutes } from '../PublicRoutes';
import { FeatureFlag, useFeatureFlag } from '../../../features/feature-flags';
import { LoadingDots } from '../../shared/LoadingDots';

interface Props {
	finishingSignup?: boolean;
}

const AuthenticationLayout = ({
	finishingSignup = false,
}: Props): JSX.Element => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const isFinishSignup =
		pathname === '/auth/finish-signup' || finishingSignup;
	const isAuth0Universal = useFeatureFlag(FeatureFlag.Auth0Universal);

	return isAuth0Universal && !isFinishSignup ? (
		<StyledLayoutContainer>
			<StyledLoginContainer data-id='login-container'>
				<LoadingDots />
			</StyledLoginContainer>
		</StyledLayoutContainer>
	) : (
		<StyledLayoutContainer>
			<StyledLoginContainer data-id='login-container'>
				<StyledLoginWrapper isFinishSignup={isFinishSignup}>
					<StyledBackgroundImage
						src={'/img/research-login.png'}
						alt={t`web.shared.images.ptWithProvider`}
						draggable='false'
					/>
					<StyledFormContainer isFinishSignup={isFinishSignup}>
						<PublicRoutes />
						<StyledLogoWrapper isFinishSignup={isFinishSignup}>
							<StyledLogo>
								<Icon
									icon={icons.Logo}
									title={t`web.shared.logo`}
								/>
							</StyledLogo>
						</StyledLogoWrapper>
					</StyledFormContainer>
				</StyledLoginWrapper>
			</StyledLoginContainer>
			<Footer />
		</StyledLayoutContainer>
	);
};

export { AuthenticationLayout };

const StyledLayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledLoginContainer = styled.div`
	padding: 16px;
	text-align: center;
	width: 100%;
	height: calc(100vh - 64px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

type FinishSignupProps = {
	isFinishSignup: boolean;
};
const StyledLoginWrapper = styled.div<FinishSignupProps>(
	({ isFinishSignup }) => `
	width: ${isFinishSignup ? '1517px' : '1103px'};
	height: 665px;
	display: flex;
	position: relative;
	`
);

const StyledBackgroundImage = styled.img`
	width: 666px;
	height: 666px;
	border-radius: 40px 0 40px 40px;
`;

const StyledFormContainer = styled.div<FinishSignupProps>(
	({ isFinishSignup, theme: { color, boxShadow } }) => `
	align-items: flex-start;
	justify-content: flex-start;
	width: ${isFinishSignup ? '992px' : '600px'};
	height: ${isFinishSignup ? '760px' : '608px'};
	background: ${color.white};
	transition: 0.2s ease all;
	border-radius: 40px 0 40px 40px;
	position: absolute;
	right: 0;
	top: ${isFinishSignup ? '-44px' : '29px'};
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: ${boxShadow.standard};

	`
);

const StyledLogoWrapper = styled.div<FinishSignupProps>(
	({ isFinishSignup }) => `
	position: absolute;
	bottom: 0;
	left: ${
		isFinishSignup
			? 'calc((992px - 168px) / 2)'
			: 'calc((578px - 168px) / 2)'
	};
	`
);

const StyledLogo = styled.div`
	position: absolute;
	bottom: 56px;
`;
