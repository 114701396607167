import { sendEventData } from '../../analytics/amplitude';
import { t } from '../../i18n';
import { AnalyticsAction } from '../../generated/graphql';
import { ExternalLink } from './ExternalLink';

type PrivacyPolicyLinkProps = {
	privacyPolicyUrl?: string;
	text?: string;
	underline?: boolean;
};

const PrivacyPolicyLink = ({
	privacyPolicyUrl = 'https://linushealth.com/privacy-policy',
	text = t`web.shared.privacyPolicyLink` as string,
	underline = true,
}: PrivacyPolicyLinkProps): JSX.Element => {
	return (
		<ExternalLink
			text={text}
			href={privacyPolicyUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedPrivacyPolicy,
				})
			}
			underline={underline}
		/>
	);
};

export { PrivacyPolicyLink };
