import styled from 'styled-components';

import { Divider } from '@mantine/core';
import { theme } from '../../../../providers/styleProvider';
import { Card as DSCard } from '../../../designSystem/Card';
import { GenericReportCardHeader } from './components/GenericReportCardHeader/GenericReportCardHeader';

type Props = {
	header: {
		titleText: string;
		tooltipText?: string;
	};
	content: { component: () => React.ReactNode; weight?: number }[];
};

export const GenericReportCard = (props: Props) => {
	return (
		<Card>
			<GenericReportCardHeader
				headerTitle={props?.header?.titleText}
				tooltipText={props?.header?.tooltipText}
			/>
			<Divider my='md' />
			<div style={{ flexDirection: 'row', display: 'flex' }}>
				{props?.content?.map(
					({ component: Component, weight }, index) => {
						const isLastIndex =
							index !== props?.content?.length - 1;
						return (
							<div
								key={`generic-report-card-component-${
									index + 1
								}`}
								style={{
									flex: weight ?? undefined,
									marginRight: !isLastIndex
										? 0
										: theme?.spacing?.lg,
								}}>
								{Component?.()}
							</div>
						);
					}
				)}
			</div>
		</Card>
	);
};
const Card = styled(DSCard)`
	padding: ${(props) => props.theme.spacing.xl};
	display: flex;
	flex-direction: column;
`;
