import { create } from 'zustand';
import { Auth } from '@lh/eng-shared-auth';

interface State {
	auth: Auth | null;
	setAuth: (auth: Auth) => void;
	bearerToken: string | null;
	setBearerToken: (bearerToken: string) => void;
	getBearerToken: () => Promise<string | undefined>;
	reset: () => void;
}

export const useAuthStore = create<State>((set, get) => ({
	auth: null,
	setAuth: (auth) => set({ auth }),
	bearerToken: null,
	setBearerToken: (bearerToken) => set({ bearerToken }),
	getBearerToken: async () => {
		const authObj = get().auth;
		if (authObj) {
			return await authObj
				.currentIdToken()
				.then((token) => token.token)
				.catch((_) => undefined);
		}
		return undefined;
	},
	reset: () => set({ auth: null, bearerToken: null }),
}));
