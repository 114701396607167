import { GetBatteryResultsByOrganizationRequest } from '.';

export {
	AssessmentType,
	AudienceType,
	BatteryResultState,
	BatteryResultStatus,
	DefaultApi as BatteryService,
	Configuration as BatteryServiceConfiguration,
	BatteryType,
	GetBatteryResultsByOrganizationFilterField,
	GetBatteryResultsByOrganizationFilterOperator,
	GetBatteryResultsByOrganizationLogicalOperator,
	GetBatteryResultsByOrganizationSortField,
	GetBatteryResultsByOrganizationSortOrder,
	GetBatteryResultsByParticipantFilterField,
	GetBatteryResultsByParticipantFilterOperator,
	GetBatteryResultsByParticipantSortField,
	GetBatteryResultsByParticipantSortOrder,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

export type {
	Battery,
	BatteryResult,
	DeepAssessment,
	DeepAssessmentResult,
	DeepBattery,
	DeepSegmentResult,
	DefaultApiGetBatteryResultsByOrganizationRequest as GetBatteryResultsByOrganizationRequest,
	DefaultApiGetSegmentResultArtifactRequest as GetSegmentResultArtifactRequest,
	MetricItem,
	PaginatedBatteryResults,
	DeepBatteryResult,
} from '@lh/eng-platform-battery-service-rest-client';

export type GetBatteryResultsByOrganizationFilterOptions = {
	filterField: GetBatteryResultsByOrganizationRequest['filterField'];
	filterOperator: GetBatteryResultsByOrganizationRequest['filterOperator'];
	filterValue: GetBatteryResultsByOrganizationRequest['filterValue'];
	logicalOperator: GetBatteryResultsByOrganizationRequest['logicalOperator'];
};

export type GetBatteryResultsByOrganizationSortOptions = {
	sortField: GetBatteryResultsByOrganizationRequest['sortField'];
	sortOrder: GetBatteryResultsByOrganizationRequest['sortOrder'];
};
