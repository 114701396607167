import styled from 'styled-components';

import { EnumItem } from 'enums/enumeration';

import { ActionPlanPillarPointer } from '../ActionPlanPillarTitlePointer';
import { ProcessedLifestyleFactors } from '../ActionPlan/actionPlanLogic';

type ActionPlanPillarPieColumnProps = {
	pillars: EnumItem[];
	longLineWidth: string;
	shortLineWidth: string;
	reverse: boolean;
	focusAreas?: ProcessedLifestyleFactors;
	fontSize?: string;
	subtitleFontSize?: string;
};

export const ActionPlanPillarPieColumn = ({
	pillars,
	longLineWidth,
	shortLineWidth,
	reverse,
	focusAreas,
	fontSize,
	subtitleFontSize,
}: ActionPlanPillarPieColumnProps): JSX.Element => {
	return (
		<StyledColumn>
			{pillars.map((p, i) => {
				const focusArea = focusAreas
					? focusAreas[p.value].riskIdentified
					: false;

				return (
					<StyledItem key={p.key}>
						<ActionPlanPillarPointer
							title={p.display as string}
							subtitle={p.secondLine as string}
							width={
								i === 0 || i === 3
									? longLineWidth
									: shortLineWidth
							}
							focusArea={focusArea}
							reverse={reverse}
							fontSize={fontSize}
							subtitleFontSize={subtitleFontSize}
						/>
					</StyledItem>
				);
			})}
		</StyledColumn>
	);
};

const StyledColumn = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const StyledItem = styled.div`
	height: 22%;
	display: flex;
	align-items: center;
`;
