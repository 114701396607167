import { Howl } from 'howler';

const PROGRESS_UPDATE_INTERVAL_MILLIS = 300;

export default class AudioPlayer {
	private audioPlayer: Howl;
	private isPlaying: boolean;
	private duration: number;
	private elapsedTime: number;
	private progressTrackingInterval:
		| string
		| number
		| NodeJS.Timeout
		| undefined;

	constructor(srcUrl: string, fileFormat: string) {
		this.audioPlayer = new Howl({
			src: srcUrl,
			format: fileFormat?.toLowerCase(),
		});
		this.audioPlayer.load();
		this.isPlaying = false;
		this.duration = this.audioPlayer?.duration();
		this.elapsedTime = 0;
		this.attachListeners();
		this.trackAudioProgress();
	}

	private attachListeners = () => {
		this.audioPlayer.on('load', this.onAudioLoadedSuccessfully);
		this.audioPlayer.on('loaderror', (_, error) => {
			console.error('Could not load audio', error);
		});
		this.audioPlayer.on('play', () => this.setIsPlaying(true));
		this.audioPlayer.on('pause', () => this.setIsPlaying(false));
		this.audioPlayer.on('stop', () => this.setIsPlaying(false));
		this.audioPlayer.on('end', () => this.setIsPlaying(false));
	};

	private setIsPlaying = (isPlaying: boolean) => {
		this.isPlaying = isPlaying;
	};

	private onAudioLoadedSuccessfully = () => {
		this.duration = Math.floor(this.audioPlayer?.duration() ?? 0);
	};

	togglePlayPause = () => {
		if (this.isPlaying) {
			this.setIsPlaying(false);
			this.audioPlayer.pause();
		} else {
			this.setIsPlaying(true);
			this.audioPlayer.play();
		}
	};

	private trackAudioProgress = () => {
		this.progressTrackingInterval = setInterval(() => {
			const currentPosition = this.audioPlayer?.seek();
			if (isNaN(currentPosition)) {
				this.elapsedTime = 0;
			} else {
				this.elapsedTime = Math.floor(currentPosition);
			}
		}, PROGRESS_UPDATE_INTERVAL_MILLIS);
	};

	unload = () => {
		clearInterval(this.progressTrackingInterval);
		this.audioPlayer.unload();
	};

	seekBackward = (timeInSeconds: number) => {
		const seekLimitBackward = Math.max(this.elapsedTime - timeInSeconds, 0);
		this.audioPlayer?.seek(seekLimitBackward);
	};

	seekForward = (timeInSeconds: number) => {
		const seekLimitForward = Math.min(
			this.elapsedTime + timeInSeconds,
			this.duration
		);
		this.audioPlayer?.seek(seekLimitForward);
	};

	seekToPosition = (position: number) => {
		this.audioPlayer?.seek(position);
	};

	getElapsedTimeInSeconds = (): number => {
		return this.elapsedTime ?? 0;
	};

	getDurationInSeconds = (): number => {
		return this.duration ?? 0;
	};

	getIsPlaying = (): boolean => {
		return this.isPlaying;
	};
}
