import { Divider } from '@mantine/core';

import { BreakdownInfo } from './BreakdownInfo';

import { ScoresProps } from './Breakdown.types';

interface BreakdownRDACProps {
	scores: ScoresProps[];
}

export function BreakdownRDACExpanded({
	scores,
}: Readonly<BreakdownRDACProps>) {
	return (
		<div>
			{scores.map((item, index) => {
				return (
					<div>
						<BreakdownInfo
							isImpaired={item.isImpaired}
							percentile={item.percentile}
							score={item.value}
							title={item.title}
						/>
						{index !== scores.length - 1 && <Divider />}
					</div>
				);
			})}
		</div>
	);
}
