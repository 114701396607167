// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const StayTrueToPurpose = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='StayTrueToPurpose-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'StayTrueToPurpose'}</title>
			<rect width='24' height='24' rx='12' fill='#6DB63A' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.9643 5.57143C12.9643 6.16316 12.4846 6.64286 11.8929 6.64286C11.3011 6.64286 10.8214 6.16316 10.8214 5.57143C10.8214 4.97969 11.3011 4.5 11.8929 4.5C12.4846 4.5 12.9643 4.97969 12.9643 5.57143ZM10.0179 8.78574L8.94644 6.10717C8.94644 6.10717 10.5536 7.1786 11.8929 7.1786C13.2322 7.1786 14.8393 6.10717 14.8393 6.10717L13.7679 8.78574H10.0179ZM14.8393 9.32142V9.85713H8.94644V9.32142H14.8393ZM9.48215 16.8214H14.3036L13.7679 10.3928H10.0179L9.48215 16.8214ZM7.875 18.4286H15.9107V19.5H7.875V18.4286ZM14.8393 17.3572H8.94644V17.8929H14.8393V17.3572Z'
				fill='white'
			/>
		</svg>
	);
};
