// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AddSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AddSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'AddSolid'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9.75059 4.75777C9.75062 4.34355 9.41486 4.00774 9.00064 4.00772C8.58643 4.00769 8.25062 4.34345 8.25059 4.75767L8.25035 8.25064L4.75709 8.25064C4.34288 8.25064 4.00709 8.58643 4.00709 9.00064C4.00709 9.41486 4.34288 9.75064 4.75709 9.75064H8.25025L8.25001 13.2431C8.24998 13.6574 8.58575 13.9932 8.99996 13.9932C9.41418 13.9932 9.74998 13.6575 9.75001 13.2432L9.75025 9.75064H13.2424C13.6566 9.75064 13.9924 9.41486 13.9924 9.00064C13.9924 8.58643 13.6566 8.25064 13.2424 8.25064H9.75035L9.75059 4.75777Z'
				fill='current'
			/>
		</svg>
	);
};
