// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const StayTrueToPurposeUntapped = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 36 36'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='StayTrueToPurposeUntapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'StayTrueToPurposeUntapped'}</title>
			<rect
				x='1.25'
				y='1.25'
				width='33.5'
				height='33.5'
				rx='16.75'
				fill='#F2F1F3'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.2857 9.42857C19.2857 10.2175 18.6461 10.8571 17.8572 10.8571C17.0682 10.8571 16.4286 10.2175 16.4286 9.42857C16.4286 8.63959 17.0682 8 17.8572 8C18.6461 8 19.2857 8.63959 19.2857 9.42857ZM15.3572 13.7143L13.9286 10.1429C13.9286 10.1429 16.0714 11.5715 17.8572 11.5715C19.6429 11.5715 21.7857 10.1429 21.7857 10.1429L20.3572 13.7143H15.3572ZM21.7857 14.4286V15.1428H13.9286V14.4286H21.7857ZM14.6429 24.4285H21.0714L20.3571 15.8571H15.3571L14.6429 24.4285ZM12.5 26.5714H23.2143V28H12.5V26.5714ZM21.7857 25.1429H13.9286V25.8572H21.7857V25.1429Z'
				fill={color}
			/>
			<circle
				cx='18'
				cy='18'
				r='16.75'
				fill='none'
				stroke='#A4D483'
				stroke-width='1.5'
				stroke-dasharray='3 4'
			/>
		</svg>
	);
};
