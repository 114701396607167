import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import { PaginatedPagerInfo } from '../../../../types';
import { PaginatedPager } from './PaginatedPager';

export type DataTableFooterProps = {
	pagerInfo: PaginatedPagerInfo;
};

export const PaginatedDataTableFooter = ({
	pagerInfo,
}: DataTableFooterProps): JSX.Element => {
	const { totalRows, currentPage, rowsPerPage } = pagerInfo;

	const start = rowsPerPage * (currentPage - 1);
	const end = rowsPerPage * currentPage;

	return (
		<StyledWrapper data-testid='linus_table_footer'>
			<div>
				<Trans
					i18nKey='web.dataTable.footer.pageInfo'
					values={{
						start: start + 1,
						end: end > totalRows ? totalRows : end,
						total: totalRows,
					}}
					components={[<strong />, <span />, <StyledSpan />]}
				/>
			</div>
			<PaginatedPager pagerInfo={pagerInfo} />
		</StyledWrapper>
	);
};

const StyledWrapper = styled.div`
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom-left: 20px;
	border-bottom-right: 20px;
`;

const StyledSpan = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.bold};
	`
);
