import { linusIconStrategy } from './linusIconStrategy';
import { clinicalIconStrategy } from './clinicalIconStrategy';
import { CurrentUser } from '../types';
import { EnumItem } from '../enums/enumeration';
import { icons } from '../enums/icons';
import { organizationType } from '../enums/organizationEnums/organizationType';
import { researchIconStrategy } from './researchIconStrategy';

const orgTypes = {
	[organizationType.Linus.value]: linusIconStrategy,
	[organizationType.Clinical.value]: clinicalIconStrategy,
	[organizationType.Research.value]: researchIconStrategy,
};

export const orgSpecificIconStrategy = (
	key: string,
	currentUser?: CurrentUser,
	orgType?: string
): EnumItem => {
	const index = currentUser
		? currentUser.organizationType.valueOf()
		: orgType
		? organizationType.fromValue(orgType)?.value
		: undefined;
	// Setting default icon here
	if (!index) return icons.Info;
	const strategy = orgTypes[index];
	return strategy[key] || key;
};
