import styled from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';
import { SimpleGrid, Space } from '@mantine/core';

import { AudioCard, AudioCardProps } from '../AudioCard/AudioCard';

type Props = {
	mocked?: boolean;
	sectionHeading: string;
	numberOfColumns?: number;
	data: AudioCardProps[];
};

const DEFAULT_GRID_COLUMN_COUNT = 2;

export const AudioCardGrid = (props: Props) => {
	return (
		<div>
			<StyledH3>{props?.sectionHeading}</StyledH3>
			<Space h={'sm'} />
			<SimpleGrid
				cols={props?.numberOfColumns ?? DEFAULT_GRID_COLUMN_COUNT}>
				{props?.data?.map((data) => {
					return (
						<AudioCard
							key={`${data?.text?.cardHeading}-${data?.audio?.source}`}
							text={{
								cardHeading: data?.text?.cardHeading,
								cardSubHeadings: data?.text?.cardSubHeadings,
							}}
							renderCardContent={data?.renderCardContent}
							audio={{
								source: data?.audio?.source,
								fileType: data?.audio?.fileType,
							}}
						/>
					);
				})}
			</SimpleGrid>
		</div>
	);
};

export const StyledH3 = styled(Typography.H3)`
	margin-top: ${(props) => props.theme.spacing.xl};
	margin-bottom: ${(props) => props.theme.spacing.lg};
	margin-left: ${(props) => props.theme.spacing.lg};
`;
