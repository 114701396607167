// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const MagnifyingGlass = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='MagnifyingGlass-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'MagnifyingGlass'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.2154 15.853C14.0258 16.8944 12.468 17.5255 10.7628 17.5255C7.02779 17.5255 4 14.4977 4 10.7628C4 7.02779 7.02779 4 10.7628 4C14.4977 4 17.5255 7.02779 17.5255 10.7628C17.5255 12.4325 16.9203 13.961 15.9174 15.1407L19.9027 19.1261C20.098 19.3213 20.098 19.6379 19.9027 19.8332C19.7074 20.0284 19.3909 20.0284 19.1956 19.8332L15.2154 15.853ZM16.5255 10.7628C16.5255 13.9454 13.9454 16.5255 10.7628 16.5255C7.58007 16.5255 5 13.9454 5 10.7628C5 7.58007 7.58007 5 10.7628 5C13.9454 5 16.5255 7.58007 16.5255 10.7628Z'
				fill='#676671'
			/>
		</svg>
	);
};
