/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../designSystem/Icon';
import { StyledCard } from '../../StyledCard';

export interface PaginatedLinusSearchBarProps {
	value: string;
	placeholder?: string;
	onChange: (item: string) => void;
	onClear: () => void;
	searchComponentMargin?: string;
	searchComponentWidth?: string;
}

export const PaginatedLinusSearchBar = ({
	value,
	placeholder = '',
	onChange,
	onClear,
	searchComponentMargin,
	searchComponentWidth,
}: PaginatedLinusSearchBarProps): JSX.Element => {
	const { t } = useTranslation();

	const [_value, setValue] = useState(value);

	/**
	 * Sync `_value` state when `value` changes
	 */
	useEffect(() => {
		if (value.trim() !== _value.trim()) setValue(value);
	}, [value]);

	function handleOnChange(newValue: string) {
		setValue(newValue);
		onChange(newValue.trim());
	}

	// Default to the width of the placeholder
	if (!searchComponentWidth) {
		searchComponentWidth = placeholder ? `${placeholder.length}ch` : '';
	}

	return (
		<>
			<StyledContainer data-testid='paginated_linus_search_bar'>
				<StyledSearchComponent
					margin={searchComponentMargin}
					width={searchComponentWidth}>
					<Icon
						icon={icons.MagnifyingGlass}
						width={24}
						height={24}
						title={t`web.shared.search.searchIconTitle`}
					/>
					<StyledInput
						value={_value}
						onChange={(e) => handleOnChange(e.target.value)}
						onBlur={(e) => setValue(e.target.value.trim())}
						data-testid={'paginated_linus_search_input'}
						placeholder={placeholder}
						width={searchComponentWidth}
					/>
					<div data-testid='paginated_search_clear_icon'>
						<Icon
							icon={icons.Close}
							width={11}
							height={11}
							title={t`web.shared.search.clearSearchButtonTitle`}
							onClick={onClear}
							visible={value.length > 0}
						/>
					</div>
				</StyledSearchComponent>
			</StyledContainer>
		</>
	);
};

const StyledContainer = styled(StyledCard)`
	display: flex;
	box-shadow: none;
	box-sizing: border-box;
`;

type StyledSearchComponentProps = {
	margin?: string;
	width?: string;
};
const StyledSearchComponent = styled(StyledCard)<StyledSearchComponentProps>(
	({
		margin,
		width,
		theme: { borderRadius, color, spacing, fontSize },
	}) => css`
		display: flex;
		align-items: center;
		padding: 0 ${spacing.md};
		position: relative;
		flex: flex-shrink;
		font-size: ${fontSize._16};
		height: 48px;
		width: ${width || ''};
		margin: ${margin || ''};
		color: ${color.formText};
		border-radius: ${borderRadius.searchBox};
		box-shadow: none;

		border: 1px solid ${color.inputBorder};

		&::placeholder {
			color: ${color.formText};
		}

		&:hover {
			border: 1px solid ${color.inputHover};
		}

		&:focus-within {
			border: 1px solid ${color.inputFocus};
			outline: none;
		}
	`
);

const StyledInput = styled.input(
	({ width, theme: { color, spacing, fontSize } }) => css`
		display: flex;
		padding: 0 ${spacing.sm};
		box-sizing: border-box;
		position: relative;
		width: ${width || ''};
		font-size: ${fontSize._16};
		color: ${color.formText};

		&::placeholder {
			color: ${color.formText};
		}

		&:hover {
			border: none;
		}

		&:focus {
			outline: none;
		}
	`
);
