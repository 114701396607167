import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { contactMethods } from './consts';
import { icons } from '../../../enums/icons';
import { useFormikContext } from 'formik';
import { Participant } from 'api/participant';
import {
	BATTERY_TYPE,
	CONTACT_METHODS,
	CONTACT_PREFERENCES,
} from '../participantHelpers';
import { AddBatteryModel } from '../../../schemas/forms/addBatterySchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { Caption, Icon, P1, P2 } from '../../shared/designSystem';
import { BatteryMakeup } from './AddBatteryForParticipant';
import { DropdownOption } from '../../../types';

interface BatteryConfigurationProps {
	values: AddBatteryModel;
	participantData: Participant | undefined;
	batteries: BatteryMakeup;
	errors?: Partial<Record<keyof AddBatteryModel, string>>;
}

export function BatteryConfiguration({
	participantData,
	values,
	batteries,
	errors,
}: BatteryConfigurationProps) {
	const { t } = useTranslation();
	const [editMode, setEditMode] = useState(false);
	const { setFieldValue, setErrors } = useFormikContext();

	const [selectedContactPreference, setSelectedContactPreference] = useState<
		string | null
	>(null);
	const isBatteryTypeNotSelected = !values.batteryType;
	const isRemote = values.batteryType === BATTERY_TYPE.REMOTE;
	const shouldBeEditVisible = useMemo(() => {
		switch (selectedContactPreference) {
			case CONTACT_PREFERENCES.EMAIL_ONLY:
				return !!values.contactEmail;
			case CONTACT_PREFERENCES.PHONE_ONLY:
				return !!values.contactPhone;
			case CONTACT_PREFERENCES.EMAIL_AND_PHONE:
				return !!values.contactPhone && !!values.contactEmail;
		}
	}, [selectedContactPreference, values]);
	function handleEditMode() {
		setEditMode((s) => !s);
	}

	useEffect(() => {
		const preference =
			participantData &&
			contactMethods.find((el) => el.value === values.contactPreference);

		if (isRemote) {
			if (preference) {
				setSelectedContactPreference(preference.value);
			}
		} else {
			setSelectedContactPreference(null);
		}
	}, [isRemote, participantData, values.contactPreference]);

	useEffect(() => {
		if (!shouldBeEditVisible && selectedContactPreference) {
			setEditMode(true);
		}
	}, [selectedContactPreference, shouldBeEditVisible]);

	useEffect(() => {
		if (participantData) {
			const { contactEmail, contactPhone } = participantData;
			const { contactEmail: formEmail, contactPhone: formPhone } = values;
			if (selectedContactPreference === CONTACT_PREFERENCES.EMAIL_ONLY) {
				setFieldValue('contactEmail', formEmail ?? contactEmail, true);
			}

			if (selectedContactPreference === CONTACT_PREFERENCES.PHONE_ONLY) {
				setFieldValue('contactPhone', formPhone ?? contactPhone, true);
			}
			if (
				selectedContactPreference ===
				CONTACT_PREFERENCES.EMAIL_AND_PHONE
			) {
				setFieldValue('contactEmail', formEmail ?? contactEmail, true);
				setFieldValue('contactPhone', formPhone ?? contactPhone, true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participantData, values.contactPreference]);

	useEffect(() => {
		setErrors({});
	}, [selectedContactPreference, setErrors]);

	const isEmailSelected =
		selectedContactPreference === CONTACT_PREFERENCES.EMAIL_AND_PHONE ||
		selectedContactPreference === CONTACT_PREFERENCES.EMAIL_ONLY;
	const isPhoneSelected =
		selectedContactPreference === CONTACT_PREFERENCES.EMAIL_AND_PHONE ||
		selectedContactPreference === CONTACT_PREFERENCES.PHONE_ONLY;

	if (isBatteryTypeNotSelected) return null;
	return (
		<BatteryConfigurationContainer>
			<BatteryConfigurationTitle>
				{t('web.shared.batteryConfiguration')}
			</BatteryConfigurationTitle>
			<BatteryConfigurationDropdowns>
				{values.batteryType === BATTERY_TYPE.IN_CLINIC ? (
					<LinusInput
						data-testid='batteries-dropdown-clinic'
						name='battery'
						type='select'
						error={errors?.battery}
						label={t`web.shared.battery`}
						dropdownOptions={batteries?.clinic?.map(
							(battery): DropdownOption => {
								return {
									display: t(`${battery?.displayKey}`),
									value: `${battery?.id}`,
								};
							}
						)}
					/>
				) : (
					<LinusInput
						data-testid='batteries-dropdown-remote'
						name='battery'
						type='select'
						error={errors?.battery}
						label={t`web.shared.battery`}
						dropdownOptions={batteries?.remote?.map(
							(battery): DropdownOption => {
								return {
									display: t(`${battery?.displayKey}`),
									value: `${battery?.id}`,
								};
							}
						)}
						styles={{ marginRight: '24px' }}
					/>
				)}
				{isRemote && (
					<LinusInput
						data-testid='contactPreference'
						name='contactPreference'
						type='select'
						label={t`web.shared.contactMethods.label`}
						dropdownOptions={contactMethods}
						data-id='contact-method'
						subtext={t`web.shared.contactMethods.subtext`}
					/>
				)}
			</BatteryConfigurationDropdowns>
			{isRemote && (
				<ContactInformationContainer>
					{selectedContactPreference &&
						selectedContactPreference !==
							CONTACT_METHODS.NO_SEND && (
							<>
								<ContactInformationTitleContaier>
									<ContactInformationTitle>
										{t(
											'web.shared.contactInformation.title'
										)}
									</ContactInformationTitle>
									{shouldBeEditVisible && !editMode && (
										<EditButton
											data-testid='editButton'
											onClick={handleEditMode}>
											{t(
												'research.participantModal.edit'
											)}
											<Icon icon={icons.PencilFilled} />
										</EditButton>
									)}
								</ContactInformationTitleContaier>
								{!shouldBeEditVisible && (
									<ContactInformationSubtitle>
										{t(
											'web.shared.contactInformation.subtitle'
										)}
									</ContactInformationSubtitle>
								)}
							</>
						)}
					<ContactInformationDropdownsContainer>
						{!editMode && shouldBeEditVisible && (
							<ContactInformationFields>
								{isEmailSelected && (
									<ContactInformationField
										data-testid='emailField'
										style={{ marginRight: '24px' }}>
										<ContactInformationCaption>
											{t('web.shared.emailAddress')}
										</ContactInformationCaption>
										<P1>{participantData?.contactEmail}</P1>
									</ContactInformationField>
								)}
								{isPhoneSelected && (
									<ContactInformationField data-testid='phoneField'>
										<ContactInformationCaption>
											{t('web.shared.phoneNumber')}
										</ContactInformationCaption>
										<P1>{participantData?.contactPhone}</P1>
									</ContactInformationField>
								)}
							</ContactInformationFields>
						)}
						{editMode && isEmailSelected && (
							<LinusInput
								data-testid='contactEmail'
								label='Email Address'
								name='contactEmail'
								styles={{ marginRight: '24px' }}
								data-id='email-address'
							/>
						)}
						{editMode && isPhoneSelected && (
							<LinusInput
								data-testid='contactPhone'
								label='Phone Number'
								name='contactPhone'
								type='phone'
								data-id='phone-number'
							/>
						)}
					</ContactInformationDropdownsContainer>
				</ContactInformationContainer>
			)}
		</BatteryConfigurationContainer>
	);
}

const BatteryConfigurationContainer = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	flex-direction: column;
	border: 1px solid ${colors.gray_70};
	background-color: ${colors.gray_90};
	width: 100%;
	border-radius: 4px;
	padding: 12px;
`
);

const BatteryConfigurationDropdowns = styled.div(
	({ theme }) => `
    display: flex;
    width: 100%;
    gap: ${theme.spacing.md};
`
);

const BatteryConfigurationTitle = styled(P1)(
	({ theme: { colors } }) => `
	color: ${colors.gray_10};
	font-weight: 500;
`
);

const ContactInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 8px;
`;

const ContactInformationTitleContaier = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ContactInformationTitle = styled(P1)(
	({ theme: { colors } }) => `
	color: ${colors.gray_10};
	font-weight: 500;
	margin-right: 8px;
`
);

const ContactInformationSubtitle = styled(P2)(
	({ theme: { colors } }) => `
	color: ${colors.gray_30};
	margin-top: 4px;
`
);

const ContactInformationDropdownsContainer = styled.div(
	({ theme }) => `
    display: flex;
    width: 100%;
    gap: ${theme.spacing.md};
`
);

const ContactInformationFields = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const ContactInformationField = styled.div`
	width: 317px;
`;

const ContactInformationCaption = styled(Caption)(
	({ theme: { colors } }) => `color: ${colors.gray_30}`
);

const EditButton = styled.a(
	({ theme: { colors } }) => `
		color: ${colors.blue}; 
		font-weight: 600;
		display: flex;
		flex-direction: row;
		align-items: center;
	`
);
