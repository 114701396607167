import { resources } from '@lh/i18n-prd';
import dateFnsFormat from 'date-fns/format';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { loadLHQTranslations } from 'utils/loadLHQTranslationUtils';
import { fallbackLngResolver } from './utils/i18nextFallbackUtil';

i18n.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		detection: {
			order: ['querystring', 'navigator'],
			caches: [],
		},
		resources,
		// debug: true,
		fallbackLng: fallbackLngResolver,
		returnEmptyString: false,
		interpolation: {
			escapeValue: false, // react already safes from xss
			format: (
				value,
				format
				// lng,
				// edit
			) => {
				if (
					!value ||
					value === '' ||
					value === undefined ||
					value === null
				) {
					return '';
				}
				if (format === undefined) {
					return value;
				}
				const [type, mask] = format.split('|');

				if (type === 'date') {
					return dateFnsFormat(value, mask);
				}
				return value;
			},
		},
	});
loadLHQTranslations(i18n);

export default i18n;
export * from 'i18next';
