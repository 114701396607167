import { theme as Theme } from '@lh/eng-web-mosaic-common';

export enum IndicativesKeys {
	Normal = 'Normal',
	Subtle = 'Subtle',
	AmnesticMild = 'AmnesticMild',
	Dysexecutive = 'Dysexecutive',
	MixedMild = 'MixedMild',
	AmnesticModerate = 'AmnesticModerate',
	NonAmnestic = 'NonAmnestic',
}

export const TranslationKeys: Record<string, string> = {
	[IndicativesKeys.Normal]: 'web.report.cohort.classification.normal.table',
	[IndicativesKeys.Subtle]: 'web.report.cohort.classification.subtle.table',
	[IndicativesKeys.AmnesticMild]:
		'web.report.cohort.classification.singleDomain.table',
	[IndicativesKeys.Dysexecutive]:
		'web.report.cohort.classification.dysexecutive.table',
	[IndicativesKeys.MixedMild]:
		'web.report.cohort.classification.mixedMild.table',
	[IndicativesKeys.AmnesticModerate]:
		'web.report.cohort.classification.amnesticModerate.table',
	[IndicativesKeys.NonAmnestic]:
		'web.report.cohort.classification.nonAmnestic.table',
};

export interface IndicativeProps {
	indicative: string;
	ellipsisColor?: string;
	height?: number;
	highlightColor?: string;
	indicativeTextColor?: string;
}

export const INDICATIVES: IndicativeProps[] = [
	{
		indicative: TranslationKeys[IndicativesKeys.Normal],
		height: 148,
	},
	{
		indicative: TranslationKeys[IndicativesKeys.Subtle],
	},
	{
		ellipsisColor: Theme.colors.yellow_light,
		indicative: TranslationKeys[IndicativesKeys.AmnesticMild],
		highlightColor: Theme.colors.yellow_lightest,
		indicativeTextColor: Theme.colors.yellow_darkest,
	},
	{
		ellipsisColor: Theme.colors.yellow_light,
		indicative: TranslationKeys[IndicativesKeys.Dysexecutive],
		height: 306,
		highlightColor: Theme.colors.yellow_lightest,
		indicativeTextColor: Theme.colors.yellow_darkest,
	},
	{
		ellipsisColor: Theme.colors.yellow_light,
		indicative: TranslationKeys[IndicativesKeys.MixedMild],
		highlightColor: Theme.colors.yellow_lightest,
		indicativeTextColor: Theme.colors.yellow_darkest,
	},
	{
		ellipsisColor: Theme.colors.orange_light,
		indicative: TranslationKeys[IndicativesKeys.AmnesticModerate],
		highlightColor: Theme.colors.orange_lightest,
		indicativeTextColor: Theme.colors.orange_darkest,
	},
	{
		ellipsisColor: Theme.colors.orange_light,
		indicative: TranslationKeys[IndicativesKeys.NonAmnestic],
		highlightColor: Theme.colors.orange_lightest,
		indicativeTextColor: Theme.colors.orange_darkest,
	},
];
