// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AlertShieldOutline = (props: IconProps): JSX.Element => {
	return (
		<AlertShield
			{...props}
			colorProp='stroke'
			title={props.title ?? 'AlertShieldOutline'}
		/>
	);
};

export const AlertShieldSolid = (props: IconProps): JSX.Element => {
	return (
		<AlertShield
			{...props}
			colorProp='fill'
			title={props.title ?? 'AlertShieldSolid'}
		/>
	);
};

const AlertShield = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
	colorProp,
}: IconProps & { colorProp: 'stroke' | 'fill' }): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 14 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AlertShieldSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			width={width}
			height={height}
			{...{
				[colorProp]: color,
				fillOpacity: colorProp === 'fill' ? 1 : 0,
			}}>
			<title>{title}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.9426 3.40064V6.20616C13.9426 6.3932 13.9426 6.56323 13.9596 6.75027V6.75033C13.9596 7.49845 13.9596 8.26357 13.9256 9.01169C13.8746 10.1339 13.4665 11.2051 12.7014 12.2083C11.8342 13.3815 10.678 14.3337 9.16472 15.1328C8.75664 15.3369 8.33156 15.5239 7.94049 15.6939C7.77046 15.762 7.61743 15.83 7.4644 15.898C7.31137 15.966 7.14134 16 6.97131 16C6.85228 16 6.71626 15.966 6.59724 15.932C6.58023 15.915 6.58023 15.915 6.56323 15.915C4.65887 15.2009 3.16259 14.2657 1.97237 13.0584C1.00319 12.0893 0.42508 11.0691 0.170032 9.94687C0.0510096 9.43677 0 8.96068 0 8.5186V5.11796V3.43464C0 2.51647 0.595111 2.29543 1.0882 2.27843C2.78852 2.17641 4.42083 1.54729 6.24017 0.289054C6.26412 0.274313 6.28788 0.259372 6.31161 0.244448C6.50683 0.121658 6.70026 0 6.98831 0C7.29437 0 7.51541 0.136026 7.71945 0.306057C9.0797 1.25824 10.3039 1.81934 11.5792 2.1084C11.8533 2.17692 12.1384 2.21231 12.4435 2.25017C12.5169 2.25928 12.5915 2.26853 12.6674 2.27843C12.7184 2.28693 12.7651 2.29118 12.8119 2.29543C12.8587 2.29968 12.9054 2.30393 12.9564 2.31243C13.5685 2.38045 13.9426 2.80553 13.9426 3.40064ZM7.81631 4.54002C7.86732 4.67605 7.90133 4.81207 7.90133 4.9651C7.88433 5.39018 7.85032 5.83226 7.81631 6.25734C7.79931 6.5889 7.77806 6.92471 7.7568 7.26052C7.73555 7.59633 7.71429 7.93215 7.69729 8.26372C7.68029 8.48475 7.68029 8.67178 7.68029 8.89282V8.89284C7.66329 9.24991 7.39123 9.52196 7.03417 9.52196C6.6771 9.52196 6.40505 9.26691 6.38805 8.90984C6.36254 8.39124 6.33279 7.87265 6.30303 7.35405C6.27327 6.83545 6.24352 6.31686 6.21801 5.79826L6.21801 5.79824C6.20101 5.52619 6.18401 5.25415 6.167 4.9651C6.167 4.50602 6.42205 4.13195 6.83013 4.01292C7.2212 3.94491 7.64628 4.14895 7.81631 4.54002ZM7.86732 11.0182C7.88433 11.4773 7.47625 11.8684 7.01716 11.8684C6.54107 11.8684 6.15 11.4773 6.15 11.0012C6.15 10.5251 6.54107 10.1341 7.01716 10.1341C7.49325 10.1341 7.90133 10.5251 7.86732 11.0182Z'
				fill='current'
			/>
		</svg>
	);
};
