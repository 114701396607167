// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const LearnSomethingNewTapped = ({
	title,
	width = '24',
	height = '24',
	color = '#52862E',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='LearnSomethingNewTapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'LearnSomethingNewTapped'}</title>
			<rect x='0.5' width='32' height='32' rx='16' fill={color} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.8239 6V18.3239V18.3449H19.6693L20.5526 20.5741H21.4359C21.7303 20.5741 21.9826 20.8265 21.9826 21.1209C21.9826 21.4154 21.7513 21.6677 21.4359 21.6677H20.9942L22.4033 25.2429C22.5294 25.5373 22.3822 25.8528 22.1088 25.9579C22.0913 25.9623 22.0746 25.9667 22.0587 25.9709C21.9984 25.9868 21.9485 26 21.8985 26C21.6882 26 21.4779 25.8528 21.3938 25.6425L19.8165 21.6677H13.6546L12.0563 25.6004C11.9721 25.8318 11.7618 25.9579 11.5515 25.9579C11.4674 25.9579 11.4043 25.9369 11.3412 25.9159C11.0468 25.8107 10.8996 25.4742 11.0258 25.2008L12.4558 21.6467H12.0352C11.7408 21.6467 11.4884 21.3943 11.4884 21.0999C11.4884 20.8055 11.7198 20.5531 12.0352 20.5531H12.9185L13.8018 18.3239H10.5V6H22.8239ZM21.8144 7.03049H11.5305V17.3144H21.8144V7.03049ZM16.1782 18.3239H14.9795L14.0962 20.5531H16.1782V18.3239ZM17.2928 20.5531H19.3749L18.4916 18.3239H17.2928V20.5531ZM13.4232 13.4658L12.0773 14.7697V7.57728H21.2255V13.1924L20.1109 11.9306C19.6272 11.4048 18.807 11.4048 18.3444 11.9306L16.1782 14.4963L15.1057 13.4658C14.643 13.0032 13.8859 13.0032 13.4232 13.4658ZM15.6945 9.95373C15.6945 9.25972 15.1267 8.71293 14.4537 8.71293C13.7597 8.71293 13.2129 9.25972 13.2129 9.95373C13.2129 10.6477 13.7597 11.1945 14.4537 11.1945C15.1477 11.1945 15.6945 10.6267 15.6945 9.95373Z'
				fill='white'
			/>
		</svg>
	);
};
