import { FeatureType } from '@lh/eng-platform-organization-service-rest-client';

import { Divider, Space } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { useGetOrgEntitlements } from '../../../../../hooks/useGetOrgEntitlements';
import { theme } from '../../../../../providers/styleProvider';
import { LinusModal } from '../../../../shared/LinusModal';
import ScoringWidget from '../../../common/scoring/scoringWidget/ScoringWidget';
import { DcrReportProps } from '../../DcrReport';
import {
	getDcrScoreData,
	getDementiaRisk,
	getMmsePrediction,
} from '../../helpers/dcrScoringDataHelpers';
import { getScoreBreakdownTitleTransKey } from '../../helpers/dcrScoringStringHelpers.ts';
import {
	AmyloidInterpretation,
	DcrMetricItemKeys,
	DcrScoreData,
} from '../../types';
import { AmyloidLikelihoodWidget } from '../amyloidLikelihood/AmyloidLikelihoodWidget';
import { MMSEAccordion } from '../mmseScore/MMSEAccordion';

import { P2 } from 'components/shared/designSystem';
import { DementiaRisk } from '../dementiaRisk';
import { DcrScoringDetails } from './components/DcrScoringDetails';
import ScoringRange from './components/DcrScoringRange';

type DcrScoringWidgetProps = {
	data: DcrReportProps['data'];
	showAmyloidWidget?: boolean;
};
const DcrScoringWidget = (props: DcrScoringWidgetProps) => {
	const { t } = useTranslation();

	const [
		isDcrScoringDetailsModalVisible,
		setIsDcrScoringDetailsModalVisible,
	] = useState<boolean>(false);

	const dcrScoreData = useMemo(
		() => getDcrScoreData(props?.data),
		[props?.data]
	);

	const dcrScoreAlgorithmVersion = useMemo(
		() =>
			props?.data?.assessmentData?.metricItems[
				DcrMetricItemKeys.DCR_SCORE
			]?.version ?? '0.1',
		[props?.data]
	);

	const breakdownSummaryText = useMemo(() => {
		return (
			<P2Med>
				<Trans
					i18nKey={
						dcrScoreData?.isUnanalyzable
							? `web.dcrReport.overview.scoringDescription.requiresClinicianReview.text`
							: getScoreBreakdownTitleTransKey(
									dcrScoreData?.totalScore?.achieved,
									dcrScoreAlgorithmVersion
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  )
					}
					components={{ a: <b /> }} // This makes the text (enclosed within <a> tags in the locale string) bold
				/>
			</P2Med>
		);
	}, [
		dcrScoreAlgorithmVersion,
		dcrScoreData?.isUnanalyzable,
		dcrScoreData?.totalScore?.achieved,
	]);

	return (
		<>
			<ScoringWidget
				isUnanalyzable={dcrScoreData?.isUnanalyzable}
				header={{
					titleText: 'Digital Clock and Recall (DCR) Report',
					infoTooltipText: t`web.dcrReport.overview.tooltip`,
				}}
				content={{
					overallScore: {
						highlightColor: dcrScoreData?.scoreTagBackgroundColors,
						value: dcrScoreData?.totalScore,
					},
					additionalScore: {
						component: () => (
							<AdditionalScores
								data={props.data}
								showAmyloidWidget={props.showAmyloidWidget}
								dcrScoreData={dcrScoreData}
							/>
						),
					},
					breakdown: {
						breakdownSummary: breakdownSummaryText,
						scores: dcrScoreData?.scoreData,
						scoreIndicatorColor:
							dcrScoreData?.scoreTagBackgroundColors?.solid,
					},
					link: {
						text: 'View Scoring Details',
						onClick: () => setIsDcrScoringDetailsModalVisible(true),
					},
				}}
			/>
			{isDcrScoringDetailsModalVisible && (
				<LinusModal
					title={t`web.dcrReport.overview.scoringDetails.modal.title`}
					onClose={() => setIsDcrScoringDetailsModalVisible(false)}>
					<DcrScoringDetails
						onClose={() =>
							setIsDcrScoringDetailsModalVisible(false)
						}
						algorithmVersion={dcrScoreAlgorithmVersion}
						rangesComponent={
							<ScoringRange version={dcrScoreAlgorithmVersion} />
						}
					/>
				</LinusModal>
			)}
		</>
	);
};

export default DcrScoringWidget;

type AdditionalScoresProps = {
	data: DcrReportProps['data'];
	dcrScoreData: DcrScoreData;
	showAmyloidWidget?: boolean;
};

function AdditionalScores({
	data,
	dcrScoreData,
	showAmyloidWidget,
}: Readonly<AdditionalScoresProps>) {
	const { t } = useTranslation();
	const { hasFeatureEntitlement } = useGetOrgEntitlements();

	const amyloidInterpretationString: AmyloidInterpretation = useMemo(
		() =>
			(data?.assessmentData?.metricItems[
				DcrMetricItemKeys.AMYLOID_INTERPRETATION
			]?.value?.toString() as AmyloidInterpretation) ??
			AmyloidInterpretation.UNANALYZABLE,
		[data]
	);

	const mmsePrediction = getMmsePrediction(
		data,
		hasFeatureEntitlement(FeatureType.MmseEstimation),
		dcrScoreData?.isUnanalyzable
	);

	const dementiaRisk = getDementiaRisk(
		data,
		hasFeatureEntitlement(FeatureType.DementiaRiskEstimateReports)
	);

	if (!showAmyloidWidget && !mmsePrediction && !dementiaRisk) {
		return null;
	}

	const scoreWidgets = [
		{
			element: (
				<AmyloidLikelihoodWidget
					isUnanalyzable={dcrScoreData?.isUnanalyzable}
					interpretationText={amyloidInterpretationString}
				/>
			),
			condition: showAmyloidWidget,
		},
		{
			element: <DementiaRisk type={dementiaRisk ?? 'Unanalyzable'} />,
			condition: !!dementiaRisk,
		},
		{
			element: (
				<MMSEAccordion
					score={mmsePrediction ?? '--'}
					marginOfError={3}
				/>
			),
			condition: !!mmsePrediction,
		},
	];

	return (
		<AdditionalScoresWrapper>
			<Space h={theme.spacing.sm} />
			<WidgetTitle data-testid={`amyloid-widet-title`}>
				{t('web.report.amyloidLikelihood.widgetHeader')?.toUpperCase()}
			</WidgetTitle>
			<Space h={theme.spacing.sm} />
			{scoreWidgets
				.filter((e) => e.condition)
				.map(({ element }, index) => {
					return (
						<React.Fragment key={element.key}>
							{index !== 0 && (
								<>
									<Space h={theme.spacing.sm} />
									<Divider
										color={theme.colors.gray_60}
										w='100%'
										h='1px'
									/>
									<Space h={theme.spacing.sm} />
								</>
							)}
							{element}
						</React.Fragment>
					);
				})}
		</AdditionalScoresWrapper>
	);
}

export const P2Med = styled(P2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const AdditionalScoresWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
`;

const WidgetTitle = styled(P2)(
	({ theme: { weight, color } }) => css`
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.medium};
	`
);
