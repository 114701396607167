import { Divider, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Trials, Typography } from '@lh/eng-web-mosaic-common';

import { theme } from '../../../../providers/styleProvider';

export type ScoreBreakdownProps = {
	breakdown: {
		title: string;
		score: string;
		suffix: string;
	}[];
};

const ScoreBreakdown = (props: ScoreBreakdownProps) => {
	const { t } = useTranslation();
	return (
		<>
			<Typography.P2 weight='500' color={theme?.color?.bodyTextSecondary}>
				{t('web.report.verbalFluency.breakdown')}
			</Typography.P2>
			<Space h='sm' />
			{props?.breakdown?.map(
				(
					{ title = 'title', score = 'score', suffix = 'suffix' },
					index
				) => {
					const isLastIndex = index !== props?.breakdown?.length - 1;
					return (
						<div key={`${title}-${score}-${suffix}`}>
							<Trials
								responseText={suffix}
								title={title}
								score={score}
							/>
							{isLastIndex ? <Divider my='xs' /> : null}
						</div>
					);
				}
			)}
		</>
	);
};

export { ScoreBreakdown };
