import {
	Answer,
	answersAndQuestionToElements,
	isPositivelyAnswered,
} from '@lh/eng-lhq-questions-common';

import { EnumItem } from '../../../../../enums/enumeration';
import { Pillar } from '../../../../../enums/Pillar';
import { SegmentType } from '../../../../../generated/graphql';
import { questionsLHQ15, questionsLHQ32 } from '../../../../table/Question';

type AnswerResults = Answer[];

export type ProcessedLifestyleFactors = {
	[key: string]: { riskIdentified: boolean };
};

export type LifestyleFactor = {
	icon: EnumItem;
	title: string;
	subtitle?: string;
};

export type PillarAcc = { [key: string]: boolean };

export const processAnswers = (
	results: AnswerResults,
	segmentType: SegmentType
): ProcessedLifestyleFactors | undefined => {
	const risks: ProcessedLifestyleFactors =
		segmentType === SegmentType.Lhq32
			? calculateLHQ32Risks(results)
			: calculateLHQ15Risks(results);

	// add in the rest of the categories
	// ( excluding "other" ) as not having any risks identified
	return Pillar.toKeys()
		.filter((x) => x !== Pillar.NonModifiableRiskFactor.key)
		.reduce((acc: ProcessedLifestyleFactors, key) => {
			if (!acc[key]) {
				acc[key] = { riskIdentified: false };
			}
			return acc;
		}, risks);
};

const calculateLHQ15Risks = (results: AnswerResults) => {
	const risks: ProcessedLifestyleFactors = {};
	const elements = answersAndQuestionToElements({
		questionsToUse: questionsLHQ15,
		userProvidedAnswers: results,
	});

	elements.forEach((questionElement) => {
		questionElement.answers.forEach((answerElement) => {
			if (
				!isPositivelyAnswered({
					singleElement: questionElement,
					answerElement,
				})
			) {
				// Only flag the risk if it's a relevant pillar
				const pillar =
					questionElement.question.answers?.[answerElement.answer.id]
						?.pillar ?? questionElement.question.pillar;
				if (
					pillar != null &&
					pillar !== Pillar.NonModifiableRiskFactor
				) {
					risks[pillar.value] = { riskIdentified: true };
				}
			}
		});
	});
	return risks;
};

const calculateLHQ32Risks = (results: AnswerResults) => {
	return (
		results
			// find questions that were answered incorrectly
			// then set the pillar as having one or more risks identified
			.filter((element) => {
				const id = element.id;
				return !!questionsLHQ32[id];
			})
			.reduce((acc: ProcessedLifestyleFactors, a) => {
				const id = a.id;
				const question = questionsLHQ32[id];
				if (
					question.pillar == null ||
					question.pillar === Pillar.NonModifiableRiskFactor
				) {
					// Irrelevant question, skip
					return acc;
				}
				if (!!question.positiveAnswer === !!a.answer) {
					// Patient answered "correctly", skip
					return acc;
				}
				// Risk identified, add it
				return {
					...acc,
					[question.pillar.value]: {
						riskIdentified: true,
					},
				};
			}, {})
	);
};
