import styled from 'styled-components';
import { H3 as DSH3 } from '../../../designSystem/Text';
import { RecallRecording } from '../../DigitalClockAndRecall(DCR)/components/dcrRecordings/components/RecallRecording';
import { FormattedSegmentResult } from '../../common';

export type WordRecallProps = {
	immediate_recall: FormattedSegmentResult | undefined;
	delayed_recall: FormattedSegmentResult | undefined;
	showAudioPlayback?: boolean;
};

const WordRecall = (wordRecallData: WordRecallProps) => {
	const {
		immediate_recall: immediateRecallData,
		delayed_recall: delayedRecallData,
		showAudioPlayback = false,
	} = wordRecallData;

	if (!wordRecallData) return null;

	return (
		<>
			<H3>Recordings</H3>
			<Container>
				{showAudioPlayback && (
					<>
						<RecallRecording
							data={immediateRecallData}
							showAudioPlayback={showAudioPlayback}
						/>
						<RecallRecording
							data={delayedRecallData}
							showAudioPlayback={showAudioPlayback}
						/>
					</>
				)}
			</Container>
		</>
	);
};

const Container = styled.div(
	({ theme: { spacing, breakpoints } }) => `
	display: flex;
	flex-direction: row;
	align-content: center;
	gap: ${spacing.md};
	width: 100%;
	${breakpoints.tabletDown} {
		flex-direction: column;
		width: 40%;
	}
	${breakpoints.mobile} {
		width: 30%;
	}
`
);

const H3 = styled(DSH3)`
	margin-top: ${(props) => props.theme.spacing.xl};
	margin-bottom: 24px; // This should be spacing.lg, but the injected theme has lg = 25. Will go back to fix it one day
`;

export { WordRecall };
