import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../../../../../shared/designSystem';
import { icons } from '../../../../../../enums/icons';
import { LinusTooltip } from '../../../../../shared/LinusTooltip';

type ScoringWidgetHeaderTooltipProps = {
	text: string;
};

const ScoringWidgetHeaderTooltip = (props: ScoringWidgetHeaderTooltipProps) => {
	return (
		<LinusTooltip
			data-testid={'scoring-widget-header-tooltip'}
			overlay={<InfoTooltip>{props?.text}</InfoTooltip>}
			tooltipIcon={
				<Icon
					icon={icons.InfoOutlined}
					iconWidth={24}
					iconHeight={24}
				/>
			}
		/>
	);
};

const InfoTooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 314px;
		font-size: ${fontSize._16};
	`
);

export default ScoringWidgetHeaderTooltip;
