import { ReactElement } from 'react';
import Tooltip from 'rc-tooltip';
import styled, { useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import { Icon } from './designSystem/Icon';

export type LinusTooltipProps = Omit<
	React.ComponentProps<typeof Tooltip> & {
		tooltipIcon?: ReactElement;
	},
	'children'
>;
export type OnPopupAlign = NonNullable<LinusTooltipProps['onPopupAlign']>;

const LinusTooltip = ({
	placement,
	trigger,
	overlay,
	overlayStyle,
	tooltipIcon,
	onPopupAlign,
	align,
}: LinusTooltipProps): JSX.Element => {
	const { color } = useTheme();

	return (
		<Tooltip
			placement={placement || 'right'}
			trigger={trigger || 'hover'}
			onPopupAlign={onPopupAlign}
			destroyTooltipOnHide={true}
			align={align}
			overlayStyle={
				overlayStyle || {
					...defaultOverlayStyle,
					backgroundColor: color.formText,
					color: color.white,
				}
			}
			overlay={
				<StyledTooltip>{overlay as React.ReactNode}</StyledTooltip>
			}>
			{tooltipIcon ? (
				tooltipIcon
			) : (
				<StyledIcon>
					<Icon
						icon={icons.InfoOutlined}
						iconWidth={40}
						iconHeight={40}
					/>
				</StyledIcon>
			)}
		</Tooltip>
	);
};

const defaultOverlayStyle: React.CSSProperties = {
	position: 'fixed',
	zIndex: 1000,
	padding: '15px 5px',
	borderRadius: '5px',
};

const StyledTooltip = styled.div(
	({ theme: { spacing } }) => `
	min-width: 100px;
	z-index: 10;
	padding: ${spacing.sm}
`
);

const StyledIcon = styled.div(
	({ theme: { spacing } }) => `
        margin: ${spacing.sm};
        width: 15px;
    `
);

export { LinusTooltip };
