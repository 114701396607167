import { ArtifactFormats } from '../components/shared/hooks';

const wavMatcher = /\.wav$/;
const webmMatcher = /\.webm$/;
const mp4Matcher = /\.mp4$/;
const jsonMatcher = /\.json$/;
const pngMatcher = /\.png$/;
export function getArtifactFormatFromExt(path: string): ArtifactFormats {
	if (wavMatcher.exec(path)) return ArtifactFormats.WAV;
	if (webmMatcher.exec(path)) return ArtifactFormats.WEBM;
	if (mp4Matcher.exec(path)) return ArtifactFormats.MP4;
	if (jsonMatcher.exec(path)) return ArtifactFormats.JSON;
	if (pngMatcher.exec(path)) return ArtifactFormats.PNG;
	throw new Error('Unrecognized artifact format');
}
