export const ConfidenceChange = {
	INCREASE: {
		SLIGHTLY: 'SLIGHTLY',
		SOMEWHAT: 'SOMEWHAT',
		FAIRLY: 'FAIRLY',
		ALOT: 'ALOT',
	},
	NONE: {
		NO_CHANGE: 'NO_CHANGE',
	},
	DECREASE: {
		SLIGHTLY: 'SLIGHTLY',
		SOMEWHAT: 'SOMEWHAT',
		FAIRLY: 'FAIRLY',
		ALOT: 'ALOT',
	},
} as const;
export function mapScoreToConfidenceChange(
	answer: keyof typeof ConfidenceChange,
	rate: number
) {
	if (answer === 'INCREASE') {
		switch (rate) {
			case 0:
				return ConfidenceChange.INCREASE.SLIGHTLY;
			case 1:
				return ConfidenceChange.INCREASE.SOMEWHAT;
			case 2:
				return ConfidenceChange.INCREASE.FAIRLY;
			case 3:
				return ConfidenceChange.INCREASE.ALOT;
		}
	}
	if (answer === 'NONE') {
		return ConfidenceChange.NONE.NO_CHANGE;
	}
	if (answer === 'DECREASE') {
		switch (rate) {
			case 0:
				return ConfidenceChange.DECREASE.SLIGHTLY;
			case 1:
				return ConfidenceChange.DECREASE.SOMEWHAT;
			case 2:
				return ConfidenceChange.DECREASE.FAIRLY;
			case 3:
				return ConfidenceChange.DECREASE.ALOT;
		}
	}
	throw new Error('Unrecognized answer value');
}
