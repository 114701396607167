import React from 'react';
import styled from 'styled-components';
import DefaultAvatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../providers/styleProvider';

interface AvatarProps {
	imageSrc?: string;
	firstName: string;
	lastName: string;
}

const _Avatar = ({
	imageSrc,
	firstName,
	lastName,
}: AvatarProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledAvatarContainer>
			{imageSrc ? (
				<StyledAvatarImage alt={t`web.shared.avatar`} src={imageSrc} />
			) : (
				<DefaultAvatar
					name={`${firstName} ${lastName}`}
					alt={t`web.shared.avatar`}
					size='100%'
					maxInitials={2}
					round={true}
					fgColor={theme.color.avatarText}
				/>
			)}
		</StyledAvatarContainer>
	);
};

export const Avatar = React.memo(_Avatar);

const StyledAvatarContainer = styled.span`
	min-width: 40px;
	min-height: 40px;
	width: 40px;
	height: 40px;
	user-select: none;
	font-size: 40px;
`;

const StyledAvatarImage = styled.img`
	width: 100%;
	border-radius: 50%;
`;
