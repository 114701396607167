import {
	DeepBattery,
	AudienceType,
	Segment,
} from '@lh/eng-platform-battery-service-rest-client';

function anySegments(condition: (seg: Segment) => boolean) {
	return function (battery: DeepBattery): boolean {
		for (const asmt of battery.assessments) {
			for (const seg of asmt.segments) {
				if (condition(seg)) return true;
			}
		}
		return false;
	};
}

function allSegments(condition: (seg: Segment) => boolean) {
	return function (battery: DeepBattery): boolean {
		for (const asmt of battery.assessments) {
			for (const seg of asmt.segments) {
				if (!condition(seg)) return false;
			}
		}
		return true;
	};
}

const hasAudience = (audience: AudienceType) =>
	anySegments((segment) => segment.audienceType === audience);

export const hasAdministratorSegment = hasAudience(AudienceType.Administrator);
export const hasSubjectSegment = hasAudience(AudienceType.Subject);
export const allExpectingMetrics = allSegments(
	(segment) => !!segment.isExpectingMetrics
);
