import styled from 'styled-components';
import { Icon } from '../../shared/designSystem';
import { icons } from '../../../enums/icons';
import { Typography } from '@lh/eng-web-mosaic-common';
import { useTranslation } from 'react-i18next';

export const WarningLabelContent = {
	baseline: {
		line1: 'web.participant.modal.assignmentWarnings.baselineCompleted.line1',
		line2: 'web.participant.modal.assignmentWarnings.baselineCompleted.line2',
		errorDisplay:
			'web.participant.modal.assignmentWarnings.baselineCompleteAlready',
	},
	longitudinal: {
		line1: 'web.participant.modal.assignmentWarnings.longitudinalUnavailable.line1',
		line2: 'web.participant.modal.assignmentWarnings.longitudinalUnavailable.line2',
		errorDisplay:
			'web.participant.modal.assignmentWarnings.baselineNotComplete',
	},
} as const;
export type WarningLabelProps = {
	flavor: keyof typeof WarningLabelContent;
};

export function WarningLabel(props: WarningLabelProps) {
	const { t } = useTranslation();
	const keys = WarningLabelContent[props.flavor];
	return (
		<StyledWarning>
			<div
				data-testid={'warning-label-' + props.flavor}
				style={{ display: 'flex', flexDirection: 'row' }}>
				<Icon icon={icons.WarningOutlined} />
				<StyledTextContainer>
					<Typography.P1 weight='500'>{t(keys.line1)}</Typography.P1>
					<Typography.P2>{t(keys.line2)}</Typography.P2>
				</StyledTextContainer>
			</div>
		</StyledWarning>
	);
}

const StyledWarning = styled.div(
	({ theme }) => `
	background-color: ${theme.color.alertWarningInfo};
  padding: ${theme.spacing.md};
  margin-top: ${theme.spacing.sm};
`
);

const StyledTextContainer = styled.div(
	({ theme }) => `
    display: flex;
    flex-direction: column;
    padding-left: ${theme.spacing.sm};
`
);
