import { t } from 'i18next';

import { BATTERY_TYPE, CONTACT_METHODS } from '../participantHelpers';
import { DropdownOption } from '../../../types';

export const batteryTypeOptions: DropdownOption[] = [
	{
		display: t('web.shared.batteryTypes.clinic'),
		value: BATTERY_TYPE.IN_CLINIC,
	},
	{
		display: t('web.shared.batteryTypes.remote'),
		value: BATTERY_TYPE.REMOTE,
	},
];

export const contactMethods: DropdownOption[] = [
	{
		display: t('web.shared.contactMethods.emailAndPhone'),
		value: CONTACT_METHODS.EMAIL_AND_PHONE,
	},
	{
		display: t('web.shared.contactMethods.email'),
		value: CONTACT_METHODS.EMAIL_ONLY,
	},
	{
		display: t('web.shared.contactMethods.phone'),
		value: CONTACT_METHODS.PHONE_ONLY,
	},
	{
		display: t('web.shared.contactMethods.generateOnly'),
		value: CONTACT_METHODS.NO_SEND,
	},
];
