import { IconProps, flipFunction } from '../flipFunction';

export const IconUnion = ({
	title,
	width = '24',
	height = '24',
	color = '#087DAE',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox={`-6 -12 24 24`}
			xmlns='http://www.w3.org/2000/svg'
			data-testid='IconUnion-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'IconUnion'}</title>
			<rect
				x='14'
				width='2'
				height='14'
				rx='1'
				transform='rotate(90 14 0)'
				fill={color}
			/>
		</svg>
	);
};
