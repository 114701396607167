import { sendEventData } from '../../analytics/amplitude';
import { t } from '../../i18n';
import { AnalyticsAction } from '../../generated/graphql';
import { ExternalLink } from './ExternalLink';

type TermsOfUseLinkProps = {
	termsOfUseUrl?: string;
	text?: string;
	underline?: boolean;
};

const TermsOfUseLink = ({
	termsOfUseUrl = 'https://linushealth.com/terms-of-use',
	text = t`web.shared.terms` as string,
	underline = true,
}: TermsOfUseLinkProps): JSX.Element => {
	return (
		<ExternalLink
			text={text}
			href={termsOfUseUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedTermsOfUse,
				})
			}
			underline={underline}
		/>
	);
};

export { TermsOfUseLink };
