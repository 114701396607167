// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Logo = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 168 50'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Logo-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Logo'}</title>

			<g clipPath='url(#clip0)'>
				<path
					d='M62.5889 2.43739C62.5889 1.50866 63.3243 0.769043 64.2824 0.769043C65.2112 0.769043 65.9466 1.50446 65.9466 2.43739V16.9692H74.6077C75.4566 16.9692 76.1374 17.65 76.1374 18.4989C76.1374 19.3478 75.4566 20.0286 74.6077 20.0286H64.2824C63.3243 20.0286 62.5889 19.2932 62.5889 18.335V2.43739Z'
					fill='#007DAD'
				/>
				<path
					d='M79.2766 2.43739C79.2766 1.50866 80.012 0.769043 80.9702 0.769043C81.8989 0.769043 82.6385 1.50446 82.6385 2.43739V18.4989C82.6385 19.4276 81.9031 20.1673 80.9702 20.1673C80.0162 20.1673 79.2766 19.4276 79.2766 18.4989V2.43739Z'
					fill='#007DAD'
				/>
				<path
					d='M86.6519 2.49202C86.6519 1.56329 87.3873 0.79846 88.3454 0.79846H88.6984C89.5179 0.79846 89.9801 1.21029 90.4466 1.78182L100.007 14.1578V2.40797C100.007 1.50446 100.742 0.769043 101.646 0.769043C102.575 0.769043 103.314 1.50446 103.314 2.40797V18.4695C103.314 19.3982 102.604 20.1336 101.675 20.1336H101.537C100.747 20.1336 100.255 19.726 99.7885 19.1251L89.9549 6.39604V18.5241C89.9549 19.4276 89.2195 20.1631 88.316 20.1631C87.3873 20.1631 86.6519 19.4234 86.6519 18.5241V2.49202Z'
					fill='#007DAD'
				/>
				<path
					d='M106.836 11.9138V2.43739C106.836 1.50866 107.575 0.769043 108.529 0.769043C109.458 0.769043 110.193 1.50446 110.193 2.43739V11.7793C110.193 15.3303 112.026 17.2172 115.03 17.2172C118.01 17.2172 119.838 15.4396 119.838 11.918V2.43739C119.838 1.50866 120.578 0.769043 121.531 0.769043C122.46 0.769043 123.196 1.50446 123.196 2.43739V11.7541C123.196 17.4651 119.972 20.3312 114.972 20.3312C110.004 20.3269 106.836 17.4609 106.836 11.9138Z'
					fill='#007DAD'
				/>
				<path
					d='M126.368 17.898C125.986 17.6248 125.713 17.1626 125.713 16.612C125.713 15.738 126.423 15.053 127.297 15.053C127.764 15.053 128.062 15.1916 128.281 15.3555C129.865 16.612 131.558 17.3223 133.634 17.3223C135.71 17.3223 137.022 16.3389 137.022 14.9185V14.8639C137.022 13.4981 136.257 12.7627 132.706 11.939C128.634 10.9556 126.339 9.75375 126.339 6.22795V6.17752C126.339 2.89966 129.071 0.630371 132.87 0.630371C135.273 0.630371 137.215 1.26073 138.934 2.40798C139.316 2.6265 139.669 3.08877 139.669 3.74854C139.669 4.62264 138.959 5.30763 138.085 5.30763C137.757 5.30763 137.484 5.22358 137.211 5.06389C135.736 4.10574 134.315 3.61407 132.815 3.61407C130.848 3.61407 129.701 4.62684 129.701 5.88335V5.93799C129.701 7.41302 130.575 8.0686 134.265 8.94269C138.307 9.92605 140.383 11.3759 140.383 14.5445V14.5991C140.383 18.1795 137.568 20.3101 133.555 20.3101C130.957 20.3017 128.499 19.4823 126.368 17.898Z'
					fill='#007DAD'
				/>
				<path
					d='M62.5889 32.2743C62.5889 31.3456 63.3243 30.606 64.2824 30.606C65.2112 30.606 65.9466 31.3414 65.9466 32.2743V38.6956H75.07V32.2743C75.07 31.3456 75.8054 30.606 76.7341 30.606C77.6922 30.606 78.4277 31.3414 78.4277 32.2743V48.3359C78.4277 49.2646 77.6922 50.0042 76.7341 50.0042C75.8054 50.0042 75.07 49.2646 75.07 48.3359V41.8054H65.9466V48.3359C65.9466 49.2646 65.2112 50.0042 64.2824 50.0042C63.3243 50.0042 62.5889 49.2646 62.5889 48.3359V32.2743Z'
					fill='#007DAD'
				/>
				<path
					d='M82.2561 48.172V32.4383C82.2561 31.4801 82.9915 30.7447 83.9497 30.7447H95.0692C95.8886 30.7447 96.5736 31.4255 96.5736 32.2491C96.5736 33.0938 95.8928 33.7536 95.0692 33.7536H85.618V38.725H93.8421C94.6615 38.725 95.3423 39.4058 95.3423 40.2547C95.3423 41.0742 94.6615 41.7297 93.8421 41.7297H85.618V46.865H95.2079C96.0273 46.865 96.7081 47.5458 96.7081 48.3695C96.7081 49.2142 96.0273 49.874 95.2079 49.874H83.9497C82.9915 49.8655 82.2561 49.1259 82.2561 48.172Z'
					fill='#007DAD'
				/>
				<path
					d='M99.4649 47.6803L106.567 31.8373C106.949 30.9884 107.634 30.4715 108.588 30.4715H108.752C109.71 30.4715 110.366 30.9926 110.748 31.8373L117.85 47.6803C117.989 47.924 118.044 48.172 118.044 48.3905C118.044 49.294 117.363 50.0042 116.459 50.0042C115.669 50.0042 115.119 49.5378 114.82 48.8276L113.261 45.2513H103.974L102.365 48.941C102.091 49.6218 101.516 50.0084 100.805 50.0084C99.9313 50.0084 99.2463 49.3276 99.2463 48.4493C99.2463 48.1972 99.3262 47.9535 99.4649 47.6803ZM111.975 42.2718L108.613 34.5394L105.252 42.2718H111.975Z'
					fill='#007DAD'
				/>
				<path
					d='M120.716 32.2743C120.716 31.3456 121.452 30.606 122.41 30.606C123.339 30.606 124.074 31.3414 124.074 32.2743V46.8062H132.735C133.584 46.8062 134.265 47.487 134.265 48.3359C134.265 49.1847 133.584 49.8655 132.735 49.8655H122.41C121.452 49.8655 120.716 49.1301 120.716 48.172V32.2743Z'
					fill='#007DAD'
				/>
				<path
					d='M139.043 33.8586H134.399C133.525 33.8586 132.84 33.1484 132.84 32.2996C132.84 31.4549 133.521 30.7405 134.399 30.7405H147.099C147.948 30.7405 148.628 31.4507 148.628 32.2996C148.628 33.1484 147.948 33.8586 147.099 33.8586H142.426V48.3359C142.426 49.2646 141.661 50.0042 140.732 50.0042C139.803 50.0042 139.039 49.2646 139.039 48.3359V33.8586H139.043Z'
					fill='#007DAD'
				/>
				<path
					d='M151.499 32.2743C151.499 31.3456 152.234 30.606 153.192 30.606C154.121 30.606 154.856 31.3414 154.856 32.2743V38.6956H163.98V32.2743C163.98 31.3456 164.719 30.606 165.644 30.606C166.602 30.606 167.338 31.3414 167.338 32.2743V48.3359C167.338 49.2646 166.598 50.0042 165.644 50.0042C164.715 50.0042 163.98 49.2646 163.98 48.3359V41.8054H154.856V48.3359C154.856 49.2646 154.121 50.0042 153.192 50.0042C152.234 50.0042 151.499 49.2646 151.499 48.3359V32.2743Z'
					fill='#007DAD'
				/>
				<path
					d='M50.4817 42.5786C50.4817 46.5583 47.2543 49.7857 43.2746 49.7857H7.8653C3.88564 49.7857 0.658203 46.5583 0.658203 42.5786C0.658203 38.5989 3.88564 35.3715 7.8653 35.3715H43.2746C47.2543 35.3673 50.4817 38.5947 50.4817 42.5786Z'
					fill='#85C559'
				/>
				<path
					d='M7.8653 49.8235C3.88564 49.8235 0.658203 46.5961 0.658203 42.6164V7.2113C0.658203 3.22743 3.88564 0 7.8653 0C11.845 0 15.0724 3.22743 15.0724 7.20709V42.6122C15.0766 46.5961 11.8492 49.8235 7.8653 49.8235Z'
					fill='#007DAD'
				/>
				<path
					d='M0.658203 42.4483V42.7425C0.658203 46.6549 3.8184 49.8235 7.72242 49.8235H8.00818C11.908 49.8235 15.0724 46.6549 15.0724 42.7425V42.4483C15.0724 40.4942 15.0808 35.3673 15.0808 35.3673C15.0808 35.3673 9.96229 35.3673 8.01238 35.3673H7.72662C3.8184 35.3673 0.658203 38.5401 0.658203 42.4483Z'
					fill='#009EA1'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect
						width='166.683'
						height='50'
						fill='white'
						transform='translate(0.658203)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
