import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { first, get } from 'lodash';
import styled from 'styled-components';

import { AssignmentType, useGetAssignment } from 'api/assignment';
import { FeatureType } from 'api/organization';

import { Gender, useGetParticipant } from 'api/participant';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { AnalyticsAction } from '../../../enums/analyticsAction';
import {
	useLazyResolveRelatedResults,
	useResolveBatteryResult,
} from '../../../hooks/batteryResults';
import { useGetOrgEntitlements } from '../../../hooks/useGetOrgEntitlements';
import { deepBatteryDataTransformer } from '../../../providers/globalDataProvider/BatteryDataTransformer';
import { GlobalDataContext } from '../../../providers/globalDataProvider/GlobalDataProvider';
import { unNull } from '../../../utils/unNull';
import { FileNotFound } from '../../FileNotFound';
import { TabbedLayout } from '../../TabbedLayout';
import { LoadingDots } from '../../shared/LoadingDots';
import { mapBatteryName } from '../../shared/breadcrumbs/Breadcrumbs.helpers';
import { BatteryHeader } from '../BatteryHeader';
import { LongitudinalEpsom } from '../Epsom/features/LongitudinalEpsom/LongitudinalEpsom';
import { ReportFooter } from './ReportFooter';
import ReportHeader from './ReportHeader';
import { generateTabs } from './helpers';
import {
	BatteryParser,
	FormattedSegmentResult,
	mergeBatteryWithResult,
	restBatteryToFormatted,
} from './parsingHelper';

const Report = (): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();
	const params = useParams() as { participantId: string; reportId: string };
	const { participantId, reportId: batteryResultId } = params;

	const { orgBatteries: deepBatteries } = useContext(GlobalDataContext);

	const { hasFeatureEntitlement } = useGetOrgEntitlements();

	const showAudioPlayers = useMemo(
		() => hasFeatureEntitlement(FeatureType.ResearchReportAudioPlayers),
		[hasFeatureEntitlement]
	);

	const showAmyloidWidget = useMemo(
		() => hasFeatureEntitlement(FeatureType.LinusAmyloidLikelihood),
		[hasFeatureEntitlement]
	);

	const showVisitType = useMemo(
		() => hasFeatureEntitlement(FeatureType.VisitTypes),
		[hasFeatureEntitlement]
	);

	const { data: participant, isFetching: isLoadingParticipantData } =
		useGetParticipant({
			participantId,
			organizationId: currentUser?.organizationId,
		});

	const { data: restBatteryResult, loading: restBatteryResultLoading } =
		useResolveBatteryResult({
			batteryResultId,
		});

	const [loadRelatedResults, { data: relatedResults }] =
		useLazyResolveRelatedResults(restBatteryResult);

	useEffect(() => {
		if (get(restBatteryResult, 'relatedResults.length')) {
			loadRelatedResults();
		}
	}, [restBatteryResult, loadRelatedResults]);

	const { data: assignment, isFetching: isLoadingAssignmentData } =
		useGetAssignment(restBatteryResult?.assignmentId ?? '');

	useEffect(() => {
		sendEventData({
			eventType: AnalyticsAction.ViewedReport,
			eventProperties: { reportName: 'Cognitive Evaluation' },
		});
	}, []);

	const isLoadingData = useMemo(
		() =>
			isLoadingParticipantData ||
			restBatteryResultLoading ||
			isLoadingAssignmentData,
		[
			isLoadingAssignmentData,
			isLoadingParticipantData,
			restBatteryResultLoading,
		]
	);

	if (
		isLoadingData ||
		(get(restBatteryResult, 'relatedResults.length') &&
			!relatedResults?.length)
	)
		return <LoadingDots />;

	const foundBattery = deepBatteryDataTransformer(
		[restBatteryResult?.batteryId || ''],
		deepBatteries
	).find((brd) => brd.id === restBatteryResult?.batteryId);

	if (!participant || !foundBattery || !restBatteryResult) {
		return <FileNotFound />;
	}

	const batteryResult = mergeBatteryWithResult(
		foundBattery,
		restBatteryResult
	);
	const batteryParser = new BatteryParser(batteryResult);
	const batteryTitleDisplay =
		foundBattery?.displayKey || foundBattery?.name || '';

	if (
		batteryParser.hasLongitudinalEpsomSegment() &&
		batteryParser.isLongEpsomBattery()
	) {
		const formattedRelatedResults = (relatedResults ?? [])
			.map((r) => new BatteryParser(restBatteryToFormatted(r)))
			.filter(
				(b) => b.hasEpsomSegment() || b.hasLongitudinalEpsomSegment()
			)
			.sort((a) => (a.hasEpsomSegment() ? -1 : 1)); //sort baseline to front
		const initialBatteryResultId = first(formattedRelatedResults)?.id || '';
		const formattedRelatedSegments = formattedRelatedResults.map(
			(b) =>
				(b.hasEpsomSegment()
					? b.getEpsomSegment()
					: b.getLongitudinalEpsomSegment()) as FormattedSegmentResult //TS *eye-roll*
		);
		const [baseline, ...longitudinals] = formattedRelatedSegments;
		const current = batteryParser.getLongitudinalEpsomSegment();
		if (!baseline || !current) throw new Error('Something went wrong');
		return (
			<LongitudinalEpsom
				segmentResult={current}
				initialBatteryResultId={initialBatteryResultId}
				relatedResults={{
					baseline,
					longitudinals,
				}}
				batteryResultId={batteryResultId}
				participantId={participantId}
				headerData={{
					visitType: showVisitType
						? restBatteryResult?.visitType ?? ''
						: '',
					externalId: participant?.externalId ?? '',
					birthYear: participant?.birthYear ?? 0,
					gender:
						(participant?.gender as Gender) ??
						Gender.ToBeCollectedAtTimeOfTesting,
				}}
			/>
		);
	} else {
		const generateTabsArgs = {
			batteryResult,
			batteryResultId,
			externalId: participant?.externalId as string,
			assignmentType:
				unNull(assignment?.type as AssignmentType | null | undefined) ||
				AssignmentType.Mobile,
			featureFlags: {
				showAudioPlayback: showAudioPlayers,
				showAmyloidWidget: showAmyloidWidget,
			},
			relatedResults,
		};
		const batteryTabs = generateTabs(generateTabsArgs);
		return (
			<StyledContainer data-testid='reportPage'>
				<ReportHeader />
				<StyledReportInfoContainer>
					<BatteryHeader
						batteryTitle={mapBatteryName(t(batteryTitleDisplay))}
						headerData={{
							externalId: participant?.externalId as string,
							birthYear: participant?.birthYear as number,
							visitType: showVisitType
								? restBatteryResult?.visitType ?? ''
								: undefined,
							gender:
								(participant?.gender as Gender) ||
								Gender.NonDisclosed,
						}}
					/>
					<TabbedLayout tabs={batteryTabs} />
				</StyledReportInfoContainer>
				<ReportFooter
					batteryResultsId={batteryResultId}
					participantId={participantId}
				/>
			</StyledContainer>
		);
	}
};

export { Report };

const StyledContainer = styled.div(
	() => `
	display: flex;
	align-content: center;
	flex-direction: column;
`
);

const StyledReportInfoContainer = styled.div(
	({ theme: { spacing } }) => `
  display: flex;
  flex-direction: column;
	margin-bottom: ${spacing.xl} !important;
	min-width: 834px;
`
);
