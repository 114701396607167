export enum QueryKey {
	Assignment = 'assignment',
	Battery = 'battery',
	DeepBatteryResult = 'battery-result-deep',
	BatteryResults = 'battery-results',
	BatteryResultsByParticipant = 'battery-results-by-participant',
	BatteryResultsByOrg = 'battery-results-by-org',
	BatterySpec = 'battery-spec',
	Batteries = 'batteries',
	CurrentUser = 'current-user',
	Participant = 'participant',
	Participants = 'participants',
	Principal = 'principal',
	Organization = 'organization',
	Questionnaire = 'questionnaire',
	Roles = 'roles',
	RemoteLink = 'remote-link',
	SegmentArtifact = 'segmentArtifact',
	User = 'user',
	Users = 'users',
	UserOrganizations = 'user-organizations',
	ValueList = 'value-list',
	ZendeskUrl = 'zendesk-url',
	AssignmentSendReceipts = 'assignment-send-receipts',
	ParticipantSendReceipts = 'participant-send-receipts',
}

export enum CacheTimes {
	ThirtyMinutes = 1000 * 60 * 30,
	FiveMinutes = 1000 * 60 * 5,
}
