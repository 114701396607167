import { Trans } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';
import { icons } from '../../../enums/icons';
import { Icon } from '../../shared/designSystem/Icon';

export function getExpiredInvitationMessageEnum(theme: DefaultTheme) {
	return {
		value: 'error',
		display: '',
		displayNode: (
			<ErrorContainer>
				<Trans
					i18nKey='web.auth.shared.messages.invalidInvitationError'
					components={[
						<Link
							href='mailto:support@linus.health'
							key={'invitation-error'}></Link>,
					]}
				/>
			</ErrorContainer>
		),
		icon: () => (
			<Icon
				iconHeight={16}
				iconWidth={14}
				icon={icons.AlertShieldSolid}
				color={theme.color.alertError}
			/>
		),
	};
}

const ErrorContainer = styled.div(
	({ theme: { color } }) => `
	color: ${color.formError};
	text-align: left;
	`
);

const Link = styled.a(
	({ theme: { color } }) => `
    color: ${color.link}	;
`
);
