// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const NoResultsDecorative = ({
	title,
	width = '24',
	height = '24',
	color = 'none',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 164 100'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='NoResultsDecorative-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'NoResultsDecorative'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M76 9C74.8954 9 74 9.89543 74 11V14.5L71.1 14.5L66.1909 14.5H57.3545C53.0247 14.5 49.5 16.5469 49.5 20.8425V82.2077C49.5 86.5032 53.0247 90.0001 57.3545 90.0001H106.445C110.775 90.0001 114.3 86.5032 114.3 82.2077V20.8425C114.3 16.5469 110.775 14.5 106.445 14.5H97.6091H95.0274C93.9687 14.5001 92.1295 14.5001 90.7808 14.5001H90.7773L90 14.5V11C90 9.89543 89.1046 9 88 9H76ZM87.5 16.9463V14.5V12C87.5 11.4477 87.0523 11 86.5 11H77.5C76.9477 11 76.5 11.4477 76.5 12V14.5001V16.9463V17H68.5V22.25C68.5 22.3881 68.6119 22.5 68.75 22.5H95.25C95.3881 22.5 95.5 22.3881 95.5 22.25V17H87.5V16.9463ZM66 16.9463V24C66 24.5523 66.4477 25 67 25H97C97.5523 25 98 24.5523 98 24V16.9463H106.445C109 16.9463 112 17.5 112 20.8425V82.2077C112 84.3603 110 87.5 106.445 87.5H57.3545C55.1847 87.5 52 85.5 52 82.2077V20.8425C52 18.5 53.5 16.9463 57.3545 16.9463H66Z'
				fill='#676671'
			/>
			<line
				x1='80.75'
				y1='72.75'
				x2='102.25'
				y2='72.75'
				stroke='#676671'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='80.75'
				y1='54.75'
				x2='102.25'
				y2='54.75'
				stroke='#676671'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='80.75'
				y1='36.75'
				x2='102.25'
				y2='36.75'
				stroke='#676671'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<mask id='path-5-inside-1_321_82621' fill='white'>
				<rect x='59.5' y='30' width='14' height='14' rx='1' />
			</mask>
			<rect
				x='59.5'
				y='30'
				width='14'
				height='14'
				rx='1'
				stroke='#676671'
				stroke-width='5'
				mask='url(#path-5-inside-1_321_82621)'
			/>
			<mask id='path-6-inside-2_321_82621' fill='white'>
				<rect x='59.5' y='48' width='14' height='14' rx='1' />
			</mask>
			<rect
				x='59.5'
				y='48'
				width='14'
				height='14'
				rx='1'
				stroke='#676671'
				stroke-width='5'
				mask='url(#path-6-inside-2_321_82621)'
			/>
			<mask id='path-7-inside-3_321_82621' fill='white'>
				<rect x='59.5' y='66' width='14' height='14' rx='1' />
			</mask>
			<rect
				x='59.5'
				y='66'
				width='14'
				height='14'
				rx='1'
				stroke='#676671'
				stroke-width='5'
				mask='url(#path-7-inside-3_321_82621)'
			/>
			<circle cx='106.5' cy='59' r='17' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M107.45 56.9793C108.164 56.3929 108.347 54.9115 107.665 54.2251L107.659 54.2193C107.411 53.9619 107.069 53.8144 106.576 53.8144C105.696 53.8144 105.231 54.2664 104.937 54.9012C104.688 55.4418 104.024 55.8721 103.341 55.5948C102.821 55.3839 102.478 54.7861 102.711 54.1922C102.963 53.5486 103.342 52.9629 103.838 52.5126C104.189 52.1927 104.595 51.9449 105.052 51.7678C105.524 51.5852 106.051 51.5 106.626 51.5C107.77 51.5 108.732 51.8402 109.449 52.567C110.165 53.2921 110.5 54.2572 110.5 55.403C110.5 56.0325 110.396 56.6069 110.173 57.1142C109.973 57.5845 109.705 57.9965 109.369 58.3439C109.038 58.6849 108.661 58.9596 108.238 59.1655C107.965 59.2987 107.689 59.4061 107.411 59.4869V60.3925C107.411 61.0256 106.898 61.5388 106.265 61.5388C105.632 61.5388 105.119 61.0256 105.119 60.3925V58.6521C105.119 58.0562 105.581 57.6166 106.104 57.5344C106.69 57.4426 107.127 57.2505 107.446 56.9827L107.45 56.9793Z'
				fill='#86C65A'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M105.097 65.028C104.85 64.7256 104.749 64.3524 104.749 63.9557V63.7216C104.749 63.3407 104.852 62.9291 105.108 62.6363C105.404 62.2972 105.831 62.1773 106.273 62.1773C106.71 62.1773 107.139 62.2946 107.427 62.6425C107.688 62.9439 107.797 63.3195 107.797 63.7216V63.9557C107.797 64.3578 107.688 64.7334 107.427 65.0347C107.139 65.3827 106.71 65.5 106.273 65.5C105.847 65.5 105.377 65.3713 105.097 65.028Z'
				fill='#86C65A'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M106.371 73.2417C109.994 73.2417 113.305 71.9005 115.833 69.6875L124.158 78.0129C124.646 78.5011 125.438 78.5011 125.926 78.0129C126.414 77.5248 126.414 76.7333 125.926 76.2451L117.565 67.8839C119.552 65.4189 120.742 62.2838 120.742 58.8708C120.742 50.934 114.308 44.5 106.371 44.5C98.434 44.5 92 50.934 92 58.8708C92 66.8076 98.434 73.2417 106.371 73.2417ZM106.371 70.7417C112.927 70.7417 118.242 65.4269 118.242 58.8708C118.242 52.3148 112.927 47 106.371 47C99.8148 47 94.5 52.3148 94.5 58.8708C94.5 65.4269 99.8148 70.7417 106.371 70.7417Z'
				fill='#86C65A'
			/>
			<path
				d='M35.8003 71.3115C28.1974 78.837 28.417 79.7702 38.5254 82.8137C28.417 79.7695 27.7485 80.4325 30.2498 91C27.7485 80.4318 26.8548 80.1645 19.2519 87.69C26.8548 80.1645 26.636 79.2313 16.5254 76.1929C26.6352 79.232 27.3045 78.569 24.8038 68C27.3037 78.5697 28.1974 78.8377 35.8003 71.3115Z'
				fill='#B1E5FB'
			/>
			<path
				d='M26.5 18.615C33.3559 21.0883 34.9405 20.4292 37.5747 14C34.9405 20.4286 35.643 21.9107 42.5 24.3829C35.643 21.9107 34.0573 22.5719 31.4241 29C34.0578 22.5724 33.3559 21.0883 26.5 18.615Z'
				fill='#C3E3AC'
			/>
			<path
				d='M117.799 3C124.828 12.0013 127.501 12.3299 136.5 5.2987C127.5 12.3299 127.174 15 134.201 24C127.174 14.9987 124.499 14.6701 115.5 21.7013C124.5 14.6701 124.828 12.0013 117.799 3Z'
				fill='#B1E5FB'
			/>
			<line
				x1='43.25'
				y1='55.25'
				x2='1.75'
				y2='55.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='43.25'
				y1='61.25'
				x2='19.75'
				y2='61.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='162.25'
				y1='40.25'
				x2='120.75'
				y2='40.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
			<line
				x1='145.25'
				y1='34.25'
				x2='121.75'
				y2='34.25'
				stroke='#EEF8FF'
				stroke-width='2.5'
				stroke-linecap='round'
			/>
		</svg>
	);
};
