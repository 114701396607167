import { enumeration } from './enumeration';

export type PillarItem = {
	value: string;
	display: string;
	secondLine?: string;
};

export type _Pillar = {
	BuildYourVillage: PillarItem;
	StayTrueToPurpose: PillarItem;
	BeMindful: PillarItem;
	KeepHealthInCheck: PillarItem;
	EatGreenAndLean: PillarItem;
	MoveEveryDay: PillarItem;
	LearnSomethingNew: PillarItem;
	GetGoodRest: PillarItem;
	NonModifiableRiskFactor: PillarItem;
};

export const Pillar = enumeration<_Pillar>({
	BuildYourVillage: {
		value: 'BuildYourVillage',
		display: 'web.report.basicactionplan.BuildYourVillage.title',
	},
	StayTrueToPurpose: {
		value: 'StayTrueToPurpose',
		display: 'web.report.basicactionplan.StayTrueToPurpose.title',
	},
	BeMindful: {
		value: 'BeMindful',
		display: 'web.report.basicactionplan.BeMindful.title',
	},
	KeepHealthInCheck: {
		value: 'KeepHealthInCheck',
		display: 'web.report.basicactionplan.KeepHealthInCheck.title',
	},
	EatGreenAndLean: {
		value: 'EatGreenAndLean',
		display: 'web.report.basicactionplan.EatGreenAndLean.title',
	},
	MoveEveryDay: {
		value: 'MoveEveryDay',
		display: 'web.report.basicactionplan.MoveEveryDay.title',
	},
	LearnSomethingNew: {
		value: 'LearnSomethingNew',
		display: 'web.report.basicactionplan.LearnSomethingNew.title',
	},
	GetGoodRest: {
		value: 'GetGoodRest',
		display: 'web.report.basicactionplan.GetGoodRest.title',
	},

	NonModifiableRiskFactor: {
		value: 'NonModifiableRiskFactor',
		display: 'web.report.basicactionplan.NonModifiableRiskFactor.title',
	},
});
