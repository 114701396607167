import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { MediaQueries } from '@lh/eng-web-mosaic-common';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { Pillar } from 'enums/Pillar';

import { ActionPlanPillarPieColumn } from '../ActionPlanPillarPieColumn';
import { ProcessedLifestyleFactors } from '../ActionPlan/actionPlanLogic';

type ActionPlanPillarPieProps = {
	headerTag?: string;
	lhqData?: ProcessedLifestyleFactors;
	width?: string;
	height?: string;
	longLineWidth?: string;
	shortLineWidth?: string;
	fontSize?: string;
	subtitleFontSize?: string;
	iconSize?: number;
	pieSize?: string;
};

export const ActionPlanPillarPie = ({
	headerTag,
	lhqData,
	width,
	height,
	longLineWidth,
	shortLineWidth,
	fontSize,
	subtitleFontSize,
	pieSize,
}: ActionPlanPillarPieProps): JSX.Element => (
	<StyledContainer width={width}>
		{headerTag && (
			<StyledHeader>
				<Trans>{headerTag}</Trans>
			</StyledHeader>
		)}
		<StyledBodyPadding>
			<StyledBody width={width} height={height}>
				<StyledLeftColumn>
					<ActionPlanPillarPieColumn
						pillars={[
							Pillar.GetGoodRest,
							Pillar.MoveEveryDay,
							Pillar.StayTrueToPurpose,
							Pillar.BuildYourVillage,
						]}
						focusAreas={lhqData}
						fontSize={fontSize}
						subtitleFontSize={subtitleFontSize}
						longLineWidth={longLineWidth || '90px'}
						shortLineWidth={shortLineWidth || '35px'}
						reverse={true}
					/>
				</StyledLeftColumn>
				<StyledCenter pieSize={pieSize}>
					<Icon icon={icons.ActionPlanPieChart} />
				</StyledCenter>
				<StyledRightColumn>
					<ActionPlanPillarPieColumn
						pillars={[
							Pillar.EatGreenAndLean,
							Pillar.BeMindful,
							Pillar.LearnSomethingNew,
							Pillar.KeepHealthInCheck,
						]}
						focusAreas={lhqData}
						fontSize={fontSize}
						subtitleFontSize={subtitleFontSize}
						longLineWidth={longLineWidth || '90px'}
						shortLineWidth={shortLineWidth || '35px'}
						reverse={false}
					/>
				</StyledRightColumn>
			</StyledBody>
		</StyledBodyPadding>
	</StyledContainer>
);

const StyledContainer = styled.div<StyledBodyProps>(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: column;
	gap:${spacing.sm};
`
);
const StyledHeader = styled.div(
	({ theme: { weight, color, spacing } }) => `
	color:${color.white};
	background-color: ${color.pillarStatusHeader};
	border-bottom-right-radius: 20px;
	height: ${spacing.xl};
	font-weight: ${weight.semi};
	align-items: center;
	display:flex;
	padding:0 ${spacing.md};
	width: fit-content;
	`
);

type StyledBodyProps = { width?: string; height?: string };
const StyledBody = styled.div<StyledBodyProps>(
	({ width, height }) => `
	width: ${width || '565px'};
	height: ${height || '168px'};
	display: flex;
	`
);
const StyledLeftColumn = styled.div`
	flex: 1;
	margin-right: -5%;
`;
type StyledCenterProps = { pieSize?: string };
const StyledCenter = styled.div<StyledCenterProps>(
	({ pieSize }) => `
	width: ${pieSize || '168px'};
	height: ${pieSize || '168px'};
	position:relative;
`
);
const StyledRightColumn = styled.div`
	height: 100%;
	position: relative;
	flex: 1;
	margin-left: -7%;
`;
const StyledBodyPadding = styled.div`
	display: flex;
	justify-content: center;

	@media ${MediaQueries.isBrowser} {
		@media ${MediaQueries.tablet} {
			transform: scale(0.8);
			max-width: 100%;
		}

		@media ${MediaQueries.mobile} {
			transform: scale(0.6);
		}

		@media ${MediaQueries.smmobile} {
			transform: scale(0.3);
		}
	}
`;
