import styled from 'styled-components';
import { icons } from '../../../../../../../enums/icons';
import { LinusTooltip } from '../../../../../../shared/LinusTooltip';
import { Icon } from '../../../../../../shared/designSystem';

type CategoryDescriptionTooltipProps = {
	tooltipText: string;
};

function CategoryDescriptionTooltip(
	props: Readonly<CategoryDescriptionTooltipProps>
) {
	return (
		<StyledTooltipContainer data-testid='category-name-tooltip-icon'>
			<LinusTooltip
				placement='bottomLeft'
				tooltipIcon={
					<span>
						<Icon
							data-testid='info-outline-icon'
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					</span>
				}
				trigger={'hover'}
				overlay={
					<StyledTooltipText>{props?.tooltipText}</StyledTooltipText>
				}></LinusTooltip>
		</StyledTooltipContainer>
	);
}

export { CategoryDescriptionTooltip };

const StyledTooltipContainer = styled.div`
	margin-left: 3px;
`;

const StyledTooltipText = styled.div`
	max-width: 330px;
	text-align: left;
	padding: 8px;
`;
