import { useApolloClient } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
	OrganizationSettings,
	UserContext,
	ValueListEntries,
	nulloUser,
} from '../../context/UserContext';
import { vlEnumeration } from '../../enums/enumeration';
import { locale } from '../../enums/locale';
import { SessionStorageEnum } from '../../enums/sessionStorageKeysEnum';
import { useAuthServiceContext } from '../../features/auth-service';
import {
	FeatureType,
	OrganizationQuery,
	useCurrentUserLazyQuery,
	useOrganizationLazyQuery,
} from '../../generated/graphql';
import { CurrentUser } from '../../types';
import {
	clearSessionStorage,
	setSessionStorage,
} from '../../utils/sessionStorage';
import { participantLanguageToPatientDisplayTransform } from '../../utils/valueListUtils';

const clearImpersonatedOrgSession = () =>
	clearSessionStorage(SessionStorageEnum.ImpersonatedOrg);

export const UserContextProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const client = useApolloClient();
	const auth = useAuthServiceContext();

	const [loggedIn, setLoggedIn] = useState(true);
	const [currentUser, setCurrentUser] = useState<CurrentUser>(nulloUser);
	const [orgLoaded, setOrgLoaded] = useState(false);
	const [isImpersonation, setIsImpersonation] = useState(false);

	const [getCurrentUser] = useCurrentUserLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			const _user = data?.currentUser?.currentUser;
			if (_user) {
				setCurrentUser((state) => ({
					...state,
					..._user,
				}));
			}
		},
		onError: () => setLoggedIn(false),
	});

	const onOrganizationQueryCompleted = (data?: OrganizationQuery) => {
		if (!data || !data.organization) return;
		const orgPreferences = data.organization.preferences;

		const organizationValueLists = orgPreferences.valueLists.reduce(
			(acc: ValueListEntries, v) => {
				acc[v.type] = vlEnumeration(v.items.nodes);
				return acc;
			},
			{} as ValueListEntries
		);

		const organizationParticipantLanguages = vlEnumeration(
			orgPreferences.participantLanguages,
			participantLanguageToPatientDisplayTransform
		);
		const orgSettings: OrganizationSettings = {
			organizationId: data.organization.id,
			organizationType: data.organization.type,
			organizationStatus: data.organization.status,
			organizationName: data.organization.name,
			organizationDateFormat: orgPreferences.dateFormat,
			organizationDefaultUserLocale:
				locale.fromDisplay(orgPreferences.defaultUserLocale) ||
				locale.EnUs,
			organizationDefaultTimezone: orgPreferences.defaultTimezone || '',
			organizationParticipantLanguages,
			organizationValueLists,
			organizationFeatures: orgPreferences.features as FeatureType[],
		};
		setCurrentUser({ ...currentUser, ...orgSettings });
		setOrgLoaded(true);
		if (isImpersonation) {
			setIsImpersonation(false);
			setSessionStorage(
				{
					organizationId: currentUser.organizationId,
					operations: currentUser.operations,
				},
				SessionStorageEnum.ImpersonatedOrg
			);
		}
	};

	const [getOrganizationQuery] = useOrganizationLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: onOrganizationQueryCompleted,
		onError: console.error, //FIXME: We will want to handle this case properly ... eventually.
	});

	const onLogin = useCallback((_user: CurrentUser) => {
		setLoggedIn(true);
		setCurrentUser((state) => ({ ...state, ..._user }));
	}, []);

	const logout = useCallback(async () => {
		try {
			await auth.logout();
			setLoggedIn(false);
			setCurrentUser(nulloUser);
			await client.clearStore();
			clearImpersonatedOrgSession();
		} catch (error) {
			console.error('error signing out: ', error);
		}
	}, [auth, client]);

	const updateUser = useCallback(
		(user: CurrentUser, isImpersonation?: boolean) => {
			setCurrentUser(user);
			if (isImpersonation) {
				setSessionStorage(
					{
						organizationId: user.organizationId,
						operations: user.operations,
					},
					SessionStorageEnum.ImpersonatedOrg
				);
				setIsImpersonation(true);
			}
		},
		[setCurrentUser, setIsImpersonation]
	);

	const clearImpersonation = useCallback(() => {
		if (currentUser.originalOrganization) {
			const currentUserWithOriginalOrg = {
				...currentUser,
				organizationId:
					currentUser?.originalOrganization.organizationId,
				originalOrganization: undefined,
			};
			setCurrentUser(currentUserWithOriginalOrg);
			clearImpersonatedOrgSession();
		}
	}, [currentUser, setCurrentUser]);

	const refetchCurrentUser = useCallback(() => {
		getCurrentUser({
			variables: {
				orgId: currentUser.organizationId,
			},
		});
	}, [currentUser.organizationId, getCurrentUser]);

	useEffect(() => refetchCurrentUser(), [refetchCurrentUser]);

	useEffect(() => {
		if (currentUser.organizationId) {
			getOrganizationQuery({
				variables: {
					orgId: currentUser.organizationId,
				},
			});
		}
	}, [currentUser.organizationId, getOrganizationQuery]);

	if (loggedIn && (!orgLoaded || !currentUser)) {
		return null;
	}

	return (
		<UserContext.Provider
			value={{
				currentUser,
				setCurrentUser: updateUser,
				updateCurrentUser: getCurrentUser,
				onLogin,
				logout,
				clearImpersonation,
				refetchCurrentUser,
				isLoggedIn: loggedIn,
			}}>
			{children}
		</UserContext.Provider>
	);
};
