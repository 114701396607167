import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IndividualScore, ScoreBgColorPair, ScoreType } from '../types';
import ScoringWidgetContent from './components/ScoringWidgetContent';
import ScoringWidgetLink from './components/ScoringWidgetLink';
import { WidgetHeader } from '../../../DigitalClockAndRecall(DCR)/components/shared/WidgetHeader';

type ScoringWidgetComponentProps = {
	isUnanalyzable?: boolean;
	header?: {
		titleText: string;
		infoTooltipText?: string;
	};
	content: {
		overallScore: {
			highlightColor: ScoreBgColorPair | undefined;
			value: ScoreType;
		};
		breakdown: {
			scoreIndicatorColor: string | undefined;
			breakdownSummary: React.ReactNode;
			scores: IndividualScore[] | [];
		};
		additionalScore?: {
			component: () => ReactElement | null;
		};
		link?: {
			text: string;
			onClick: () => void;
		};
	};
};

const ScoringWidget = (props: ScoringWidgetComponentProps) => {
	return (
		<Container>
			{props?.header ? (
				<WidgetHeader
					titleText={props?.header?.titleText}
					infoTooltipText={props?.header?.infoTooltipText}
				/>
			) : null}
			<StyledSeparator />
			<ScoringWidgetContent
				isUnanalyzable={props?.isUnanalyzable}
				overallScore={props?.content?.overallScore}
				breakdown={props?.content?.breakdown}
				additionalScoreComponent={
					props?.content?.additionalScore?.component
				}
			/>
			{props?.content?.link ? (
				<ScoringWidgetLink
					onClick={props?.content?.link?.onClick}
					text={props?.content?.link?.text}
				/>
			) : null}
		</Container>
	);
};

export default ScoringWidget;

const Container = styled.div(
	({ theme: { spacing } }) => `
	padding: ${spacing.md};
`
);

const StyledSeparator = styled.div(
	({ theme: { color, spacing } }) => `
	position: relative;
	width: 100%;
	border-bottom: 1px solid ${color.infoSeparator};
	margin-bottom: ${spacing.sm};
	margin-top: ${spacing.sm};
`
);
