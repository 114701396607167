// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const GetGoodRestLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='GetGoodRestLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'GetGoodRestLHQ'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M23.46 12.4805H24.6893C25.1576 12.4805 25.5283 12.8707 25.5088 13.339C25.5088 13.8073 25.1381 14.178 24.6698 14.178H21.782C21.4503 14.178 21.1576 14.0024 21.021 13.7098C20.8844 13.4366 20.9039 13.0854 21.0991 12.8317L23.0112 10.1976H21.782C21.3137 10.1976 20.943 9.82683 20.943 9.35854C20.943 8.89024 21.3137 8.5 21.782 8.5H24.6893C25.0015 8.5 25.2942 8.69512 25.4503 8.96829C25.5869 9.24146 25.5673 9.59268 25.3722 9.84634L23.46 12.4805ZM21.5086 17.944C21.2354 17.7879 20.9037 17.8465 20.6695 18.0611C19.5573 19.1928 17.9964 19.7391 16.4159 19.5635C14.0744 19.2904 12.1817 17.3977 11.9281 15.0757C11.7525 13.4757 12.2988 11.9343 13.4305 10.8221C13.6647 10.6074 13.7037 10.2562 13.5476 9.98305C13.3915 9.70987 13.0793 9.57329 12.7866 9.65134C9.23539 10.4708 6.81588 13.7294 7.011 17.3587C7.20612 21.144 10.3281 24.2855 14.1329 24.4806C14.2012 24.4806 14.2695 24.4855 14.3377 24.4904L14.3378 24.4904L14.3379 24.4904C14.4062 24.4952 14.4744 24.5001 14.5427 24.5001C18.0159 24.5001 21.0598 22.1196 21.8793 18.7245C21.9183 18.3928 21.7817 18.1001 21.5086 17.944ZM15.9675 12.8708H17.1968L15.2846 15.5049C15.0895 15.7586 15.07 16.1098 15.2066 16.3829C15.3431 16.6756 15.6358 16.8512 15.9675 16.8512H18.8553C19.3236 16.8512 19.6944 16.4805 19.6944 16.0122C19.6944 15.5439 19.3236 15.1732 18.8553 15.1732H17.6261L19.5383 12.539C19.7334 12.2854 19.7529 11.9342 19.6163 11.661C19.4797 11.3683 19.187 11.1927 18.8553 11.1927H15.9675C15.4992 11.1927 15.1285 11.5634 15.1285 12.0317C15.1285 12.5 15.4992 12.8708 15.9675 12.8708Z'
				fill={color}
			/>
		</svg>
	);
};
