// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Skip10sAhead = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Skip10sAhead-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Skip10sAhead'}</title>
			<path
				d='M16 26.6668C13.1508 26.6668 10.4721 25.5573 8.45765 23.5427C6.4429 21.528 5.33337 18.8491 5.33337 16.0004C5.33337 13.1516 6.4429 10.4725 8.45748 8.45802C10.4722 6.44327 13.1506 5.33374 15.9998 5.33374C19.4881 5.33374 22.8868 7.03583 24.8696 9.77546C25.1353 10.1426 25.0532 10.6556 24.6859 10.9212C24.3188 11.1868 23.8058 11.1046 23.5402 10.7375C21.8606 8.41641 18.971 6.97466 16 6.97466C11.0234 6.97466 6.9746 11.0234 6.9746 16.0001C6.9746 20.9768 11.0235 25.0255 16 25.0255C20.9767 25.0255 25.0254 20.9766 25.0254 16.0001C25.0254 15.5469 25.3927 15.1795 25.846 15.1795C26.2991 15.1795 26.6665 15.5468 26.6665 16.0001C26.6665 18.8491 25.557 21.5279 23.5424 23.5424C21.5277 25.5572 18.8489 26.6667 16.0001 26.6667L16 26.6668Z'
				fill='#676671'
			/>
			<path
				d='M24.2051 11.0771H20.1024C19.6493 11.0771 19.2819 10.7098 19.2819 10.2565C19.2819 9.80339 19.6491 9.43596 20.1024 9.43596H23.3845V6.15406C23.3845 5.70092 23.7518 5.3335 24.2051 5.3335C24.6582 5.3335 25.0256 5.70077 25.0256 6.15406V10.2565C25.0255 10.7098 24.6582 11.0771 24.2051 11.0771Z'
				fill='#676671'
			/>
			<path
				d='M10.272 20.333V19.3303H12.3627V13.7623H12.2774L10.5494 15.6077L9.81337 14.9463L11.712 12.8877H13.5467V19.3303H15.36V20.333H10.272ZM19.2625 20.461C18.8003 20.461 18.3985 20.3757 18.0572 20.205C17.7159 20.0272 17.435 19.7748 17.2145 19.4477C16.9941 19.1206 16.8305 18.7188 16.7239 18.2423C16.6172 17.7659 16.5639 17.2219 16.5639 16.6103C16.5639 16.0059 16.6172 15.4655 16.7239 14.989C16.8305 14.5055 16.9941 14.1001 17.2145 13.773C17.435 13.4459 17.7159 13.197 18.0572 13.0263C18.3985 12.8486 18.8003 12.7597 19.2625 12.7597C20.187 12.7597 20.8661 13.0975 21.2999 13.773C21.7408 14.4486 21.9612 15.3943 21.9612 16.6103C21.9612 17.8263 21.7408 18.7721 21.2999 19.4477C20.8661 20.1232 20.187 20.461 19.2625 20.461ZM19.2625 19.437C19.5185 19.437 19.7354 19.3872 19.9132 19.2877C20.0981 19.181 20.2474 19.0317 20.3612 18.8397C20.475 18.6477 20.5568 18.4201 20.6065 18.157C20.6563 17.8868 20.6812 17.5881 20.6812 17.261V15.9597C20.6812 15.6326 20.6563 15.3375 20.6065 15.0743C20.5568 14.8041 20.475 14.573 20.3612 14.381C20.2474 14.189 20.0981 14.0432 19.9132 13.9437C19.7354 13.837 19.5185 13.7837 19.2625 13.7837C19.0065 13.7837 18.7861 13.837 18.6012 13.9437C18.4234 14.0432 18.2777 14.189 18.1639 14.381C18.0501 14.573 17.9683 14.8041 17.9185 15.0743C17.8688 15.3375 17.8439 15.6326 17.8439 15.9597V17.261C17.8439 17.5881 17.8688 17.8868 17.9185 18.157C17.9683 18.4201 18.0501 18.6477 18.1639 18.8397C18.2777 19.0317 18.4234 19.181 18.6012 19.2877C18.7861 19.3872 19.0065 19.437 19.2625 19.437Z'
				fill='#676671'
			/>
		</svg>
	);
};
