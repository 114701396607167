import {
	Organization,
	PaginatedOrganization,
	UserOrganizationsRole,
	ValueListItem,
	ValueListType,
} from '@lh/eng-platform-organization-service-rest-client';
import { create } from 'zustand';

export type ValueListContainer = {
	[key in ValueListType]?: ValueListItem[];
};

interface State {
	allUserOrganizations: PaginatedOrganization[] | null;
	setAllUserOrganizations: (orgs: PaginatedOrganization[]) => void;

	currentOrganizationId: string | null;
	setCurrentOrganizationId: (orgId: string) => void;

	currentOrganization: Organization | null;
	setCurrentOrganization: (org: Organization) => void;

	valueListItems: ValueListContainer | null;
	setValueListItems: (
		valueListType: ValueListType,
		items: ValueListItem[]
	) => void;

	role: UserOrganizationsRole | null;
	setRole: (orgRole: UserOrganizationsRole | undefined) => void;

	primaryOrganizationId: string | null;
	setPrimaryOrganizationId: (id: string) => void;

	reset: () => void;
}

export const useOrganizationStore = create<State>((set) => ({
	allUserOrganizations: null,
	setAllUserOrganizations: (orgs) => set({ allUserOrganizations: orgs }),

	currentOrganizationId: null,
	setCurrentOrganizationId: (orgId) => set({ currentOrganizationId: orgId }),

	// TODO May not need this, we'll see
	currentOrganization: null,
	setCurrentOrganization: (org) => set({ currentOrganization: org }),

	valueListItems: null,
	setValueListItems: (
		valueListType: ValueListType,
		items: ValueListItem[]
	) => {
		const newItem = { [valueListType]: items };
		set((state) => ({
			valueListItems: { ...state.valueListItems, ...newItem },
		}));
	},

	role: null,
	setRole: (role) => set({ role }),

	primaryOrganizationId: null,
	setPrimaryOrganizationId: (id) => set({ primaryOrganizationId: id }),

	reset: () =>
		set({
			allUserOrganizations: null,
			currentOrganizationId: null,
			currentOrganization: null,
			valueListItems: null,
			role: null,
		}),
}));
