import styled, { useTheme } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { icons } from '../../../../enums/icons';
import { Icon, P1 } from '../../../../components/shared/designSystem';

type ConcernsProps = {
	concerns: string[];
};
const Concerns = ({ concerns }: ConcernsProps): JSX.Element => {
	const { t } = useTranslation();
	const theme = useTheme();
	return (
		<StyledConcerns>
			<StyledConcernsIcon>
				<Icon
					icon={icons.AlertSolid}
					title={t`web.report.concerns.icon.title`}
					color={theme.color.graphOrange}
				/>
			</StyledConcernsIcon>
			<StyledConcernsText>
				<StyledConcernsTitle>
					<Trans i18nKey={`web.report.concerns.content.title`} />
				</StyledConcernsTitle>
				{concerns.map((concern, idx) => {
					return (
						<StyledConcernsList key={concern}>
							{idx === concerns.length - 1 ? (
								<Trans i18nKey={concern} />
							) : (
								<>
									<Trans i18nKey={concern} /> {', '}
								</>
							)}
						</StyledConcernsList>
					);
				})}
			</StyledConcernsText>
		</StyledConcerns>
	);
};

const StyledConcerns = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0;
`;

const StyledConcernsText = styled(P1)``;
const StyledConcernsIcon = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1px;
	margin-right: 10px;
	align-self: flex-start;
`;

const StyledConcernsTitle = styled.span(
	({ theme: { color } }) => `
	color: ${color.textAlert};
	margin-right: 5px;
`
);

const StyledConcernsList = styled.span(
	({ theme: { color } }) => `
	color: ${color.graphTextSecondary};
    white-space: pre-wrap;
`
);

export { Concerns };
