import titleize from 'titleize';
import { EnumItem } from '../enums/enumeration';
import {
	ValueList,
	ValueListConnection,
	ValueListItem,
	ValueListType,
} from '../generated/graphql';
import { DropdownOption } from '../types';

export const humanizeValue = (value: string): string => {
	return value
		.split('_')
		.map((v) => titleize(v))
		.join(' ');
};

export type ValueListRollup = {
	[key in ValueListType]: DropdownOption[];
};

export const inlineSuffix = (suffixes: ValueListItem[] = []): string => {
	const joinedSuffixes = suffixes.map((suffix) => suffix.display).join(', ');
	// A list of joined suffixes should be prepended will a leading comma e.g. ', RN, MD, PHD'.
	// Resulting in a display of "Bubba Jones, RN, MD, PHD"
	return joinedSuffixes.length > 0 ? `, ${joinedSuffixes}` : '';
};

export const rollupValueLists = (nodes: ValueList[]): ValueListRollup => {
	return (nodes || []).reduce((acc, vl) => {
		const collectionType = acc[vl.type];
		const dropdownOption = {
			value: vl.id,
			display: vl.name || humanizeValue(vl.type),
		};
		if (collectionType) {
			collectionType.push(dropdownOption);
		} else {
			acc[vl.type] = [dropdownOption];
		}
		return acc;
	}, {} as ValueListRollup);
};

export const participantLanguageToOrgDisplayTransform = (
	v: ValueListItem
): EnumItem => {
	return {
		...v,
		value: v.id,
		key: v.value,
	} as EnumItem;
};

export const getValueListItemsFromConnection = (
	valueLists: ValueListConnection,
	valueListType: ValueListType
): ValueListItem[] => {
	const list = (valueLists?.nodes || []).find(
		(list: ValueList) => list.type === valueListType
	);
	return !list ? [] : list.items.nodes;
};

export const participantLanguageToPatientDisplayTransform = (
	v: ValueListItem
): EnumItem => {
	return {
		value: v.value?.split('|')[0] || '',
		key: v.displayKey || v.display || '',
		display: v.displayKey || v.display,
		longDisplay: v.longDisplay || v.display,
	} as EnumItem;
};
