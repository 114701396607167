// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const TraditionalArrowUp = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 12 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='TraditionalArrowUp-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'TraditionalArrowUp'}</title>

			<path
				d='M10.8317 6.72392C11.0269 6.91918 11.3435 6.91918 11.5388 6.72392C11.734 6.52866 11.734 6.21207 11.5388 6.01681L6.3701 0.848129C6.27866 0.747998 6.14705 0.685181 6.00077 0.685181C6.00065 0.685181 6.00089 0.68518 6.00077 0.685181C5.87269 0.685086 5.74421 0.733902 5.64649 0.831627L0.461303 6.01681C0.266041 6.21207 0.266041 6.52866 0.461302 6.72392C0.656565 6.91918 0.973147 6.91918 1.16841 6.72392L5.50077 2.39156L5.50077 14.5185C5.50077 14.7947 5.72463 15.0185 6.00077 15.0185C6.27692 15.0185 6.50077 14.7947 6.50077 14.5185L6.50077 2.39302L10.8317 6.72392Z'
				fill='current'
			/>
		</svg>
	);
};
