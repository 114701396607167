// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CameraPlusOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 19 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CameraPlusOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CameraPlusOutlined'}</title>

			<path
				d='M17.1 0.600006C17.1 0.323864 16.8761 0.100006 16.6 0.100006C16.3239 0.100006 16.1 0.323864 16.1 0.600006V2.00001H14.7C14.4239 2.00001 14.2 2.22386 14.2 2.50001C14.2 2.77615 14.4239 3.00001 14.7 3.00001H16.1V4.40001C16.1 4.67615 16.3239 4.90001 16.6 4.90001C16.8761 4.90001 17.1 4.67615 17.1 4.40001V3.00001H18.5C18.7761 3.00001 19 2.77615 19 2.50001C19 2.22386 18.7761 2.00001 18.5 2.00001H17.1V0.600006Z'
				fill='current'
			/>
			<path
				d='M16.5 10C16.7761 10 17 9.77614 17 9.5C17 9.22386 16.7761 9 16.5 9C16.2239 9 16 9.22386 16 9.5C16 9.77614 16.2239 10 16.5 10Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.54877 10.3658C8.61931 10.3658 7.86584 11.1193 7.86584 12.0488C7.86584 12.9782 8.61931 13.7317 9.54877 13.7317C10.4782 13.7317 11.2317 12.9782 11.2317 12.0488C11.2317 11.1193 10.4782 10.3658 9.54877 10.3658ZM8.86584 12.0488C8.86584 11.6716 9.1716 11.3658 9.54877 11.3658C9.92593 11.3658 10.2317 11.6716 10.2317 12.0488C10.2317 12.4259 9.92593 12.7317 9.54877 12.7317C9.1716 12.7317 8.86584 12.4259 8.86584 12.0488Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.54876 8C7.31269 8 5.5 9.81269 5.5 12.0488C5.5 14.2848 7.31269 16.0975 9.54876 16.0975C11.7848 16.0975 13.5975 14.2848 13.5975 12.0488C13.5975 9.81269 11.7848 8 9.54876 8ZM6.5 12.0488C6.5 10.365 7.86498 9 9.54876 9C11.2325 9 12.5975 10.365 12.5975 12.0488C12.5975 13.7325 11.2325 15.0975 9.54876 15.0975C7.86498 15.0975 6.5 13.7325 6.5 12.0488Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.0333 4H6.96667L4.87667 6.54545H0.95C0.425329 6.54545 0 6.97282 0 7.5V17.0455C0 17.5726 0.425329 18 0.95 18H18.05C18.5747 18 19 17.5726 19 17.0455V7.5C19 6.97282 18.5747 6.54545 18.05 6.54545H14.1233L12.0333 4ZM13.3505 7.18003C13.5404 7.41137 13.824 7.54545 14.1233 7.54545H18V17H1V7.54545H4.87667C5.17599 7.54545 5.45958 7.41137 5.64953 7.18003L7.43949 5H11.5605L13.3505 7.18003Z'
				fill='current'
			/>
		</svg>
	);
};
