import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';

export async function getAssignment(assignmentId: string) {
	if (!isNonEmptyString(assignmentId)) {
		throw new Error("useGetAssignment: assignmentId can't be null");
	}
	const batteryService = await getBatteryService();
	const { data } = await batteryService.getAssignment({ assignmentId });
	return data;
}

export function useGetAssignment(assignmentId: string) {
	return useQuery({
		enabled: isNonEmptyString(assignmentId),
		meta: {
			errorMessage: `Error fetching assignment ${assignmentId}`,
		},
		queryKey: [QueryKey.Assignment, assignmentId],
		queryFn: () => getAssignment(assignmentId),
	});
}
