// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const LogoSmall = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 161 173'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='LogoSmall-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'LogoSmall'}</title>

			<path
				d='M160.362 147.427C160.362 161.279 149.411 172.41 135.784 172.41H24.5774C10.9503 172.41 0 161.279 0 147.427C0 133.575 10.9503 122.443 24.5774 122.443H135.784C149.411 122.443 160.362 133.575 160.362 147.427Z'
				fill='#86C65A'
			/>
			<path
				d='M24.5774 172.41C10.9503 172.41 0 161.279 0 147.427V24.9834C0 11.1312 10.9503 0 24.5774 0C38.2045 0 49.1549 11.1312 49.1549 24.9834V147.427C49.1549 161.279 38.2045 172.41 24.5774 172.41Z'
				fill='#087DAE'
			/>
			<path
				d='M0 146.932V147.922C0 161.526 10.707 172.41 24.0908 172.41H25.0641C38.4479 172.41 49.1549 161.526 49.1549 147.922V146.932C49.1549 140.253 49.1549 122.443 49.1549 122.443C49.1549 122.443 31.6343 122.443 25.0641 122.443H24.0908C10.707 122.443 0 133.327 0 146.932Z'
				fill='#009EA1'
			/>
		</svg>
	);
};
