import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Field, useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { icons } from '../../../../enums/icons';
import { DropdownOption, LinusInputChildrenProps } from '../../../../types';
import { Icon } from '../../designSystem/Icon';
import { listVariants } from '../utils/listVariants';
import { definedProps } from './definedProps';

const Dropdown = <T,>(props: LinusInputChildrenProps<T>): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, _, helpers] = useField(props.name);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const { t } = useTranslation();
	const { setValue } = helpers;
	const dropdownSelectField = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		if (isOpen) {
			document.addEventListener('keyup', handleKeyPress, false);
		}
		return () => {
			// eslint-disable-next-line no-use-before-define
			document.removeEventListener('keyup', handleKeyPress, false);
		};
	});

	useEffect(() => {
		const onBlurEvent = (e: MouseEvent) => {
			const clickTarget = (e.target as HTMLElement).nodeName;
			const clickName = (e.target as HTMLElement).getAttribute('name');
			const isNotListElement =
				clickTarget !== 'LI' && clickTarget !== 'UL';
			const isBlurClick =
				isOpen && isNotListElement && clickName !== props.name;
			if (isBlurClick) {
				setIsOpen(false);
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', onBlurEvent);
		}
		return () => document.removeEventListener('mousedown', onBlurEvent);
	}, [isOpen, props.name]);

	//TODO: consider refactor this rule selection. See: https://linushealth.atlassian.net/browse/WEB-1279

	//Animations must be in the component here to access props
	const listTransition = {
		type: 'spring',
		ease: 'anticipate',
		duration: 0.25,
	};

	const definedProps_ = definedProps(props);
	const dropdownOptions = definedProps_.dropdownOptions as DropdownOption[];
	const selectedOption = (dropdownOptions || []).find(
		(x) => x.value === props.value
	);
	const display =
		definedProps_.preferDisplayLength === 'long'
			? 'longDisplay'
			: 'display';

	const handleKeyPress = (e: KeyboardEvent) => {
		switch (e.key) {
			case 'Enter': {
				if (isOpen && selectedIndex > -1) {
					const val = dropdownOptions[selectedIndex].value;
					handleSelect(val);
					setIsOpen(false);
				}
				break;
			}
			case 'Escape':
				setIsOpen(false);
				break;
			case 'ArrowLeft':
			case 'ArrowUp':
				setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0);
				break;
			case 'ArrowRight':
			case 'ArrowDown':
				setSelectedIndex(
					selectedIndex < dropdownOptions.length - 1
						? selectedIndex + 1
						: dropdownOptions.length - 1
				);
				break;
			default:
				break;
		}
	};

	const handleClick = () => {
		definedProps_.disabled ? setIsOpen(false) : setIsOpen(!isOpen);
	};

	const handleSelect = (value: string) => {
		if (value === props.value) {
			setValue('');
		} else {
			setValue(value);
		}
	};

	const renderOptions = () => {
		return (dropdownOptions || []).map(
			(option: DropdownOption, idx: number) => (
				<StyledOption
					data-testid={option.display}
					key={option.value}
					selected={
						option.value === props.value || selectedIndex === idx
					}
					onClick={() => handleSelect(option.value)}>
					{option[display]}
				</StyledOption>
			)
		);
	};

	/**
	 * Checks if the dropdown element exists and calculates if its bottom position is greater than
	 * two-thirds of the viewport height.
	 * @returns {boolean} True if the dropdown should open upwards, false otherwise.
	 */
	const openUpwards = useCallback(() => {
		if (!dropdownSelectField.current) return false;
		return (
			dropdownSelectField.current.getBoundingClientRect()?.bottom >
			(2 * window.innerHeight) / 3
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const dropUpSpace = useMemo(
		() => (openUpwards() ? '8px' : undefined),
		[openUpwards]
	);

	const getPlaceholder = () => {
		if (selectedOption) {
			//selected is local state holding current selected option (ui)
			return selectedOption[display];
		} else {
			//this grabs placeholder prop
			return definedProps_.placeholder || definedProps_.label;
		}
	};

	return (
		<StyledSelectField
			{...definedProps_} // { ...definedProps} overwrites anything above it, so its gotta be first in the list
			ref={dropdownSelectField}
			onChange={field.onChange}
			onBlur={field.onBlur}
			data-testid={`${props.name}_dropdown`}
			as='div'
			onClick={handleClick}>
			<StyledPlaceholder>
				{getPlaceholder() as React.ReactNode}
			</StyledPlaceholder>
			<AnimatePresence>
				{isOpen && (
					<StyledList
						initial='initial'
						animate='in'
						exit='out'
						variants={listVariants(dropUpSpace)}
						transition={listTransition}
						data-testid={props.name + '_options'}>
						{renderOptions()}
					</StyledList>
				)}
			</AnimatePresence>
			<StyledArrow>
				{isOpen ? (
					<Icon
						icon={icons.ArrowUp}
						title={t`web.shared.forms.closeDropdown`}
					/>
				) : (
					<Icon
						icon={icons.ArrowDown}
						title={t`web.shared.forms.openDropdown`}
					/>
				)}
			</StyledArrow>
		</StyledSelectField>
	);
};

export { Dropdown };

const StyledSelectField = styled(Field)(
	({ value, error, disabled, theme: { color } }) => `
	position: relative;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	background: ${disabled ? color.formDisabledBg : color.white};
	box-sizing: border-box;
	padding: 0 23px;
	color: ${disabled ? color.formTextDisabled : color.formText};
	font-size: 16px;
	transition: 0.2s ease all;
	border: 1px solid ${error ? color.formError : color.inputBorder};
	padding-top: ${value ? '16px' : '0px'};
	justify-content: space-between;
	white-space: nowrap;

	&:hover {
		border: 1px solid ${disabled ? color.inputBorder : color.inputHover};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
	}

	&:focus {
		border: 1px solid ${color.inputHover};
		outline: none;
	}
	`
);

const StyledPlaceholder = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledList = styled(motion.ul)(
	({ theme: { boxShadow, spacing, color } }) => `
	position: absolute;
	top: calc((100% + 32px) * -1);
	left: -1px;
	padding: ${spacing.sm};
	width: calc(100% + 2px);
	max-height: 204px;
	background: white;
	border: 1px solid ${color.inputFocus};
	border-radius: 10px;
	box-shadow: ${boxShadow.standard};
	overflow: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		-webkit-appearance: none;
		margin: ${spacing.sm} 0;
		background: ${color.formDisabledBg};
		border-radius: 10px;
		box-shadow: inset ${boxShadow.standard};
	}
	&::-webkit-scrollbar-thumb {
		-webkit-appearance: none;
		background: ${color.dropdownItemBorder};
		border-radius: 10px;
	}
`
);
type StyledOptionProps = {
	selected: boolean;
};

const StyledOption = styled.li<StyledOptionProps>(
	({ selected, theme: { color, spacing } }) => `
	padding: ${spacing.sm};
	border-bottom: 1px solid ${color.dropdownItemBorder};
	background: ${selected ? color.kebabLinkHover : 'auto'};
	white-space: normal;

	&:hover {
		background: ${color.kebabLinkHover};
	}

	&:last-of-type {
		border-bottom: none;
	}
`
);

const StyledArrow = styled.div`
	justify-self: flex-end;
	transition: 0.15s ease all;
`;
