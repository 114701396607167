import { SimpleGrid, Tooltip } from '@mantine/core';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { gender } from '../../../enums/participantEnums/gender';
import { Gender } from '../../../generated/graphql';
import { theme } from '../../../providers/styleProvider';
import { StyledCard } from '../../shared/StyledCard';
import { H1, H4, P2 } from '../../shared/designSystem';

type BatteryHeaderProps = {
	batteryTitle: string;
	renderActionButton?: () => JSX.Element;
	headerData: {
		externalId: string;
		birthYear: number;
		gender: Gender;
		visitType?: string;
	};
	width?: string;
};

const batteryHeaderTestIdPrefix = 'battery-header';

export const BatteryHeader = (props: BatteryHeaderProps): JSX.Element => {
	const { batteryTitle, headerData, width, renderActionButton } = props;
	const gridColumnsCount = Object.keys(headerData).length + 1; // make room for an extra column to space columns well
	const participantId = headerData?.externalId;
	const yob = headerData?.birthYear;
	const participantGender =
		gender.fromValue(headerData?.gender)?.display || 'Unknown';
	const visitType = headerData?.visitType;

	return (
		<StyledContainer width={width}>
			<StyledHeaderContainer>
				<StyledTitle
					data-testid={`${batteryHeaderTestIdPrefix}-battery-title`}>
					<StyledHeader>{`${batteryTitle}`}</StyledHeader>
				</StyledTitle>
				{renderActionButton?.()}
			</StyledHeaderContainer>
			<div
				style={{
					marginLeft: theme.spacing.lg,
					marginTop: theme.spacing.md,
				}}>
				<SimpleGrid cols={gridColumnsCount}>
					{/* Participant ID */}
					<StyledTextContainer>
						<StyledTextTitle
							data-testid={`${batteryHeaderTestIdPrefix}-participant-title`}>
							<Trans i18nKey='web.participant.value' />
						</StyledTextTitle>
						<StyledTextContent
							data-testid={`${batteryHeaderTestIdPrefix}-participant-value`}>
							{participantId}
						</StyledTextContent>
					</StyledTextContainer>
					{/* Year of Birth */}
					<StyledTextContainer>
						<StyledTextTitle
							data-testid={`${batteryHeaderTestIdPrefix}-yearOfBirth-title`}>
							<Trans i18nKey='research.participantModal.yearOfBirth' />
						</StyledTextTitle>
						<StyledTextContent
							data-testid={`${batteryHeaderTestIdPrefix}-yearOfBirth-value`}>
							{yob}
						</StyledTextContent>
					</StyledTextContainer>
					{/* Gender */}
					<StyledTextContainer>
						<StyledTextTitle
							data-testid={`${batteryHeaderTestIdPrefix}-gender-title`}>
							<Trans i18nKey='web.report.cognitiveEvaluation.batteryHeader.genderLabel' />
						</StyledTextTitle>
						<StyledTextContent
							data-testid={`${batteryHeaderTestIdPrefix}-gender-value`}>
							<Trans i18nKey={participantGender} />
						</StyledTextContent>
					</StyledTextContainer>
					{/* Visit Type (Optional) */}
					{visitType && (
						<StyledTextContainer>
							<StyledTextTitle
								data-testid={`${batteryHeaderTestIdPrefix}-visitType-title`}>
								<Trans i18nKey='web.visitTypes.visitType' />
							</StyledTextTitle>
							<Tooltip label={visitType}>
								<StyledTextContent
									data-testid={`${batteryHeaderTestIdPrefix}-visitType-value`}>
									{visitType}
								</StyledTextContent>
							</Tooltip>
						</StyledTextContainer>
					)}
				</SimpleGrid>
			</div>
		</StyledContainer>
	);
};

type StyledContainerProps = {
	width?: string;
};

const StyledContainer = styled(StyledCard)<StyledContainerProps>(
	({ theme: { spacing }, width }) => `
		height: 220px;
		width: ${width || '100%'};
		margin-bottom: ${spacing.xl};
	`
);
const StyledHeaderContainer = styled.div(
	({ theme: { spacing } }) => `
		height: 100px;
		display: flex;
		align-items: center;
		margin: 0 ${spacing.lg};
		margin-top: ${spacing.md};
		justify-content: space-between;
	`
);
const StyledTitle = styled.div`
	display: flex;
`;

const StyledHeader = styled(H1)(
	({ theme: { color, spacing, weight } }) => `
		font-weight: ${weight.light};
		padding-left:${spacing.sm};
		border-left: 4px solid ${color.reportTitleBar}
	`
);

const StyledTextContainer = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: column;
		width: 200px;
		margin-right ${spacing.xl};
	`
);
const StyledTextTitle = styled(P2)(
	({ theme: { spacing, color } }) => `
		color: ${color.textBlue};
		margin-bottom: ${spacing.sm};
	`
);
const StyledTextContent = styled(H4)(
	({ theme: { color, weight, fontSize } }) => `
		color: ${color.bodyText};
		font-weight: ${weight.medium};
		font-size: ${fontSize._18};
		word-break: break-all;
		white-space: nowrap;
        overflow: hidden;
		text-overflow: ellipsis;
	`
);
