import styled from 'styled-components';

import { BinaryArtifactData } from '../../../components/shared/hooks/useGetReportData';
import { AudioCardProps } from '../common/AudioCard/AudioCard';

const buildAudioCardData = (
	binaryData: BinaryArtifactData[]
): AudioCardProps[] => {
	return binaryData.map((data) => {
		return {
			audio: { source: data?.source, fileType: data?.fileType },
			text: {
				cardHeading: 'Complex Picture Description',
				cardSubHeadings: ['Image below was shown for 60 seconds'],
			},
			renderCardContent: () => (
				<>
					<StyledImage
						src='/img/candyTheftDog.png'
						alt={'todo-translate'}
					/>
				</>
			),
		};
	});
};

const StyledImage = styled.img(
	({ theme: { spacing } }) => `
  padding: ${spacing.sm};
  width: 425px;
`
);

const mapDataToProps = (
	audioData?: BinaryArtifactData[] | null | undefined
) => {
	if (!audioData)
		return {
			audioCardData: [],
		};
	return {
		audioCardData: buildAudioCardData(audioData),
	};
};

export { mapDataToProps };
