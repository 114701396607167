// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CheckmarkOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckmarkOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CheckmarkOutlined'}</title>

			<path
				d='M13.8817 6.32297C14.0601 6.11217 14.0338 5.79668 13.823 5.61831C13.6122 5.43994 13.2967 5.46623 13.1183 5.67703L7.99754 11.7288L5.37963 8.67461C5.19992 8.46494 4.88427 8.44066 4.6746 8.62037C4.46494 8.80009 4.44066 9.11573 4.62037 9.3254L7.62037 12.8254C7.71572 12.9366 7.85507 13.0005 8.00159 13C8.14811 12.9995 8.28705 12.9348 8.38169 12.823L13.8817 6.32297Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
				fill='current'
			/>
		</svg>
	);
};
