export type ParticipantStrings = {
	participantAdded: string;
	participantAddedSuccess: string;
	participantFinishAssignment: string;
	participantBatteryAssigned: string;
	participantBatteryAssignedRemote: string;
	addBatteryModalAssign: string;
	Participants: string;
	participantModalTitleAdd: string;
	participantSearchBarPlaceholder: string;
	participant: string;
	participants: string;
	participantModalTitleEdit: string;
	filterPlaceHolder: string;
	newParticipantHeader: string;
	addBatteryHeader: string;
};

export const participantStrings: ParticipantStrings = {
	participantAdded: 'web.participant.modal.title.added',
	participantAddedSuccess: 'web.participant.modal.addedSuccess',
	participantFinishAssignment: 'web.participant.modal.finishAssignment',
	participantBatteryAssigned: 'web.participant.modal.batteryAssigned',
	participantBatteryAssignedRemote:
		'web.participant.modal.batteryAssignedRemote',
	addBatteryModalAssign: '',
	Participants: 'web.participant.plural',
	participantModalTitleAdd: 'web.participant.modal.title.add',
	participantSearchBarPlaceholder: 'web.participant.externalId',
	participant: 'web.participant.value',
	participants: 'web.participant.plural',
	participantModalTitleEdit: 'web.participant.modal.title.edit',
	filterPlaceHolder: 'research.participantsTable.searchLabel',
	newParticipantHeader: 'web.participant.modal.newParticipantHeader',
	addBatteryHeader: 'web.participant.modal.addBatteryHeader',
};
