// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const StayTrueToPurposeTapped = ({
	title,
	width = '24',
	height = '24',
	color = '#A4D483',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='StayTrueToPurposeTapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'StayTrueToPurposeTapped'}</title>
			<rect
				x='0.5'
				width='32'
				height='32'
				rx='16'
				fill={color || '#A4D483'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.7857 7.42857C17.7857 8.21755 17.1461 8.85714 16.3572 8.85714C15.5682 8.85714 14.9286 8.21755 14.9286 7.42857C14.9286 6.63959 15.5682 6 16.3572 6C17.1461 6 17.7857 6.63959 17.7857 7.42857ZM13.8572 11.7143L12.4286 8.14289C12.4286 8.14289 14.5714 9.57146 16.3572 9.57146C18.1429 9.57146 20.2857 8.14289 20.2857 8.14289L18.8572 11.7143H13.8572ZM20.2857 12.4286V13.1428H12.4286V12.4286H20.2857ZM13.1429 22.4285H19.5714L18.8571 13.8571H13.8571L13.1429 22.4285ZM11 24.5714H21.7143V26H11V24.5714ZM20.2857 23.1429H12.4286V23.8572H20.2857V23.1429Z'
				fill='white'
			/>
		</svg>
	);
};
