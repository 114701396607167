import { useCallback, useMemo } from 'react';
import {
	CategoryPattern,
	EpsomDataWithoutScores,
} from '../../../../../../../types/epsomTypes';
import CategoryRow from './CategoryRow';

type AnswersGroupedByCategoryProps = {
	groupedAnswers?: EpsomDataWithoutScores;
};

function AnswersGroupedByCategory(
	props: Readonly<AnswersGroupedByCategoryProps>
) {
	const groupedAnswers = useMemo(
		() => props?.groupedAnswers ?? null,
		[props]
	);

	const renderAnswersByCategory = useCallback(() => {
		if (groupedAnswers) {
			const answerRowsForAllCategories = [];
			const totalRowsToDisplay = Object.keys(groupedAnswers)?.length;
			for (let rowIndex = 0; rowIndex < totalRowsToDisplay; rowIndex++) {
				const categoryPattern: CategoryPattern =
					Object.keys(groupedAnswers)[rowIndex];

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const listOfAnswers = (groupedAnswers as any)[categoryPattern];

				answerRowsForAllCategories.push(
					<CategoryRow
						key={`${categoryPattern}-${rowIndex}`}
						categoryPattern={categoryPattern}
						listOfAnswers={listOfAnswers}
						isDividerVisible={rowIndex !== totalRowsToDisplay - 1}
					/>
				);
			}
			return answerRowsForAllCategories;
		}
	}, [groupedAnswers]);

	return (
		<div data-testid={'participant-responses-answers-container'}>
			{renderAnswersByCategory()}
		</div>
	);
}

export { AnswersGroupedByCategory };
