import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../context/UserContext';
import { ERROR } from 'logging/linusLogger';

type QueryProviderProps = {
	children: React.ReactNode;
};

export function QueryProvider({ children }: QueryProviderProps) {
	const { isLoggedIn, logout } = useContext(UserContext);

	const [client] = useState(
		new QueryClient({
			queryCache: new QueryCache({
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				onError: (error, query) => {
					ERROR(error.message);

					if (error.message === 'Unauthorized' && isLoggedIn) {
						ERROR('Unauthorized/Session expired', error);
						logout();
					}
					// TODO: Handle other errors
				},
			}),
			mutationCache: new MutationCache({
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				onError: (error, _, __, mutation) => {
					ERROR(error.message);

					if (error.message === 'Unauthorized' && isLoggedIn) {
						ERROR('Unauthorized/Session expired', error);
						logout();
					}

					// TODO: Handle other errors
				},
			}),
		})
	);

	return (
		<QueryClientProvider client={client}>{children}</QueryClientProvider>
	);
}
