// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CheckboxSideCheck = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 16 13'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckboxSideCheck-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CheckboxSideCheck'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.8334 0.111357C16.0318 0.279237 16.0565 0.576167 15.8886 0.77457L6.14471 12.2901C6.05563 12.3954 5.92487 12.4563 5.78697 12.4567C5.64907 12.4572 5.51792 12.3971 5.42817 12.2924L0.113299 6.09172C-0.0558402 5.89439 -0.0329878 5.59731 0.164342 5.42817C0.361671 5.25903 0.658753 5.28189 0.827893 5.47922L5.78316 11.2604L15.1702 0.166625C15.338 -0.0317777 15.635 -0.0565219 15.8334 0.111357Z'
				fill='current'
			/>
		</svg>
	);
};
