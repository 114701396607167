// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const InviteOutlined = ({
	title,
	width = 18,
	height = 18,
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='InviteOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'InviteOutlined'}</title>

			<path
				d='M10.8055 8.09866C11.0546 8.09866 11.2566 7.89669 11.2566 7.64756C11.2566 7.39842 11.0546 7.19646 10.8055 7.19646H9.45218V5.84316C9.45218 5.59402 9.25022 5.39206 9.00108 5.39206C8.75195 5.39206 8.54998 5.59402 8.54998 5.84316V7.19646H7.19668C6.94754 7.19646 6.74558 7.39842 6.74558 7.64756C6.74558 7.89669 6.94754 8.09866 7.19668 8.09866H8.54998V9.45196C8.54998 9.70109 8.75195 9.90306 9.00108 9.90306C9.25022 9.90306 9.45218 9.70109 9.45218 9.45196V8.09866H10.8055Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 7.51982V17.5646C18.0005 17.6218 17.9898 17.6786 17.9683 17.7316C17.9468 17.7847 17.915 17.8329 17.8747 17.8736C17.8344 17.9143 17.7865 17.9465 17.7337 17.9686C17.6808 17.9906 17.6242 18.002 17.5669 18.0019H0.433057C0.318204 18.0019 0.208054 17.9563 0.12684 17.8751C0.0456264 17.7939 5.09282e-07 17.6837 5.09282e-07 17.5689V7.52199C-9.2099e-05 7.4616 0.0124463 7.40186 0.0368109 7.3466L0.0498025 7.32278C0.0592034 7.30323 0.0700605 7.28441 0.0822818 7.26649L0.103934 7.2405L0.158066 7.18637L2.46842 5.27443V2.50503C2.46842 2.39018 2.51405 2.28003 2.59526 2.19882C2.67647 2.1176 2.78662 2.07198 2.90148 2.07198H6.33778L8.73041 0.099408C8.80807 0.0351553 8.9057 0 9.00649 0C9.10728 0 9.20491 0.0351553 9.28256 0.099408L11.6644 2.07198H15.1007C15.2155 2.07198 15.3257 2.1176 15.4069 2.19882C15.4881 2.28003 15.5337 2.39018 15.5337 2.50503V5.27443L17.8419 7.18421L17.896 7.23834L17.9177 7.26432C17.9299 7.28225 17.9408 7.30107 17.9502 7.32062L17.9632 7.34444C17.9875 7.39969 18.0001 7.45943 18 7.51982ZM15.5337 8.49637L16.8675 7.5025L15.5337 6.40254V8.49637ZM10.3002 2.07198L9.00107 0.989338L7.7019 2.07198H10.3002ZM14.6676 9.14162V2.93809H3.33453V9.14162L9.00107 13.3661L14.6676 9.14162ZM2.46842 8.5007V6.40254L1.13461 7.50683L2.46842 8.5007ZM0.866113 8.38161V17.1358L17.1339 17.1315V8.38161L15.4406 9.64396C15.392 9.70736 15.3268 9.75607 15.2522 9.78471L9.25874 14.2538C9.18398 14.3096 9.09323 14.3396 8.99999 14.3396C8.90675 14.3396 8.816 14.3096 8.74124 14.2538L2.74991 9.7912C2.67533 9.76256 2.61014 9.71385 2.56153 9.65046L0.866113 8.38161Z'
				fill='current'
			/>
		</svg>
	);
};
