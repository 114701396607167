import { TableData } from '../../../../../schemas/table/participantDetailsSchema';

interface Props {
	results: TableData[];
}

export enum ResultsConst {
	DCR = 'DCR',
	DAC = 'DAC', // This was formally rDAC
}

export function getBreakdownProps({ results }: Readonly<Props>) {
	let dcrResultId: string | undefined;
	let dcrParticipantId: string | undefined;
	let dacResultId: string | undefined;
	let dacParticipantId: string | undefined;

	results
		.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
		.forEach((result) => {
			if (result.battery.includes(ResultsConst.DCR)) {
				dcrResultId = result.id;
				dcrParticipantId = result.participantId;
			}

			if (result.battery.includes(ResultsConst.DAC)) {
				dacResultId = result.id;
				dacParticipantId = result.participantId;
			}
		});

	return {
		dcrResultId,
		dcrParticipantId,
		dacResultId,
		dacParticipantId,
	};
}
