import { Field, useField } from 'formik';
import styled from 'styled-components';
import { LinusInputChildrenProps } from '../../../../types';
import { definedProps } from './definedProps';

export const PhoneNumber = <T,>(
	props: LinusInputChildrenProps<T>
): JSX.Element => {
	const [field] = useField(props.name);

	const _definedProps = definedProps(props);
	const placeholder = props.placeholder || props.label;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		field.onChange(e);
	};

	return (
		<Container>
			<StyledField
				{..._definedProps}
				onChange={handleOnChange}
				onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
					field.onBlur(e);
				}}
				placeholder={placeholder}
			/>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	border-radius: 6px;
`;

const StyledField = styled(Field)(
	({ value, error, disabled, theme: { color } }) => `
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: ${disabled ? color.formDisabledBg : color.white};
    box-sizing: border-box;
    color: ${color.formText};
    font-size: 16px;
    transition: 0.2s ease all;
    border: 1px solid ${error ? color.formError : color.inputBorder};
    padding: ${value ? '16px' : '0px'} 23px 0 23px;

    &::placeholder {
        color: ${disabled ? color.formTextDisabled : color.formText};
    }

    &:hover {
        border: 1px solid ${disabled ? color.inputBorder : color.inputHover};
        cursor: ${disabled ? 'not-allowed' : 'text'};
    }

    &:focus {
        border: 1px solid ${color.inputFocus};
        outline: none;
    }
    `
);
