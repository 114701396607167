export enum Json {
	ClockJSON = 'clock.json',
	SemanticFluencyJSON = 'semantic-fluency.json',
	PhonemicFluencyJSON = 'phonemic-fluency.json',
	BackwardsDigitSpanJSON = 'backwards-digit-span.json',
	ImmediateRecall6 = 'immediate-recall.json',
	DelayedRecall6 = 'delayed-recall.json',
	ComplexPictureDescription = 'complex-picture-description.json',
	DelayedRecognition = 'delayed-recognition.json',
	CustomQuestionnaire = 'answers.json',
}

export enum Png {
	CommandPNG = 'final-drawing-command-clock.png',
	CopyPNG = 'final-drawing-copy-clock.png',
}

export enum Mp4 {
	ImmediateMP4 = 'response.mp4',
	DelayedMP4 = 'response.mp4',
	PhonemicFluency = 'phonemic_fluency_audio.mp4', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-phonemic-fluency-api/src/state/consts.ts#L5C33-L5C55
	SemanticFluency = 'semantic_fluency_audio.mp4', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-semantic-fluency-api/src/state/consts.ts#L5C33-L5C55
	ImmediateRecall6_1 = 'immediate-recall-audio-1.mp4', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	ImmediateRecall6_2 = 'immediate-recall-audio-2.mp4', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	DelayedRecall6 = 'delayed-recall-audio.mp4', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-delayed-recall-api/src/contract.ts
	ComplexPictureDescription = 'complex-picture-description-audio.mp4',
}

export enum Wav {
	PhonemicFluency = 'phonemic_fluency_audio.wav', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-phonemic-fluency-api/src/state/consts.ts#L5C33-L5C55
	SemanticFluency = 'semantic_fluency_audio.wav', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-semantic-fluency-api/src/state/consts.ts#L5C33-L5C55
	ImmediateRecall6_1 = 'immediate-recall-audio-1.wav', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	ImmediateRecall6_2 = 'immediate-recall-audio-2.wav', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	DelayedRecall6 = 'delayed-recall-audio.wav', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-delayed-recall-api/src/contract.ts
	ComplexPictureDescription = 'complex-picture-description-audio.wav',
}

export enum Webm {
	PhonemicFluency = 'phonemic_fluency_audio.webm', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-phonemic-fluency-api/src/state/consts.ts#L5C33-L5C55
	SemanticFluency = 'semantic_fluency_audio.webm', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-semantic-fluency-api/src/state/consts.ts#L5C33-L5C55
	ImmediateRecall6_1 = 'immediate-recall-audio-1.webm', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	ImmediateRecall6_2 = 'immediate-recall-audio-2.webm', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-immediate-recall-api/src/contract.ts
	DelayedRecall6 = 'delayed-recall-audio.webm', // https://github.com/linushealth/eng-common-assessments/blob/main/libraries/segment-delayed-recall-api/src/contract.ts
	ComplexPictureDescription = 'complex-picture-description-audio.webm',
}
