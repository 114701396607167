import { useTranslation } from 'react-i18next';
import { H4 } from '../../../../../../../shared/designSystem';
import { theme } from '../../../../../../../../providers/styleProvider';

function ConfidenceScoreHeader() {
	const { t } = useTranslation();
	const confidenceScoreHeadingText = t(
		'research.epsomConfidenceScore.headingText'
	);

	return (
		<H4
			data-testid={'confidence-score-card-header-text'}
			style={{ fontWeight: theme.weight.medium }}>
			{confidenceScoreHeadingText}
		</H4>
	);
}

export { ConfidenceScoreHeader };
