import { Flex, Space } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';

import { icons } from '../../../enums/icons';
import { Icon, P1 } from '../../shared/designSystem';
import { participantStrings } from '../participantStrings';

export type AddBatteryForNewParticipantHeaderProps = {
	hasRemoteAssessmentFlag?: boolean;
};

const AddBatteryForNewParticipantHeader = ({
	hasRemoteAssessmentFlag,
}: AddBatteryForNewParticipantHeaderProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<>
			<StyledHeader
				data-testid='styled-header'
				style={{ width: hasRemoteAssessmentFlag ? '648px' : '548px' }}>
				<Flex direction={'row'}>
					<StyledTitleIcon>
						<Icon
							icon={icons.CheckmarkSolid}
							width={40}
							height={40}
							iconWidth={24}
							iconHeight={24}
							title={t`web.patients.forms.addBattery`}
							color={theme.colors.green}
						/>
					</StyledTitleIcon>
					<Typography.H2 weight='500'>
						<Trans
							i18nKey={participantStrings['participantAdded']}
						/>
					</Typography.H2>
				</Flex>
				<Space h='sm' />
				{hasRemoteAssessmentFlag ? (
					<StyledSubTitle>
						{t(participantStrings['newParticipantHeader'])}
					</StyledSubTitle>
				) : (
					<StyledSubTitle>
						<Trans
							i18nKey={
								hasRemoteAssessmentFlag
									? participantStrings['newParticipantHeader']
									: participantStrings[
											'participantFinishAssignment'
									  ]
							}
						/>
					</StyledSubTitle>
				)}
			</StyledHeader>
			<Space h='sm' />
		</>
	);
};

export { AddBatteryForNewParticipantHeader };

const StyledHeader = styled.div`
	width: 100%;
`;

const StyledTitleIcon = styled.div(
	({ theme: { spacing } }) => `
		padding-right:${spacing.md};
		align-self: center;
`
);

const StyledSubTitle = styled(P1)(
	({ theme: { spacing } }) => `
	padding-top: ${spacing.sm};
	margin: 0 0 0 0;
`
);
