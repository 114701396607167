/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { UserOnlyContext } from '../UserProvider/UserOnlyContext';
import { isEmpty } from 'lodash';
import { ERROR } from 'logging/linusLogger';
import { useOrganizationStore } from 'store';
import {
	OrganizationType,
	ValueListItem,
} from '@lh/eng-platform-organization-service-rest-client';
import { getValueListItems, useGetOrganization } from 'api/organization';
import { QueryKey } from 'api/query';
import { useQueryClient } from '@tanstack/react-query';
import { OrganizationSessionContext } from './OrganizationContext';
import { getSessionStorage } from 'utils/sessionStorage';
import { SessionStorageEnum } from 'enums/sessionStorageKeysEnum';

export const RemoteOrgSessionProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const { user, logout } = useContext(UserOnlyContext);
	const orgStore = useOrganizationStore();
	const queryClient = useQueryClient();
	const record = getSessionStorage(SessionStorageEnum.ImpersonatedOrg);

	/**
	 * Logout checks.
	 *
	 * First block is for determining if the user has a research org available to continue logging into.
	 *
	 * Second block is for determining if the role in the selected research org is allowed to be logged into.
	 *
	 * */
	useEffect(() => {
		if (isEmpty(user)) return;
		if (isEmpty(record)) return;

		const orgId: string = (record?.organizationId as string) ?? '';

		// Linus Role logout check
		const roleOfValidOrg = user.roles.find(
			(r) => r.organizationType === OrganizationType.Linus
		);

		orgStore.setAllUserOrganizations([]); // We don't need all orgs when impersonating
		orgStore.setRole(roleOfValidOrg);
		orgStore.setCurrentOrganizationId(orgId);
	}, [user, logout]);

	/**
	 * API call to get the Organization details we're logging into
	 * */

	const {
		data: _deepOrg,
		error: _deepOrgError,
		refetch: _refetchOrg,
	} = useGetOrganization(orgStore.currentOrganizationId);
	useEffect(() => {
		if (_deepOrgError) {
			ERROR('Error fetching current organization ', _deepOrgError);
			return;
		}

		if (isEmpty(_deepOrg)) return;
		orgStore.setCurrentOrganization(_deepOrg);
	}, [_deepOrg, _deepOrgError]);

	/**
	 * API call to get all preference value lists for the org we're apart of
	 * */
	useEffect(() => {
		if (isEmpty(orgStore.currentOrganization)) return;
		// TODO: Maybe explicitly reset stored VLIs here?

		const valueListQueries =
			orgStore.currentOrganization?.preferences.valueLists.map((vl) => {
				return queryClient
					.fetchQuery({
						queryKey: [QueryKey.ValueList, { id: vl.id }],
						queryFn: async () => getValueListItems(vl.id),
					})
					.then((res) => {
						orgStore.setValueListItems(
							vl.type,
							res as ValueListItem[]
						);
					})
					.catch((err) => {
						ERROR('Error fetching value list items', err);
					});
			});

		Promise.allSettled(valueListQueries);
	}, [orgStore.currentOrganization, queryClient]);

	const setActiveOrg = useCallback(
		async (orgId: string) => {
			orgStore.setCurrentOrganizationId(orgId);
			_refetchOrg();
		},
		[orgStore, _refetchOrg]
	);

	const providedValue = useMemo(
		() => ({
			setActiveOrganizationId: setActiveOrg,
			allUserOrganizations: orgStore.allUserOrganizations,
			currentOrganization: orgStore.currentOrganization,
			currentOrganizationRole: orgStore.role,
			currentOrganizationValueLists: orgStore.valueListItems,
		}),
		[
			setActiveOrg,
			orgStore.allUserOrganizations,
			orgStore.currentOrganization,
			orgStore.role,
			orgStore.valueListItems,
		]
	);

	return (
		<OrganizationSessionContext.Provider value={providedValue}>
			{children}
		</OrganizationSessionContext.Provider>
	);
};
