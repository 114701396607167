import { Link } from 'react-router-dom';

import { OperationToken } from 'api/organization';
import { GetParticipantsByOrgSortField } from 'api/participant';
import { BatteryStatus } from 'components/shared/BatteryStatus/BatteryStatus';
import { ParticipantsTableData } from '../../components/participant/Participants/Participants.types';
import {
	TableColumn,
	TableColumnOption,
} from '../../components/shared/DataTable/DataTable';
import { Kebab } from '../../components/shared/DataTable/columnComponents/Kebab';
import { ShowIfAuthorized } from '../../components/shared/ShowIfAuthorized';
import { ButtonSm } from '../../components/shared/designSystem';
import { icons } from '../../enums/icons';
import { AssignmentStatus, SortDir } from '../../generated/graphql';
import i18n from '../../i18n';
import { dateTimeFormatter } from '../../stringStrategy/dateFormatStringStrategy';
import { ParticipantTableData } from './participantSchema';
import { AssignmentType } from '@lh/eng-platform-battery-service-rest-client';

const hasCurrentAssignment = (
	status: AssignmentStatus | undefined
): boolean => {
	return (
		!!status &&
		status !== AssignmentStatus.Deleted &&
		status !== AssignmentStatus.Complete
	);
};

export type TableData = {
	id: string;
	avatarUrl?: string;
	firstName: string;
	lastName: string;
	visitType: string;
	patientExternalId: string | null;
	batteryResults?: [AssignmentStatus];
	birthDate: string;
	lastCompletedBattery?: string;
	organizationId: string;
	assignmentInfo: {
		id: string;
		status: AssignmentStatus | undefined;
		type: AssignmentType | undefined;
		batteryDisplayKey: string | undefined;
	};
	newPatient?: boolean;
};

const columns = (
	dateFormat: string,
	showVisitTypes = false,
	columnOptions?: TableColumnOption<ParticipantTableData>[],
	addBatteryCallback?: (x: ParticipantTableData) => void
): TableColumn<ParticipantTableData>[] => {
	const tableColumns: TableColumn<ParticipantTableData>[] = [
		{
			propertyName: 'externalId',
			headerDisplay: i18n.t('research.participantModal.participantId'),
			sortable: true,
			minWidth: '100px',
			sortProperty: GetParticipantsByOrgSortField.ExternalId,
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as ParticipantTableData;
				return (
					<Link to={`/participants/${rowDataType.id}`}>
						{rowDataType.externalId}
					</Link>
				);
			},
			sortDir: SortDir.Asc,
		},
		{
			propertyName: 'birthYear',
			headerDisplay: i18n.t('web.participant.birthYear'),
			sortable: true,
			sortProperty: GetParticipantsByOrgSortField.BirthYear,
			minWidth: '100px',
		},
		{
			propertyName: 'lastCompletedBattery',
			headerDisplay: i18n.t('web.patients.lastCompletedBattery'),
			sortable: false,
			minWidth: '324px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as ParticipantTableData;
				const lastCompletedBattery =
					rowDataType.lastCompletedBattery ?? '';
				const formattedDate = dateTimeFormatter(
					lastCompletedBattery,
					dateFormat,
					navigator.language
				);
				return !lastCompletedBattery ? (
					<>{i18n.t(`web.shared.none`)}</>
				) : (
					<>{formattedDate}</>
				);
			},
		},
		{
			propertyName: 'batteryStatus',
			headerDisplay: i18n.t('web.patients.batteryStatus'),
			sortable: false,
			minWidth: '192px',
			rowColumnComponent: ({ row }) => {
				const rowData = row as ParticipantTableData;
				return !hasCurrentAssignment(rowData.assignmentInfo?.status) ? (
					<ShowIfAuthorized
						operations={[OperationToken.AssignBattery]}>
						<ButtonSm
							onClick={() => {
								return (
									addBatteryCallback &&
									addBatteryCallback({
										...rowData,
										newPatient: false,
									})
								);
							}}
							width='160px'
							text={i18n.t('web.patients.forms.addBattery')}
							IconLeft={icons.AddOutlined}
						/>
					</ShowIfAuthorized>
				) : (
					<BatteryStatus
						assignmentId={rowData.assignmentInfo?.id ?? ''}
						assignmentStatus={rowData?.assignmentInfo?.status}
						assignmentType={
							rowData?.assignmentInfo?.type as AssignmentType
						}
						batteryDisplayKey={
							rowData?.assignmentInfo?.batteryDisplayKey
						}
					/>
				);
			},
		},
		{
			propertyName: '',
			sortable: false,
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ row, column }) => {
				const rowDataType = row as TableData;
				return hasCurrentAssignment(
					rowDataType.assignmentInfo?.status
				) ? (
					<Kebab row={row} column={column} />
				) : (
					<></>
				);
			},
			options: columnOptions,
		},
	];
	// Add the "Visit" column conditionally (after YOB column)
	if (showVisitTypes === true) {
		const lastCompletedBatteryColumnIndex = tableColumns.findIndex(
			(column) => column?.propertyName === 'lastCompletedBattery'
		);
		const visitTypeColumn = {
			propertyName: 'visitType',
			headerDisplay: i18n.t('web.visitTypes.visit'),
			sortable: false,
			minWidth: '100px',
		};
		tableColumns.splice(
			lastCompletedBatteryColumnIndex,
			0,
			visitTypeColumn
		);
	}
	return tableColumns;
};

const mapData = (
	participantData?: ParticipantsTableData
): ParticipantTableData[] => {
	return (participantData ?? []).map((participant) => ({
		id: participant.id || '',
		externalId: participant.externalId || '',
		birthYear: participant.birthYear,
		lastCompletedBattery: participant?.batteryResult?.endTime,
		assignmentInfo: {
			id: participant?.assignment?.id || '',
			status: participant?.assignment?.assignmentStatus,
			type: participant?.assignment?.type as unknown as AssignmentType,
			batteryDisplayKey: participant?.assignment?.batteryDisplayKey ?? '',
		},
		visitType: participant?.assignment?.visitType ?? '',
		assignmentStatus:
			participant?.assignment?.assignmentStatus ?? undefined,
		contactEmail: participant.contactEmail ?? undefined,
	}));
};

const participantTableSchema = { columns, mapData };
export { participantTableSchema };
