import { Divider, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Accordion } from 'components/accordion';
import { theme } from '../../../../../providers/styleProvider';
import { CloseButton } from '../../../../designSystem/Button/CloseButton';
import { P1 } from '../../../../designSystem/Text';

import { DEMENTIA_RISK_MAP } from './DementiaRisk.consts';
import {
	Container,
	Content,
	Info,
	P1Medium,
	SecondaryText,
	Tag,
	TagInfo,
	TagText,
} from './DementiaRiskDetails.styles';

type DementiaRiskDetailsProps = {
	onClose: () => void;
};

export const DementiaRiskDetails = ({ onClose }: DementiaRiskDetailsProps) => {
	const { t } = useTranslation();

	return (
		<Content>
			<Container>
				<Space h={theme.spacing.sm} />
				<p>{t`web.report.dementiaRisk.modal.description`}</p>
				<Space h={theme.spacing.sm} />
				<Divider color={theme.colors.gray_60} w='100%' h='1px' />
				<Space h={theme.spacing.md} />
				<P1Medium>
					{t`web.report.dementiaRisk.modal.info.title`}
				</P1Medium>
				<Space h={theme.spacing.md} />
				<Info>
					<TagInfo>
						<P1Medium>
							{t`web.report.dementiaRisk.modal.info.moreThan60`}
						</P1Medium>
						<Tag
							$bgColor={
								DEMENTIA_RISK_MAP.NotElevated.backgroundColor
							}>
							<TagText
								$color={DEMENTIA_RISK_MAP.NotElevated.color}>
								{DEMENTIA_RISK_MAP.NotElevated.text}
							</TagText>
						</Tag>
						<P1>{t`web.report.dementiaRisk.modal.info.risk`}</P1>
					</TagInfo>
					<TagInfo>
						<P1Medium>
							{t`web.report.dementiaRisk.modal.info.lessThan60`}
						</P1Medium>
						<Tag
							$bgColor={
								DEMENTIA_RISK_MAP.Elevated.backgroundColor
							}>
							<TagText $color={DEMENTIA_RISK_MAP.Elevated.color}>
								{DEMENTIA_RISK_MAP.Elevated.text}
							</TagText>
						</Tag>
						<P1>{t`web.report.dementiaRisk.modal.info.risk`}</P1>
					</TagInfo>
				</Info>
				<Space h={theme.spacing.md} />
				<Accordion
					title={
						<P1Medium>{t`web.report.dementiaRisk.modal.info.references`}</P1Medium>
					}>
					<SecondaryText>
						{t('web.report.dementiaRisk.modal.info.referencesText')}
					</SecondaryText>
				</Accordion>
			</Container>
			<CloseButton
				text={t('web.report.dementiaRisk.modal.info.close')}
				onClick={onClose}
			/>
		</Content>
	);
};
