import { Card, Flex, Space } from '@mantine/core';
import styled from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';

import { InfoTooltip } from 'components/report/common/InfoTooltip/InfoTooltip';
import { StyledLinkButton } from 'components/shared/StyledLinkButton';
import { theme } from '../../../../../../providers/styleProvider';

type Props = {
	headingText: string;
	subHeadingText: string;
	tooltipText: string;
	link: {
		text: string;
		onClick: () => void;
	};
};

export const IndicationWidget = (props: Props) => {
	return (
		<div style={{ width: '524px' }}>
			<Card padding={'md'} radius={'md'} shadow='sm'>
				<Flex
					direction={'row'}
					style={{ justifyContent: 'space-between' }}>
					<Flex direction={'row'} style={{ alignItems: 'center' }}>
						<Ellipsis
							$backgroundColor={theme?.colors?.orange_light}
						/>
						<Typography.H4
							testId='indication-widget-heading'
							color={theme?.color?.bodyText}
							weight='500'>
							{props?.headingText}
						</Typography.H4>
						<InfoTooltip tooltipText={props?.tooltipText} />
					</Flex>
					{props?.link?.text ? (
						<StyledLink
							data-testid='indication-widget-link'
							onClick={props?.link?.onClick}
							role='link'>
							{props?.link?.text}
						</StyledLink>
					) : null}
				</Flex>
				<Space h='sm' />
				<Typography.P2
					testId='indication-widget-sub-heading'
					style={{
						fontStyle: 'italic',
						color: theme?.color?.bodyTextSecondary,
					}}>
					{props?.subHeadingText}
				</Typography.P2>
			</Card>
		</div>
	);
};

export const StyledLink = styled(StyledLinkButton)(
	({ theme: { colors } }) => `
	font-size: 14px;
	font-weight: 600;
	color: ${colors.blue}
`
);

export const Ellipsis = styled.div<{ $backgroundColor?: string }>(
	({ $backgroundColor = '#ffd75e' }) => `
		border-radius: 50%;
		width: 12px;
		height: 12px;
		background-color: ${$backgroundColor};
		margin-right: 8px;
`
);
