// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CheckmarkFilled = ({
	title,
	width = 18,
	height = 19,
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 19'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckmarkFilled-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CheckmarkFilled'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5ZM14.0725 6.98446C14.3401 6.66825 14.3007 6.19502 13.9845 5.92746C13.6683 5.6599 13.195 5.69934 12.9275 6.01554L7.99632 11.8433L5.56944 9.01191C5.29988 8.69741 4.8264 8.66099 4.51191 8.93056C4.19741 9.20012 4.16099 9.6736 4.43056 9.98809L7.43056 13.4881C7.57359 13.655 7.78261 13.7507 8.00239 13.75C8.22217 13.7493 8.43058 13.6522 8.57254 13.4845L14.0725 6.98446Z'
				fill={color}
			/>
		</svg>
	);
};
