// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const WarningOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='WarningOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'WarningOutlined'}</title>
			<path
				d='M12.9184 9.10753C12.9757 9.26055 13.014 9.41358 13.014 9.58574C12.9949 10.064 12.9566 10.5613 12.9184 11.0395C12.8992 11.4125 12.8753 11.7903 12.8514 12.1681C12.8275 12.5459 12.8036 12.9237 12.7845 13.2967C12.7653 13.5453 12.7653 13.7558 12.7653 14.0044C12.7462 14.4061 12.4401 14.7122 12.0384 14.7122C11.6367 14.7122 11.3307 14.4253 11.3116 14.0236C11.2829 13.4402 11.2494 12.8567 11.2159 12.2733C11.1824 11.6899 11.149 11.1065 11.1203 10.523C11.1011 10.217 11.082 9.91092 11.0629 9.58574C11.0629 9.06927 11.3498 8.64844 11.8089 8.51454C12.2489 8.43802 12.7271 8.66757 12.9184 9.10753Z'
				fill='#676671'
			/>
			<path
				d='M12.9757 16.3955C12.9949 16.912 12.5358 17.3519 12.0193 17.3519C11.4837 17.3519 11.0438 16.912 11.0438 16.3764C11.0438 15.8408 11.4837 15.4008 12.0193 15.4008C12.5549 15.4008 13.014 15.8408 12.9757 16.3955Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.70983 19.9819C3.40325 19.9819 2.57007 18.5427 3.23283 17.4066L10.5231 4.85211C11.167 3.71596 12.8144 3.71596 13.4771 4.85211L20.7674 17.4066C21.4302 18.5617 20.597 19.9819 19.2715 19.9819H4.70983ZM12.5049 5.41822L19.7916 17.9665L19.7928 17.9684C20.0189 18.3658 19.74 18.8569 19.2715 18.8569H4.70983C4.26902 18.8569 3.98058 18.3575 4.20458 17.9734L11.496 5.41705L11.5019 5.40675C11.7085 5.04221 12.2697 5.01614 12.5049 5.41822Z'
				fill='#676671'
			/>
		</svg>
	);
};
