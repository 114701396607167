// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AddUserOutlineSparkle = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 158 100'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AddUserOutlineSparkle-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'AddUserOutlineSparkle'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M80.331 48.875C89.3818 48.875 96.8623 41.2604 96.8623 31.6875C96.8623 22.1145 89.3818 14.5 80.331 14.5C71.2803 14.5 63.7998 22.1145 63.7998 31.6875C63.7998 41.2604 71.2803 48.875 80.331 48.875ZM80.331 51.375C90.8417 51.375 99.3623 42.5606 99.3623 31.6875C99.3623 20.8144 90.8417 12 80.331 12C69.8204 12 61.2998 20.8144 61.2998 31.6875C61.2998 42.5606 69.8204 51.375 80.331 51.375ZM83.42 90.0025L52.9509 90C51.1018 90 49.4873 89.1973 49.4873 87.1728V75.4953C49.4873 64.6563 58.0286 55.9576 68.4196 55.9576H92.3221C93.0287 55.9576 94.5812 56.0016 96.1372 56.0871C94.1273 56.6318 92.23 57.4502 90.4878 58.5H68.4196C59.4873 58.5 51.9873 65.5 51.9873 75.4953V87.4663L81.958 87.4686C82.3908 88.3474 82.8796 89.1936 83.42 90.0025Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M117.862 77.625C117.862 86.3925 110.755 93.5 101.987 93.5C93.2198 93.5 86.1123 86.3925 86.1123 77.625C86.1123 68.8575 93.2198 61.75 101.987 61.75C110.755 61.75 117.862 68.8575 117.862 77.625ZM120.362 77.625C120.362 87.7732 112.136 96 101.987 96C91.8391 96 83.6123 87.7732 83.6123 77.625C83.6123 67.4768 91.8391 59.25 101.987 59.25C112.136 59.25 120.362 67.4768 120.362 77.625ZM103.13 84.3571C103.13 84.9883 102.618 85.5 101.987 85.5C101.356 85.5 100.844 84.9883 100.844 84.3571V78.6427H95.1302C94.499 78.6427 93.9873 78.131 93.9873 77.4998C93.9873 76.8687 94.499 76.357 95.1302 76.357H100.844V70.6429C100.844 70.0117 101.356 69.5 101.987 69.5C102.618 69.5 103.13 70.0117 103.13 70.6429V76.357H108.844C109.476 76.357 109.987 76.8687 109.987 77.4998C109.987 78.131 109.476 78.6427 108.844 78.6427H103.13V84.3571Z'
				fill='#86C65A'
			/>
			<path
				d='M154.788 58.3115C147.185 65.837 147.404 66.7702 157.513 69.8137C147.404 66.7695 146.736 67.4325 149.237 78C146.736 67.4318 145.842 67.1645 138.239 74.69C145.842 67.1645 145.623 66.2313 135.513 63.1929C145.623 66.232 146.292 65.569 143.791 55C146.291 65.5697 147.185 65.8377 154.788 58.3115Z'
				fill='#B1E5FB'
			/>
			<path
				d='M44.7863 26C51.8157 35.0013 54.4888 35.3299 63.4873 28.2987C54.4875 35.3299 54.1615 38 61.1883 47C54.1615 37.9987 51.4858 37.6701 42.4873 44.7013C51.4871 37.6701 51.8157 35.0013 44.7863 26Z'
				fill='#B1E5FB'
			/>
			<path
				d='M27.4873 81.615C34.3432 84.0883 35.9278 83.4292 38.562 77C35.9278 83.4286 36.6303 84.9107 43.4873 87.3829C36.6303 84.9107 35.0446 85.5719 32.4115 92C35.0451 85.5724 34.3432 84.0883 27.4873 81.615Z'
				fill='#C3E3AC'
			/>
			<path
				d='M118.487 15.615C125.343 18.0883 126.928 17.4292 129.562 11C126.928 17.4286 127.63 18.9107 134.487 21.3829C127.63 18.9107 126.045 19.5719 123.411 26C126.045 19.5724 125.343 18.0883 118.487 15.615Z'
				fill='#C3E3AC'
			/>
			<line
				x1='43.2373'
				y1='67.25'
				x2='1.7373'
				y2='67.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='43.2373'
				y1='73.25'
				x2='19.7373'
				y2='73.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='145.237'
				y1='47.25'
				x2='103.737'
				y2='47.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='128.237'
				y1='41.25'
				x2='104.737'
				y2='41.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
		</svg>
	);
};
