import { Chip, theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	MeasureCell,
	MeasuresContainer,
	NormalMeasuresContent,
} from '../styles';

export function MixedMildMeasures() {
	const { t } = useTranslation();

	return (
		<MeasuresContainer $backgroundColor={Theme.colors.gray_90}>
			<NormalMeasuresContent data-testid='mixed-mild-measures-content'>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='2' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
			</NormalMeasuresContent>
		</MeasuresContainer>
	);
}
