import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Space } from '@mantine/core';
import styled from 'styled-components';

import { Typography, theme } from '@lh/eng-web-mosaic-common';

import { AlertShieldSolid } from '../../../../../../shared/designSystem/Icons';
import { Answer } from '../../../../common/components/table/Answer';
import { ConfidenceScoreRange } from '../../../../../Epsom/common/constants/confidenceScore';
import {
	getConfidenceScoreRatingTextTransKey,
	mapScoreToConfidenceRating,
} from '../../../../../Epsom/common/helpers/confidenceScoreUtils';

export type AnswerRowProps = {
	itemNumber: number;
	participantAnswer: string;
	rating: {
		isLowConfidenceIndicator?: boolean;
	};
	score: {
		current: number;
	};
};

const AnswerRow = (props: AnswerRowProps) => {
	const { t } = useTranslation();
	const currentScoreRange = useMemo(
		() => mapScoreToConfidenceRating(props?.score?.current),
		[props?.score]
	);

	const rowBackgroundColor = useMemo(
		() =>
			props?.itemNumber % 2 !== 0
				? theme?.colors?.gray_90
				: 'transparent',
		[props?.itemNumber]
	);

	// "no loss" of confidence needs to be at the bottom, "complete loss" at the top
	const scoreRangeLowToHigh = useMemo(
		() => Object.keys(ConfidenceScoreRange)?.reverse(),
		[]
	);

	const alertIconColor = theme.color.alertError;

	// Freetext length should be increased to 80 characters (from 50) for both baseline and longitudinal ePSOM
	const participantAnswerTrimmed = useMemo(() => {
		const totalChars = props?.participantAnswer?.length;
		if (totalChars > 80) {
			return props?.participantAnswer?.substring(0, 80)?.concat('...');
		} else {
			return props?.participantAnswer;
		}
	}, [props?.participantAnswer]);

	return (
		<div key={props?.itemNumber}>
			{scoreRangeLowToHigh.map((scoreRange: string, index) => {
				const ratingDisplayKey = t(
					getConfidenceScoreRatingTextTransKey(
						scoreRange as ConfidenceScoreRange
					)
				);
				const isSelectedScoreRange = currentScoreRange === scoreRange;

				return (
					<Flex
						key={scoreRange}
						style={{ backgroundColor: rowBackgroundColor }}
						direction={'row'}>
						<SingleColSpanRow
							style={{ paddingTop: theme?.spacing?.xxs }}>
							{index === 0 ? (
								<Typography.P1>
									<span>
										<b>{`Q.${props?.itemNumber}:`}</b>
										&nbsp;
										{participantAnswerTrimmed}
									</span>
								</Typography.P1>
							) : (
								<p />
							)}
						</SingleColSpanRow>
						<DoubleColSpanRow>
							<SingleColSpanRow>
								<Answer
									labelText={ratingDisplayKey}
									isSelected={isSelectedScoreRange}
									isEmphasized={false}
								/>
							</SingleColSpanRow>
							<DoubleColSpanRow>
								{/* Low Confidence Indicator */}
								{isSelectedScoreRange &&
									props?.rating?.isLowConfidenceIndicator && (
										<>
											<Space w={'sm'} />
											<AlertShieldSolid
												height={12}
												width={12}
												color={alertIconColor}
											/>
											<Space w={'sm'} />
										</>
									)}
							</DoubleColSpanRow>
						</DoubleColSpanRow>
						<SingleColSpanRow>
							{isSelectedScoreRange && (
								<Typography.P1
									data-testid='score-value'
									weight='600'
									style={{
										textAlign: 'end',
										width: '95%',
									}}>
									{`+${props?.score?.current}`}
								</Typography.P1>
							)}
						</SingleColSpanRow>
					</Flex>
				);
			})}
		</div>
	);
};

export { AnswerRow };

const SingleColSpanRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

const DoubleColSpanRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 2;
	align-items: center;
`;
