import React from 'react';

import { Flex } from '@mantine/core';
import styled from 'styled-components';

import { P1, P2 } from '../../../../../designSystem/Text';
import { IndividualScore } from '../../types';

type scoreBreakdownProps = {
	scoreIndicatorColor: string | undefined;
	breakdownSummary: React.ReactNode;
	scores: IndividualScore[] | [];
};
const ScoreBreakdown = (props: scoreBreakdownProps) => {
	return (
		<Wrapper>
			<Flex direction={'row'}>
				<LeftContainer>
					{props?.scores?.length > 0 ? (
						<IndicatorCircle
							data-testid={'score-indicator-circle'}
							style={{
								backgroundColor: props?.scoreIndicatorColor,
							}}
						/>
					) : (
						<Dash data-testid={'score-indicator-dash'}>{'--'}</Dash>
					)}
				</LeftContainer>
				<Flex direction={'column'}>
					<ScoreBreakdownText
						data-testid={'score-breakdown-summary-text'}>
						{props?.breakdownSummary}
					</ScoreBreakdownText>
					<ColumnListWrapper>
						{props?.scores?.map((individualScore) => (
							<IndividualScoreBreakdown
								key={individualScore?.scoreCategory}
								data-testid={'score-individual-breakdown-text'}>
								{`${individualScore.scoreValues.achieved}/${individualScore.scoreValues.total} points from ${individualScore.scoreCategory}`}
							</IndividualScoreBreakdown>
						))}
					</ColumnListWrapper>
				</Flex>
			</Flex>
		</Wrapper>
	);
};

export default ScoreBreakdown;

const Wrapper = styled.div(
	({ theme: { spacing } }) => `
	padding: ${spacing.md}
`
);

const LeftContainer = styled.div(
	({ theme: { spacing } }) => `
	height: 100%;
	display: flex;
	flex: 1;
	margin-right: ${spacing.sm};
`
);

const IndicatorCircle = styled.div`
	height: 12px;
	width: 12px;
	border-radius: 12px;
	margin-top: 2px;
`;

const ScoreBreakdownText = styled(P2)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.bold};
	margin-bottom: ${spacing.sm};
`
);

const ColumnListWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Dash = styled(P1)(
	({ theme: { color, weight, fontSize } }) => `
		color: ${color.graphTextSecondary};
		font-weight: ${weight.bold};
		font-size: ${fontSize._24};
		min-width: 26px;
	`
);

const IndividualScoreBreakdown = styled(P2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.regular}
`
);
