import i18nBase, { i18n } from '../../i18n';
import { Settings } from 'luxon';
import { ReactNode } from 'react';
import { I18nextProvider as BaseI18nextProvider } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB, enUS, es as esMX } from 'date-fns/esm/locale';

interface I18nextProviderProps {
	children: ReactNode;
	i18n?: i18n;
}

const I18nextProvider = ({
	children,
	i18n = i18nBase,
}: I18nextProviderProps): JSX.Element => {
	//Grab browser language pref and set Luxon locale
	const currentLocale = window.navigator.language;
	Settings.defaultLocale = currentLocale;
	registerLocale('en_GB', enGB);
	registerLocale('es_MX', esMX);
	registerLocale('en_US', enUS);
	setDefaultLocale(window.navigator.language);

	return <BaseI18nextProvider i18n={i18n}>{children}</BaseI18nextProvider>;
};

export { I18nextProvider };
