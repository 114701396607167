import { IconProps, flipFunction } from '../flipFunction';

export const IconAdd = ({
	title,
	width = '24',
	height = '24',
	color = '#087DAE',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-testid='IconAdd-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'IconAdd'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 19C12.5523 19 13 18.5523 13 18V13.0001H18C18.5523 13.0001 19 12.5524 19 12.0001C19 11.4478 18.5523 11.0001 18 11.0001H13V5.99998C13 5.4477 12.5523 4.99998 12 4.99998C11.4477 4.99998 11 5.4477 11 5.99998L11 11.0001H6C5.44772 11.0001 5 11.4478 5 12.0001C5 12.5524 5.44772 13.0001 6 13.0001H11L11 18C11 18.5523 11.4477 19 12 19Z'
				fill={color}
			/>
		</svg>
	);
};
