import { useQuery } from '@tanstack/react-query';

import { getQnrService } from 'api/getQnrService';
import {
	DefaultApiGetQuestionnaireResultRequest,
	QuestionnaireSpecWithScoringResult,
} from 'api/qnr';
import { isNonEmptyString } from '../../utils/stringUtils';
import { QueryKey } from '../query';

export async function fetchQnrSpecWithScore(
	input: DefaultApiGetQuestionnaireResultRequest
): Promise<QuestionnaireSpecWithScoringResult | null> {
	const isValidInput =
		isNonEmptyString(input?.batteryResultId) &&
		isNonEmptyString(input?.segmentResultId);
	if (!isValidInput) {
		throw new Error('useGetQnrSpecWithScore: invalid QNR Id or version');
	}

	const service = await getQnrService();
	const { data } = await service.getQuestionnaireResult({
		batteryResultId: input?.batteryResultId,
		segmentResultId: input?.segmentResultId,
	});
	return data?.result;
}

export function useGetQnrSpecWithScore(
	input: DefaultApiGetQuestionnaireResultRequest
) {
	const isValidInput =
		isNonEmptyString(input?.batteryResultId) &&
		isNonEmptyString(input?.segmentResultId);
	return useQuery({
		enabled: isValidInput,
		meta: {
			errorMessage: 'Error fetching QNR spec',
		},
		queryKey: [
			QueryKey.Questionnaire,
			input?.batteryResultId,
			input?.segmentResultId,
		],
		queryFn: () => fetchQnrSpecWithScore(input),
		staleTime: Infinity,
	});
}
