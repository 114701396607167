import { create } from 'zustand';

import { MeasuresProps } from '../components/participant/ParticipantDetails/components/ParticipantClassification/Breakdown/Breakdown.types';
import {
	IndicativeProps,
	getIndicativeInterpretation,
} from '../components/participant/ParticipantDetails/components/ParticipantClassification/Breakdown/Breakdown.utils';

interface State extends MeasuresProps {
	indicative: IndicativeProps | null;
	ready: boolean;
	setReady: () => void;
	setMeasures: (measures: MeasuresProps) => void;
	reset: () => void;
}

const useBreakdownMeasuresStore = create<State>()((set, get) => ({
	indicative: null,
	ready: false,
	episodicMemoryMeasures: -1,
	executiveControlMeasures: -1,
	totalImpairedMeasures: -1,
	visuoconstructionalMeasures: -1,
	setReady: () => {
		set({
			ready: true,
		});
	},
	setMeasures: (_measures) => {
		let totalImpairedMeasures = 0;
		let indicative: IndicativeProps | null = null;

		const measures = { ...get(), ..._measures };
		const {
			episodicMemoryMeasures,
			executiveControlMeasures,
			visuoconstructionalMeasures,
		} = measures;
		if (
			episodicMemoryMeasures !== undefined &&
			executiveControlMeasures !== undefined &&
			visuoconstructionalMeasures !== undefined
		) {
			totalImpairedMeasures =
				episodicMemoryMeasures +
				executiveControlMeasures +
				visuoconstructionalMeasures;

			indicative = getIndicativeInterpretation({
				episodicMemoryMeasures,
				executiveControlMeasures,
				totalImpairedMeasures,
				visuoconstructionalMeasures,
			});
		}

		set({
			...measures,
			totalImpairedMeasures,
			indicative,
		});
	},
	reset: () => {
		set(() => ({
			ready: false,
			episodicMemoryMeasures: -1,
			executiveControlMeasures: -1,
			totalImpairedMeasures: -1,
			visuoconstructionalMeasures: -1,
		}));
	},
}));

export { useBreakdownMeasuresStore };
