import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../../metrics';
import { TFunction } from 'react-i18next';

export const getSpatialReasoningDataSet = (
	dataMap: MetricHash,
	t: TFunction
): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const spatialReasoningMap = {
		commandClock: [
			{
				x: t(spatialReasoningStrings.clockFaceCircularity),
				y: dataMap?.COMClockfaceCircularity_s?.value,
			},
			{
				x: t(spatialReasoningStrings.componentPlacement),
				y: dataMap?.COMComponentPlacement_s?.value,
			},
			{
				x: t(spatialReasoningStrings.verticalSpatialPlacement),
				y: dataMap?.COMVerticalSpatialPlacement_s?.value,
			},
			{
				x: t(spatialReasoningStrings.horizontalSpatialPlacement),
				y: dataMap?.COMHorizontalSpatialPlacement_s?.value,
			},
		],
		copyClock: [
			{
				x: t(spatialReasoningStrings.clockFaceCircularity),
				y: dataMap?.COPClockfaceCircularity_s?.value,
			},
			{
				x: t(spatialReasoningStrings.componentPlacement),
				y: dataMap?.COPComponentPlacement_s?.value,
			},
			{
				x: t(spatialReasoningStrings.verticalSpatialPlacement),
				y: dataMap?.COPVerticalSpatialPlacement_s?.value,
			},
			{
				x: t(spatialReasoningStrings.horizontalSpatialPlacement),
				y: dataMap?.COPHorizontalSpatialPlacement_s?.value,
			},
		],
	};

	return spatialReasoningMap as DataSet;
};

export const spatialReasoningStrings = {
	clockFaceCircularity: 'web.report.segmentUtils.clockFaceCircularity',
	componentPlacement: 'web.report.segmentUtils.componentPlacement',
	verticalSpatialPlacement:
		'web.report.segmentUtils.verticalSpatialPlacement',
	horizontalSpatialPlacement:
		'web.report.segmentUtils.horizontalSpatialPlacement',
};
