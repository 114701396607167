import { BatteryService, BatteryServiceConfiguration } from 'api/battery';

import { useAuthStore } from 'store';
import { config } from '../config';

export async function getBatteryService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new BatteryService(
			new BatteryServiceConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
