// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Support = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Support-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Support'}</title>

			<path
				d='M8.372 10.262C8.372 10.5172 8.57884 10.724 8.834 10.724C9.08916 10.724 9.296 10.5172 9.296 10.262V9.42801C9.56 9.38001 9.824 9.29601 10.088 9.17601C10.352 9.05601 10.588 8.89601 10.796 8.69601C11.004 8.49601 11.172 8.25601 11.3 7.97601C11.436 7.68801 11.504 7.35201 11.504 6.96801C11.504 6.27201 11.288 5.72001 10.856 5.31201C10.424 4.90401 9.836 4.70001 9.092 4.70001C8.716 4.70001 8.384 4.75201 8.096 4.85601C7.808 4.96001 7.556 5.10401 7.34 5.28801C7.132 5.46401 6.956 5.67201 6.812 5.91201C6.7483 6.02067 6.69163 6.13196 6.64197 6.24588C6.54632 6.46532 6.67175 6.709 6.89564 6.79372C7.15652 6.89243 7.43966 6.73472 7.56475 6.48542C7.66203 6.29154 7.78711 6.1204 7.94 5.97201C8.22 5.70801 8.592 5.57601 9.056 5.57601C9.488 5.57601 9.832 5.70001 10.088 5.94801C10.344 6.18801 10.472 6.51201 10.472 6.92001V7.08801C10.472 7.59201 10.284 7.98801 9.908 8.27601C9.61371 8.50633 9.22732 8.65478 8.74885 8.72138C8.53893 8.7506 8.372 8.92254 8.372 9.13448V10.262Z'
				fill='current'
			/>
			<path
				d='M8.276 13.172C8.396 13.3 8.584 13.364 8.84 13.364C9.096 13.364 9.28 13.3 9.392 13.172C9.512 13.044 9.572 12.876 9.572 12.668V12.512C9.572 12.304 9.512 12.136 9.392 12.008C9.28 11.88 9.096 11.816 8.84 11.816C8.584 11.816 8.396 11.88 8.276 12.008C8.164 12.136 8.108 12.304 8.108 12.512V12.668C8.108 12.876 8.164 13.044 8.276 13.172Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
				fill='current'
			/>
		</svg>
	);
};
