import styled from 'styled-components';
import { P1 } from '../../../../../shared/designSystem';

type EndOfQuestionnaireTextProps = {
	text: string;
};

function EndOfQuestionnaireText(props: EndOfQuestionnaireTextProps) {
	return (
		<StyledSecondaryText data-testid='end-of-questionnaire-text'>
			{props?.text}
		</StyledSecondaryText>
	);
}

export { EndOfQuestionnaireText };

const StyledSecondaryText = styled(P1)(
	({ theme: { color, spacing } }) => `    
    color: ${color.bodyTextSecondary};
    text-align: center;
	margin-bottom: ${spacing.md};
`
);
