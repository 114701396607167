import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from '../getBatteryService';
import { QueryKey } from '../query';

import {
	Assignment,
	AssignmentStatus,
	GetAssignmentsFilterField,
	GetAssignmentsFilterOperator,
} from './types';

export async function fetchAssignedAssignment(
	participantId: string | null
): Promise<Assignment | null> {
	if (!participantId) {
		throw new Error(
			"useAssignedAssignmentQuery: participantId can't be null"
		);
	}

	const batteryService = await getBatteryService();
	const { data } = await batteryService.getAssignments({
		participantId,
		filterField: [GetAssignmentsFilterField.AssignmentStatus],
		filterOperator: [GetAssignmentsFilterOperator.In],
		filterValue: [
			[AssignmentStatus.Assigned, AssignmentStatus.Started].join(','),
		],
		page: 0,
		pageSize: 1,
	});
	return data.results.at(0) ?? null;
}

export function useAssignedAssignmentQuery(participantId: string | null) {
	return useQuery({
		enabled: !!participantId,
		meta: {
			errorMessage: `Error fetching assignments for participant ${participantId}`,
		},
		queryKey: [QueryKey.Assignment, participantId],
		queryFn: () => fetchAssignedAssignment(participantId),
		staleTime: Infinity,
	});
}
