import { Flex, Space } from '@mantine/core';
import { H4 } from '../../../../../designSystem/Text';
import { theme } from '../../../../../../providers/styleProvider';
import { TableSubHeading } from './TableSubHeading';

export type TableSectionHeaderProps = {
	cardHeading: string;
	cardHeadingDescriptions: React.ReactNode[];
	subheading: string;
};

function TableSectionHeader(props: TableSectionHeaderProps) {
	return (
		<>
			<Flex direction={'row'} align={'center'}>
				{/* Header Text */}
				<H4
					data-testid={'header-text'}
					style={{ fontWeight: theme.weight.medium }}>
					{props?.cardHeading}
				</H4>
				<Space w={'md'} />
				<Flex
					direction={'row'}
					align={'center'}
					data-testid={'header-description-container'}>
					{/* Header Description SVG */}
					{props.cardHeadingDescriptions}
				</Flex>
			</Flex>
			<Space h='md' />
			<TableSubHeading subheadingText={props.subheading} />
		</>
	);
}

export { TableSectionHeader };
