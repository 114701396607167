import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DeepBatteryResult } from 'api/battery';
import { ReportBatteryTabs } from 'components/report/common/batteryTabs';
import { UserContext } from '../../../../../../context/UserContext';
import { dateFormatter } from '../../../../../../stringStrategy/dateFormatStringStrategy';
import { ResultsConst } from '../../ParticipantClassification/ParticipantClassification.utils';
import { IndicationWidget } from '../components/IndicationWidget';
import { checkPHQ8ForDepression } from './indicationWidgetHelpers';

type Indication = {
	isIndicative: (deepBatteryResult: DeepBatteryResult | undefined) => boolean;
	renderIndicationWidget: (
		deepBatteryResult: DeepBatteryResult | undefined
	) => JSX.Element;
};

type IndicationWidgetConfig = {
	// The name of the battery we're checking for any indications.
	batteryDisplayKey: string;
	// An assessment can indicate multiple factors such as anxiety, depression, personality traits etc.
	indications: Indication[];
};

export const useGetResultFlagsConfig = (): IndicationWidgetConfig[] => {
	const navigate = useNavigate();
	const { t: tFunction } = useTranslation();
	const { currentUser } = useContext(UserContext);
	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';

	const resultFlagConfigRDAC = useMemo(
		() => ({
			isIndicative: checkPHQ8ForDepression,
			renderIndicationWidget: (
				deepBatteryResult: DeepBatteryResult | undefined
			) => {
				const participantId = deepBatteryResult?.participantId;
				const batteryResultId = deepBatteryResult?.id;
				const formattedBatteryResultDate = dateFormatter(
					deepBatteryResult?.endTime ?? '',
					dateFormat
				);
				return (
					<IndicationWidget
						headingText={tFunction(
							'web.report.phq8.depression.resultFlag.heading'
						)}
						subHeadingText={`${tFunction(
							'web.report.phq8.depression.resultFlag.subHeading'
						)} ${formattedBatteryResultDate}`}
						tooltipText={tFunction(
							'web.report.phq8.depression.tooltip'
						)}
						link={{
							text: tFunction(
								'web.report.cohort.breakdown.viewReport'
							),
							onClick: () => {
								navigate(
									`/results/${participantId}/${batteryResultId}`,
									{
										state: {
											activeTab: ReportBatteryTabs.PHQ8,
										},
									}
								);
							},
						}}
					/>
				);
			},
		}),
		[dateFormat, navigate, tFunction]
	);

	const resultFlagsConfig = useMemo(() => {
		return [
			{
				batteryDisplayKey: ResultsConst.DAC,
				indications: [resultFlagConfigRDAC],
			},
		];
	}, [resultFlagConfigRDAC]);

	return resultFlagsConfig;
};
