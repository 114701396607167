import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Divider } from '@mantine/core';
import styled from 'styled-components';

import { getConfidenceScoreData } from '../../LongitudinalEpsom.helpers';
import { ConfidenceScoreCard } from '../ConfidenceScoreCard';
import { RankedAndRatedAnswers } from '../RankedAndRatedAnswers/RankedAndRatedAnswers';
import { AnswerRow } from '../AnswerRow/AnswerRow';
import { getConfidenceRatingDisplayKeys } from '../../../../common/helpers/confidenceScoreUtils';
import { TableHeader as ColumnsHeader } from '../../../../common/components/table/TableHeader';
import { TotalScoreText } from '../../../../common/components/table/TotalScoreText';
import { Caption } from '../../../../../../designSystem/Text';
import { AlertShieldSolid } from '../../../../../../shared/designSystem/Icons';
import { theme } from '../../../../../../../providers/styleProvider';

type Props = { data: ReturnType<typeof getConfidenceScoreData> };

export const ConfidenceScoreTab = (props: Props) => {
	const { t } = useTranslation();
	const epsomTranslations = useMemo(
		() => getConfidenceRatingDisplayKeys(t),
		[t]
	);
	const columnHeadings = useMemo(() => {
		return epsomTranslations?.card?.list;
	}, [epsomTranslations]);
	const footerText = useMemo(() => {
		return epsomTranslations?.card?.footer;
	}, [epsomTranslations?.card?.footer]);

	if (!props.data) return null;

	return (
		<div>
			<Space h={'md'} />
			<ConfidenceScoreCard
				totalScore={props.data.totalScore}
				breakdown={props.data.breakdown}
			/>
			<RankedAndRatedAnswers
				sectionHeaderProps={{
					cardHeading: t(
						'web.report.longEpsom.confidenceScore.rankedRated.title'
					),
					cardHeadingDescriptions: [
						<>
							<CenteredDiv
								data-testid={'header-description-svg-icon'}>
								<AlertShieldSolid
									height={12}
									width={12}
									color={theme.color.alertError}
								/>
							</CenteredDiv>
							<Space w={4} />
							{/* Header Description text */}
							<StyledCaption
								style={{
									color: theme.color.textAlert,
									alignContent: 'center',
								}}
								data-testid={'header-description-text'}>
								{t(
									'web.report.longEpsom.confidenceScore.rankedRated.badges.decline'
								)}
							</StyledCaption>
						</>,
					],
					subheading: t(
						'web.report.longEpsom.confidenceScore.rankedRated.subtitle'
					),
				}}
				renderHeader={() => (
					<ColumnsHeader textToDisplay={columnHeadings} />
				)}
				renderRows={() =>
					(props.data?.rankedAnswers ?? []).map((row) => (
						<AnswerRow {...row} />
					))
				}
				renderFooter={() => (
					<>
						<Space h='md' />
						<Divider />
						<Space h='md' />
						<TotalScoreText
							totalScoreText={footerText?.totalScore}
							totalScoreValue={props.data?.totalScore ?? 0}
						/>
					</>
				)}
			/>
		</div>
	);
};

const CenteredDiv = styled.div(
	() => `
    display: flex;
    justify-content: center;
    align-items: center; 
  `
);

const StyledCaption = styled(Caption)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.bodyText};
`
);
