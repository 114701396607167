import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { AssessmentStatus } from './AssessmentStatuses';
import { H4 } from '../../../../components/shared/designSystem';
import { AssignmentType } from '../../../../generated/graphql';

type AssessmentStatusDescriptionProps = {
	assessment: AssessmentStatus;
	transKey: string;
};

export const AssessmentStatusDescription = ({
	assessment,
	transKey,
}: AssessmentStatusDescriptionProps): JSX.Element => {
	const { t } = useTranslation();
	const testId = `${assessment.assessmentName.replace(/ /g, '')}_description`;
	const completionWay =
		assessment.type === AssignmentType.Web
			? t('web.shared.remotely')
			: t('web.shared.inClinic');

	const visitTypeTextWithPadding = assessment.visitType
		? ` for ${assessment.visitType} `
		: ' ';

	const descriptionText = {
		...assessment,
		completionWay,
		visitTypeText: visitTypeTextWithPadding,
	};

	return (
		<>
			<StyledDescription data-testid={testId}>
				<Trans
					i18nKey={t(transKey)}
					components={{
						bold: <b />,
					}}
					values={descriptionText}
				/>
			</StyledDescription>
			<StyledSeparator />
		</>
	);
};

const StyledDescription = styled(H4)(
	({ theme: { spacing } }) => `
	padding: ${spacing.md} 0;
`
);

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	&:not(:last-child) {
		position: relative;
		width: 100%;
		border-bottom: 1px solid ${color.infoSeparator};
	}
`
);
