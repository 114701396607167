import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { icons } from '../../../enums/icons';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { useAuthServiceContext } from '../../../features/auth-service';
import {
	ForgotPasswordModel,
	getModel,
	validationSchema,
} from '../../../schemas/forms/forgotPasswordSchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { ButtonLg, H2, Icon } from '../../shared/designSystem';

const ForgotPasswordForm = (): JSX.Element => {
	const { t } = useTranslation();
	const auth = useAuthServiceContext();

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const messages = {
		success: t`web.auth.shared.messages.forgotPasswordSuccess`,
		error: t`web.auth.shared.messages.forgotPasswordError`,
	};

	const onSubmit = async (
		values: ForgotPasswordModel,
		{ setSubmitting }: FormikHelpers<ForgotPasswordModel>
	) => {
		const success = await auth.requestPasswordReset(values.username);
		if (success) {
			setServerSideMessage(messageEnum.success(messages.success));
		} else {
			setServerSideMessage(messageEnum.error(messages.error));
		}

		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledForgotPassword>
							<StyledLink to='/auth/login'>
								<Icon
									icon={icons.ArrowCircleLeft}
									title={t`web.shared.arrowLeft`}
									width={42}
									height={42}
								/>
							</StyledLink>
							<StyledH2>
								<Trans i18nKey='web.shared.forgotPassword' />
							</StyledH2>
						</StyledForgotPassword>
						<LinusInput
							data-id='forgot-password-email-input'
							name='username'
							type='email'
							label={t`web.shared.emailAddress`}
						/>
						<InfoMessage
							messageEnum={serverSideMessage}
							showIf={!!serverSideMessage}
						/>
						<StyledButtonWrapper>
							<ButtonLg
								disabled={!(isValid && dirty) || isSubmitting}
								dataId='forgot-password-submit-button'
								text={t`web.shared.resetPassword`}
								type={'submit'}
								width='317px'
								primary={true}
							/>
						</StyledButtonWrapper>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { ForgotPasswordForm };

const StyledForm = styled(Form)`
	position: absolute;
	width: 578px;
	height: 601px;
	padding: 32px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: 0.4s ease all;
`;

const StyledLink = styled(Link)`
	align-self: flex-start;
	padding: 0 32px 0 0;
	transition: 0.4s ease all;

	&:hover {
		cursor: pointer;
	}
`;
const StyledH2 = styled(H2)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.md} 0;	
	`
);
const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 0 0;
	`
);

const StyledForgotPassword = styled.div`
	display: flex;
	justify-content: center;
`;
