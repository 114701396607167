import * as yup from 'yup';

import { Gender, Handedness, Participant } from 'api/participant';
import { first } from 'lodash';
import { ContactPreference } from '../../components/participant/participantHelpers';
import { Enumeration, VLEnum } from '../../enums/enumeration';
import i18n, { t } from '../../i18n';
import { maxCharacterLimits } from '../../utils/stringUtils';
import { extract } from '../../utils/extract';
import { contactPhoneValidator } from '../../utils/validationHelpers';

export type EditParticipantModel = {
	id: string;
	externalId: string;
	birthYear: string;
	contactEmail?: string;
	contactPhone?: string;
	contactPreference?: ContactPreference;
	gender?: Gender;
	ethnicityIds?: string;
	raceId?: string;
	educationId?: string;
	handedness?: Handedness;
	language?: string;
	notes?: string;
};

export const getModel = (
	organizationParticipantLanguages: Enumeration<VLEnum>,
	participant?: Participant
): EditParticipantModel => {
	return {
		...participant,
		id: participant?.id,
		birthYear: participant?.birthYear?.toString(),
		contactEmail: participant?.contactEmail || '',
		contactPhone: participant?.contactPhone || '',
		contactPreference: participant?.contactPreference || null,
		gender: participant?.gender,
		ethnicityIds: extract(participant?.ethnicity, 'id').join(', '),
		raceId: first(extract(participant?.race, 'id')),
		educationId: first(extract(participant?.education, 'id')),
		handedness: participant?.handedness,
		language: participant?.language
			? organizationParticipantLanguages.fromValue(participant?.language)
					?.value
			: '',
		externalId: participant?.externalId,
	} as EditParticipantModel;
};

export const editParticipantSchema = yup.object().shape({
	birthYear: yup
		.number()
		.required(i18n.t('web.shared.forms.schemas.required'))
		.min(1900)
		.max(new Date().getFullYear()),
	gender: yup.string().nullable(),
	educationId: yup.string(),
	handedness: yup.string().nullable(),
	language: yup.string().required(t`web.shared.forms.schemas.required`),
	notes: yup
		.string()
		.max(
			maxCharacterLimits.notes,
			t`web.shared.forms.schemas.maxCharLimit` +
				` (${maxCharacterLimits.notes.toLocaleString()})`
		)
		.optional()
		.nullable(),
	externalId: yup
		.string()
		.test(
			'is-whitespace-only',
			i18n.t('web.shared.forms.schemas.required'),
			(value) => {
				return value ? !value.match(/^\s{1,}$/i) : !!value;
			}
		)
		.min(1, i18n.t('web.shared.forms.schemas.required'))
		.max(
			maxCharacterLimits.externalId,
			t`web.shared.forms.schemas.maxExternalId`
		),
	contactPhone: yup
		.string()
		.optional()
		.nullable()
		.concat(contactPhoneValidator()),
	contactEmail: yup
		.string()
		.optional()
		.nullable()
		.email(i18n.t('web.shared.forms.schemas.validEmail')),
});
