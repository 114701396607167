import { useNavigate } from 'react-router-dom';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonSm } from '../../designSystem/ButtonSm';
import { ShowIfAuthorized } from '../../ShowIfAuthorized';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import { useMediaQuery } from '@mantine/hooks';

type ViewResultButtonProps = {
	participantId: string | undefined;
	batteryResultId: string | undefined;
	text?: string | undefined;
	disabled?: boolean | undefined;
	width?: string;
};

const mq = '(min-width: 1000px) and (max-width: 1500px)';
const ViewResultButton = ({
	participantId,
	batteryResultId,
	text = `web.dataTable.viewResultsButton.view`,
	disabled,
	width = '160px',
}: ViewResultButtonProps): JSX.Element => {
	const { t } = useTranslation();
	const isDisabled = disabled || !(batteryResultId || participantId);
	const navigate = useNavigate();
	const isShortened = useMediaQuery(mq);
	const display = truncate(t`${text}`, {
		length: isShortened ? 10 : 100,
		omission: '',
		separator: ' ',
	});

	return (
		<ShowIfAuthorized operations={[OperationToken.ViewAllBatteryResults]}>
			<ButtonSm
				text={display}
				primary={false}
				disabled={isDisabled}
				onClick={() =>
					navigate(`/results/${participantId}/${batteryResultId}`)
				}
				width={isShortened ? '120px' : width}
			/>
		</ShowIfAuthorized>
	);
};

export { ViewResultButton };
