import * as yup from 'yup';
import i18n from '../../i18n';
import { CurrentUser } from '../../types';
import { phoneRegExp } from '../../components/shared/Forms/utils/phoneNumberFormat';

export type UserProfileModel = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	avatarUrl: string;
	suffixIds: string;
	roleId: string;
};

export const getModel = (user?: CurrentUser): UserProfileModel => {
	const userSuffixIds = user?.suffix?.map((v) => v?.id).join(', ');
	return {
		id: user?.id,
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		email: user?.email || '',
		phoneNumber: user?.phoneNumber || '',
		avatarUrl: user?.avatarUrl || '',
		suffixIds: userSuffixIds,
		roleId: user?.roleId || '',
	} as UserProfileModel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validationSchema = () => {
	return yup.object().shape(
		{
			firstName: yup
				.string()
				.required(i18n.t('web.shared.forms.schemas.required')),
			lastName: yup
				.string()
				.required(i18n.t('web.shared.forms.schemas.required')),
			email: yup
				.string()
				.email(i18n.t('web.authErrors.invalidEmail'))
				.required(i18n.t('web.authErrors.emailRequired')),
			phoneNumber: yup
				.string()
				.max(20, i18n.t('web.shared.forms.schemas.invalidPhone'))
				.nullable()
				.notRequired()
				.when('phoneNumber', {
					is: (value: string) => value?.length,
					then: yup
						.string()
						.matches(
							phoneRegExp,
							i18n.t('web.shared.forms.schemas.invalidPhone')
						),
				}),
		},
		[
			// Add cyclic dependencies when making 'requires' to itself
			['phoneNumber', 'phoneNumber'],
		]
	);
};
