import styled from 'styled-components';

import { Divider } from '@mantine/core';

import { BreakdownInfo } from './BreakdownInfo';

import { ScoresProps } from './Breakdown.types';

interface BreakdownDCRExpandedProps {
	scores: ScoresProps[][];
}

export function BreakdownDCRExpanded({
	scores,
}: Readonly<BreakdownDCRExpandedProps>) {
	return (
		<BreakdownsContainer>
			{scores.map((item, index) => {
				return (
					<BreakdownsInfoContainer>
						<BreakdownInfoWrapper>
							<BreakdownInfo
								isImpaired={item[0].isImpaired}
								percentile={item[0].percentile}
								score={item[0].value}
								title={item[0].title}
							/>
							<Divider />
							{item[1] && (
								<BreakdownInfo
									isImpaired={item[1].isImpaired}
									percentile={item[1].percentile}
									score={item[1].value}
									title={item[1].title}
								/>
							)}
						</BreakdownInfoWrapper>
						{index % 2 === 0 && (
							<StyledDivider orientation='vertical' />
						)}
					</BreakdownsInfoContainer>
				);
			})}
		</BreakdownsContainer>
	);
}

const BreakdownsContainer = styled.div`
	display: flex;
`;

const BreakdownsInfoContainer = styled.div`
	display: flex;
`;

const BreakdownInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledDivider = styled(Divider)`
	height: 100%;
`;
