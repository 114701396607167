import { Variants } from 'framer-motion';

// Used to calculate animations for dropdown lists (select & multi)
// If 'dropUpSpace' is present, we calculate how far to slide the list up
export const listVariants = (dropUpSpace?: string): Variants => {
	const pos = dropUpSpace
		? { bottom: `calc(100% + ${dropUpSpace})` }
		: { top: 'calc(100% + 8px)' };

	const posInit = dropUpSpace
		? { bottom: `calc(100% + ${dropUpSpace})`, top: 'unset' }
		: { top: '0px' };

	return {
		initial: {
			opacity: 0,
			...posInit,
		},
		in: {
			opacity: 1,
			...pos,
			zIndex: 2,
		},
		out: {
			opacity: 0,
			top: '0px',
		},
	};
};
