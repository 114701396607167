import { useEffect, useState } from 'react';

import { ArtifactFormats, useGetReportData } from 'components/shared/hooks';
import { Mp4 } from '../../../../../../artifacts/ReportArtifactNames';
import { AudioCard } from '../../../../../../components/report/common/AudioCard/AudioCard';
import { FormattedSegmentResult } from '../../../../common';

type RecallRecordingProps = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayback?: boolean;
};

const RecallRecording = ({
	data,
	showAudioPlayback = false,
}: RecallRecordingProps) => {
	const [segmentResultId, setSegmentResultId] = useState<string>('');

	useEffect(() => {
		const segmentResultId = data?.rawDataUrl?.split('segments/')?.[1] ?? '';
		setSegmentResultId(segmentResultId);
	}, [data, setSegmentResultId]);

	const { loading, error, metadata } = useGetReportData({
		segmentResultId,
		fileName: Mp4.ImmediateMP4,
		fileType: ArtifactFormats.MP4,
	});

	if (loading || error) return null;

	return (
		<div>
			{showAudioPlayback && (
				<AudioCard
					text={{
						cardHeading:
							(data?.segment?.name as string) || '[Segment Name]',
						cardSubHeadings: [
							'Prompt: ' +
								(data?.metricItems['expected_words']
									?.value as string) || '[Expected words]',
						],
					}}
					audio={{
						source: metadata as string,
						fileType: ArtifactFormats.MP4,
					}}
				/>
			)}
		</div>
	);
};

export { RecallRecording };
