import { sendEventData } from '../../../analytics/amplitude';
import { t } from '../../../i18n';
import { AnalyticsAction } from '../../../generated/graphql';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';

type PrivacyPolicyLinkProps = {
	privacyPolicyUrl?: string;
	text?: string;
};

const PrivacyPolicyLink = ({
	privacyPolicyUrl = 'https://linushealth.com/privacy-policy',
	text = t`web.shared.privacyPolicyLink` as string,
}: PrivacyPolicyLinkProps): JSX.Element => {
	return (
		<ExternalLinkWithIcon
			text={text}
			href={privacyPolicyUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedPrivacyPolicy,
				})
			}
		/>
	);
};

export { PrivacyPolicyLink };
