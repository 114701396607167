import styled from 'styled-components';
import { useGetRawData } from '../../../../../hooks/useGetRawData';
import { FormattedSegmentResult } from '../../../common';
import { RawAnswer } from './LongitudinalEpsom.helpers';
import { LongitudinalEpsomLayout } from './LongitudinalEpsom.layout';

type Props = {
	segmentResult: FormattedSegmentResult;
	initialBatteryResultId: string;
	relatedResults: {
		baseline: FormattedSegmentResult;
		longitudinals: FormattedSegmentResult[];
	};
};
type MinimalRawEpsom = { answers: RawAnswer[] };
export const LongitudinalEpsomTabs = (props: Props) => {
	const { data: rawLongitudinal } = useGetRawData<MinimalRawEpsom>({
		segmentResultId: props.segmentResult.id,
		fileName: 'longitudinal-epsom.json',
	});
	const { data: rawBaseline } = useGetRawData<MinimalRawEpsom>({
		segmentResultId: props.relatedResults.baseline.id,
		fileName: 'epsom.json',
	});
	if (!rawLongitudinal || !rawBaseline) return null;
	return (
		<StyledContainer>
			<LongitudinalEpsomLayout
				segmentResult={props.segmentResult}
				rawData={{
					baseline: rawBaseline,
					currentLongitudinal: rawLongitudinal,
				}}
				relatedResults={{
					baseline: props.relatedResults.baseline,
					longitudinals: props.relatedResults.longitudinals,
				}}
			/>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	align-content: center;
	flex-direction: column;
  margin-top: ${spacing.sm};
`
);
