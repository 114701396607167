import { get, pick } from 'lodash';

import { coerceToBoolean } from '../../../../../utils/coerceToBoolean';
import { MetricHash } from '../../../common';
import { AnswerRowProps as ScoreAnswer } from '../LongitudinalEpsom/components/AnswerRow/AnswerRow';
import { AnswerRowProps as ChangeAnswer } from '../LongitudinalEpsom/components/ChangeInConfidenceTab/components/AnswerRow';

export type ConfidenceScoreData = {
	totalScore: number;
	breakdown: { value: number | string; suffix: string }[];
	rankedAnswers: ScoreAnswer[];
};
export type ConfidenceChangeData = {
	improved: number;
	stable: number;
	declined: number;
	rankedChanges: ChangeAnswer[];
};
export type AspectUpdate = {
	aspect: string;
	description: string;
};

export type Entry = Omit<RawAnswer, 'questionId'>;
export type RawAnswer = {
	type: 'ANSWERED' | 'NOT_PRESENTED' | 'NOT_ANSWERED';
	value?: number;
	questionId: string;
};

/**
 *
 * @param baselineMetrics
 * @param longitudinalMetrics
 * @returns
 */
const getRankedAnswers = (
	baselineMetrics: MetricHash,
	longitudinalMetrics: MetricHash
) => {
	const cumulativeConfidenceLevelData: ScoreAnswer[] = [];
	for (let count = 1; count <= 5; count++) {
		const participantAnswer = get(
			baselineMetrics,
			`item_${count}_text.value`,
			''
		);
		// Previous rating
		const baselineRatingMetricItem = Number(
			get(baselineMetrics, `item_${count}_rating.value`, 0)
		);
		const previousRating = baselineRatingMetricItem ?? 1;
		// Current Rating
		const currentRatingMetricItem = Number(
			get(longitudinalMetrics, `item_${count}_rating.value`, 0)
		);
		const currentRating = currentRatingMetricItem ?? 1;
		// Use "change_from_previous" metric item to decide which arrow icon to show (Up or Down)
		const changeFromPreviousMetricItem = Number(
			get(
				longitudinalMetrics,
				`item_${count}_rating_change_from_previous.value`,
				0
			)
		);
		const changeFromPrevious = changeFromPreviousMetricItem ?? 0;
		const arrowIconColor =
			changeFromPrevious === 0
				? undefined
				: changeFromPrevious > 0
				? 'green'
				: 'red';

		const changeFromPreviousDisplayValue = Math.abs(changeFromPrevious);

		// Use "warning_badge" metric items to indicate Low Confidence
		const isLowConfidenceIndicator = coerceToBoolean(
			get(longitudinalMetrics, `item_${count}_warning_badge.value`)
		);

		// Cumulative data
		const confidenceLevelData: ScoreAnswer = {
			itemNumber: count,
			participantAnswer: participantAnswer?.toString(),
			rating: {
				isLowConfidenceIndicator: isLowConfidenceIndicator,
				changeFromPrevious: changeFromPreviousDisplayValue,
				arrowIconColor: arrowIconColor,
			},
			score: {
				previous: previousRating,
				current: currentRating,
			},
		};
		cumulativeConfidenceLevelData.push(confidenceLevelData);
	}
	return cumulativeConfidenceLevelData;
};

/**
 *
 * @param metricItems
 * @returns
 */
export const getConfidenceScoreData = (
	baselineMetrics: MetricHash | undefined,
	longitudinalMetrics: MetricHash | undefined
): ConfidenceScoreData | null => {
	if (!baselineMetrics || !longitudinalMetrics) return null;
	const totalScoreMetricItem = Number(
		get(longitudinalMetrics, 'total_confidence.value', 0)
	);
	const changedFromPrevious = Number(
		get(longitudinalMetrics, 'n_changed_from_previous.value', 0)
	);
	const achievedConfidenceScore = totalScoreMetricItem ?? 0;
	const rankedAnswers = getRankedAnswers(
		baselineMetrics,
		longitudinalMetrics
	);
	return {
		totalScore: achievedConfidenceScore,
		breakdown: [
			{
				value: changedFromPrevious,
				suffix: 'web.report.longEpsom.confidenceScore.changeSuffix',
			},
		],
		rankedAnswers: rankedAnswers,
	};
};

/**
 *
 * @param metricItems
 * @returns
 */
export const getConfidenceChangeData = (
	baselineMetrics: MetricHash | undefined,
	longitudinalMetrics: MetricHash | undefined,
	rawLongitudinal: RawAnswer[]
): ConfidenceChangeData | null => {
	if (!baselineMetrics || !longitudinalMetrics) return null;
	const improved = Number(
		get(longitudinalMetrics, 'n_improved_recently.value', 0)
	);
	const declined = Number(
		get(longitudinalMetrics, 'n_declined_recently.value', 0)
	);
	const stable = Number(
		get(longitudinalMetrics, 'n_stable_recently.value', 0)
	);
	const rankedChanges = getRankedChanges(baselineMetrics, rawLongitudinal);
	return {
		improved,
		stable,
		declined,
		rankedChanges,
	};
};

/**
 *
 * @param metricItems
 * @returns
 */
export const getImportantAspectUpdates = (
	baselineMetrics: MetricHash | undefined,
	longitudinal: RawAnswer[] | undefined
): AspectUpdate[] | null => {
	if (!longitudinal || !baselineMetrics) return null;
	const longitudinalHash = longitudinal.reduce(toHash, {});
	const aspects: AspectUpdate[] = [];

	for (let i = 1; i < 6; i++) {
		const aspect = get(baselineMetrics, `item_${i}_text`)?.value.toString();
		const description = get(
			longitudinalHash,
			`optional_comments_${i}`
		)?.value?.toString();
		if (description && aspect)
			aspects.push({
				aspect,
				description,
			});
	}
	return aspects;
};

/**
 *
 * @param baselineMetrics
 * @param longitudinalMetrics
 * @returns
 */
const getRankedChanges = (
	baselineMetrics: MetricHash,
	rawAnswers: RawAnswer[]
) => {
	const cumulativeConfidenceLevelData: ChangeAnswer[] = [];
	const rawResultMap = rawAnswers.reduce(toHash, {});
	for (let count = 1; count <= 5; count++) {
		const participantAnswer = get(
			baselineMetrics,
			`item_${count}_text.value`,
			''
		);
		const answer = deriveAnswer(rawResultMap, count);
		// Cumulative data
		const confidenceLevelData: ChangeAnswer = {
			itemNumber: count,
			participantAnswer: participantAnswer?.toString(),
			answer: answer?.type ?? 'NONE',
			rate: Number(answer?.value),
		};
		cumulativeConfidenceLevelData.push(confidenceLevelData);
	}
	return cumulativeConfidenceLevelData;
};

export function toHash(
	acc: Record<string, Entry>,
	v: RawAnswer
): Record<string, Entry> {
	return {
		...acc,
		[v.questionId]: pick(v, 'type', 'value'),
	};
}

function deriveAnswer(metrics: Record<string, Entry>, num: number) {
	const positive = get(metrics, `more_confident_rating_${num}`);
	const negative = get(metrics, `less_confident_rating_${num}`);
	if (
		positive?.type === 'NOT_PRESENTED' &&
		negative?.type === 'NOT_PRESENTED'
	)
		return {
			type: 'NONE' as const,
			value: 0,
		};
	if (positive?.type === 'ANSWERED')
		return {
			type: 'INCREASE' as const,
			value: positive.value as number,
		};

	if (negative?.type === 'ANSWERED')
		return {
			type: 'DECREASE' as const,
			value: negative.value as number,
		};
	return undefined;
}
