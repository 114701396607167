import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { icons } from '../../../enums/icons';
import { Icon } from '../designSystem/Icon';

type ChipProps = {
	id?: string | number;
	labelTag: string;
	isActive?: boolean;
};

export const Chip = ({ labelTag, isActive }: ChipProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	const activeIcon = (
		<StyledActiveIcon data-testid={`active_chip_icon_${labelTag}`}>
			<Icon
				title='Checkmark'
				icon={icons.CheckmarkSolid}
				color={theme.color.chipActiveColor}
				height='12px'
				width='12px'
			/>
		</StyledActiveIcon>
	);

	return (
		<StyledChip isActive={!!isActive}>
			{isActive && activeIcon}
			{t(labelTag)}
		</StyledChip>
	);
};

type StyledChipProps = {
	isActive: boolean;
};

const StyledChip = styled.div<StyledChipProps>(
	({ theme: { color, spacing, fontSize, weight }, isActive }) => `
	min-height: 32px;
	min-width: 126px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: ${spacing.xs} ${spacing.sm};
	border-radius: ${spacing.xs};
  	${isActive && `color: ${color.chipActiveColor}`};
	background: ${isActive ? color.chipActiveBackgroundColor : color.white};
	border: 1px solid ${
		isActive ? color.chipActiveBorderColor : color.chipBorderColor
	};
	font-size: ${fontSize._12};
	font-weight: ${weight.medium};
	line-height: ${fontSize._14};
	text-transform: uppercase;
  	cursor: pointer;
	margin: ${spacing.xs};
`
);

const StyledActiveIcon = styled.span`
	padding-right: 4px;
`;
