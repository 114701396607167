import { CellContext } from '@tanstack/react-table';
import { ReactNode, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
	SimpleTable,
	Trials,
	Typography,
	theme,
} from '@lh/eng-web-mosaic-common';

import { InfoTooltip } from '../../common/InfoTooltip/InfoTooltip';

export type BackwardsDigitSpanTableProps = {
	data: {
		trialName: string;
		score: {
			anyOrder: {
				requiresReview: boolean;
				text: string;
			};
			serialOrder: {
				requiresReview: boolean;
				text: string;
			};
		};
	}[];
};

const getBdstColumns = (t: TFunction) => {
	return [
		{
			accessorKey: 'breakdown',
			header: () => (
				<Typography.P2
					weight='500'
					color={theme?.color?.bodyTextSecondary}>
					{t('web.report.verbalFluency.breakdown')}
				</Typography.P2>
			),
			cell: (props: CellContext<unknown, unknown>) => {
				const value = props.getValue<ReactNode>();
				return <span>{value}</span>;
			},
		},
		{
			accessorKey: 'anyOrder',
			header: () => (
				<Row>
					<Typography.P1 weight='600'>
						{t('web.report.verbalFluency.bdst.anyOrder.name')}
					</Typography.P1>
					<InfoTooltip
						tooltipText={t(
							'web.report.verbalFluency.bdst.anyOrder.tooltipText'
						)}
					/>
				</Row>
			),
			cell: (props: CellContext<unknown, unknown>) => {
				const value = props.getValue<ReactNode>();
				return <span>{value}</span>;
			},
		},
		{
			accessorKey: 'serialOrder',
			header: () => (
				<Row>
					<Typography.P1 weight='600'>
						{t('web.report.verbalFluency.bdst.serialOrder.name')}
					</Typography.P1>
					<InfoTooltip
						tooltipText={t(
							'web.report.verbalFluency.bdst.serialOrder.tooltipText'
						)}
					/>
				</Row>
			),
			cell: (props: CellContext<unknown, unknown>) => {
				const value = props.getValue<ReactNode>();
				return <span>{value}</span>;
			},
		},
	];
};

const BackwardsDigitSpanTable = (props: BackwardsDigitSpanTableProps) => {
	const { t } = useTranslation();

	const BDST_TABLE_COLS = useMemo(() => getBdstColumns(t), [t]);

	const mapPropsToTableData = useMemo(() => {
		let mappedData: unknown[] = [];
		const numbersText = t('web.report.verbalFluency.numbers');
		const cannotCalculateText = t(
			'web.report.verbalFluency.cannotCalculate'
		);
		if (props?.data) {
			if (Array.isArray(props?.data)) {
				mappedData = props?.data?.map(({ trialName, score }) => {
					return {
						breakdown: (
							<Typography.P1 weight='600'>
								{trialName}
							</Typography.P1>
						),
						anyOrder: (
							<Trials
								responseText={
									score?.anyOrder?.requiresReview
										? cannotCalculateText
										: numbersText
								}
								score={score?.anyOrder?.text}
								title={''}
							/>
						),
						serialOrder: (
							<Trials
								responseText={
									score?.anyOrder?.requiresReview
										? cannotCalculateText
										: numbersText
								}
								score={score?.serialOrder?.text}
								title={''}
							/>
						),
					};
				});
			}
		}
		return mappedData;
	}, [props?.data, t]);

	return (
		<div>
			<SimpleTable
				striped={false}
				horizontalSpacing='xs'
				withColumnBorders
				withRowBorders
				columns={BDST_TABLE_COLS}
				data={mapPropsToTableData}
			/>
		</div>
	);
};

export { BackwardsDigitSpanTable };

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
