import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { setSessionStorage } from 'utils/sessionStorage';
import { SessionStorageEnum } from 'enums/sessionStorageKeysEnum';
import { LoadingDots } from 'components/shared/LoadingDots';

export const RemoteAuth = (): JSX.Element | null => {
	const [searchParams] = useSearchParams();
	const orgId = searchParams?.get('orgId');
	const [loading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!orgId) return;

		setSessionStorage(
			{ organizationId: orgId },
			SessionStorageEnum.ImpersonatedOrg
		);
		setIsLoading(false);
	}, [orgId]);

	if (loading) return <LoadingDots />;
	return <Navigate to={'/organization'} />;
};
