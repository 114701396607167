import { CellContext } from '@tanstack/react-table';

import i18n from 'i18next';

import { Column, Divider, Selection } from './DelayedRecognitionTable.style';
import { AnswerResponse } from '../../DelayedRecognition.types';
import { TableWord, Typography } from '@lh/eng-web-mosaic-common';
import { Trans } from 'react-i18next';

export type DelayedRecognitionData = {
	group: string;
	selection: JSX.Element;
};

export const COLUMNS = [
	{
		accessorKey: 'group',
		header: () => (
			<Typography.P1 style={{ textTransform: 'uppercase' }} weight='500'>
				<Trans
					i18nKey={
						'web.report.verbalFluency.delayedRecognition.table.group'
					}
				/>
			</Typography.P1>
		),
		cell: (props: CellContext<DelayedRecognitionData, unknown>) => {
			const value = props.getValue<React.ReactNode>();
			return <Typography.P1 weight='500'>{value}</Typography.P1>;
		},
	},
	{
		accessorKey: 'selection',
		header: () => (
			<Typography.P1 style={{ textTransform: 'uppercase' }} weight='500'>
				<Trans
					i18nKey={
						'web.report.verbalFluency.delayedRecognition.table.selections'
					}
				/>
			</Typography.P1>
		),
		cell: (props: CellContext<DelayedRecognitionData, unknown>) => {
			const value = props.getValue<React.ReactNode>();
			return <Column>{value}</Column>;
		},
	},
];

const uppercaseWord = (word: string) =>
	word[0].toUpperCase() + word.substring(1);

export const buildTableData = (answers: AnswerResponse[]) => {
	if (!answers) return [];
	return answers?.map((answerGroup, i) => {
		const { answer, answerOptions, correctAnswer } = answerGroup;
		const selectedCorrectAnswer = answer === correctAnswer;
		return {
			group: `${i18n.t(
				'web.report.verbalFluency.delayedRecognition.table.group'
			)} ${i + 1}`,
			selection: (
				<Column data-testid={'individual-answer-column'}>
					{answerOptions.map((option, _index) => (
						<div key={option?.toString()}>
							<TableWord
								key={`${option}_${_index}`}
								correct={option === correctAnswer}
								selected={option === answer}>
								<Selection
									$selected={option === answer}
									$unselectedCorrect={
										!selectedCorrectAnswer &&
										option === answer
									}>
									{uppercaseWord(option)}
								</Selection>
							</TableWord>
							{answerOptions.length - 1 !== _index && <Divider />}
						</div>
					))}
				</Column>
			),
		};
	});
};
