// TODO: this will need to be updated if we start supporting transltions in Research (or whomever consumes this package needs it)
export const mapBatteryName = (batteryName?: string): string => {
	switch (batteryName) {
		case 'DCR':
			return 'Digital Clock And Recall';
		case undefined:
			return '';
		default:
			return batteryName;
	}
};
