import { BasicOption } from '../../../types';
import { IssueType, OrganizationType } from '../../../generated/graphql';
import i18n from '../../../i18n';

export const buildIssueOptions = (
	orgType: string = OrganizationType.Research
): BasicOption[] => {
	// Need to manually create list of issues to maintain order
	const issueOptions = [
		IssueType.Declined,
		IssueType.Motor,
		IssueType.Hearing,
		IssueType.Fatigue,
		IssueType.Vision,
		IssueType.Device,
		IssueType.PrimaryLang,
	];
	return issueOptions.map((issue: IssueType | string) => {
		const displayKey = issue.valueOf();
		let ctx = {};
		if (
			orgType === OrganizationType.Research &&
			issue === IssueType.Declined
		) {
			ctx = {
				context: orgType,
			};
		}

		return {
			value: issue,
			display: i18n.t(
				`web.shared.removeBatteryModal.issues.${displayKey}`,
				ctx
			),
		};
	});
};
