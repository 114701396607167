import { ReactNode, useMemo } from 'react';
import { RemoteOrgSessionProvider } from './RemoteOrgSessionProvider';
import { OrganizationSessionProvider } from './OrganizationSessionProvider';
import { useGetIsDeeplinking } from '../../hooks/useGetIsDeeplinking';

export const OrgIdCheck = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const isDeeplinking = useGetIsDeeplinking();

	const OrgProvider = useMemo(() => {
		if (isDeeplinking) {
			return RemoteOrgSessionProvider;
		} else {
			return OrganizationSessionProvider;
		}
	}, [isDeeplinking]);

	return <OrgProvider>{children}</OrgProvider>;
};
