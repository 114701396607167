// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const InfoOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='InfoOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'InfoOutlined'}</title>

			<path
				d='M9.21679 4H8.78588C8.54922 4 8.35781 4.19279 8.35781 4.43118V5.56883C8.35781 5.76161 8.54921 6 8.78588 6H9.21679C9.45345 6 9.64486 5.80721 9.64486 5.56883V4.43118C9.64486 4.23839 9.45346 4 9.21679 4Z'
				fill='current'
			/>
			<path
				d='M9.21679 7.02019H8.78588C8.54922 7.02019 8.35781 7.21298 8.35781 7.45137V13.5688C8.35781 13.7616 8.54921 14 8.78588 14H9.21679C9.45345 14 9.64486 13.8072 9.64486 13.5688V7.45137C9.64486 7.25858 9.45346 7.02019 9.21679 7.02019Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
				fill='current'
			/>
		</svg>
	);
};
