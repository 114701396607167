import { TFunction } from 'i18next';
import { inRangeInclusive } from '../../../../../utils/rangeUtils';
import {
	ConfidenceScoreRange,
	ConfidenceScoreRangeBgColorMap,
	ConfidenceScoreRangeTransKeyMap,
	confidenceScoreRangeValues,
} from '../constants/confidenceScore';

const isNoLossOfConfidenceScore = (score: number) => {
	return inRangeInclusive(
		score,
		confidenceScoreRangeValues[ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE]
			.start,
		confidenceScoreRangeValues[ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE]
			.end
	);
};

const isSlightLossOfConfidenceScore = (score: number) => {
	return inRangeInclusive(
		score,
		confidenceScoreRangeValues[
			ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE
		].start,
		confidenceScoreRangeValues[
			ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE
		].end
	);
};

const isSomeLossOfConfidenceScore = (score: number) => {
	return inRangeInclusive(
		score,
		confidenceScoreRangeValues[ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE]
			.start,
		confidenceScoreRangeValues[ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE]
			.end
	);
};

const isFairLossOfConfidenceScore = (score: number) => {
	return inRangeInclusive(
		score,
		confidenceScoreRangeValues[ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE]
			.start,
		confidenceScoreRangeValues[ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE]
			.end
	);
};

const isCompleteLossOfConfidenceScore = (score: number) => {
	return inRangeInclusive(
		score,
		confidenceScoreRangeValues[
			ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE
		].start,
		confidenceScoreRangeValues[
			ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE
		].end
	);
};

/**
 *
 * @param score
 * @returns
 */
export const getConfidenceScoreRange = (
	score: number
): ConfidenceScoreRange | null => {
	if (isNaN(score)) return null;
	if (isNoLossOfConfidenceScore(score)) {
		return ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE;
	} else if (isSlightLossOfConfidenceScore(score)) {
		return ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE;
	} else if (isSomeLossOfConfidenceScore(score)) {
		return ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE;
	} else if (isFairLossOfConfidenceScore(score)) {
		return ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE;
	} else if (isCompleteLossOfConfidenceScore(score)) {
		return ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE;
	} else {
		return null;
	}
};

/**
 *
 * @param score
 * @returns
 */
export const getConfidenceScoreDescriptionTransKey = (
	scoreRange: ConfidenceScoreRange
) => {
	const prefix = 'research.epsomConfidenceScore.descriptionForRange';
	switch (scoreRange) {
		case ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE:
			return `${prefix}.${ConfidenceScoreRangeTransKeyMap.NO_LOSS_OF_CONFIDENCE}`;
		case ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE:
			return `${prefix}.${ConfidenceScoreRangeTransKeyMap.SLIGHT_LOSS_OF_CONFIDENCE}`;
		case ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE:
			return `${prefix}.${ConfidenceScoreRangeTransKeyMap.SOME_LOSS_OF_CONFIDENCE}`;
		case ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE:
			return `${prefix}.${ConfidenceScoreRangeTransKeyMap.FAIR_LOSS_OF_CONFIDENCE}`;
		case ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE:
			return `${prefix}.${ConfidenceScoreRangeTransKeyMap.COMPLETE_LOSS_OF_CONFIDENCE}`;
		default:
			return '';
	}
};

/**
 *
 * @param score
 * @returns
 */
export const getConfidenceScoreContainerBgColor = (
	scoreRange: ConfidenceScoreRange
) => {
	return ConfidenceScoreRangeBgColorMap[scoreRange];
};

export const getRangeStartAndEnd = (scoreRange: ConfidenceScoreRange) => {
	return confidenceScoreRangeValues[scoreRange];
};

export const getConfidenceScoreRatingTextTransKey = (
	scoreRange?: ConfidenceScoreRange | null | undefined
) => {
	if (!scoreRange) return '';
	const prefix =
		'research.epsomRankedAndRatedAnswers.card.list.confidenceLevelRating';
	return `${prefix}.${ConfidenceScoreRangeTransKeyMap[scoreRange]}`;
};

export type ConfidenceRatingTableTranslations = {
	title: string;
	card: {
		title: string;
		subtitle: string;
		lowConfidenceIndicator: string;
		list: {
			header: {
				col1: string;
				col2: string;
				col3: string;
			};
			confidenceLevelRating: {
				noLossOfConfidence: string;
				slightLossOfConfidence: string;
				someLossOfConfidence: string;
				fairLossOfConfidence: string;
				completeLossOfConfidence: string;
			};
		};
		footer: {
			totalScore: string;
			endOfQuestionnaire: string;
		};
	};
};

export const getConfidenceRatingDisplayKeys = (
	t: TFunction
): ConfidenceRatingTableTranslations => {
	const i18nPrefix = 'research.epsomRankedAndRatedAnswers';
	const textToDisplay = {
		title: t(`${i18nPrefix}.title`),
		card: {
			title: t(`${i18nPrefix}.card.title`),
			subtitle: t(`${i18nPrefix}.card.subtitle`),
			lowConfidenceIndicator: `= ${t(
				`${i18nPrefix}.card.lowConfidenceIndicator` // = Low Confidence
			)}`,
			list: {
				header: {
					col1: t(`${i18nPrefix}.card.list.header.col1`),
					col2: t(`${i18nPrefix}.card.list.header.col2`),
					col3: t(`${i18nPrefix}.card.list.header.col3`),
				},
				confidenceLevelRating: {
					noLossOfConfidence: t(
						`${i18nPrefix}.card.list.confidenceLevelRating.noLossOfConfidence`
					),
					slightLossOfConfidence: t(
						`${i18nPrefix}.card.list.confidenceLevelRating.slightLossOfConfidence`
					),
					someLossOfConfidence: t(
						`${i18nPrefix}.card.list.confidenceLevelRating.someLossOfConfidence`
					),
					fairLossOfConfidence: t(
						`${i18nPrefix}.card.list.confidenceLevelRating.fairLossOfConfidence`
					),
					completeLossOfConfidence: t(
						`${i18nPrefix}.card.list.confidenceLevelRating.completeLossOfConfidence`
					),
				},
			},
			footer: {
				totalScore: t(`${i18nPrefix}.card.footer.totalScore`),
				endOfQuestionnaire: t(
					`${i18nPrefix}.card.footer.endOfQuestionnaire`
				),
			},
		},
	};
	return textToDisplay;
};

export const mapScoreToConfidenceRating = (
	score: number
): ConfidenceScoreRange | undefined => {
	switch (score) {
		case 1:
			return ConfidenceScoreRange.COMPLETE_LOSS_OF_CONFIDENCE;
		case 2:
			return ConfidenceScoreRange.FAIR_LOSS_OF_CONFIDENCE;
		case 3:
			return ConfidenceScoreRange.SOME_LOSS_OF_CONFIDENCE;
		case 4:
			return ConfidenceScoreRange.SLIGHT_LOSS_OF_CONFIDENCE;
		case 5:
			return ConfidenceScoreRange.NO_LOSS_OF_CONFIDENCE;
		default:
			return undefined;
	}
};
