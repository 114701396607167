import { useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { isNonEmptyString } from '../../utils/stringUtils';
import { QueryKey } from '../query';
import { GetRolesByOrgIdInput, Role } from './types';

export async function fetchRolesForOrganization(
	input: GetRolesByOrgIdInput
): Promise<Role[]> {
	if (!input.organizationId) {
		throw new Error('fetchRolesForOrganization: organization ID invalid');
	}
	const service = await getOrganizationService();
	const { data } = await service.getRolesByOrgId({
		organizationId: input?.organizationId,
	});
	return data;
}

export function useGetRolesByOrgId(input: GetRolesByOrgIdInput) {
	const organizationId = input?.organizationId ?? '';
	return useQuery({
		enabled: isNonEmptyString(organizationId),
		meta: {
			errorMessage: `Error fetching roles for organization: ${organizationId}`,
		},
		queryKey: [QueryKey.Roles, organizationId],
		queryFn: () => fetchRolesForOrganization(input),
		staleTime: Infinity,
	});
}
