import { useTheme } from 'styled-components';
import {
	PaginatedLinusSearchBar,
	PaginatedLinusSearchBarProps,
} from '../../inputs/LinusSearchBar';

export const PaginatedDataTableSearchBar = ({
	value,
	placeholder = '',
	onChange,
	onClear,
}: PaginatedLinusSearchBarProps): JSX.Element => {
	const theme = useTheme();

	return (
		<PaginatedLinusSearchBar
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			onClear={onClear}
			searchComponentMargin={`0 ${theme.spacing.md}`}
		/>
	);
};
