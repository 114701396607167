import {
	fetchLHQ32Questions,
	fetchLHQ15Questions,
} from '@lh/eng-lhq-questions-common';
import { Pillar } from '../../enums/Pillar';

export const questionsLHQ32 = fetchLHQ32Questions({
	pillarFetchFunction(pillarData) {
		return Pillar.fromValue(pillarData);
	},
});

export const questionsLHQ15 = fetchLHQ15Questions({
	pillarFetchFunction(pillarData) {
		return Pillar.fromValue(pillarData);
	},
});
