// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const HeartInMindSolid = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='HeartInMindSolid-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'HeartInMindSolid'}</title>
			<path d='M27.4548 13.6231H24.3124C24.0451 13.6231 23.8291 13.4067 23.8291 13.1398C23.8291 12.8729 24.0456 12.6565 24.3124 12.6565H27.4548C27.7221 12.6565 27.9382 12.8729 27.9382 13.1398C27.9382 13.4067 27.7219 13.6231 27.4548 13.6231Z' />
			<path d='M16.4811 4.10882C16.2143 4.10882 15.9978 3.89237 15.9978 3.62551V0.483312C15.9978 0.216455 16.2143 0 16.4811 0C16.748 0 16.9644 0.216449 16.9644 0.483312V3.62574C16.9644 3.89237 16.748 4.10883 16.4811 4.10883V4.10882Z' />
			<path d='M23.776 9.0791C23.5832 9.0791 23.4008 8.96281 23.3259 8.77222C23.2282 8.52372 23.3506 8.24315 23.5987 8.14521L26.3756 7.05323C26.6241 6.95595 26.9049 7.07755 27.0028 7.32606C27.1006 7.57457 26.9781 7.85514 26.73 7.95307L23.9529 9.04528C23.8947 9.06805 23.8348 9.07911 23.776 9.07911L23.776 9.0791Z' />
			<path d='M21.1893 5.47847C21.0923 5.47847 20.9948 5.44973 20.9099 5.38915C20.6919 5.23483 20.6408 4.93304 20.7953 4.71527L22.6141 2.153C22.7691 1.93522 23.0708 1.88415 23.2882 2.03847C23.5062 2.1928 23.5572 2.49459 23.4027 2.71235L21.584 5.27463C21.4896 5.40795 21.3408 5.47848 21.1893 5.47848L21.1893 5.47847Z' />
			<path d='M18.6357 21.459C17.3354 19.9965 22.7598 18.8108 22.7598 13.14C22.7598 5.54371 17.2296 5.45032 16.0668 5.45032C14.9043 5.45032 9.37389 6.12419 9.37389 13.14C9.37389 18.8107 14.798 19.9965 13.4979 21.459C11.7842 23.3863 5.85864 20.9594 5.85864 28.444H26.2753C26.2756 20.9598 20.3499 23.3865 18.6361 21.459H18.6357ZM16.0582 15.0763C15.3971 14.7121 13.6063 13.5215 13.5028 11.9719C13.3821 10.1613 15.7083 10.4037 16.0582 11.745C16.4082 10.4034 18.7343 10.1615 18.6136 11.9719C18.5101 13.5213 16.7195 14.7121 16.0582 15.0763Z' />
			<path d='M7.68778 13.6231H4.54581C4.27896 13.6231 4.0625 13.4067 4.0625 13.1398C4.0625 12.8729 4.27895 12.6565 4.54581 12.6565H7.68778C7.95464 12.6565 8.17109 12.8729 8.17109 13.1398C8.17109 13.4067 7.95465 13.6231 7.68778 13.6231Z' />
			<path d='M8.22475 9.08089C8.16572 9.08089 8.1058 9.07005 8.04787 9.04728L5.27097 7.9553C5.02246 7.85757 4.9002 7.57679 4.99814 7.32828C5.09609 7.07978 5.37666 6.95751 5.62516 7.05546L8.40206 8.14744C8.65057 8.24516 8.77283 8.52595 8.67489 8.77445C8.59972 8.96437 8.41731 9.08089 8.22474 9.08089L8.22475 9.08089Z' />
			<path d='M11.7629 5.16226C11.6115 5.16226 11.4624 5.09129 11.3683 4.95841L9.54953 2.39614C9.39476 2.17836 9.44628 1.87657 9.66405 1.72226C9.88205 1.56749 10.1836 1.61901 10.3379 1.83678L12.1567 4.39906C12.3114 4.61684 12.2599 4.91863 12.0421 5.07294C11.9575 5.13351 11.8597 5.16226 11.7629 5.16226L11.7629 5.16226Z' />
		</svg>
	);
};
