import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../../artifacts/ReportArtifactNames';
import { ArtifactFormats, useGetReportData } from '../../../shared/hooks';
import { FormattedSegmentResult } from '../../common';
import { mapDataToProps } from './DelayedRecall6.helpers';
import DelayedRecall6Layout from './DelayedRecall6.layout';

export type DelayedRecallSixWordProps = {
	data: FormattedSegmentResult | undefined;
	showAudioPlayers?: boolean;
};

const DelayedRecallSixWord = ({
	data: segmentResult,
	showAudioPlayers = false,
}: DelayedRecallSixWordProps) => {
	const { t: translationFn } = useTranslation();

	const segmentResultId = useMemo(
		() => segmentResult?.id,
		[segmentResult?.id]
	);

	const {
		error,
		loading,
		binaryArtifactData: audioData,
	} = useGetReportData({
		segmentResultId,
		fileName: Json.DelayedRecall6,
		fileType: ArtifactFormats.JSON,
	});

	const layoutProps = useMemo(
		() => mapDataToProps({ segmentResult, audioData, translationFn }),
		[audioData, segmentResult, translationFn]
	);

	if (!segmentResult || loading || error || !layoutProps) return null;

	return (
		<DelayedRecall6Layout
			{...layoutProps}
			showAudioPlayers={showAudioPlayers}
		/>
	);
};

export { DelayedRecallSixWord };
