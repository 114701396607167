import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Json } from '../../../artifacts/ReportArtifactNames';
import { ArtifactFormats, useGetReportData } from '../../shared/hooks';
import { FormattedSegmentResult } from '../common';
import { mapDataToProps } from './DelayedRecognition.helpers';
import {
	DelayedRecognitionLayout,
	DelayedRecognitionLayoutProps,
} from './DelayedRecognition.layout';

type DelayedRecognitionProps = {
	data: FormattedSegmentResult | undefined;
};

const DelayedRecognition = ({
	data: segmentResult,
}: DelayedRecognitionProps) => {
	const { t: translationFn } = useTranslation();

	const segmentResultId = useMemo(
		() => segmentResult?.id,
		[segmentResult?.id]
	);

	const { error, loading, metadata } = useGetReportData({
		segmentResultId,
		fileName: Json.DelayedRecognition,
		fileType: ArtifactFormats.JSON,
	});

	const cardHeaderProps = useMemo(
		() =>
			mapDataToProps({
				segmentResult,
				translationFn,
			}),
		[segmentResult, translationFn]
	);

	if (!segmentResult || loading || error || !cardHeaderProps) return null;

	return (
		<DelayedRecognitionLayout
			{...cardHeaderProps}
			individualAnswers={
				(metadata as { data: unknown })
					?.data as unknown as DelayedRecognitionLayoutProps['individualAnswers']
			}
		/>
	);
};

export default DelayedRecognition;
