import { useAuthServiceContext } from '../../../features/auth-service';
import { useCallback } from 'react';
import {
	Configuration as OrganizationConfig,
	DefaultApi as OrganizationService,
} from '@lh/eng-platform-organization-service-rest-client';
import {
	Configuration as BatteryConfig,
	DefaultApi as BatteryService,
} from '@lh/eng-platform-battery-service-rest-client';
import { config } from '../../../config';

export type CreateRestServicesResponse = {
	createOrganizationService: () => Promise<OrganizationService>;
	createBatteryService: () => Promise<BatteryService>;
};
// TODO: Test
export const useRestServices = (): CreateRestServicesResponse => {
	const auth = useAuthServiceContext();

	const createOrganizationService = useCallback(async () => {
		const jwt = await auth.currentIdToken();
		return new OrganizationService(
			new OrganizationConfig({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	}, [auth]);

	const createBatteryService = useCallback(async () => {
		const jwt = await auth.currentIdToken();
		return new BatteryService(
			new BatteryConfig({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	}, [auth]);

	return { createOrganizationService, createBatteryService };
};
