import { useLocalStorage } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import {
	isChrome,
	isEdge,
	isEdgeChromium,
	isMobileSafari,
	isSafari,
} from 'react-device-detect';
import styled from 'styled-components';

import { Icon, H2, P1, ButtonSm } from '../../components/shared/designSystem';
import { icons } from '../../enums/icons';

type BrowserProviderProps = {
	children: ReactNode;
};

export const BrowserRestrictionProvider = ({
	children,
}: BrowserProviderProps) => {
	const { t } = useTranslation();
	const [ignoreRestriction, setIgnoreRestriction] = useLocalStorage({
		key: 'ignore-browser-restriction',
		defaultValue: false,
	});

	const handleIgnoreRestriction = () => {
		setIgnoreRestriction(true);
	};

	if (
		isChrome ||
		isEdge ||
		isEdgeChromium ||
		(isSafari && !isMobileSafari) ||
		(isMobileSafari && ignoreRestriction)
	)
		return <>{children}</>;

	return (
		<Container>
			<Modal>
				<Icon icon={icons.AlertSolid} iconWidth={75} iconHeight={75} />
				<Heading>{t`web.supportedBrowser.title`}</Heading>
				<Subtext>{t`web.supportedBrowser.subTitle`}</Subtext>

				<LinksContainer>
					<a
						href='https://www.google.com/chrome/downloads/'
						target='_blank'
						rel='noreferrer'>
						{t`web.supportedBrowser.downloadChrome`}
					</a>
					<p>|</p>
					<a
						href='https://www.microsoft.com/en-us/edge'
						target='_blank'
						rel='noreferrer'>
						{t`web.supportedBrowser.downloadEdge`}
					</a>
					<p>|</p>
					<a
						href='https://support.apple.com/downloads/safari'
						target='_blank'
						rel='noreferrer'>
						{t`web.supportedBrowser.downloadSafari`}
					</a>
				</LinksContainer>
				{isMobileSafari && (
					<ButtonSm
						text={t`web.supportedBrowser.continue`}
						primary
						width='120'
						onClick={handleIgnoreRestriction}
					/>
				)}
				<Logo>
					<Icon icon={icons.Logo} iconHeight={58} />
				</Logo>
			</Modal>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	background: #141518a6;
	width: 100vw;
	height: 100vh;
`;

const LinksContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: space-between;
	margin-top: ${spacing.xl};
	width: 100%
`
);

const Modal = styled.div(
	({ theme: { colors, spacing } }) => `
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 40px 0 40px 40px;
	background: ${colors.white};
	width: 548px;
	height: 475px;
	padding: ${spacing.xl};
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 600px) {
		width: 90%;
		height: auto;
	}
	`
);

const Heading = styled(H2)(
	({ theme: { colors, weight, spacing } }) => `
	margin-top: ${spacing.lg};
	color: ${colors.blue};
	text-align: center;
	font-weight: ${weight.medium}
`
);

const Subtext = styled(P1)(
	({ theme: { colors, spacing } }) => `
	margin-top: ${spacing.lg};
	color: ${colors.gray_30};
	text-align: center;`
);

const Logo = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.xl};
`
);
