import { useTheme } from 'styled-components';
import {
	LinusSearchBar,
	LinusSearchBarProps,
} from '../../inputs/LinusSearchBar';

export const DataTableSearchBar = <T,>({
	placeholder = '',
	setIsFilteringData,
	searchableList,
	filterFunction,
	onChange,
}: LinusSearchBarProps<T>): JSX.Element => {
	const theme = useTheme();
	return (
		<LinusSearchBar
			placeholder={placeholder}
			setIsFilteringData={setIsFilteringData}
			searchableList={searchableList}
			filterFunction={filterFunction}
			onChange={onChange}
			searchComponentMargin={`0 ${theme.spacing.md}`}
		/>
	);
};
