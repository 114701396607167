import { Space } from '@mantine/core';

import { ScoreTag, ScoreWithInterpretation } from '@lh/eng-web-mosaic-common';

import { RcrInterpretation } from '../../Interpretation/RcrInterpretation';
import { AudioCardProps } from '../../common/AudioCard/AudioCard';
import { AudioCardGrid } from '../../common/AudioCardGrid/AudioCardGrid';
import {
	ScoreBreakdown,
	ScoreBreakdownProps,
} from '../../common/Breakdown/Breakdown';
import { GenericReportCard } from '../../common/GenericReportCard/GenericReportCard';

export type ImmediateRecall6LayoutProps = {
	header: {
		title: string;
		tooltipText?: string;
	};
	audioCardData: AudioCardProps[];
	score: {
		requiresClinicianReview: boolean;
		total: {
			title: string;
			value: string;
		};
		breakdown: ScoreBreakdownProps['breakdown'];
	};
	showAudioPlayers?: boolean;
};

function ImmediateRecall6Layout(props: ImmediateRecall6LayoutProps) {
	const scoreComponent = () => {
		if (!props?.score?.requiresClinicianReview) {
			return {
				component: () => (
					<ScoreTag
						roundedBorders
						title={props?.score?.total?.title}
						score={props?.score?.total?.value}
					/>
				),
			};
		} else {
			return {
				weight: 1,
				component: () => (
					<ScoreWithInterpretation
						score={props?.score?.total?.value}
						title={props?.score?.total?.title}
						renderInterpretationText={() => (
							<RcrInterpretation
								interpretationTransKey={
									'web.report.verbalFluency.reviewRequired.listenToRecording'
								}
							/>
						)}
					/>
				),
			};
		}
	};

	return (
		<div data-testid='immediate-recall-six-word-container'>
			<Space h={'md'} />
			<GenericReportCard
				header={{
					titleText: props?.header?.title,
					tooltipText: props?.header?.tooltipText,
				}}
				content={[
					scoreComponent(),
					{
						weight: 2,
						component: () => (
							<ScoreBreakdown
								breakdown={props?.score?.breakdown}
							/>
						),
					},
				]}
			/>
			<Space h={'sm'} />
			{props?.showAudioPlayers && (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={props?.audioCardData}
				/>
			)}
		</div>
	);
}

export default ImmediateRecall6Layout;
