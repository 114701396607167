import { Space } from '@mantine/core';

import { ScoreTag, ScoreWithInterpretation } from '@lh/eng-web-mosaic-common';

import { useMemo } from 'react';
import { RcrInterpretation } from '../Interpretation/RcrInterpretation';
import { GenericReportCard } from '../common/GenericReportCard/GenericReportCard';
import { DelayedRecognitionTable } from './components/DelayedRecognitionTable/DelayedRecognitionTable';
import { AnswerResponse } from './DelayedRecognition.types';

export type DelayedRecognitionLayoutProps = {
	header: {
		title: string;
		tooltipText?: string;
	};
	score: {
		requiresClinicianReview: boolean;
		total: {
			title: string;
			value: string;
		};
	};
	individualAnswers?: AnswerResponse[];
};

const DelayedRecognitionLayout = (props: DelayedRecognitionLayoutProps) => {
	const ScoreComponent = useMemo(() => {
		if (!props?.score?.requiresClinicianReview) {
			return {
				component: () => (
					<ScoreTag
						roundedBorders
						title={props?.score?.total?.title}
						score={props?.score?.total?.value}
					/>
				),
			};
		} else {
			return {
				weight: 1 / 3,
				component: () => (
					<ScoreWithInterpretation
						score={props?.score?.total?.value}
						title={props?.score?.total?.title}
						renderInterpretationText={() => (
							<RcrInterpretation
								interpretationTransKey={
									'web.report.verbalFluency.reviewRequired.reviewAnswers'
								}
							/>
						)}
					/>
				),
			};
		}
	}, [
		props?.score?.requiresClinicianReview,
		props?.score?.total?.title,
		props?.score?.total?.value,
	]);

	return (
		<div data-testid='delayed-recognition-layout-container'>
			<Space h={'md'} />
			<GenericReportCard
				header={{
					titleText: props?.header?.title,
					tooltipText: props?.header?.tooltipText,
				}}
				content={[ScoreComponent]}
			/>
			<Space h={'sm'} />
			<DelayedRecognitionTable answers={props?.individualAnswers} />
		</div>
	);
};

export { DelayedRecognitionLayout };
