import { enumeration, EnumItem } from './enumeration';

export type { EnumItem };

export const AnalyticsAction = {
	AddedAvatar: 'ADDED_AVATAR',
	AddedNewOrganization: 'ADDED_NEW_ORGANIZATION',
	AddedNewPatient: 'ADDED_NEW_PATIENT',
	/** Event for when a user clicked Archive Patient from the kebab menu */
	ArchivedPatient: 'ARCHIVED_PATIENT',
	AssignedBattery: 'ASSIGNED_BATTERY',
	/** Event for each click of a tab in the CCE Report */
	ClickedCceTab: 'CLICKED_CCE_TAB',
	/** Event for when a user clicked on Learn More on Consider Cognitive Care Planning Visit box */
	ClickedCcp: 'CLICKED_CCP',
	/** Event for when a user clicked on More Details on Concerns List */
	ClickedConcernsDetails: 'CLICKED_CONCERNS_DETAILS',
	/** Event for when a user clicked on one of the external links */
	ClickedExternalLink: 'CLICKED_EXTERNAL_LINK',
	/** Event for when a user clicked on View Recommended Followup */
	ClickedFollowup: 'CLICKED_FOLLOWUP',
	/** Event for when a user clicked on View Anticholinergic medications list */
	ClickedMeds: 'CLICKED_MEDS',
	/** Event for when a user clicked on Show Notes button from Patient Profile page. */
	ClickedNotes: 'CLICKED_NOTES',
	/** Event for when a user clicked on Learn more on the pathway overview modal */
	ClickedPathwayLearnmore: 'CLICKED_PATHWAY_LEARNMORE',
	/** Event for when a user clicked on View Pathway Overview on Recommended Next Steps card */
	ClickedPathwayOverview: 'CLICKED_PATHWAY_OVERVIEW',
	/** Event for when a user clicked on References link from Recommended Next Steps card or View details modal */
	ClickedRef: 'CLICKED_REF',
	/** Event for when a user clicked on link in References modal */
	ClickedRefLink: 'CLICKED_REF_LINK',
	/** Event for when a user clicked on View Composite Scales button on Subscores card from Results page */
	ClickedScales: 'CLICKED_SCALES',
	/** Event for when a user clicked on View Scoring Details button on DCR card from Results page */
	ClickedScoring: 'CLICKED_SCORING',
	/** Event for each click of the hide arrow on the sidebar */
	ClickedSidebarHideArrow: 'CLICKED_SIDEBAR_HIDE_ARROW',
	/** Event for each click of a sidebar item */
	ClickedSidebarItem: 'CLICKED_SIDEBAR_ITEM',
	DownloadedReport: 'DOWNLOADED_REPORT',
	/** Event for when a CDS Full Article page is abandoned or escaped */
	EscapedCceCdsFullArticle: 'ESCAPED_CCE_CDS_FULL_ARTICLE',
	InvitedNewUser: 'INVITED_NEW_USER',
	LoggedOut: 'LOGGED_OUT',
	PlayedRecording: 'PLAYED_RECORDING',
	RemovedBattery: 'REMOVED_BATTERY',
	ResetPassword: 'RESET_PASSWORD',
	SavedAccountChanges: 'SAVED_ACCOUNT_CHANGES',
	/** Generic event for searching on a data table */
	SearchingTable: 'SEARCHING_TABLE',
	/** Please add a description for all of these analytics Keys */
	StartedSession: 'STARTED_SESSION',
	/** Event for when a user is switching to a different organization than the default one */
	SwitchedOrg: 'SWITCHED_ORG',
	UpdatedOrganization: 'UPDATED_ORGANIZATION',
	UpdatedPatient: 'UPDATED_PATIENT',
	UpdatedUser: 'UPDATED_USER',
	ViewedContactUs: 'VIEWED_CONTACT_US',
	ViewedMyTeam: 'VIEWED_MY_TEAM',
	ViewedOrganization: 'VIEWED_ORGANIZATION',
	ViewedOrganizations: 'VIEWED_ORGANIZATIONS',
	/** Event for when user scrolled to the bottom of the pathway */
	ViewedPathway: 'VIEWED_PATHWAY',
	ViewedPatients: 'VIEWED_PATIENTS',
	ViewedPatientDetails: 'VIEWED_PATIENT_DETAILS',
	/** Event for when user scrolled to the bottom of the patients results table */
	ViewedPatientResults: 'VIEWED_PATIENT_RESULTS',
	ViewedPrivacyPolicy: 'VIEWED_PRIVACY_POLICY',
	ViewedProfile: 'VIEWED_PROFILE',
	ViewedRegulatoryLabel: 'VIEWED_REGULATORY_LABEL',
	ViewedReport: 'VIEWED_REPORT',
	ViewedResults: 'VIEWED_RESULTS',
	ViewedSupport: 'VIEWED_SUPPORT',
	ViewedTermsAndPrivacy: 'VIEWED_TERMS_AND_PRIVACY',
	ViewedTermsOfUse: 'VIEWED_TERMS_OF_USE',
} as const;

export const analyticsAction =
	enumeration<typeof AnalyticsAction>(AnalyticsAction);
