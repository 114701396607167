import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { StyledLink } from '../../shared/designSystem';

type RegulatoryLinkProps = {
	regulatoryLabelPageUrl?: string;
	text?: string;
	isRegulatoryPage?: boolean;
};

const RegulatoryLink = ({
	regulatoryLabelPageUrl = '/regulatory-label',
	text,
}: RegulatoryLinkProps): JSX.Element => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	text = text ?? (t`web.shared.regulatoryLink` as string);

	return pathname === '/regulatory-label' ? (
		<StyledText>{text}</StyledText>
	) : (
		<StyledLink to={regulatoryLabelPageUrl}>{text}</StyledLink>
	);
};

const StyledText = styled.p`
	margin: 0;
`;

export { RegulatoryLink };
