import * as yup from 'yup';
import i18n from '../../i18n';

import {
	BATTERY_TYPE,
	ContactMethods,
} from '../../components/participant/participantHelpers';
import { isValidPhoneNumber } from '../../utils/validationHelpers';

export type AddBatteryProps = {
	id: string;
	externalId?: string;
	newPatient?: boolean;
	organizationId?: string;
};

export type AddBatteryModel = {
	battery?: string;
	batteryType?: string;
	contactPreference?: ContactMethods;
	contactEmail?: string;
	contactPhone?: string;
	visitType?: string;
};

export const getModel = (): AddBatteryModel => ({
	battery: undefined,
	batteryType: undefined,
	contactPreference: undefined,
	contactEmail: undefined,
	contactPhone: undefined,
	visitType: undefined,
});

type AddBatterySchemaProps = {
	hasRemoteAssessmentsFlag?: boolean;
	hasVisitTypesFlag?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addBatterySchema = ({
	hasRemoteAssessmentsFlag,
	hasVisitTypesFlag,
}: AddBatterySchemaProps) => {
	return yup.object().shape(
		{
			visitType: !hasVisitTypesFlag
				? yup.string().notRequired()
				: yup
						.string()
						.required(i18n.t('web.visitTypes.mandatoryFieldError')),
			battery: yup
				.string()
				.typeError(i18n.t('web.shared.forms.schemas.required'))
				.required(i18n.t('web.shared.forms.schemas.required')),
			batteryType: !hasRemoteAssessmentsFlag
				? yup.string().notRequired()
				: yup.string().required(),
			contactPreference: yup.string().when('batteryType', {
				is: (val: string) => {
					return val === BATTERY_TYPE.REMOTE;
				},
				then: (schema) => {
					return schema.required(
						i18n.t('web.shared.forms.schemas.selectContactMethod')
					);
				},
			}),
			contactPhone: yup
				.string()
				.optional()
				.nullable()
				.typeError(i18n.t('web.shared.forms.schemas.required'))
				.when('contactPreference', {
					is: (val: string) => {
						return ['phone_only', 'email_and_phone'].includes(val);
					},
					then: (schema) => {
						return schema.required(
							i18n.t('web.shared.forms.schemas.required')
						);
					},
					otherwise: (schema) => {
						return schema.nullable().default(null);
					},
				})
				.test(
					'is-valid-phone',
					i18n.t('web.shared.forms.schemas.phoneNumberHint'),
					function (item) {
						if (item) {
							return isValidPhoneNumber(item);
						}
						return true;
					}
				),
			contactEmail: yup
				.string()
				.optional()
				.nullable()
				.email(i18n.t('web.shared.forms.schemas.validEmail'))
				.typeError(i18n.t('web.shared.forms.schemas.required'))
				.when('contactPreference', {
					is: (val: string) => {
						return ['email_only', 'email_and_phone'].includes(val);
					},
					then: (schema) => {
						return schema.required(
							i18n.t('web.shared.forms.schemas.required')
						);
					},
					otherwise: (schema) => {
						return schema.nullable().default(null);
					},
				}),
		},
		[
			['contactPhone', 'contactPreference'], // this is disabling dependency ordering for these fields b/c they depend on each other - https://github.com/jquense/yup/issues/176#issuecomment-369925782
			['contactEmail', 'contactPreference'],
			['contactPreference', 'batteryType'],
		]
	);
};
