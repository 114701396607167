// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const AddUserOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 16 19'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='AddUserOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'AddUserOutlined'}</title>

			<path
				d='M7.9993 17.9995L0.78875 17.9989C0.367663 17.9989 0 17.6447 0 17.1819V14.5129C0 12.0354 1.94506 10.0472 4.31131 10.0472H9.75448C10.0797 10.0472 10.4032 10.0883 10.7183 10.1658C10.0621 10.3393 9.45885 10.6429 8.93768 11.0472H4.31131C2.52642 11.0472 1 12.5582 1 14.5129V16.999L7.41583 16.9995C7.57177 17.3565 7.76819 17.6918 7.9993 17.9995Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.3577 4.46568C11.3577 6.94312 9.41262 8.93135 7.04637 8.93135C4.65354 8.93135 2.73403 6.94326 2.73506 4.46568C2.73506 1.98823 4.68012 0 7.04637 0C9.43817 0 11.3577 1.98703 11.3577 4.46568ZM3.73506 4.46568C3.73506 2.51099 5.26148 1 7.04637 1C8.85319 1 10.3577 2.50605 10.3577 4.46568C10.3577 6.42037 8.83126 7.93135 7.04637 7.93135C5.23845 7.93135 3.73425 6.42385 3.73506 4.46568Z'
				fill='current'
			/>
			<path
				d='M14 15.5C14.2761 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.2761 14.5 14 14.5H12.5V13C12.5 12.7239 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.7239 11.5 13V14.5H10C9.72386 14.5 9.5 14.7239 9.5 15C9.5 15.2761 9.72386 15.5 10 15.5H11.5V17C11.5 17.2761 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.2761 12.5 17V15.5H14Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 15C16 17.2091 14.2091 19 12 19C9.79086 19 8 17.2091 8 15C8 12.7909 9.79086 11 12 11C14.2091 11 16 12.7909 16 15ZM15 15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15C9 13.3431 10.3431 12 12 12C13.6569 12 15 13.3431 15 15Z'
				fill='current'
			/>
		</svg>
	);
};
