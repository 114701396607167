import styled from 'styled-components';
import { userStatus } from './../../../../enums/userStatus';
import { useTheme } from 'styled-components';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../designSystem/Icon';
import { useTranslation } from 'react-i18next';

export type StatusColumnProps = {
	status?: string;
};

const StatusColumn = ({ status }: StatusColumnProps): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	let img;
	let text;

	if (!status) {
		return null;
	}

	switch (userStatus.fromValue(status)) {
		case userStatus.Invited: {
			img = <Icon icon={icons.InviteOutlined} />;
			text = (
				<StyledStatusText>
					{t(
						`web.enums.userStatus.${(
							userStatus.Invited.display as string
						).toLowerCase()}`
					)}
				</StyledStatusText>
			);
			break;
		}

		case userStatus.Deactivated: {
			img = (
				<Icon
					icon={icons.CloseOutlined}
					color={theme.color.alertError}
				/>
			);
			text = (
				<StyledDeactivatedText>
					{t(
						`web.enums.userStatus.${(
							userStatus.Deactivated.display as string
						).toLowerCase()}`
					)}
				</StyledDeactivatedText>
			);
			break;
		}
		case userStatus.Active: {
			img = (
				<Icon
					icon={icons.CheckmarkOutlined}
					color={theme.color.alertSuccess}
				/>
			);
			text = (
				<StyledStatusText>
					{t(
						`web.enums.userStatus.${(
							userStatus.Active.display as string
						).toLowerCase()}`
					)}
				</StyledStatusText>
			);
		}
	}
	return (
		<StyledStatusColumn>
			<StyledStatusIcon>{img}</StyledStatusIcon>
			{text}
		</StyledStatusColumn>
	);
};

export { StatusColumn };

const StyledStatusColumn = styled.div`
	display: flex;
	align-items: Center;
`;

const StyledStatusIcon = styled.div(
	({ theme: { spacing } }) => `
	padding-right: ${spacing.sm};
	display: flex;
	align-item:center;
	`
);

const StyledStatusText = styled.div``;

const StyledDeactivatedText = styled.div(
	({ theme: { color } }) => `
	color: ${color.deactivatedText};
`
);
