import { useMemo } from 'react';

import { useGetQnrSpecWithScore } from 'api/qnr';
import { ArtifactFormats, useGetReportData } from 'components/shared/hooks';
import { FormattedSegmentResult } from '../../common';
import { SingleSelectUserAnswer } from '../common/types/CustomQnr.types';
import {
	getPHQ8DepressionScoreBreakdown,
	getPHQ8QnrTableData,
	isPHQ8IndicatingDepression,
} from './PHQ-8.helpers';
import { PHQ8Layout } from './PHQ-8.layout';
import { PHQ8Footer } from './components/PHQ-8.footer';
import { PHQ8Header } from './components/PHQ-8.header';
import { PHQ8DepressionScoreBreakdown } from './components/PHQ8DepressionScoreBreakdown';

type Props = {
	batteryResultId: string;
	segmentData: FormattedSegmentResult | undefined;
};

type SingleSelectQnrResults = { answers: SingleSelectUserAnswer[] };

const PHQ8 = (props: Props) => {
	const requestParams = useMemo(
		() => ({
			segmentResultId: props?.segmentData?.id as string,
			fileName: 'answers.json',
			fileType: ArtifactFormats.JSON,
		}),
		[props?.segmentData]
	);

	const { loading, metadata } = useGetReportData(requestParams);
	const { isFetching, data: qnrSpecWithScore } = useGetQnrSpecWithScore({
		batteryResultId: props?.batteryResultId ?? '',
		segmentResultId: props?.segmentData?.id ?? '',
	});

	const tableData = useMemo(
		() =>
			getPHQ8QnrTableData({
				qnrSpecWithScore,
				segmentResultMetricItems: props?.segmentData?.metricItems,
				userAnswers: (metadata as SingleSelectQnrResults)?.answers,
			}),
		[metadata, props?.segmentData?.metricItems, qnrSpecWithScore]
	);

	const totalScoreToDisplay = useMemo(
		() => tableData?.totalScoreToDisplay ?? '',
		[tableData?.totalScoreToDisplay]
	);

	const phq8DepressionScoreBreakdown = useMemo(
		() => getPHQ8DepressionScoreBreakdown(props?.segmentData?.metricItems),
		[props?.segmentData?.metricItems]
	);

	const isIndicativeOfDepression = useMemo(
		() => isPHQ8IndicatingDepression(phq8DepressionScoreBreakdown),
		[phq8DepressionScoreBreakdown]
	);

	if (loading || isFetching) return null;

	return (
		<PHQ8Layout
			renderHeader={() => <PHQ8Header />}
			tableRowData={tableData?.tableRows}
			renderInterpretation={() =>
				isIndicativeOfDepression ? (
					<PHQ8DepressionScoreBreakdown
						{...phq8DepressionScoreBreakdown}
					/>
				) : null
			}
			renderFooter={() => <PHQ8Footer totalScore={totalScoreToDisplay} />}
		/>
	);
};

export { PHQ8 };
