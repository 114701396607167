import { TFunction } from 'i18next';

import { MetricHash } from '../../../report/common';

import { convertToSentenceCase } from '../../../../utils/stringUtils';
import { DelayedRecall6LayoutProps } from './DelayedRecall6.layout';
import { MapDataToPropsArgs } from './DelayedRecall6.types';

const minimumAchievableScore = 0;
const maximumAchievableScore = 6;
const unanalyzableScoreToDisplay = '--';

const formatExpectedWords = (commaSeparatedStrings: string): string => {
	const listOfWords = commaSeparatedStrings.split(',').join(', ');
	return convertToSentenceCase(listOfWords);
};

const checkIfTotalAchievedScoreIsZero = (metricItems: MetricHash): boolean => {
	const totalScoreAchieved =
		(metricItems?.['pvlt_recall_score']?.value as number) ??
		minimumAchievableScore;
	return totalScoreAchieved === 0;
};

const getTotalScoreToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): DelayedRecall6LayoutProps['score']['total'] => {
	const totalScoreAchieved =
		(metricItems?.['pvlt_recall_score']?.value as number) ??
		minimumAchievableScore;
	const totalScoreToDisplay = `${
		totalScoreAchieved === 0
			? unanalyzableScoreToDisplay
			: totalScoreAchieved
	}/${maximumAchievableScore}`;
	const scoreTitle = translationFn(
		'web.report.verbalFluency.correctResponses'
	)?.toUpperCase();
	return { value: totalScoreToDisplay, title: scoreTitle };
};

const getScoreInfo = (
	metricItems: MetricHash,
	translationFn: TFunction
): DelayedRecall6LayoutProps['score'] => {
	return {
		requiresClinicianReview: checkIfTotalAchievedScoreIsZero(metricItems),
		total: getTotalScoreToDisplay(metricItems, translationFn),
	};
};

const getAudioPromptToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): string => {
	const promptDisplayKey = `${translationFn(
		'web.report.verbalFluency.prompt'
	)}`;
	const expectedWordsFromMetrics =
		metricItems?.['pvlt_expected_words']?.value?.toString() ?? '';
	return formatExpectedWords(
		`${promptDisplayKey}: ${expectedWordsFromMetrics}`
	);
};

const mapDataToProps = ({
	segmentResult,
	audioData,
	translationFn,
}: MapDataToPropsArgs): DelayedRecall6LayoutProps | null => {
	if (!segmentResult) return null;
	if (!audioData) return null;

	const segmentName = segmentResult?.segment?.displayKey ?? 'Delayed Recall';

	const metricItems = segmentResult?.metricItems;

	const audioObject = audioData?.[0] ?? {};

	return {
		header: {
			title: segmentName,
			tooltipText: translationFn(
				'web.report.verbalFluency.delayedRecallSixWord.tooltipText'
			),
		},
		audioCardData: [
			{
				text: {
					cardHeading: segmentName,
					cardSubHeadings: [
						getAudioPromptToDisplay(metricItems, translationFn),
					],
				},
				audio: {
					source: audioObject?.source ?? '',
					fileType: audioObject?.fileType ?? '',
				},
			},
		],
		score: getScoreInfo(metricItems, translationFn),
	};
};

export { mapDataToProps };
