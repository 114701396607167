import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H2, H4 } from '../../../../../../../shared/designSystem';
import {
	getConfidenceScoreContainerBgColor,
	getConfidenceScoreDescriptionTransKey,
	getConfidenceScoreRange,
} from '../../../../../common/helpers/confidenceScoreUtils';

const getTestId = (elementId: string) => `confidence-score-${elementId}`;

type ConfidenceScoreResultType = {
	totalScore: number;
};

function ConfidenceScoreResult({
	totalScore,
}: Readonly<ConfidenceScoreResultType>) {
	const { t } = useTranslation();

	const scoreRange = getConfidenceScoreRange(totalScore);
	if (!scoreRange) return null;

	const { container, textContainer } =
		getConfidenceScoreContainerBgColor(scoreRange);
	const scoreValueText = `${totalScore}/25`;
	const scoreRangeDescription = t(
		getConfidenceScoreDescriptionTransKey(scoreRange)
	);

	return (
		<div>
			<StyledContainer
				data-testid={getTestId('container')}
				style={{ backgroundColor: container }}>
				<Flex direction={'row'} align={'center'}>
					<StyledPrimaryTextContainer
						style={{ backgroundColor: textContainer }}>
						<StyledPrimaryText
							data-testid={getTestId('value-text')}>
							{scoreValueText}
						</StyledPrimaryText>
					</StyledPrimaryTextContainer>
					<StyledSecondaryText
						data-testid={getTestId('description-text')}>
						{scoreRangeDescription}
					</StyledSecondaryText>
				</Flex>
			</StyledContainer>
		</div>
	);
}

export { ConfidenceScoreResult };

const StyledContainer = styled.div(
	() => `
		border-radius: 8px;
	`
);

const StyledPrimaryTextContainer = styled.div(
	({ theme: { spacing } }) => `
		flex-direction: row;
		padding: ${spacing.sm};
		margin: ${spacing.md};
		border-radius: 8px;
	`
);

const StyledPrimaryText = styled(H2)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.medium};
	padding: ${spacing.sm};
`
);

const StyledSecondaryText = styled(H4)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.regular};	
	margin-right: ${spacing.md};
	margin-top: ${spacing.md};
	margin-bottom: ${spacing.md};
`
);
