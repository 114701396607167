import {
	Answer,
	LHQ15_QUESTIONS as questions15,
	questionParser,
	TLHQWithSchemaResponsedata,
} from '@lh/eng-lhq-questions-common';
import { SegmentType } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import { ArtifactFormats, useGetReportData } from 'components/shared/hooks';

import { Json } from '../../../../artifacts/ReportArtifactNames';
import { FormattedBatteryResult, FormattedSegmentResult } from '../../common';
import { SegmentQuestionsPair } from './types';
import { getLHQMetrics, getLifeAndHealthAssessmentData } from './LHQ15.helpers';
import { LHQ15Layout } from './LHQ-15.layout';

type Props = {
	batteryResultId: string;
	batteryResult: FormattedBatteryResult;
	segmentData: FormattedSegmentResult | undefined;
};

export function LHQ15({ batteryResult, segmentData }: Props) {
	const [answers, setAnswers] = useState<{
		results: Answer[] | undefined;
	}>({ results: undefined });

	const { t } = useTranslation();

	const lhqData = useMemo(
		() =>
			getLifeAndHealthAssessmentData({
				batteryResult,
				segmentResult: segmentData,
			}),
		[batteryResult, segmentData]
	);

	const requestParams = useMemo(
		() => ({
			segmentResultId: segmentData?.id as string,
			fileName: Json.CustomQuestionnaire,
			fileType: ArtifactFormats.JSON,
		}),
		[segmentData]
	);

	const { loading, metadata } = useGetReportData(requestParams);
	const { elevatedRisk } = getLHQMetrics(lhqData);

	useEffect(() => {
		if (metadata && !loading) {
			const { results } = questionParser(
				metadata as TLHQWithSchemaResponsedata
			);

			setAnswers({ results: results });
		}
	}, [metadata, loading]);

	if (!answers.results || loading) {
		return null;
	}

	const segmentQuestions: SegmentQuestionsPair<string> = {
		segmentType: SegmentType.Lhq15,
		questions: questions15,
	};

	return (
		<LHQ15Layout
			title={t`web.report.lifeAndHealth.title`}
			subHeader={t`web.report.lifeAndHealth.subHeader`}
			segmentQuestions={segmentQuestions}
			patientAnswers={answers.results}
			tooltipCopy={t`web.report.lifeAndHealth.tooltip.copy`}
			tooltipMeasures={t`web.report.lifeAndHealth.tooltip.measures`}
			elevatedRisk={elevatedRisk}
			offsetComponentRef={undefined}
		/>
	);
}
