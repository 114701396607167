import { useEffect, useState } from 'react';
import { DeepBatteryResult } from '@lh/eng-platform-battery-service-rest-client';
import { useRestServices } from '../../components/shared/hooks/useRestServices';
import { ERROR } from '../../logging/linusLogger';

export function useResolveBatteryResult({
	batteryResultId,
}: {
	batteryResultId: string;
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<unknown | null>(null);
	const [apiResponse, setApiResponse] = useState<DeepBatteryResult | null>(
		null
	);
	const { createBatteryService } = useRestServices();
	useEffect(() => {
		if (batteryResultId) {
			setLoading(true);
			(async () => {
				try {
					const batteryClient = await createBatteryService();
					const { data: batteryResult } =
						await batteryClient.getDeepBatteryResult({
							batteryResultId,
							withMetrics: true,
						});
					setApiResponse(batteryResult);
					setLoading(false);
				} catch (e) {
					ERROR('Failed to fetch battery result', {
						batteryResultId,
					});
					setLoading(false);
					setError(e);
				}
			})();
		}
	}, [batteryResultId, createBatteryService]);
	return { data: apiResponse, loading, error };
}
