import i18n from '../../../../i18n';
import { DefaultTheme } from 'styled-components';
import { icons } from '../../../../enums/icons';

export type DctScoreClassification =
	| 'outside'
	| 'indeterminate'
	| 'inside'
	| 'unanalyzable';

export const dctScoreMap = (theme: DefaultTheme) => ({
	inside: {
		dctScoreColor: theme.color.graphGreen,
		scoreIcon: icons.ArrowIncrease,
		scoreText: i18n.t(
			'web.report.DCTClock.subscore.info.scoreDisplayText.inside'
		),
		scoreTextColor: theme.color.graphGreenDarkest,
	},
	indeterminate: {
		dctScoreColor: theme.color.graphYellow,
		scoreIcon: icons.Indeterminate,
		scoreText: i18n.t(
			'web.report.DCTClock.subscore.info.scoreDisplayText.indeterminate'
		),
		scoreTextColor: theme.color.graphYellowDarker,
	},
	outside: {
		dctScoreColor: theme.color.graphOrange,
		scoreIcon: icons.ArrowDecrease,
		scoreText: i18n.t(
			'web.report.DCTClock.subscore.info.scoreDisplayText.outside'
		),
		scoreTextColor: theme.color.graphOrangeDarker,
	},
	unanalyzable: {
		dctScoreColor: '',
		scoreIcon: '',
		scoreText: 'unanalyzable',
		scoreTextColor: '',
	},
});
