import { getValidValue } from './typeGuard';

import packageInfo from '../../package.json';

const nodeEnvs = ['development', 'ci', 'qa', 'production'];
type NodeEnv = (typeof nodeEnvs)[number];

const appPackageVersion = packageInfo?.version;

type Config = {
	nodeEnv: NodeEnv;
	// should be specific options once we know what they will be
	logging_level: string;
	// should be specific options once we know what they will be
	logging_transports: string;
	host: string;
	apiHost: string;
	apiPort: number;
	apiDomain: string;
	apiProtocol: string;
	amplifyRegion: string;
	amplifyUserPoolId: string;
	amplifyUserPoolWebClientId: string;
	amplitudeApiKey: string;
	cookieDomain: string;
	packageVersion: string;
	softwareReferenceNumber: string;
	releaseDate: string;
	applicationEnv: string;
	clinicalDomainUrl: string;
	researchDomainUrl: string;
	managementDomainUrl: string;
	auth0Domain: string;
	auth0ClientId: string;
	preferredUserProvider: string;
	splitIoApiKey: string;
};

const _config: Config = {
	nodeEnv: getValidValue<NodeEnv>(
		process.env.NODE_ENV || 'development',
		nodeEnvs
	),

	// All the subsequent env vars should be prefixed with REACT_APP_.
	// If they are not they will not be found in the process.env
	logging_level: process.env.REACT_APP_LOGGING_LEVEL || 'INFO',
	// list of logging outputs e.g. console, some other transport
	logging_transports: process.env.REACT_APP_LOGGING_TRANSPORTS || 'console',
	host: process.env.REACT_APP_HOST || 'localhost',
	cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN || '',
	apiHost: process.env.REACT_APP_API_HOST || 'localhost',
	apiPort: isNaN(parseInt(process.env.REACT_APP_API_PORT || '', 10))
		? 3000
		: parseInt(process.env.REACT_APP_API_PORT || '', 10),
	apiDomain: process.env.REACT_APP_API_DOMAIN || 'graphql',
	apiProtocol: process.env.REACT_APP_API_PROTOCOL || 'http',
	amplifyRegion: process.env.REACT_APP_AWS_REGION || '',
	amplifyUserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
	amplifyUserPoolWebClientId:
		process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
	amplitudeApiKey: process.env.REACT_APP_AMPLITUDE || '',
	packageVersion: appPackageVersion,
	softwareReferenceNumber: process.env.REACT_APP_REF_NUMBER || '',
	releaseDate: process.env.REACT_APP_RELEASE_DATE || '',
	applicationEnv: process.env.APPLICATION_ENV || 'development',
	clinicalDomainUrl: process.env.REACT_APP_CLINICAL_DOMAIN_URL || '',
	researchDomainUrl: process.env.REACT_APP_RESEARCH_DOMAIN_URL || '',
	managementDomainUrl: process.env.REACT_APP_MANAGEMENT_DOMAIN_URL || '',
	auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
	auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
	preferredUserProvider:
		process.env.REACT_APP_PREFERRED_USER_PROVIDER || 'cognito',
	splitIoApiKey: process.env.REACT_APP_SPLIT_IO_API_KEY || '',
};

export const getConfig = (): Config => {
	// Because we build once in dev ci and use that package
	// for all environments, we need a way to set environment specific values
	// from the subsequent environments.  Thus we inject a script on the page that
	// has these values.

	if (!window.externalConfig || !window.externalConfig?.apiHost) {
		return _config;
	}
	const externalConfig = window.externalConfig;
	return {
		..._config,
		host: externalConfig.host,
		cookieDomain: externalConfig.cookieDomain,
		apiHost: externalConfig.apiHost,
		apiPort: parseInt(externalConfig.apiPort),
		apiDomain: externalConfig.apiDomain,
		apiProtocol: externalConfig.apiProtocol,
		amplifyRegion: externalConfig.amplifyRegion,
		amplifyUserPoolId: externalConfig.amplifyUserPoolId,
		amplifyUserPoolWebClientId: externalConfig.amplifyUserPoolWebClientId,
		amplitudeApiKey: externalConfig.amplitudeApiKey,
		packageVersion: appPackageVersion,
		softwareReferenceNumber: externalConfig.softwareReferenceNumber,
		releaseDate: externalConfig.releaseDate,
		applicationEnv: externalConfig.applicationEnv,
		clinicalDomainUrl: externalConfig.clinicalDomainUrl,
		researchDomainUrl: externalConfig.researchDomainUrl,
		managementDomainUrl: externalConfig.managementDomainUrl,
		preferredUserProvider: externalConfig.preferredUserProvider,
		auth0Domain: externalConfig.auth0Domain,
		auth0ClientId: externalConfig.auth0ClientId,
		splitIoApiKey: externalConfig.splitIoApiKey,
	};
};

export const config: Config = getConfig();
