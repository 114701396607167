import { Space } from '@mantine/core';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';

import { useEffect, useState } from 'react';
import { DropdownOption } from 'types';
import { isNonEmptyString } from 'utils/stringUtils';
import { LinusInput } from '../../../components/shared/Forms/Components/LinusInput';
import { P2 } from '../../../components/shared/designSystem';
import { useGetActiveVisitTypes } from '../../../hooks/useGetActiveVisitTypes';

const formikFieldKey = 'visitType';

const VisitConfiguration = () => {
	const [_, meta] = useField(formikFieldKey);
	const [isVisitTypeTouched, setIsVisitTypeTouched] = useState(false);
	const activeVisitTypeOptions = useGetActiveVisitTypes();
	const { t } = useTranslation();

	useEffect(() => {
		// The "touched" values from the formik field is somehow always false.
		// This is a short term workaround for that issue.
		if (isNonEmptyString(meta?.value)) {
			setIsVisitTypeTouched(true);
		}
	}, [meta]);

	return (
		<>
			<Space h='md' />
			<SectionContainer>
				<Typography.P1 weight='500'>
					{t('web.visitTypes.visitConfiguration')}
				</Typography.P1>
				<SectionDropdowns>
					<LinusInput
						data-testid='visit-types-dropdown'
						name={formikFieldKey}
						type='select'
						dropdownOptions={activeVisitTypeOptions?.map(
							(option): DropdownOption => {
								return {
									display: option?.name,
									value: option?.name,
								};
							}
						)}
						label={t`web.visitTypes.visitType`}
						styles={{ marginRight: '24px' }}
					/>
				</SectionDropdowns>
				{meta?.error && isVisitTypeTouched && (
					<StyledErrorText data-id={`input-error-visitTypes`}>
						{meta?.error}
					</StyledErrorText>
				)}
			</SectionContainer>
		</>
	);
};

export { VisitConfiguration };

const SectionContainer = styled.div(
	({ theme: { colors } }) => `
	display: flex;
	flex-direction: column;
	border: 1px solid ${colors.gray_70};
	background-color: ${colors.gray_90};
	width: 100%;
	border-radius: 4px;
	padding: 12px;
`
);

const SectionDropdowns = styled.div`
	display: flex;
	width: 100%;
`;

const StyledErrorText = styled(P2)(
	({ theme: { spacing, color } }) => `
	display: flex;
	align-items: center;	
	justify-content: flex-start;
	color: ${color.formError};
	font-style: italic;
	padding: 0 0 0 ${spacing.xs};
	`
);
