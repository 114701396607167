import { EpsomData } from '../types/epsomTypes';
import { useGetRawData } from './useGetRawData';

interface UseGetEpsomJsonDataProps {
	segmentResultId: string;
}

export const useGetEpsomJsonData = (args: UseGetEpsomJsonDataProps) => {
	return useGetRawData<EpsomData>({ ...args, fileName: 'epsom.json' });
};
