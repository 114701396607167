import { Route, Routes } from 'react-router-dom';
import { AccessDenied } from 'components/accessDenied';
import { RegulatoryPage } from 'components/regulatory';
import { SessionTimeout } from 'components/sessionTimeout/SessionTimeout';
import { UserNotFound } from 'components/userNotFound';
import { UserContextProvider } from '../userContextProvider/UserContextProvider';
import { LoggedInCheck } from './LoggedInCheck';
import { GlobalDataProvider } from '../globalDataProvider/GlobalDataProvider';
import { QueryProvider } from '../queryProvider/QueryProvider';
import { UserOnlyProvider } from '../UserProvider/UserOnlyProvider';
import { OrgIdCheck } from '../OrganizationProvider/OrgIdCheck';
import { RemoteAuth } from '../remoteAuth';

export const RouteProvider = (): JSX.Element | null => {
	return (
		<Routes>
			<Route path='/regulatory-label' element={<RegulatoryPage />} />
			<Route path={'/access-denied'} element={<AccessDenied />} />
			<Route path={'/user-not-found'} element={<UserNotFound />} />
			<Route path={'/session-timeout'} element={<SessionTimeout />} />
			<Route path={'/remoteAuth'} element={<RemoteAuth />} />
			<Route
				path='/*'
				element={
					<QueryProvider>
						<UserOnlyProvider>
							<OrgIdCheck>
								<UserContextProvider>
									<GlobalDataProvider>
										<LoggedInCheck />
									</GlobalDataProvider>
								</UserContextProvider>
							</OrgIdCheck>
						</UserOnlyProvider>
					</QueryProvider>
				}
			/>
		</Routes>
	);
};
