/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from '@lh/eng-shared-auth';

import axios, { ResponseType } from 'axios';

type KoaRestPostProps = {
	url: string;
	responseType?: ResponseType;
	signal?: AbortSignal;
	body: Record<any, any>;
};

export async function koaPost<T>(
	auth: Auth,
	input: KoaRestPostProps
): Promise<T> {
	const { url, body = {}, responseType = 'json', signal } = input;
	const jwt = await auth.currentIdToken();

	return await axios.post(url, body, {
		responseType,
		signal,
		headers: {
			authorization: jwt ? `Bearer ${jwt.token}` : '',
		},
	});
}
