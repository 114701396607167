import styled from 'styled-components';

export const StyledCard = styled.div(
	({ theme: { color, boxShadow, borderRadius } }) => `
		background: ${color.white};
		box-shadow: ${boxShadow.standard};
		border-radius: ${borderRadius.card};
		box-sizing: content-box;
		@media print {
			border: none;
			box-shadow: none;
			border-radius: 0;
		}
	`
);
