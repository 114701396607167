import { config } from '../config';

export const AuthProvider = {
	isCognito: config.preferredUserProvider === 'cognito',
	isAuth0: config.preferredUserProvider === 'auth0',
	getClientId() {
		if (this.isCognito) {
			return;
		}
		return config.auth0ClientId;
	},
};
