// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Repeat = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Repeat-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Repeat'}</title>
			<path
				d='M19.25 17.5832H21.4168C21.75 17.5832 22 17.8333 22.0835 18.2502C22.0835 18.5002 21.8335 18.7502 21.5002 18.7502H18.1669C18.0002 18.7502 17.8335 18.6668 17.7502 18.5835C17.5835 18.4168 17.5835 18.2502 17.5835 18.0835V14.8335C17.5835 14.5835 17.8335 14.3335 18.1668 14.3335C18.5 14.3335 18.75 14.5002 18.75 14.8335V16.6668C19.6668 15.25 20.1668 13.6667 20.1668 12C20.1668 9.8332 19.25 7.74998 17.7501 6.24998C15.2501 3.83327 11.5001 3.24998 8.41685 4.74998C8.16685 4.83327 7.83342 4.74998 7.66685 4.49998C7.58342 4.1667 7.66685 3.83327 7.91685 3.74998C11.5001 1.99999 15.7501 2.6667 18.5 5.49998C20.25 7.24998 21.1668 9.49998 21.1668 12C21.1668 14.0832 20.5 15.9999 19.25 17.5832Z'
				fill='#676671'
			/>
			<path
				d='M6.25 17.75C8.75 20.1667 12.4999 20.75 15.5832 19.25C15.8332 19.1667 16.1666 19.2501 16.3332 19.5001C16.4999 19.8334 16.3332 20.0834 16.0832 20.2501C14.75 20.8335 13.4167 21.1668 12 21.1668C9.58321 21.1668 7.25 20.2501 5.5 18.5001C3.75 16.7501 2.83329 14.5001 2.83329 12.0001C2.83329 9.91663 3.5 7.99991 4.75 6.41648H2.58329C2.25 6.41648 2 6.08319 2 5.83319C2 5.58319 2.25 5.33319 2.5 5.33319H5.83329C6.16671 5.33319 6.41671 5.5832 6.41671 5.83334V9.16663C6.41671 9.49992 6.08329 9.74992 5.83329 9.74992C5.58329 9.74992 5.33329 9.49991 5.25 9.16649V7.3332C4.33329 8.74999 3.83329 10.3333 3.83329 12C3.83329 14.1668 4.75 16.25 6.25 17.75Z'
				fill='#676671'
			/>
		</svg>
	);
};
