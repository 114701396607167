import { routeEnum, RouteEnum } from '../../../enums/routeEnum';
import { hasOperation } from '../../../hasOperation';
import { CurrentUser } from '../../../types';

const buildMenuNodes = (
	currentUser: CurrentUser,
	isAuth0Universal?: boolean
): RouteEnum[] => {
	return routeEnum(currentUser, isAuth0Universal)
		.filter((value) => (value.show ? value.show() : true))
		.filter((value) => hasOperation(currentUser, value.operations));
};

export { buildMenuNodes };
