import { get } from 'lodash';

import {
	LocalizationSpecTranslationsInner,
	QuestionnaireSpecWithScoringResult,
} from 'api/qnr';
import { MetricHash } from 'components/report/metrics';
import { getBrowserLanguage } from 'utils/getLanguage';
import {
	SingleSelectRowType,
	SingleSelectUserAnswer,
} from '../common/types/CustomQnr.types';
import { PHQ8DepressionScore } from './PHQ-8.types';

type PHQLayoutProps = {
	tableRows: SingleSelectRowType[];
	totalScoreToDisplay: string;
};

const MAXIMUM_SCORE_PHQ8_OVERALL = 24;
const MAXIMUM_SCORE_PHQ8_Q1_Q2 = 6;

const getAllTranslationsFromSpec = (
	qnrSpecWithScore: QuestionnaireSpecWithScoringResult | null | undefined
): LocalizationSpecTranslationsInner[] | null => {
	// QNR returns a locale of the form "en_US" whereas the browser API returns a locale of the form "en-US".
	const locale = getBrowserLanguage()?.replaceAll('-', '_');
	// Get all translations from the spec for the user's locale
	const translations =
		qnrSpecWithScore?.localizations?.find(
			(localization) => localization.locale === locale
		)?.translations ?? null;
	return translations;
};

export const getPHQ8QnrTableData = (input: {
	qnrSpecWithScore: QuestionnaireSpecWithScoringResult | null | undefined;
	userAnswers: SingleSelectUserAnswer[];
	segmentResultMetricItems: MetricHash | undefined;
}): PHQLayoutProps | undefined => {
	if (
		!input?.qnrSpecWithScore ||
		!input?.segmentResultMetricItems ||
		!input?.userAnswers
	)
		return;
	const {
		userAnswers = [],
		qnrSpecWithScore,
		segmentResultMetricItems,
	} = input;
	const qnrTranslations = getAllTranslationsFromSpec(qnrSpecWithScore);
	// Get overall score from metrics
	const achievedOverallScore = Number(
		get(segmentResultMetricItems, 'qnr:phq-8:overall:total.value', '0')
	);
	const totalScoreToDisplay = `${achievedOverallScore}/${MAXIMUM_SCORE_PHQ8_OVERALL}`;
	const totalAnswers = userAnswers?.length;
	const tableRows = userAnswers?.map((userAnswer, index) => {
		const isLastQuestion = index + 1 === totalAnswers;
		// Every QNR spec has multiple "components" that represent the question asked, and the choices associated with th
		const qnrSpecComponent = qnrSpecWithScore?.components?.find(
			(questionData) => questionData?.id === userAnswer?.questionId
		);
		// Get text for question to display
		const questionText =
			qnrTranslations?.find(
				(translation) =>
					translation?.key === qnrSpecComponent?.promptI18nKey
			)?.translation ?? '';
		// Get text for answer choices associated with each question
		const choices = qnrSpecComponent?.choices?.map((choice) => {
			const choiceScore = choice?.value;
			const choicei18nKey = choice?.choiceI18nKey;
			const choiceText =
				qnrTranslations?.find(
					(translation) => translation?.key === choicei18nKey
				)?.translation ?? '';
			// Additional UI requirement - All questions except for the last must show the score besides each answer choice text
			const choiceTextToDisplay = `${
				!isLastQuestion ? choiceScore + ' - ' : ''
			}${choiceText}`;
			return {
				isSelected: userAnswer.value === choiceScore,
				choiceText: choiceTextToDisplay,
			};
		});
		return {
			questionText,
			answerChoices: choices ?? [],
		};
	});
	return {
		tableRows,
		totalScoreToDisplay,
	};
};

export const getPHQ8DepressionScoreBreakdown = (
	phq8MetricItems: MetricHash | undefined
): PHQ8DepressionScore => {
	const achievedOverallScore = Number(
		get(phq8MetricItems, 'qnr:phq-8:overall:total.value', '0')
	);
	const q1Score = Number(
		get(
			phq8MetricItems,
			'qnr:phq-8:question:phq8_q1:question_answer_scores.value',
			'0'
		)
	);
	const q2Score = Number(
		get(
			phq8MetricItems,
			'qnr:phq-8:question:phq8_q2:question_answer_scores.value',
			'0'
		)
	);
	const combinedScoreQ1Q2 = q1Score + q2Score;
	const scoreExcludingQ1Q2 = achievedOverallScore - combinedScoreQ1Q2;
	return {
		q1q2Score: {
			max: MAXIMUM_SCORE_PHQ8_Q1_Q2,
			achieved: combinedScoreQ1Q2,
		},
		totalScore: {
			max: MAXIMUM_SCORE_PHQ8_OVERALL,
			achieved: achievedOverallScore,
		},
		scoreExcludingQ1Q2: {
			max: MAXIMUM_SCORE_PHQ8_OVERALL - MAXIMUM_SCORE_PHQ8_Q1_Q2,
			achieved: scoreExcludingQ1Q2,
		},
	};
};

export const isPHQ8IndicatingDepression = (
	input: PHQ8DepressionScore
): boolean => {
	const isTotalScoreIndicative = input?.totalScore?.achieved >= 10;
	const isCombinedQ1Q2ScoreIndicative = input?.q1q2Score?.achieved >= 3;
	return isTotalScoreIndicative || isCombinedQ1Q2ScoreIndicative;
};
