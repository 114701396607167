import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { icons } from '../../../../../enums/icons';
import { theme } from '../../../../../providers/styleProvider/theme';
import { Caption, Icon } from '../../../../shared/designSystem';
import { AnswerResponse } from '../../DelayedRecognition.types';
import {
	COLUMNS,
	DelayedRecognitionData,
	buildTableData,
} from './DelayedRecognitionTable.consts';
import {
	Card,
	FooterText,
	IconWrapper,
	LineDivider,
	Selected,
	TableHeader,
	TableHeaderWrapper,
} from './DelayedRecognitionTable.style';

type DelayedRecognitionTableProps = {
	answers?: AnswerResponse[];
};

export function DelayedRecognitionTable({
	answers,
}: Readonly<DelayedRecognitionTableProps>) {
	const { t } = useTranslation();

	const tableData = useMemo(() => {
		if (answers) {
			return buildTableData(answers);
		}
	}, [answers]);

	if (!tableData) {
		return <></>;
	}

	return (
		<Card>
			<TableHeaderWrapper>
				<TableHeader>
					{t(
						'web.report.verbalFluency.delayedRecognition.table.individualAnswers'
					)}
				</TableHeader>
				<IconWrapper>
					<Icon icon={icons.RedClose} />
					<Caption style={{ color: theme.colors.orange_darker }}>
						{`= ${t(
							'web.report.verbalFluency.delayedRecognition.table.incorrect'
						)}`}
					</Caption>
				</IconWrapper>
				<IconWrapper>
					<Icon icon={icons.GreenCheckmark} />
					<Caption style={{ color: theme.colors.green_darkest }}>
						{`= ${t(
							'web.report.verbalFluency.delayedRecognition.table.correct'
						)}`}
					</Caption>
				</IconWrapper>
				<IconWrapper>
					<Selected />
					<Caption style={{ color: theme.colors.gray_30 }}>
						{`= ${t(
							'web.report.verbalFluency.delayedRecognition.table.selection'
						)}`}
					</Caption>
				</IconWrapper>
			</TableHeaderWrapper>
			<SimpleTable<DelayedRecognitionData>
				columns={COLUMNS}
				data={tableData}
				horizontalSpacing={'xs'}
				styles={{ td: { verticalAlign: 'top' } }} // Override default Mantine table styling
			/>
			<LineDivider />
			<FooterText>
				{t(
					'web.report.verbalFluency.delayedRecognition.table.endOfQuestionnaire'
				)}
			</FooterText>
		</Card>
	);
}
