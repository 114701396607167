import styled from 'styled-components';
import { P2 } from '../../../../../../shared/designSystem';

type AnswerHeaderTextProps = {
	index: number;
};

function AnswerHeaderText({ index }: AnswerHeaderTextProps) {
	const answerSequenceNumber = index + 1;
	return (
		<StyledP2MediumTextSecondary
			data-testid={`answer-number-${answerSequenceNumber}`}>
			{'Answer ' + answerSequenceNumber + ':'}
		</StyledP2MediumTextSecondary>
	);
}

export { AnswerHeaderText };

const StyledP2MediumTextSecondary = styled(P2)(
	({ theme: { color, weight } }) => `
    margin: 0 0 0 0;
    font-weight: ${weight.medium};
    color: ${color.bodyTextSecondary};
`
);
