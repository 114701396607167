import { EnumItem } from '../../../../enums/enumeration';
import { EmptyTableFallback } from '../EmptyTableFallback';
import { NoMatchFoundTableContent } from '../noMatchFoundTableContent/NoMatchFoundTableContent';

type DataTableContentProps = {
	fallbackText: string;
	longText?: string;
	noDataIcon?: EnumItem;
	isFilteringData?: boolean;
	notFoundTitle?: string;
	notFoundSubtitle?: string;
	hasInitialData?: boolean;
};

const EmptyTableContent = ({
	fallbackText,
	longText,
	noDataIcon,
	isFilteringData,
	notFoundTitle = '',
	notFoundSubtitle = '',
	hasInitialData,
}: DataTableContentProps): JSX.Element => {
	return (
		<>
			{isFilteringData ? (
				<NoMatchFoundTableContent
					notFoundTitle={notFoundTitle}
					notFoundSubtitle={notFoundSubtitle}
					noDataIcon={noDataIcon}
				/>
			) : !hasInitialData ? (
				<EmptyTableFallback
					longText={longText}
					fallbackText={fallbackText}
					noDataIcon={noDataIcon}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export { EmptyTableContent };
