import { getOrganizationService } from 'api/getOrganizationService';

export const getValueListItems = async (listId: string) => {
	const orgService = await getOrganizationService();
	return await orgService
		.getValueListItemsByValueListId({
			valueListId: listId,
		})
		.then((res) => res.data);
};
