/* eslint-disable */
import { dctFill, dctComponents } from './components';
import { dctJSON } from './json';
import { dctTest } from './model';
import { dctStrings } from './dctStrings';
import { dctMath } from './math';
import { dctListener } from './listener';
import { dctDOM } from './dom';
import { dctActions } from './actions';
import {
	player_timebar_segments,
	player_drawstack_annotation,
} from './gen_html';
import playButton from './playButton.svg';
import pauseButton from './pauseButton.svg';
import { sendEventData } from '../../../../analytics/amplitude';
import { AnalyticsAction } from '../../../../generated/graphql';
const styleMap = {};

//Style set function
const dctStyle = function (arg_dom, arg_property, arg_value) {
	arg_dom.style[styleMap[arg_property] || arg_property] = arg_value;
};

//Transform setup
if (
	typeof document.body.style.transform === 'undefined' &&
	typeof document.body.style.webkitTransform !== 'undefined'
) {
	styleMap['transform'] = 'webkitTransform';
}

const noop = (...args) => {
	console.log('No op called with arguments', args);
};

const _listeners = {};

const _session = {};
const dctCrossTabSession = {
	addEventListener: (eventName, cb) => {
		_listeners[eventName] = cb;
	},
	get: (key) => _session[key],
	set: (key, value) => {
		_session[key] = value;
	},
};

//################################################################################
//Data Wrapper
//################################################################################
const dctTestDataWrapper = dctComponents.makeComponent('dct_test_data_wrapper');
dctTestDataWrapper.prototype.init = function () {
	var data_tag = this.element.querySelector('.player_json_data');
	var raw = dctJSON.parseOrNull(data_tag.textContent);
	this._test_data = new dctTest(raw);
};

//Helper function to get data
dctTestDataWrapper.getTestData = function (arg_element) {
	var component = dctComponents.findUpwards(arg_element, dctTestDataWrapper);
	return component._test_data;
};

//################################################################################
//Player options
//################################################################################
const dctPlayerOptions = new (function () {
	//Defaults
	this.defaults = {
		nib: false,
		timings: false,
		_debug: false,
	};

	//Final options
	var final_options = null;

	//Regenerate
	const regenerate = () => {
		//Final options
		var was_null = final_options === null;
		final_options = {};
		for (let k in this.defaults) final_options[k] = this.defaults[k];
		var overrides = dctCrossTabSession.get('playerOptions') || {};
		for (let k in overrides) final_options[k] = overrides[k];

		//If changing, send action
		if (!was_null) dctActions.triggerEventListener('player-options-change');
	};

	//Get them
	this.get = function () {
		return final_options;
	};

	//Set them
	this.set = function (overrides) {
		//Only save different from default
		var ops = {};
		for (var k in overrides) {
			if (this.defaults[k] !== overrides[k]) ops[k] = overrides[k];
		}

		//Set them
		dctCrossTabSession.set('playerOptions', ops);
	};

	//Initialize
	regenerate();
	window.addEventListener('load', regenerate);

	//Listen for changes
	dctCrossTabSession.addEventListener('playerOptions', regenerate);
})();

//Constants
dctPlayerOptions.COLOR_SKETCH = '#000000';
dctPlayerOptions.COLOR_SKETCH_OMIT = 'rgba(0,0,0,0)';
dctPlayerOptions.COLOR_PREVIEW = '#cccccc';
dctPlayerOptions.LINE_WIDTH_SKETCH = 1;

//################################################################################
//Player object
//################################################################################
const dctSketchPlayer = dctComponents.makeComponent('dct_player');
dctSketchPlayer.prototype.init = function () {
	//Get the stroke data
	this._test_data = dctTestDataWrapper.getTestData(this.element);
	this._section_id = this.element.getAttribute('data-section-id');
	this._section = this._test_data.getSectionById(this._section_id);
	this._segments = this._section.getSegments();

	this._playbackActive = false;
	this._playbackSpeed = 1;
	this._playbackTimeStart = 0;
	this._playbackPosition = 0;
	this._playbackStartPosition = 0;
	this._playbackMaxTime = this._section.getDuration();
	this._playbackEndPosition = this._playbackMaxTime;
	this._playbackSegmentIndex = 0;
	this._scale = dctPlayerOptions.get()._debug ? 1 : 0;
	this._scaled_up_once = false;

	this._requestedAnimationFrame = null;

	this._boundFrame = this._frame.bind(this);

	this._canZoom = this.element.classList.contains('dct_player_zoomable');

	this._split_mode = this.element.hasAttribute('data-split-mode');
	if (this._split_mode) this._scissorIndex = this.getDefaultScissorIndex();
};

//Reset the player to its start state
dctSketchPlayer.prototype.reset = function () {
	this.pause();
	this.setScale(0);
	this._setPlayState(false);
	this.seekTime(0);
	this.triggerEventListener('reset', []);
	this.triggerEventListener('repaint', []);
};

//Extends the listener class
dctListener.extendClass(dctSketchPlayer);

//Information
dctSketchPlayer.prototype.getDuration = function () {
	return this._playbackMaxTime;
};
dctSketchPlayer.prototype.getSection = function () {
	return this._section;
};
dctSketchPlayer.prototype.getSegments = function () {
	return this._segments;
};
dctSketchPlayer.prototype.getActive = function () {
	return this._playbackActive;
};
dctSketchPlayer.prototype.getSpeed = function () {
	return this._playbackSpeed;
};
dctSketchPlayer.prototype.getSegmentIndex = function () {
	return this._playbackSegmentIndex;
};

//Figure out default scissor index
dctSketchPlayer.prototype.getDefaultScissorIndex = function () {
	//Note, this returns null if there's one stroke.  That's okay.
	var best = null;
	for (var i = 0; i < this._segments.length; i++) {
		if (
			!this._segments[i].isDown() &&
			(best === null ||
				this._segments[best].getDuration() <
					this._segments[i].getDuration())
		) {
			best = i;
		}
	}
	return best;
};

//Options changed
dctSketchPlayer.prototype.eventOptionsChanged = function () {
	this.triggerEventListener('options', [dctPlayerOptions.get()]);
};
dctActions.addQueryComponentEventListener(
	'player-options-change',
	dctSketchPlayer,
	dctSketchPlayer.prototype.eventOptionsChanged
);

//Scale
dctSketchPlayer.prototype.setScale = function (arg_scale) {
	//Not allowed?
	if (!this._canZoom) return;

	//Different?
	if (arg_scale !== this._scale) {
		//Save states
		if (arg_scale > 0) this._scaled_up_once = true;
		this._scale = arg_scale;

		//Event trigger
		this.triggerEventListener('scale', [arg_scale]);
	}
};
dctSketchPlayer.prototype.getScale = function () {
	return this._scale;
};

//Draw a frame
dctSketchPlayer.prototype._frame = function () {
	//What time is it now?
	var now = Date.now();

	//Calculate new position
	var timediff_real = now - this._playbackTimeStart;
	var timediff_sim = timediff_real * this._playbackSpeed;
	var new_position = Math.min(
		this._playbackMaxTime,
		this._playbackStartPosition + timediff_sim
	);

	//Set position
	this._setPosition(new_position, null);

	//Hit the end?
	if (this._playbackPosition >= this._playbackEndPosition) {
		this._setPlayState(false);
		this.triggerEventListener('end', []);
		this._requestedAnimationFrame = null;
	}
	//Keep going!
	else {
		this._requestedAnimationFrame = requestAnimationFrame(this._boundFrame);
	}
};

//Set position
dctSketchPlayer.prototype._setPosition = function (
	arg_position,
	arg_end_position
) {
	//Set end position?
	if (arg_end_position !== null) this._playbackEndPosition = arg_end_position;

	//Out-of-bounds...
	if (arg_position < 0) arg_position = 0;
	else if (arg_position > this._playbackEndPosition)
		arg_position = this._playbackEndPosition;

	//Set the position
	this._playbackPosition = arg_position;

	//Update which segment we are in, using a simple linear search
	var index_before = this._playbackSegmentIndex;

	//Did we go backwards?  If so, start again!
	if (
		this._segments[this._playbackSegmentIndex].getStartTime_Section() >
		this._playbackPosition
	) {
		this._playbackSegmentIndex = 0;
	}
	//At the end?
	if (this._playbackPosition === this._playbackMaxTime) {
		this._playbackSegmentIndex = this._segments.length - 1;
	}
	//Search forward until we find it!
	else {
		while (
			this._playbackPosition < this._playbackEndPosition &&
			this._segments[this._playbackSegmentIndex].getStopTime_Section() <=
				this._playbackPosition
		) {
			this._playbackSegmentIndex++;
		}
	}

	//Always send position at this point
	//Send along "up" or "down" along with it.
	var down = this._segments[this._playbackSegmentIndex].isDown();
	this.triggerEventListener('position', [
		arg_position,
		this._playbackMaxTime,
		down,
	]);

	//Found something?
	if (index_before !== this._playbackSegmentIndex) {
		this.triggerEventListener('segment', [this._playbackSegmentIndex]);
	}
};

//Set if it is playing or not
dctSketchPlayer.prototype._setPlayState = function (arg_active) {
	//Force boolean
	arg_active = !!arg_active;

	//If no change, and this isn't a force
	if (arg_active === this._playbackActive) return;

	//Is this the first time we've gone active?  And scale is still zero?  Zoom in automatically.
	if (!this._scaled_up_once && arg_active) this.setScale(1);

	//Start playing!
	if (arg_active) {
		this._playbackActive = true;
		this._playbackStartPosition = this._playbackPosition;
		this._playbackTimeStart = Date.now();
		if (!this._requestedAnimationFrame) {
			this._requestedAnimationFrame = requestAnimationFrame(
				this._boundFrame
			);
		}
	}
	//Stop playing!
	else {
		this._playbackActive = false;
		if (this._requestedAnimationFrame)
			cancelAnimationFrame(this._requestedAnimationFrame);
		this._requestedAnimationFrame = null;
	}

	//Trigger an event either way
	this.triggerEventListener('active', [this._playbackActive]);
};

//Set the playback speed
dctSketchPlayer.prototype._setPlaybackSpeed = function (arg_speed) {
	//No change
	if (this._playbackSpeed === arg_speed) return;

	//Set it, trigger event
	this._playbackSpeed = arg_speed;
	this.triggerEventListener('speed', [arg_speed]);

	//Am I currently in the middle of playing?  If so, update time mechanisms so timing stays right!
	if (this._playbackActive) {
		this._playbackStartPosition = this._playbackPosition;
		this._playbackTimeStart = Date.now();
	}
};

//Play a range of content
dctSketchPlayer.prototype.playSegment = function (arg_index) {
	//Get the segment
	var segment = this._segments[arg_index];

	//Set the position and end time
	this._playbackStartPosition = segment.getStartTime_Section();
	this._playbackTimeStart = Date.now();
	this._setPosition(
		this._playbackStartPosition,
		segment.getStopTime_Section()
	);

	//And start playing, at speed 1
	this._setPlayState(true);
};

//Seek a given time
dctSketchPlayer.prototype.seekTime = function (arg_position) {
	this._playbackStartPosition = arg_position;
	this._playbackTimeStart = Date.now();
	this._setPosition(arg_position, this._playbackMaxTime);
};

//Seek a certain percent
dctSketchPlayer.prototype.seekPercent = function (arg_percent) {
	this.seekTime(Math.floor(arg_percent * this._playbackMaxTime));
};

//Toggle play state
dctSketchPlayer.prototype.playToggle = function (arg_stop) {
	//Playing?
	if (this._playbackActive) {
		this._setPlayState(false);
		if (arg_stop) {
			this._setPosition(this._playbackMaxTime, this._playbackMaxTime);
		}
	}
	//Paused?
	else {
		if (this._playbackPosition === this._playbackMaxTime) {
			this._setPosition(0, this._playbackMaxTime);
		}
		this._setPosition(this._playbackPosition, this._playbackMaxTime);
		this._setPlayState(true);
	}
};

//Change speed
dctSketchPlayer.prototype.setSpeed = function (arg_speed) {
	this._setPlaybackSpeed(arg_speed);
};

//Simple playback changes
dctSketchPlayer.prototype.play = function play() {
	this._setPlayState(true);
};
dctSketchPlayer.prototype.pause = function pause() {
	this._setPlayState(false);
};

//Getters
dctSketchPlayer.prototype.getPosition = function getPosition() {
	return this._playbackPosition;
};

//Scissor (split tests) functionality
dctSketchPlayer.prototype.setScissorIndex = function (arg_index) {
	//Do-nothing
	if (this._scissorIndex === arg_index) return;

	//Change and event
	this._scissorIndex = arg_index;
	this.triggerEventListener('scissor', [this._scissorIndex]);
};
dctSketchPlayer.prototype.getScissorIndex = function () {
	return this._scissorIndex;
};
dctSketchPlayer.prototype.adjustScissorIndex = function (arg_amount) {
	var new_index = this._scissorIndex + arg_amount * 2; //Why *2?  Half are pauses, half are strokes!
	new_index = Math.max(new_index, 1); //First pause
	new_index = Math.min(new_index, this._segments.length - 2); //Last pause
	this.setScissorIndex(new_index);
};
dctSketchPlayer.prototype.getScissorSegment = function () {
	return this._segments[this._scissorIndex];
};

//Global event - reset all on print dialog
window.addEventListener('beforeprint', function () {
	dctComponents
		.queryComponents(document.body, '*', dctSketchPlayer)
		.forEach(function (player) {
			player.reset();
		});
});

// ################################################################################
// Timebar control
// ################################################################################
dctSketchPlayer.Timebar = dctComponents.makeComponent('dct_player_timebar');
dctSketchPlayer.Timebar.prototype.init = function () {
	//Player events
	this._player = dctComponents.findUpwards(this.element, dctSketchPlayer);
	this._player.addEventListener(
		'active',
		this.eventPlaybackActive.bind(this)
	);
	this._player.addEventListener(
		'position',
		this.eventPositionChange.bind(this)
	);
	this._player.addEventListener(
		'segment',
		this.eventSegmentChange.bind(this)
	);
	this._player.addEventListener('end', this.eventPlaybackEnd.bind(this));
	this._player.addEventListener('scissor', this.eventScissor.bind(this));
	this._player.addEventListener('reset', this.eventReset.bind(this));

	//DOM and events
	this._dom_arrow = this.element.querySelector('.timebar_arrow');
	this._dom_ghost_arrow = this.element.querySelector('.timebar_ghost_arrow');
	this._dom_time = this.element.querySelector('.timebar_time');
	this._dom_bar = this.element.querySelector('.timebar_bar');
	this._has_played = false;

	//Split mode?
	this._split_mode = this.element.classList.contains(
		'dct_player_timebar_split'
	);

	//Segments
	var SEGMENT_THUMBNAIL_WIDTH = 16;
	var SEGMENT_THUMBNAIL_HEIGHT = 16;
	this._dom_segment_box = this.element.querySelector('.timebar_segments');
	dctFill(
		this._dom_segment_box,
		player_timebar_segments(
			this._player.getSection(),
			this._player.getSegments(),
			SEGMENT_THUMBNAIL_WIDTH,
			SEGMENT_THUMBNAIL_HEIGHT,
			this._split_mode
		)
	);
	this._dom_segments = this.element.querySelectorAll('.timebar_segment');
	this._dom_segment_current = null;

	//Track
	this._dom_catcher = this.element.querySelector('.timebar_clickcatcher');
	this._dom_catcher.addEventListener(
		'mousedown',
		this.eventTrackMouseDown.bind(this),
		false
	);
	this._dom_catcher.addEventListener(
		'mouseenter',
		this.eventTrackMouseEnter.bind(this),
		false
	);
	this._dom_catcher.addEventListener(
		'mouseleave',
		this.eventTrackMouseLeave.bind(this),
		false
	);
	this._dom_catcher.addEventListener(
		'mousemove',
		this.eventTrackMouseMove.bind(this),
		false
	);
	this._dom_segment_box.addEventListener(
		'click',
		this.eventSegmentClick.bind(this),
		false
	);

	//Setup
	this.eventSegmentChange(null);
	this.eventScissor(this._player.getScissorIndex());

	//Play mode?
	this._play_mode = false;
};

//Scissor application
dctSketchPlayer.Timebar.prototype.eventReset = function () {
	this.setPlayMode(false);
	this._dom_time.textContent = '';
};

//Event play mode changed!
dctSketchPlayer.Timebar.prototype.setPlayMode = function (arg_play_mode) {
	if (this._play_mode !== arg_play_mode) {
		this._play_mode = arg_play_mode;
		this.element.classList.toggle('timebar_playmode', arg_play_mode);
		document
			.getElementById('player_button')
			.setAttribute('src', arg_play_mode ? pauseButton : playButton);
	}
	if (!this._has_played) {
		this._has_played = true;
		sendEventData({
			eventType: AnalyticsAction.PlayedRecording,
			eventProperties: {
				recordingType: `${this._player._section_id.toUpperCase()}_CLOCK`,
			},
		});
	}
};

//Event playback changed!
dctSketchPlayer.Timebar.prototype.eventPlaybackActive = function (arg_active) {
	if (arg_active) this.setPlayMode(true);
};

//Event position changed!
dctSketchPlayer.Timebar.prototype.eventPositionChange = function (
	arg_position,
	arg_duration,
	arg_down
) {
	//None?
	if (!this._dom_segment_current)
		this.eventSegmentChange(this._player.getSegmentIndex());

	//After zero?
	if (arg_position > 0) this.setPlayMode(true);

	//Change
	var percent = arg_position / arg_duration;
	dctStyle(
		this._dom_arrow,
		'transform',
		'translate3d(' + (100 * percent).toFixed(4) + '%,0,0)'
	);
	dctStyle(
		this._dom_bar,
		'transform',
		'scale(' + percent.toFixed(4) + ',1.0)'
	);
	if (percent === 1) {
		this._has_played = false;
	}
	this._dom_arrow.classList.toggle('timebar_arrow_down', arg_down);

	//Time
	this._dom_time.textContent = '';
};

//On active segment changed
dctSketchPlayer.Timebar.prototype.eventSegmentChange = function (arg_index) {
	//Old one!
	if (this._dom_segment_current)
		this._dom_segment_current.classList.remove('active');

	//Change
	this._dom_segment_current =
		arg_index == null ? null : this._dom_segments[arg_index];

	//New one!
	if (this._dom_segment_current)
		this._dom_segment_current.classList.add('active');
};

//Playback ended
dctSketchPlayer.Timebar.prototype.eventPlaybackEnd = function () {
	//TODO, do something on end?
};

//Event position changed!
dctSketchPlayer.Timebar.prototype.eventScissor = function (arg_scissor_index) {
	//Old one?
	if (this._scissor_element) {
		this._scissor_element.classList.remove('timebar_segment_cutpoint');
		this._scissor_element = null;
	}

	//New one?
	if (arg_scissor_index !== null) {
		this._scissor_element = this.element.querySelector(
			"*[data-segment-index='" + arg_scissor_index + "']"
		);
		if (this._scissor_element)
			this._scissor_element.classList.add('timebar_segment_cutpoint');
	}
};

//A segment got clicked
dctSketchPlayer.Timebar.prototype.eventSegmentClick = function (arg_event) {
	//Stop events
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Find the segment index
	var segment = dctDOM.findClassUpwards(arg_event.target, 'timebar_segment');
	if (!segment) return;
	var segindex = parseFloat(segment.getAttribute('data-segment-index'));
	if (isNaN(segindex)) return;

	//Split mode?
	if (this._split_mode) {
		if (segment.classList.contains('timebar_segment_off')) {
			this._player.setScissorIndex(segindex);
		}
	} else {
		//Play it!
		this._player.playSegment(segindex);
	}
};

//Track action
dctSketchPlayer.Timebar.prototype.eventTrackAction = function (
	arg_event,
	arg_down
) {
	//Get coordinates
	let bbox = this._dom_segment_box.getBoundingClientRect();
	var coords = dctDOM.mouseEventToRelativeXY(bbox, arg_event);
	var percent = Math.max(0, Math.min(1, coords.x / (bbox.width || 1)));

	//Down or not?
	var time = Math.floor(percent * this._player.getDuration());
	var segment = this._player.getSection().getSegmentAt(time);
	var down = segment.isDown();

	//Set the ghost coordinates
	dctStyle(
		this._dom_ghost_arrow,
		'transform',
		'translate3d(' + (100 * percent).toFixed(4) + '%,0,0)'
	);
	this._dom_ghost_arrow.classList.toggle('timebar_arrow_down', down);

	//Click?
	if (arg_down) this._player.seekPercent(percent);
};

//Time bar clicked
dctSketchPlayer.Timebar.prototype.eventTrackMouseDown = function (arg_event) {
	this.eventTrackAction(arg_event, true);
};

//Ghost arrow behavior
dctSketchPlayer.Timebar.prototype.eventTrackMouseEnter = function (arg_event) {
	this._dom_ghost_arrow.classList.add('active');
};
dctSketchPlayer.Timebar.prototype.eventTrackMouseLeave = function (arg_event) {
	this._dom_ghost_arrow.classList.remove('active');
};
dctSketchPlayer.Timebar.prototype.eventTrackMouseMove = function (arg_event) {
	this.eventTrackAction(arg_event, false);
};

// ################################################################################
// Player control bar
// ################################################################################

dctSketchPlayer.Controlbar = dctComponents.makeComponent(
	'dct_player_controlbar'
);
dctSketchPlayer.Controlbar.prototype.init = function () {
	//Player events
	this._player = dctComponents.findUpwards(this.element, dctSketchPlayer);
	this._player.addEventListener(
		'active',
		this.eventPlaybackActive.bind(this)
	);
	this._player.addEventListener('speed', this.eventPlaybackSpeed.bind(this));
	this._player.addEventListener('scale', this.eventScale.bind(this));
	this._player.addEventListener('scissor', this.eventScissor.bind(this));

	//Play button
	this._dom_play = this.element.querySelector('.controlbar_link_playpause');
	this._dom_play.addEventListener('click', this.eventPlayClick.bind(this));

	//Speed buttons
	this._dom_speeds = this.element.querySelectorAll('.controlbar_link_speed');
	for (var i = 0; i < this._dom_speeds.length; i++) {
		this._dom_speeds[i].addEventListener(
			'click',
			this.eventSpeedClick.bind(this),
			false
		);
	}

	//Zoom buttons
	this._dom_zoom = this.element.querySelector('.controlbar_link_zoom');
	if (this._dom_zoom)
		this._dom_zoom.addEventListener(
			'click',
			this.eventZoomClick.bind(this)
		);

	//Scissor buttons
	this._dom_scissor_left = this.element.querySelector(
		'.controlbar_link_scissor_left'
	);
	this._dom_scissor_right = this.element.querySelector(
		'.controlbar_link_scissor_right'
	);
	if (this._dom_scissor_left)
		this._dom_scissor_left.addEventListener(
			'click',
			this.eventScissorLeft.bind(this)
		);
	if (this._dom_scissor_right)
		this._dom_scissor_right.addEventListener(
			'click',
			this.eventScissorRight.bind(this)
		);

	//Initialize
	this.eventPlaybackActive(this._player.getActive());
	this.eventPlaybackSpeed(1);
};

//Playback change
dctSketchPlayer.Controlbar.prototype.eventPlaybackActive = function (
	arg_active
) {
	this._dom_play.classList.toggle('active', arg_active);
	this._dom_play.firstChild.textContent = !arg_active ? '\uf04b' : '\uf04c';
	this._dom_play.firstChild.setAttribute(
		'src',
		!arg_active ? playButton : pauseButton
	);
};

//Speed change
dctSketchPlayer.Controlbar.prototype.eventPlaybackSpeed = function (arg_speed) {
	for (var i = 0; i < this._dom_speeds.length; i++) {
		var dom = this._dom_speeds[i];
		dom.classList.toggle(
			'active',
			arg_speed === parseFloat(dom.getAttribute('data-speed'))
		);
	}
};

//Scale change
dctSketchPlayer.Controlbar.prototype.eventScale = function (arg_scale) {
	if (this._dom_zoom)
		this._dom_zoom.firstChild.textContent =
			arg_scale > 0 ? '\uF010' : '\uF00E';
};

//Speed link clicked
dctSketchPlayer.Controlbar.prototype.eventPlayClick = function (arg_event) {
	//Block
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Toggle play
	this._player.playToggle();
};

//Speed link clicked
dctSketchPlayer.Controlbar.prototype.eventSpeedClick = function (arg_event) {
	//Block
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Get speed
	var speed_dom = dctDOM.findClassUpwards(
		arg_event.target,
		'controlbar_link_speed'
	);
	if (!speed_dom) return;
	var speed = parseFloat(speed_dom.getAttribute('data-speed'));
	if (!speed) return;

	//Set speed
	this._player.setSpeed(speed);
};

//Zoom link clicked
dctSketchPlayer.Controlbar.prototype.eventZoomClick = function (arg_event) {
	//Block
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Toggle play
	this._player.setScale(this._player.getScale() > 0 ? 0 : 1);
};

//Scissor position adjusted
dctSketchPlayer.Controlbar.prototype.eventScissor = function (
	arg_scissor_index
) {
	//
	this._dom_scissor_left.classList.toggle(
		'controlbar_link_disabled',
		arg_scissor_index <= 1
	);
	this._dom_scissor_right.classList.toggle(
		'controlbar_link_disabled',
		arg_scissor_index >= this._player.getSegments().length - 2
	);
};

//Scissor left/right buttons
dctSketchPlayer.Controlbar.prototype.eventScissorMove = function (
	arg_event,
	arg_amount
) {
	//Block
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Adjust
	this._player.adjustScissorIndex(arg_amount);
};
dctSketchPlayer.Controlbar.prototype.eventScissorLeft = function (arg_event) {
	this.eventScissorMove(arg_event, -1);
};
dctSketchPlayer.Controlbar.prototype.eventScissorRight = function (arg_event) {
	this.eventScissorMove(arg_event, 1);
};

// ################################################################################
// Player micro control bar
// ################################################################################

dctSketchPlayer.MicroControlbar = dctComponents.makeComponent(
	'dct_player_microcontrolbar'
);
dctSketchPlayer.MicroControlbar.prototype.init = function () {
	//Player events
	this._player = dctComponents.findUpwards(this.element, dctSketchPlayer);
	this._player.addEventListener(
		'active',
		this.eventPlaybackActive.bind(this)
	);

	//DOM and events
	this._dom_play = this.element.querySelector('.microcontrolbar_play');
	this._dom_play.addEventListener('click', this.eventPlayClick.bind(this));

	this._dom_time = this.element.querySelector('.microcontrolbar_time');
	this._player.addEventListener(
		'position',
		this.eventPlaybackPosition.bind(this)
	);

	//Initialize
	this.eventPlaybackActive(this._player.getActive());
	this.eventPlaybackPosition(this._player.getPosition());
};

//Playback change
dctSketchPlayer.MicroControlbar.prototype.eventPlaybackActive = function (
	arg_active
) {
	this._dom_play.firstChild.textContent = !arg_active ? '\uf04b' : '\uf04d';
};
//Playback change
dctSketchPlayer.MicroControlbar.prototype.eventPlaybackPosition = function (
	arg_position,
	arg_duration
) {
	//Set the text content
	this._dom_time.textContent = !this._player.getActive()
		? ''
		: dctStrings.time_mm_ss(arg_position, 2);
};

//Speed link clicked
dctSketchPlayer.MicroControlbar.prototype.eventPlayClick = function (
	arg_event
) {
	//Block
	arg_event.preventDefault();
	arg_event.stopPropagation();

	//Toggle play
	this._player.playToggle(true);
};

// ################################################################################
// Player rendering surface
// ################################################################################

dctSketchPlayer.Drawstack = dctComponents.makeComponent('dct_player_drawstack');
dctSketchPlayer.Drawstack.prototype.init = function () {
	//DOM elements
	this._dom_paper = this.element.querySelector('.drawstack_paper');
	this._canvas = this.element.querySelector('.drawstack_canvas');
	this._dom_nib = this.element.querySelector('.drawstack_nib');

	//Draw mode
	this._draw_mode = this.element.getAttribute('data-draw-mode');
	switch (this._draw_mode) {
		case 'split_before':
			this._styler_sketch = this.styleSplitBefore;
			this._styler_pre = this.stylePreviewBefore;
			break;
		case 'split_after':
			this._styler_sketch = this.styleSplitAfter;
			this._styler_pre = this.stylePreviewAfter;
			break;
		default:
			this._styler_sketch = this.styleSketch;
			break;
	}

	//Player events
	this._player = dctComponents.findUpwards(this.element, dctSketchPlayer);
	this._player.addEventListener('active', this.eventActive.bind(this));
	this._player.addEventListener(
		'position',
		this.eventPlaybackPosition.bind(this)
	);
	this._player.addEventListener('scale', this.eventScale.bind(this));
	this._player.addEventListener(
		'segment',
		this.eventSegmentChange.bind(this)
	);
	this._player.addEventListener(
		'options',
		this.eventOptionsChanged.bind(this)
	);
	this._player.addEventListener('scissor', this.eventScissor.bind(this));
	this._player.addEventListener('reset', this.eventReset.bind(this));

	//Element events
	this.element.addEventListener('click', this.eventClick.bind(this));

	//Window events
	dctActions.addEventListener(
		'window-resize-width',
		this.eventWindowSize.bind(this),
		this
	);

	//Nib variables
	this._nib_x = 0;
	this._nib_y = 0;
	this._nib_display = 0;

	//Scissor
	this._scissorIndex = this._player.getScissorIndex();

	//Painter variables
	this._section = this._player.getSection();
	this._segments = this._player.getSegments();
	this._segment_count = this._segments.length;
	this._segpainter_index = 0;
	this._paint_position = 0;
	this._segpainters = [];
	for (var i = 0; i < this._segment_count; i++) {
		//Initialize painter for this segment
		var segment = this._segments[i];
		var p = {
			segment: segment,
			point_index: -1,
			interpolation: 0,
			last_x: null,
			last_y: null,
			index: i,
		};
		this._segpainters.push(p);
	}

	//Page bounds
	this._page_bounds = this._section.getPageBounds();

	//Drawing bounds
	this._draw_bounds = this._section.getDrawingBounds().getRounded();

	//Set playback position to end
	this._end_position = this._section.getDuration();
	this._last_paint_position = this._end_position;

	//Request initial paint
	this._resetCanvasOnRepaint = true;
	this._requestedAnimationFrame = null;
	this._boundRepaint = function () {
		this.eventPlaybackPosition(
			this._last_paint_position,
			this._player.getDuration()
		);
	}.bind(this);
	this.requestRepaint();

	//On repaint event, paint immediately.
	this._player.addEventListener('repaint', this._boundRepaint);

	//Set initial zoom
	this._zoom_click_coordinates = null;
	this.eventScale(this._player.getScale());

	//Setup options
	this.eventOptionsChanged(dctPlayerOptions.get());
	this._init_complete = true;
};

//Scissor application
dctSketchPlayer.Drawstack.prototype.eventReset = function () {
	this._last_paint_position = this._end_position;
	this.requestRepaint();
};

//Scissor application
dctSketchPlayer.Drawstack.prototype.eventScissor = function (
	arg_scissor_index
) {
	this._scissorIndex = arg_scissor_index;
	this.requestRepaint(true);
};

//Initialize annotations
dctSketchPlayer.Drawstack.prototype.initializeAnnotations = function () {
	//Prevent double-setup
	if (this._annotationsInitialized) return;
	this._annotationsInitialized = true;

	//Set up annotations
	this._segment_annotation_current = null;
	this._segment_annotations = [];
	var seg_counter = 0;
	var temp_container = document.createElement('div');
	for (var i = 0; i < this._segments.length; i++) {
		//Check it
		if (!this._segments[i].isDown()) continue;

		//Create DOM
		seg_counter++;
		temp_container.innerHTML = player_drawstack_annotation(
			seg_counter,
			this._segments[i],
			this._segments[i + 1]
		);
		var annot = temp_container.firstElementChild;
		this.element.appendChild(annot);

		//References
		this._segment_annotations[i] = annot;
	}

	//Request repaint to force position update
	this.requestRepaint(true);
};

//On active segment changed
dctSketchPlayer.Drawstack.prototype.eventSegmentChange = function (arg_index) {
	//What should be active?
	//eslint-disable-next-line
	var new_active = !this._timings_on
		? null //Not on?
		: arg_index == null
		? null //No active segment?
		: !this._player._playbackActive && this._player._playbackPosition === 0
		? null //At start!
		: this._segment_annotations[arg_index] || //eslint-disable-next-line
		  this._segment_annotations[arg_index - 1]; //This one, or the one before
	if (new_active === this._segment_annotation_current) return;

	//Old one!
	if (this._segment_annotation_current)
		this._segment_annotation_current.classList.remove('active');

	//New one!
	this._segment_annotation_current = new_active;
	if (this._segment_annotation_current)
		this._segment_annotation_current.classList.add('active');
};

//Active?
dctSketchPlayer.Drawstack.prototype.eventActive = function (arg_active) {
	this.eventSegmentChange(this._player.getSegmentIndex());
};

//Options change
dctSketchPlayer.Drawstack.prototype.eventOptionsChanged = function (
	arg_options
) {
	//Timing annotations
	this._timings_on = arg_options.timings;
	if (this._timings_on) this.initializeAnnotations();
	this.element.classList.toggle(
		'drawstack_show_annotations',
		this._timings_on
	);

	//Nib
	this.element.classList.toggle('drawstack_show_nib', !!arg_options.nib);

	//Colorization & labels
	this._labels_on = this._confidence_colors = arg_options._debug;

	//Triggers relevant events
	this.eventSegmentChange(this._player.getSegmentIndex());

	//Reset
	if (this._init_complete) {
		this.requestRepaint(true);
	}
};

//On destroy...
dctSketchPlayer.Drawstack.prototype.destroyed = function () {
	//Remove event listener
	dctActions.removeAllFromContext(this);
};

//Request a repaint
dctSketchPlayer.Drawstack.prototype.requestRepaint = function (arg_restart) {
	//Resize next frame?
	if (arg_restart) this._resetCanvasOnRepaint = true;

	//Request a frame
	if (!this._requestedAnimationFrame)
		this._requestedAnimationFrame = requestAnimationFrame(
			this._boundRepaint
		);
};

//Reset canvas
dctSketchPlayer.Drawstack.prototype.resetCanvas = function (arg_event) {
	//Canvas padding is global pixel constant.
	//Why padding?
	//Because if you don't pad, the anti-aliasing gets clipped off, and the edges of the drawing look shabby.
	//Also, the nib gets cut off in normal zoom mode without it.
	this._canvas_padding = 10;

	//Record some old values before we transition
	var old_width = this._canvas_width;
	var old_height = this._canvas_height;

	//Shortcuts to make things a little easier to read
	var drawbounds = this._draw_bounds;
	var pagebounds = this._page_bounds;

	//Get the boundaries for the entire draw area
	let bbox = this.element.getBoundingClientRect();

	//How big is the area we have to paint?
	if (this._scale > 0) {
		//Fit the biggest thing we can draw in there
		var fit_size = dctMath.fitRectangle(
			drawbounds.getWidth(),
			drawbounds.getHeight(), //Size of drawing
			bbox.width * this._scale,
			bbox.height * this._scale, //Size of container
			true, //Rounding
			this._canvas_padding //Padding
		);

		//Set the sizes
		this._canvas_width = fit_size.width;
		this._canvas_height = fit_size.height;
		this._canvas_left = this._scale > 1 ? 0 : fit_size.center_left;
		this._canvas_top = this._scale > 1 ? 0 : fit_size.center_top;
		this._scale_x = fit_size.scale;
		this._scale_y = fit_size.scale;

		//Click zoom?
		if (this._zoom_click_coordinates && this._scale > 1) {
			//Save and erase, so this happens once
			var coords = this._zoom_click_coordinates;
			this._zoom_click_coordinates = null;

			//Percentages
			var x_percent = coords.x / bbox.width;
			var y_percent = coords.y / bbox.height;

			//Add a buffer so we don't have to touch the edges
			var percent_buffer = 0.2;
			var x_percent_buffered = Math.max(
				0,
				Math.min(
					1,
					(x_percent - percent_buffer) / (1 - 2 * percent_buffer)
				)
			);
			var y_percent_buffered = Math.max(
				0,
				Math.min(
					1,
					(y_percent - percent_buffer) / (1 - 2 * percent_buffer)
				)
			);

			//Max amount of scroll
			var max_x = this._canvas_width - bbox.width;
			var max_y = this._canvas_height - bbox.height;

			//Final calculation and set!
			this._canvas_left = this._canvas_left - x_percent_buffered * max_x;
			this._canvas_top = this._canvas_top - y_percent_buffered * max_y;
		}
	} else {
		//Get the boundaries of the paper element
		let bbox = this._dom_paper.getBoundingClientRect();

		//The size of the canvas depends on the size of the drawing relative to the size of the paper
		this._canvas_width = Math.round(
			(drawbounds.getWidth() / (pagebounds.getWidth() || 1)) * bbox.width
		);
		this._canvas_height = Math.round(
			(drawbounds.getHeight() / (pagebounds.getHeight() || 1)) *
				bbox.height
		);
		this._canvas_left =
			((drawbounds.getLeft() - pagebounds.getLeft()) /
				(pagebounds.getWidth() || 1)) *
			bbox.width;
		this._canvas_top =
			((drawbounds.getTop() - pagebounds.getTop()) /
				(pagebounds.getWidth() || 1)) *
			bbox.width;
		this._scale_x = this._canvas_width / (drawbounds.getWidth() || 1);
		this._scale_y = this._canvas_height / (drawbounds.getHeight() || 1);

		//Apply padding
		this._canvas_width += 2 * this._canvas_padding;
		this._canvas_height += 2 * this._canvas_padding;
		this._canvas_left -= this._canvas_padding;
		this._canvas_top -= this._canvas_padding;
	}

	//Canvas transform
	this._canvas_transform =
		'translate3d(' +
		this._canvas_left +
		'px,' +
		this._canvas_top +
		'px,0px)';

	//Calculate scale change
	this._scale_change_x = old_width
		? old_width / (this._canvas_width || 1)
		: null;
	this._scale_change_y = old_height
		? old_height / (this._canvas_height || 1)
		: null;

	//Numbers always behave this way
	this._subtract_x = drawbounds.getLeft();
	this._subtract_y = drawbounds.getTop();

	//Resize and reposition the canvas
	this._canvas.width = this._canvas_width; //For API purposes, this must be in pixels!
	this._canvas.height = this._canvas_height; //For API purposes, this must be in pixels!
	this._canvas.style.width = this._canvas.width + 'px'; //Width of the canvas!
	this._canvas.style.height = this._canvas.height + 'px'; //Width of the canvas!

	//Context setup - must be done every time canvas is resized!
	this._ctx = this._canvas.getContext('2d');
	this._ctx.lineCap = 'round';
	this._ctx.lineJoin = 'round';
	this._ctx.lineWidth = dctPlayerOptions.LINE_WIDTH_SKETCH;
	this._ctx.strokeStyle = dctPlayerOptions.COLOR_SKETCH;

	//And reset
	this.resetPaint();

	//Constants
	var ANNOTATION_PADDING = 16;
	var ANNOTATION_MARGIN = 4;
	var ANNOTATION_VOFFSET = -8;

	//Figure out the location for all the annotations
	if (this._segment_annotations) {
		for (var i = 0; i < this._segment_annotations.length; i++) {
			//Get references
			var annot = this._segment_annotations[i];
			if (!annot) continue;
			var seg = this._segments[i];
			var bounds = seg.getDrawingBounds();
			var annot_width = annot.getBoundingClientRect().width;
			var annot_height = annot.getBoundingClientRect().height;

			//Calculate the positions
			var annot_left =
				ANNOTATION_PADDING +
				this._canvas_left +
				this._canvas_width *
					((bounds.getRight() - this._draw_bounds.getLeft()) /
						this._draw_bounds.getWidth());
			var annot_top =
				ANNOTATION_VOFFSET +
				this._canvas_top +
				this._canvas_height *
					((bounds.getTop() +
						bounds.getHeight() / 2 -
						this._draw_bounds.getTop()) /
						this._draw_bounds.getHeight());

			//Off the page?  Do something about it! (A pretty crude calculation, could try left, bottom, center, etc, someday)
			if (annot_left + annot_width >= bbox.width - ANNOTATION_MARGIN)
				annot_left = bbox.width - annot_width - ANNOTATION_MARGIN;
			if (annot_left < ANNOTATION_MARGIN) annot_left = ANNOTATION_MARGIN;
			if (annot_top + annot_height > bbox.height - ANNOTATION_MARGIN)
				annot_top = bbox.height - annot.height - ANNOTATION_MARGIN;
			if (annot_top < ANNOTATION_MARGIN) annot_top = ANNOTATION_MARGIN;

			//Set position
			dctStyle(
				annot,
				'transform',
				'translate3d(' + annot_left + 'px,' + annot_top + 'px,0px)'
			);
		}
	}
};

//Click event
dctSketchPlayer.Drawstack.prototype.eventClick = function (arg_event) {
	var current_scale = this._player.getScale();
	this._player.setScale(
		current_scale === 0
			? 1
			: current_scale === 1
			? 2
			: current_scale === 2
			? 1
			: 0
	);

	//Immediate move action if zoom > 0
	if (this._player.getScale() > 1) {
		this._zoom_click_coordinates = dctDOM.mouseEventToRelativeXY(
			this.element.getBoundingClientRect(),
			arg_event
		);
	}
};

//Set zoom
dctSketchPlayer.Drawstack.prototype.eventScale = function (arg_amount) {
	//Only if changed...
	var scale = this._player.getScale();
	if (this._scale !== arg_amount) {
		//Set variable and class, and reset the canvas
		this._scale = arg_amount;
		this.element.classList.toggle('drawstack_zoomed', this._scale > 0);
		this.element.classList.toggle('drawstack_zoommax', this._scale > 1);

		//Repaint required now
		this.requestRepaint(true);
	}
};

//Reset paint state
dctSketchPlayer.Drawstack.prototype.resetPaint = function () {
	//Reset all the segment poainters
	for (; this._segpainter_index >= 0; this._segpainter_index--) {
		var segpainter = this._segpainters[this._segpainter_index];
		if (!segpainter) continue;
		segpainter.point_index = 0;
		segpainter.interpolation = 0;
		segpainter.last_x = null;
		segpainter.last_y = null;
		segpainter.labelled = false;
	}
	this._segpainter_index = 0;
	this._paint_position = 0;
};

//Window size
dctSketchPlayer.Drawstack.prototype.eventWindowSize = function () {
	this.requestRepaint(true);
};

//Playback segments to a certain position
dctSketchPlayer.Drawstack.prototype.eventPlaybackPosition = function (
	arg_position,
	arg_max_position
) {
	//Save the last paint position so we can repaint later if necessary
	this._last_paint_position = arg_position;

	//Reset canvas?
	if (this._resetCanvasOnRepaint) this.resetCanvas();

	//Move?
	if (this._canvas_transform) {
		//Animated version - only applies with @media=screen!
		this._canvas.classList.add('instant_move');
		if (
			this._canvas_transform_last &&
			this._scale_change_x &&
			this._scale_change_y
		) {
			dctStyle(
				this._canvas,
				'transform',
				this._canvas_transform_last +
					' scale(' +
					this._scale_change_x.toFixed(3) +
					',' +
					this._scale_change_y.toFixed(3) +
					')'
			);
			var _ = this._canvas.offsetWidth;
			this._canvas.classList.remove('instant_move');
		}
		dctStyle(this._canvas, 'transform', this._canvas_transform);

		//Print version - only applies with @media=print!
		//This is done because Edge has a bug where translate3d doesn't print correctly, it offsets too much.
		//See drawstack.less for the media queries that apply this.
		dctStyle(this._canvas, 'left', this._canvas_left.toFixed(3) + 'px');
		dctStyle(this._canvas, 'top', this._canvas_top.toFixed(3) + 'px');

		this._canvas_transform_last = this._canvas_transform;
		this._canvas_transform = null;
	}

	//If we went backwards, we need to erase and repaint forwards!
	if (this._paint_position > arg_position || this._resetCanvasOnRepaint) {
		//Clear this canvas
		this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
		this.resetPaint();

		//Prepaint?
		//This paints the ENTIRE clock, beginning to end, with a different style, then resets the position so it is
		//painted dynamically with animation.
		if (this._styler_pre) {
			this.paint(arg_max_position, this._styler_pre);
			this.resetPaint();
		}
	}

	//Clear flags
	this._resetCanvasOnRepaint = false;
	this._requestedAnimationFrame = null;

	//Paint
	this.paint(arg_position, this._styler_sketch, true);
};

//Segment stylers
//These alter the drawing mode of stroke painting for different modes of painting.
dctSketchPlayer.Drawstack.prototype.styleSplitBefore = function (
	arg_segment,
	arg_segpainter
) {
	this._ctx.strokeStyle =
		arg_segpainter.index < this._scissorIndex
			? dctPlayerOptions.COLOR_SKETCH
			: dctPlayerOptions.COLOR_SKETCH_OMIT;
};
dctSketchPlayer.Drawstack.prototype.styleSplitAfter = function (
	arg_segment,
	arg_segpainter
) {
	this._ctx.strokeStyle =
		arg_segpainter.index >= this._scissorIndex
			? dctPlayerOptions.COLOR_SKETCH
			: dctPlayerOptions.COLOR_SKETCH_OMIT;
};
dctSketchPlayer.Drawstack.prototype.stylePreviewBefore = function (
	arg_segment,
	arg_segpainter
) {
	this._ctx.strokeStyle =
		arg_segpainter.index < this._scissorIndex
			? dctPlayerOptions.COLOR_PREVIEW
			: dctPlayerOptions.COLOR_SKETCH_OMIT;
};
dctSketchPlayer.Drawstack.prototype.stylePreviewAfter = function (
	arg_segment,
	arg_segpainter
) {
	this._ctx.strokeStyle =
		arg_segpainter.index >= this._scissorIndex
			? dctPlayerOptions.COLOR_PREVIEW
			: dctPlayerOptions.COLOR_SKETCH_OMIT;
};
dctSketchPlayer.Drawstack.prototype.styleSketch = function (arg_segment) {
	//Confidence colorization
	if (this._confidence_colors)
		this._ctx.strokeStyle = arg_segment.getStrokeTypeConfidenceColor();
	else this._ctx.strokeStyle = dctPlayerOptions.COLOR_SKETCH;
};

//Playback segments to a certain position
dctSketchPlayer.Drawstack.prototype.paint = function (
	arg_position,
	arg_styler,
	arg_primary
) {
	//Draw forwards
	var segpainter;
	var path_started = false;
	var catchall = 0;
	while (this._paint_position < arg_position) {
		//Get the segpainter
		segpainter = this._segpainters[this._segpainter_index];
		if (!segpainter) {
			this._paint_position = arg_position;
			break;
		} //Reached the end
		var segment = segpainter.segment;

		//Empty?  Don't paint a thing!
		if (!segment.isDown()) {
			//Up = no nib
			this._nib_display = 0;

			//Record painted
			this._paint_position = Math.min(
				segment.getStopTime_Section(),
				arg_position
			);
			if (arg_position >= segment.getStopTime_Section())
				this._segpainter_index++;
			continue;
		}

		//The next piece of the line to paint
		var from_index = Math.max(0, segpainter.point_index);
		var to_index = segpainter.point_index + 1;

		//If we've reached the end of this segment, go to the next one.
		if (to_index >= segpainter.segment.getCount()) {
			//We've painted as much as we can on this stroke!
			if (path_started) {
				this._ctx.stroke();
				path_started = false;
			}
			this._segpainter_index++;
		}
		//Figure out how to draw the line
		else {
			//What percentage to draw?
			var from_time = segment.getTime(from_index);
			var to_time = segment.getTime(to_index);
			var interp_percentage = Math.min(
				1,
				(arg_position - from_time) / (to_time - from_time || 1)
			);

			//Calculate starting X and Y position if necessary
			if (!segpainter.last_x) {
				segpainter.last_x =
					this._scale_x *
						(segment.getX(from_index) - this._subtract_x) +
					this._canvas_padding;
				segpainter.last_y =
					this._scale_y *
						(segment.getY(from_index) - this._subtract_y) +
					this._canvas_padding;
			}

			//Interpolated x/y, the point between the place we're leaving and the place we're going
			var interpolated_x =
				(1 - interp_percentage) * segment.getX(from_index) +
				interp_percentage * segment.getX(to_index);
			var interpolated_y =
				(1 - interp_percentage) * segment.getY(from_index) +
				interp_percentage * segment.getY(to_index);
			var interpolated_p =
				(1 - interp_percentage) * segment.getPressure(from_index) +
				interp_percentage * segment.getPressure(to_index);

			//Calculate new X and Y position
			var new_x = (this._nib_x =
				this._scale_x * (interpolated_x - this._subtract_x) +
				this._canvas_padding);
			var new_y = (this._nib_y =
				this._scale_y * (interpolated_y - this._subtract_y) +
				this._canvas_padding);
			var significant_move =
				to_index === 0 || //Start move is important (going from 0 to 0)
				interp_percentage >= 1 || //Final move is important
				Math.abs(new_x - segpainter.last_x) >= 1 || //1+ pixels horizontal is important
				Math.abs(new_y - segpainter.last_y) >= 1; //1+ pixels vertical is important

			//Display nib full size
			this._nib_display = 1;

			//Not enough to draw?
			if (interp_percentage <= 0 || !significant_move) {
				//Don't paint anything, but update how much we've painted, and finish the line if necessary
				if (path_started) {
					this._ctx.stroke();
					path_started = false;
				}
				this._paint_position = arg_position;
			} else {
				//Point zero?
				if (!segpainter.labelled && this._labels_on && arg_primary) {
					segpainter.labelled = true;
					this._ctx.save();
					this._ctx.strokeStyle = '#ffffff';
					this._ctx.fillColor = '#000000';
					this._ctx.lineWidth = 3;
					this._ctx.font = '8px';
					this._ctx.strokeText(
						dctStrings.stroke_short_names(segment.getStrokeType()),
						new_x,
						new_y
					);
					this._ctx.fillText(
						dctStrings.stroke_short_names(segment.getStrokeType()),
						new_x,
						new_y
					);
					this._ctx.restore();
				}

				//Draw it
				if (!path_started) {
					//Confidence colorization
					arg_styler.call(this, segment, segpainter);

					//Start
					path_started = true;
					this._ctx.beginPath();
					this._ctx.moveTo(segpainter.last_x, segpainter.last_y);
				}
				//Draw the line
				this._ctx.lineTo(new_x, new_y);
				segpainter.last_x = new_x;
				segpainter.last_y = new_y;
				if (interp_percentage < 1)
					segpainter.interpolation = interp_percentage;
				else segpainter.point_index++;
			}
		}
	}

	//Wrap up if necessary
	if (segpainter && path_started) {
		this._ctx.stroke();
		path_started = false;
	}

	//Very last one?
	if (this._segpainter_index === this._segpainters.length - 1) {
		var last_painter = this._segpainters[this._segpainters.length - 1];
	}

	//Correct nib position for beginning/end
	this._nib_display =
		arg_position === 0 || arg_position === this._end_position
			? 0
			: this._nib_display;

	//Move nib, too
	if (arg_primary) this.moveNib();
};

//Move the nib
dctSketchPlayer.Drawstack.prototype.moveNib = function () {
	//Show?
	var x = this._nib_x === null ? 0 : this._canvas_left + this._nib_x;
	var y = this._nib_y === null ? 0 : this._canvas_top + this._nib_y;

	//Move the nib (scale to 0 to hide)
	dctStyle(
		this._dom_nib,
		'transform',
		'translate3d(' +
			x +
			'px,' +
			y +
			'px,0px) scale(' +
			this._nib_display +
			',' +
			this._nib_display +
			')'
	);
};

//################################################################################
//Split metadata control
//################################################################################
dctSketchPlayer.SplitData = dctComponents.makeComponent('dct_player_splitinfo');
dctSketchPlayer.SplitData.prototype.init = function () {
	//Player events
	this._player = dctComponents.findUpwards(this.element, dctSketchPlayer);
	this._player.addEventListener('scissor', this.eventScissor.bind(this));

	//State
	this._split_position = this.element.getAttribute('data-splitinfo-position');
	this._is_before = this._split_position === 'before';
	this._is_after = !this._is_before;

	//Initialize
	this.eventScissor(this._player.getScissorIndex());
};

//On scissor
dctSketchPlayer.SplitData.prototype.eventScissor = function (
	arg_scissor_index
) {
	//Safety check - should only apply in single-stroke tests.
	if (!arg_scissor_index) return;

	//Get segments
	var segments = this._player.getSegments();

	//Math!
	var strokes_total = (segments.length + 1) / 2; //Segments = N strokes + (N-1) pauses, so stroke count = (segments+1)/2
	var strokes_before = (arg_scissor_index + 1) / 2; //Same math applies before.
	var strokes_after = strokes_total - strokes_before; //Simple math.
	var strokes_this = this._is_before ? strokes_before : strokes_after;

	//What time?
	var start_time = (
		this._is_before ? segments[0] : segments[arg_scissor_index + 1]
	).getStartTime_Section();
	var end_time = (
		this._is_before
			? segments[arg_scissor_index - 1]
			: segments[segments.length - 1]
	).getStopTime_Section();
	var duration = end_time - start_time;

	//Set content
	this.element.textContent = dctStrings.split_test_info(
		this._is_before,
		strokes_this,
		duration
	);
};

//################################################################################
//Ugly hacks
//################################################################################
window.dctFixElectronCanvases = function () {
	/*
  For some reason, Electron produces terrible quality images for Canvas tags, unless for some reason, you get the data URL.
  This seems to be true on Linux run under XVFB, but not necessarily on Windows.

  Why this fixes it, I have no idea.  I spent a while digging into it - forcing delays, forcing reflows, forcing repaints,
  etc, and nothing else seems to do the trick.  Such an odd quirk, but a simple enough one to work around.

  True on 2016-10-26, versions of Nightmare 2.8.1, XVFB 0.2.3, Electron 2.15.9.  Check if this is true again in the future.
  */
	var canvases = document.querySelectorAll('canvas');
	for (var i = 0; i < canvases.length; i++) canvases[i].toDataURL(); //Generate a data URL, immediately discard it.
};

export { dctSketchPlayer };
