export enum ArtifactFormats {
	PNG = 'PNG',
	MP4 = 'MP4',
	WAV = 'WAV',
	JSON = 'JSON',
	WEBM = 'WEBM',
}

export interface GetReportArtifactProps {
	segmentResultId: string;
	fileName: string;
	fileType: ArtifactFormats;
}

export type GetReportArtifactArguments = Omit<
	GetReportArtifactProps,
	'fileType'
>;
