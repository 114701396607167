import styled, { useTheme } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { icons } from '../../../enums/icons';
import { Icon, P1 } from '../../shared/designSystem';

type ChartConcernsProps = {
	concerns: string[];
};

const ChartConcerns = ({ concerns }: ChartConcernsProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<StyledWrapper>
			<StyledIcon>
				<Icon
					icon={icons.AlertSolid}
					width={24}
					height={24}
					color={theme.color.graphOrange}
					title={t`web.shared.iconAltText.warning`}
				/>
			</StyledIcon>
			<StyledP1>
				<Trans
					i18nKey={`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.lowSubscaleScores`}
				/>
				{concerns.map((concern, idx) => {
					return idx === concerns.length - 1
						? ` ${t(concern)}.`
						: ` ${t(concern)},`;
				})}
			</StyledP1>
		</StyledWrapper>
	);
};

export { ChartConcerns };

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	align-items: center;
	margin: ${spacing.sm} 0 0 0;
`
);

const StyledIcon = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.xs} ${spacing.sm} 0 0;
	color: white
`
);

const StyledP1 = styled(P1)(
	({ theme: { weight, color } }) => `
	font-weight: ${weight.medium};
	color: ${color.graphTextAlert};
	margin: 0;
	padding: 0;
`
);
