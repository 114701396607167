import styled, { css } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { Icon } from '../../../shared/designSystem';

type Props = {
	tooltipText: string;
};

const InfoTooltip = (props: Props) => {
	return (
		<LinusTooltip
			trigger={'hover'}
			data-testid={'tooltip'}
			overlay={<StyledTooltip>{props?.tooltipText}</StyledTooltip>}
			tooltipIcon={
				<Icon
					icon={icons.InfoOutlined}
					iconWidth={24}
					iconHeight={24}
				/>
			}
		/>
	);
};

export { InfoTooltip };

const StyledTooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);
