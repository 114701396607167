// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Home = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Home-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Home'}</title>

			<path
				d='M18.0005 15.5454C18.0005 15.8469 17.713 16.0909 17.3577 16.0909C17.0023 16.0909 16.7147 15.8467 16.7147 15.5452V7.59552L9.00018 1.30134L2.05294 6.96905C1.79679 7.17786 1.38998 7.17148 1.14388 6.95414C0.897782 6.73679 0.905313 6.39162 1.16146 6.18281L8.55454 0.151816C8.80439 -0.0506054 9.1974 -0.0506054 9.44601 0.151816L17.8034 6.9702C17.929 7.07354 18.0005 7.21523 18.0005 7.36332V15.5454Z'
				fill='current'
			/>
			<path
				d='M0 17.4545V8.72699C0 8.42549 0.287536 8.18152 0.642876 8.18152C0.998216 8.18152 1.28575 8.42548 1.28575 8.72699V16.909H6.10732V12.5453C6.10732 11.1923 7.40564 10.0907 9.00027 10.0907C10.5949 10.0907 11.8932 11.1923 11.8932 12.5453V16.909H15.1076C15.4629 16.909 15.7505 17.153 15.7505 17.4545C15.7505 17.756 15.4629 18 15.1076 18H11.2503C10.895 18 10.6075 17.756 10.6075 17.4545V12.5453C10.6075 11.7931 9.88673 11.1816 9.00027 11.1816C8.1138 11.1816 7.39308 11.7931 7.39308 12.5453V17.4545C7.39308 17.756 7.10555 18 6.7502 18H0.642876C0.287536 18 0 17.756 0 17.4545Z'
				fill='current'
			/>
		</svg>
	);
};
