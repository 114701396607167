// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Configure = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Configure-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Configure'}</title>
			<path
				d='M9.93699 7.00002C9.97812 6.84021 10 6.67267 10 6.50002C10 6.32737 9.97812 6.15983 9.93699 6.00002H20C20.2761 6.00002 20.5 6.22387 20.5 6.50002C20.5 6.77616 20.2761 7.00002 20 7.00002H9.93699Z'
				fill='#087DAE'
			/>
			<path
				d='M6.06301 7.00002H4C3.72386 7.00002 3.5 6.77616 3.5 6.50002C3.5 6.22387 3.72386 6.00002 4 6.00002H6.06301C6.02188 6.15983 6 6.32737 6 6.50002C6 6.67267 6.02188 6.84021 6.06301 7.00002Z'
				fill='#087DAE'
			/>
			<path
				d='M10 6.50002C10 7.60458 9.10457 8.50002 8 8.50002C6.89543 8.50002 6 7.60458 6 6.50002C6 5.39545 6.89543 4.50002 8 4.50002C9.10457 4.50002 10 5.39545 10 6.50002Z'
				fill='#087DAE'
			/>
			<path
				d='M9.93699 18C9.97812 17.8402 10 17.6727 10 17.5C10 17.3274 9.97812 17.1598 9.93699 17H20C20.2761 17 20.5 17.2239 20.5 17.5C20.5 17.7762 20.2761 18 20 18H9.93699Z'
				fill='#087DAE'
			/>
			<path
				d='M6.06301 18H4C3.72386 18 3.5 17.7762 3.5 17.5C3.5 17.2239 3.72386 17 4 17H6.06301C6.02188 17.1598 6 17.3274 6 17.5C6 17.6727 6.02188 17.8402 6.06301 18Z'
				fill='#087DAE'
			/>
			<path
				d='M10 17.5C10 18.6046 9.10457 19.5 8 19.5C6.89543 19.5 6 18.6046 6 17.5C6 16.3954 6.89543 15.5 8 15.5C9.10457 15.5 10 16.3954 10 17.5Z'
				fill='#087DAE'
			/>
			<path
				d='M17.937 12.5C17.9781 12.3402 18 12.1727 18 12C18 11.8274 17.9781 11.6598 17.937 11.5H20C20.2761 11.5 20.5 11.7239 20.5 12C20.5 12.2762 20.2761 12.5 20 12.5H17.937Z'
				fill='#087DAE'
			/>
			<path
				d='M14.063 12.5H4C3.72386 12.5 3.5 12.2762 3.5 12C3.5 11.7239 3.72386 11.5 4 11.5H14.063C14.0219 11.6598 14 11.8274 14 12C14 12.1727 14.0219 12.3402 14.063 12.5Z'
				fill='#087DAE'
			/>
			<path
				d='M18 12C18 13.1046 17.1046 14 16 14C14.8954 14 14 13.1046 14 12C14 10.8954 14.8954 10 16 10C17.1046 10 18 10.8954 18 12Z'
				fill='#087DAE'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.32291 5.00002C9.61811 5.26057 9.83581 5.6069 9.93699 6.00002H20C20.2761 6.00002 20.5 6.22387 20.5 6.50002C20.5 6.77616 20.2761 7.00002 20 7.00002H9.93699C9.83581 7.39313 9.61811 7.73946 9.32291 8.00002C8.97035 8.3112 8.50722 8.50002 8 8.50002C7.49278 8.50002 7.02965 8.3112 6.67709 8.00002C6.38188 7.73946 6.16419 7.39313 6.06301 7.00002M6.06301 6.00002H4C3.72386 6.00002 3.5 6.22387 3.5 6.50002C3.5 6.77616 3.72386 7.00002 4 7.00002H5.04148M6.06301 6.00002C6.16419 5.6069 6.38188 5.26057 6.67709 5.00002C7.02965 4.68883 7.49278 4.50002 8 4.50002C8.50722 4.50002 8.97035 4.68883 9.32291 5.00002M9.32291 16C9.61811 16.2606 9.83581 16.6069 9.93699 17H20C20.2761 17 20.5 17.2239 20.5 17.5C20.5 17.7762 20.2761 18 20 18H9.93699C9.83581 18.3931 9.61811 18.7395 9.32291 19C8.97035 19.3112 8.50722 19.5 8 19.5C7.49278 19.5 7.02965 19.3112 6.67709 19C6.38188 18.7395 6.16419 18.3931 6.06301 18H4C3.72386 18 3.5 17.7762 3.5 17.5C3.5 17.2239 3.72386 17 4 17H6.06301C6.16419 16.6069 6.38188 16.2606 6.67709 16C7.02965 15.6888 7.49278 15.5 8 15.5C8.50722 15.5 8.97035 15.6888 9.32291 16ZM17.3229 10.5C17.6181 10.7606 17.8358 11.1069 17.937 11.5H20C20.2761 11.5 20.5 11.7239 20.5 12C20.5 12.2762 20.2761 12.5 20 12.5H17.937C17.8358 12.8931 17.6181 13.2395 17.3229 13.5C16.9703 13.8112 16.5072 14 16 14C15.4928 14 15.0297 13.8112 14.6771 13.5C14.3819 13.2395 14.1642 12.8931 14.063 12.5H4C3.72386 12.5 3.5 12.2762 3.5 12C3.5 11.7239 3.72386 11.5 4 11.5H14.063C14.1642 11.1069 14.3819 10.7606 14.6771 10.5C15.0297 10.1888 15.4928 10 16 10C16.5072 10 16.9703 10.1888 17.3229 10.5ZM8 7.50002C8.55228 7.50002 9 7.0523 9 6.50002C9 5.94773 8.55228 5.50002 8 5.50002C7.44772 5.50002 7 5.94773 7 6.50002C7 7.0523 7.44772 7.50002 8 7.50002ZM8 18.5C8.55228 18.5 9 18.0523 9 17.5C9 16.9477 8.55228 16.5 8 16.5C7.44772 16.5 7 16.9477 7 17.5C7 18.0523 7.44772 18.5 8 18.5ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z'
				fill='#087DAE'
			/>
		</svg>
	);
};
