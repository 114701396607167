import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '@mantine/core';
import styled from 'styled-components';

import { Typography, theme } from '@lh/eng-web-mosaic-common';
import { Answer } from '../../../../../common/components/table/Answer';
import { ConfidenceChange } from '../../../../../../Epsom/common/helpers/confidenceChangeUtils';
import { P1 } from '../../../../../../../../components/designSystem/Text';
import {
	AlertShieldOutline,
	AlertShieldSolid,
} from '../../../../../../../../components/shared/designSystem/Icons';

export type AnswerRowProps = {
	itemNumber: number;
	participantAnswer: string;
	answer: 'INCREASE' | 'DECREASE' | 'NONE';
	rate: number;
};

const AnswerRow = (props: AnswerRowProps) => {
	const { t } = useTranslation();
	const rowBackgroundColor = useMemo(
		() =>
			props?.itemNumber % 2 !== 0
				? theme?.colors?.gray_90
				: 'transparent',
		[props?.itemNumber]
	);

	// Freetext length should be increased to 80 characters (from 50) for both baseline and longitudinal ePSOM
	const participantAnswerTrimmed = useMemo(() => {
		const totalChars = props?.participantAnswer?.length;
		if (totalChars > 80) {
			return props?.participantAnswer?.substring(0, 80)?.concat('...');
		} else {
			return props?.participantAnswer;
		}
	}, [props?.participantAnswer]);

	return (
		<div key={props?.itemNumber}>
			<Flex
				key={props?.itemNumber}
				style={{
					backgroundColor: rowBackgroundColor,
					padding: theme.spacing.md,
				}}
				direction={'row'}
				align={'flex-start'}>
				<SingleColSpanRow style={{ paddingTop: theme?.spacing?.xxs }}>
					{
						<Typography.P1>
							<span>
								<b>{`Q.${props?.itemNumber}:`}</b>
								&nbsp;
								{participantAnswerTrimmed}
							</span>
						</Typography.P1>
					}
				</SingleColSpanRow>
				<SingleColSpanRow>
					<Flex direction={'column'} style={{ width: '85%' }}>
						{Object.keys(ConfidenceChange).map(
							(scoreCategory: string) => {
								const ratingDisplay = t(
									`web.report.longEpsom.changeInConfidence.${scoreCategory.toLowerCase()}Confidence`
								);

								return (
									<SingleColSpanRow
										key={`confidence-change-selection-${scoreCategory}`}>
										<Answer
											labelText={ratingDisplay}
											isSelected={
												props.answer === scoreCategory
											}
										/>
									</SingleColSpanRow>
								);
							}
						)}
					</Flex>
				</SingleColSpanRow>
				<SingleColSpanRow>
					<Flex direction={'column'} style={{ width: '100%' }}>
						{props.answer === 'INCREASE' ? (
							Object.values(ConfidenceChange.INCREASE).map(
								(v, index) => {
									const isSelected = props.rate === index;
									return (
										<Flex
											direction={'row'}
											key={`confidence-change-increase-${index}`}>
											<Answer
												width='85%'
												labelText={t(
													`web.report.longEpsom.changeInConfidence.increase.${v.toLowerCase()}`
												)}
												isSelected={isSelected}
											/>
											{props.rate > 1 && isSelected && (
												<CenteredDiv>
													<AlertShieldOutline
														height={12}
														width={12}
														color={
															theme.color
																.alertSuccessText
														}
													/>
												</CenteredDiv>
											)}
										</Flex>
									);
								}
							)
						) : props.answer === 'DECREASE' ? (
							Object.values(ConfidenceChange.DECREASE).map(
								(v, index) => {
									const isSelected = props.rate === index;
									return (
										<Flex
											direction={'row'}
											key={`confidence-change-decrease-${index}`}>
											<Answer
												width='85%'
												labelText={t(
													`web.report.longEpsom.changeInConfidence.decrease.${v.toLowerCase()}`
												)}
												isSelected={isSelected}
											/>
											{props.rate > 1 && isSelected && (
												<CenteredDiv>
													<AlertShieldSolid
														height={12}
														width={12}
														color={
															theme.color
																.alertError
														}
													/>
												</CenteredDiv>
											)}
										</Flex>
									);
								}
							)
						) : (
							<StyledNoChange>
								{t(
									'web.report.longEpsom.changeInConfidence.noChange'
								)}
							</StyledNoChange>
						)}
					</Flex>
				</SingleColSpanRow>
			</Flex>
		</div>
	);
};

export { AnswerRow };

const SingleColSpanRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: flex-start;
`;

const CenteredDiv = styled.div(
	({ theme: { spacing } }) => `
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: ${spacing.sm};
  `
);

const StyledNoChange = styled(P1)(
	({ theme: { spacing, color } }) => `
  color: ${color.formTextDisabled};
  padding: ${spacing.md};
`
);
