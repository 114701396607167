import { Auth } from '@lh/eng-shared-auth';
import { createContext, useContext } from 'react';

export const AuthServiceContext = createContext<Auth | null>(null);

export function useAuthServiceContext() {
	const context = useContext(AuthServiceContext);

	if (context === null) {
		throw new Error(
			'AuthServiceContext must be used within <AuthServiceProvider />'
		);
	}

	return context;
}
