import { useEffect } from 'react';
import { useZendeskRedirectUrlMutation } from '../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import { getBrowserLanguage } from '../../utils/getLanguage';

enum ZendeskArticleKeys {
	instructionsForUse = '4414840896539',
	researchIFU = '7414620361627-Research-Platform-IFU',
}

type ZendeskArticleProps = {
	article: ZendeskArticleKeys;
};

const ZendeskArticle = ({ article }: ZendeskArticleProps): JSX.Element => {
	const navigate = useNavigate();
	const [zendeskRedirectURLMutation, { data }] =
		useZendeskRedirectUrlMutation({
			variables: {
				zendeskRedirectUrlInput: {
					locale: getBrowserLanguage(),
					article,
				},
			},
		});

	useEffect(() => {
		zendeskRedirectURLMutation();
	}, [zendeskRedirectURLMutation]);
	useEffect(() => {
		if (data?.zendeskRedirectUrl) {
			if (data.zendeskRedirectUrl.redirectUrl) {
				window.open(data.zendeskRedirectUrl.redirectUrl);
			}
			navigate('/regulatory-label'); //Remains on the regulatory label page
		}
	}, [data, navigate]);
	return <></>; //TODO: we should probably have a loading spinner here or something
};
export { ZendeskArticle, ZendeskArticleKeys };
