import { ParticipantApi, SubjectServiceConfiguration } from 'api/participant';

import { useAuthStore } from 'store';
import { config } from '../config';

export async function getSubjectService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new ParticipantApi(
			new SubjectServiceConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
