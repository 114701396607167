import { Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { LinusModal } from '../../../../../components/shared/LinusModal';
import { CloseButton } from '../../../../designSystem/Button/CloseButton';

import {
	AmnesticMildMeasures,
	AmnesticModerateMeasures,
	DysexecutiveMeasures,
	MixedMildMeasures,
	NonAmnesticMeasures,
	NormalMeasures,
	SubtleMeasures,
} from './components';
import { Indicative } from './Indicative';

import {
	ClassificationContainer,
	ClassificationContent,
	ClassificationHeader,
	Container,
	MeasuresContainer,
	MeasuresContent,
	MeasuresHeader,
} from './ScoresClassification.style';
import { INDICATIVES } from './ScoreClassification.utils';

export interface ScoresClassificationProps {
	isOpen: boolean;
	onClose: () => void;
}

export function ScoresClassification({
	isOpen,
	onClose,
}: Readonly<ScoresClassificationProps>) {
	const { t } = useTranslation();

	if (!isOpen) {
		return null;
	}

	return (
		<LinusModal
			onClose={onClose}
			title={t('web.report.cohort.classification.modalTitle')}>
			<Container>
				<MeasuresContainer>
					<MeasuresHeader>
						<Typography.P1 weight='500'>
							{t('web.report.cohort.breakdown.episodicMemory')}
						</Typography.P1>
						<Typography.P1 weight='500'>
							{t('web.report.cohort.breakdown.executiveControl')}
						</Typography.P1>
						<Typography.P1 weight='500'>
							{t(
								'web.report.cohort.breakdown.visuoconstructional'
							)}
						</Typography.P1>
					</MeasuresHeader>
					<MeasuresContent>
						<NormalMeasures />
						<SubtleMeasures />
						<AmnesticMildMeasures />
						<DysexecutiveMeasures />
						<MixedMildMeasures />
						<AmnesticModerateMeasures />
						<NonAmnesticMeasures />
					</MeasuresContent>
				</MeasuresContainer>
				<ClassificationContainer>
					<ClassificationHeader>
						<Typography.P1 weight='500'>
							{t(
								'web.report.cohort.classification.classificationHeader'
							)}
						</Typography.P1>
					</ClassificationHeader>
					<ClassificationContent>
						{INDICATIVES.map((item, index) => {
							return (
								<Indicative
									key={index}
									indicative={item.indicative}
									ellipsisColor={item.ellipsisColor}
									height={item.height}
									highlightColor={item.highlightColor}
									indicativeTextColor={item.indicative}
									isLastElement={
										index === INDICATIVES.length - 1
									}
								/>
							);
						})}
					</ClassificationContent>
				</ClassificationContainer>
			</Container>
			<CloseButton text={t`web.shared.close`} onClick={onClose} />
		</LinusModal>
	);
}
