import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Space } from '@mantine/core';
import styled from 'styled-components';

import { Typography, theme } from '@lh/eng-web-mosaic-common';
import {
	AlertShieldSolid,
	TraditionalArrowDown,
	TraditionalArrowUp,
} from '../../../../../../shared/designSystem/Icons';
import { Answer } from '../../../../common/components/table/Answer';
import {
	ConfidenceScoreRange,
	ConfidenceScoreRangeKeys,
} from '../../../../../Epsom/common/constants/confidenceScore';
import {
	getConfidenceScoreRatingTextTransKey,
	mapScoreToConfidenceRating,
} from '../../../../../Epsom/common/helpers/confidenceScoreUtils';

export type AnswerRowProps = {
	itemNumber: number;
	participantAnswer: string;
	rating: {
		isLowConfidenceIndicator?: boolean;
		changeFromPrevious: number;
		arrowIconColor?: 'green' | 'red' | undefined;
	};
	score: {
		previous?: number;
		current: number;
	};
};

const AnswerRow = (props: AnswerRowProps) => {
	const { t } = useTranslation();
	const currentScoreRange = useMemo(
		() => mapScoreToConfidenceRating(props?.score?.current),
		[props?.score]
	);

	const previousScoreRange = useMemo(
		() => mapScoreToConfidenceRating(props?.score?.previous ?? 0),
		[props?.score]
	);

	const rowBackgroundColor = useMemo(
		() =>
			props?.itemNumber % 2 !== 0
				? theme?.colors?.gray_90
				: 'transparent',
		[props?.itemNumber]
	);

	const alertIconColor = theme.color.alertError;

	const renderArrowIcon = useCallback(() => {
		const arrowIconColor = props?.rating?.arrowIconColor;
		if (!arrowIconColor) return null;
		switch (arrowIconColor) {
			case 'green':
				return (
					<TraditionalArrowUp
						height={12}
						width={12}
						color={arrowIconColor}
					/>
				);
			case 'red':
				return (
					<TraditionalArrowDown
						height={12}
						width={12}
						color={arrowIconColor}
					/>
				);
			default:
				return null;
		}
	}, [props?.rating?.arrowIconColor]);

	// Freetext length should be increased to 80 characters (from 50) for both baseline and longitudinal ePSOM
	const participantAnswerTrimmed = useMemo(() => {
		const totalChars = props?.participantAnswer?.length;
		if (totalChars > 80) {
			return props?.participantAnswer?.substring(0, 80)?.concat('...');
		} else {
			return props?.participantAnswer;
		}
	}, [props?.participantAnswer]);

	return (
		<div key={props?.itemNumber}>
			{ConfidenceScoreRangeKeys.map((scoreRange: string, index) => {
				const ratingDisplayKey = t(
					getConfidenceScoreRatingTextTransKey(
						scoreRange as ConfidenceScoreRange
					)
				);
				const wasPreviouslySelected =
					previousScoreRange && previousScoreRange === scoreRange;
				const isSelectedScoreRange = currentScoreRange === scoreRange;

				return (
					<Flex
						key={scoreRange}
						style={{ backgroundColor: rowBackgroundColor }}
						direction={'row'}>
						<SingleColSpanRow
							style={{ paddingTop: theme?.spacing?.xxs }}>
							{index === 0 ? (
								<Typography.P1>
									<span>
										<b>{`Q.${props?.itemNumber}:`}</b>
										&nbsp;
										{participantAnswerTrimmed}
									</span>
								</Typography.P1>
							) : (
								<p />
							)}
						</SingleColSpanRow>
						<DoubleColSpanRow>
							<SingleColSpanRow>
								<Answer
									labelText={ratingDisplayKey}
									isSelected={isSelectedScoreRange}
									isEmphasized={
										wasPreviouslySelected &&
										!isSelectedScoreRange
									}
								/>
							</SingleColSpanRow>
							<DoubleColSpanRow>
								{/* Previously selected answer indicator */}
								{wasPreviouslySelected && (
									<Flex direction={'row'}>
										<Space w='sm' />
										<Typography.P2
											color={
												theme?.color?.bodyTextSecondary
											}
											style={{ fontStyle: 'italic' }}>
											{t`web.report.longEpsom.confidenceScore.rowText.baselineAnswer`}
										</Typography.P2>
									</Flex>
								)}
								{/* Badge to show */}
								{isSelectedScoreRange &&
									props?.rating?.arrowIconColor && (
										<>
											<Space w={'sm'} />
											{renderArrowIcon()}
										</>
									)}
								{/* No Rating change from previous */}
								{isSelectedScoreRange &&
									props?.rating?.changeFromPrevious === 0 && (
										<>
											<Space w={'sm'} />
											<Typography.P2>
												{t`web.report.longEpsom.confidenceScore.rowText.noChangeInScore`}
											</Typography.P2>
										</>
									)}
								{/* Rating change from previous */}
								{isSelectedScoreRange &&
									props?.rating?.changeFromPrevious !== 0 && (
										<Typography.P2>
											<span
												style={{
													color: `${props?.rating?.arrowIconColor}`,
												}}>
												{`${props?.rating?.changeFromPrevious}`}
											</span>
											&nbsp;
											{t`web.report.longEpsom.confidenceScore.rowText.changeSuffix`}
										</Typography.P2>
									)}
								{/* Low Confidence Indicator */}
								{isSelectedScoreRange &&
									props?.rating?.isLowConfidenceIndicator && (
										<>
											<Space w={'sm'} />
											<AlertShieldSolid
												height={12}
												width={12}
												color={alertIconColor}
											/>
										</>
									)}
							</DoubleColSpanRow>
						</DoubleColSpanRow>
						<SingleColSpanRow>
							{isSelectedScoreRange && (
								<Typography.P1
									data-testid='score-value'
									weight='600'
									style={{
										textAlign: 'end',
										width: '95%',
									}}>
									{`+${props?.score?.current}`}
								</Typography.P1>
							)}
						</SingleColSpanRow>
					</Flex>
				);
			})}
		</div>
	);
};

export { AnswerRow };

const SingleColSpanRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`;

const DoubleColSpanRow = styled.div`
	display: flex;
	flex-direction: row;
	flex: 2;
	align-items: center;
`;
