import { createContext } from 'react';
import { Enumeration, EnumItem, VLEnum } from 'enums/enumeration';
import {
	FeatureType,
	OrganizationStatus,
	OrganizationType,
	UserStatus,
	ValueListType,
} from 'generated/graphql';
import { CurrentUser } from 'types';

export type UserContextProps = {
	currentUser: CurrentUser;
	setCurrentUser: (user: CurrentUser, isImpersonation?: boolean) => void;
	updateCurrentUser: () => void;
	refetchCurrentUser: () => void;
	onLogin: (user: CurrentUser) => void;
	logout: () => void;
	clearImpersonation: () => void;
	isLoggedIn: boolean;
};

export type ValueListEntries = {
	[key in ValueListType]?: Enumeration<VLEnum>;
};

export type OrganizationSettings = {
	organizationDateFormat: string;
	organizationDefaultUserLocale: EnumItem;
	organizationValueLists: ValueListEntries;
	organizationParticipantLanguages: Enumeration<VLEnum>;
	organizationId: string;
	organizationType: OrganizationType;
	organizationStatus: OrganizationStatus;
	organizationName: string;
	organizationFeatures: FeatureType[];
	organizationDefaultTimezone: string;
};

export const nulloUser: CurrentUser = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	avatarUrl: '',
	role: '',
	roleId: '',
	operations: [],
	organizationId: '',
	organizationType: OrganizationType.Linus,
	organizationStatus: OrganizationStatus.Live,
	organizationName: '',
	organizationDateFormat: '',
	organizationDefaultTimezone: 'US/Eastern',
	organizationValueLists: [],
	organizationParticipantLanguages: [],
	organizationFeatures: [],
	suffix: [],
	userStatus: UserStatus.Deactivated,
};

export const UserContext = createContext<UserContextProps>({
	currentUser: nulloUser,
	onLogin: () => undefined,
	logout: () => undefined,
	setCurrentUser: () => undefined,
	updateCurrentUser: () => undefined,
	clearImpersonation: () => undefined,
	refetchCurrentUser: () => undefined,
	isLoggedIn: false,
});
