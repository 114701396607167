// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const BeMindfulLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='BeMindfulLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'BeMindfulLHQ'}</title>
			<path
				d='M10.662 15.2778C10.662 15.278 10.6621 15.2786 10.3333 15.3334L10.662 15.2778Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 20C16.3137 20 19 17.3137 19 14C19 10.6863 16.3137 8 13 8C9.68629 8 7 10.6863 7 14C7 17.3137 9.68629 20 13 20ZM12 12.6667C12 13.0349 11.7016 13.3333 11.3334 13.3333C10.9652 13.3333 10.6667 13.0349 10.6667 12.6667C10.6667 12.2985 10.9652 12 11.3334 12C11.7016 12 12 12.2985 12 12.6667ZM13.9999 12.6667C13.9999 13.0349 14.2984 13.3333 14.6666 13.3333C15.0348 13.3333 15.3333 13.0349 15.3333 12.6667C15.3333 12.2985 15.0348 12 14.6666 12C14.2984 12 13.9999 12.2985 13.9999 12.6667ZM10.662 15.2778C10.6313 15.0967 10.4598 14.9744 10.2785 15.0046C10.0969 15.0349 9.97421 15.2066 10.0045 15.3882L10.3333 15.3334C10.0045 15.3882 10.0044 15.388 10.0045 15.3882L10.0048 15.39L10.0052 15.3926L10.0066 15.3999L10.0111 15.423C10.015 15.4419 10.0207 15.468 10.0288 15.5002C10.0448 15.5644 10.0702 15.6535 10.1083 15.7591C10.1843 15.9696 10.313 16.2508 10.5249 16.5334C10.9577 17.1103 11.7199 17.6666 12.9999 17.6666C14.2799 17.6666 15.0422 17.1103 15.4749 16.5334C15.6869 16.2508 15.8156 15.9696 15.8916 15.7591C15.9297 15.6535 15.9551 15.5644 15.9711 15.5002C15.9792 15.468 15.9849 15.4419 15.9888 15.423L15.9933 15.3999L15.9947 15.3926L15.9951 15.39L15.9953 15.3886C15.9954 15.3884 15.9954 15.3882 15.6666 15.3334L15.9953 15.3886C16.0256 15.207 15.903 15.0349 15.7214 15.0046C15.5403 14.9744 15.369 15.0964 15.338 15.2772L15.3358 15.2887C15.3337 15.2991 15.33 15.316 15.3244 15.3385C15.3131 15.3836 15.2941 15.4508 15.2646 15.5327C15.2051 15.6972 15.1047 15.9159 14.9416 16.1333C14.6243 16.5564 14.0533 17 12.9999 17C11.9466 17 11.3755 16.5564 11.0583 16.1333C10.8952 15.9159 10.7948 15.6972 10.7353 15.5327C10.7058 15.4508 10.6868 15.3836 10.6755 15.3385C10.6699 15.316 10.6662 15.2991 10.6641 15.2887L10.662 15.2778Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.1132 14C20.1132 17.4256 17.6526 20.2765 14.4023 20.8816C15.4219 22.7401 17.397 24 19.6664 24C22.9801 24 25.6664 21.3137 25.6664 18C25.6664 14.739 23.0648 12.0856 19.824 12.002C20.0122 12.6352 20.1132 13.3058 20.1132 14ZM20.8943 15.983C20.7197 15.9248 20.6253 15.736 20.6835 15.5614C20.7417 15.3867 20.9305 15.2923 21.1051 15.3505L23.1051 16.0172C23.2798 16.0754 23.3742 16.2642 23.316 16.4388C23.2577 16.6135 23.069 16.7079 22.8943 16.6497L20.8943 15.983ZM21.6451 19.8831C21.7098 20.0554 21.6224 20.2476 21.4501 20.3122L18.7834 21.3122C18.611 21.3768 18.4189 21.2895 18.3543 21.1171C18.2896 20.9448 18.3769 20.7526 18.5493 20.688L21.216 19.688C21.3884 19.6233 21.5805 19.7107 21.6451 19.8831ZM21.3331 18.0001C20.9649 18.0001 20.6664 17.7016 20.6664 17.3334C20.6664 16.9652 20.9649 16.6668 21.3331 16.6668C21.7013 16.6668 21.9998 16.9652 21.9998 17.3334C21.9998 17.7016 21.7013 18.0001 21.3331 18.0001Z'
				fill={color}
			/>
		</svg>
	);
};
