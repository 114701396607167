/* eslint-disable */
export const dctStrings = {
	report_missing_sketch() {
		return 'No Sketch';
	},
	time_mm_ss(time) {
		const m = Math.floor(time / 60000);
		const s = Math.floor(time % 60000) / 1000;
		const x = (s < 10 ? 0 : '') + s.toFixed(4);
		return m + ':' + x.substr(0, 2);
	},
};
