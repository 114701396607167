import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useRestServices } from '../../components/shared/hooks/useRestServices';
import { UserContext } from '../../context/UserContext';
import { ERROR, DEBUG } from '../../logging/linusLogger';
import {
	Battery,
	DeepBattery,
} from '@lh/eng-platform-battery-service-rest-client';
import { User } from '@lh/eng-platform-organization-service-rest-client';
import { useGetOrgUsers } from 'api/organization';
import { useAuthStore } from 'store';
import { isEmpty } from 'lodash';
import { useOrganizationStore } from '../../store/useOrganizationStore';

interface GlobalDataProviderProps {
	children: ReactNode;
}

interface GlobalData {
	batteries: Battery[];
	orgBatteries: DeepBattery[];
	orgUsers: User[];
}

export const GlobalDataContext = createContext<GlobalData>({
	batteries: [],
	orgBatteries: [],
	orgUsers: [],
});

/***
 * GlobalDataProvider is meant to fetch reference data and provide it further down the tree.
 * @param children
 * @constructor
 * @returns GlobalData
 */
export const GlobalDataProvider = ({ children }: GlobalDataProviderProps) => {
	const { createBatteryService } = useRestServices();
	const [globalData, setGlobalData] = useState<GlobalData>({
		batteries: [],
		orgBatteries: [],
		orgUsers: [],
	});
	const { isLoggedIn, currentUser } = useContext(UserContext);
	const { bearerToken } = useAuthStore();
	const { currentOrganizationId } = useOrganizationStore();

	const { data: UsersPage } = useGetOrgUsers({
		isLoggedIn,
		organizationId: currentUser.organizationId,
	});
	useEffect(() => {
		const loadBatteries = async () => {
			DEBUG('Loading Global Batteries');
			return await createBatteryService()
				.then((batteryService) => {
					return batteryService.getBatteries();
				})
				.then((response) => {
					setGlobalData((prev) => ({
						...prev,
						batteries: response.data,
					}));
					DEBUG('Global Batteries Loaded');
					return response;
				})
				.catch((error) => {
					ERROR(error);
					return [];
				});
		};

		if (!isEmpty(bearerToken)) {
			loadBatteries();
		}
	}, [bearerToken, createBatteryService]);

	useEffect(() => {
		if (UsersPage) {
			setGlobalData((prev) => ({
				...prev,
				orgUsers: UsersPage.results,
			}));
		}
	}, [UsersPage]);

	useEffect(() => {
		const loadOrgBatteries = async () => {
			DEBUG('Loading Organization Batteries (Deep)');
			const batteryService = await createBatteryService();
			batteryService
				.getBatteries({
					organizationId: currentOrganizationId as string, // TS
				})
				.then(({ data: batteries }) => {
					return Promise.all(
						batteries.map((battery) =>
							batteryService.getDeepBattery({
								batteryId: battery.id,
							})
						)
					);
				})
				.then((response) => {
					setGlobalData((prev) => ({
						...prev,
						orgBatteries: response.map(
							(deepBatteryResponse) => deepBatteryResponse.data
						),
					}));
					DEBUG('Organization Batteries Loaded');
					return response;
				})
				.catch((error) => {
					ERROR(error);
					return [];
				});
		};

		if (!isEmpty(currentOrganizationId) && !isEmpty(bearerToken)) {
			loadOrgBatteries();
		}
	}, [currentOrganizationId, createBatteryService, bearerToken]);
	return (
		<GlobalDataContext.Provider value={globalData}>
			{children}
		</GlobalDataContext.Provider>
	);
};
