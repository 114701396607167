import { Flex } from '@mantine/core';
import styled from 'styled-components';
import { P1 } from '../../../../../../shared/designSystem';
import { CategoryDescriptionTooltip } from './CategoryDescriptionTooltip';

type CategoryNameWithTooltipProps = {
	category: {
		tooltipText: string;
		displayKey: string;
	};
};

function CategoryNameWithTooltip(
	props: Readonly<CategoryNameWithTooltipProps>
) {
	return (
		<Flex
			data-testid='category-row-tooltip'
			direction={'row'}
			align={'center'}>
			{/* Category Name */}
			<StyledP1MediumText data-testid='category-name'>
				{props?.category?.displayKey}
			</StyledP1MediumText>
			<CategoryDescriptionTooltip
				tooltipText={props?.category?.tooltipText}
			/>
		</Flex>
	);
}

const StyledP1MediumText = styled(P1)(
	({ theme: { color, weight } }) => `
    margin: 0 0 0 0;
    font-weight: ${weight.medium};
    color: ${color.bodyText};
`
);

export { CategoryNameWithTooltip };
