import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RecallSubscoreWidget } from './components/RecallSubscoreWidget';
import { ClockDrawingSubscoreWidget } from './components/ClockDrawingSubscoreWidget';
import { WidgetHeader } from '../shared/WidgetHeader';
import { DcrReportProps } from '../../DcrReport';
import { getDcrSubscoresData } from '../../helpers/dcrSubscoresDataHelpers';

type DcrSubscoresWidgetProps = {
	data: DcrReportProps['data'];
};

const DcrSubscoresWidget = (props: DcrSubscoresWidgetProps) => {
	const recallSubtext = 'correct words';

	const dcrSubscoresData = useMemo(
		() => getDcrSubscoresData(props?.data),
		[props?.data]
	);

	const recallData = useMemo(
		() => dcrSubscoresData?.recall,
		[dcrSubscoresData?.recall]
	);

	return (
		<Container>
			<WidgetHeader titleText={'Subscores'} />
			<RowContainer>
				<OneColSpan>
					<RecallSubscoreWidget
						heading={recallData?.immediate?.segmentName}
						colors={recallData?.immediate?.scoreColor}
						score={recallData?.immediate?.score}
						subtext={recallSubtext}
					/>
				</OneColSpan>
				<VerticalSeparator />
				<TwoColSpan>
					<ClockDrawingSubscoreWidget
						data={dcrSubscoresData?.clock}
					/>
				</TwoColSpan>
				<VerticalSeparator />
				<OneColSpan>
					<RecallSubscoreWidget
						heading={recallData?.delayed?.segmentName}
						colors={recallData?.delayed?.scoreColor}
						score={recallData?.delayed?.score}
						subtext={recallSubtext}
					/>
				</OneColSpan>
			</RowContainer>
		</Container>
	);
};

export { DcrSubscoresWidget };

const Container = styled.div(
	({ theme: { spacing } }) => `
	padding: ${spacing.md};
`
);

const RowContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	margin-top: ${spacing.md};
`
);

const OneColSpan = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;

const TwoColSpan = styled.div`
	display: flex;
	flex: 2;
	justify-content: center;
`;

const VerticalSeparator = styled.div(
	({ theme: { color } }) => `
	&:not(:last-child) {
		position: relative;
		border-right: 1px solid ${color.infoSeparator};
	}
`
);
