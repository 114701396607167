import { Typography } from '@lh/eng-web-mosaic-common';

import { Space } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { FeatureType } from 'api/organization';
import { useBreakdownMeasuresStore } from 'store';
import { sendEventData } from '../../../analytics/amplitude';
import { LoadingDots } from '../../../components/shared/LoadingDots';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import { AnalyticsAction } from '../../../generated/graphql';
import { useGetOrgEntitlements } from '../../../hooks/useGetOrgEntitlements';
import { columns } from '../../../schemas/table/participantDetailsSchema';
import { FileNotFound } from '../../FileNotFound';
import { LinusPaginatedDataTable } from '../../shared/DataTable/PaginatedDataTable';
import { Header } from '../../shared/Header';
import { ParticipantInfo } from '../ParticipantInfo';

import { ParticipantClassification } from './components/ParticipantClassification';
import { ResultsConst } from './components/ParticipantClassification/ParticipantClassification.utils';
import { ResultFlags } from './components/ResultFlags/ResultFlags';
import { useGetParticipantDetails } from './useGetParticipantDetails';

const ParticipantDetails = (): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	const params = useParams();
	const reset = useBreakdownMeasuresStore((state) => state.reset);
	const { hasFeatureEntitlement } = useGetOrgEntitlements();

	const { currentUser } = useContext(UserContext);

	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedPatientDetails });
	}, []);

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';

	const { participantId = null } = params;

	const { loading, data: participantData } =
		useGetParticipantDetails(participantId);

	const hasVisitTypesEntitlement = useMemo(
		() => hasFeatureEntitlement(FeatureType.VisitTypes),
		[hasFeatureEntitlement]
	);
	const tableColumns = useMemo(
		() => columns(dateFormat, theme, hasVisitTypesEntitlement),
		[dateFormat, hasVisitTypesEntitlement, theme]
	);
	useEffect(() => reset(), [participantId, reset]); //whenever we get a new participant we reset the breakdown

	if (loading) {
		return <LoadingDots />;
	}

	if (!participantData?.participantInfo || !participantId) {
		return <FileNotFound />;
	}

	const latestDacBatteryResult = participantData?.cohortResults
		?.filter((item) => item.battery.includes(ResultsConst.DAC))
		.sort(
			(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
		)[0];

	return (
		<StyledContainer>
			<StyledContent>
				<Header />
				<ResultFlags participantData={participantData} />
				<Space h='sm' />
				{latestDacBatteryResult?.id &&
					participantData?.cohortResults && (
						<>
							<StyledH3 testId='classifications-heading-text'>{t`web.results.classifications`}</StyledH3>
							<Space h='sm' />
							<ParticipantClassification
								data-testid='classification'
								latestDacBatteryResultId={
									latestDacBatteryResult?.id
								}
								results={participantData.cohortResults}
								participantLanguage={
									participantData.participantInfo.language
								}
							/>
						</>
					)}
				<>
					<StyledH3 testId='session-history-header'>{t`web.results.sessionHistory`}</StyledH3>
					<Space h='sm' />
					<LinusPaginatedDataTable
						title={t`web.results.title`}
						columns={tableColumns}
						tableData={participantData?.batteryResults || []}
						operations={[]}
						deviantWidth='804px'
						longText={t`web.results.emptyTablePlaceholder`}
						noDataIcon={icons.EmptyClipboardSearch}
						count={participantData?.batteryResults?.length || 0}
						total={participantData?.batteryResults?.length || 0}
						currentPage={0}
						setCurrentPage={(pageNumber): number => pageNumber}
						loading={false}
						hasInitialData={false}
						hideFooter={true}
					/>
				</>
			</StyledContent>
			<AnimatePresence>
				<ParticipantInfo data={participantData?.participantInfo} />
			</AnimatePresence>
		</StyledContainer>
	);
};

export { ParticipantDetails };

const StyledContainer = styled.div`
	display: flex;
`;

const StyledContent = styled.div`
	width: calc(100% - 248px);
`;

const StyledH3 = styled(Typography.H3)`
	display: block;
	color: ${(props) => props.theme.color.headerTextSecondary};
`;
