// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const NoDataUser = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 146 100'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='NoDataUser-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'NoDataUser'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M99 30.5C99 41.2696 90.4934 50 80 50C69.5066 50 61 41.2696 61 30.5C61 19.7304 69.5066 11 80 11C90.4934 11 99 19.7304 99 30.5ZM96.5 30.5C96.5 39.9505 89.0519 47.5 80 47.5C70.9481 47.5 63.5 39.9505 63.5 30.5C63.5 21.0495 70.9481 13.5 80 13.5C89.0519 13.5 96.5 21.0495 96.5 30.5Z'
				fill='#676671'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M111 86.4144C111 88.3287 109.493 90.0001 107.523 90.0001L52.4766 89.9954C50.6206 89.9954 49 88.4407 49 86.4097V74.6955C49 63.8223 57.5734 55.0962 68.0033 55.0962H91.9956C101.677 55.0962 111 63.3282 111 73.2898V86.4144ZM108.5 73.2898V87.5046L51.5 87.5001V74.6955C51.5 64.9753 58.8884 57.5001 68.0033 57.5001H91.9956C100 57.5001 108.5 65.0001 108.5 73.2898Z'
				fill='#676671'
			/>
			<path
				d='M131.275 58.3115C123.672 65.837 123.892 66.7702 134 69.8137C123.892 66.7695 123.223 67.4325 125.724 78C123.223 67.4318 122.329 67.1645 114.727 74.69C122.329 67.1645 122.111 66.2313 112 63.1929C122.11 66.232 122.779 65.569 120.278 55C122.778 65.5697 123.672 65.8377 131.275 58.3115Z'
				fill='#B1E5FB'
			/>
			<path
				d='M38.299 26C45.3284 35.0013 48.0015 35.3299 57 28.2987C48.0002 35.3299 47.6742 38 54.701 47C47.6742 37.9987 44.9985 37.6701 36 44.7013C44.9998 37.6701 45.3284 35.0013 38.299 26Z'
				fill='#B1E5FB'
			/>
			<path
				d='M27 81.615C33.8559 84.0883 35.4405 83.4292 38.0747 77C35.4405 83.4286 36.143 84.9107 43 87.3829C36.143 84.9107 34.5573 85.5719 31.9241 92C34.5578 85.5724 33.8559 84.0883 27 81.615Z'
				fill='#C3E3AC'
			/>
			<path
				d='M118 15.615C124.856 18.0883 126.441 17.4292 129.075 11C126.441 17.4286 127.143 18.9107 134 21.3829C127.143 18.9107 125.557 19.5719 122.924 26C125.558 19.5724 124.856 18.0883 118 15.615Z'
				fill='#C3E3AC'
			/>
			<line
				x1='42.75'
				y1='67.25'
				x2='1.25'
				y2='67.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='42.75'
				y1='73.25'
				x2='19.25'
				y2='73.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='144.75'
				y1='47.25'
				x2='103.25'
				y2='47.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
			<line
				x1='127.75'
				y1='41.25'
				x2='104.25'
				y2='41.25'
				stroke='#EEF8FF'
				strokeWidth='2.5'
				strokeLinecap='round'
			/>
		</svg>
	);
};
