import { useMutation } from '@tanstack/react-query';

import { CreateParticipantInput } from 'api/participant';
import { getSubjectService } from '../getSubjectService';

type CreateParticipantArgs = {
	organizationId: string;
	participant: CreateParticipantInput;
};

async function createParticipant({
	organizationId,
	participant,
}: CreateParticipantArgs) {
	const service = await getSubjectService();
	return await service.createParticipant({
		createParticipantInput: { ...participant },
		organizationId,
	});
}

export function useCreateParticipant() {
	return useMutation({
		meta: {
			errorMessage: 'Error creating participant',
		},
		mutationFn: createParticipant,
	});
}
