import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Caption } from '../../../shared/designSystem';

type ReportFooterProps = {
	batteryResultsId: string;
	participantId: string;
};

export const ReportFooter = ({
	batteryResultsId,
	participantId,
}: ReportFooterProps): JSX.Element => {
	return (
		<div>
			<CaptionRegular>
				{<Trans i18nKey={'web.report.disclaimer'} />}
			</CaptionRegular>
			<Row>
				<CaptionMedium>
					<Trans
						i18nKey={
							'web.report.cognitiveEvaluation.footer.participantId'
						}
					/>
					: {participantId}
				</CaptionMedium>
				<CaptionMedium>
					<Trans
						i18nKey={
							'web.report.cognitiveEvaluation.footer.batteryResultsId'
						}
					/>
					: {batteryResultsId}
				</CaptionMedium>
			</Row>
		</div>
	);
};

const CaptionRegular = styled(Caption)(
	({ theme: { weight, color } }) => `
		font-weight: ${weight.regular};
		color: ${color.bodyTextSecondary};
	`
);

const Row = styled.div(
	({ theme: { spacing } }) => `
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0;
		gap: ${spacing.sm};
	`
);

const CaptionMedium = styled(Caption)(
	({ theme: { weight, color } }) => `
		display: flex;
		flex-direction: column;
		line-height: 13.5px;
		letter-spacing: 0.2px;
		font-weight: ${weight.medium};
		color: ${color.bodyText};
		text-transform: uppercase;
		background: ${color.reportFooterIdsBg};
	`
);
