// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const ArrowRight = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 8 14'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='ArrowRight-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'ArrowRight'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.646448 13.3536C0.451186 13.1583 0.451185 12.8417 0.646447 12.6464L6.29289 7L0.646446 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646446C0.841709 0.451185 1.15829 0.451184 1.35355 0.646446L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355L1.35355 13.3536C1.15829 13.5488 0.84171 13.5488 0.646448 13.3536Z'
				fill='current'
			/>
		</svg>
	);
};
