import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../../../designSystem/Text';
import ScoringWidgetHeaderTooltip from '../../../common/scoring/scoringWidget/components/ScoringWidgetHeaderTooltip';

type WidgetHeaderProps = {
	titleText: string;
	infoTooltipText?: string;
};

const WidgetHeader = (props: WidgetHeaderProps) => {
	return (
		<HeaderContainer>
			<HeaderTitleText data-testid={'scoring-widget-header-title-text'}>
				{props?.titleText}
			</HeaderTitleText>
			{props?.infoTooltipText && (
				<ScoringWidgetHeaderTooltip text={props?.infoTooltipText} />
			)}
		</HeaderContainer>
	);
};

export { WidgetHeader };

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const HeaderTitleText = styled(H4)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);
