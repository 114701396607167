import styled from 'styled-components';
import { Header } from '../../shared/Header';
import { Breadcrumbs } from '../../shared/breadcrumbs/Breadcrumbs';

function ReportHeader() {
	return (
		<StyledHeader>
			<Header />
			<Breadcrumbs />
		</StyledHeader>
	);
}

const StyledHeader = styled.div(
	({ theme: { color, spacing } }) => `
	background: ${color.body};
	position: sticky;
	top: -${spacing.md};
	z-index: 3;
`
);

export default ReportHeader;
