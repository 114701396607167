import { Card, Space } from '@mantine/core';

import { LineDivider } from 'components/shared/LineDivider';
import { SingleSelectRowType } from '../common/types/CustomQnr.types';
import { FooterText, HeaderWrapper } from './PHQ-8.style';
import { PHQ8Table } from './components/PHQ-8Table/PHQ-8.table';

export type PHQ8LayoutProps = {
	renderHeader: () => React.ReactNode;
	renderInterpretation: () => React.ReactNode;
	tableRowData?: SingleSelectRowType[];
	renderFooter: () => React.ReactNode;
};

const PHQ8Layout = (props: PHQ8LayoutProps) => {
	return (
		<div>
			{props?.renderInterpretation?.()}
			<Card padding={'md'} radius='md' withBorder shadow='sm'>
				<HeaderWrapper>{props?.renderHeader?.()}</HeaderWrapper>
				<Space h='md' />
				<PHQ8Table tableRowData={props?.tableRowData} />
				<LineDivider />
				<Space h='md' />
				<FooterText>{props?.renderFooter?.()}</FooterText>
			</Card>
			<Space h='md' />
		</div>
	);
};

export { PHQ8Layout };
