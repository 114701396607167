import { useMemo } from 'react';

import { TabbedLayout } from '../../../../TabbedLayout';
import { FormattedSegmentResult } from '../../../../report/common';
import {
	getConfidenceScoreData,
	getConfidenceChangeData,
	getImportantAspectUpdates,
	RawAnswer,
} from './LongitudinalEpsom.helpers';
import { ConfidenceScoreTab } from './components/ConfidenceScoreTab/ConfidenceScoreTab';
import { ChangeInConfidenceTab } from './components/ChangeInConfidenceTab/ChangeInConfidenceTab';
import { ImportantAspectUpdatesTab } from './components/ImportantAspectUpdatesTab/ImportantAspectUpdatesTab';
import { useTranslation } from 'react-i18next';

type Props = {
	segmentResult: FormattedSegmentResult;
	rawData: {
		baseline: Record<string, unknown>;
		currentLongitudinal: { answers: RawAnswer[] };
	};
	relatedResults: {
		baseline: FormattedSegmentResult;
		longitudinals: FormattedSegmentResult[];
	};
};

export const LongitudinalEpsomLayout = (props: Props) => {
	const { t } = useTranslation();
	const { confidenceScoreData, confidenceChangeData, importantAspectData } =
		useMemo(() => {
			return {
				confidenceScoreData: getConfidenceScoreData(
					props.relatedResults.baseline.metricItems,
					props.segmentResult.metricItems
				),
				confidenceChangeData: getConfidenceChangeData(
					props.relatedResults.baseline.metricItems,
					props.segmentResult.metricItems,
					props.rawData.currentLongitudinal.answers
				),
				importantAspectData: getImportantAspectUpdates(
					props.relatedResults.baseline.metricItems,
					props.rawData.currentLongitudinal.answers
				),
			};
		}, [props.segmentResult, props.relatedResults, props.rawData]);

	return (
		<TabbedLayout
			tabs={[
				{
					name: t('web.report.longEpsom.tabs.confidenceScore'),
					component: (
						<ConfidenceScoreTab data={confidenceScoreData} />
					),
				},
				{
					name: t('web.report.longEpsom.tabs.changeInConfidence'),
					component: (
						<ChangeInConfidenceTab data={confidenceChangeData} />
					),
				},
				{
					name: t('web.report.longEpsom.tabs.importantAspect'),
					component: (
						<ImportantAspectUpdatesTab data={importantAspectData} />
					),
				},
			]}
		/>
	);
};
