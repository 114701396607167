import { TFunction } from 'i18next';
import { DelayedRecognitionLayoutProps } from './DelayedRecognition.layout';
import { MapDataToPropsArgs } from './DelayedRecognition.types';
import { MetricHash } from '../metrics';

const unanalyzableScore = '--';
const minimumAchievableScore = 0;
const maximumAchievableScore = 6;

const getTotalScoreToDisplay = (
	totalScoreAchieved: number,
	translationFn: TFunction
): DelayedRecognitionLayoutProps['score']['total'] => {
	const totalScoreToDisplay = `${
		totalScoreAchieved === 0 ? unanalyzableScore : totalScoreAchieved
	}/${maximumAchievableScore}`;
	const scoreTitle = translationFn(
		'web.report.verbalFluency.correctResponses'
	)?.toUpperCase();
	return { value: totalScoreToDisplay, title: scoreTitle };
};

const getScoreInfo = (
	metricItems: MetricHash,
	translationFn: TFunction
): DelayedRecognitionLayoutProps['score'] => {
	const totalScore =
		(metricItems?.['pvlt_recognition_score']?.value as number) ??
		minimumAchievableScore;
	return {
		requiresClinicianReview: totalScore === minimumAchievableScore,
		total: getTotalScoreToDisplay(totalScore, translationFn),
	};
};

const mapDataToProps = ({
	segmentResult,
	translationFn,
}: MapDataToPropsArgs): Omit<
	DelayedRecognitionLayoutProps,
	'individualAnswers'
> => {
	const metricItems = segmentResult?.metricItems ?? {};
	const segmentName =
		segmentResult?.segment?.displayKey ?? 'Delayed Recognition';
	return {
		header: {
			title: segmentName,
			tooltipText: translationFn(
				'web.report.verbalFluency.delayedRecognition.tooltipText'
			),
		},
		score: getScoreInfo(metricItems, translationFn),
	};
};

export { mapDataToProps };
