import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../../metrics';
import { TFunction } from 'react-i18next';

export const getDrawingEfficiencyDataSet = (
	dataMap: MetricHash,
	t: TFunction
): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const drawingEfficiencyMap = {
		commandClock: [
			{
				x: t(drawingEfficiencyStrings.strokeCountConformity),
				y: dataMap?.COMStrokeCountConformity_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.totalTime),
				y: dataMap?.COMTotalTime_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.inkLength),
				y: dataMap?.COMInkLength_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.drawingSize),
				y: dataMap?.COMDrawingSize_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.drawingProcessEfficiency),
				y: dataMap?.COMDrawingProcessEfficiency_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.noise),
				y: dataMap?.COMNoise_s?.value,
			},
		],
		copyClock: [
			{
				x: t(drawingEfficiencyStrings.strokeCountConformity),
				y: dataMap?.COPStrokeCountConformity_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.totalTime),
				y: dataMap?.COPTotalTime_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.inkLength),
				y: dataMap?.COPInkLength_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.drawingSize),
				y: dataMap?.COPDrawingSize_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.drawingProcessEfficiency),
				y: dataMap?.COPDrawingProcessEfficiency_s?.value,
			},
			{
				x: t(drawingEfficiencyStrings.noise),
				y: dataMap?.COPNoise_s?.value,
			},
		],
	};
	return drawingEfficiencyMap as DataSet;
};

export const drawingEfficiencyStrings = {
	strokeCountConformity: 'web.report.segmentUtils.strokeCountConformity',
	totalTime: 'web.report.segmentUtils.totalTime',
	inkLength: 'web.report.segmentUtils.inkLength',
	drawingSize: 'web.report.segmentUtils.drawingSize',
	drawingProcessEfficiency:
		'web.report.segmentUtils.drawingProcessEfficiency',
	noise: 'web.report.segmentUtils.noise',
};
