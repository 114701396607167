import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { config } from '../../config';
import { icons } from '../../enums/icons';
import { Icon } from '../shared/designSystem';
import { ButtonSm } from '../shared/designSystem/ButtonSm';
import { H2 } from '../shared/designSystem/TextComponents';

function getDomainUrl(params: URLSearchParams) {
	const type = params.get('ot');

	switch (type) {
		case 'c':
			return config.clinicalDomainUrl;
		case 'r':
			return config.researchDomainUrl;
		case 'm':
			return config.managementDomainUrl;
		default:
			return null;
	}
}

const AccessDenied = (): JSX.Element | null => {
	const { search } = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const domainUrl = getDomainUrl(new URLSearchParams(search));

	// Special Case - deactivated users

	const messageBody = domainUrl
		? t('web.shared.accessDenied.body')
		: t('web.shared.accessDenied.deactivatedBody');

	function handleClick() {
		if (domainUrl) {
			window.open(`${domainUrl}/auth/login`, '_blank');
		} else {
			navigate('/auth/login');
		}
	}

	return (
		<StyledPage data-testid='access-denied-page'>
			<StyledFormContainer>
				<Icon icon={icons.WarningRound} width={75} height={75} />
				<StyledH2>
					{t`web.shared.accessDenied.title` as string}
				</StyledH2>
				<StyledBodyText>{messageBody}</StyledBodyText>
				<StyledButtonContainer>
					<ButtonSm
						primary={true}
						text={t`web.login.title`}
						onClick={handleClick}
					/>
				</StyledButtonContainer>
				<StyledLogoWrapper>
					<StyledLogo>
						<Icon
							icon={icons.Logo}
							title={t`web.shared.logo`}
							width={150}
							height={50}
						/>
					</StyledLogo>
				</StyledLogoWrapper>
			</StyledFormContainer>
		</StyledPage>
	);
};

const StyledPage = styled.div(
	({ theme: { spacing } }) =>
		`
	display: flex;
	justify-content: center;
	padding: ${spacing.xxxl}
`
);

const StyledFormContainer = styled.div(
	({ theme: { color, boxShadow, spacing } }) => `
		justify-content: center;
		align-content: center;
		text-align: center;
		width: 548px;
		height: auto;
		padding: ${spacing.xxl} ${spacing.xl};
		background: ${color.white};
		border-radius: ${spacing.xxl} 0 ${spacing.xxl} ${spacing.xxl};
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};
	`
);

const StyledH2 = styled(H2)(
	({ theme: { fontSize, color, spacing } }) => `
        font-size: '${fontSize._32}';
        line-height: 40px;
        -webkit-letter-spacing: 0.2px;
        -moz-letter-spacing: 0.2px;
        -ms-letter-spacing: 0.2px;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: ${color.textBlue};
        padding-top: ${spacing.xl};
        font-weight: 600;
        margin: 0;
	`
);

const StyledBodyText = styled.div(
	({ theme: { spacing, fontSize, color } }) => `
		color: ${color.textInfo};
		padding-top: ${spacing.md};
		font-size: ${fontSize._16};
		line-height: 19px;
		-webkit-letter-spacing: 0.5px;
		-moz-letter-spacing: 0.5px;
		-ms-letter-spacing: 0.5px;
		letter-spacing: 0.5px;
	`
);

const StyledButtonContainer = styled.div(
	({ theme: { spacing } }) =>
		`
		display: flex;
		justify-content: center;
		margin-top: ${spacing.xl};
	`
);

const StyledLogoWrapper = styled.div(
	() => `
	`
);

const StyledLogo = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.xxl};
`
);

export { AccessDenied };
