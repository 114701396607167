import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { DctScoreClassification, dctScoreMap } from './dctScoreMap';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { Icon, P1, P2 } from '../../../shared/designSystem';
import { icons } from '../../../../enums/icons';

export type DctClockScoreInfoProps = {
	dctScoreClassification: DctScoreClassification;
};

export const DctClockScoreInfo = ({
	dctScoreClassification,
}: DctClockScoreInfoProps): JSX.Element => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { dctScoreColor, scoreIcon, scoreText, scoreTextColor } =
		dctScoreMap(theme)[dctScoreClassification];
	const translatedClassification = t`web.report.DCTClock.subscore.info.classification.${dctScoreClassification}`;
	return (
		<Container>
			<StyledScoringInfo>
				<StyledScoreTitle>
					<Trans
						i18nKey={`web.report.DCTClock.subscore.info.title`}
					/>
				</StyledScoreTitle>
				<LinusTooltip
					overlay={
						<StyledTooltipTextContainer>
							<StyledTooltipText>
								<Trans
									i18nKey={`web.report.DCTClock.subscore.info.tooltip.0`}
								/>
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.1'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.2'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.3'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
						</StyledTooltipTextContainer>
					}
					tooltipIcon={
						<span>
							<Icon
								icon={icons.InfoOutlined}
								width={18}
								height={18}
							/>
						</span>
					}
				/>
				{dctScoreClassification !== 'unanalyzable' ? (
					<StyledScoreIconDescriptionContainer>
						<StyledIcon
							dctScoreClassification={dctScoreClassification}>
							<Icon
								icon={scoreIcon}
								color={dctScoreColor}
								title={translatedClassification}
								width={18}
								height={10.67}
							/>
						</StyledIcon>
						<StyledScoreText
							data-testid='dctScoreText'
							scoreTextColor={scoreTextColor}>
							{scoreText}
						</StyledScoreText>
					</StyledScoreIconDescriptionContainer>
				) : null}
			</StyledScoringInfo>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledScoringInfo = styled.span(
	({ theme: { color, spacing } }) => `
	display: flex;
	flex-direction: row;
  align-items: center;
	color: ${color.graphTextPrimary};
	margin-bottom: ${spacing.md};
`
);

type StyledScoreTextProps = {
	scoreTextColor: string;
};

const StyledScoreText = styled(P1)<StyledScoreTextProps>(
	({ scoreTextColor }) => `
	color: ${scoreTextColor};
`
);

const StyledScoreTitle = styled(P1)(
	({ theme: { color, weight } }) => `
    color: ${color.graphTextPrimary};
    font-weight: ${weight.regular};
`
);

const StyledTooltipText = styled(P2)`
	color: white;
	text-align: left;
	margin-bottom: 10px;
`;

const StyledTooltipTextContainer = styled.div`
	width: 300px;
`;

const StyledScoreIconDescriptionContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: ${spacing.md};
	`
);

const StyledIcon = styled.span<{
	dctScoreClassification: DctScoreClassification;
}>(
	({ dctScoreClassification, theme: { spacing } }) => `
	margin-right: ${spacing.sm};
	padding-bottom: ${dctScoreClassification === 'indeterminate' ? '6px' : 0}
`
);
