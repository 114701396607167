import { useLayoutEffect, useRef } from 'react';
import { dctTest } from './model';
import { single_player } from './gen_html.js';
import { dctSketchPlayer } from './player';
import { dctFill } from './components';
import './vars.less';
import './drawstack.less';
import './timebar.less';

type ClockAnimationProps = {
	rawData: Record<string, unknown>;
	section_name: string;
};

const toKey = (val: string): string => {
	return val.toLowerCase();
};

const ClockAnimation = ({
	rawData,
	section_name,
}: ClockAnimationProps): JSX.Element => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		if (ref.current) {
			dctFill(
				ref.current,
				single_player(
					toKey(section_name),
					new dctTest({ doc_data: { test_data: rawData } }),
					{
						size: 'xm',
					}
				)
			);
			new dctSketchPlayer();
		}
		// FIXME fix me or explain why please
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref.current]);
	return <div ref={ref} />;
};

export { ClockAnimation };
