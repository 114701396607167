import styled from 'styled-components';
import { Trans } from 'react-i18next';

type CompositeScalesLegendProps = {
	colors: string[];
};

const CompositeScalesLegend = ({
	colors,
}: CompositeScalesLegendProps): JSX.Element => {
	return (
		<StyledWrapper>
			<StyledData>
				<StyledKey>
					<StyledCap background={colors[0]} />
					<StyledBlock background={colors[0]} />
				</StyledKey>
				<StyledSpan>
					<Trans
						i18nKey={`web.dcrReport.recordings.clock.command.title`}
					/>
				</StyledSpan>
			</StyledData>
			<StyledData>
				<StyledKey>
					<StyledCap background={colors[1]} />
					<StyledBlock background={colors[1]} />
				</StyledKey>
				<StyledSpan>
					<Trans
						i18nKey={`web.dcrReport.recordings.clock.copy.title`}
					/>
				</StyledSpan>
			</StyledData>
		</StyledWrapper>
	);
};

export { CompositeScalesLegend };

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `
	width: 100%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
  margin: ${spacing.xl} 0 0 0;
  @media print {
    justify-content: center;
  }
`
);

const StyledData = styled.div`
	display: flex;
	align-items: center;
	width: auto;
	margin: 0 32px 0 0;
`;

type StyledKeyProps = {
	background: string;
};
const StyledKey = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 4px 4px 0 0;
	position: relative;
	overflow: hidden;
`;
const StyledCap = styled.div<StyledKeyProps>(
	({ background }) => `
	width: 100%;
	height: 4px;
	position: absolute;
	top: 0;
	left: 0;
	background: ${background || undefined};
`
);
const StyledBlock = styled.div<StyledKeyProps>(
	({ background }) => `
	width: 100%;
	height: 100%;
	background: ${background || undefined};
	opacity: 0.2;
`
);
const StyledSpan = styled.span(
	({ theme: { spacing, fontSize, color } }) => `
	font-size: ${fontSize._12};
	margin: 0 ${spacing.sm};
	color: ${color.bodyText};
`
);
