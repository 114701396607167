// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Users = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 20 16'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Users-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Users'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.3716 7.8145H16.6282C18.346 7.8145 20 9.27333 20 11.0387V13.3646C20 13.7038 19.7325 14 19.3832 14L11 13.9993V15.3646C11 15.7038 10.7325 16 10.3832 16L0.616822 15.9992C0.287521 15.9992 0 15.7236 0 15.3637V13.2878C0 11.3609 1.52108 9.8145 3.37155 9.8145H7.62824C8.16346 9.8145 8.6925 9.95613 9.16724 10.2045C9.61067 8.81275 10.8848 7.8145 12.3716 7.8145ZM10.9055 12.3123C10.7586 11.7539 10.4492 11.2489 10.0382 10.8414C10.2422 9.66669 11.2368 8.8145 12.3716 8.8145H16.6282C17.8614 8.8145 19 9.89098 19 11.0387V13L11 12.9993C10.9999 12.7536 10.9641 12.524 10.9055 12.3123ZM9.00004 11.2699C8.60303 10.988 8.12325 10.8145 7.62824 10.8145H3.37155C2.09738 10.8145 1 11.8889 1 13.2878V14.9992L10 15V13.0387C10 12.8561 9.97118 12.6753 9.91774 12.5004C9.67854 11.7711 9 11.2878 9 11.2878C9 11.2818 9.00001 11.2758 9.00004 11.2699Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.5104 6.94661C14.1876 6.94661 13.8757 6.90058 13.5807 6.8145C12.1659 6.40168 11.1382 5.06783 11.1389 3.4733C11.1389 1.5464 12.66 0 14.5104 0C16.3809 0 17.882 1.54547 17.882 3.4733C17.882 5.06775 16.8405 6.40167 15.4327 6.8145C15.1392 6.90058 14.8297 6.94661 14.5104 6.94661ZM12.1389 3.4733C12.1389 2.07436 13.2363 1 14.5104 1C15.801 1 16.882 2.0697 16.882 3.4733C16.882 4.87225 15.7846 5.94661 14.5104 5.94661C13.219 5.94661 12.1383 4.87569 12.1389 3.4733Z'
				fill='current'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.13888 5.4733C2.13822 7.06783 3.1659 8.40168 4.58072 8.8145C4.87573 8.90058 5.18757 8.94661 5.51043 8.94661C5.8297 8.94661 6.13916 8.90058 6.4327 8.8145C7.8405 8.40167 8.88199 7.06775 8.88199 5.4733C8.88199 3.54547 7.38089 2 5.51043 2C3.65997 2 2.13888 3.5464 2.13888 5.4733ZM3.13888 5.4733C3.13888 4.07436 4.23627 3 5.51043 3C6.80095 3 7.88199 4.0697 7.88199 5.4733C7.88199 6.87225 6.7846 7.94661 5.51043 7.94661C4.21905 7.94661 3.1383 6.87569 3.13888 5.4733Z'
				fill='current'
			/>
		</svg>
	);
};
