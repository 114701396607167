import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { config } from '../../config';
import { RegulatoryLink } from '../shared/RegulatoryLink';
import { P2, Subtitle } from '../shared/designSystem';
import { PrivacyPolicyLink } from './PrivacyPolicyLink';
import { TermsOfUseLink } from './TermsOfUseLink';

const Footer = (): JSX.Element => {
	const { t } = useTranslation();
	const versionLabel = t(`research.menu.webAppVersionLabel`);
	const version = config.packageVersion;

	const HorizontalSeparator = (): JSX.Element => (
		<div>
			<StyledHorizontalMargin>{'|'}</StyledHorizontalMargin>
		</div>
	);

	return (
		<StyledFooterContainer data-testid='footer-container'>
			<StyledLinksContainer>
				<StyledVersion data-testid='footer-version-label-and-number'>{`${versionLabel} ${version}`}</StyledVersion>
				<HorizontalSeparator />

				<PrivacyPolicyLink />
				<HorizontalSeparator />

				<TermsOfUseLink />
				<HorizontalSeparator />

				<RegulatoryLink />
			</StyledLinksContainer>
			<StyledCopyright>
				<Trans
					i18nKey='web.shared.footer.copyrightText'
					values={{
						copyrightYear: new Date().getFullYear(),
					}}
				/>
			</StyledCopyright>
		</StyledFooterContainer>
	);
};

export { Footer };

const StyledFooterContainer = styled.div(
	({ theme: { color } }) => `
	display: flex;
	justify-content: space-between;
  align-items: center;
	max-width: 100%;
  height: 64px;
  padding: 0 128px;
  background-color: ${color.footer};
	position: relative;
`
);

const StyledLinksContainer = styled(Subtitle)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 450px;
`;

const StyledHorizontalMargin = styled.div(
	() => `
    margin: 0 10px;
`
);

const StyledCopyright = styled(P2)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    color: ${color.footerCopyright};
`
);

const StyledVersion = styled(P2)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    color: ${color.footerCopyright};
`
);
