import { Gender } from '../../generated/graphql';
import { generateTranslationEnum } from '../../utils/enumTranslationUtils';

export const gender = generateTranslationEnum<typeof Gender>(
	Gender,
	'web.enums.gender'
);

gender.Male.index = 1;
gender.Female.index = 2;
gender.TransgenderMale.index = 3;
gender.TransgenderFemale.index = 4;
gender.NonBinary.index = 5;
gender.Other.index = 6;
gender.NonDisclosed.index = 7;
gender.ToBeCollectedAtTimeOfTesting.index = 8;
