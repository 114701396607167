import {
	QuestionTableProps,
	isChecked,
	isPositivelyAnswered,
} from '@lh/eng-lhq-questions-common';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { CheckBox } from './icons/Checkbox';
import { CheckmarkIcon } from './icons/Checkmark';
import { CloseIcon } from './icons/Close';

export const QuestionsTable = ({
	elements,
	header,
}: QuestionTableProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledComponentContainer className='tbl-container'>
			<StyledTable className='tbl-tbl'>
				<thead>
					<tr>
						<th colSpan={2}>
							<StyledHeader className='tbl-header'>
								<StyledHeaderTitle
									className='tbl-title'
									data-testid='header-title-container'>
									{header}
								</StyledHeaderTitle>
								<StyledHeaderFactors className='tbl-header-fctors'>
									<StyledRisk
										className='tbl-header-fctors-risk'
										data-testid='risk-factors-container'>
										<CloseIcon size='10px' /> ={' '}
										{t(
											'web.report.lifeAndHealth.answers.riskFactorIdentified'
										)}
									</StyledRisk>
									<StyledDoingWell
										className='tbl-header-fctors-well'
										data-testid='wellness-factors-container'>
										<CheckmarkIcon size='18px' />={' '}
										{t(
											'web.report.lifeAndHealth.answers.doingWell'
										)}
									</StyledDoingWell>
								</StyledHeaderFactors>
							</StyledHeader>
						</th>
					</tr>
					<tr>
						<StyledTableHeader data-testid='table-questions-title'>
							{t(
								'web.report.lifeAndHealth.answers.questionTitle'
							)}
						</StyledTableHeader>
						<StyledTableHeader data-testid='table-selectionstitle'>
							{t('web.report.lifeAndHealth.answers.selections')}
						</StyledTableHeader>
					</tr>
				</thead>
				<tbody data-testid='qtable-body'>
					{elements.map((singleElement, index) => {
						return (
							<StyledTableRow
								key={singleElement.titleKey}
								className={
									'tbl-tbl-row ' +
									'data-row-table-row-' +
									index
								}>
								<QuestionCell className='tbl-tbl-row-cell'>
									<b>Q{index + 1}: </b>
									<span>{t(singleElement.titleKey)}</span>
								</QuestionCell>
								<StyledContentCell className='tbl-tbl-stld-cell'>
									{singleElement.answers.map(
										(answerElement) => {
											//We select which icon we'll use to display besides the answer.
											const checked = isChecked({
												singleElement,
												answerElement,
											});
											const positivelyAnswered =
												isPositivelyAnswered({
													singleElement,
													answerElement,
												});
											const displayIcon: JSX.Element =
												positivelyAnswered ? (
													<CheckmarkIcon size='18px' />
												) : (
													<CloseIcon size='10px' />
												);
											return (
												<StlyedContentContainer
													className='tbl-tbl-syld-cont'
													key={
														answerElement.titleKey
													}>
													<StyledIconContainer
														positivelyAnswered={
															positivelyAnswered
														}>
														{displayIcon}
													</StyledIconContainer>
													<CheckBox
														checked={checked}
														size='15px'
													/>
													<StyledText className='tbl-tbl-stld-txt'>
														{t(
															answerElement.titleKey
														)}
													</StyledText>
												</StlyedContentContainer>
											);
										}
									)}
								</StyledContentCell>
							</StyledTableRow>
						);
					})}
				</tbody>
			</StyledTable>
			<StyledFooter>
				<div data-testid='footer-text-container'>
					{t('web.report.lifeAndHealth.answers.endOfQuestionnaire')}
				</div>
			</StyledFooter>
		</StyledComponentContainer>
	);
};

const StyledHeader = styled.div(
	({ theme: { spacing, color } }) => css`
		background-color: ${color.white};
		display: inline-flex;
		padding-bottom: ${spacing.md};
		align-items: center;
		width: 100%;
		row-gap: ${spacing.md};
		flex-wrap: wrap;
	`
);

const StyledHeaderFactors = styled.div(
	() => css`
		display: flex;
	`
);

const StyledRisk = styled.span(
	({ theme: { weight, fontSize, color } }) => css`
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: ${weight.regular};
		font-size: ${fontSize._12};
		line-height: ${fontSize._10};
		color: ${color.graphOrangeDarker};
		display: flex;
		align-items: center;

		> div {
			display: inline-block;
		}
	`
);

const StyledDoingWell = styled.span(
	({ theme: { spacing, color, fontSize } }) => css`
		margin-left: ${spacing.sm};
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: ${fontSize._12};
		line-height: ${fontSize._10};
		color: ${color.graphGreenDarkest};

		display: flex;
		align-items: center;
	`
);

const StyledTable = styled.table(
	({ theme: { color } }) => css`
		width: 100%;

		tbody tr:first-child td {
			border-top: 2px solid ${color.tableBorder};
		}

		tbody tr:nth-child(odd) {
			background-color: ${color.cellHoverBg};
		}
	`
);

const StyledTableHeader = styled.th(
	({ theme: { weight, fontSize, color } }) => css`
		padding-bottom: 1em;
		font-style: normal;
		font-weight: ${weight.medium};
		font-size: ${fontSize._16};
		line-height: ${fontSize._10};
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: ${color.graphTextPrimary};
		padding-left: 8px;
	`
);

const StyledHeaderTitle = styled.h4(
	({ theme: { weight, fontSize, color } }) => css`
		font-style: normal;
		font-weight: ${weight.medium};
		font-size: ${fontSize._21};
		line-height: 26px;
		letter-spacing: 0.2px;
		color: ${color.graphTextPrimary};
	`
);

const StyledTableRow = styled.tr(
	({ theme: { fontSize, spacing, weight, color } }) => css`
		font-style: normal;
		font-weight: ${weight.medium};
		font-size: ${fontSize._8};
		line-height: 10px;
		color: ${color.graphTextPrimary};

		td {
			padding: ${spacing.sm} ${spacing.sm} ${spacing.md} ${spacing.sm};
		}
	`
);

const StyledContentCell = styled.td`
	display: flex;
	flex-direction: column;
`;

const QuestionCell = styled.td(
	({ theme: { spacing, fontSize } }) => css`
		width: 40%;
		font-size: ${fontSize._16};
		line-height: 19px;
		letter-spacing: 0.5px;
		padding: ${spacing.sm};
	`
);

const StlyedContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: flex-start;
		padding-left: ${spacing.xs};
		padding-top: ${spacing.xs};
		padding-bottom: ${spacing.xs};
	`
);

const StyledText = styled.div(
	({ theme: { weight, fontSize, spacing } }) => css`
		padding-left: ${spacing.xxs};
		font-weight: ${weight.regular};
		font-size: ${fontSize._16};
		line-height: 19px;
		letter-spacing: 0.5px;
	`
);

const StyledComponentContainer = styled.div(
	({ theme: { borderRadius, spacing } }) => css`
		background: white;
		box-shadow: 0px 2px 10px rgba(23, 24, 32, 0.1);
		border-radius: ${borderRadius.card};
		padding: ${spacing.xl};
	`
);

const StyledFooter = styled.div(
	({ theme: { color, weight, fontSize } }) => css`
		div {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: ${weight.regular};
			font-size: ${fontSize._16};
			line-height: 19px;
			display: flex;
			align-items: flex-end;
			text-align: center;
			letter-spacing: 0.5px;
			color: ${color.bodyTextSecondary};
			padding: ${fontSize._8};
			justify-content: center;
			align-items: center;
			border-top: 1px solid #d9d8dc;
			border-top: 1px solid ${color.inputBorder};
			margin-top: ${fontSize._8};
			padding-bottom: 0;
			margin-bottom: 0;
		}
	`
);

const StyledIconContainer = styled.div<{ positivelyAnswered: boolean }>(
	({ positivelyAnswered }) => css`
		${!positivelyAnswered && 'padding-top: 5px;'}
		position: relative;
		width: 17px;
		height: 18px;
	`
);
