import { useContext, useEffect, useMemo, useState } from 'react';

import {
	useQueryClient,
	useQueries,
	InfiniteQueryObserver,
} from '@tanstack/react-query';
import { negate, isNil, groupBy } from 'lodash';
import { CacheTimes, QueryKey } from '../../../api/query';

import {
	Assignment,
	AssignmentType,
	fetchAssignedAssignment,
} from 'api/assignment';
import { useGetOrgBatteryResults } from 'api/battery';
import { Participant, useGetPaginatedParticipants } from 'api/participant';
import { GlobalDataContext } from '../../../providers/globalDataProvider/GlobalDataProvider';
import {
	getPaginatedParticipantsFilterOptions,
	getParticipantBatteryResultsFilterOptions,
	getParticipantBatteryResultsSortOptions,
} from './Participants.helpers';
import {
	ParticipantTableDataRow,
	ParticipantsTableData,
	ParticipantsTableDataOptions,
} from './Participants.types';
import { getSendReceipts, PaginatedReceipts } from 'api/remote-assessments';

type GetParticipantsTableData = {
	loading: boolean;
	data: ParticipantsTableData;
	totalCount: number;
	reloadParticipants: () => void;
	error?: Error | null;
};

export function useGetParticipantsTableData(
	input: ParticipantsTableDataOptions
): GetParticipantsTableData {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);
	const [data, setParticipantsTableData] = useState<ParticipantsTableData>(
		[]
	);
	const client = useQueryClient();
	const { orgBatteries } = useContext(GlobalDataContext);

	const organizationId = input?.organizationId;

	// Get the list of participants
	const {
		data: participants,
		refetch,
		error: fetchParticipantsError,
	} = useGetPaginatedParticipants({
		organizationId: input?.organizationId,
		page: input?.page,
		pageSize: input?.pageSize,
		...getPaginatedParticipantsFilterOptions(input?.searchValue),
		...input?.sort,
	});

	// Get list of assignments for each participant and combine the data
	const {
		data: assignments,
		pending: loadingAssignments,
		error: fetchAssignmentsError,
	} = useQueries({
		queries: participants?.results
			? participants?.results.map((participant: Participant) => {
					const participantId = participant?.id;
					return {
						queryKey: [QueryKey.Assignment, participantId],
						queryFn: () => fetchAssignedAssignment(participantId),
					};
			  })
			: [],
		combine: (results) => {
			return {
				data: results.map((result) => result.data),
				pending: results.some((result) => result.isPending),
				error: results.map((result) => result.error),
			};
		},
	});

	useEffect(() => {
		const assigned = pullWebAssignments(assignments);
		if (loadingAssignments || !assignments.length || !assigned.length)
			return;
		(async () => {
			const queryObserver = new InfiniteQueryObserver(client, {
				queryKey: ['infinite-org-receipts', assigned.join(',')],
				queryFn: ({ pageParam }) =>
					getSendReceipts({
						assignmentIds: assigned,
						organizationId,
						page: pageParam,
						pageSize: 20,
					}),
				staleTime: CacheTimes.ThirtyMinutes,
				initialPageParam: 0,
				getNextPageParam: (
					lastPage: PaginatedReceipts,
					_allPages: PaginatedReceipts[],
					pageNum: number
				) => {
					if (
						lastPage.results.length === 0 || //no more results
						(pageNum === 0 &&
							lastPage.results.length === lastPage.totalCount) //no second page
					)
						return;

					return pageNum + 1;
				},
			});
			let hasNextPage = true;
			while (hasNextPage) {
				const result = await queryObserver.fetchNextPage();
				hasNextPage = result.hasNextPage;
			}
			const results = queryObserver.getCurrentResult().data?.pages;
			if (!results) return;
			Object.entries(
				groupBy(
					results.flatMap(({ results }) => results),
					'assignmentId'
				)
			).forEach(([assignmentId, entry]) => {
				client.fetchQuery({
					queryKey: [QueryKey.AssignmentSendReceipts, assignmentId],
					queryFn: () => ({
						results: entry,
						totalCount: entry.length,
					}),
					staleTime: CacheTimes.ThirtyMinutes,
				});
			});
		})();
	}, [assignments, client, organizationId, loadingAssignments]);
	const { data: batteryResults, error: fetchBatteryResultsError } =
		useGetOrgBatteryResults({
			organizationId,
			...getParticipantBatteryResultsFilterOptions(),
			...getParticipantBatteryResultsSortOptions(),
		});

	useEffect(() => {
		// Handle errors from each API endpoint (if any)
		if (fetchParticipantsError) {
			setError(fetchParticipantsError);
		} else if (fetchBatteryResultsError) {
			setError(fetchBatteryResultsError);
		} else if (fetchAssignmentsError?.[0]) {
			setError(fetchAssignmentsError?.[0]);
		} else {
			setError(null);
		}
	}, [
		fetchParticipantsError,
		fetchBatteryResultsError,
		fetchAssignmentsError,
	]);

	useEffect(() => {
		const getCombinedParticipantsList = () => {
			if (!participants || loadingAssignments) {
				return;
			}
			setLoading(true);
			const finalResults = participants?.results?.map(
				(participant: Participant) => {
					const participantId = participant?.id;
					const batteryResult = batteryResults?.results?.find(
						(result) => result?.participantId === participantId
					);
					const assignment = assignments?.find(
						(assignment: Assignment | null | undefined) =>
							assignment?.participantId === participantId
					);
					const battery = orgBatteries?.find(
						(battery) => battery.id === assignment?.batteryId
					);
					const assignmentInfo: ParticipantTableDataRow['assignment'] =
						assignment
							? {
									...assignment,
									batteryDisplayKey:
										battery?.displayKey ?? '',
							  }
							: null;
					return {
						...participant,
						assignment: assignmentInfo ?? null,
						batteryResult: batteryResult ?? null,
					};
				}
			);
			setParticipantsTableData(finalResults);
			setLoading(false);
		};
		getCombinedParticipantsList();
	}, [
		participants,
		batteryResults,
		loadingAssignments,
		assignments,
		orgBatteries,
	]);

	const totalCount = useMemo(
		() => participants?.totalCount ?? 0,
		[participants?.totalCount]
	);

	return { data, loading, error, totalCount, reloadParticipants: refetch };
}

function isDefined<T>(arg: T | null | undefined): arg is T {
	return negate(isNil)(arg);
}
function isWeb(arg: Assignment) {
	return arg.type === AssignmentType.Web;
}
function pullWebAssignments(assignments: Array<Assignment | null | undefined>) {
	return assignments
		.filter(isDefined)
		.filter(isWeb)
		.map(({ id }) => id);
}
