import i18n from 'i18n';
import { theme } from '../../../../../providers/styleProvider';

export type RiskLevel = 'Elevated' | 'NotElevated' | 'Unanalyzable';

type MapElement = {
	color: string;
	backgroundColor: string;
	text: string;
	info: string;
};

export const DEMENTIA_RISK_MAP: Record<RiskLevel, MapElement> = {
	Elevated: {
		backgroundColor: theme.colors.yellow_lightest,
		color: theme.colors.yellow_darkest,
		text: i18n.t('web.report.dementiaRisk.forecast.elevated'),
		info: i18n.t('web.report.dementiaRisk.forecast.risk'),
	},
	NotElevated: {
		backgroundColor: theme.colors.green_lightest,
		color: theme.colors.green_darkest,
		text: i18n.t('web.report.dementiaRisk.forecast.notElevated'),
		info: i18n.t('web.report.dementiaRisk.forecast.risk'),
	},
	Unanalyzable: {
		backgroundColor: theme.colors.gray_80,
		color: theme.colors.gray_10,
		text: '--',
		info: i18n.t('web.report.dementiaRisk.forecast.unanalyzable'),
	},
};
