/* eslint-disable no-mixed-spaces-and-tabs */
import { Space } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import {
	ScoreWithInterpretation,
	Trials,
	Typography,
} from '@lh/eng-web-mosaic-common';

import { LineDivider } from '../../../components/shared/LineDivider';
import { LinusTooltip } from '../../../components/shared/LinusTooltip';
import { H4, Icon } from '../../../components/shared/designSystem';
import { icons } from '../../../enums/icons';
import { theme } from '../../../providers/styleProvider';
import { ordinalSuffixPerformance } from '../../../utils/ordinalSuffix';
import { Card as DSCard } from '../../designSystem/Card';
import { RcrInterpretation } from '../Interpretation/RcrInterpretation';
import { AudioCardProps } from '../common/AudioCard/AudioCard';
import { AudioCardGrid } from '../common/AudioCardGrid/AudioCardGrid';

export type PhonemicFluencyLayoutProps = {
	mocked?: boolean;
	segmentResultId: string;
	segmentNameDisplayKey: string;
	tooltipText?: string;
	correctResponses: string;
	percentile: number;
	requiresReview?: boolean;
	breakdownScores: {
		epoch1: string;
		epoch2: string;
		epoch3: string;
		epoch4: string;
	};
	showAudioPlayer: boolean;
	audioCardData: AudioCardProps[];
};

export const PhonemicFluencyLayout = (props: PhonemicFluencyLayoutProps) => {
	const { t } = useTranslation();

	const scoreHeaderText = useMemo(
		() => t('web.report.verbalFluency.correctResponses').toUpperCase(),
		[t]
	);

	const scoreTagValueText = useMemo(
		() => (props?.requiresReview ? '--' : props?.correctResponses),
		[props]
	);

	return (
		<div>
			<Card data-testid='card-container'>
				<CardHeader data-testid='card-header-with-tooltip'>
					<HeaderTitle>{props?.segmentNameDisplayKey}</HeaderTitle>
					{props?.tooltipText && (
						<LinusTooltip
							data-testid={'scoring-widget-header-tooltip'}
							overlay={
								<InfoTooltip>{props?.tooltipText}</InfoTooltip>
							}
							tooltipIcon={
								<Icon
									icon={icons.InfoOutlined}
									iconWidth={24}
									iconHeight={24}
								/>
							}
						/>
					)}
				</CardHeader>
				<br />
				<LineDivider spacing={4} />
				<br />
				<div style={{ flexDirection: 'row', display: 'flex' }}>
					<div
						data-testid='scoring-with-interpretation'
						style={{ flex: 1, marginRight: theme?.spacing?.lg }}>
						<ScoreWithInterpretation
							score={scoreTagValueText}
							title={scoreHeaderText}
							renderInterpretationText={() => {
								if (props?.requiresReview) {
									return (
										<RcrInterpretation
											interpretationTransKey={
												'web.report.verbalFluency.reviewRequired.listenToRecording'
											}
										/>
									);
								} else {
									// e.g. Performance is in the <b>92nd percentile</b>
									return (
										<Typography.P2 weight='500'>
											{t(
												'web.report.verbalFluency.performancePrefix'
											)}{' '}
											<Typography.P2 weight='700'>
												{ordinalSuffixPerformance(
													props?.percentile
												)}{' '}
												{t(
													'web.report.verbalFluency.percentile'
												)}
											</Typography.P2>
										</Typography.P2>
									);
								}
							}}
						/>
					</div>
					<div data-testid='score-breakdown' style={{ flex: 2 }}>
						<Typography.P2
							weight='500'
							color={theme?.color?.bodyTextSecondary}>
							{t('web.report.verbalFluency.breakdown')}
						</Typography.P2>
						<div style={{ marginTop: 8 }} />
						<Trials
							responseText={
								props?.requiresReview
									? t(
											'web.report.verbalFluency.cannotCalculate'
									  )
									: t(
											'web.report.verbalFluency.correctResponses'
									  )
							}
							title={t('web.report.verbalFluency.time', {
								value: '0-15',
							})}
							score={
								props?.requiresReview
									? '--'
									: props?.breakdownScores.epoch1
							}
						/>

						<LineDivider spacing={4} />
						<Trials
							responseText={
								props?.requiresReview
									? t(
											'web.report.verbalFluency.cannotCalculate'
									  )
									: t(
											'web.report.verbalFluency.correctResponses'
									  )
							}
							title={t('web.report.verbalFluency.time', {
								value: '16-30',
							})}
							score={
								props?.requiresReview
									? '--'
									: props?.breakdownScores?.epoch2
							}
						/>

						<LineDivider spacing={4} />
						<Trials
							responseText={
								props?.requiresReview
									? t(
											'web.report.verbalFluency.cannotCalculate'
									  )
									: t(
											'web.report.verbalFluency.correctResponses'
									  )
							}
							title={t('web.report.verbalFluency.time', {
								value: '31-45',
							})}
							score={
								props?.requiresReview
									? '--'
									: props?.breakdownScores?.epoch3
							}
						/>

						<LineDivider spacing={4} />
						<Trials
							responseText={
								props?.requiresReview
									? t(
											'web.report.verbalFluency.cannotCalculate'
									  )
									: t(
											'web.report.verbalFluency.correctResponses'
									  )
							}
							title={t('web.report.verbalFluency.time', {
								value: '46-60',
							})}
							score={
								props?.requiresReview
									? '--'
									: props?.breakdownScores?.epoch4
							}
						/>
					</div>
				</div>
			</Card>
			<Space h={'sm'} />
			{props?.showAudioPlayer && (
				<AudioCardGrid
					sectionHeading='Recordings'
					data={props?.audioCardData}
				/>
			)}
		</div>
	);
};

export const StyledH3 = styled(Typography.H3)`
	margin-top: ${(props) => props.theme.spacing.xl};
	margin-bottom: ${(props) => props.theme.spacing.lg};
`;
const Card = styled(DSCard)`
	padding: ${(props) => props.theme.spacing.xl};
	display: flex;
	flex-direction: column;
`;

const CardHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

const HeaderTitle = styled(H4)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);

const InfoTooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);
