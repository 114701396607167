import * as yup from 'yup';
import i18n from '../../i18n';

export type ResetPasswordModel = {
	password: string;
	repeatPassword: string;
};
export const getModel = (): ResetPasswordModel => {
	return {
		password: '',
		repeatPassword: '',
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validationSchema = () => {
	return yup.object().shape({
		password: yup
			.string()
			.min(8, i18n.t('web.authErrors.passwordTooShort'))
			.matches(
				/[a-z]/,
				i18n.t('web.shared.forms.schemas.lowercaseRequired')
			)
			.matches(
				/[A-Z]/,
				i18n.t('web.shared.forms.schemas.uppercaseRequired')
			)
			.matches(/\d/, i18n.t('web.shared.forms.schemas.numberRequired'))
			.matches(
				/[$&+,~_`:#;=?[\]@|{}'<>".^*()%!/\\-]/,
				i18n.t('web.authErrors.passwordOneSpecialChar')
			)
			.required(i18n.t('web.authErrors.passwordRequired')),
		repeatPassword: yup
			.string()
			.required(i18n.t('web.authErrors.passwordRequired'))
			.oneOf(
				[yup.ref('password'), null],
				i18n.t('web.authErrors.passwordNotMatching')
			),
	});
};
