import { Auth } from '@lh/eng-shared-auth';
import { useCallback, useEffect, useState } from 'react';
import { useAuthServiceContext } from '../features/auth-service';
import { fetchUtil, prefix } from '../helpers/s3ApiFetch';

interface GetDataProps {
	segmentResultId: string;
	fileName: `${string}.${'json'}`;
}

type ResponseDataType<T> = {
	data: T | undefined | null;
	loading: boolean;
	error: unknown;
};

export const useGetRawData = <T>({
	segmentResultId,
	fileName,
}: GetDataProps): ResponseDataType<T> => {
	const [data, setData] = useState<T | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<unknown>(null);

	const authService: Auth = useAuthServiceContext();
	const envPrefix = prefix();
	const fetchData = useCallback(fetchUtil, []);

	useEffect(() => {
		const abortController = new AbortController();

		const fetchDataFromS3Async = async () => {
			if (!segmentResultId) return;
			try {
				const url = `${envPrefix}s3MetricFiles/${segmentResultId}/${fileName}`;
				const response = await fetchData({
					url,
					signal: abortController.signal,
					auth: authService,
				});
				if (response) {
					setData(response.data as T);
				}
				setIsLoading(false);
			} catch (error) {
				setError(error);
				setIsLoading(false);
			}
		};

		fetchDataFromS3Async();

		return () => {
			abortController.abort();
		};
	}, [fetchData, envPrefix, authService, segmentResultId, fileName]);

	return { data, loading: isLoading, error: error };
};
