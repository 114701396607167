/* eslint-disable @typescript-eslint/no-explicit-any */
import camelcase from 'camelcase';
import { Enumeration, enumeration } from '../enums/enumeration';

// Used to create the enumKey: { value, displayKey} sig
type TranslationEnum<T> = {
	-readonly [Property in keyof T]: { value: T; displayKey: string };
};

/*
 * Loop through enumToGen, reduce them all into an object that looks like
 * { enumKeyN: { value: enumValue, displayKey: translationPath } }
 * then pass it to enumeration() that will massage it further and translate based on displayKey
 * */
export const generateTranslationEnum = <T extends Record<any, any>>(
	enumToGen: T,
	translationPath: string
): Enumeration<T> => {
	const translationEnumObj = Object.entries(enumToGen).reduce(
		(prev, [key, value]) => {
			prev[key as keyof T] = {
				value: value.valueOf(),
				displayKey: `${translationPath}.${camelcase(value.valueOf())}`,
			};
			return prev;
		},
		{} as TranslationEnum<T>
	);

	return enumeration(translationEnumObj);
};
