import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LinusModal } from 'components/shared/LinusModal';
import { theme } from '../../../../../providers/styleProvider';

import { DEMENTIA_RISK_MAP, RiskLevel } from './DementiaRisk.consts';
import {
	Container,
	Info,
	LabelText,
	Link,
	ProbabilityTag,
	Row,
	Tag,
	TagText,
} from './DementiaRisk.styles';
import { DementiaRiskDetails } from './DementiaRiskDetails';

type DementiaRiskProps = {
	type: RiskLevel;
};

export const DementiaRisk = ({ type }: Readonly<DementiaRiskProps>) => {
	const { t } = useTranslation();
	const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

	const dementiaVariant = DEMENTIA_RISK_MAP[type];

	return (
		<Container>
			{isDetailsModalVisible && (
				<LinusModal
					title={t`web.report.dementiaRisk.modal.title`}
					onClose={() => setIsDetailsModalVisible(false)}>
					<DementiaRiskDetails
						onClose={() => setIsDetailsModalVisible(false)}
					/>
				</LinusModal>
			)}
			<Row>
				<LabelText>
					{t`web.report.dementiaRisk.forecast.title`}
				</LabelText>
				<ProbabilityTag>
					<Tag $bgColor={dementiaVariant.backgroundColor}>
						<TagText $color={dementiaVariant.color}>
							{dementiaVariant.text}
						</TagText>
					</Tag>
					<Info
						$weight={
							type === 'Unanalyzable'
								? theme.weight.medium
								: theme.weight.regular
						}>
						{dementiaVariant.info}
					</Info>
				</ProbabilityTag>
			</Row>
			<Link onClick={() => setIsDetailsModalVisible(true)}>
				{t`web.report.dementiaRisk.forecast.details`.toUpperCase()}
			</Link>
		</Container>
	);
};
