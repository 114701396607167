export function coerceToBoolean(v: unknown): boolean {
	if (v === 'false') return false;
	return Boolean(v);
}

//Boolean(str) => true (only exception)
//Boolean(null) => false
//Boolean(0) => false
//Boolean(1) => true
//Boolean(undefined) => false
