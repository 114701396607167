import styled from 'styled-components';
import { CompositeScales } from '../../graphs/CompositeScales';
import { getConcerningScores, getDataSetCharacteristics } from '../barChart';
import { getCompositeScalesDataSet } from '../../DctClock/clockData/compositeScales';
import { getDrawingEfficiencyDataSet } from '../../DctClock/clockData/drawingEfficiency';
import { getInformationProcessingDataSet } from '../../DctClock/clockData/informationProcessing';
import { getMotorDataSet } from '../../DctClock/clockData/simpleAndComplexMotor';
import { getSpatialReasoningDataSet } from '../../DctClock/clockData/spatialReasoning';
import { MetricHash } from '../../metrics';
import { Trans, useTranslation } from 'react-i18next';
import { glossary } from '../../Assessment/assessmentComponents/glossary';
import { ButtonSm, H4 } from '../../../../components/shared/designSystem';

export type DctClockCompositeScalesProps = {
	metricData: MetricHash;
	onClose?: () => void;
};

const DctClockCompositeScales = ({
	metricData,
	onClose,
}: DctClockCompositeScalesProps): JSX.Element => {
	const { t } = useTranslation();
	// When requirements are locked down, we will probably want to break out each of these into their own component
	// Overview
	const compositeDataSet = getCompositeScalesDataSet(metricData, t);
	const defaultDataSetInfo = getDataSetCharacteristics(compositeDataSet);
	const chartConcerns = getConcerningScores(metricData, t);

	// Drawing Efficiency
	const drawingEfficiencyDataSet = getDrawingEfficiencyDataSet(metricData, t);
	const drawingEfficiencyDataSetInfo = getDataSetCharacteristics(
		drawingEfficiencyDataSet
	);

	// Simple & Complex Motor
	const motorDataSet = getMotorDataSet(metricData, t);
	const motorDataSetInfo = getDataSetCharacteristics(motorDataSet);

	// Information Processing
	const informationDataSet = getInformationProcessingDataSet(metricData, t);
	const informationDataSetInfo =
		getDataSetCharacteristics(informationDataSet);

	// Spatial Reasoning
	const spatialReasoningDataSet = getSpatialReasoningDataSet(metricData, t);
	const spatialReasoningDataSetInfo = getDataSetCharacteristics(
		spatialReasoningDataSet
	);

	return (
		<>
			<StyledDataGraphs>
				<StyledCompositeScale data-testid='compositeScalesGraph'>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						dataSet={compositeDataSet}
						concerns={chartConcerns}
						width={946}
						height={346}
						barWidth={94}
						barDistance={100}
						xAxisRange={[0, compositeDataSet.commandClock.length]}
						yAxisRange={[
							defaultDataSetInfo.min,
							defaultDataSetInfo.max,
						]}
						squeezeEdgesX={[-98, 110]}
						slideDataX={-24}
						customYAxisTickValues={defaultDataSetInfo.yTickValues}
						makeResponsive={true}
						hideLegend
					/>
				</StyledCompositeScale>
			</StyledDataGraphs>
			<PrintOnlyBreak />
			<StyledDataGraphs>
				<StyledH4>
					<Trans
						i18nKey={`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.title`}
					/>
				</StyledH4>
				<StyledCompositeScale data-testid='compositeScalesGraph-drawingEfficiency'>
					<CompositeScales
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.drawingEfficiency.chartTitle`}
						dataSet={drawingEfficiencyDataSet}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[
							0,
							drawingEfficiencyDataSet.commandClock.length,
						]}
						yAxisRange={[
							drawingEfficiencyDataSetInfo.min,
							drawingEfficiencyDataSetInfo.max,
						]}
						squeezeEdgesX={[-78, 72]}
						slideDataX={-24}
						customYAxisTickValues={
							drawingEfficiencyDataSetInfo.yTickValues
						}
						makeResponsive={true}
						hideLegend
					/>
				</StyledCompositeScale>
				<StyledCompositeScale data-testid='compositeScalesGraph-motor'>
					<CompositeScales
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.simpleAndComplexMotor.chartTitle`}
						dataSet={motorDataSet}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[0, motorDataSet.commandClock.length]}
						yAxisRange={[
							motorDataSetInfo.min,
							motorDataSetInfo.max,
						]}
						squeezeEdgesX={[-78, 72]}
						slideDataX={-24}
						customYAxisTickValues={motorDataSetInfo.yTickValues}
						makeResponsive={true}
						hideLegend
					/>
				</StyledCompositeScale>
				<StyledCompositeScale data-testid='compositeScalesGraph-informationProcessing'>
					<CompositeScales
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.informationProcessing.chartTitle`}
						dataSet={informationDataSet}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[0, informationDataSet.commandClock.length]}
						yAxisRange={[
							informationDataSetInfo.min,
							informationDataSetInfo.max,
						]}
						squeezeEdgesX={[-72, 72]}
						slideDataX={-24}
						customYAxisTickValues={
							informationDataSetInfo.yTickValues
						}
						makeResponsive={true}
						hideLegend
					/>
				</StyledCompositeScale>
				<StyledCompositeScale data-testid='compositeScalesGraph-spatialReasoning'>
					<CompositeScales
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.spatialReasoning.chartTitle`}
						dataSet={spatialReasoningDataSet}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[
							0,
							spatialReasoningDataSet.commandClock.length,
						]}
						yAxisRange={[
							spatialReasoningDataSetInfo.min,
							spatialReasoningDataSetInfo.max,
						]}
						squeezeEdgesX={[-120, 98]}
						slideDataX={-24}
						customYAxisTickValues={
							spatialReasoningDataSetInfo.yTickValues
						}
						makeResponsive={true}
					/>
				</StyledCompositeScale>
				<StyledGlossary data-testid='compositeScalesGlossary'>
					{glossary.map(({ term, definition }, idx) => {
						return (
							<StyledGlossaryRow key={idx}>
								<StyledTerm>
									<Trans i18nKey={term} />
								</StyledTerm>
								<StyledDescription>
									<Trans i18nKey={definition} />
								</StyledDescription>
							</StyledGlossaryRow>
						);
					})}
				</StyledGlossary>
				{onClose ? (
					<StyledButtonWrapper>
						<ButtonSm
							text={t`web.report.DCTClock.subscore.info.compositeScalesModal.closeButton.text`}
							width='158px'
							onClick={onClose}
						/>
					</StyledButtonWrapper>
				) : undefined}
			</StyledDataGraphs>
		</>
	);
};

const StyledCompositeScale = styled.div`
	margin-bottom: 15px;
	@media print {
		page-break-inside: avoid;
		height: 300px;
		margin-bottom: 40px;
	}
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: space-around;
`;

const StyledDataGraphs = styled.div(
	({ theme: { spacing } }) => `
	@media print {
		padding:${spacing.lg};
	}
`
);

const StyledH4 = styled(H4)(
	({ theme: { spacing } }) => `
    margin: 0 ${spacing.lg} ${spacing.lg} 0;
	@media print {
		margin-bottom: ${spacing.xs};
	}
	`
);

const StyledGlossary = styled.div(
	({ theme: { spacing, color } }) => `
    max-width: 1000px;
    border-top: 1px solid ${color.tableBorder};
    padding-top: ${spacing.xl};
    @media print {
      display: none;
    }
`
);
const StyledGlossaryRow = styled.span(
	({ theme: { color, spacing } }) => `
    display: flex;
    flex-direction: column;
    color: ${color.bodyTextSecondary};
    margin: ${spacing.sm} 0;
`
);
const StyledTerm = styled.p(
	({ theme: { weight, fontSize, color } }) => `
    font-weight: ${weight.medium};
    font-size: ${fontSize._12};
    text-transform: uppercase;
    color: ${color.bodyTextSecondary};
    margin-bottom: 0;
`
);
const StyledDescription = styled.p(
	({ theme: { color, fontSize } }) => `
    color: ${color.bodyTextSecondary};
    margin-bottom: 0;
    font-size: ${fontSize._12};
`
);

const PrintOnlyBreak = styled.div`
	display: none;
	@media print {
		width: 100%;
		display: block;
		page-break-before: always;
	}
`;
export { DctClockCompositeScales };
