import styled from 'styled-components';
import { P1 } from '../../../../../designSystem/Text';

type RankedAndRatedAnswersSubHeadingProps = {
	subheadingText: string;
};

function TableSubHeading(props: RankedAndRatedAnswersSubHeadingProps) {
	return (
		<StyledP1SecondaryText data-testid={'subheading-text'}>
			{props?.subheadingText}
		</StyledP1SecondaryText>
	);
}

export { TableSubHeading };

const StyledP1SecondaryText = styled(P1)(
	({ theme: { color } }) => `
    color: ${color.bodyTextSecondary};
`
);
