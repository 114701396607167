import { theme } from '../../../../providers/styleProvider';
import { DualColor } from '../types';

export function getRecallSubscoreColor(score: number): DualColor {
	const color: DualColor = {
		background: theme.colors.gray_80,
		text: theme.colors.gray_40,
	};
	handleDCRSubscore(score, {
		low: () => {
			color.background = theme.colors.orange_lightest;
			color.text = theme.color.textAlert;
		},
		moderate: () => {
			color.background = theme.colors.yellow_lightest;
			color.text = theme.colors.yellow_darkest;
		},
		high: () => {
			color.background = theme.colors.green_lightest;
			color.text = theme.colors.green_darkest;
		},
	});
	return color;
}

export function handleDCRSubscore(
	score: number,
	callback: {
		low(): void;
		moderate(): void;
		high(): void;
	}
) {
	if (inRange(score, 0, 1)) return callback.low();
	if (score === 2) return callback.moderate();
	if (score === 3) return callback.high();
}

function inRange(value: number, from: number, to: number): boolean {
	return value >= from && value <= to;
}
