import { Flex } from '@mantine/core';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { theme } from '../../../../../../providers/styleProvider';
import { RadioButton } from '../../../../../designSystem/Button/RadioButton';

type AnswerProps = {
	isSelected?: boolean;
	labelText: string;
	isEmphasized?: boolean;
	width?: string;
};

function Answer({
	isSelected = false,
	labelText,
	isEmphasized = false,
	width = '100%',
}: AnswerProps) {
	const highlightColor = isSelected ? theme.colors.gray_80 : '';
	const borderColor = isSelected ? theme.colors.gray_60 : '';
	const borderWidth = isSelected ? 1 : 0;
	const labelStyle: CSSProperties = isEmphasized
		? { fontStyle: 'italic', color: theme?.color?.bodyText }
		: {};

	return (
		<Flex
			data-testid='answer'
			direction={'row'}
			style={{
				width,
				paddingTop: theme?.spacing?.xs,
				paddingBottom: theme?.spacing?.xs,
				backgroundColor: highlightColor,
				alignContent: 'center',
				alignItems: 'center',
				border: `${borderWidth}px solid ${borderColor}`,
			}}>
			<CenteredDiv>
				<RadioButton
					testId={'answer-radio-button'}
					checked={isSelected}
					disabled={!isSelected}
					color='gray'
					labelStyle={labelStyle}
					label={labelText}
				/>
			</CenteredDiv>
		</Flex>
	);
}

export { Answer };

const CenteredDiv = styled.div(
	() => `
    display: flex;
    justify-content: center;
    align-items: center; 
  `
);
