/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

import { dctActions } from './actions';
//################################################################################
//DOM utilities
//################################################################################
export const dctDOM = new (function () {
	//########################################
	//Variables
	//########################################

	//Resize
	var RESIZE_DEBOUNCE_DELAY = 128;
	var resize_timer = null;
	var resize_last_width = document.body.clientWidth;
	var resize_last_height = document.body.clientHeight;

	//Local variables
	this.global_body_classes = [];

	//########################################
	//Private member functions
	//########################################

	//Resize event occurred
	function resize_event() {
		//Timer is off
		resize_timer = null;

		//Sizes
		var old_width = resize_last_width;
		var old_height = resize_last_height;
		resize_last_width = document.documentElement.clientWidth;
		resize_last_height = document.documentElement.clientHeight;

		//Events
		var either = false;
		if (old_width !== resize_last_width) {
			dctActions.triggerEventListener('window-resize-width');
			either = true;
		}
		if (old_height !== resize_last_height) {
			dctActions.triggerEventListener('window-resize-height');
			either = true;
		}
		if (either) {
			dctActions.triggerEventListener('window-resize');
		}
	}

	//Resize event occurred, but debounce it
	function resize_event_debounce() {
		if (!resize_timer)
			resize_timer = setTimeout(resize_event, RESIZE_DEBOUNCE_DELAY);
	}

	//########################################
	//Public member functions
	//########################################

	//Get the X/Y coordinates of an event relative to an element
	this.mouseEventToRelativeXY = function (
		arg_bounding_client_rect,
		arg_event
	) {
		return {
			x:
				arg_event.pageX -
				(arg_bounding_client_rect.left +
					(window.scrollX || window.pageXOffset)),
			y:
				arg_event.pageY -
				(arg_bounding_client_rect.top +
					(window.scrollY || window.pageYOffset)),
		};
	};

	//Find an element by tag name upwards through the DOM
	this.findTagUpwards = function findTagUpwards(arg_start, arg_tagname) {
		for (var t = arg_start; t && t !== document; t = t.parentNode) {
			if (t.tagName === arg_tagname) return t;
		}
		return null;
	};

	//Find an element by class name upwards through the DOM
	this.findClassUpwards = function findClassUpwards(
		arg_start,
		arg_classname
	) {
		for (var t = arg_start; t && t !== document; t = t.parentNode) {
			if (t.classList.contains(arg_classname)) return t;
		}
		return null;
	};

	//Find an attribute upwards through the DOM
	this.findAttributeUpwards = function getAttributeUpwards(
		arg_start,
		arg_attrname
	) {
		for (var t = arg_start; t && t !== document; t = t.parentNode) {
			if (t.hasAttribute(arg_attrname))
				return t.getAttribute(arg_attrname);
		}
		return null;
	};

	//########################################
	//Initialization
	//########################################

	//On resize
	window.addEventListener('resize', resize_event_debounce, false);

	//IE detection - used for style only!
	if (navigator.userAgent.indexOf('Trident') !== -1)
		this.global_body_classes.push('browser_Trident');
	if (navigator.appVersion.indexOf('MSIE 10.0') !== -1)
		this.global_body_classes.push('browser_IE10');

	//Mac detection - for function key representations
	if (
		navigator.platform === 'Mac68K' ||
		navigator.platform === 'MacPPC' ||
		navigator.platform === 'MacIntel'
	)
		this.global_body_classes.push('mac_platform');

	//Remove only-after-load load time animation protectors
	window.addEventListener('load', function () {
		var load_elements = document.querySelectorAll('.only_after_load');
		for (var i = 0; i < load_elements.length; i++)
			load_elements[i].classList.remove('only_after_load');
	});

	//########################################
	//Tiny browser polyfills
	//########################################
	if (typeof NodeList.prototype.forEach === 'undefined')
		NodeList.prototype.forEach = Array.prototype.forEach;
})();
