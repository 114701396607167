// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const WarningRound = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 75 75'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='WarningRound-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'WarningRound'}</title>
			<path
				clipRule='evenodd'
				d='M72.5 37.5C72.5 56.83 56.83 72.5 37.5 72.5C18.17 72.5 2.5 56.83 2.5 37.5C2.5 18.17 18.17 2.5 37.5 2.5C56.83 2.5 72.5 18.17 72.5 37.5ZM75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5ZM41.2972 26.0213C41.2972 25.3836 41.1555 24.8169 40.943 24.2501C40.2345 22.6206 38.4633 21.7705 36.8339 22.0538C35.1335 22.5498 34.0708 24.1084 34.0708 26.0213C34.1417 27.2252 34.2125 28.3584 34.2833 29.4915L34.2834 29.4927C34.3896 31.6531 34.5136 33.8135 34.6375 35.9738L34.6376 35.9752C34.7616 38.136 34.8856 40.2969 34.9919 42.4577C35.0627 43.9454 36.1962 45.0082 37.684 45.0082C39.1718 45.0082 40.3053 43.8745 40.3762 42.3867C40.3762 41.4658 40.3762 40.6865 40.447 39.7655C40.5179 38.384 40.6064 36.9847 40.695 35.5855C40.7836 34.1863 40.8721 32.7871 40.943 31.4056C41.0847 29.6344 41.2264 27.7924 41.2972 26.0213ZM37.6132 54.785C39.526 54.785 41.2264 53.1555 41.1555 51.2427C41.2972 49.1881 39.5969 47.5586 37.6132 47.5586C35.6295 47.5586 34 49.1881 34 51.1718C34 53.1555 35.6295 54.785 37.6132 54.785Z'
				fill='current'
				fillRule='evenodd'></path>
		</svg>
	);
};
