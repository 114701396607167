import React from 'react';
import { useField } from 'formik';
import { FormElementProps } from '../../../../types';
import { LinusTooltip } from '../../LinusTooltip';
import { useTranslation } from 'react-i18next';
import { PasswordStrengthOverlay } from './PasswordStrengthOverlay';
import { LinusPasswordInput } from './LinusPasswordInput';

export const LinusPasswordInputWithStrength = <T,>({
	name = 'password',
}: FormElementProps<T>): JSX.Element => {
	const [field] = useField(name);
	const { t } = useTranslation();
	return (
		<LinusTooltip
			overlay={<PasswordStrengthOverlay value={field.value} />}
			placement='topLeft'
			trigger='focus'
			overlayStyle={overlayStyle}
			tooltipIcon={
				<div>
					<LinusPasswordInput
						data-id='login-password-input'
						name='password'
						label={t`web.shared.password`}
					/>
				</div>
			}
		/>
	);
};

const overlayStyle: React.CSSProperties = {
	width: '260px',
	height: '92px',
};
