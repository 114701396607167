import { GetPaginatedUsersInput } from '.';

export {
	FeatureType,
	GetUsersSortField,
	OperationToken,
	DefaultApi as OrganizationService,
	Configuration as OrganizationServiceConfiguration,
	OrganizationType,
	UserOrganizationsRoleStatus,
	UserStatus,
	ValueListType,
	VisitTypeStatus,
	GetUsersFilterField,
	GetUsersFilterOperator,
	GetUsersLogicalOperator,
} from '@lh/eng-platform-organization-service-rest-client';

export type {
	DefaultApiGetUsersRequest as GetPaginatedUsersInput,
	DefaultApiGetRolesByOrgIdRequest as GetRolesByOrgIdInput,
	GetUsersSortOrder,
	DefaultApiInviteUserRequest as InviteUserInput,
	Organization,
	User as OrganizationUser,
	PaginatedUsersV2 as PaginatedUsers,
	Role,
	DefaultApiUpdateUserRequest as UpdateOrgUserInput,
	DefaultApiUpdateUserOrganizationsRoleStatusRequest as UpdateOrgUserStatusInput,
	ValueListItem,
	VisitType,
} from '@lh/eng-platform-organization-service-rest-client';

export type GetUsersFilterOptions = {
	filterField: GetPaginatedUsersInput['filterField'];
	filterOperator: GetPaginatedUsersInput['filterOperator'];
	filterValue: GetPaginatedUsersInput['filterValue'];
	logicalOperator: GetPaginatedUsersInput['logicalOperator'];
};

export type GetUsersSortOptions = {
	sortField: GetPaginatedUsersInput['sortField'];
	sortOrder: GetPaginatedUsersInput['sortOrder'];
};
