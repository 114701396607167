import {
	RemoteAssessmentsService,
	RAConfiguration,
} from 'api/remote-assessments';

import { useAuthStore } from 'store';
import { config } from '../config';

export async function getRemoteAssessmentsService() {
	try {
		const bearerToken = await useAuthStore?.getState()?.getBearerToken();
		return new RemoteAssessmentsService(
			new RAConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${bearerToken}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
