import { Buffer } from 'buffer';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import {
	useFinishSignupMutation,
	UserStatus,
} from '../../../generated/graphql';
import { t } from '../../../i18n';
import { loginLandingPage } from '../../../providers/routeProvider';
import {
	CreateInvitedAccount,
	createInvitedAccountSchema,
} from '../../../schemas/forms/createInvitedAccountSchema';
import { CurrentUser } from '../../../types';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { PrivacyPolicyLink } from '../../shared/PrivacyPolicyLink';
import { TermsOfUseLink } from '../../shared/TermsOfUseLink';
import { ButtonLg } from '../../shared/designSystem/ButtonLg';
import { Icon } from '../../shared/designSystem/Icon';
import { H2 } from '../../shared/designSystem/TextComponents';
import { getExpiredInvitationMessageEnum } from './CreateAccountForm.helpers';

type FormValues = {
	firstName: string;
	lastName: string;
	email: string;
};

const CreateAccountFormAuth0 = (): JSX.Element => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const initialFormValues = useRef<FormValues>({
		email: '',
		firstName: '',
		lastName: '',
	});

	const { currentUser, setCurrentUser } = useContext(UserContext);

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const [finishSignupMutation, { loading, error: finishSignUpError }] =
		useFinishSignupMutation({
			errorPolicy: 'all',
		});

	const messages = {
		finishSignUpError: t`web.auth.shared.messages.finishSignUpError`,
		invalidInvitationError: t`web.auth.shared.messages.invalidInvitationError`,
	};

	const params = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);

	const payloadHex = params.has('payload')
		? params.get('payload')
		: undefined;

	const payload = payloadHex
		? JSON.parse(Buffer.from(payloadHex, 'hex').toString())
		: undefined;

	const organizationDisplayName = currentUser.organizationName?.length
		? currentUser?.organizationName
		: payload?.orgName;

	if (payload && currentUser?.id === '') {
		initialFormValues.current.email = payload.email;
		initialFormValues.current.firstName = payload.firstName;
		initialFormValues.current.lastName = payload.lastName;
	} else {
		initialFormValues.current.email = currentUser.email;
		initialFormValues.current.firstName = currentUser.firstName;
		initialFormValues.current.lastName = currentUser.lastName;
	}

	useEffect(() => {
		// See if user has the payload for Auth0 invite or not.
		// If not, redirect to Login page for expired invitations
		// https://linushealth.atlassian.net/browse/PRD-949
		if (currentUser.id === '' && !params.get('payload')) {
			navigate('/auth/login', { replace: true });
		}
	}, [navigate, params, currentUser.id]);

	if (finishSignUpError && !serverSideMessage) {
		setServerSideMessage(
			payload
				? getExpiredInvitationMessageEnum(theme)
				: messageEnum.error(
						messages.finishSignUpError,
						icons.AlertShieldSolid
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  )
		);
	}

	const onSubmit = async (
		values: CreateInvitedAccount,
		{ setSubmitting }: FormikHelpers<CreateInvitedAccount>
	) => {
		// Resetting the error state on submit click
		setServerSideMessage(undefined);
		try {
			// complete sign up
			const newUser = await finishSignupMutation({
				variables: {
					FinishSignupInput: {
						email: values.email,
						firstName: values.firstName,
						lastName: values.lastName,
					},
				},
			});
			const signedUpUser = newUser?.data?.finishSignup
				?.currentUser as CurrentUser;
			if (signedUpUser) {
				// Deprecated; currently leaving around to ensure interface compliance
				setCurrentUser({
					...currentUser,
					...signedUpUser,
					userStatus: UserStatus.Active,
				});
				const path = loginLandingPage(signedUpUser);
				navigate(path);
			} else {
				if (newUser.errors) {
					newUser.errors.forEach((el) =>
						console.error('ERROR: ', el.message)
					);
				}
				setServerSideMessage(
					messageEnum.error(
						messages.finishSignUpError,
						icons.AlertShieldSolid
					)
				);
			}
		} catch (error) {
			console.error(error);
		}
		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				firstName: initialFormValues.current.firstName,
				lastName: initialFormValues.current.lastName,
				email: initialFormValues.current.email,
			}}
			validationSchema={createInvitedAccountSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid }) => {
				return (
					<StyledContainer>
						<StyledMessageContainer>
							{!serverSideMessage && (
								<>
									<Icon
										icon={icons.InviteOutlined}
										iconWidth={40}
										iconHeight={40}
										color={theme.color.iconInvite}
										title={t`web.auth.forms.inviteMail`}
									/>
									<StyledInviteMessage>
										<Trans
											i18nKey='web.auth.shared.fragments.invitedToJoin'
											values={{
												orgName:
													organizationDisplayName,
											}}
											components={[<strong />]}
										/>
									</StyledInviteMessage>
								</>
							)}
						</StyledMessageContainer>
						<H2>
							<>{t`web.auth.shared.fragments.confirmInformation`}</>
						</H2>
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='360px'
									name='firstName'
									data-id='setup-new-pass-first-name'
									label={t`web.shared.firstName`}
								/>
								<LinusInput
									width='360px'
									name='lastName'
									data-id='setup-new-pass-last-name'
									label={t`web.shared.lastName`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									data-id='setup-new-pass-email'
									name='email'
									type='email'
									width='100%'
									disabled
									label={t`web.shared.emailAddress`}
								/>
							</StyledRow>
							<InfoMessage
								showIf={!!serverSideMessage}
								messageEnum={serverSideMessage}
							/>
							<StyledAgreementContainer>
								<StyledAgreementText>
									<Trans
										i18nKey='web.auth.shared.fragments.continueToAgree'
										components={[
											<PrivacyPolicyLink
												text={t`web.shared.LHPP`}
												underline={false}
											/>,
											<TermsOfUseLink
												underline={false}
											/>,
										]}
									/>
								</StyledAgreementText>
							</StyledAgreementContainer>
							<StyledSubmitButtonWrapper>
								<ButtonLg
									dataId='createAccountBtn'
									disabled={!isValid || isSubmitting}
									text={t`web.auth.forms.continue`}
									type='submit'
									width='360px'
									primary={true}
									loading={loading}
								/>
							</StyledSubmitButtonWrapper>
						</StyledForm>
					</StyledContainer>
				);
			}}
		</Formik>
	);
};

export { CreateAccountFormAuth0 };

const StyledContainer = styled.div`
	width: 100%;
	height: 760px;
	padding: 56px 120px;
	transition: 0.4s ease all;
	text-align: center;
	justify-content: space-between;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const StyledMessageContainer = styled.div(
	({ theme: { spacing } }) => `
        display: flex;
        align-items: center;
		margin: ${spacing.xl} 0;
    `
);

const StyledForm = styled(Form)(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);
const StyledAgreementContainer = styled.div`
	display: flex;
`;

const StyledAgreementText = styled.span(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);

const StyledSubmitButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.md} 0;
	display: flex;
	justify-content: center;
	`
);

const StyledInviteMessage = styled.span(
	({ theme: { spacing } }) => `
	text-align: center;
	margin: 0 0 0 ${spacing.md};
	`
);
