import { useMemo } from 'react';
import { FeatureFlag } from './constants';
import { parseEnvForAttributes } from './parseEnvForAttributes';
import { useFeatureFlagsContext } from './useFeatureFlagsContext';
import { isEmpty } from 'lodash';

export function useFeatureFlag(flag: FeatureFlag) {
	const client = useFeatureFlagsContext();
	// eslint-disable-next-line no-restricted-globals
	const attributes = parseEnvForAttributes(location.host);

	return useMemo(() => {
		if (isEmpty(client)) return false;

		const treatment = client?.getTreatment(flag, attributes);
		return treatment === 'on';
	}, [attributes, client, flag]);
}
