import { DcrMetricItemKeys, DcrSubscoreData } from '../types';
import { DcrReportProps } from '../DcrReport';
import { getRecallSubscoreColor } from './dcrSubscoreColorHelpers';
import { DcrScores } from '../constants/scores';

const getImmediateRecallSubscores = (
	data: DcrReportProps['data']
): DcrSubscoreData['recall']['immediate'] => {
	const immediateRecallMetricItems = data?.recall?.immediate?.metricItems;
	// Calculate Immediate Recall Score from metrics
	const immediateRecallSegmentName =
		data?.recall?.immediate?.segment?.name ?? 'Immediate Recall';
	const totalImmediateRecallScore = Number(
		immediateRecallMetricItems?.[DcrMetricItemKeys.EXPECTED_WORDS_COUNT]
			?.value ?? DcrScores.TOTAL_RECALL_SCORE
	);
	const achievedImmediateRecallScore = Number(
		immediateRecallMetricItems?.[DcrMetricItemKeys.CORRECT_WORDS_COUNT]
			?.value ?? DcrScores.MINIMUM_ACHIEVABLE_RECALL_SCORE
	);
	const immediateRecallScoreColor = getRecallSubscoreColor(
		achievedImmediateRecallScore
	);
	return {
		score: {
			achieved: achievedImmediateRecallScore,
			total: totalImmediateRecallScore,
		},
		scoreColor: immediateRecallScoreColor,
		segmentName: immediateRecallSegmentName,
	};
};

const getDelayedRecallSubscores = (
	data: DcrReportProps['data']
): DcrSubscoreData['recall']['delayed'] => {
	const delayedRecallMetricItems = data?.recall?.delayed?.metricItems;
	// Calculate Delayed Recall Score from metrics
	const delayedRecallSegmentName =
		data?.recall?.delayed?.segment?.name ?? 'Delayed Recall';
	const totalDelayedRecallScore = Number(
		delayedRecallMetricItems?.[DcrMetricItemKeys.EXPECTED_WORDS_COUNT]
			?.value ?? DcrScores.TOTAL_RECALL_SCORE
	);
	const achievedDelayedRecallScore = Number(
		delayedRecallMetricItems?.[DcrMetricItemKeys.CORRECT_WORDS_COUNT]
			?.value ?? DcrScores.MINIMUM_ACHIEVABLE_RECALL_SCORE
	);
	const delayedRecallScoreColor = getRecallSubscoreColor(
		achievedDelayedRecallScore
	);
	return {
		score: {
			achieved: achievedDelayedRecallScore,
			total: totalDelayedRecallScore,
		},
		scoreColor: delayedRecallScoreColor,
		segmentName: delayedRecallSegmentName,
	};
};

export const getDcrSubscoresData = (
	data: DcrReportProps['data']
): DcrSubscoreData => {
	return {
		recall: {
			immediate: getImmediateRecallSubscores(data),
			delayed: getDelayedRecallSubscores(data),
		},
		clock: data?.clock,
	};
};
