import { InvalidCredentialsError } from '@lh/eng-shared-auth';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendEventData, updateAmplitudeWithProps } from 'analytics/amplitude';
import { config } from 'config';
import { UserContext } from '../../../context/UserContext';
import { messageEnum } from 'enums/messageEnum';
import { RouteEnum, routeEnum as _routeEnum } from '../../../enums/routeEnum';
import { FeatureFlag, useFeatureFlag } from 'features/feature-flags';
import { AnalyticsAction, UserStatus } from 'generated/graphql';
import { AuthProvider } from '../../../providers/AuthProvider';
import { loginLandingPage } from '../../../providers/routeProvider';
import {
	getModel,
	LoginModel,
	validationSchema,
} from '../../../schemas/forms/loginSchema';
import { CurrentUser } from 'types';
import { ButtonLg, H2 } from 'components/shared/designSystem';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { LinusPasswordInput } from '../../shared/Forms/Components/LinusPasswordInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { UserOnlyContext } from '../../../providers/UserProvider/UserOnlyContext';
import { isEmpty } from 'lodash';

const LoginForm = ({ pathname }: { pathname?: string }): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const isAuth0Provider = AuthProvider.isAuth0;
	const isUniversalLoginEnabled = useFeatureFlag(FeatureFlag.Auth0Universal);
	const isAuth0Universal = isAuth0Provider && isUniversalLoginEnabled;
	const { currentUser } = useContext(UserContext);

	const { login } = useContext(UserOnlyContext);

	const [error, setError] = useState(false);
	const { t } = useTranslation();

	const redirect = useCallback(
		(user: CurrentUser) => {
			const enumeration = _routeEnum(user, isAuth0Universal);
			// Here location.state has no shape ( it's defined by the navigation ).  We are casting
			// it to what we hope to find in it.  Then trying to access that item.  If it's not there
			// then we check to see if the pathname was passed in to the form.  This is what will most often
			// happens when a user is loggedout and tries to access a page
			let path = (location.state as { from: string })?.from || pathname;
			// If path does not exist at this point, then we default to the login landing page for the specific org.
			path = path || loginLandingPage(user);
			// Check the route to see if it is available to this user or not.
			const isRouteFound = enumeration.find((route: RouteEnum) => {
				return path?.toLowerCase().includes(route.path);
			});
			if (
				user.userStatus === UserStatus.Invited &&
				config.preferredUserProvider === 'auth0'
			) {
				const path = `/auth/finish-signup`;
				navigate(path);
			} else {
				navigate(isRouteFound ? path : '/', { replace: true });
			}
		},
		[location, navigate, isAuth0Universal, pathname]
	);

	useEffect(() => {
		if (isEmpty(currentUser)) return;

		updateAmplitudeWithProps(currentUser);
		sendEventData({
			eventType: AnalyticsAction.StartedSession,
		});
		redirect(currentUser);
	}, [currentUser, currentUser.id, redirect]);

	const onSubmit = async (
		values: LoginModel,
		{ setSubmitting }: FormikHelpers<LoginModel>
	) => {
		// resets the error state on click of submit
		setError(false);
		setSubmitting(true);
		try {
			await login(values.username, values.password);

			// TODO: May need to move /user-not-found handling up?
			// if (!currentUser && isLoggedIn) {
			// 	logout();
			// 	navigate(`/user-not-found`);
			// 	return;
			// }
			// if (!currentUser) {
			// 	setError(true);
			// 	return;
			// }
			//
			// updateAmplitudeWithProps(currentUser);
			// sendEventData({
			// 	eventType: AnalyticsAction.StartedSession,
			// });
			// return redirect(currentUser);
		} catch (err: unknown) {
			if (err instanceof InvalidCredentialsError) setError(true);
		}

		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledH2>{t`web.login.title`}</StyledH2>
						<LinusInput
							data-testid='login-email-input'
							name='username'
							type='email'
							label={t`web.shared.emailAddress`}
						/>
						<LinusPasswordInput
							data-testid='login-password-input'
							name='password'
							label={t`web.shared.password`}
						/>
						<StyledForgotPasswordLink
							data-testid='login-forgot-password-link'
							to='/auth/forgotpassword'>
							{t`web.login.forgotPassword`}
						</StyledForgotPasswordLink>
						<ButtonLg
							disabled={!(isValid && dirty) || isSubmitting}
							type='submit'
							width='317px'
							text={t`web.login.title`}
							primary={true}
							dataTestId='login-submit-button'
						/>

						<StyledInfoMessage>
							<InfoMessage
								data-testid='login-txt-error'
								messageEnum={messageEnum.error(
									t`web.authErrors.invalidCredentials`
								)}
								showIf={error}
							/>
						</StyledInfoMessage>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { LoginForm };

const StyledForm = styled(Form)`
	position: absolute;
	width: 600px;
	height: 608px;
	padding: 64px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: 0.4s ease all;
`;
const StyledForgotPasswordLink = styled(Link)(
	({ theme: { spacing, color } }) => `
	width: 317px;
	color: ${color.link};
	display: flex;
	justify-content: flex-start;
	margin: ${spacing.sm} 0 ${spacing.lg} 0;	
	`
);
const StyledH2 = styled(H2)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.md} 0;	
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	padding-botton: ${spacing.md};
	margin: ${spacing.lg} 0 0 0;
`
);
