// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Skip10sBack = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Skip10sBack-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Skip10sBack'}</title>
			<path
				d='M15.9999 26.6668C18.8491 26.6668 21.5277 25.5573 23.5422 23.5427C25.557 21.528 26.6665 18.8491 26.6665 16.0004C26.6665 13.1516 25.557 10.4725 23.5424 8.45802C21.5277 6.44327 18.8493 5.33374 16.0001 5.33374C12.5118 5.33374 9.11309 7.03583 7.13025 9.77546C6.86463 10.1426 6.9467 10.6556 7.31397 10.9212C7.68107 11.1868 8.1941 11.1046 8.45972 10.7375C10.1393 8.41641 13.0289 6.97466 15.9999 6.97466C20.9765 6.97466 25.0253 11.0234 25.0253 16.0001C25.0253 20.9768 20.9764 25.0255 15.9999 25.0255C11.0232 25.0255 6.97446 20.9766 6.97446 16.0001C6.97446 15.5469 6.60719 15.1795 6.1539 15.1795C5.70076 15.1795 5.33333 15.5468 5.33333 16.0001C5.33333 18.8491 6.44286 21.5279 8.45743 23.5424C10.4722 25.5572 13.151 26.6667 15.9998 26.6667L15.9999 26.6668Z'
				fill='#676671'
			/>
			<path
				d='M7.79475 11.0771H11.8974C12.3505 11.0771 12.718 10.7098 12.718 10.2565C12.718 9.80339 12.3507 9.43596 11.8974 9.43596H8.61531V6.15406C8.61531 5.70092 8.24804 5.3335 7.79475 5.3335C7.34161 5.3335 6.97419 5.70077 6.97419 6.15406V10.2565C6.97436 10.7098 7.34163 11.0771 7.79475 11.0771Z'
				fill='#676671'
			/>
			<path
				d='M10.272 20.333V19.3303H12.3627V13.7623H12.2774L10.5494 15.6077L9.81337 14.9463L11.712 12.8877H13.5467V19.3303H15.36V20.333H10.272ZM19.2625 20.461C18.8003 20.461 18.3985 20.3757 18.0572 20.205C17.7159 20.0272 17.435 19.7748 17.2145 19.4477C16.9941 19.1206 16.8305 18.7188 16.7239 18.2423C16.6172 17.7659 16.5639 17.2219 16.5639 16.6103C16.5639 16.0059 16.6172 15.4655 16.7239 14.989C16.8305 14.5055 16.9941 14.1001 17.2145 13.773C17.435 13.4459 17.7159 13.197 18.0572 13.0263C18.3985 12.8486 18.8003 12.7597 19.2625 12.7597C20.187 12.7597 20.8661 13.0975 21.2999 13.773C21.7408 14.4486 21.9612 15.3943 21.9612 16.6103C21.9612 17.8263 21.7408 18.7721 21.2999 19.4477C20.8661 20.1232 20.187 20.461 19.2625 20.461ZM19.2625 19.437C19.5185 19.437 19.7354 19.3872 19.9132 19.2877C20.0981 19.181 20.2474 19.0317 20.3612 18.8397C20.475 18.6477 20.5568 18.4201 20.6065 18.157C20.6563 17.8868 20.6812 17.5881 20.6812 17.261V15.9597C20.6812 15.6326 20.6563 15.3375 20.6065 15.0743C20.5568 14.8041 20.475 14.573 20.3612 14.381C20.2474 14.189 20.0981 14.0432 19.9132 13.9437C19.7354 13.837 19.5185 13.7837 19.2625 13.7837C19.0065 13.7837 18.7861 13.837 18.6012 13.9437C18.4234 14.0432 18.2777 14.189 18.1639 14.381C18.0501 14.573 17.9683 14.8041 17.9185 15.0743C17.8688 15.3375 17.8439 15.6326 17.8439 15.9597V17.261C17.8439 17.5881 17.8688 17.8868 17.9185 18.157C17.9683 18.4201 18.0501 18.6477 18.1639 18.8397C18.2777 19.0317 18.4234 19.181 18.6012 19.2877C18.7861 19.3872 19.0065 19.437 19.2625 19.437Z'
				fill='#676671'
			/>
		</svg>
	);
};
