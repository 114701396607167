import { isEmpty } from 'lodash';
import { useGetEpsomJsonData } from '../../../../../../../hooks/useGetEpsomJsonData';
import { dateFormatter } from '../../../../../../../stringStrategy/dateFormatStringStrategy';
import { EpsomDataWithoutScores } from '../../../../../../../types/epsomTypes';
import { AssessmentStatuses } from '../../../../../Assessment';
import { FormattedSegmentResult } from '../../../../../common';
import { ParticipantWrittenResponses } from './ParticipantWrittenResponses';
import { AssignmentType } from '@lh/eng-platform-battery-service-rest-client';
import {
	generateEpsomScores,
	mergeEpsomScores,
} from 'components/report/Epsom/common/helpers/epsomScorer';
import { useEffect, useState } from 'react';
import { LoadingDots } from 'components/shared/LoadingDots';

type EpsomProps = {
	segmentResult: FormattedSegmentResult;
	assignmentType: AssignmentType;
};

function EpsomWrittenResponses(props: Readonly<EpsomProps>) {
	const { segmentResult, assignmentType } = props;
	const segmentResultId = segmentResult.id;
	const { loading, data, error } = useGetEpsomJsonData({
		segmentResultId,
	});
	const [epsomDataWithoutScore, setEpsomDataWithoutScore] =
		useState<EpsomDataWithoutScores>();
	useEffect(() => {
		if (data) {
			const epsomDataWithScore = isEmpty(segmentResult.metricItems) //For backwards compat ie: baseline epsom tests from before engine was released
				? generateEpsomScores(data)
				: mergeEpsomScores(data, segmentResult);
			const {
				total: _total,
				ranking: _ranking,
				...epsomDataWithoutScore
			} = epsomDataWithScore;
			setEpsomDataWithoutScore(epsomDataWithoutScore);
		}
	}, [data, segmentResult]);

	if (loading) return <LoadingDots />;

	if (error) return <p>{error + ''}</p>;

	if (!data || !epsomDataWithoutScore) return null;

	return (
		<>
			<AssessmentStatuses
				assessmentStatuses={[
					{
						assessmentName: segmentResult?.segment?.name ?? '',
						dateTaken: segmentResult?.endTime
							? dateFormatter(
									segmentResult?.endTime,
									"MMM d', 'yyyy' at 't"
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  )
							: undefined,
						type: assignmentType,
					},
				]}
			/>
			<ParticipantWrittenResponses
				epsomWrittenResponses={epsomDataWithoutScore}
			/>
		</>
	);
}

export { EpsomWrittenResponses };
