import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FeatureType, OperationToken } from 'api/organization';
import {
	GetPaginatedParticipantsSortOptions,
	ParticipantsSortField,
	ParticipantsSortOrder,
} from 'api/participant';
import { QueryKey } from 'api/query';
import { sendEventData } from '../../../analytics/amplitude';
import { FormElementError } from '../../../components/shared/Forms/Components/FormElementError';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import {
	AnalyticsAction,
	ResearchParticipantResultFragment,
	SortDir,
	useDeleteAssignmentForParticipantMutation,
	useGetAllBatteriesForOrgQuery,
} from '../../../generated/graphql';
import { useGetOrgEntitlements } from '../../../hooks/useGetOrgEntitlements';
import { deepBatteryDataTransformer } from '../../../providers/globalDataProvider/BatteryDataTransformer';
import { GlobalDataContext } from '../../../providers/globalDataProvider/GlobalDataProvider';
import { ParticipantTableData } from '../../../schemas/table/participantSchema';
import { participantTableSchema } from '../../../schemas/table/participantTableSchema';
import { useBreakdownMeasuresStore } from '../../../store';
import { hasAdministratorSegment } from '../../../utils/assignmentRules';
import { SearchProps } from '../../paginatedTableProps';
import { LinusPaginatedDataTable } from '../../shared/DataTable/PaginatedDataTable';
import { Header } from '../../shared/Header';
import { LinusModal } from '../../shared/LinusModal';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { useActiveTableHeader, useSessionIssue } from '../../shared/hooks';
import { AddBatteryForNewParticipantHeader } from '../AddBatteryForNewParticipantHeader/AddBatteryForNewParticipantHeader';
import {
	AddBatteryForParticipant,
	BatteryMakeup,
} from '../AddBatteryForParticipant/AddBatteryForParticipant';
import { AddBatteryForParticipantHeader } from '../AddBatteryForParticipantHeader/AddBatteryForParticipantHeader';
import { AddParticipantForm } from '../AddParticipantForm';
import { RemoveBatteryForParticipant } from '../RemoveBatteryForParticipant';
import { participantStrings } from '../participantStrings';
import { ParticipantsTableData } from './Participants.types';
import { useGetParticipantsTableData } from './useGetParticipantsTableData';
import { getLinkByAssignmentId } from 'api/remote-assessments';
import { RemoteLinkViewer } from '../RemoteLinkViewer/RemoteLinkViewer';

const ROWS_PER_PAGE = 10;

const defaultSortOptions = {
	sortField: [ParticipantsSortField.CreatedAt],
	sortOrder: [ParticipantsSortOrder.Desc],
};

const defaultSearchOptions: SearchProps = {
	value: null,
	prop: 'PARTICIPANT_EXTERNAL_ID',
};

const Participants = (): JSX.Element | null => {
	const queryClient = useQueryClient();
	const { activeHeader, setActiveHeader } = useActiveTableHeader();
	const { currentUser } = useContext(UserContext);
	const { orgBatteries } = useContext(GlobalDataContext);
	const navigate = useNavigate();
	const sessionIssuesEnabled = useSessionIssue();
	const theme = useTheme();
	const { t } = useTranslation();
	const { hasFeatureEntitlement } = useGetOrgEntitlements();

	const { reset: resetBreakdownMeasures } = useBreakdownMeasuresStore();

	useEffect(() => {
		resetBreakdownMeasures();
	}, [resetBreakdownMeasures]);

	const [activeData, setActiveData] = useState<ParticipantsTableData>();
	const [isAssignBatteryModalOpen, setIsAssignBatteryModalOpen] =
		useState(false);
	const [isBatteryRemovedModalOpen, setIsBatteryRemovedModalOpen] =
		useState(false);
	const [isRemoteLinkOpen, setRemoteLinkOpen] = useState(false);
	const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false);
	const [isRemoveBatteryModalOpen, setIsRemoveBatteryModalOpen] =
		useState(false);
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState<
		GetPaginatedParticipantsSortOptions | undefined | null
	>(defaultSortOptions);
	const [search, setSearch] = useState<SearchProps>(defaultSearchOptions);
	const [participant, setParticipantInfo] = useState<ParticipantTableData>();

	// Grabs all batteries configured for the org
	const batteryQueryResponse = useGetAllBatteriesForOrgQuery({
		variables: {
			orgId: currentUser.organizationId,
		},
	});
	const dividedBatteries: BatteryMakeup = useMemo(() => {
		const resp: BatteryMakeup = {
			clinic: [],
			remote: [],
		};
		const batteryData = deepBatteryDataTransformer(
			(
				batteryQueryResponse?.data?.organization?.batteries.nodes || []
			).map(({ id }) => id),
			orgBatteries
		);
		batteryData.forEach((b) => {
			if (b.mobileEnabled) {
				resp.clinic.push(b);
			}
			if (b.webEnabled && !hasAdministratorSegment(b)) {
				resp.remote.push(b);
			}
		});

		return resp;
	}, [batteryQueryResponse, orgBatteries]);

	const { data, totalCount, reloadParticipants, loading, error } =
		useGetParticipantsTableData({
			organizationId: currentUser?.organizationId,
			page: page - 1,
			pageSize: ROWS_PER_PAGE,
			searchValue: search?.value ?? null,
			sort,
		});

	useEffect(() => {
		setActiveData(data);
	}, [data]);

	const hasRemoteAssessmentsFlag = useMemo(
		() => hasFeatureEntitlement(FeatureType.RemoteAssessment),
		[hasFeatureEntitlement]
	);

	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedPatients });
	}, []);

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';
	const [deleteAssignmentForParticipantMutation] =
		useDeleteAssignmentForParticipantMutation({ errorPolicy: 'all' });

	const handleCreatePatient = useCallback(() => {
		sendEventData({ eventType: AnalyticsAction.AddedNewPatient });
		setIsNewPatientModalOpen(false);
		setIsAssignBatteryModalOpen(true);
		reloadParticipants?.();
	}, [reloadParticipants]);

	const handleBatteryClick = useCallback((props: ParticipantTableData) => {
		sendEventData({ eventType: AnalyticsAction.AssignedBattery });
		setParticipantInfo(props);
		setIsAssignBatteryModalOpen(true);
	}, []);

	const handleCancel = useCallback(() => {
		//close everything and reset pt - back to starting point
		setIsNewPatientModalOpen(false);
		setIsAssignBatteryModalOpen(false);
		setIsRemoveBatteryModalOpen(false);
		setIsBatteryRemovedModalOpen(false);
		setParticipantInfo(undefined);
		setRemoteLinkOpen(false);
		reloadParticipants?.();
	}, [reloadParticipants]);

	const handleRemoveBattery = useCallback(
		async (row: ParticipantTableData): Promise<void> => {
			if (sessionIssuesEnabled) {
				setParticipantInfo(row);
				setIsRemoveBatteryModalOpen(true);
			} else {
				const id = row.assignmentInfo?.id;
				// The ID should always be there, this conditional was to make TS happy
				if (!id) return;
				try {
					const response =
						await deleteAssignmentForParticipantMutation({
							variables: {
								DeleteAssignmentInput: {
									id,
								},
								orgId: currentUser.organizationId,
							},
						});
					if (response.data?.deleteAssignment?.success) {
						// Invalidate assignment query since the previously cached data is stale
						await queryClient.invalidateQueries({
							queryKey: [QueryKey.Assignment, row?.id],
						});
						sendEventData({
							eventType: AnalyticsAction.RemovedBattery,
						});
						setParticipantInfo(row);
						setIsBatteryRemovedModalOpen(true);
					}
					reloadParticipants?.();
				} catch (err) {
					Sentry.captureException(err);
				}
			}
		},
		[
			sessionIssuesEnabled,
			deleteAssignmentForParticipantMutation,
			currentUser.organizationId,
			reloadParticipants,
			queryClient,
		]
	);

	const count = activeData?.length || 0;
	const total = totalCount || 0;
	const participantsList = participantTableSchema.mapData(activeData);
	const hasVisitTypesEntitlement = useMemo(
		() => hasFeatureEntitlement(FeatureType.VisitTypes),
		[hasFeatureEntitlement]
	);

	const columns = participantTableSchema.columns(
		dateFormat,
		hasVisitTypesEntitlement,
		[
			{
				key: 'editContactInformation',
				value: t`web.patients.editContactInformation`,
				callback: (row: ParticipantTableData) =>
					navigate(`/participants/${row.id}`),
				operations: [OperationToken.EditParticipant],
			},
			{
				key: 'getRemoteLink',
				value: t`web.patients.getRemoteLink`,
				callback: (row: ParticipantTableData) => {
					setParticipantInfo(row);
					setRemoteLinkOpen(true);
				},
				hide: (row: ParticipantTableData | undefined) => {
					return row?.assignmentInfo?.type !== 'WEB';
				},
				onRender: (row: ParticipantTableData) => () => {
					queryClient.prefetchQuery({
						queryFn: () =>
							getLinkByAssignmentId({
								organizationId: currentUser.organizationId,
								assignmentId: row.assignmentInfo?.id,
							}),
						staleTime: Infinity,
						queryKey: [QueryKey.RemoteLink, row.assignmentInfo?.id],
					});
				},
				operations: [OperationToken.AssignBattery],
			},
			{
				key: 'removeBattery',
				value: t`web.patients.removeBattery`,
				callback: (row: ParticipantTableData) =>
					handleRemoveBattery(row),

				operations: [OperationToken.DeleteSchedule],
			},
		],
		handleBatteryClick
	);

	const noDataIcon = icons.NoDataUser;

	const notFoundTitle = t('web.shared.search.noMatchFound', {
		entity: t(participantStrings['Participants']).toLowerCase(),
	});
	const notFoundSubtitle = t('web.shared.search.addNewOrChangeSpelling', {
		entity: t(participantStrings['participant']).toLowerCase(),
	});

	const onSort = (dir: SortDir | undefined, prop: string) => {
		if (!dir) {
			setSort(null);
		} else {
			setActiveHeader(prop);
			setSort({
				sortField: [prop as ParticipantsSortField],
				sortOrder: [dir as ParticipantsSortOrder],
			});
		}
	};

	if (error) {
		return <FormElementError>{error?.message}</FormElementError>;
	}

	return (
		<>
			<Header />
			<LinusPaginatedDataTable
				activeHeader={activeHeader}
				title={t(`${participantStrings['Participants']}`)}
				columns={columns}
				tableData={participantsList}
				rowsPerPage={ROWS_PER_PAGE}
				buttonText={t(participantStrings['participantModalTitleAdd'])}
				noDataIcon={noDataIcon}
				searchBarPlaceholder={t(participantStrings.filterPlaceHolder)}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				count={count}
				total={total}
				currentPage={page}
				setCurrentPage={(pageNumber: number) => setPage(pageNumber)}
				loading={loading}
				hasInitialData={false}
				onSort={onSort}
				onFilter={(predicate: string) => {
					setSearch({ ...search, value: predicate });
				}}
				onHeaderButtonClick={() => {
					setIsNewPatientModalOpen(true);
				}}
				operations={[OperationToken.CreateParticipant]}
				buttonIcon={icons.AddUserOutlined}
			/>
			{isNewPatientModalOpen && (
				<LinusModal
					overflow='visible'
					title={t(participantStrings['participantModalTitleAdd'])}
					titleIcon={icons.AddUserSolid}
					titleIconColor={theme.color.iconAddUserSolid}
					onClose={handleCancel}>
					<AddParticipantForm
						onCancel={handleCancel}
						onFinish={handleCreatePatient}
						setParticipant={setParticipantInfo}
					/>
				</LinusModal>
			)}
			{isAssignBatteryModalOpen && participant?.id && (
				<LinusModal
					overflow='visible'
					onClose={handleCancel}
					dataId='patient_added_modal'>
					<AddBatteryForParticipant
						participant={participant}
						onCancel={handleCancel}
						batteries={dividedBatteries}
						renderProps={() =>
							participant?.newPatient ? (
								<AddBatteryForNewParticipantHeader
									hasRemoteAssessmentFlag={
										hasRemoteAssessmentsFlag
									}
								/>
							) : (
								<AddBatteryForParticipantHeader
									hasRemoteAssessmentsFlag={
										hasRemoteAssessmentsFlag
									}
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									externalId={participant.externalId!}
								/>
							)
						}
					/>
				</LinusModal>
			)}
			{isRemoveBatteryModalOpen && participant?.id && (
				<LinusModal
					onClose={handleCancel}
					dataId='remove_battery_modal'>
					<RemoveBatteryForParticipant
						participant={participant}
						onCancel={handleCancel}
					/>
				</LinusModal>
			)}
			{isBatteryRemovedModalOpen && participant?.externalId && (
				<LinusModalDialog
					onClose={handleCancel}
					title={t`web.shared.removeBatteryModal.title`}
					titleIcon={icons.CheckmarkSolid}
					titleIconColor={theme.color.alertSuccess}
					acceptButtonText={t`web.shared.close`}
					acceptButtonCallback={handleCancel}>
					<Trans
						i18nKey='web.shared.removeBatteryModal.batteryRemoved'
						values={{
							firstName: 'Participant',
							lastName: participant?.externalId,
						}}
						components={{
							b: <strong />,
						}}
					/>
				</LinusModalDialog>
			)}
			{isRemoteLinkOpen &&
				participant?.id &&
				participant.assignmentInfo?.batteryDisplayKey && (
					<LinusModal
						overflow='visible'
						onClose={handleCancel}
						dataId='patient_added_modal'>
						<RemoteLinkViewer
							payload={{
								batteryName: t(
									participant.assignmentInfo
										?.batteryDisplayKey
								),
								externalId: participant.externalId ?? '',
								assignmentId:
									participant.assignmentInfo?.id ?? '',
								contactEmail: participant.contactEmail,
							}}
						/>
					</LinusModal>
				)}
		</>
	);
};

export { Participants };
export type { ResearchParticipantResultFragment };
