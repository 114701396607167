import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { Participant } from 'api/participant';

import { UserContext } from '../../../context/UserContext';
import { gender as genderEnum } from '../../../enums/participantEnums/gender';
import { handedness as handednessEnum } from '../../../enums/participantEnums/handedness';
import { FeatureType } from '../../../generated/graphql';
import { H3, P2, Subtitle } from '../../shared/designSystem';

export type ParticipantInfoDetailsProps = {
	participantInfo: Participant;
	toggleEditModal: () => void;
};
const ParticipantInfoDetails = ({
	participantInfo,
	toggleEditModal,
}: ParticipantInfoDetailsProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);

	const {
		externalId,
		birthYear,
		gender,
		contactEmail,
		contactPhone,
		handedness,
		language,
		race: raceList,
		education: educationList,
	} = participantInfo;

	const hasRemoteAssessmentsFlag = currentUser.organizationFeatures?.includes(
		FeatureType.RemoteAssessment
	);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const displayGender = genderEnum.fromValue(gender)?.display;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const displayHandedness = handednessEnum.fromValue(handedness)?.display;

	const displayLanguage =
		currentUser?.organizationParticipantLanguages.fromValue(language);

	const race = raceList?.[0] ?? null;
	const education = educationList?.[0] ?? null;

	return (
		<StyledWrapper
			data-id='participantInfo'
			initial='initial'
			animate='in'
			exit='out'
			variants={participantInfoVariants}
			transition={participantInfoTransition}>
			<StyledHeaderRow>
				<StyledH3 data-id='participantInfoHeader'>
					<Trans i18nKey='web.participant.value' />
					{':'}
					<br />
					<StyledExternalId>{externalId}</StyledExternalId>
				</StyledH3>
				<StyledLink
					data-id='particpantEditInfo'
					onClick={toggleEditModal}>
					<Trans i18nKey='web.patients.info.edit' />
				</StyledLink>
			</StyledHeaderRow>
			<StyledSeparator />
			<StyledList>
				<StyledItem data-id='birthYear'>
					<StyledLabel>
						<Trans i18nKey='web.participant.forms.birthYear' />
					</StyledLabel>
					<StyledText>{birthYear}</StyledText>
				</StyledItem>

				<StyledItem data-id='participantInfoLanguage'>
					<StyledLabel>
						<Trans i18nKey='web.patients.forms.languageLabel' />
					</StyledLabel>
					<StyledText>
						<Trans i18nKey={displayLanguage?.display} />
					</StyledText>
				</StyledItem>

				<StyledItem data-id='participantInfoHandedness'>
					<StyledLabel>
						<Trans i18nKey={'web.patients.forms.handednessLabel'} />{' '}
						<Trans i18nKey={'web.shared.forms.optional'} />
					</StyledLabel>
					<StyledText>
						<Trans i18nKey={displayHandedness} />
					</StyledText>
				</StyledItem>

				<StyledItem data-id='participantInfoEducation'>
					<StyledLabel>
						<Trans
							i18nKey={'web.patients.forms.educationLevelLabel'}
						/>{' '}
						<Trans i18nKey={'web.shared.forms.optional'} />
					</StyledLabel>
					<StyledText>
						{education?.displayKey ? (
							<Trans i18nKey={education?.displayKey} />
						) : (
							education?.display
						)}
					</StyledText>
				</StyledItem>

				<StyledItem data-id='participantInfoGender'>
					<StyledLabel>
						<Trans i18nKey={'web.patients.forms.genderLabel'} />{' '}
						<Trans i18nKey={'web.shared.forms.optional'} />
					</StyledLabel>
					<StyledText>
						<Trans i18nKey={displayGender} />
					</StyledText>
				</StyledItem>

				<StyledItem data-id='participantInfoRace'>
					<StyledLabel>
						<Trans i18nKey='web.patients.forms.raceLabel' />
					</StyledLabel>
					<StyledText>
						{race?.displayKey ? (
							<Trans i18nKey={race?.displayKey ?? ''} />
						) : (
							race?.display
						)}
					</StyledText>
				</StyledItem>

				{hasRemoteAssessmentsFlag && (
					<>
						<StyledItem data-id='participantEmail'>
							<StyledLabel>
								<Trans i18nKey='web.patients.forms.emailLabel' />
							</StyledLabel>
							<StyledText>{contactEmail}</StyledText>
						</StyledItem>
						<StyledItem data-id='participantPhone'>
							<StyledLabel>
								<Trans i18nKey='web.patients.forms.phoneNumber' />
							</StyledLabel>
							<StyledText>{contactPhone}</StyledText>
						</StyledItem>
					</>
				)}
			</StyledList>
		</StyledWrapper>
	);
};
export { ParticipantInfoDetails };

const StyledWrapper = styled(motion.div)(
	({ theme: { color } }) => `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 32px;
	position: fixed;
	width: 248px;
	height: 100vh;
	overflow: auto;
	right: 0;
	top: 0;
	background: ${color.white};
`
);

const StyledHeaderRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	text-align: center;
`;
const StyledH3 = styled(H3)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.sm};
  whitespace: wrap;
`
);

const StyledList = styled.ul`
	width: 100%;
	position: relative;
`;
const StyledItem = styled.li(
	({ theme: { spacing } }) => `
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 0 ${spacing.lg} 0;
`
);
const StyledLabel = styled(P2)(
	({ theme: { color, spacing } }) => `
	color: ${color.textBlue};margin: 0 0 ${spacing.xs} 0;
	`
);
const StyledText = styled(Subtitle)(
	({ theme: { weight } }) => `
		font-weight: ${weight.medium};
		line-height: 22px;
		margin: 0;
	`
);

const StyledExternalId = styled.span(
	({ theme: { spacing } }) => `
	padding-left: ${spacing.sm};
`
);

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	position: relative;
	width: 100%;
	border-bottom: 1px solid ${color.infoSeparator};
	margin-top: 25px;
	margin-bottom: 25px;
`
);

const StyledLink = styled.a`
	line-height: 19px;
	letter-spacing: 0.5px;
`;

const participantInfoVariants = {
	initial: {
		opacity: 0.25,
		right: '-100px',
	},
	in: {
		opacity: 1,
		right: '0px',
	},
	out: {
		opacity: 0.25,
		right: '-100px',
	},
};

const participantInfoTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.5,
};
