import { useContext, useEffect, useState } from 'react';

import {
	VisitType,
	VisitTypeStatus,
	useGetOrganization,
} from 'api/organization';
import { UserContext } from '../context/UserContext';

const useGetActiveVisitTypes = (): VisitType[] => {
	const [visitTypeOptions, setVisitTypeOptions] = useState<VisitType[]>([]);

	const { currentUser } = useContext(UserContext);

	// TODO: Replace with query to the state "store" once we start storing the organization data (including preferences) there.
	// This data would be queried via the cache, so performance should not be a concern here (hopefully)
	const { data: organizationData } = useGetOrganization(
		currentUser?.organizationId
	);
	useEffect(() => {
		const visitTypes = organizationData?.preferences?.visitTypes ?? [];
		const activeVisitTypes = visitTypes?.filter(
			(visitType) => visitType?.status === VisitTypeStatus.Active
		);
		setVisitTypeOptions(activeVisitTypes);
	}, [organizationData]);

	return visitTypeOptions;
};

export { useGetActiveVisitTypes };
