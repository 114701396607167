/* eslint-disable no-mixed-spaces-and-tabs */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Caption, P1, P2 } from '../../../../../components/designSystem/Text';
import { LinusModal } from '../../../../shared/LinusModal';
import {
	getAmyloidColorForInterpretation,
	getAmyloidInterpretationTransKeyByValue,
} from '../../helpers/amyloidHelpers';
import { AmyloidInterpretation } from '../../types';
import { AmyloidLikelihoodDetails } from './AmyloidLikelihoodDetails';

type AmyloidLikelihoodWidgetProps = {
	isUnanalyzable?: boolean;
	interpretationText?: AmyloidInterpretation;
};

export const AmyloidLikelihoodWidget = (
	props: AmyloidLikelihoodWidgetProps
) => {
	const { t } = useTranslation();
	const [isAmyloidDetailsModalVisible, setIsAmyloidDetailsModalVisible] =
		useState<boolean>(false);

	// Set up display keys

	const labelTitleText = t('web.report.amyloidLikelihood.labelText');

	const interpretationTextValue =
		props?.interpretationText ?? AmyloidInterpretation.UNANALYZABLE;

	const interpretationTextSecondaryTransKey = props?.isUnanalyzable
		? 'web.report.amyloidLikelihood.interpretationRanges.unanalyzable.rangeText'
		: 'web.report.amyloidLikelihood.interpretationRanges.rangeTextCommon';

	const linkText = t(
		'web.report.amyloidLikelihood.modal.linkText'
	)?.toUpperCase();

	const modalHeading = t('web.report.amyloidLikelihood.modal.title');

	const interpretationTextTransKey = useMemo(
		() =>
			props?.isUnanalyzable
				? AmyloidInterpretation.UNANALYZABLE
				: getAmyloidInterpretationTransKeyByValue(
						interpretationTextValue
				  ),
		[interpretationTextValue, props?.isUnanalyzable]
	);

	// Set up text colors
	const colorsByInterpretation = useMemo(
		() =>
			getAmyloidColorForInterpretation(
				interpretationTextValue,
				props?.isUnanalyzable
			),
		[interpretationTextValue, props?.isUnanalyzable]
	);

	return (
		<Container data-testid='amyloid-widget-container'>
			{isAmyloidDetailsModalVisible && (
				<LinusModal
					title={modalHeading}
					onClose={() => setIsAmyloidDetailsModalVisible(false)}>
					<AmyloidLikelihoodDetails
						closeButton={{
							text: t`web.dcrReport.overview.scoringDetails.modal.closeButton.content`,
							onClick: () =>
								setIsAmyloidDetailsModalVisible(false),
						}}
					/>
				</LinusModal>
			)}
			<Row>
				<LabelText data-testid={`amyloid-label-text`}>
					{labelTitleText}
				</LabelText>
				<ProbabilityTag
					style={{
						backgroundColor: colorsByInterpretation?.background,
					}}>
					<ProbabilityTagText
						data-testid={`amyloid-probability-text`}
						style={{
							color: colorsByInterpretation?.text,
						}}>
						{t(interpretationTextTransKey)}
					</ProbabilityTagText>
				</ProbabilityTag>
				{!props?.isUnanalyzable ? (
					<InterpretationText
						data-testid={`amyloid-interpretation-text`}>
						{t(interpretationTextSecondaryTransKey)}
					</InterpretationText>
				) : (
					<InterpretationTextBold
						data-testid={`amyloid-interpretation-text`}>
						{t(interpretationTextSecondaryTransKey)}
					</InterpretationTextBold>
				)}
			</Row>
			<Link
				onClick={() => setIsAmyloidDetailsModalVisible(true)}
				data-testid={`amyloid-view-details-link`}>
				{linkText}
			</Link>
		</Container>
	);
};

const Container = styled.div(
	({ theme: { spacing } }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing.md};
    flex: 1 0 0;
    align-self: stretch;
	`
);

const Row = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
`;

const LabelText = styled(P1)(
	({ theme: { weight, color } }) => `
	color: ${color.bodyText};
	font-weight: ${weight.semi};
`
);

const ProbabilityTag = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	padding: ${spacing.xs} ${spacing.sm};
	margin-top: -${spacing.xs};
	justify-content: center;
	align-items: center;
	gap: ${spacing.xxs};
	border-radius: ${spacing.sm};
	min-width: 100px;
`
);

const ProbabilityTagText = styled(P1)(
	({ theme: { weight } }) => `
	text-align: center;
	font-weight: ${weight.semi};
`
);

const InterpretationText = styled(P1)(
	({ theme: { weight, color } }) => `
	font-weight: ${weight.regular};
	color: ${color.bodyText};
`
);

const InterpretationTextBold = styled(P2)(
	({ theme: { weight, color } }) => `
	font-weight: ${weight.medium};
	color: ${color.bodyText};
`
);

const Link = styled(Caption)(
	({ theme: { weight, colors } }) => `
	font-weight: ${weight.medium};
	color: ${colors.blue};
	cursor: pointer;
`
);
