import styled, { useTheme } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useField } from 'formik';
import { FormElementProps } from '../../types';
import { icons } from '../../enums/icons';
import { Icon, P2 } from '../shared/designSystem';
import { LinusInput } from '../shared/Forms/Components/LinusInput';

const UserStatusComponent = <T,>({
	name,
}: FormElementProps<T>): JSX.Element => {
	const theme = useTheme();
	const [field] = useField(name);
	const { t } = useTranslation();

	switch (field.value) {
		case 'ACTIVE':
			return (
				<StyledWrapper>
					<StyledStatusText>
						<StyledLabel>
							<Trans i18nKey='web.team.editUserModal.userStatus.statusLabel' />
						</StyledLabel>
						<StyledIcon>
							<Icon
								icon={icons.CheckmarkOutlined}
								color={theme.color.iconUserActiveStatus}
							/>
						</StyledIcon>
						<StyledTextSuccess>
							<Trans i18nKey='web.team.editUserModal.userStatus.active.status' />
						</StyledTextSuccess>
					</StyledStatusText>
					<StyledTextAlert>
						<LinusInput
							name='deactivate'
							type='checkbox'
							height='auto'
							label={t`web.team.editUserModal.userStatus.active.deactivateUser`}
							value='deactivateUser'
							data-id='deactivateUser-checkbox'
						/>
					</StyledTextAlert>
				</StyledWrapper>
			);
		case 'DEACTIVATED':
			return (
				<StyledWrapper>
					<StyledStatusText>
						<StyledLabel>
							<Trans i18nKey='web.team.editUserModal.userStatus.statusLabel' />
						</StyledLabel>
						<StyledIcon>
							<Icon icon={icons.CloseOutlined} />
						</StyledIcon>
						<StyledTextAlert>
							<Trans i18nKey='web.team.editUserModal.userStatus.deactivated.status' />
						</StyledTextAlert>
					</StyledStatusText>
					<StyledTextBlue>
						<LinusInput
							name='activate'
							type='checkbox'
							height='auto'
							label={t`web.team.editUserModal.userStatus.deactivated.activateUser`}
							value='activateUser'
						/>
					</StyledTextBlue>
				</StyledWrapper>
			);
		case 'INVITED':
			return (
				<StyledWrapper>
					<StyledStatusText>
						<StyledLabel>
							<Trans i18nKey='web.team.editUserModal.userStatus.statusLabel' />
						</StyledLabel>
						<StyledIcon>
							<Icon icon={icons.InviteOutlined} />
						</StyledIcon>
						<StyledTextInvited>
							<Trans i18nKey='web.team.editUserModal.userStatus.invited.status' />
						</StyledTextInvited>
					</StyledStatusText>
					<StyledRow>
						<LinusInput
							name='invitedUserActions'
							label={t`web.team.editUserModal.userStatus.invited.resendInvite`}
							value='resendInvitation'
							type='radio'
							height='auto'
						/>
						<LinusInput
							name='invitedUserActions'
							label={t`web.team.editUserModal.userStatus.invited.cancelInvite`}
							value='cancelInvitation'
							type='radio'
							height='auto'
						/>
					</StyledRow>
				</StyledWrapper>
			);

		default:
			return <StyledWrapper />;
	}
};

const StyledLabel = styled.span`
	font-weight: bold;
	margin-right: 10px;
`;
const StyledIcon = styled.span`
	height: 22px;
`;
const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `	width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
margin: ${spacing.sm} 0 0 0;
`
);
const StyledStatusText = styled(P2)`
	display: flex;
	align-items: center;
	margin: 0;
`;
const StyledRow = styled.div(
	({ theme: { color } }) => `
        color: ${color.textBlue};
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
    `
);

const StyledTextAlert = styled.span(
	({ theme: { color } }) => `
		margin-left: 10px;
        color: ${color.textAlert};
    `
);
const StyledTextSuccess = styled.span(
	({ theme: { color } }) => `
		margin-left: 10px;
        color: ${color.textSuccess};
    `
);
const StyledTextBlue = styled.span(
	({ theme: { color } }) => `
        color: ${color.textBlue};
    `
);
const StyledTextInvited = styled.span`
	margin-left: 10px;
`;

export { UserStatusComponent };
