import {
	BatteryResultState,
	GetBatteryResultsByOrganizationFilterField,
	GetBatteryResultsByOrganizationFilterOperator,
	GetBatteryResultsByOrganizationFilterOptions,
	GetBatteryResultsByOrganizationLogicalOperator,
} from 'api/battery';
import { truncateString } from 'utils/stringUtils';
import {
	BatteryResultsTableData,
	isBatteryResultConcerning,
	isBatteryResultProcessing,
} from '../../schemas/table/batteryResultsSchema';
import { MapBatteryResultToTableDataInput } from './BatteryResults.types';

export const getBatteryResultsTableDataFilterOptions = (
	participantIds?: string[]
): GetBatteryResultsByOrganizationFilterOptions => {
	const filterFields: GetBatteryResultsByOrganizationFilterField[] = [];
	const filterOperators: GetBatteryResultsByOrganizationFilterOperator[] = [];
	const logicalOperators: GetBatteryResultsByOrganizationLogicalOperator[] =
		[];
	const filterValues: string[] = [];

	// Apply battery state filter (Default - we'll never get results that are not in the "complete" state in this case)
	filterFields.push(
		GetBatteryResultsByOrganizationFilterField.StatusContextState
	);
	filterOperators.push(GetBatteryResultsByOrganizationFilterOperator.In);
	filterValues.push(
		[
			BatteryResultState.AnalysisComplete,
			BatteryResultState.DataComplete,
			BatteryResultState.MetricsComplete,
			BatteryResultState.ParticipantComplete,
		].join(',')
	);

	// Apply Participant ID filter (if any such list of IDs exists)
	if (participantIds && participantIds?.length > 0) {
		logicalOperators.push(
			GetBatteryResultsByOrganizationLogicalOperator.And
		);
		filterFields.push(
			GetBatteryResultsByOrganizationFilterField.ParticipantId
		);
		filterOperators.push(GetBatteryResultsByOrganizationFilterOperator.In);
		filterValues.push(participantIds.join(','));
	}

	return {
		filterField: filterFields,
		logicalOperator: logicalOperators,
		filterOperator: filterOperators,
		filterValue: filterValues,
	};
};

export const mapBatteryResultToTableData = (
	input: MapBatteryResultToTableDataInput
): BatteryResultsTableData[] => {
	const batteryResults = input?.orgBatteryResults?.results ?? [];
	const data = batteryResults?.map((batteryResult) => {
		const participantId = batteryResult?.participantId;
		const externalId =
			input?.orgParticipants?.results?.find(
				(participant) => participant?.id === participantId
			)?.externalId ?? '';
		const batteryDisplayKey =
			input?.orgBatteries?.find(
				(deepBattery) => deepBattery.id === batteryResult?.batteryId
			)?.displayKey ?? '';
		const proctor = input?.orgUsers?.find(
			(user) => user.id === batteryResult?.proctorId
		);
		const proctorName = truncateString(
			`${proctor?.lastName ?? 'Machine'}, ${
				proctor?.firstName ?? 'User'
			} `,
			20
		);
		const batteryResultTableData: BatteryResultsTableData = {
			id: batteryResult?.id ?? '',
			participantId: participantId,
			externalId: externalId,
			date: batteryResult?.endTime ?? '',
			batteryName: input?.t(batteryDisplayKey),
			notification: isBatteryResultConcerning(batteryResult),
			administrator: proctorName,
			processing: isBatteryResultProcessing(batteryResult),
			visitType: batteryResult?.visitType ?? '',
			delayed:
				batteryResult?.statusContext?.state ===
				BatteryResultState.ProcessingError,
		};
		return batteryResultTableData;
	});
	return data ?? [];
};
