import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import { StyledLinkButton } from '../shared/StyledLinkButton';

const UserNavigationMenu = (): JSX.Element => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { logout } = useContext(UserContext);

	async function handleLogout() {
		await logout();
	}

	function navigateToProfile() {
		navigate(`/profile`);
	}

	return (
		<>
			<StyledControlsContainer>
				<StyledControl
					data-testid='user_nav_controls'
					role='link'
					onClick={navigateToProfile}
					isActive={!!pathname.match(/profile/)}>
					<Trans i18nKey={'web.menuItems.profile'} />
				</StyledControl>
				<StyledControl
					role='link'
					onClick={handleLogout}
					data-id='logout'
					data-testid='logout'>
					<Trans i18nKey={'web.menuItems.logout'} />
				</StyledControl>
			</StyledControlsContainer>
		</>
	);
};

export { UserNavigationMenu };

const StyledControlsContainer = styled.div`
	display: block;
	position: absolute;
	bottom: 50px;
	width: 100%;
	z-index: 1;
`;

type StyledControlProps = {
	isActive?: boolean;
};

const StyledControl = styled(StyledLinkButton)<StyledControlProps>(
	({ isActive, theme: { color, spacing } }) => `
	display: flex;
	align-items: center;
	padding: ${spacing.sm} ${spacing.md} ${spacing.sm} ${spacing.xxxxl};
	width: 100%;
	height: ${spacing.xxxl};
	border-radius: ${spacing.xxl} 0 ${spacing.xxl} ${spacing.xxl};
	color: ${isActive ? color.userNavActiveText : color.userNavText};
	background: ${isActive ? color.userNavActiveBg : color.white};
}

	&:hover {
		color: ${color.userNavHoverText};
		cursor: pointer;
	}
`
);
