import { Chip, theme as Theme, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	ExpandedMeasureCell,
	ExpandedMeasureCellContainer,
	ExpandedMeasureCellContent,
	ExpandedMeasureCellLabel,
	MeasureCell,
	NonAmnesticMeasuresContainer,
	NormalMeasuresContent,
} from '../styles';

export function NonAmnesticMeasures() {
	const { t } = useTranslation();

	return (
		<NonAmnesticMeasuresContainer $backgroundColor={Theme.colors.gray_90}>
			<NormalMeasuresContent data-testid='non-amnestic-measures-content'>
				<MeasureCell>
					<Chip variant='Gray' label='0' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<ExpandedMeasureCellContainer $width='62%'>
					<ExpandedMeasureCellContent data-testid='non-amnestic-measures-expanded'>
						<ExpandedMeasureCell>
							<ExpandedMeasureCellLabel weight='600'>
								4+
							</ExpandedMeasureCellLabel>
						</ExpandedMeasureCell>
						<Typography.P2>
							{t('web.report.cohort.breakdown.impairedMeasures')}
						</Typography.P2>
					</ExpandedMeasureCellContent>
				</ExpandedMeasureCellContainer>
			</NormalMeasuresContent>
		</NonAmnesticMeasuresContainer>
	);
}
