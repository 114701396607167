import React from 'react';
import { DctClockDrawingScore } from '../../../../DctClock';
import { SectionHeading } from '../../shared/SectionHeading';
import styled from 'styled-components';
import { FormattedSegmentResult } from '../../../../common';

type ClockDrawingSubscoreWidgetProps = {
	data: FormattedSegmentResult | undefined;
};

const ClockDrawingSubscoreWidget = (props: ClockDrawingSubscoreWidgetProps) => {
	return (
		<Container>
			<SectionHeading text={'Clock Drawing'} />
			{props?.data ? (
				<DctClockDrawingScore dctClockData={props?.data} />
			) : null}
		</Container>
	);
};

export { ClockDrawingSubscoreWidget };

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
