import React from 'react';
import styled, { css } from 'styled-components';
import { H2, P1 } from './designSystem/TextComponents';
import Modal, { ModalProps as _ModalProps } from 'styled-react-modal';
import { icons } from '../../enums/icons';
import { Icon } from './designSystem/Icon';
import { EnumItem } from '../../enums/enumeration';
import { useTranslation } from 'react-i18next';
import { theme } from '../../providers/styleProvider';

export interface ModalProps {
	children: React.ReactNode;
	overflow?: string;
	onClose?: () => void;
	title?: string;
	titleIcon?: EnumItem;
	titleIconColor?: string;
	subTitle?: string;
	hideCloseButton?: boolean;
	width?: string;
	dataId?: string;
	isThirdPartyManaged?: boolean;
}

const LinusModal = ({
	children,
	overflow = 'auto',
	onClose,
	title,
	titleIcon,
	titleIconColor,
	subTitle,
	hideCloseButton,
	width,
	dataId,
	isThirdPartyManaged,
}: ModalProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<ModalWrap
			overflow={overflow}
			isOpen={true}
			onBackgroundClick={onClose}
			onEscapeKeydown={onClose}>
			<StyledModalWrapper
				width={width}
				data-id={
					dataId
						? dataId
						: title?.toLowerCase().replace(/\s/g, '_') + '_modal'
				}>
				<StyledHeaderSection>
					<StyledTitle>
						{!hideCloseButton && (
							<StyledIconWrapper onClick={onClose}>
								<Icon
									icon={icons.Close}
									title={t`web.shared.closeModal`}
								/>
							</StyledIconWrapper>
						)}
						<StyledTextRow>
							{titleIcon && (
								<StyledTitleIcon>
									<Icon
										icon={titleIcon}
										color={titleIconColor}
										iconWidth={40}
										iconHeight={40}
									/>
								</StyledTitleIcon>
							)}
							{title && <StyledH2>{title}</StyledH2>}
							{isThirdPartyManaged && (
								<SSOTag> {t`web.shared.ssoUser`}</SSOTag>
							)}
						</StyledTextRow>
					</StyledTitle>
					{subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
				</StyledHeaderSection>
				{children}
			</StyledModalWrapper>
		</ModalWrap>
	);
};

export { LinusModal };

const StyledModal = Modal.styled`
    max-width: 100%;
    max-height: 98vh;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    padding: ${theme.spacing.xl};
    box-sizing: border-box;
    position:relative;
    overflow: ${(props: ModalProps) => props.overflow}
`;
const ModalWrap = (props: _ModalProps & { overflow: string }) =>
	React.cloneElement(<StyledModal {...props} />, {
		overflow: props.overflow ?? 'auto',
	});

type StyledModalWrapperProps = {
	width?: string;
};

const StyledModalWrapper = styled.div<StyledModalWrapperProps>(
	({ width }) => `
		max-width: ${width ? width : '100%'};
	`
);
const StyledH2 = styled(H2)(
	({ theme: { weight } }) => `
	position: relative;
	font-weight: ${weight.medium};
`
);
const StyledIconWrapper = styled.div(
	({ theme: { spacing } }) => `
	position: absolute;
	top: ${spacing.md};
	right: ${spacing.md};
	width: ${spacing.xl};
	height: ${spacing.lg};
	&:hover {
		cursor: pointer;
	}
`
);

const StyledHeaderSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
`;

const StyledTitle = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledTextRow = styled.div`
	display: flex;
	position: relative;
	margin-right: 50px;
`;

const StyledSubTitle = styled(P1)(
	({ theme: { spacing } }) => `
	padding-top: ${spacing.sm};
	margin: 0 0 0 0;
`
);

const StyledTitleIcon = styled.div(
	({ theme: { spacing } }) => `
  padding-right:${spacing.md}
`
);

export const SSOTag = styled.span(
	({ theme: { color, fontSize, spacing, weight } }) => css`
		background-color: ${color.menuHoverBg};
		padding: ${spacing.xs} ${spacing.sm};
		border-radius: 30px;
		width: auto;
		height: 22px;
		align-self: center;
		font-weight: ${weight.medium};
		font-size: ${fontSize._12};
		line-height: 14px;
		margin-left: ${spacing.md};
	`
);
