import {
	BatteryResultState,
	GetBatteryResultsByOrganizationFilterField,
	GetBatteryResultsByOrganizationFilterOperator,
	GetBatteryResultsByOrganizationSortField,
	GetBatteryResultsByOrganizationSortOrder,
} from 'api/battery';
import {
	GetPaginatedParticipantsFilterOptions,
	GetParticipantsByOrgFilterField,
	GetParticipantsByOrgFilterOperator,
} from 'api/participant';

export const getPaginatedParticipantsFilterOptions = (
	searchValue?: string | null | undefined
): GetPaginatedParticipantsFilterOptions => {
	if (searchValue && searchValue?.length > 0) {
		return {
			filterField: [GetParticipantsByOrgFilterField.ExternalId],
			filterOperator: [GetParticipantsByOrgFilterOperator.Ilike],
			filterValue: [searchValue],
		};
	}
	return {
		filterField: undefined,
		filterOperator: undefined,
		filterValue: undefined,
	};
};

export const getParticipantBatteryResultsFilterOptions = () => {
	return {
		filterField: [
			GetBatteryResultsByOrganizationFilterField.StatusContextState,
		],
		filterOperator: [GetBatteryResultsByOrganizationFilterOperator.In],
		filterValue: [
			[
				BatteryResultState.AnalysisComplete,
				BatteryResultState.DataComplete,
				BatteryResultState.MetricsComplete,
				BatteryResultState.ParticipantComplete,
			].join(','),
		],
	};
};

export const getParticipantBatteryResultsSortOptions = () => {
	return {
		sortField: [GetBatteryResultsByOrganizationSortField.EndTime],
		sortOrder: [GetBatteryResultsByOrganizationSortOrder.Desc],
	};
};
