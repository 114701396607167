import { ButtonSm } from './ButtonSm';
import { ShowIfAuthorized } from '../ShowIfAuthorized';
import { ButtonProps } from '../../../types';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

export const AuthButtonSm = ({
	onClick,
	type = 'button',
	disabled,
	loading,
	width,
	text,
	primary,
	IconRight,
	IconCenter,
	IconLeft,
	operations,
}: ButtonProps & { operations: OperationToken[] }): JSX.Element => {
	return (
		<ShowIfAuthorized operations={operations}>
			<ButtonSm
				onClick={onClick}
				type={type}
				disabled={disabled}
				loading={loading}
				width={width}
				text={text}
				primary={primary}
				IconRight={IconRight}
				IconCenter={IconCenter}
				IconLeft={IconLeft}
			/>
		</ShowIfAuthorized>
	);
};
