import styled, { css } from 'styled-components';
import { Subtitle } from '../designSystem/TextComponents';
import { Trans, useTranslation } from 'react-i18next';
import { icons } from '../../../enums/icons';
import { Icon } from '../designSystem/Icon';
import { EnumItem } from '../../../enums/enumeration';

// We could also pass in different types if we need to change icons and/or text type
interface EmptyTableFallbackProps {
	longText?: string;
	fallbackText: string;
	noDataIcon?: EnumItem;
}

const EmptyTableFallback = ({
	longText, // long text is optional, if nothing is passed in as longText, then it will default to fallbackText which is the title.
	fallbackText,
	noDataIcon, // icon also optional, if nothing is passed in, it defaults to empty clipboard
}: EmptyTableFallbackProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledContainer data-testid='empty_table_fallback'>
			<Icon
				icon={noDataIcon ? noDataIcon : icons.EmptyClipboardSearch}
				title={t`web.dataTable.emptyTableFallback.noDataIcon`}
			/>
			{longText ? (
				<>
					<StyledTitle>
						{t`web.dataTable.emptyTableFallback.nothingToSeeHere`}
					</StyledTitle>
					<StyledSubtitle>{t`${longText}`}</StyledSubtitle>
				</>
			) : (
				<StyledSubtitle>
					<Trans
						i18nKey={`web.dataTable.emptyTableFallback.youHaveNoValueAdded`}
						values={{
							fallbackText: fallbackText?.toLowerCase(),
						}}
					/>
				</StyledSubtitle>
			)}
		</StyledContainer>
	);
};

export { EmptyTableFallback };

const StyledContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: ${spacing.xxxl};
	`
);

const StyledTitle = styled(Subtitle)(
	({ theme: { fontSize, spacing, color } }) => css`
		font-size: ${fontSize._24};
		margin: ${spacing.md} 0;
		white-space: pre-wrap;
		color: ${color.textBlue};
	`
);
const StyledSubtitle = styled(Subtitle)(
	({ theme: { fontSize, spacing } }) => css`
		font-size: ${fontSize._21};
		margin: ${spacing.sm} 0;
		white-space: pre-wrap;
	`
);
