import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Space } from '@mantine/core';
import { Card as DSCard } from '../../../../../../../designSystem/Card';
import { H3, Caption } from '../../../../../../../designSystem/Text';
import {
	EpsomScores,
	QuestionIdPattern,
} from '../../../../../../../../types/epsomTypes';
import { getConfidenceRatingDisplayKeys } from '../../../../../common/helpers/confidenceScoreUtils';
import { theme } from '../../../../../../../../providers/styleProvider';
import { TableSectionHeader } from '../../../../../common/components/table/TableSectionHeader';
import { EndOfQuestionnaireText } from '../../../../../common/components/table/EndOfQuestionnaireText';
import { RankedAndRatedAnswersList } from './RankedAndRatedAnswersList/List';
import { AlertShieldSolid } from '../../../../../../../shared/designSystem/Icons';
import { TotalScoreText } from '../../../../../common/components/table/TotalScoreText';

type RankedAndRatedAnswersProps = {
	epsomScores: EpsomScores;
};

function RankedAndRatedAnswers(props: RankedAndRatedAnswersProps) {
	const { t } = useTranslation();

	const textToDisplay = getConfidenceRatingDisplayKeys(t);
	const { epsomScores } = props;

	return (
		<>
			<H3 data-testid='section-title'>{textToDisplay.title}</H3>
			<MediumVerticalMargin />
			<Card>
				<PaddedContainer>
					<TableSectionHeader
						cardHeading={textToDisplay?.card?.title}
						cardHeadingDescriptions={[
							<>
								<CenteredDiv
									data-testid={'header-description-svg-icon'}>
									<AlertShieldSolid
										height={12}
										width={12}
										color={theme.color.alertError}
									/>
								</CenteredDiv>
								<Space w={4} />
								{/* Header Description text */}
								<StyledCaption
									style={{
										color: theme.color.textAlert,
										alignContent: 'center',
									}}
									data-testid={'header-description-text'}>
									{textToDisplay.card.lowConfidenceIndicator}
								</StyledCaption>
							</>,
						]}
						subheading={textToDisplay?.card?.subtitle}
					/>
					<MediumVerticalMargin />

					<RankedAndRatedAnswersList
						rankedAnswers={epsomScores[QuestionIdPattern.ranking]}
						textToDisplay={textToDisplay.card.list}
					/>

					<StyledFooter>
						<TotalScoreText
							totalScoreText={
								textToDisplay.card.footer.totalScore
							}
							totalScoreValue={epsomScores.total}
						/>

						<StyledSeparator />
						<MediumVerticalMargin />

						<EndOfQuestionnaireText
							text={textToDisplay.card.footer.endOfQuestionnaire}
						/>
					</StyledFooter>
				</PaddedContainer>
			</Card>
		</>
	);
}

const PaddedContainer = styled.div(
	({ theme: { spacing } }) => `
		padding: ${spacing.xl};			
	`
);

const MediumVerticalMargin = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.md};
`
);

const StyledSeparator = styled.div(
	({ theme: { color, spacing } }) => `
	position: relative;
	width: 100%;  
	border-bottom: 1px solid ${color.infoSeparator};	
	margin-top: ${spacing.md};
`
);

const StyledFooter = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.md};
`
);
const Card = styled(DSCard)`
	padding: ${(props) => props.theme.spacing.xl};
	display: flex;
	flex-direction: column;
`;
const CenteredDiv = styled.div(
	() => `
    display: flex;
    justify-content: center;
    align-items: center; 
  `
);

const StyledCaption = styled(Caption)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.bodyText};
`
);
export { RankedAndRatedAnswers };
