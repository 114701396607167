import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getSubjectService } from 'api/getSubjectService';
import { Participant } from 'api/participant';
import { isNonEmptyString } from '../../utils/stringUtils';

type FetchParticipantInput = {
	participantId: string | null;
	organizationId: string;
};

export async function fetchParticipant(
	input: FetchParticipantInput
): Promise<Participant> {
	if (!input?.participantId || !input?.organizationId) {
		throw new Error(
			'useParticipantQuery: participantId/organization IDs cannot be null'
		);
	}

	const service = await getSubjectService();
	const { data } = await service.getParticipantByOrgIdAndUuid({
		organizationId: input?.organizationId,
		subjectId: input?.participantId,
	});
	return data;
}

export function useGetParticipant(input: FetchParticipantInput) {
	const isValidOrganizationId = isNonEmptyString(input?.organizationId);
	const isValidParticipantId = isNonEmptyString(input?.participantId);
	return useQuery({
		enabled: isValidParticipantId && isValidOrganizationId,
		meta: {
			errorMessage: `Error fetching participant ${input?.participantId} for org ${input?.organizationId}`,
		},
		queryKey: [QueryKey.Participant, input?.participantId],
		queryFn: () => fetchParticipant(input),
		staleTime: Infinity,
	});
}
