import { useState, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { UserContext } from '../../../context/UserContext';
import { useGetLinkByAssignmentId, Link } from 'api/remote-assessments';
import { truncateString } from 'utils/stringUtils';
import {
	CheckmarkFilled,
	Clipboard,
	InviteOutlined,
	TraditionalArrowRight,
} from 'components/shared/designSystem/Icons';
import { LoadingDots } from 'components/shared/LoadingDots';

export type RemoteLinkViewerProps = {
	payload: {
		batteryName: string;
		assignmentId: string;
		externalId: string;
		contactEmail?: string;
	};
};

const RemoteLinkViewer = ({
	payload: { batteryName, assignmentId, externalId, contactEmail },
}: RemoteLinkViewerProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [copied, setCopied] = useState(false);
	const { currentUser, logout } = useContext(UserContext);
	const { data: link } = useGetLinkByAssignmentId({
		organizationId: currentUser.organizationId,
		assignmentId,
	});

	function handleClick(url: string) {
		return async () => {
			window?.open && window.open(url, '_blank', 'noreferrer');
			await logout();
		};
	}

	const handles = useMemo(() => {
		if (link) {
			return {
				clipboardHandler: getClipboardHandler(link, (err) => {
					if (err) return;
					setCopied(true);
				}),
				formatted: formatLink(link),
				mailTo: formatMailTo(link, contactEmail),
			};
		}
	}, [link, contactEmail]);
	if (!link || !handles)
		return (
			<Placeholder>
				<LoadingDots />
			</Placeholder>
		);
	return (
		<StyledContainer>
			<StyledHeaderRow>
				<Clipboard height={31} width={29} color={theme.colors.blue} />
				<StyledHeader>{t`web.shared.remoteLinkViewer.header`}</StyledHeader>
			</StyledHeaderRow>
			<StyledDescription>
				<Trans
					i18nKey='web.shared.remoteLinkViewer.description'
					values={{
						externalId,
					}}
					components={{
						b: <strong />,
					}}
				/>
			</StyledDescription>
			<StyledSection>
				<StyledHeading>{t`web.shared.remoteLinkViewer.battery`}</StyledHeading>
				<div>{batteryName}</div>
			</StyledSection>
			<StyledSection>
				<StyledHeading>{t`web.shared.remoteLinkViewer.link`}</StyledHeading>
				<StyledLinkBox onClick={handles.clipboardHandler}>
					<StyledLink>
						{truncateString(handles.formatted, 40)}
					</StyledLink>
					<StyledAction>{t`web.shared.remoteLinkViewer.copy`}</StyledAction>
					<Clipboard color={theme.colors.blue} />
				</StyledLinkBox>
				<StyledCopyRow className={copied ? 'copied' : ''}>
					<CheckmarkFilled
						width={24}
						height={24}
						color={theme.color.alertSuccess}
					/>
					<span>{t`web.shared.remoteLinkViewer.copied`}</span>
				</StyledCopyRow>
			</StyledSection>
			<StyledActionRow>
				<InviteOutlined
					color={theme.colors.blue}
					height={24}
					width={24}
				/>
				<a href={handles?.mailTo}>
					<StyledAction>
						{t`web.shared.remoteLinkViewer.openInEmail`}
					</StyledAction>
				</a>
			</StyledActionRow>
			<StyledActionRow className='last'>
				<TraditionalArrowRight
					width={24}
					height={24}
					color={theme.colors.blue}
				/>
				<StyledAction onClick={handleClick(handles?.formatted)}>
					{t`web.shared.remoteLinkViewer.startNow`}
				</StyledAction>
			</StyledActionRow>
		</StyledContainer>
	);
};

export { RemoteLinkViewer };

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 460px;
	max-width: 500px;
	justify-content: space-between;
`;

const Placeholder = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 460px;
	min-height: 425px;
	justify-content: center;
`;

const StyledHeading = styled.div(
	({ theme: { color, weight, fontSize } }) => `
	text-transform: uppercase;
  color: ${color.bodyTextSecondary};
  font-weight: ${weight.medium};
  font-size: ${fontSize._12};
`
);

const StyledActionRow = styled.div(
	({ theme: { spacing, colors } }) => `
  border-top: 1px solid ${colors.gray_50};
  width: calc(100% + 2 * ${spacing.xl});
  margin: 0 -${spacing.xl};
  padding: ${spacing.lg};
  padding-left: ${spacing.xl};
  display: flex;
  flex-direction: row;
  gap: ${spacing.sm};
  &.last {
    padding-bottom: 0;
  }
`
);

const StyledCopyRow = styled.div(
	({ theme: { spacing, colors, borderRadius } }) => `
  display: none;
  flex-direction: row;
  gap: ${spacing.sm};
  margin-top: ${spacing.lg};
  padding: ${spacing.sm};
  border-radius: ${borderRadius.btn};
  &.copied {
    display: flex;
    background-color: ${colors.green_lightest};
    color: ${colors.green_darkest}
  }
`
);

const StyledSection = styled.div(
	({ theme: { spacing } }) => `
  margin: 0 0 ${spacing.md} 0;
`
);
const StyledAction = styled.div(
	({ theme: { colors, weight, fontSize } }) => `
  color: ${colors.blue};
  font-weight: ${weight.semi};
  font-size: ${fontSize._18};
  cursor: pointer;
`
);
const StyledLink = styled.div`
	flex-grow: 2;
`;

const StyledLinkBox = styled.div(
	({ theme: { spacing, colors, color, borderRadius } }) => `
  background-color: ${colors.gray_90};
  color: ${color.bodyTextSecondary};
  border: 1px solid ${colors.gray_50};
  padding: ${spacing.md} ${spacing.sm};
  width: 100%;
  border-radius: ${borderRadius.btn};
  display: flex;
  flex-direction: row;
  gap: ${spacing.md};
  & :hover {
    cursor: copy;
  }
`
);

const StyledDescription = styled.div(
	({ theme }) => `
  margin: ${theme.spacing.md} 0;
  margin-top: 0;
`
);

const StyledHeader = styled.span(
	({ theme }) => `
  font-size: ${theme.fontSize._32};
  font-weight: ${theme.weight.medium};
  align-self: center;
`
);

const StyledHeaderRow = styled.div(
	({ theme }) => `
  padding: 0 0 ${theme.spacing.md} 0;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.sm};
`
);

function formatLink(link?: Link): string {
	if (!link) return 'Link Not Found';
	return `${link.baseUrl}?token=${link.token}`;
}

function formatMailTo(link?: Link, contactEmail?: string): string {
	const url = contactEmail ?? 'unknown_email@linus.health';
	return `mailto:${url}?body=${formatLink(link)}`;
}

function getClipboardHandler(link?: Link, errBack?: (e?: unknown) => void) {
	return async () => {
		try {
			await navigator.clipboard.writeText(formatLink(link));
			errBack?.();
		} catch (err) {
			errBack?.(err);
		}
	};
}
