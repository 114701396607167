import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Divider, Space } from '@mantine/core';

import { Card as DSCard } from '../../../../../../designSystem/Card';
import { EndOfQuestionnaireText } from '../../../../common/components/table/EndOfQuestionnaireText';
import {
	TableSectionHeader,
	TableSectionHeaderProps,
} from '../../../../common/components/table/TableSectionHeader';

type Props = {
	renderHeader: () => React.ReactNode;
	renderRows: () => React.ReactNode[];
	renderFooter?: () => React.ReactNode;
	sectionHeaderProps: TableSectionHeaderProps;
};

const RankedAndRatedAnswers = (props: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<Title>
				{t('web.report.longEpsom.confidenceScore.rankedRated.heading')}
			</Title>
			<Card>
				{/* Section Header */}
				<TableSectionHeader {...props.sectionHeaderProps} />
				<Space h='md' />
				{/* Table */}
				{props.renderHeader()}
				{props.renderRows()}
				{/* Footer */}
				{props.renderFooter?.()}
				<Space h='md' />
				<Divider />
				<Space h='lg' />
				<EndOfQuestionnaireText
					text={t(
						'research.epsomRankedAndRatedAnswers.card.footer.endOfQuestionnaire'
					)}
				/>
			</Card>
		</>
	);
};

export { RankedAndRatedAnswers };

const Card = styled(DSCard)`
	padding: ${(props) => props.theme.spacing.xl};
	display: flex;
	flex-direction: column;
`;

const Title = styled.h3(
	({ theme }) => `
  font-size: ${theme.fontSize._24};
  margin: ${theme.spacing.xl} 0;
`
);
