import { create } from 'zustand';
import {
	LinusPrincipal,
	User,
} from '@lh/eng-platform-organization-service-rest-client';

interface State {
	reset: () => void;
	user: User | null;
	setUser: (user: User) => void;
	principal: LinusPrincipal | null;
	setPrincipal: (principal: LinusPrincipal) => void;
}

export const useUserStore = create<State>((set) => ({
	user: null,
	setUser: (user) => set({ user }),

	principal: null,
	setPrincipal: (principal) => set({ principal }),

	reset: () =>
		set({
			user: null,
			principal: null,
		}),
}));
