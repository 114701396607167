// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const PyramidArrowDown = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 8 5'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='PyramidArrowDown-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'PyramidArrowDown'}</title>
			<path d='M0.444456 -2.2005e-06C0.264695 -2.18478e-06 0.102635 0.115502 0.0338441 0.292651C-0.0349478 0.4698 0.00307715 0.673707 0.130187 0.809291L3.68573 4.60187C3.8593 4.78701 4.1407 4.78701 4.31427 4.60187L7.86981 0.809291C7.99692 0.673707 8.03495 0.4698 7.96616 0.292651C7.89736 0.115502 7.7353 -1.88421e-06 7.55554 -1.86849e-06L0.444456 -2.2005e-06Z' />
		</svg>
	);
};
