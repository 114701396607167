import { CSSProperties } from 'react';
import { AssignmentStatus } from '../../../generated/graphql';
import { theme } from '../../../providers/styleProvider';

export const getAssignmentStyling = (
	assignmentStatus: AssignmentStatus | undefined
): CSSProperties => {
	return {
		fontWeight: theme?.weight?.medium,
		color: getAssignmentColor(assignmentStatus),
		textTransform: 'uppercase',
	};
};

const getAssignmentColor = (assignmentStatus: AssignmentStatus | undefined) => {
	switch (assignmentStatus) {
		case AssignmentStatus.Started:
			return theme.colors.green_darker;
		default:
			return theme.color.bodyTextSecondary;
	}
};
