import { TFunction } from 'i18next';
import { convertToSentenceCase } from '../../../utils/stringUtils';
import { ScoreBreakdownProps } from '../common/Breakdown/Breakdown';
import { MetricHash } from '../metrics';
import { SemanticFluencyLayoutProps } from './SemanticFluency.layout';
import { MapDataToPropsArgs } from './SemanticFluency.types';

const minimumAchievableScore = 0;
const unanalyzableScoreToDisplay = '--';

const getBreakdownSuffixTransKeyByScore = (score: number) => {
	return score === 0
		? 'web.report.verbalFluency.cannotCalculate'
		: 'web.report.verbalFluency.correctResponses';
};

const getEpochTimeRange = (epochNumber: number) => {
	switch (epochNumber) {
		case 1:
			return '0-15';
		case 2:
			return '16-30';
		case 3:
			return '31-45';
		case 4:
			return '46-60';
		default:
			return '';
	}
};

const getNumericEpochScore = (
	metricItems: MetricHash,
	epochNumber: number
): number => {
	const epochScoreMetricKey = `semantic_fluency_epoch${epochNumber}_score`;
	return (
		(metricItems?.[epochScoreMetricKey]?.value as number) ??
		minimumAchievableScore
	);
};

const getEpochScoreToDisplay = (epochScore: number) => {
	return epochScore === 0
		? unanalyzableScoreToDisplay
		: epochScore.toString();
};

const getPercentile = (metricItems: MetricHash): number => {
	return (
		(metricItems?.['semantic_fluency_percentile']?.value as number) ??
		minimumAchievableScore
	);
};

const getEpochTimeToDisplay = (
	translationFn: TFunction,
	epochNumber: number
) => {
	return translationFn('web.report.verbalFluency.time', {
		value: getEpochTimeRange(epochNumber),
	});
};

/**
 * Checks to see if the total score from the metrics is zero or not.
 * @param metricItems Different metrics as obtained in the segment result.
 * @returns {boolean} whether or not the total score in the metric items is zero.
 */
const checkIfTotalAchievedScoreIsZero = (metricItems: MetricHash): boolean => {
	const totalScoreAchieved =
		(metricItems?.['semantic_fluency_total_score']?.value as number) ??
		minimumAchievableScore;
	return totalScoreAchieved === 0;
};

/**
 * Calculates the total score (value and description) as provided in the metric items.
 * If scores are not found in the metric items, it falls back to the minimum
 * scores as defined above.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Object} An object containing the total score, with keys title and value
 * @returns {@string} value - The total score obtained
 * @returns {@string} title - The title describing the total score
 */
const getTotalScoreToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): SemanticFluencyLayoutProps['score']['total'] => {
	const totalScoreAchieved =
		(metricItems?.['semantic_fluency_total_score']?.value as number) ??
		minimumAchievableScore;
	const totalScoreToDisplay = `${
		totalScoreAchieved === 0 ? '--' : totalScoreAchieved
	}`;
	const scoreTitle = translationFn(
		'web.report.verbalFluency.correctResponses'
	)?.toUpperCase();
	return { value: totalScoreToDisplay, title: scoreTitle };
};

/**
 * Creates individual score breakdown for each "Trial", using scores as in the metrics.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Array} An array of objects depicting individual score breakdown.
 */
const getIndividualScoreBreakdown = (
	metricItems: MetricHash,
	translationFn: TFunction
): ScoreBreakdownProps['breakdown'] => {
	const epoch1Score = getNumericEpochScore(metricItems, 1);
	const epoch2Score = getNumericEpochScore(metricItems, 2);
	const epoch3Score = getNumericEpochScore(metricItems, 3);
	const epoch4Score = getNumericEpochScore(metricItems, 4);
	return [
		{
			title: getEpochTimeToDisplay(translationFn, 1),
			score: getEpochScoreToDisplay(epoch1Score),
			suffix: translationFn(
				getBreakdownSuffixTransKeyByScore(epoch1Score)
			),
		},
		{
			title: getEpochTimeToDisplay(translationFn, 2),
			score: getEpochScoreToDisplay(epoch2Score),
			suffix: translationFn(
				getBreakdownSuffixTransKeyByScore(epoch2Score)
			),
		},
		{
			title: getEpochTimeToDisplay(translationFn, 3),
			score: getEpochScoreToDisplay(epoch3Score),
			suffix: translationFn(
				getBreakdownSuffixTransKeyByScore(epoch3Score)
			),
		},
		{
			title: getEpochTimeToDisplay(translationFn, 4),
			score: getEpochScoreToDisplay(epoch4Score),
			suffix: translationFn(
				getBreakdownSuffixTransKeyByScore(epoch4Score)
			),
		},
	];
};

/**
 * Runs validations against the individual and total scores and accumulates them into a single object.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Object} An object with 3 values - "requiresClinicianReview", "total" & "breakdown"
 */
const getScoreInfo = (
	metricItems: MetricHash,
	translationFn: TFunction
): SemanticFluencyLayoutProps['score'] => {
	return {
		percentile: getPercentile(metricItems),
		requiresClinicianReview: checkIfTotalAchievedScoreIsZero(metricItems),
		total: getTotalScoreToDisplay(metricItems, translationFn),
		breakdown: getIndividualScoreBreakdown(metricItems, translationFn),
	};
};

const getAudioPromptToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): string => {
	const promptDisplayKey = `${translationFn(
		'web.report.verbalFluency.prompt'
	)}`;
	const promptFromMetrics =
		metricItems?.['semantic_category']?.value?.toString() ?? '';
	return convertToSentenceCase(`${promptDisplayKey}: ${promptFromMetrics}`);
};

/**
 *
 * @param param0
 * @returns
 */
const mapDataToProps = ({
	segmentResult,
	audioData,
	translationFn,
}: MapDataToPropsArgs): SemanticFluencyLayoutProps | null => {
	if (!segmentResult) return null;
	if (!audioData) return null;

	const segmentName =
		segmentResult?.segment?.displayKey ?? 'Semantic Fluency';

	const metricItems = segmentResult?.metricItems;

	const audioObject = audioData?.[0] ?? {};

	return {
		header: {
			title: segmentName,
			tooltipText: translationFn(
				'web.report.verbalFluency.semantic.tooltipText'
			),
		},
		audioCardData: [
			{
				text: {
					cardHeading: segmentName,
					cardSubHeadings: [
						getAudioPromptToDisplay(metricItems, translationFn),
					],
				},
				audio: {
					source: audioObject?.source ?? '',
					fileType: audioObject?.fileType ?? '',
				},
			},
		],
		score: getScoreInfo(metricItems, translationFn),
	};
};

export { mapDataToProps };
