import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateLinkInput } from './types';
import { getRemoteAssessmentsService } from '../getRemoteAssessmentsService';
import { QueryKey } from 'api/query';
import { AxiosError } from 'axios';

type CreateLinkArgs = {
	organizationId: string;
	link: CreateLinkInput;
};

async function createLink({ link, organizationId }: CreateLinkArgs) {
	const service = await getRemoteAssessmentsService();
	return await service.createIndividualLink({
		organizationId,
		postIndividualLinkBody: { ...link },
	});
}

export function useCreateLink() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error creating link for assignment',
		},
		mutationFn: createLink,
		onMutate: async ({ link }) => {
			return { assignmentId: link.assignmentId };
		},
		onSettled: (
			_result,
			_error: AxiosError<{ errorCode: string }> | null,
			variables
		) => {
			queryClient.invalidateQueries({
				queryKey: [QueryKey.RemoteLink, variables.link.assignmentId],
			});
		},
	});
}
