import React from 'react';
import styled from 'styled-components';

export type AlertProps = {
	children: JSX.Element;
	severity: 'info';
};

const Alert = ({ children, severity }: AlertProps): JSX.Element => {
	return (
		<StyledAlertContainer severity={severity}>
			{children}
		</StyledAlertContainer>
	);
};

export { Alert };

type StyledAlertContainerProps = {
	severity: AlertProps['severity'];
};

const StyledAlertContainer = styled.div<StyledAlertContainerProps>(
	({ theme: { color, spacing, fontSize, weight, borderRadius } }) => `
  padding: ${spacing.lg} ${spacing.xl};
  background: ${color.alertInfoSegmentBg};
  border-radius: ${borderRadius.md};
  color: ${color.alertInfoSegmentText}; // TODO (zm): Handle severity logic later.
  font-size: ${fontSize._21};
  font-weight: ${weight.semi};
`
);
