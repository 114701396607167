// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const CheckboxCheck = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckboxCheck-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'CheckboxCheck'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H5ZM11.1859 16.558C10.5347 17.2091 9.47661 17.2091 8.82547 16.558L5.97673 13.7093C5.32559 13.0581 5.32559 12 5.97673 11.3489C6.62787 10.6977 7.68598 10.6977 8.33712 11.3489L9.6394 12.6512C9.88358 12.8953 10.2091 12.8953 10.3719 12.6512L15.6624 7.44203C16.3136 6.79089 17.3717 6.79089 18.0228 7.44203C18.674 8.09317 18.674 9.15127 18.0228 9.80241L11.1859 16.558Z'
				fill='current'
			/>
		</svg>
	);
};
