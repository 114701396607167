import { useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryKey } from '../query';

import { getOrganizationService } from 'api/getOrganizationService';
import { isNonEmptyString } from '../../utils/stringUtils';
import { OrganizationUser, PaginatedUsers } from './types';

type FetchOrgUserInput = {
	organizationId: string | null;
	isLoggedIn: boolean;
};

export async function fetchOrgUsers(
	input: FetchOrgUserInput
): Promise<PaginatedUsers> {
	if (!input.organizationId) {
		throw new Error('fetchOrgUsers: organization IDs cannot be null');
	}

	const service = await getOrganizationService();
	const { data } = await service.getUsers({
		organizationId: input.organizationId,
	});
	return data;
}

export function useGetOrgUsers(input: FetchOrgUserInput) {
	const client = useQueryClient();
	return useQuery({
		enabled: input.isLoggedIn && isNonEmptyString(input.organizationId),
		meta: {
			errorMessage: `Error fetching users in organization: ${input.organizationId}`,
		},
		queryKey: [QueryKey.Users, input?.organizationId],
		queryFn: () =>
			fetchOrgUsers(input).then((pagedUsers) => {
				pagedUsers.results.forEach((user) =>
					client.setQueryData<OrganizationUser>(
						[QueryKey.User, input.organizationId, user.id],
						user
					)
				);
				return pagedUsers;
			}),
		staleTime: Infinity,
	});
}
