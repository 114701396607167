import {
	CreateParticipantInput,
	UpdateParticipantInput,
	Gender,
	GetParticipantsByOrgFilterField,
	GetParticipantsByOrgFilterOperator,
	GetParticipantsByOrgSortField,
	GetParticipantsByOrgSortOrder,
	Handedness,
	PaginatedParticipants,
	Participant,
	ParticipantApi,
	ParticipantApiGetParticipantsByOrgRequest,
	Status as ParticipantStatus,
	GetParticipantsByOrgSortField as ParticipantsSortField,
	GetParticipantsByOrgSortOrder as ParticipantsSortOrder,
	Configuration as SubjectServiceConfiguration,
} from '@lh/eng-platform-subject-service-rest-client';

export type {
	CreateParticipantInput,
	PaginatedParticipants,
	Participant,
	UpdateParticipantInput,
	ParticipantApiGetParticipantsByOrgRequest,
};

export {
	Gender,
	GetParticipantsByOrgFilterField,
	GetParticipantsByOrgFilterOperator,
	GetParticipantsByOrgSortField,
	GetParticipantsByOrgSortOrder,
	ParticipantApi,
	ParticipantStatus,
	ParticipantsSortField,
	ParticipantsSortOrder,
	SubjectServiceConfiguration,
	Handedness,
};

export type GetPaginatedParticipantsFilterOptions = {
	filterField: ParticipantApiGetParticipantsByOrgRequest['filterField'];
	filterOperator: ParticipantApiGetParticipantsByOrgRequest['filterOperator'];
	filterValue: ParticipantApiGetParticipantsByOrgRequest['filterValue'];
};

export type GetPaginatedParticipantsSortOptions = {
	sortField: ParticipantApiGetParticipantsByOrgRequest['sortField'];
	sortOrder: ParticipantApiGetParticipantsByOrgRequest['sortOrder'];
};
