import { Card, Flex, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Typography } from '@lh/eng-web-mosaic-common';

import ScoringWidget from 'components/report/common/scoring/scoringWidget/ScoringWidget';
import { theme } from '../../../../../providers/styleProvider';
import { PHQ8DepressionScore } from '../PHQ-8.types';

export const PHQ8DepressionScoreBreakdown = (props: PHQ8DepressionScore) => {
	const { t } = useTranslation();
	return (
		<>
			<Space h='sm' />
			<Card padding='md' shadow='sm' radius={'md'}>
				<ScoringWidget
					header={{
						titleText: t(
							'web.report.phq8.depression.reportCard.heading'
						),
						infoTooltipText: t(
							'web.report.phq8.depression.tooltip'
						),
					}}
					content={{
						overallScore: {
							highlightColor: {
								solid: theme.colors.orange_light,
								light: '#FB80661A',
							},
							value: {
								achieved: props?.totalScore?.achieved,
								total: props?.totalScore?.max,
							},
						},
						breakdown: {
							scoreIndicatorColor: theme.colors.orange_light,
							breakdownSummary: (
								<Flex direction={'row'}>
									<Typography.P2 weight='500'>
										{t(
											'web.report.phq8.depression.reportCard.indicatorText1'
										)}
									</Typography.P2>
									<span>&nbsp;</span>
									<Typography.P2 weight='700'>
										{t(
											'web.report.phq8.depression.reportCard.indicatorText2'
										)}
									</Typography.P2>
								</Flex>
							),
							scores: [
								{
									scoreCategory: t(
										'web.report.phq8.depression.reportCard.scoreBreakdown1'
									),
									scoreValues: {
										total: props?.q1q2Score?.max,
										achieved: props?.q1q2Score?.achieved,
									},
								},
								{
									scoreCategory: t(
										'web.report.phq8.depression.reportCard.scoreBreakdown2'
									),
									scoreValues: {
										total: props?.scoreExcludingQ1Q2?.max,
										achieved:
											props?.scoreExcludingQ1Q2?.achieved,
									},
								},
							],
						},
						additionalScore: undefined,
					}}
				/>
			</Card>
			<Space h='sm' />
		</>
	);
};
