// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const GetGoodRestUntapped = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 36 36'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='GetGoodRestUntapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'GetGoodRestUntapped'}</title>
			<rect
				x='1.25'
				y='1.25'
				width='33.5'
				height='33.5'
				rx='16.75'
				fill='#F2F1F3'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.4598 14.4805H26.689C27.1573 14.4805 27.5281 14.8707 27.5085 15.339C27.5085 15.8073 27.1378 16.178 26.6695 16.178H23.7817C23.45 16.178 23.1573 16.0024 23.0207 15.7098C22.8841 15.4366 22.9037 15.0854 23.0988 14.8317L25.011 12.1976H23.7817C23.3134 12.1976 22.9427 11.8268 22.9427 11.3585C22.9427 10.8902 23.3134 10.5 23.7817 10.5H26.689C27.0012 10.5 27.2939 10.6951 27.45 10.9683C27.5866 11.2415 27.5671 11.5927 27.372 11.8463L25.4598 14.4805ZM23.5086 19.9439C23.2354 19.7878 22.9037 19.8463 22.6695 20.061C21.5573 21.1927 19.9964 21.739 18.4159 21.5634C16.0744 21.2902 14.1817 19.3976 13.9281 17.0756C13.7525 15.4756 14.2988 13.9341 15.4305 12.8219C15.6647 12.6073 15.7037 12.2561 15.5476 11.9829C15.3915 11.7098 15.0793 11.5732 14.7866 11.6512C11.2354 12.4707 8.81588 15.7293 9.011 19.3585C9.20612 23.1439 12.3281 26.2854 16.1329 26.4805C16.2012 26.4805 16.2695 26.4854 16.3378 26.4902C16.4061 26.4951 16.4744 26.5 16.5427 26.5C20.0159 26.5 23.0598 24.1195 23.8793 20.7244C23.9183 20.3927 23.7817 20.1 23.5086 19.9439ZM17.9671 14.8707H19.1964L17.2842 17.5049C17.089 17.7585 17.0695 18.1098 17.2061 18.3829C17.3427 18.6756 17.6354 18.8512 17.9671 18.8512H20.8549C21.3232 18.8512 21.6939 18.4805 21.6939 18.0122C21.6939 17.5439 21.3232 17.1732 20.8549 17.1732H19.6256L21.5378 14.539C21.7329 14.2854 21.7525 13.9341 21.6159 13.661C21.4793 13.3683 21.1866 13.1927 20.8549 13.1927H17.9671C17.4988 13.1927 17.1281 13.5634 17.1281 14.0317C17.1281 14.5 17.4988 14.8707 17.9671 14.8707Z'
				fill={color}
			/>
			<circle
				cx='18'
				cy='18'
				r='16.75'
				fill='none'
				stroke='#087DAE'
				stroke-width='1.5'
				stroke-dasharray='3 4'
			/>
		</svg>
	);
};
