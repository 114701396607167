// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Timer = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 19 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Timer-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Timer'}</title>

			<path
				d='M11.7 1C11.9761 1 12.2 0.776142 12.2 0.5C12.2 0.223858 11.9761 0 11.7 0H8.20001C7.92386 0 7.70001 0.223858 7.70001 0.5C7.70001 0.776143 7.92386 1 8.20001 1L9.4 1V1.81837C7.11642 1.97149 5.09207 3.07102 3.71583 4.72809C3.70925 4.73401 3.70279 4.74014 3.69645 4.74648C3.50119 4.94174 3.50119 5.25832 3.69645 5.45358L5.49645 7.25358C5.69171 7.44885 6.00829 7.44885 6.20356 7.25358C6.39882 7.05832 6.39882 6.74174 6.20356 6.54648L4.66614 5.00906C5.98127 3.58895 7.86182 2.7 9.95 2.7C13.9264 2.7 17.15 5.92355 17.15 9.9C17.15 13.8764 13.9264 17.1 9.95 17.1C5.97355 17.1 2.75 13.8764 2.75 9.9C2.75 9.6922 2.7588 9.48647 2.77605 9.28313L3.74645 10.2535C3.94171 10.4488 4.25829 10.4488 4.45355 10.2535C4.64882 10.0583 4.64882 9.74168 4.45355 9.54642L2.65355 7.74642C2.45829 7.55116 2.14171 7.55116 1.94645 7.74642L0.146447 9.54642C-0.0488155 9.74168 -0.0488156 10.0583 0.146447 10.2535C0.341709 10.4488 0.658292 10.4488 0.853554 10.2535L1.87733 9.22975C1.85923 9.45077 1.85 9.6743 1.85 9.89999C1.85 14.3735 5.47649 18 9.95 18C14.4235 18 18.05 14.3735 18.05 9.89999C18.05 5.57748 14.6642 2.04577 10.4 1.81228V1L11.7 1Z'
				fill='current'
			/>
			<path
				d='M9.9685 4.98725C10.2446 4.98725 10.4685 4.76625 10.4685 4.49362C10.4685 4.221 10.2446 4 9.9685 4C9.69236 4 9.4685 4.221 9.4685 4.49362C9.4685 4.76625 9.69236 4.98725 9.9685 4.98725Z'
				fill='current'
			/>
			<path
				d='M16 10C16 10.2761 15.7761 10.5 15.5 10.5C15.2239 10.5 15 10.2761 15 10C15 9.72386 15.2239 9.5 15.5 9.5C15.7761 9.5 16 9.72386 16 10Z'
				fill='current'
			/>
			<path
				d='M14.5 13.7436C14.5 14.0162 14.2761 14.2372 14 14.2372C13.7239 14.2372 13.5 14.0162 13.5 13.7436C13.5 13.471 13.7239 13.25 14 13.25C14.2761 13.25 14.5 13.471 14.5 13.7436Z'
				fill='current'
			/>
			<path
				d='M5.99999 14.2372C6.27614 14.2372 6.49999 14.0162 6.49999 13.7436C6.49999 13.471 6.27614 13.25 5.99999 13.25C5.72385 13.25 5.49999 13.471 5.49999 13.7436C5.49999 14.0162 5.72385 14.2372 5.99999 14.2372Z'
				fill='current'
			/>
			<path
				d='M10.4685 15.4936C10.4685 15.7662 10.2446 15.9872 9.9685 15.9872C9.69236 15.9872 9.4685 15.7662 9.4685 15.4936C9.4685 15.221 9.69236 15 9.9685 15C10.2446 15 10.4685 15.221 10.4685 15.4936Z'
				fill='current'
			/>
			<path
				d='M11.5387 10C11.5387 10.8284 10.8585 11.5 10.0194 11.5C9.18024 11.5 8.49999 10.8284 8.49999 10C8.49999 9.17157 9.18024 8.5 10.0194 8.5C10.2686 8.5 10.5038 8.55923 10.7113 8.66421L12.9985 6.5804C13.2026 6.39442 13.5189 6.40914 13.7049 6.61327C13.8908 6.8174 13.8761 7.13364 13.672 7.31961L11.4054 9.38461C11.4911 9.57238 11.5387 9.78069 11.5387 10Z'
				fill='current'
			/>
		</svg>
	);
};
