import { useUpdatePrimaryOrganizationMutation } from '../generated/graphql';

export function useChangeDefaultOrganization() {
	const [
		updatePrimaryOrganization,
		{ loading: updatingPrimaryOrganization },
	] = useUpdatePrimaryOrganizationMutation();

	const changeDefaultOrganization = async (organizationId: string) => {
		const response = await updatePrimaryOrganization({
			variables: {
				updatePrimaryOrganizationInput: {
					targetOrgId: organizationId,
				},
			},
		});

		if (response?.data?.updatePrimaryOrganization?.success) {
			return organizationId;
		}

		return null;
	};

	return {
		changeDefaultOrganization,
		updatingPrimaryOrganization,
	};
}
