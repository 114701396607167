import styled from 'styled-components';
import { LinusInputChildrenProps } from './../../../../types';
import { Caption } from '../../designSystem/TextComponents';

// The trailing comma in  <ObjectType, > is added due to constraints of the  .tsx file extension.
// You can read more in the TypeScript Generics. Discussing naming conventions
// https://wanago.io/2020/02/17/typescript-generics-discussing-naming-conventions/
export const Label = <T,>({
	name,
	value,
	placeholder,
	disabled,
	label,
}: LinusInputChildrenProps<T>): JSX.Element => {
	return (
		<StyledLabel htmlFor={name} data-testid={`${name}_label`}>
			<StyledText disabled={disabled}>
				{value ? label || placeholder : ''}
			</StyledText>
		</StyledLabel>
	);
};

const StyledLabel = styled.label(
	({ theme: { spacing } }) => `
	position: absolute;
	top: 2px;
	left: ${spacing.lg};
	z-index: 1;
	transition: 0.2s ease all;
`
);
type StyledTextProps = {
	disabled?: boolean;
};
const StyledText = styled(Caption)<StyledTextProps>(
	({ disabled, theme: { color, weight } }) => `
	position: relative;
	color: ${disabled ? color.formTextDisabled : color.label};
	font-weight: ${weight.medium};
	`
);
