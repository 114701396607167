import styled, { css } from 'styled-components';

import { Caption, P1 } from '../../../../../components/designSystem/Text';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const LabelText = styled(P1)(
	({ theme: { weight, color } }) => css`
		color: ${color.bodyText};
		font-weight: ${weight.semi};
	`
);

export const ProbabilityTag = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

export const Tag = styled.div<{ $bgColor: string }>(
	({ theme: { spacing }, $bgColor }) => css`
		padding: ${spacing.xs} ${spacing.sm};
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: ${spacing.sm};
		background-color: ${$bgColor};
		min-width: 100px;
	`
);

export const TagText = styled(P1)<{ $color: string }>(
	({ $color, theme: { weight } }) => css`
		font-weight: ${weight.semi};
		color: ${$color};
		letter-spacing: 0.5px;
	`
);

export const Info = styled(P1)<{ $weight: number }>(
	({ $weight, theme: { color } }) => css`
		color: ${color.bodyText};
		letter-spacing: 0.5px;
		font-weight: ${$weight};
	`
);

export const Link = styled(Caption)(
	({ theme: { weight, colors } }) => css`
		font-weight: ${weight.medium};
		color: ${colors.blue};
		cursor: pointer;
	`
);
