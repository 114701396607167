// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Play = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Play-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Play'}</title>
			<rect width='32' height='32' rx='16' fill='#B1E5FB' />
			<path
				d='M21.3534 17.1806L14.064 21.7958C13.1757 22.3593 12 21.6885 12 20.6152V11.3848C12 10.3115 13.1496 9.6407 14.064 10.2042L21.3534 14.8194C22.2155 15.356 22.2155 16.644 21.3534 17.1806Z'
				fill='#087DAE'
			/>
		</svg>
	);
};
