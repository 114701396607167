import styled from 'styled-components';
import ScoreTag from '../../../../common/scoring/scoringWidget/components/ScoreTag';
import { SectionHeading } from '../../shared/SectionHeading';
import { P1 } from '../../../../../designSystem/Text';
import { ScoreType } from '../../../../common/scoring/types';
import { DualColor } from '../../../types';

type RecallSubscoreWidgetProps = {
	heading: string;
	score: ScoreType;
	colors: DualColor;
	subtext: string;
};

const RecallSubscoreWidget = (props: RecallSubscoreWidgetProps) => {
	return (
		<Container>
			<SectionHeading text={props?.heading} />
			<ScoreTagWrapper
				style={{ backgroundColor: props?.colors?.background }}>
				<ScoreTag
					showTotalScoreHeader={false}
					textColor={props?.colors?.text}
					highlightColor={props?.colors?.background}
					score={props?.score}
				/>
			</ScoreTagWrapper>
			<StyledScoreSubText data-testid={'recall-score-subtext'}>
				{props?.subtext}
			</StyledScoreSubText>
		</Container>
	);
};

export { RecallSubscoreWidget };

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ScoreTagWrapper = styled.div(
	({ theme: { spacing } }) => `
	border-radius: ${spacing.sm};
	padding: ${spacing.sm};
	margin-top: ${spacing.md};
	margin-bottom: ${spacing.md};
`
);

const StyledScoreSubText = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.regular};		
    white-space: pre;    
`
);
