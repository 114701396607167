import { AssessmentStatus } from './AssessmentStatuses';

export const assessmentStrategy = (assessment: AssessmentStatus): string => {
	for (const strategy of strategies) {
		if (strategy.testAssessment(assessment)) {
			return strategy.transKey;
		}
	}
	return '';
};

const strategies = [
	{
		testAssessment: (assessment: AssessmentStatus) =>
			!assessment.webEnabled,
		transKey: 'web.report.assessmentStatus.inClinicOrRemote',
	},
	{
		testAssessment: (assessment: AssessmentStatus) =>
			assessment.tasks && assessment.mistakeCount,
		transKey: 'web.report.assessmentStatus.tasksAndMistakesWithTimestamp',
	},
	{
		testAssessment: (assessment: AssessmentStatus) =>
			assessment.tasks && assessment.dateTaken,
		transKey: 'web.report.assessmentStatus.tasksWithTimestamp',
	},
	{
		testAssessment: (assessment: AssessmentStatus) =>
			assessment.dateTaken && !assessment.tasks,
		transKey: 'web.report.assessmentStatus.dateOnly',
	},
	{
		testAssessment: (assessment: AssessmentStatus) =>
			assessment.participantName && assessment.dateTaken,
		transKey: 'web.report.assessmentStatus.participantNameWithTimestamp',
	},
	{
		testAssessment: (assessment: AssessmentStatus) =>
			assessment.participantName,
		transKey: 'web.report.assessmentStatus.participantNameOnly',
	},
];
