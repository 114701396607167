import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendEventData } from '../../../analytics/amplitude';
import { messageEnum } from '../../../enums/messageEnum';
import { useAuthServiceContext } from '../../../features/auth-service';
import { AnalyticsAction } from '../../../generated/graphql';
import {
	ResetPasswordModel,
	getModel,
	validationSchema,
} from '../../../schemas/forms/resetPasswordSchema';
import { LinusPasswordInput } from '../../shared/Forms/Components/LinusPasswordInput';
import { LinusPasswordInputWithStrength } from '../../shared/Forms/Components/LinusPasswordInputWithStrength';
import { InfoMessage } from '../../shared/InfoMessage';
import { ButtonLg, H2 } from '../../shared/designSystem';

const ResetPasswordForm = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuthServiceContext();
	const { t } = useTranslation();

	const [error, setError] = useState(false);

	const onSubmit = async (
		values: ResetPasswordModel,
		{ setSubmitting }: FormikHelpers<ResetPasswordModel>
	) => {
		try {
			const params = new URLSearchParams(location.search);
			const json = Buffer.from(
				params.get('payload') as string,
				'hex'
			).toString();
			const payload = JSON.parse(json);
			await auth.resetPassword({
				username: payload.email,
				password: values.password,
				code: payload.tempCode,
			});
			sendEventData({ eventType: AnalyticsAction.ResetPassword });
			navigate('/auth/login');
		} catch (error) {
			console.error(`Error resetting password: ${JSON.stringify(error)}`);
			setError(true);
		}

		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledH2>
							<Trans i18nKey='web.shared.resetPassword' />
						</StyledH2>
						<LinusPasswordInputWithStrength
							name='password'
							label={t`web.shared.createNewPassword`}
						/>
						<LinusPasswordInput
							name='repeatPassword'
							label={t`web.shared.repeatPassword`}
						/>
						<StyledSubmitButtonWrapper>
							<ButtonLg
								disabled={!(isValid && dirty) || isSubmitting}
								text={t`web.shared.resetPassword`}
								type='submit'
								width='317px'
								primary={true}
								dataId='resetPasswordButton'
							/>
						</StyledSubmitButtonWrapper>
						<StyledInfoMessage>
							<InfoMessage
								messageEnum={messageEnum.error(
									t`web.shared.genericError`
								)}
								showIf={error}
							/>
						</StyledInfoMessage>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { ResetPasswordForm };

//Should this component have its own interface?
//TODO: make base component
const StyledForm = styled(Form)`
	width: 578px;
	height: 601px;
	padding: 64px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: 0.4s ease all;
`;
const StyledH2 = styled(H2)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.md} 0;	
	`
);
const StyledSubmitButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.md} 0;
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	padding-bottom: ${spacing.md};
	margin: ${spacing.lg} 0 0 0;
`
);
