import { TFunction } from 'i18next';
import { convertToSentenceCase } from '../../../../utils/stringUtils';
import { MetricHash } from '../../../report/common';
import { ScoreBreakdownProps } from '../../../report/common/Breakdown/Breakdown';
import { MapDataToPropsArgs } from './ImmediateRecall6.types';
import { ImmediateRecall6LayoutProps } from './ImmediateRecall6Layout';

const formatExpectedWords = (commaSeparatedStrings: string): string => {
	const listOfWords = commaSeparatedStrings.split(',').join(', ');
	return convertToSentenceCase(listOfWords);
};

const ScoreRange = {
	Trial1: {
		max: 6,
		min: 0,
	},
	Trial2: {
		max: 6,
		min: 0,
	},
};

/**
 * Calculates the total score (value and description) from the
 * individual PVLT recall scores provided in the metric items.
 * If scores are not found in the metric items, it falls back to the minimum
 * scores as defined in the {@link ScoreRange}
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Object} An object containing the total score, with keys title and value
 * @returns {@string} value - The total score obtained
 * @returns {@string} title - The title describing the total score
 */
const getTotalScoreToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): ImmediateRecall6LayoutProps['score']['total'] => {
	const trial1Score =
		metricItems?.['pvlt_recall_score_trial_1']?.value ??
		ScoreRange['Trial1']['min'];
	const trial2Score =
		metricItems?.['pvlt_recall_score_trial_2']?.value ??
		ScoreRange['Trial2']['min'];
	const totalAchievableScore =
		ScoreRange['Trial1']['max'] + ScoreRange['Trial2']['max'];
	const totalScoreAchieved = Number(trial1Score) + Number(trial2Score);
	const totalScoreToDisplay = `${
		totalScoreAchieved === 0 ? '--' : totalScoreAchieved
	}/${totalAchievableScore}`;
	const scoreTitle = translationFn(
		'web.report.verbalFluency.correctResponses'
	)?.toUpperCase();
	return { value: totalScoreToDisplay, title: scoreTitle };
};

/**
 * Creates individual score breakdown for each "Trial", using scores as in the metrics.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Array} An array of objects depicting individual score breakdown.
 */
const getIndividualScoreBreakdown = (
	metricItems: MetricHash,
	translationFn: TFunction
): ScoreBreakdownProps['breakdown'] => {
	// Trial 1
	const trial1Title = `${translationFn(
		'web.report.verbalFluency.immediateRecallSixWord.trials.first.sequence'
	)}: ${translationFn(
		'web.report.verbalFluency.immediateRecallSixWord.trials.first.name'
	)}`;
	const trial1Score =
		metricItems?.['pvlt_recall_score_trial_1']?.value ??
		ScoreRange['Trial1']['min'];
	const isTrial1ScoreZero = Number(trial1Score) === 0;
	const trial1ScoreToDisplay = isTrial1ScoreZero
		? '--'
		: `${trial1Score}/${ScoreRange['Trial1']['max']}`;
	// Trial 2
	const trial2Title = `${translationFn(
		'web.report.verbalFluency.immediateRecallSixWord.trials.second.sequence'
	)}: ${translationFn(
		'web.report.verbalFluency.immediateRecallSixWord.trials.second.name'
	)}`;
	const trial2Score =
		metricItems?.['pvlt_recall_score_trial_2']?.value ??
		ScoreRange['Trial2']['min'];
	const isTrial2ScoreZero = Number(trial2Score) === 0;
	const trial2ScoreToDisplay = isTrial2ScoreZero
		? '--'
		: `${trial2Score}/${ScoreRange['Trial2']['max']}`;
	// Common suffix for both Trials
	const suffixTransKey = isTrial1ScoreZero
		? 'web.report.verbalFluency.cannotCalculate'
		: 'web.report.verbalFluency.correctResponses';

	return [
		{
			title: trial1Title,
			score: trial1ScoreToDisplay,
			suffix: translationFn(suffixTransKey),
		},
		{
			title: trial2Title,
			score: trial2ScoreToDisplay,
			suffix: translationFn(suffixTransKey),
		},
	];
};

/**
 * Extracts "Expected Words" data from PVLT metrics to use in Audio Card prompts.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Array} An array of 2 strings, containing a `Prompt` string for both Trials 1 and 2
 */
const getAudioPromptsToDisplay = (
	metricItems: MetricHash,
	translationFn: TFunction
): string[] => {
	const expectedWordsTrial1 = formatExpectedWords(
		metricItems?.['pvlt_expected_words_trial_1']?.value?.toString() ?? ''
	);
	const expectedWordsTrial2 = formatExpectedWords(
		metricItems?.['pvlt_expected_words_trial_2']?.value?.toString() ?? ''
	);
	const promptDisplayKey = `${translationFn(
		'web.report.verbalFluency.prompt'
	)}`;
	return [
		`${promptDisplayKey}: ${expectedWordsTrial1}`,
		`${promptDisplayKey}: ${expectedWordsTrial2}`,
	];
};

/**
 * Checks to see if the total score for both trials is zero or not.
 * @param metricItems Different metrics as obtained in the segment result.
 * @returns {boolean} whether or not the sum of the individual trials in metrics is zero.
 */
const checkIfTotalAchievedScoreIsZero = (metricItems: MetricHash): boolean => {
	const trial1Score =
		metricItems?.['pvlt_recall_score_trial_1']?.value ??
		ScoreRange['Trial1']['min'];
	const trial2Score =
		metricItems?.['pvlt_recall_score_trial_1']?.value ??
		ScoreRange['Trial2']['min'];
	const totalScore = Number(trial1Score) + Number(trial2Score);
	return totalScore === 0;
};

/**
 * Runs validations against the individual and total scores and accumulates them into a single object.
 * @param metricItems Different metrics as obtained in the segment result.
 * @param translationFn The function that translates a key into a string based on locale.
 * @returns {Object} An object with 3 values - "requiresClinicianReview", "total" & "breakdown"
 */
const getScoreInfo = (
	metricItems: MetricHash,
	translationFn: TFunction
): ImmediateRecall6LayoutProps['score'] => {
	return {
		requiresClinicianReview: checkIfTotalAchievedScoreIsZero(metricItems),
		total: getTotalScoreToDisplay(metricItems, translationFn),
		breakdown: getIndividualScoreBreakdown(metricItems, translationFn),
	};
};

/**
 * Maps the segment result data and audio artifact data into an object
 * that can be directly injected into the ImmediateRecall6Layout component as props.
 * @param args {Object} an object of the shape {@link MapDataToPropsArgs}
 * @returns props object of the shape {@link ImmediateRecall6LayoutProps}
 */
const mapDataToProps = ({
	segmentResult,
	audioData,
	translationFn,
}: MapDataToPropsArgs): ImmediateRecall6LayoutProps | null => {
	if (!segmentResult) return null;
	if (!audioData) return null;

	const metricItems = segmentResult?.metricItems ?? {};
	const promptData = getAudioPromptsToDisplay(metricItems, translationFn);
	const scoreData = getScoreInfo(metricItems, translationFn);
	const segmentName: string =
		segmentResult?.segment?.displayKey ?? 'Immediate Recall';

	return {
		header: {
			title: segmentName,
			tooltipText: translationFn(
				'web.report.verbalFluency.immediateRecallSixWord.tooltipText'
			),
		},
		audioCardData: [
			{
				text: {
					cardHeading: translationFn(
						'web.report.verbalFluency.immediateRecallSixWord.trials.first.name'
					),
					cardSubHeadings: [promptData?.[0]],
				},
				audio: {
					source: audioData?.[0]?.source ?? '',
					fileType: audioData?.[0]?.fileType ?? '',
				},
			},
			{
				text: {
					cardHeading: translationFn(
						'web.report.verbalFluency.immediateRecallSixWord.trials.second.name'
					),
					cardSubHeadings: [promptData?.[1]],
				},
				audio: {
					source: audioData?.[1]?.source ?? '',
					fileType: audioData?.[1]?.fileType ?? '',
				},
			},
		],
		score: scoreData,
	};
};

export { mapDataToProps };
