export { ReactComponent as ActionPlanPieChart } from './ActionPlanPieChart.svg';
export { ReactComponent as ActionRepeat } from './ActionRepeat.svg';
export { ReactComponent as Add } from './Add.svg';
export { ReactComponent as AddOutlined } from './AddOutlined.svg';
export { ReactComponent as AddSolid } from './AddSolid.svg';
export { ReactComponent as AddUser } from './AddUser.svg';
export { ReactComponent as AddUserOutlined } from './AddUserOutlined.svg';
export { ReactComponent as AddUserOutlineSparkle } from './AddUserOutlineSparkle.svg';
export { ReactComponent as AddUserSolid } from './AddUserSolid.svg';
export { ReactComponent as AlertShieldSolid } from './AlertShieldSolid.svg';
export { ReactComponent as AlertSolid } from './AlertSolid.svg';
export { ReactComponent as ArrowCircleLeft } from './ArrowCircleLeft.svg';
export { ReactComponent as ArrowDecrease } from './ArrowDecrease.svg';
export { ReactComponent as ArrowDown } from './ArrowDown.svg';
export { ReactComponent as ArrowFlag } from './ArrowFlag.svg';
export { ReactComponent as ArrowIncrease } from './ArrowIncrease.svg';
export { ReactComponent as ArrowLeft } from './ArrowLeft.svg';
export { ReactComponent as ArrowRight } from './ArrowRight.svg';
export { ReactComponent as ArrowUp } from './ArrowUp.svg';
export { ReactComponent as Badge } from './Badge.svg';
export { ReactComponent as BeMindfulLHQ } from './BeMindfulLHQ.svg';
export { ReactComponent as BeMindfulTapped } from './BeMindfulTapped.svg';
export { ReactComponent as BeMindfulUntapped } from './BeMindfulUntapped.svg';
export { ReactComponent as BrainCenterDotOutlined } from './BrainCenterDotOutlined.svg';
export { ReactComponent as BuildYourVillageLHQ } from './BuildYourVillageLHQ.svg';
export { ReactComponent as BuildYourVillageTapped } from './BuildYourVillageTapped.svg';
export { ReactComponent as BuildYourVillageUntapped } from './BuildYourVillageUntapped.svg';
export { ReactComponent as CameraPlusOutlined } from './CameraPlusOutlined.svg';
export { ReactComponent as CheckboxCheck } from './CheckboxCheck.svg';
export { ReactComponent as CheckboxEmpty } from './CheckboxEmpty.svg';
export { ReactComponent as CheckboxSideCheck } from './CheckboxSideCheck.svg';
export { ReactComponent as CheckmarkOutlined } from './CheckmarkOutlined.svg';
export { ReactComponent as CheckmarkSolid } from './CheckmarkSolid.svg';
export { ReactComponent as Clipboard } from './Clipboard.svg';
export { ReactComponent as Close } from './Close.svg';
export { ReactComponent as CloseOutlined } from './CloseOutlined.svg';
export { ReactComponent as CloseSolid } from './CloseSolid.svg';
export { ReactComponent as Configure } from './Configure.svg';
export { ReactComponent as Download } from './Download.svg';
export { ReactComponent as EatGreenAndLeanLHQ } from './EatGreenAndLeanLHQ.svg';
export { ReactComponent as EatGreenAndLeanTapped } from './EatGreenAndLeanTapped.svg';
export { ReactComponent as EatGreenAndLeanUntapped } from './EatGreenAndLeanUntapped.svg';
export { ReactComponent as EmptyClipboardSearch } from './EmptyClipboardSearch.svg';
export { ReactComponent as ExternalLink } from './ExternalLink.svg';
export { ReactComponent as EyeClosedOutlined } from './EyeClosedOutlined.svg';
export { ReactComponent as EyeOpenOutlined } from './EyeOpenOutlined.svg';
export { ReactComponent as FocusAreaStar } from './FocusAreaStar.svg';
export { ReactComponent as Frailty } from './Frailty.svg';
export { ReactComponent as GetGoodRestLHQ } from './GetGoodRestLHQ.svg';
export { ReactComponent as GetGoodRestTapped } from './GetGoodRestTapped.svg';
export { ReactComponent as GetGoodRestUntapped } from './GetGoodRestUntapped.svg';
export { ReactComponent as GreenCheckmark } from './GreenCheckmark.svg';
export { ReactComponent as HeartInMindSolid } from './HeartInMindSolid.svg';
export { ReactComponent as HeartShapedPersonOutlined } from './HeartShapedPersonOutlined.svg';
export { ReactComponent as Home } from './Home.svg';
export { ReactComponent as IconRestart } from './IconRestart.svg';
export { ReactComponent as Indeterminate } from './Indeterminate.svg';
export { ReactComponent as Info } from './Info.svg';
export { ReactComponent as InfoOutlined } from './InfoOutlined.svg';
export { ReactComponent as InviteOutlined } from './InviteOutlined.svg';
export { ReactComponent as Kebab } from './Kebab.svg';
export { ReactComponent as KeepHealthInCheckLHQ } from './KeepHealthInCheckLHQ.svg';
export { ReactComponent as KeepHealthInCheckTapped } from './KeepHealthInCheckTapped.svg';
export { ReactComponent as KeepHealthInCheckUntapped } from './KeepHealthInCheckUntapped.svg';
export { ReactComponent as LearnSomethingNewLHQ } from './LearnSomethingNewLHQ.svg';
export { ReactComponent as LearnSomethingNewTapped } from './LearnSomethingNewTapped.svg';
export { ReactComponent as LearnSomethingNewUntapped } from './LearnSomethingNewUntapped.svg';
export { ReactComponent as LeftPointerArrow } from './LeftPointerArrow.svg';
export { ReactComponent as LoaderWhite } from './LoaderWhite.svg';
export { ReactComponent as Logo } from './Logo.svg';
export { ReactComponent as LogoSmall } from './LogoSmall.svg';
export { ReactComponent as LogoStacked } from './LogoStacked.svg';
export { ReactComponent as MagnifyingGlass } from './MagnifyingGlass.svg';
export { ReactComponent as MoveEveryDayLHQ } from './MoveEveryDayLHQ.svg';
export { ReactComponent as MoveEveryDayTapped } from './MoveEveryDayTapped.svg';
export { ReactComponent as MoveEveryDayUntapped } from './MoveEveryDayUntapped.svg';
export { ReactComponent as NoDataUser } from './NoDataUser.svg';
export { ReactComponent as NonModifiableRiskFactorLHQ } from './NonModifiableRiskFactorLHQ.svg';
export { ReactComponent as NotFoundUser } from './NotFoundUser.svg';
export { ReactComponent as Organization } from './Organization.svg';
export { ReactComponent as PencilFilled } from './PencilFilled.svg';
export { ReactComponent as PencilOutlined } from './PencilOutlined.svg';
export { ReactComponent as PrintOutlined } from './PrintOutlined.svg';
export { ReactComponent as RedClose } from './RedClose.svg';
export { ReactComponent as RemoveUserSolid } from './RemoveUserSolid.svg';
export { ReactComponent as Results } from './Results.svg';
export { ReactComponent as ScoreCurveHigh } from './ScoreCurveHigh.svg';
export { ReactComponent as ScoreCurveLow } from './ScoreCurveLow.svg';
export { ReactComponent as ScoreCurveMedium } from './ScoreCurveMedium.svg';
export { ReactComponent as ScoreCurveVeryLow } from './ScoreCurveVeryLow.svg';
export { ReactComponent as SortArrowDownDisabled } from './SortArrowDown_Disabled.svg';
export { ReactComponent as SortArrowDownEnabled } from './SortArrowDown_Enabled.svg';
export { ReactComponent as SortArrowUpDisabled } from './SortArrowUp_Disabled.svg';
export { ReactComponent as SortArrowUpEnabled } from './SortArrowUp_Enabled.svg';
export { ReactComponent as Star } from './Star.svg';
export { ReactComponent as StarOutlined } from './StarOutlined.svg';
export { ReactComponent as StayTrueToPurposeLHQ } from './StayTrueToPurposeLHQ.svg';
export { ReactComponent as StayTrueToPurposeTapped } from './StayTrueToPurposeTapped.svg';
export { ReactComponent as StayTrueToPurposeUntapped } from './StayTrueToPurposeUntapped.svg';
export { ReactComponent as Subtract } from './Subtract.svg';
export { ReactComponent as Support } from './Support.svg';
export { ReactComponent as TestGears } from './TestGears.svg';
export { ReactComponent as Timer } from './Timer.svg';
export { ReactComponent as TraditionalArrowDown } from './TraditionalArrowDown.svg';
export { ReactComponent as TraditionalArrowRight } from './TraditionalArrowRight.svg';
export { ReactComponent as TraditionalArrowUp } from './TraditionalArrowUp.svg';
export { ReactComponent as UKCA } from './UKCA.svg';
export { ReactComponent as Users } from './Users.svg';
export { ReactComponent as UserSolid } from './UserSolid.svg';
export { ReactComponent as WarningOutlined } from './WarningOutlined.svg';
export { ReactComponent as WarningRound } from './WarningRound.svg';
export { ReactComponent as WarningSolid } from './WarningSolid.svg';
