// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const TraditionalArrowRight = ({
	title,
	width = 18,
	height = 12,
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			//you might be wondering "whats with these *magic* numbers?"
			//well this icon has whitespace around it, which makes scaling/positionion a real pain in the neck
			//so these numbers limit the viewBox to only cover the arrow and ignore the surrounding space
			viewBox='4.83 6.17 14.33 11.37'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='TraditionalArrowRight-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'TraditionalArrowRight'}</title>
			<path
				d='M13.1278 16.6834C12.9326 16.8787 12.9326 17.1953 13.1278 17.3905C13.3231 17.5858 13.6397 17.5858 13.835 17.3905L19.0036 12.2219C19.1038 12.1304 19.1666 11.9988 19.1666 11.8525L19.1666 11.8489C19.1659 11.7219 19.117 11.5952 19.0201 11.4983L13.835 6.31307C13.6397 6.11781 13.3231 6.11781 13.1278 6.31307C12.9326 6.50834 12.9326 6.82492 13.1278 7.02018L17.4602 11.3525L5.33325 11.3525C5.05711 11.3525 4.83325 11.5764 4.83325 11.8525C4.83325 12.1287 5.05711 12.3525 5.33325 12.3525L17.4587 12.3525L13.1278 16.6834Z'
				fill='current'
			/>
		</svg>
	);
};
