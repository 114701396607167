import { Chip, Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	ExpandedMeasureCell,
	ExpandedMeasureCellContainer,
	ExpandedMeasureCellContent,
	ExpandedMeasureCellLabel,
	MeasureCell,
	MeasuresDividerText,
	MeasuresSeparator,
	MeasuresStyledDivider,
	MeasuresContainer,
	NormalMeasuresContent,
} from '../styles';

export function DysexecutiveMeasures() {
	const { t } = useTranslation();

	return (
		<MeasuresContainer>
			<ExpandedMeasureCellContainer data-testid='dysexecutive-first-expanded'>
				<ExpandedMeasureCellContent>
					<ExpandedMeasureCell>
						<ExpandedMeasureCellLabel weight='600'>
							3
						</ExpandedMeasureCellLabel>
					</ExpandedMeasureCell>
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</ExpandedMeasureCellContent>
			</ExpandedMeasureCellContainer>
			<MeasuresSeparator>
				<MeasuresStyledDivider />
				<MeasuresDividerText>OR</MeasuresDividerText>
				<MeasuresStyledDivider />
			</MeasuresSeparator>
			<NormalMeasuresContent data-testid='dysexecutive-first-content'>
				<MeasureCell>
					<Chip variant='Gray' label='0-1*' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='2' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<MeasureCell>
					<Chip variant='Gray' label='0-1*' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
			</NormalMeasuresContent>
			<MeasuresSeparator>
				<MeasuresStyledDivider />
				<MeasuresDividerText>OR</MeasuresDividerText>
				<MeasuresStyledDivider />
			</MeasuresSeparator>
			<NormalMeasuresContent data-testid='dysexecutive-second-content'>
				<MeasureCell>
					<Chip variant='Gray' label='2' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
				<ExpandedMeasureCellContainer $width='62%'>
					<ExpandedMeasureCellContent data-testid='dysexecutive-second-expanded'>
						<ExpandedMeasureCell>
							<ExpandedMeasureCellLabel weight='600'>
								1
							</ExpandedMeasureCellLabel>
						</ExpandedMeasureCell>
						<Typography.P2>
							{t('web.report.cohort.breakdown.impairedMeasures')}
						</Typography.P2>
					</ExpandedMeasureCellContent>
				</ExpandedMeasureCellContainer>
			</NormalMeasuresContent>
			<MeasuresSeparator>
				<MeasuresStyledDivider />
				<MeasuresDividerText>OR</MeasuresDividerText>
				<MeasuresStyledDivider />
			</MeasuresSeparator>
			<NormalMeasuresContent>
				<ExpandedMeasureCellContainer $width='62%'>
					<ExpandedMeasureCellContent data-testid='dysexecutive-third-expanded'>
						<ExpandedMeasureCell>
							<ExpandedMeasureCellLabel weight='600'>
								1
							</ExpandedMeasureCellLabel>
						</ExpandedMeasureCell>
						<Typography.P2>
							{t('web.report.cohort.breakdown.impairedMeasures')}
						</Typography.P2>
					</ExpandedMeasureCellContent>
				</ExpandedMeasureCellContainer>
				<MeasureCell>
					<Chip variant='Gray' label='2' />
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</MeasureCell>
			</NormalMeasuresContent>
		</MeasuresContainer>
	);
}
