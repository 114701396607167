import { Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import {
	ExpandedMeasureCell,
	ExpandedMeasureCellContainer,
	ExpandedMeasureCellContent,
	ExpandedMeasureCellLabel,
	MeasuresContainer,
} from '../styles';

export function SubtleMeasures() {
	const { t } = useTranslation();

	return (
		<MeasuresContainer>
			<ExpandedMeasureCellContainer>
				<ExpandedMeasureCellContent>
					<ExpandedMeasureCell>
						<ExpandedMeasureCellLabel weight='600'>
							2
						</ExpandedMeasureCellLabel>
					</ExpandedMeasureCell>
					<Typography.P2>
						{t('web.report.cohort.breakdown.impairedMeasures')}
					</Typography.P2>
				</ExpandedMeasureCellContent>
			</ExpandedMeasureCellContainer>
		</MeasuresContainer>
	);
}
