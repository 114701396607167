import {
	ReactNode,
	createContext,
	useCallback,
	useEffect,
	useRef,
} from 'react';

import { config } from '../../config';
import { useAuthServiceContext } from '../../features/auth-service';

// Make sure to keep this value in sync with the inactivity timeout i.e. "idle_token_lifetime"
// Link to source code - https://github.com/linushealth/cloudeng-iac-modules/pull/954/files#diff-9c20118417a31b6882e62bb956b26b70d4867ff8f6ffdf4d27e92bbacee139c3R75
const THIRTY_MINUTES = 30 * 60 * 1000;

export const userInteractionEvents = {
	CLICK: 'click',
	LOAD: 'load',
	KEY_DOWN: 'keydown',
	MOUSE_MOVE: 'mousemove',
	TOUCH_START: 'touchstart',
};

export type SessionTimeoutContextProps = {
	startTimer: () => void;
	resetTimer: () => void;
};

export const SessionTimeoutContext = createContext<SessionTimeoutContextProps>({
	startTimer: () => {
		// do nothing
	},
	resetTimer: () => {
		// do nothing
	},
});

export const SessionTimeoutProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const timeoutIdRef = useRef<number>();
	const auth = useAuthServiceContext();
	const sessionTimeout = THIRTY_MINUTES;

	const startTimer = useCallback(() => {
		timeoutIdRef.current = window.setTimeout(async () => {
			await auth.logout(`${config.researchDomainUrl}/session-timeout`);
		}, sessionTimeout);
	}, [auth, sessionTimeout]);

	const resetTimer = useCallback(() => {
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
		}
		startTimer();
	}, [startTimer]);

	useEffect(() => {
		const events = [
			userInteractionEvents.CLICK,
			userInteractionEvents.LOAD,
			userInteractionEvents.KEY_DOWN,
			userInteractionEvents.MOUSE_MOVE,
			userInteractionEvents.TOUCH_START,
		];
		const resetTimeout = () => resetTimer();
		// reset inactivity timer in case of any of the above mentioned user interactions
		events.forEach((event) => window.addEventListener(event, resetTimeout));
		startTimer();

		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			events.forEach((event) =>
				window.removeEventListener(event, resetTimeout)
			);
		};
	}, [resetTimer, startTimer]);

	return (
		<SessionTimeoutContext.Provider value={{ startTimer, resetTimer }}>
			{children}
		</SessionTimeoutContext.Provider>
	);
};
