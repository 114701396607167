import amplitude from 'amplitude-js';
import { debounce } from 'lodash';
import { config } from '../config';
import { AnalyticsAction } from '../generated/graphql';
import { CurrentUser } from '../types';
import { analyticsAction as analyticsActionEnum } from '../enums/analyticsAction';

const HALF_SECOND_DELAY_MILLIS = 500;

type SendEventDataProps = {
	eventType: AnalyticsAction;
	eventProperties?: Record<string, string | boolean | string[]>;
};

export const updateAmplitudeWithProps = (currentUser: CurrentUser): void => {
	const {
		id,
		role,
		organizationId,
		organizationName,
		organizationType,
		organizationStatus,
	} = currentUser;
	try {
		amplitude.getInstance().setUserId(id);
		amplitude.getInstance().setUserProperties({
			role,
			orgId: organizationId,
			orgName: organizationName,
			orgType: organizationType,
			orgStatus: organizationStatus,
		});
	} catch (e) {
		throw new Error(
			'Error updating Amplitude session with user properties'
		);
	}
};

export const initAmplitude = (): void => {
	try {
		amplitude.getInstance().init(config.amplitudeApiKey);
	} catch (e) {
		throw new Error('Error instantiating Amplitude SDK');
	}
};

export const sendEventData = ({
	eventType,
	eventProperties = {},
}: SendEventDataProps): void => {
	const eventDisplay = analyticsActionEnum.fromValue(eventType)?.display;
	if (!eventDisplay) {
		console.error('No display property for event type: ', eventType);
		return;
	}

	amplitude
		.getInstance()
		.logEvent(eventDisplay.toLowerCase(), eventProperties);
};

export const sendDebouncedEventData = debounce(
	sendEventData,
	HALF_SECOND_DELAY_MILLIS,
	{ trailing: true }
);

export const logout = (): void => {
	sendEventData({ eventType: AnalyticsAction.LoggedOut });
	amplitude.getInstance().setUserId(null);
	amplitude.getInstance().setUserProperties({
		role: null,
		orgId: null,
		orgName: null,
		orgType: null,
		orgStatus: null,
	});
};
