import React, { useContext, useEffect, useState } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { Role, ValueListType, useInviteUserToOrg } from 'api/organization';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { AnalyticsAction } from '../../../enums/analyticsAction';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { ERROR, INFO } from '../../../logging/linusLogger';
import { AuthProvider } from '../../../providers/AuthProvider';
import {
	AddNewUserModel,
	addNewUserSchema,
	getNewUserModel,
} from '../../../schemas/forms/addNewUserSchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { ButtonLg } from '../../shared/designSystem';
import { UserRoleDefinitions } from './UserRoleDefinitions';
import { InviteUserEvents } from '../events';

const TAG_NAME = 'AddNewUserForm';

export type AddNewUserFormProps = {
	roles: Role[] | undefined;
	onCancel?: (event: React.MouseEvent) => void;
	onFinish?: () => void;
};

const AddNewUserForm = ({
	roles = [],
	onCancel,
	onFinish,
}: AddNewUserFormProps): JSX.Element => {
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t, i18n } = useTranslation();

	const {
		mutateAsync: inviteNewUserMutation,
		isPending,
		isError,
		isSuccess,
		error,
	} = useInviteUserToOrg();

	useEffect(() => {
		if (isPending) return;
		if (isSuccess) {
			INFO(TAG_NAME, InviteUserEvents.SUCCESS);
			sendEventData({ eventType: AnalyticsAction.InvitedNewUser });
			onFinish && onFinish();
		}
		if (isError) {
			const message = getErrorMessage(error, t);
			ERROR(TAG_NAME, {
				message: InviteUserEvents.FAILURE,
				error: error,
			});
			setServerSideMessage(messageEnum.error(message));
		}
	}, [error, isError, isPending, isSuccess, onFinish, t]);

	const onSubmit = async (
		values: AddNewUserModel,
		{ setSubmitting }: FormikHelpers<AddNewUserModel>
	) => {
		// TODO FIGURE OUT HOW TO POST AN IMAGE TO GRAPHQL
		delete values.avatarUrl;
		try {
			INFO(TAG_NAME, InviteUserEvents.INIT);
			await inviteNewUserMutation({
				organizationId: currentUser?.organizationId,
				userInvite: {
					...values,
					organizationId: currentUser?.organizationId,
					phoneNumber: values.phoneNumber,
					suffixIds: values?.suffixIds?.split(', ').filter(Boolean), //remove empty string,
					clientId: AuthProvider.getClientId(),
				},
			});
		} catch (error) {
			ERROR('Invite user failed', error);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={getNewUserModel()}
			validationSchema={addNewUserSchema()}
			onSubmit={onSubmit}>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledRow>
							<LinusInput
								width='257px'
								name='firstName'
								label={t`web.team.sharedModal.firstNameLabel`}
							/>
							<LinusInput
								width='257px'
								name='lastName'
								label={t`web.team.sharedModal.lastNameLabel`}
							/>
							<LinusInput
								width='257px'
								name='suffixIds'
								label={t`web.team.sharedModal.suffixLabel`}
								type='multi'
								preferDisplayLength='long'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Suffix
								]?.toOptions()}
							/>
						</StyledRow>
						<StyledRow>
							<LinusInput
								name='email'
								label={t`web.team.sharedModal.emailAddressLabel`}
								type='email'
								width='402px'
							/>
							<LinusInput
								name='phoneNumber'
								label={t`web.team.sharedModal.phoneNumberLabel`}
								width='402px'
							/>
						</StyledRow>
						<StyledRow>
							<LinusInput
								name='roleId'
								type='select'
								label={t`web.team.sharedModal.userRoleLabel`}
								width='100%'
								dropdownOptions={(roles || []).map((r) => ({
									display: i18n.t(r.displayKey),
									value: r.id,
								}))}
								dropUpSpace={theme.spacing.sm}
							/>
						</StyledRow>
						<StyledInfoMessage>
							<InfoMessage
								messageEnum={serverSideMessage}
								showIf={!!serverSideMessage}
							/>
						</StyledInfoMessage>
						<UserRoleDefinitions roles={roles} />
						<StyledButtonRow>
							{isValid && dirty && (
								<StyledButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.team.sharedModal.cancelButton`}
										primary={false}
										width='200px'
									/>
								</StyledButtonWrapper>
							)}
							<ButtonLg
								primary={true}
								disabled={!(isValid && dirty) || isSubmitting}
								text={t`web.team.addNewUserModal.inviteButton`}
								type='submit'
								width='200px'
							/>
						</StyledButtonRow>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { AddNewUserForm };

const StyledForm = styled(Form)`
	width: min-content;
	margin: 0;
	padding: 0;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 836px;
`;
const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		padding: ${spacing.md};
	`
);
const StyledButtonWrapper = styled.div`
	margin: 0 32px 0 0;
`;

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => css`
		padding-bottom: ${spacing.sm};
	`
);

const getErrorMessage = (error: Error, t: TFunction) => {
	if (
		error?.message?.includes('UsernameExistsException') ||
		error?.message?.includes('Unique constraint')
	) {
		return t`web.team.addNewUserModal.error.accountExists`;
	} else if (
		(error as unknown as { errorCode: string })?.errorCode ===
		'CROSS_PRODUCT_USER_NOT_ALLOWED'
	) {
		return t`web.team.addNewUserModal.error.crossProduct`;
	} else {
		return t`web.team.addNewUserModal.error.default`;
	}
};
