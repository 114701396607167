import { useTranslation } from 'react-i18next';
import { icons } from '../../../enums/icons';
import { SortDir } from '../../../generated/graphql';
import { Icon } from '../designSystem/Icon';
import styled from 'styled-components';

interface SortArrowProps {
	sortDir?: string;
}

const SortArrow = ({ sortDir }: SortArrowProps): JSX.Element => {
	const { t } = useTranslation();

	const { upArrow, downArrow } = getArrowsForSortDir(sortDir);

	return (
		<>
			<Icon
				icon={upArrow}
				iconWidth={8}
				iconHeight={5}
				title={t`web.dataTable.sortArrow.arrowUp`}
			/>
			<StyledIconHolder>
				<Icon
					icon={downArrow}
					iconWidth={8}
					iconHeight={5}
					title={t`web.dataTable.sortArrow.arrowDown`}
				/>
			</StyledIconHolder>
		</>
	);
};

const getArrowsForSortDir = (sortDir: string | undefined) => {
	let upArrow = icons.SortArrowUpDisabled;
	let downArrow = icons.SortArrowDownDisabled;

	if (sortDir === SortDir.Asc) {
		upArrow = icons.SortArrowUpEnabled;
		downArrow = icons.SortArrowDownDisabled;
	} else if (sortDir === SortDir.Desc) {
		upArrow = icons.SortArrowUpDisabled;
		downArrow = icons.SortArrowDownEnabled;
	}

	return {
		upArrow,
		downArrow,
	};
};

const StyledIconHolder = styled.div`
	margin-top: 2px;
`;

export { SortArrow };
