import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../../metrics';
import { TFunction } from 'react-i18next';

export const getCompositeScalesDataSet = (
	dataMap: MetricHash,
	t: TFunction
): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const compositeScalesMap = {
		commandClock: [
			{
				x: t(compositeScalesStrings.drawingEfficiency),
				y: dataMap?.COMDrawingEfficiency_s?.value,
			},
			{
				x: t(compositeScalesStrings.motor),
				y: dataMap?.COMSimpleMotor_s?.value,
			},
			{
				x: t(compositeScalesStrings.infoProcessing),
				y: dataMap?.COMInformationProcessing_s?.value,
			},
			{
				x: t(compositeScalesStrings.spatialReasoning),
				y: dataMap?.COMSpatialReasoning_s?.value,
			},
		],
		copyClock: [
			{
				x: t(compositeScalesStrings.drawingEfficiency),
				y: dataMap?.COPDrawingEfficiency_s?.value,
			},
			{
				x: t(compositeScalesStrings.motor),
				y: dataMap?.COPSimpleMotor_s?.value,
			},
			{
				x: t(compositeScalesStrings.infoProcessing),
				y: dataMap?.COPInformationProcessing_s?.value,
			},
			{
				x: t(compositeScalesStrings.spatialReasoning),
				y: dataMap?.COPSpatialReasoning_s?.value,
			},
		],
	};

	return compositeScalesMap as DataSet;
};

export const compositeScalesStrings = {
	drawingEfficiency: 'web.report.segmentUtils.drawingEfficiency',
	motor: 'web.report.segmentUtils.simpleComplexMotor',
	infoProcessing: 'web.report.segmentUtils.infoProcessing',
	spatialReasoning: 'web.report.segmentUtils.spatialReasoning',
};
