/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

//################################################################################
//Action triggers
//################################################################################

import { dctListener } from './listener';
import { dctJSON } from './json';
import { dctComponents } from './components';

//Action listener
export const dctActions = new (function () {
	//Add listener properties
	dctListener.extendObject(this);

	//Initialize event listener
	window.addEventListener(
		'click',
		function (arg_event) {
			//Find component
			var action_target = arg_event.target;
			while (
				action_target &&
				action_target.hasAttribute &&
				!action_target.hasAttribute('data-action')
			)
				action_target = action_target.parentNode;
			if (!action_target || !action_target.hasAttribute) return;

			//Get values
			var action_key = action_target.getAttribute('data-action');
			var action_params_text =
				action_target.getAttribute('data-action-params');
			var action_params =
				(action_params_text
					? dctJSON.parseOrNull(action_params_text)
					: null) || {};

			//Prevent default from this point onLine
			arg_event.preventDefault();
			arg_event.stopPropagation();

			//Cast an event
			dctActions.triggerAction(action_key, action_params, action_target);
		},
		false
	);

	//Trigger an action
	this.triggerAction = function triggerAction(
		arg_action_key,
		arg_params,
		arg_target
	) {
		this.triggerEventListener(arg_action_key, [arg_params, arg_target]);
	};

	//Element listener
	this.addQuerySelectorEventListener = function addQuerySelectorEventListener(
		arg_event,
		arg_query,
		arg_func,
		arg_context
	) {
		//Add an event listener
		this.addEventListener(
			arg_event,
			function querySelectorListener() {
				var elements = document.body.querySelectorAll(arg_query);
				for (var i = 0; i < elements.length; i++) {
					arg_func.apply(elements[i], arguments);
				}
			},
			arg_context
		);
	};

	//Element listener
	this.addQueryComponentEventListener =
		function addQueryComponentEventListener(
			arg_event,
			arg_component,
			arg_func,
			arg_context
		) {
			//Add an event listener
			this.addEventListener(
				arg_event,
				function querySelectorListener() {
					var components = dctComponents.queryComponents(
						document.body,
						'*',
						arg_component
					);
					for (var i = 0; i < components.length; i++) {
						arg_func.apply(components[i], arguments);
					}
				},
				arg_context
			);
		};
})();

//Common actions
dctActions.addEventListener('print-dialog', function () {
	window.print();
});
