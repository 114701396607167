import { useQuery } from '@tanstack/react-query';

import { getRemoteAssessmentsService } from '../getRemoteAssessmentsService';
import { QueryKey } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';

export interface GetLinkParams {
	assignmentId: string;
	organizationId: string;
}
export async function getLinkByAssignmentId({
	assignmentId,
	organizationId,
}: Partial<GetLinkParams>) {
	if (!isNonEmptyString(assignmentId) || !isNonEmptyString(organizationId)) {
		throw new Error(
			"getLink: assignmentId and organizationId can't be null"
		);
	}
	const service = await getRemoteAssessmentsService();
	const { data } = await service.getLinkById({
		assignmentId,
		organizationId,
	});
	return data;
}

export function useGetLinkByAssignmentId({
	assignmentId,
	organizationId,
}: Partial<GetLinkParams>) {
	return useQuery({
		enabled:
			isNonEmptyString(assignmentId) && isNonEmptyString(organizationId),
		meta: {
			errorMessage: `Error fetching assignment ${assignmentId}`,
		},
		queryKey: [QueryKey.RemoteLink, assignmentId],
		queryFn: () => getLinkByAssignmentId({ assignmentId, organizationId }),
		staleTime: Infinity,
	});
}
