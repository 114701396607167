import { AuthFactory, AuthProviderType } from '@lh/eng-shared-auth';
import { config } from '../../config';
import { AuthStorage } from './AuthStorage';

export function createAuthService(sso: boolean) {
	const baseUrl = `${config.apiProtocol}://${config.apiHost}:${config.apiPort}/${config.apiDomain}`;

	if (config.preferredUserProvider === 'auth0') {
		return new AuthFactory(
			{
				type: AuthProviderType.Auth0,
				clientId: config.auth0ClientId,
				domain: config.auth0Domain,
				baseUrl,
				cookieDomain: config.cookieDomain,
				sso,
			},
			new AuthStorage() // We need this in case SSO is false (https://github.com/linushealth/eng-shared-auth/blob/main/src/auth.ts#L61)
		);
	}

	return new AuthFactory({
		type: AuthProviderType.Cognito,
		amplifyRegion: config.amplifyRegion,
		amplifyUserPoolId: config.amplifyUserPoolId,
		amplifyUserPoolWebClientId: config.amplifyUserPoolWebClientId,
		host: config.host,
		baseUrl,
	});
}
