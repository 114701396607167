export const glossary = [
	{
		term: `web.dcrReport.glossary.averageLatency.term`,
		definition: `web.dcrReport.glossary.averageLatency.definition`,
	},
	{
		term: `web.dcrReport.glossary.averageSpeed.term`,
		definition: `web.dcrReport.glossary.averageSpeed.definition`,
	},
	{
		term: `web.dcrReport.glossary.clockFaceCircularity.term`,
		definition: `web.dcrReport.glossary.clockFaceCircularity.definition`,
	},
	{
		term: `web.dcrReport.glossary.commandClock.term`,
		definition: `web.dcrReport.glossary.commandClock.definition`,
	},
	{
		term: `web.dcrReport.glossary.componentPlacement.term`,
		definition: `web.dcrReport.glossary.componentPlacement.definition`,
	},
	{
		term: `web.dcrReport.glossary.compositeScales.term`,
		definition: `web.dcrReport.glossary.compositeScales.definition`,
	},
	{
		term: `web.dcrReport.glossary.copyClock.term`,
		definition: `web.dcrReport.glossary.copyClock.definition`,
	},
	{
		term: `web.dcrReport.glossary.delayedRecall.term`,
		definition: `web.dcrReport.glossary.delayedRecall.definition`,
	},
	{
		term: `web.dcrReport.glossary.drawingEfficiency.term`,
		definition: `web.dcrReport.glossary.drawingEfficiency.definition`,
	},
	{
		term: `web.dcrReport.glossary.drawingProcessEfficiency.term`,
		definition: `web.dcrReport.glossary.drawingProcessEfficiency.definition`,
	},
	{
		term: `web.dcrReport.glossary.drawingSize.term`,
		definition: `web.dcrReport.glossary.drawingSize.definition`,
	},
	{
		term: `web.dcrReport.glossary.horizontalSpatialPlacement.term`,
		definition: `web.dcrReport.glossary.horizontalSpatialPlacement.definition`,
	},
	{
		term: `web.dcrReport.glossary.immediateRecall.term`,
		definition: `web.dcrReport.glossary.immediateRecall.definition`,
	},
	{
		term: `web.dcrReport.glossary.informationProcessing.term`,
		definition: `web.dcrReport.glossary.informationProcessing.definition`,
	},
	{
		term: `web.dcrReport.glossary.initiationSpeed.term`,
		definition: `web.dcrReport.glossary.initiationSpeed.definition`,
	},
	{
		term: `web.dcrReport.glossary.inkLength.term`,
		definition: `web.dcrReport.glossary.inkLength.definition`,
	},
	{
		term: `web.dcrReport.glossary.latencyVariability.term`,
		definition: `web.dcrReport.glossary.latencyVariability.definition`,
	},
	{
		term: `web.dcrReport.glossary.longLatencyCount.term`,
		definition: `web.dcrReport.glossary.longLatencyCount.definition`,
	},
	{
		term: `web.dcrReport.glossary.longestLatencyCount.term`,
		definition: `web.dcrReport.glossary.longestLatencyCount.definition`,
	},
	{
		term: `web.dcrReport.glossary.maxSpeed.term`,
		definition: `web.dcrReport.glossary.maxSpeed.definition`,
	},
	{
		term: `web.dcrReport.glossary.noise.term`,
		definition: `web.dcrReport.glossary.noise.definition`,
	},
	{
		term: `web.dcrReport.glossary.oscillatoryMotion.term`,
		definition: `web.dcrReport.glossary.oscillatoryMotion.definition`,
	},
	{
		term: `web.dcrReport.glossary.percentInkTime.term`,
		definition: `web.dcrReport.glossary.percentInkTime.definition`,
	},
	{
		term: `web.dcrReport.glossary.percentThinkTime.term`,
		definition: `web.dcrReport.glossary.percentThinkTime.definition`,
	},
	{
		term: `web.dcrReport.glossary.relativeLongLatency.term`,
		definition: `web.dcrReport.glossary.relativeLongLatency.definition`,
	},
	{
		term: `web.dcrReport.glossary.simpleAndComplexMotor.term`,
		definition: `web.dcrReport.glossary.simpleAndComplexMotor.definition`,
	},
	{
		term: `web.dcrReport.glossary.spatialReasoning.term`,
		definition: `web.dcrReport.glossary.spatialReasoning.definition`,
	},
	{
		term: `web.dcrReport.glossary.strokeCountConformity.term`,
		definition: `web.dcrReport.glossary.strokeCountConformity.definition`,
	},
	{
		term: `web.dcrReport.glossary.terminationSpeed.term`,
		definition: `web.dcrReport.glossary.terminationSpeed.definition`,
	},
	{
		term: `web.dcrReport.glossary.totalTime.term`,
		definition: `web.dcrReport.glossary.totalTime.definition`,
	},
	{
		term: `web.dcrReport.glossary.verticalSpatialPlacement.term`,
		definition: `web.dcrReport.glossary.verticalSpatialPlacement.definition`,
	},
];
