import { useContext, useState, useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { OperationToken } from 'api/organization';
import { Participant, useUpdateParticipant } from 'api/participant';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { handedness } from '../../../enums/participantEnums/handedness';
import { AnalyticsAction, ValueListType } from '../../../generated/graphql';
import { ERROR } from '../../../logging/linusLogger';
import {
	EditParticipantModel,
	editParticipantSchema,
	getModel,
} from '../../../schemas/forms/editParticipantSchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { ShowIfAuthorized } from '../../shared/ShowIfAuthorized';
import { ButtonLg } from '../../shared/designSystem';
import { messages } from '../../shared/errorMessages';
import { yearOptions } from '../AddParticipantForm';
import { buildLanguageDisplayOptions } from '../AddParticipantForm/AddParticipantForm';
import { CONTACT_PREFERENCES, getGendersList } from '../participantHelpers';
import { FeatureType } from '@lh/eng-platform-organization-service-rest-client';

type EditParticipantFormProps = {
	participantInfo: Participant;
	onCancel: () => void;
};

const EditParticipantForm = ({
	participantInfo: data,
	onCancel,
}: EditParticipantFormProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t } = useTranslation();

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [duplicate, setDuplicate] = useState<string>();
	const {
		mutateAsync: updateParticipant,
		error: updateError,
		isPending,
	} = useUpdateParticipant();
	const hasRemoteAssessmentsFlag = currentUser.organizationFeatures?.includes(
		FeatureType.RemoteAssessment
	);
	useEffect(() => {
		if (!updateError) return;

		if (updateError.response?.data.errorCode === 'EXTERNAL_ID_EXISTS') {
			setDuplicate(
				t('web.participant.forms.errors.externalId.duplicate')
			);
		} else {
			// probably log this.  But I think payload errors are logged server side.
			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
		}
		ERROR(updateError?.message as string, updateError);
		return;
	}, [updateError, t]);
	const onSubmit = async (
		values: EditParticipantModel,
		{ setSubmitting }: FormikHelpers<EditParticipantModel>
	) => {
		let contactPreference = null;
		if (values.contactEmail && !values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.EMAIL_ONLY;
		}
		if (!values.contactEmail && values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.PHONE_ONLY;
		}
		if (values.contactEmail && values.contactPhone) {
			contactPreference = CONTACT_PREFERENCES.EMAIL_AND_PHONE;
		}
		const result = await updateParticipant({
			participantId: values.id,
			participant: {
				birthYear: parseInt(values.birthYear),
				contactEmail: values.contactEmail || null,
				contactPhone: values.contactPhone || null,
				...(contactPreference !== null && {
					contactPreference: contactPreference,
				}),
				educationIds: values.educationId ? [values.educationId] : [],
				ethnicityIds: values?.ethnicityIds
					? values.ethnicityIds.split(/, ?/)
					: [],
				externalId: values.externalId.trim(),
				gender: values?.gender || null,
				handedness: values?.handedness || null,
				language: values.language,
				notes: values.notes ?? null,
				raceIds: values.raceId ? [values.raceId] : [],
			},
			organizationId: currentUser.organizationId,
		});
		if (result.status !== 200) return;
		sendEventData({
			eventType: AnalyticsAction.UpdatedUser,
		});
		setShowSuccessModal(true);
		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel(
				currentUser.organizationParticipantLanguages,
				data
			)}
			initialErrors={duplicate}
			validationSchema={editParticipantSchema}
			onSubmit={onSubmit}>
			{({ isSubmitting, values }) => {
				const genders = getGendersList();

				return showSuccessModal ? (
					<LinusModalDialog
						onClose={onCancel}
						title={t`web.participant.modal.title.updated`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonCallback={onCancel}>
						<Trans
							i18nKey={'web.participant.modal.updatedSuccess'}
							values={{
								entity: values?.externalId,
							}}
							components={[
								<strong key={'styled_strong_success'} />,
							]}
						/>
					</LinusModalDialog>
				) : (
					<StyledForm>
						<Row>
							<LinusInput
								name='externalId'
								label={t(
									'research.participantModal.participantId',
									{
										context:
											currentUser.organizationType.value,
									}
								)}
								error={duplicate}
							/>
							<LinusInput
								name='birthYear'
								type='select'
								label={`${t(
									'web.participant.forms.birthYear'
								)}`}
								dropdownOptions={yearOptions}
							/>
						</Row>
						<Row>
							<LinusInput
								name='language'
								type='select'
								label={t`web.patients.forms.languageLabel`}
								dropdownOptions={buildLanguageDisplayOptions(
									currentUser.organizationParticipantLanguages
								)}
								preferDisplayLength='short'
							/>
							<LinusInput
								name='gender'
								type='select'
								label={`${t(
									'web.patients.forms.genderLabel'
								)} ${t('web.shared.forms.optional')}`}
								dropdownOptions={genders}
							/>
						</Row>
						<Row>
							<LinusInput
								name='raceId'
								type='select'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Race
								]?.toOptions()}
								label={t`web.patients.forms.raceLabel`}
							/>
							<LinusInput
								name='ethnicityIds'
								type='multi'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Ethnicity
								]?.toOptions()}
								label={t`web.patients.forms.ethnicityLabel`}
							/>
						</Row>
						<Row>
							<LinusInput
								name='educationId'
								type='select'
								dropdownOptions={currentUser?.organizationValueLists?.[
									ValueListType.Education
								]?.toOptions()}
								label={`${t(
									'web.patients.forms.educationLevelLabel'
								)} ${t('web.shared.forms.optional')}`}
							/>
							<LinusInput
								name='handedness'
								type='select'
								dropdownOptions={handedness?.toOptions()}
								label={`${t(
									'web.patients.forms.handednessLabel'
								)} ${t('web.shared.forms.optional')}`}
							/>
						</Row>
						{hasRemoteAssessmentsFlag && (
							<Row>
								<LinusInput
									name='contactEmail'
									label={t`web.patients.forms.email`}
								/>
								<LinusInput
									label={t`web.patients.forms.phone`}
									name='contactPhone'
									type='phone'
								/>
							</Row>
						)}
						<Row>
							<LinusInput
								label={t`web.patients.forms.notesLabel`}
								name='notes'
								type='textarea'
								width='667px'
								rows={2}
							/>
						</Row>
						<InfoMessage
							messageEnum={serverSideMessage}
							showIf={!!serverSideMessage}
						/>
						<ButtonRow>
							<ButtonLg
								onClick={onCancel}
								text={t`web.participant.forms.cancelCTA`}
								width='200px'
							/>
							<ShowIfAuthorized
								operations={[OperationToken.EditParticipant]}>
								<ButtonLg
									primary
									disabled={isSubmitting}
									loading={isPending}
									text={t`web.participant.forms.saveCTA`}
									type='submit'
									width='200px'
								/>
							</ShowIfAuthorized>
						</ButtonRow>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { EditParticipantForm };

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 667px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ButtonRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		padding-top: ${spacing.xxl};
		gap: ${spacing.lg};
	`
);
