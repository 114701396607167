import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { SingleSelectRowType } from '../../../common/types/CustomQnr.types';
import {
	PHQ8QnrTableData,
	buildPHQ8QnrTableData,
	getPHQ8TableColumns,
} from './PHQ-8.table.helpers';

type PHQ8TableProps = {
	tableRowData?: SingleSelectRowType[];
};

export function PHQ8Table({ tableRowData }: Readonly<PHQ8TableProps>) {
	const { t } = useTranslation();

	const tableData = useMemo(() => {
		if (tableRowData) {
			return buildPHQ8QnrTableData(tableRowData);
		}
	}, [tableRowData]);

	const tableColumns = useMemo(() => getPHQ8TableColumns(t), [t]);

	if (!tableData) {
		return null;
	}

	return (
		<SimpleTable<PHQ8QnrTableData>
			columns={tableColumns}
			data={tableData}
			horizontalSpacing={'xs'}
			styles={{ td: { verticalAlign: 'top', width: '50%' } }}
		/>
	);
}
