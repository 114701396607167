import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';

import { StyleProvider as MosaicStyleProvider } from '@lh/eng-web-mosaic-common';

import { useStoreReset } from 'store';
import { initAmplitude } from '../analytics/amplitude';
import { AuthServiceProvider } from '../features/auth-service';
import { FeatureFlagsProvider } from '../features/feature-flags';
import { ApolloProvider } from '../providers';
import { BrowserRestrictionProvider } from '../providers/BrowserRestrictionProvider/BrowserRestrictionProvider';
import { I18nextProvider } from '../providers/I18nextProvider/I18nextProvider';
import { RouteProvider } from '../providers/routeProvider';
import { StyleProvider } from '../providers/styleProvider';
import { AvatarProvider } from '../providers/styleProvider/AvatarProvider';

export const App = (): JSX.Element => {
	useStoreReset(); // TODO: @Kunal/@Shane Refactor this once UserContext refactor work is complete
	initAmplitude();
	return (
		<FeatureFlagsProvider>
			<AuthServiceProvider>
				<MosaicStyleProvider>
					<StyleProvider>
						<I18nextProvider>
							<BrowserRestrictionProvider>
								<BrowserRouter>
									<ApolloProvider>
										<ModalProvider>
											<AvatarProvider>
												<RouteProvider />
											</AvatarProvider>
										</ModalProvider>
									</ApolloProvider>
								</BrowserRouter>
							</BrowserRestrictionProvider>
						</I18nextProvider>
					</StyleProvider>
				</MosaicStyleProvider>
			</AuthServiceProvider>
		</FeatureFlagsProvider>
	);
};
