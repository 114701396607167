import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
	selected: boolean;
	children: React.ReactNode;
};

const SingleSelectChoice = (props: Props) => {
	return <Wrapper selected={props?.selected}>{props?.children}</Wrapper>;
};

export const Wrapper = styled.div<{ selected: boolean }>(
	({ selected, theme: { colors, spacing } }) => css`
		padding: 2px 8px 2px 8px;
		background-color: ${selected ? `${colors.gray_80}` : 'unset'};
		border: ${selected ? `1px solid ${colors.gray_60}` : 'unset'};
		border-radius: ${spacing.xs};
	`
);

export { SingleSelectChoice };
