import { Flex } from '@mantine/core';
import { ConfidenceScoreRange } from 'components/report/Epsom/common/constants/confidenceScore';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { P2 } from '../../../../../../../shared/designSystem';
import {
	getConfidenceScoreContainerBgColor,
	getConfidenceScoreDescriptionTransKey,
	getRangeStartAndEnd,
} from '../../../../../common/helpers/confidenceScoreUtils';

type Props = {
	scoreRange: ConfidenceScoreRange;
	showSeparator?: boolean;
};

function ConfidenceScoreLegendRow({ showSeparator = true, scoreRange }: Props) {
	const { t } = useTranslation();

	if (!scoreRange) return null;

	const iconColor =
		getConfidenceScoreContainerBgColor(scoreRange)?.textContainer;
	const scoreRangeDescription = t(
		getConfidenceScoreDescriptionTransKey(scoreRange)
	);
	const range = getRangeStartAndEnd(scoreRange);
	const rangeValues = `${range?.start}-${range?.end}`;
	return (
		<>
			<Flex direction={'row'}>
				{/* Icon */}
				<div
					data-testid='legend-icon'
					style={{
						backgroundColor: iconColor,
						height: 12,
						width: 12,
						borderRadius: 8,
					}}></div>
				{/* Score Range */}
				<StyledScoreRangeText data-testid='legend-range-value'>
					{rangeValues}
				</StyledScoreRangeText>
				{/* Range Description */}
				<StyledScoreRangeDescriptionText data-testid='legend-range-description'>
					{scoreRangeDescription}
				</StyledScoreRangeDescriptionText>
			</Flex>
			{<StyledSeparator $showDivider={showSeparator} />}
		</>
	);
}

const StyledScoreRangeText = styled(P2)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.medium};	
	margin-left: ${spacing.sm};
	min-width: ${spacing.xxxxxl}
    
`
);

const StyledScoreRangeDescriptionText = styled(P2)(
	({ theme: { weight, spacing } }) => `
	font-weight: ${weight.regular};	
	margin-left: ${spacing.md}    
`
);

const StyledSeparator = styled.div<{ $showDivider?: boolean }>(
	({ $showDivider = true, theme: { color, spacing } }) => `
	position: relative;
	width: 100%;  
	border-bottom: ${$showDivider ? `1px` : `0`} solid ${color.infoSeparator};	
	margin-top: ${spacing.sm};
	margin-bottom: ${spacing.sm};
`
);

export default ConfidenceScoreLegendRow;
