import styled from 'styled-components';

import { ActionPlanPillarTitle } from '../ActionPlanPillarTitle';

type ActionPlanPillarPointerProps = {
	title: string;
	width: string;
	subtitle?: string;
	reverse?: boolean;
	focusArea?: boolean;
	fontSize?: string;
	subtitleFontSize?: string;
};

export const ActionPlanPillarPointer = ({
	title,
	width,
	subtitle = '',
	reverse,
	focusArea,
	fontSize,
	subtitleFontSize,
}: ActionPlanPillarPointerProps): JSX.Element => {
	return (
		<StyledContainer reverse={reverse}>
			{!reverse && <StyledPointer width={width}>&nbsp;</StyledPointer>}
			<StyledTitle reverse={reverse}>
				<ActionPlanPillarTitle
					title={title}
					subtitle={subtitle}
					reverse={reverse}
					focusArea={focusArea}
					fontSize={fontSize}
					isBold
					subtitleFontSize={subtitleFontSize}
				/>
			</StyledTitle>
			{reverse && <StyledPointer width={width}>&nbsp;</StyledPointer>}
		</StyledContainer>
	);
};

type StyledContainerProps = {
	reverse?: boolean;
};
const StyledContainer = styled.div<StyledContainerProps>(
	({ reverse, theme: { spacing } }) => `
		display:flex;
		align-items: center;
		justify-content: ${reverse ? 'left' : 'right'};
		width:100%;
		gap: ${spacing.xs};
	`
);

type StyledPointerProps = {
	width: string;
};

const StyledPointer = styled.div<StyledPointerProps>(
	({ width, theme: { color } }) => `
		width: ${width};
		height: 1px;
		background-color: ${color.pillarPointer}

	`
);

const StyledTitle = styled.div<StyledContainerProps>(
	({ reverse }) => `
		width: ${reverse ? '75%' : '70%'};
	`
);
