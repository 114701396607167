import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { ResendInvitationUserEvents } from 'components/team/events';
import { DEBUG, ERROR } from 'logging/linusLogger';
import { InviteUserInput, OrganizationUser } from './types';

export async function inviteUserToOrg(
	input: InviteUserInput
): Promise<OrganizationUser> {
	if (!input?.userInvite || !input.organizationId) {
		throw new Error('inviteUserToOrg: invalid API request params');
	}

	const service = await getOrganizationService();
	const { data } = await service.inviteUser(input);
	return data;
}

export function useInviteUserToOrg() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error updating org user',
		},
		mutationFn: inviteUserToOrg,
		onMutate: () => {
			DEBUG(ResendInvitationUserEvents.INIT);
		},
		onSuccess: () => {
			DEBUG(ResendInvitationUserEvents.SUCCESS);
			queryClient.invalidateQueries({ queryKey: [QueryKey.Users] });
		},
		onError: (error) => {
			ERROR(ResendInvitationUserEvents.FAILURE, error);
		},
	});
}
