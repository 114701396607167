import { I18N_NAMESPACE, I18N_RESOURCES } from '@lh/eng-lhq-questions-common';
import { i18n } from 'i18n';

export function loadLHQTranslations(i18n: i18n) {
	// The LHQ translations go into the general 'translation' namespace for web
	const GENERAL_NAMESPACE = 'translation';

	Object.entries(I18N_RESOURCES).forEach(([lng, resource]) =>
		i18n.addResourceBundle(
			lng,
			GENERAL_NAMESPACE,
			resource[I18N_NAMESPACE],
			true,
			true
		)
	);
}
