import { useMutation } from '@tanstack/react-query';
import { getRemoteAssessmentsService } from '../getRemoteAssessmentsService';

type SendLinkParams = {
	organizationId: string;
	assignmentId: string;
};

async function sendLink({ assignmentId, organizationId }: SendLinkParams) {
	const service = await getRemoteAssessmentsService();
	return await service.sendLink({
		organizationId,
		assignmentId,
	});
}

export function useSendLink() {
	return useMutation({
		meta: {
			errorMessage: 'Error sending link',
		},
		mutationFn: sendLink,
	});
}
