import styled from 'styled-components';
import { IndividualScore, ScoreBgColorPair, ScoreType } from '../../types';
import ScoreBreakdown from './ScoreBreakdown';
import ScoreTag from './ScoreTag';

type ScoringWidgetContentProps = {
	isUnanalyzable?: boolean;
	overallScore: {
		highlightColor: ScoreBgColorPair | undefined;
		value: ScoreType;
	};
	additionalScoreComponent?: () => React.ReactNode | null;
	breakdown: {
		scoreIndicatorColor: string | undefined;
		breakdownSummary: React.ReactNode;
		scores: IndividualScore[] | [];
	};
};

const ScoringWidgetContent = (props: ScoringWidgetContentProps) => {
	return (
		<ContentContainer>
			<OverallScore
				data-testid={'scoring-widget-overall-score-section'}
				style={{
					backgroundColor: props?.overallScore?.highlightColor?.light,
				}}>
				<ScoreTagWrapper
					style={{
						backgroundColor:
							props?.overallScore?.highlightColor?.solid,
					}}>
					<ScoreTag
						showTotalScoreHeader
						isUnanalyzable={props?.isUnanalyzable}
						score={props?.overallScore?.value}
						highlightColor={
							props?.overallScore?.highlightColor?.solid
						}
					/>
				</ScoreTagWrapper>
				<ScoreBreakdown
					breakdownSummary={props?.breakdown?.breakdownSummary}
					scoreIndicatorColor={props?.breakdown?.scoreIndicatorColor}
					scores={props?.breakdown?.scores}
				/>
			</OverallScore>
			<AdditionalScoreSection
				data-testid={'scoring-widget-additional-score-section'}>
				{props?.additionalScoreComponent?.()}
			</AdditionalScoreSection>
		</ContentContainer>
	);
};

export default ScoringWidgetContent;

const ContentContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: ${spacing.md};
	gap: ${spacing.md};
`
);

const OverallScore = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex: 1;
	border-radius: ${spacing.sm} ${spacing.sm} ${spacing.sm} ${spacing.sm};
`
);

const ScoreTagWrapper = styled.div(
	({ theme: { spacing } }) => `
	min-width: 124px;
	border-radius: ${spacing.sm} 0 0 ${spacing.sm};
	display:flex;
	justify-content: center;
	align-items:center;
`
);

const AdditionalScoreSection = styled.div`
	display: flex;
	flex: 1.5;
`;
