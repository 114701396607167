/* eslint-disable react-hooks/exhaustive-deps */
// Disabled exhaustive deps to "fine-tune" the useEffect calls (read: fix bugs in the new calls).
// It'll probably make more bugs, and i'll fix them when they appear. -SF

import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { UserContext, nulloUser } from '../../context/UserContext';
import { CurrentUser } from 'types';
import { UserOnlyContext } from '../UserProvider/UserOnlyContext';
import { OrganizationSessionContext } from '../OrganizationProvider/OrganizationContext';
import { isEmpty } from 'lodash';
import { buildGQLCurrentUserFromRest } from 'helpers/gqlToRestMappers';
import { DEBUG } from 'logging/linusLogger';
import { useGetIsDeeplinking } from '../../hooks/useGetIsDeeplinking';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

export const UserContextProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const { user, logout, refetchUser, principal } =
		useContext(UserOnlyContext);
	const {
		currentOrganization,
		currentOrganizationRole,
		currentOrganizationValueLists,
	} = useContext(OrganizationSessionContext);
	const isDeeplinking = useGetIsDeeplinking();

	const _currentUser = useMemo((): CurrentUser => {
		const isDataReady = user && principal && currentOrganizationRole;
		if (!isDataReady) {
			return nulloUser;
		}

		const result = buildGQLCurrentUserFromRest({
			user,
			principal,
			role: currentOrganizationRole,
			organization: currentOrganization,
			valueLists: currentOrganizationValueLists,
		});

		if (isDeeplinking) {
			// If we're deeplinking from MGMT, you're allowed to see everything (backend might not let you do everything)
			result.operations = Object.values(OperationToken);
		}

		return result;
	}, [
		user,
		principal,
		currentOrganization,
		currentOrganizationRole,
		currentOrganizationValueLists,
	]);

	const _setCurrentUser = useCallback((_: CurrentUser) => {
		DEBUG('GQL setCurrentUser() called');
		refetchUser();
	}, []);

	const _updateCurrentUser = useCallback(() => {
		DEBUG('GQL updateCurrentUser() called');
	}, []);

	const _logout = useCallback(() => {
		logout();
	}, []);

	const _onLogin = useCallback(() => {
		DEBUG('GQL onLogin() called');
	}, []);

	const _clearImpersonation = useCallback(() => {
		DEBUG('GQL clearImpersonation() called');
	}, []);

	const _refetchCurrentUser = useCallback(() => {
		DEBUG('GQL refetchCurrentUser() called');
	}, []);

	const providedValue = useMemo(() => {
		return {
			currentUser: _currentUser,
			setCurrentUser: _setCurrentUser,
			updateCurrentUser: _updateCurrentUser,
			onLogin: _onLogin,
			logout: _logout,
			clearImpersonation: _clearImpersonation,
			refetchCurrentUser: _refetchCurrentUser,
			isLoggedIn: !isEmpty<CurrentUser>(_currentUser),
		};
	}, [
		_currentUser,
		_setCurrentUser,
		_updateCurrentUser,
		_onLogin,
		_logout,
		_clearImpersonation,
		_refetchCurrentUser,
	]);

	// In order to avoid showing the Linus Login page, prevent showing anything unless logged in
	if (isEmpty<CurrentUser>(_currentUser)) {
		return null;
	}

	return (
		<UserContext.Provider value={providedValue}>
			{children}
		</UserContext.Provider>
	);
};
