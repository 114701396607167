// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const MoveEveryDayLHQ = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='MoveEveryDayLHQ-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'MoveEveryDayLHQ'}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.931 18.3854C19.6876 16.8014 18.3153 13.045 18.3153 13.045L20.8793 6.40744C21.0851 5.87502 20.8203 5.2755 20.2872 5.06976C19.7562 4.86401 19.1552 5.12875 18.9495 5.66191C18.9495 5.66191 16.7969 10.9898 16.6281 11.0399C16.2712 11.1461 15.6303 11.3474 15.1031 11.5783C13.6186 12.2309 10.8083 13.2736 10.8083 13.2736C10.4868 13.3473 10.2191 13.5678 10.0879 13.8687C9.95512 14.1703 9.97355 14.5154 10.138 14.8008L11.9985 18.0344C12.1902 18.367 12.5391 18.5528 12.896 18.5528C13.0715 18.5528 13.2485 18.5078 13.4114 18.4142C13.907 18.1288 14.0773 17.4953 13.7919 16.9998L12.6312 14.9852L13.8384 14.7116L15.3265 18.5078L12.6983 25.5524C12.4697 26.0767 12.7116 26.6859 13.2359 26.9137C13.3701 26.9727 13.5095 27 13.6467 27C14.0471 27 14.4283 26.7662 14.5972 26.3761L17.541 19.7769L18.8905 26.1217C18.9701 26.6342 19.4089 27 19.9118 27C19.9649 27 20.0173 26.9956 20.0704 26.9867C20.6352 26.8997 21.0224 26.3717 20.9361 25.8068C20.9361 25.8068 20.1743 19.9679 19.931 18.3854ZM15.9832 8.94864C15.9832 10.0479 15.0921 10.9389 13.9929 10.9389C12.8937 10.9389 12.0026 10.0479 12.0026 8.94864C12.0026 7.84942 12.8937 6.95833 13.9929 6.95833C15.0921 6.95833 15.9832 7.84942 15.9832 8.94864Z'
				fill={color}
			/>
		</svg>
	);
};
