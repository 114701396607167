import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { icons } from '../../enums/icons';

import { ButtonSm } from '../shared/designSystem/ButtonSm';
import { Icon } from '../shared/designSystem/Icon';
import { H2 } from '../shared/designSystem/TextComponents';

const SessionTimeout = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	function handleClick() {
		navigate('/auth/login');
	}

	return (
		<StyledPage>
			<StyledContainer>
				<Icon icon={icons.WarningRound} />
				<StyledTitle data-testid='session-timeout-txt-title'>
					{'Session Timeout'}
				</StyledTitle>
				<StyledContent data-testid='session-timeout-txt-body'>
					{
						'Your session has expired due to inactivity. Please log in again to continue.'
					}
				</StyledContent>
				<StyledButtonContainer>
					<ButtonSm
						data-testid='session-timeout-btn-redirect'
						primary
						text={t`web.login.title`}
						onClick={handleClick}
					/>
				</StyledButtonContainer>
				<StyledLogo>
					<Icon icon={icons.Logo} title={t`web.shared.logo`} />
				</StyledLogo>
			</StyledContainer>
		</StyledPage>
	);
};

const StyledLogo = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.xxl};
`
);

const StyledPage = styled.div(
	({ theme: { spacing } }) =>
		`
	display: flex;
	justify-content: center;
	padding: ${spacing.xxxl}
`
);

const StyledTitle = styled(H2)(
	({ theme: { fontSize, color, spacing } }) => `
        font-size: '${fontSize._32}';
        line-height: 40px;
        -webkit-letter-spacing: 0.2px;
        -moz-letter-spacing: 0.2px;
        -ms-letter-spacing: 0.2px;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: ${color.textBlue};
        padding-top: ${spacing.xl};
        font-weight: 600;
        margin: 0;
	`
);

const StyledContainer = styled.div(
	({ theme: { color, boxShadow, spacing } }) => `
		justify-content: center;
		align-content: center;
		text-align: center;
		width: 548px;
		height: auto;
		padding: ${spacing.xxl} ${spacing.xl};
		background: ${color.white};
		border-radius: ${spacing.xxl} 0 ${spacing.xxl} ${spacing.xxl};
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};
	`
);

const StyledContent = styled.div(
	({ theme: { spacing, fontSize, color } }) => `
		color: ${color.textInfo};
		padding-top: ${spacing.md};
		font-size: ${fontSize._16};
		line-height: 19px;
		-webkit-letter-spacing: 0.5px;
		-moz-letter-spacing: 0.5px;
		-ms-letter-spacing: 0.5px;
		letter-spacing: 0.5px;
	`
);

const StyledButtonContainer = styled.div(
	({ theme: { spacing } }) =>
		`
		display: flex;
		justify-content: center;
		margin-top: ${spacing.xl};
	`
);

export { SessionTimeout };
