import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Typography } from '@lh/eng-web-mosaic-common';
import { Caption } from 'components/designSystem/Text';
import { AssignmentStatus, AssignmentType } from 'api/assignment';
import { theme } from '../../../providers/styleProvider';
import { getAssignmentStyling } from './BatteryStatus.helpers';
import { useReceiptObserver } from 'api/remote-assessments';
import { useState, useEffect } from 'react';
import { truncateString } from 'utils/stringUtils';

type Props = {
	assignmentId: string;
	assignmentStatus: AssignmentStatus | undefined;
	assignmentType: AssignmentType;
	batteryDisplayKey: string | undefined;
};

const BatteryStatus = (props: Props) => {
	const { t } = useTranslation();
	const [assignmentTypeTranslation, setAssignmentTypeTranslation] =
		useState<string>('');
	const receipts = useReceiptObserver({
		assignmentId: props.assignmentId,
	});
	useEffect(() => {
		if (props.assignmentType !== AssignmentType.Web) {
			setAssignmentTypeTranslation(
				`: ${t`web.shared.batteryTypes.clinic`}`
			);
		} else if (receipts) {
			setAssignmentTypeTranslation(
				`: ${t`web.shared.batteryTypes.remote`}`
			);
		} else {
			setAssignmentTypeTranslation(
				`: ${t`web.shared.batteryTypes.generated`}`
			);
		}
	}, [receipts, props.assignmentType, t]);

	return (
		<Flex direction={'column'}>
			<Caption style={getAssignmentStyling(props?.assignmentStatus)}>
				{`${props?.assignmentStatus}${assignmentTypeTranslation}`}
			</Caption>
			{props?.batteryDisplayKey ? (
				<Typography.P1 color={theme.color.bodyText}>
					{truncateString(t(props?.batteryDisplayKey), 21)}
				</Typography.P1>
			) : null}
		</Flex>
	);
};

export { BatteryStatus };
