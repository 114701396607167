import { Locale } from 'generated/graphql';

export enum AnalyticsReportName {
	DAC_REPORT = 'DAC Participant Report',
	FALLBACK = 'Default Report',
}

const ES_US = 'ES_US';

export const AnalyticsLanguage: Record<string, string> = {
	[Locale.EnGb]: 'EN_GB',
	[Locale.EnUs]: 'EN_US',
	[Locale.EsMx]: 'ES_MX',
	[ES_US]: 'ES_US',
};
