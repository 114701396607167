/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { EnumItem } from '../enums/enumeration';
import i18n from '../i18n';

const NonAlphabetRegex = /^[^a-zA-Z]*$/;

export const validateParticipantLanguages = (items: EnumItem[]): boolean => {
	// Filter out all participantLang items that have been chosen, split on locale (via |)
	// then smash into a set and check lengths; if diff lengths, then dupes exist
	const checkDups: {
		[key: string]: number;
	} = {};
	for (let i = 0; i < items.length; i++) {
		const value = items[i].key.split('|')[0];
		if (checkDups[value]) {
			return false;
		} else {
			checkDups[value] = 1;
		}
	}

	return true;
};

/**
 *
 * @param dateValue A value to validate as a valid date value.
 * @returns Whether a data is valid or not.
 */
export const validateDate = (dateValue: any) => {
	return dateValue instanceof Date && !isNaN(dateValue.valueOf());
};

export const isValidPhoneNumber = (phoneNumber: string) => {
	return (
		phoneNumber.startsWith('+') && // should start with "+"
		NonAlphabetRegex.test(phoneNumber) && // no alphabets allowed
		phoneNumber.replace(/[^0-9]/g, '').length >= 1 && // should contain at least 1 digit
		phoneNumber.replace(/[^0-9]/g, '').length <= 15 // should contain at most 15 digits
	);
};

export const contactPhoneValidator = () => {
	return yup
		.string()
		.test(
			'is-valid-phone',
			i18n.t('web.shared.forms.schemas.phoneNumberHint'),
			function (item) {
				if (item) {
					return isValidPhoneNumber(item);
				}
				return true;
			}
		);
};
