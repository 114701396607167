import { Divider, Flex, Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ConfidenceScoreRange } from '../../../../common/constants/confidenceScore';
import { LinusModal } from '../../../../../../shared/LinusModal';
import { ButtonLg, P1 } from '../../../../../../shared/designSystem';
import ConfidenceScoreLegendRow from '../../../BaselineEpsom/components/RankingsAndRatings/ConfidenceScore/ConfidenceScoreLegendRow';

type Props = {
	visible: boolean;
	onClose: () => void;
};

const ConfidenceScoringDetailsModal = (props: Props) => {
	const { t } = useTranslation();
	if (!props?.visible) return null;
	const confidenceRangesOrderedByScore =
		Object.keys(ConfidenceScoreRange).reverse();
	const scoringDescription = t(
		'research.epsomConfidenceScore.scoringDetails.modal.descriptionText'
	);
	const closeButtonText = t('web.team.sharedModal.closeButton');

	return (
		<LinusModal
			hideCloseButton
			title={'Scoring Details'}
			onClose={props?.onClose}>
			<Space h='sm' />
			<ModalBodyText>{scoringDescription}</ModalBodyText>

			<Space h='sm' />
			<Divider />
			<Space h='sm' />

			<p>{'The ranges and confidence levels are as follows:'}</p>
			<Space h='sm' />
			{confidenceRangesOrderedByScore.map(
				(confidenceRangesOrderedByScore): JSX.Element | null => (
					<ConfidenceScoreLegendRow
						showSeparator={false}
						key={confidenceRangesOrderedByScore?.toString()}
						scoreRange={
							confidenceRangesOrderedByScore as ConfidenceScoreRange
						}
					/>
				)
			)}
			<Space h='sm' />
			<Flex justify={'center'}>
				<ButtonLg
					onClick={props?.onClose}
					text={closeButtonText}
					width='158px'
				/>
			</Flex>
		</LinusModal>
	);
};

export { ConfidenceScoringDetailsModal };

const ModalBodyText = styled(P1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.regular};		
    white-space: pre;    
`
);
