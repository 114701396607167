import {
	Chip,
	Variant as ChipVariant,
	Typography,
} from '@lh/eng-web-mosaic-common';
import { Space } from '@mantine/core';
import { CSSProperties } from 'react';

type Props = {
	name: string;
	score: string;
	color: ChipVariant;
	description: string;
	position: CSSProperties['alignItems'];
};

const Score = (props: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: props?.position,
			}}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<Typography.H4 weight='400'>{props?.name}</Typography.H4>
				<Space h='xs' />
				<Chip label={props?.score} variant={props?.color} />
				<Space h='xs' />
				<Typography.P1 weight='400'>{props?.description}</Typography.P1>
			</div>
		</div>
	);
};

export default Score;
