import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericReportCard } from '../../../../../../common/GenericReportCard/GenericReportCard';
import Score from './Score';

import { Variant as ChipColor } from '@lh/eng-web-mosaic-common';

type Props = {
	improved: number;
	stable: number;
	declined: number;
};

const maximumChangeInConfidence = 5;

const zeroScoreColor: ChipColor = 'Gray';

const ReportCard = (props: Props) => {
	const { t } = useTranslation();

	const improvedIndicatorColor = useMemo(
		(): ChipColor => (props.improved === 0 ? zeroScoreColor : 'Green'),
		[props.improved]
	);

	const stableIndicatorColor: ChipColor = zeroScoreColor;

	const declinedIndicatorColor = useMemo(
		(): ChipColor => (props.declined === 0 ? zeroScoreColor : 'Red'),
		[props.declined]
	);

	return (
		<GenericReportCard
			header={{
				titleText: 'Change in Confidence',
				tooltipText: undefined,
			}}
			content={[
				{
					weight: 1,
					component: () => (
						<Score
							position={'center'}
							name={t(
								'web.report.longEpsom.changeInConfidence.improved'
							)}
							score={`${props.improved}/${maximumChangeInConfidence}`}
							color={improvedIndicatorColor}
							description={t(
								'web.report.longEpsom.changeInConfidence.importantOutcomes'
							)}
						/>
					),
				},
				{
					component: () => (
						<div
							style={{
								border: '1px solid #D9D8DC',
								height: '100%',
							}}
						/>
					),
				},
				{
					weight: 1,
					component: () => (
						<Score
							position={'center'}
							name={t(
								'web.report.longEpsom.changeInConfidence.declined'
							)}
							score={`${props.declined}/${maximumChangeInConfidence}`}
							color={declinedIndicatorColor}
							description={t(
								'web.report.longEpsom.changeInConfidence.importantOutcomes'
							)}
						/>
					),
				},
				{
					component: () => (
						<div
							style={{
								border: '1px solid #D9D8DC',
								height: '100%',
							}}
						/>
					),
				},
				{
					weight: 1,
					component: () => (
						<Score
							position={'center'}
							name={t(
								'web.report.longEpsom.changeInConfidence.stable'
							)}
							score={`${props.stable}/${maximumChangeInConfidence}`}
							color={stableIndicatorColor}
							description={t(
								'web.report.longEpsom.changeInConfidence.importantOutcomes'
							)}
						/>
					),
				},
			]}
		/>
	);
};

export { ReportCard };
