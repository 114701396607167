import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Json, Png } from '../../../../artifacts/ReportArtifactNames';
import { icons } from '../../../../enums/icons';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { LoadingDots } from '../../../shared/LoadingDots';
import { StyledCard } from '../../../shared/StyledCard';
import { H1, H4, Icon } from '../../../shared/designSystem';
import { ArtifactFormats, useGetReportData } from '../../../shared/hooks';
import { ClockAnimation } from '../ClockAnimation';
import { Maybe } from 'generated/graphql';

type DctClockRecordingProps = {
	title: string;
	tooltipText: string;
	// TODO: turn this into enum
	clockType?: 'COMMAND' | 'COPY';
	segmentId?: string;
	// TODO: getting rid of this breaks the storybook, revisit
	clockDataUrl?: string;
	contentLoaded?: () => void;
};

export const DctClockRecording = ({
	title,
	tooltipText,
	clockType,
	segmentId,
	contentLoaded,
}: DctClockRecordingProps): JSX.Element | null => {
	const { t } = useTranslation();

	const [clockData, setClockData] =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		useState<Maybe<Record<string, any>>>(undefined);

	const {
		loading: isLoadingJson,
		error: clockDataError,
		metadata: clockJsonData,
	} = useGetReportData({
		segmentResultId: segmentId as string,
		fileName: Json.ClockJSON,
		fileType: ArtifactFormats.JSON,
	});

	const clockPngFilename = useMemo(
		() => (clockType === 'COPY' ? Png.CopyPNG : Png.CommandPNG),
		[clockType]
	);

	const {
		loading: isLoadingPng,
		error: clockImageError,
		metadata: clockImage,
	} = useGetReportData({
		segmentResultId: segmentId as string,
		fileName: clockPngFilename,
		fileType: ArtifactFormats.PNG,
	});

	useEffect(() => {
		if (clockJsonData && clockImage) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			setClockData(clockJsonData as any);
			// alert parent component that all the data is loaded an ready to be printed
			contentLoaded && contentLoaded();
		}
	}, [clockData, clockImage, clockJsonData, contentLoaded]);

	const isStrokeDataAvailable = useMemo(() => {
		if (clockData && clockType) {
			return (
				clockData[clockType.toLowerCase() + '_clock'].drawing?.data
					?.stroke_data?.length > 0
			);
		}
		return false;
	}, [clockData, clockType]);

	if (clockDataError || clockImageError) {
		console.error(clockDataError ?? clockImageError);
		return null;
	}

	if (isLoadingJson || isLoadingPng) {
		return <LoadingDots />;
	}

	if (!clockData || !clockImage || !clockType) {
		return null;
	}

	return (
		<StyledWrapper data-testid={'dct-clock-recording-wrapper'}>
			<StyledTitle>
				<H4>{title}</H4>
				<LinusTooltip
					overlay={<StyledTooltip>{tooltipText}</StyledTooltip>}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			</StyledTitle>
			<StyledBody>
				{isStrokeDataAvailable ? (
					<PrintHide>
						<StyledClockAnimationWrapper
							data-testid={'dct-clock-animation-wrapper'}>
							<ClockAnimation
								rawData={clockData}
								section_name={clockType}
							/>
						</StyledClockAnimationWrapper>
					</PrintHide>
				) : (
					<StyledUnavailableText
						data-testid={'dct-clock-unavailable-text'}>
						<H1>{t<string>('web.report.DCTClock.unavailable')}</H1>
					</StyledUnavailableText>
				)}
				<StyledImg
					src={clockImage as string}
					alt={t`web.report.DCTClock.recording.imageAltText`}
				/>
			</StyledBody>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => `
  box-sizing: border-box;
	height: 630px;
	padding: ${spacing.md};
	vertical-align: bottom;
	@media print {
		min-width: unset;
	}`
);

const StyledClockAnimationWrapper = styled.div`
	position: relative;
	top: 80px;
`;

const StyledUnavailableText = styled.div`
	top: 50%;
`;

const StyledTitle = styled.div`
	display: flex;
	align-items: center;
`;
const StyledBody = styled.div`
	width: fit-content;
	margin: auto;
	height: 100%;
	text-align: center;
`;
const StyledImg = styled.img`
	max-width: 100%;
	max-height: 100%;
	display: none;
	@media print {
		display: block;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin: 10px;
		max-height: calc(
			100% - 22px - 80px
		); //height - margin - border - header height
	}
`;

const PrintHide = styled.div`
	@media print {
		display: none;
	}
`;

const StyledTooltip = styled.div`
	margin-left: 11px;
	margin-top: 3px;
`;
