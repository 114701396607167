import { Icon } from '../components/shared/designSystem';
import { theme } from '../providers/styleProvider';
import { EnumItem } from './enumeration';
import { icons } from './icons';

export type MessageEnumItem = {
	value: string;
	display: string;
	displayNode?: React.ReactNode;
	icon?: React.ElementType;
};
export type MessageEnum = {
	success: MessageEnumItem;
	error: MessageEnumItem;
	info: MessageEnumItem;
	warning: MessageEnumItem;
};

export const messageEnum = {
	success: (display: string, altIcon?: EnumItem): MessageEnumItem => ({
		value: 'success',
		icon: () => (
			<Icon
				icon={altIcon || icons.CheckmarkOutlined}
				color={theme.color.alertSuccess}
			/>
		),
		display,
	}),
	error: (
		display: string,
		altIcon?: EnumItem,
		displayNode?: React.ReactNode
	): MessageEnumItem => ({
		value: 'error',
		icon: () => (
			<Icon
				icon={altIcon || icons.AlertSolid}
				color={theme.color.alertError}
			/>
		),

		display,
		displayNode,
	}),
	info: (display: string): MessageEnumItem => ({ value: 'info', display }),
	warning: (display: string): MessageEnumItem => ({
		value: 'warning',
		display,
	}),
};
