import { getOrganizationService } from 'api/getOrganizationService';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'api/query';
import { isEmpty } from 'lodash';

const getPrincipal = async () => {
	const orgService = await getOrganizationService();
	return await orgService.getLinusPrincipal().then((res) => res.data);
};

const getUserDeep = async (organizationId: string, userId: string) => {
	const orgService = await getOrganizationService();
	return await orgService
		.getUserById({ organizationId, userId })
		.then((res) => res.data);
};

export const useGetPrincipal = (enableQuery = false) => {
	return useQuery({
		queryKey: [QueryKey.Principal],
		queryFn: getPrincipal,
		enabled: enableQuery,
		staleTime: Infinity,
	});
};

export const useGetUserDeep = (organizationId = '', userId = '') => {
	const enabled = !isEmpty(organizationId) && !isEmpty(userId);
	return useQuery({
		queryKey: [QueryKey.User, { organizationId, userId }],
		queryFn: async () => getUserDeep(organizationId, userId),
		enabled,
		staleTime: Infinity,
	});
};
