import styled from 'styled-components';
import { passwordStrengthCharacteristics } from '../../../../utils/passwordUtils';

type PasswordStrengthOverlayProps = {
	value: string;
};

const PasswordStrengthOverlay = ({
	value,
}: PasswordStrengthOverlayProps): JSX.Element => {
	return (
		<StyledContainer>
			{passwordStrengthCharacteristics.map((passCharacteristics) => (
				<StyledText
					key={passCharacteristics.name}
					value={value}
					isValid={
						!!value.match(passCharacteristics.regex) &&
						value.length >= passCharacteristics.min
					}>
					{passCharacteristics.display}
				</StyledText>
			))}
		</StyledContainer>
	);
};

export { PasswordStrengthOverlay };

const StyledContainer = styled.ul(
	({ theme: { color, spacing } }) => `
	text-align: justify;
	background: ${color.white};
	border-radius: ${spacing.xs};
	border: 1px solid;
	color: ${color.formText};
	position: absolute;
	padding: ${spacing.xs} ${spacing.xxs};
	z-index: 2;

	::after {
		content: '';
		width: 12px;
		height: 12px;
		transform: rotate(-45deg);
		background: ${color.white};
		position: absolute;
		border: 1px solid;
		bottom: -7px;
		left: 8%;
		z-index: 1;
		border-color: ${color.white} ${color.white} ${color.formText} ${color.formText};
	}
`
);

const StyledText = styled.li<{
	isValid: boolean;
	value: string;
}>(
	({ theme: { color, spacing }, isValid, value }) => `
	display: inline-block;
	padding: 0 ${spacing.sm};
	font-size: 12px;
	color: ${
		!value
			? color.formText
			: isValid
			? color.formTextDisabled
			: color.formError
	};
	:before {
		content: '\\2022';
		color: ${
			!value
				? color.label
				: isValid
				? color.formTextDisabled
				: color.alertError
		};
		padding-right: ${spacing.sm};
	}
`
);
