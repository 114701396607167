// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const KeepHealthInCheckTapped = ({
	title,
	width = '24',
	height = '24',
	color = '#FB8066',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 33 32'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='KeepHealthInCheckTapped-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'KeepHealthInCheckTapped'}</title>
			<rect x='0.5' width='32' height='32' rx='16' fill={color} />
			<path
				d='M22.4623 16.8172L22.4663 15.6129L24.3291 15.6192C25.7046 9.81083 18.3848 6.28763 16.4368 11.7744C14.1558 6.29108 7.41709 9.69424 8.64916 15.4031L12.2857 15.3844L13.7836 13.4086C14.181 13.0527 14.5265 13.0974 14.8201 13.5428L16.5393 17.7116L18.0208 14.5983C18.2874 14.2069 18.6112 14.1518 18.9922 14.4331L20.1512 15.6023L21.2658 15.613L21.2542 16.8172L19.8921 16.8042C19.7335 16.8026 19.582 16.7386 19.4703 16.626L18.7402 15.8895L17.0438 19.4543C16.8211 19.9223 16.1439 19.9115 15.9434 19.4252L14.1078 14.9745L13.0658 16.3489C12.9527 16.4982 12.7764 16.5863 12.5891 16.5873L9.01819 16.6056C9.9338 18.9102 12.1534 21.489 16.3838 23.968H16.395C20.6895 21.5618 22.965 19.0673 23.9283 16.8222L22.4623 16.8172Z'
				fill='white'
			/>
		</svg>
	);
};
