import styled, { css } from 'styled-components';

import { Typography } from '@lh/eng-web-mosaic-common';

import { LinusTooltip } from '../../../../../shared/LinusTooltip';
import { icons } from '../../../../../../enums/icons';
import { Icon } from '../../../../../shared/designSystem/Icon';

type Props = {
	headerTitle: string;
	tooltipText?: string;
};

export const GenericReportCardHeader = (props: Props) => {
	return (
		<HeaderContainer data-testid='header-container'>
			<HeaderTitle data-testid='header-title'>
				{props?.headerTitle}
			</HeaderTitle>
			{props?.tooltipText && (
				<LinusTooltip
					trigger={'hover'}
					data-testid={'header-tooltip'}
					overlay={<InfoTooltip>{props?.tooltipText}</InfoTooltip>}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			)}
		</HeaderContainer>
	);
};

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const HeaderTitle = styled(Typography.H4)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);

const InfoTooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);
