// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const EyeClosedOutlined = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 18 17'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='EyeClosedOutlined-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'EyeClosedOutlined'}</title>

			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.4257 13.0249L5.42881 13.0266L3.40246 15.5206C3.22833 15.7349 3.2609 16.0498 3.47522 16.2239C3.68954 16.398 4.00444 16.3655 4.17857 16.1512L6.36334 13.4622C7.19723 13.7956 8.08259 14 9 14C13.9703 14 18 8 18 8C18 8 16.4538 5.69782 14.0956 3.94555L16.366 1.15128C16.5401 0.936962 16.5075 0.62206 16.2932 0.447927C16.0789 0.273794 15.764 0.30637 15.5899 0.520688L13.2683 3.37803L13.2653 3.37618L12.6296 4.15854L12.6326 4.16037L11.5017 5.55225L11.4993 5.54977L10.863 6.33287L10.8653 6.33548L7.75232 10.1669L7.74929 10.1651L7.113 10.9483L7.11593 10.9501L6.07273 12.2341L6.06957 12.2325L5.4257 13.0249ZM13.4644 4.72243L12.1089 6.39073C12.3588 6.87252 12.5 7.41977 12.5 8C12.5 8.92826 12.1313 9.8185 11.4749 10.4749C10.8185 11.1313 9.92826 11.5 9 11.5C8.67477 11.5 8.3599 11.4556 8.06111 11.3726L7.02823 12.6439C7.66895 12.8692 8.32997 13 9 13C11.0999 13 13.1114 11.715 14.6864 10.2644C15.456 9.55562 16.0819 8.84371 16.5155 8.30823C16.6056 8.19697 16.6871 8.09368 16.7596 8C16.6871 7.90633 16.6056 7.80303 16.5155 7.69178C16.0819 7.1563 15.456 6.44439 14.6864 5.73558C14.3032 5.38269 13.8942 5.0396 13.4644 4.72243ZM11.3925 7.27248L8.77803 10.4903C8.85116 10.4967 8.9252 10.5 9 10.5C9.66304 10.5 10.2989 10.2366 10.7678 9.76777C11.2366 9.29893 11.5 8.66304 11.5 8C11.5 7.74695 11.4624 7.50269 11.3925 7.27248Z'
				fill='current'
			/>
			<path
				d='M6.40748 10.3514L7.04838 9.56258C6.70525 9.13457 6.5 8.59125 6.5 8C6.5 6.61929 7.61929 5.5 9 5.5C9.40676 5.5 9.79082 5.59714 10.1303 5.76949L10.7712 4.98062C10.2517 4.67517 9.64629 4.5 9 4.5C7.067 4.5 5.5 6.067 5.5 8C5.5 8.90515 5.8436 9.73005 6.40748 10.3514Z'
				fill='current'
			/>
			<path
				d='M11.8067 3.7062C10.9124 3.27168 9.96553 3 9 3C6.9001 3 4.8886 4.28505 3.3136 5.73558C2.54397 6.44439 1.91806 7.1563 1.48449 7.69178C1.39441 7.80303 1.31291 7.90633 1.24042 8C1.31291 8.09367 1.39441 8.19697 1.48449 8.30823C1.91806 8.84371 2.54397 9.55562 3.3136 10.2644C3.90529 10.8094 4.55858 11.3309 5.25503 11.7698L4.62027 12.551C1.86406 10.7754 0 8 0 8C0 8 4.02975 2 9 2C10.2232 2 11.3894 2.36338 12.4526 2.91129L11.8067 3.7062Z'
				fill='current'
			/>
		</svg>
	);
};
