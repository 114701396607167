import { useEffect, useState } from 'react';
import { useQuery, useQueryClient, QueryObserver } from '@tanstack/react-query';
import { getRemoteAssessmentsService } from '../getRemoteAssessmentsService';
import { QueryKey, CacheTimes } from 'api/query';
import { isNonEmptyString } from 'utils/stringUtils';
import { isNil, isEmpty } from 'lodash';
import {
	GetSendReceiptsFilterField,
	GetSendReceiptsFilterOperator,
	GetSendReceiptsLogicalOperator,
	PaginatedReceipts,
} from './types';
import { AssignmentType } from 'api/assignment';

export interface GetReceiptsParams {
	assignmentIds?: string[];
	participantIds?: string[];
	organizationId: string;
	page: number;
	pageSize: number;
}
export interface GetSingleReceiptsParams {
	assignmentId?: string;
	participantId?: string;
	organizationId: string;
	assignmentType?: AssignmentType;
}
function isValidList(list?: unknown[]) {
	return !isNil(list) && !isEmpty(list);
}
export async function getSendReceipts({
	participantIds,
	assignmentIds,
	organizationId,
	page = 0,
	pageSize = 20,
}: Partial<GetReceiptsParams>) {
	const hasParticipantFilter = isValidList(participantIds);
	const hasAssignmentFilter = isValidList(assignmentIds);
	if (!isNonEmptyString(organizationId)) {
		throw new Error("getSendReceipts: organizationId can't be null");
	}
	if (!hasParticipantFilter && !hasAssignmentFilter) {
		throw new Error(
			'getSendReceipts: Either participantIds or assignmentIds must be populated'
		);
	}
	const { filterField, filterOperator, filterValue } = formatQueryFilters(
		hasParticipantFilter ? participantIds : undefined,
		hasAssignmentFilter ? assignmentIds : undefined
	);
	const service = await getRemoteAssessmentsService();
	const { data } = await service.getSendReceipts({
		organizationId,
		filterField,
		filterOperator,
		logicalOperator: [GetSendReceiptsLogicalOperator.Or],
		filterValue: filterValue.map((v) => v.join(',')),
		pageSize,
		page,
	});
	return data;
}

export function useGetAssignmentSendReceipts({
	assignmentId,
	organizationId,
	assignmentType = AssignmentType.Web,
}: Partial<GetSingleReceiptsParams>) {
	const queryTuple = [QueryKey.AssignmentSendReceipts, assignmentId];
	const enabled =
		isNonEmptyString(assignmentId) &&
		isNonEmptyString(organizationId) &&
		assignmentType === AssignmentType.Web;
	return useQuery({
		enabled,
		meta: {
			errorMessage: `Error fetching receipts for assignment ${assignmentId}`,
		},
		queryKey: queryTuple,
		queryFn: () =>
			getSendReceipts({
				assignmentIds: assignmentId ? [assignmentId] : undefined,
				organizationId,
			}),
		staleTime: CacheTimes.ThirtyMinutes,
	});
}

export function useReceiptObserver({
	assignmentId,
}: Partial<GetSingleReceiptsParams>) {
	const client = useQueryClient();
	const [data, setData] = useState<PaginatedReceipts | undefined>(
		client.getQueryData([QueryKey.AssignmentSendReceipts, assignmentId])
	);
	useEffect(() => {
		if (client) {
			const observer = new QueryObserver<PaginatedReceipts>(client, {
				enabled: false,
				queryKey: [QueryKey.AssignmentSendReceipts, assignmentId],
			});
			return observer.subscribe((event) => {
				setData(event.data);
			});
		}
	}, [client, assignmentId]);
	return data;
}

export function useGetParticipantSendReceipts({
	participantId,
	organizationId,
}: Partial<GetSingleReceiptsParams>) {
	return useQuery({
		enabled:
			isNonEmptyString(participantId) && isNonEmptyString(organizationId),
		meta: {
			errorMessage: `Error fetching receipts for participant ${participantId}`,
		},
		queryKey: [QueryKey.ParticipantSendReceipts, participantId],
		queryFn: () =>
			getSendReceipts({
				participantIds: participantId ? [participantId] : undefined,
				organizationId,
			}),
		staleTime: CacheTimes.ThirtyMinutes,
	});
}

function formatQueryFilters(
	participantIds?: string[],
	assignmentIds?: string[]
) {
	const set: [
		string[] | undefined,
		GetSendReceiptsFilterField,
		GetSendReceiptsFilterOperator
	][] = [
		[
			participantIds,
			GetSendReceiptsFilterField.ParticipantId,
			GetSendReceiptsFilterOperator.In,
		],
		[
			assignmentIds,
			GetSendReceiptsFilterField.AssignmentId,
			GetSendReceiptsFilterOperator.In,
		],
	];
	return set
		.filter((params) => !!params[0])
		.reduce(
			(acc, v) => {
				if (v[0]) {
					acc.filterValue.push(v[0]);
					acc.filterField.push(v[1]);
					acc.filterOperator.push(v[2]);
				}
				return acc;
			},
			{
				filterField: [],
				filterOperator: [],
				filterValue: [],
			} as {
				filterField: GetSendReceiptsFilterField[];
				filterOperator: GetSendReceiptsFilterOperator[];
				filterValue: string[][];
			}
		);
}
