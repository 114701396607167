/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthServiceContext } from 'features/auth-service';
import { useAuthStore, useStoreReset, useUserStore } from 'store';
import {
	useGetPrincipal,
	useGetUserDeep,
} from 'api/organization/useGetPrincipal';
import { config } from 'config';
import { auth0Login } from '@lh/eng-shared-auth';
import { isEmpty } from 'lodash';
import { UserOnlyContext } from './UserOnlyContext';
import { ERROR } from 'logging/linusLogger';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { clearSessionStorage } from 'utils/sessionStorage';
import { SessionStorageEnum } from 'enums/sessionStorageKeysEnum';

export const UserOnlyProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const navigate = useNavigate();
	const auth = useAuthServiceContext();
	const { user, setUser, principal, setPrincipal } = useUserStore();
	const { bearerToken, setBearerToken, setAuth } = useAuthStore();
	const resetAllStores = useStoreReset();

	useEffect(() => {
		if (isEmpty(auth)) return;
		setAuth(auth);
	}, [auth]);

	const _getFreshJWT = useCallback(async () => {
		return await auth.currentIdToken();
	}, [auth]);

	const { data: _principal, error: _principalError } = useGetPrincipal(
		!isEmpty(bearerToken)
	);
	const {
		data: _deepUser,
		error: _deepUserError,
		refetch: _deepUserRefetch,
	} = useGetUserDeep(principal?.defaultOrganizationId, principal?.id);

	// Manual login, if successful we fetch the bearer token and store it to kick off principal fetch
	const _login = useCallback(
		async (user: string, pass: string) => {
			return auth
				.login({ username: user, password: pass })
				.then(() => {
					_getFreshJWT()
						.then((token) => {
							setBearerToken(token.token);
						})
						.catch((_) => setBearerToken(''));
					return true;
				})
				.catch((e) => {
					ERROR('Error logging in: ', e);
					return false;
				});
		},
		[auth, setBearerToken]
	);

	// TODO: Enumerate logout paths
	const _logout = useCallback(
		async (urlRedirect: string = config.researchDomainUrl) => {
			resetAllStores();
			clearSessionStorage(SessionStorageEnum.ImpersonatedOrg);
			auth.logout(urlRedirect);
		},
		[auth, resetAllStores]
	);

	// First load, attempt auth0 login
	useEffect(() => {
		auth0Login(
			auth,
			config.researchDomainUrl,
			`${window.location.pathname}${window.location.search}`
		)
			.then((response: void | RedirectLoginResult | undefined) => {
				_getFreshJWT()
					.then((token) => {
						setBearerToken(token.token);
					})
					.catch((_) => setBearerToken(''));

				if (response?.appState?.target) {
					navigate(response?.appState?.target, {
						replace: true,
					});
				}
			})
			.catch((err) => {
				ERROR('error with auth0 login', err);
			});
	}, [auth, setBearerToken, _getFreshJWT]);

	// After we log in with auth0, get the principal for the user
	useEffect(() => {
		if (!isEmpty(_principalError)) {
			ERROR('Error fetching principal', _principalError);
			navigate('/auth/login', { replace: true });
			return;
		}
		if (!isEmpty(_principal)) {
			setPrincipal(_principal);
		}
	}, [_principal, _principalError, setPrincipal, setBearerToken]);

	// After we have the principal, get the full user object
	useEffect(() => {
		if (!isEmpty(_deepUserError)) {
			ERROR('Error fetching user', _deepUserError);
			navigate('/auth/login', { replace: true });
			return;
		}
		if (!isEmpty(_deepUser)) {
			setUser(_deepUser);
		}
	}, [_deepUser, _deepUserError, setUser]);

	const providedValue = useMemo(
		() => ({
			refetchUser: _deepUserRefetch,
			user: user,
			principal: principal,
			logout: _logout,
			login: _login,
			getFreshJWT: _getFreshJWT,
		}),
		[_deepUserRefetch, user, principal, _logout, _login]
	);

	return (
		<UserOnlyContext.Provider value={providedValue}>
			{children}
		</UserOnlyContext.Provider>
	);
};
