import {
	Assignment,
	AssignmentIssue,
	BatteryResultStatus,
	DeepAssessmentResult,
	MetricItem,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { FormattedBatteryResult } from 'components/report/common';
import { MetricHash } from 'components/report/metrics';
import { SlimUser, User, Battery as GQLBattery } from 'generated/graphql';

import { FormattedAssessmentResults, FormattedSegmentResults } from './types';

export const getLHQMetrics = (
	lifeAndHealthAssessment: FormattedAssessmentResults | undefined
): { elevatedRisk: boolean; modifiableFactors: boolean } => {
	const segmentResults = lifeAndHealthAssessment?.segmentResults ?? [];

	let lhqSegment: FormattedSegmentResults | undefined;

	segmentResults.forEach((result) => {
		if (
			'lhqElevatedRisk' in result.metricItems ||
			'lhq32_modifiable_factors' in result.metricItems
		) {
			lhqSegment = result;
		}
	});

	const elevatedRisk =
		'lhqElevatedRisk' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhqElevatedRisk.value === 'true';
	const modifiableFactors =
		'lhq32_modifiable_factors' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhq32_modifiable_factors.value === 'true';
	return { elevatedRisk, modifiableFactors };
};

export type BatteryIssue = AssignmentIssue & {
	reporter: SlimUser;
};

export type BatteryAssignment = Assignment & { interpretingUser: User };
export type BatteryResultData = {
	id: string;
	assessmentResults: DeepAssessmentResult[];
	assignment?: Partial<BatteryAssignment>;
	battery: GQLBattery;
	endTime?: string;
	issue?: BatteryIssue;
	metricItems?: MetricItem[];
	rawDataUrl?: string;
	startTime: string;
	status?: BatteryResultStatus;
};
export type Report = {
	batteryResultById?: BatteryResultData;
};

interface AssessmentDataProps {
	batteryResult: FormattedBatteryResult;
	segmentResult?: FormattedSegmentResults;
}

export const getLifeAndHealthAssessmentData = ({
	batteryResult,
	segmentResult,
}: AssessmentDataProps): FormattedAssessmentResults | undefined => {
	const assessmentResult = batteryResult.assessmentResults?.find((ar) => {
		return ar.segmentResults.some(
			(sr) => sr.segment.segmentType === SegmentType.CustomQuestionnaire
		);
	});

	let assessmentResults;

	if (assessmentResult) {
		assessmentResults = formatAssessmentResults(assessmentResult);
	}
	if (!assessmentResult && segmentResult) {
		assessmentResults = formatSegmentResults(segmentResult);
	}
	if (assessmentResults) return assessmentResults;
};

function formatSegmentResults(
	segmentResult: FormattedSegmentResults
): FormattedAssessmentResults | undefined {
	const updatedSegmentResults = [
		{
			id: segmentResult.id,
			rawDataUrl: segmentResult.rawDataUrl,
			name: segmentResult.name,
			segmentType: segmentResult.segmentType,
			metricItems: segmentResult.metricItems,
		},
	];

	return {
		segmentResults: updatedSegmentResults,
		metricItems: segmentResult.metricItems,
	};
}

export const formatAssessmentResults = (
	assessmentResult: FormattedAssessmentResults
): FormattedAssessmentResults | undefined => {
	const assessmentMetrics: MetricHash = assessmentResult?.metricItems;

	const updatedSegmentResults: FormattedSegmentResults[] =
		assessmentResult?.segmentResults
			? assessmentResult.segmentResults.map((segmentResult) => {
					/* eslint-disable no-mixed-spaces-and-tabs */
					return {
						id: segmentResult.id,
						rawDataUrl: segmentResult.rawDataUrl,
						name: segmentResult.name,
						segmentType: segmentResult.segmentType,
						metricItems: segmentResult.metricItems,
					};
			  })
			: [];

	return {
		assessment: assessmentResult?.assessment,
		metricItems: assessmentMetrics,
		segmentResults: updatedSegmentResults,
	};
};
