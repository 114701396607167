import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { CreateAssignmentInput, useCreateAssignment } from 'api/assignment';
import { useGetParticipant, useUpdateParticipant } from 'api/participant';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { icons } from '../../../enums/icons';
import { messageEnum, MessageEnumItem } from '../../../enums/messageEnum';
import { AnalyticsAction } from 'enums/analyticsAction';
import { Battery } from '@lh/eng-platform-battery-service-rest-client';
import { DEBUG } from '../../../logging/linusLogger';
import {
	AddBatteryModel,
	AddBatteryProps,
	addBatterySchema,
	getModel,
} from '../../../schemas/forms/addBatterySchema';
import { DropdownOption } from '../../../types';
import { ButtonSm } from '../../shared/designSystem';
import { messages } from '../../shared/errorMessages';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { InfoMessage } from '../../shared/InfoMessage';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import {
	BATTERY_TYPE,
	CONTACT_METHODS,
	ContactPreference,
	formatContactFields,
} from '../participantHelpers';
import { participantStrings } from '../participantStrings';
import { BatteryConfiguration } from './BatteryConfiguration';
import { batteryTypeOptions } from './consts';
import { VisitConfiguration } from './VisitConfiguration';
import { useGetOrgEntitlements } from '../../../hooks/useGetOrgEntitlements';
import {
	WarningLabel,
	WarningLabelContent,
	WarningLabelProps,
} from './WarningLabel';
import { FeatureType } from 'api/organization';
import { LinusModal } from 'components/shared/LinusModal';
import { RemoteLinkViewer } from '../RemoteLinkViewer/RemoteLinkViewer';
import { useSendLink } from 'api/remote-assessments/useSendLink';

export type AddBatteryForParticipantProps = {
	onCancel: () => void;
	renderProps?: () => JSX.Element;
	participant: AddBatteryProps;
	batteries: BatteryMakeup;
};

type BatteryForAssignment = Pick<Battery, 'displayKey' | 'id' | 'name'>;
export type BatteryMakeup = {
	clinic: BatteryForAssignment[];
	remote: BatteryForAssignment[];
};
const AddBatteryForParticipant = ({
	onCancel,
	renderProps,
	participant,
	batteries,
}: AddBatteryForParticipantProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
	const [remoteLinkData, setRemoteLinkData] = useState<{
		assignmentId: string;
		batteryName: string;
		contactEmail?: string;
	} | null>(null);
	const [warningContent, setWarningContent] = useState<
		WarningLabelProps['flavor'] | null
	>(null);
	const [
		batteryAssignmentSuccessMessage,
		setBatteryAssignmentModalSuccessMessage,
	] = useState('');
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const [[batteryType, batterySelection], setBatteryConf] = useState<
		[string | null, string | null]
	>([null, null]);
	const clinicBatteriesOptions = useRef<DropdownOption[] | undefined>([]);

	const {
		mutateAsync: createAssignment,
		error: createAssignmentError,
		isPending: createAssignmentLoading,
	} = useCreateAssignment();
	const {
		mutateAsync: updateParticipant,
		error: updateParticipantError,
		isPending: updateParticipantLoading,
	} = useUpdateParticipant();
	const { mutateAsync: sendLink } = useSendLink();
	const { data: participantDetails } = useGetParticipant({
		participantId: participant.id,
		organizationId: currentUser.organizationId,
	});

	const { hasFeatureEntitlement } = useGetOrgEntitlements();
	const hasRemoteAssessmentsFlag = useMemo(
		() => hasFeatureEntitlement(FeatureType.RemoteAssessment),
		[hasFeatureEntitlement]
	);
	const hasVisitTypesFlag = useMemo(
		() => hasFeatureEntitlement(FeatureType.VisitTypes),
		[hasFeatureEntitlement]
	);

	useEffect(() => {
		if (batteries.clinic) {
			clinicBatteriesOptions.current = batteries.clinic.map(
				(battery): DropdownOption => {
					return {
						display: t(`${battery?.displayKey}`),
						value: `${battery?.id}`,
					};
				}
			);
		}
	}, [batteries, batteries.clinic, t]);
	const errors = useMemo(() => {
		if (!warningContent) return {};
		const text = t(WarningLabelContent[warningContent].errorDisplay);
		return {
			battery: text,
		};
	}, [warningContent, t]);
	const loading = createAssignmentLoading && updateParticipantLoading;

	const canBeReinitialized =
		!isSuccessModalVisible &&
		!updateParticipantLoading &&
		!createAssignmentLoading;

	const validationSchema = useMemo(
		() => addBatterySchema({ hasRemoteAssessmentsFlag, hasVisitTypesFlag }),
		[hasRemoteAssessmentsFlag, hasVisitTypesFlag]
	);

	const formik = useFormik({
		enableReinitialize: canBeReinitialized,
		initialValues: {
			...getInitialValues(),
		},
		validationSchema,
		onSubmit: async (
			values: AddBatteryModel,
			{ setSubmitting }: FormikHelpers<AddBatteryModel>
		) => {
			if (!values.battery) return;
			if (values.batteryType)
				setBatteryConf([values.batteryType, values.battery]);
			if (
				hasRemoteAssessmentsFlag &&
				values.batteryType === BATTERY_TYPE.REMOTE
			) {
				//don't update if not sending immediately
				if (values.contactPreference !== CONTACT_METHODS.NO_SEND) {
					const contactFields = formatContactFields({
						contactEmail: values.contactEmail,
						contactPhone: values.contactPhone,
						contactPreference: values.contactPreference,
					});

					if (!participantDetails) {
						DEBUG(
							`Error fetching participant data for id ${participant.id}, skipping update step during assignment`
						);
					} else {
						const updateResult = await updateParticipant({
							participant: {
								...contactFields,
							},
							participantId: participantDetails.id,
							organizationId: currentUser.organizationId,
						});
						if (updateResult.status !== 200) {
							setServerSideMessage(
								messageEnum.error(
									t(messages.mutationPayloadError)
								)
							);
							setSubmitting(false);
							return;
						}
					}
				}
			}

			const input: CreateAssignmentInput = {
				participantId: participant.id,
				batteryId: values.battery,
				organizationId: currentUser.organizationId,
				visitType: hasVisitTypesFlag ? values?.visitType : undefined,
			};
			if (values.batteryType === BATTERY_TYPE.REMOTE) input.remote = true;
			let assignment;
			try {
				assignment = await createAssignment({
					assignment: input,
				});
			} catch (e) {
				setSubmitting(false);
			}

			if (assignment?.data) {
				sendEventData({
					eventType: AnalyticsAction.AssignedBattery,
					eventProperties: {
						batteryId: values.battery,
					},
				});
				if (values.contactPreference !== CONTACT_METHODS.NO_SEND) {
					const isRemote = values.batteryType === BATTERY_TYPE.REMOTE;
					if (isRemote)
						sendLink({
							organizationId: currentUser.organizationId,
							assignmentId: assignment.data.id,
						});
					showSuccessModal(isRemote);
				} else {
					const battery = batteries.remote.find(
						({ id }) => id === values.battery
					);
					setRemoteLinkData({
						assignmentId: assignment.data.id,
						batteryName: battery?.displayKey ?? '',
						contactEmail: formik.values.contactEmail,
					});
				}
			}
			setSubmitting(false);
		},
	});
	const isSubmitButtonDisabled = useMemo(
		() => !(formik.isValid && formik.dirty) || formik.isSubmitting,
		[formik.dirty, formik.isSubmitting, formik.isValid]
	);
	const isVisitConfigurationVisible = useMemo(() => {
		if (!hasVisitTypesFlag) return false;
		if (!hasRemoteAssessmentsFlag) return true;
		// if remote assessments flag is enabled, battery type must be chosen first, before showing visit configuration dropdown
		return !!formik?.values?.batteryType;
	}, [
		formik?.values?.batteryType,
		hasRemoteAssessmentsFlag,
		hasVisitTypesFlag,
	]);

	useEffect(() => {
		// reset battery & visit Type if battery type was updated
		if (formik.values.batteryType !== batteryType) {
			formik.setFieldValue('battery', undefined, true);
			formik.setFieldValue('visitType', undefined, true);
			setWarningContent(null);
			setBatteryConf((prev) => [
				formik.values?.batteryType ?? prev[0],
				null,
			]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.batteryType]);

	useEffect(() => {
		if (formik.values.battery) {
			setWarningContent(null);
		}
	}, [formik.values?.battery, setWarningContent]);

	useEffect(() => {
		if (createAssignmentError || updateParticipantError) {
			const baselineExistent =
				createAssignmentError?.response?.data?.errorCode ===
				'LONG_EPSOM_FOUND';

			const baselineNonexistent =
				createAssignmentError?.response?.data?.errorCode ===
				'BASELINE_EPSOM_REQUIRED';
			if (baselineExistent) {
				setWarningContent('baseline');
			} else if (baselineNonexistent) {
				setWarningContent('longitudinal');
			} else {
				setServerSideMessage(
					messageEnum.error(t(messages.mutationPayloadError))
				);
			}
		}
	}, [createAssignmentError, t, updateParticipantError]);

	function showSuccessModal(isRemoteBattery: boolean) {
		const successMessage = isRemoteBattery
			? participantStrings['participantBatteryAssignedRemote']
			: participantStrings['participantBatteryAssigned'];
		setBatteryAssignmentModalSuccessMessage(successMessage);
		setIsSuccessModalVisible(true);
	}

	function getInitialValues() {
		const values = getModel();
		if (participantDetails) {
			if (batteryType) values.batteryType = batteryType;
			if (batterySelection) values.battery = batterySelection;

			const { contactEmail, contactPhone, contactPreference } =
				participantDetails;

			if (contactEmail) values.contactEmail = contactEmail;
			if (contactPhone) values.contactPhone = contactPhone;
			if (contactPreference) {
				values.contactPreference =
					contactPreference as ContactPreference;
			}
		}
		return values;
	}
	return isSuccessModalVisible ? (
		<LinusModalDialog
			onClose={onCancel}
			title={t`web.patients.forms.batteryAssigned`}
			titleIcon={icons.CheckmarkSolid}
			titleIconColor={theme.color.alertSuccess}
			acceptButtonText={t('web.shared.close')}
			acceptButtonCallback={onCancel}>
			<StyledSuccessMessage>
				<Trans
					i18nKey={t(batteryAssignmentSuccessMessage)}
					values={{
						entity: `${participant?.externalId}`,
					}}
					components={[<strong />]}
				/>
			</StyledSuccessMessage>
		</LinusModalDialog>
	) : remoteLinkData ? (
		<LinusModal
			overflow='visible'
			onClose={onCancel}
			dataId='patient_added_modal_view_link'>
			<RemoteLinkViewer
				payload={{
					batteryName: remoteLinkData.batteryName,
					externalId: participant.externalId ?? '',
					assignmentId: remoteLinkData.assignmentId ?? '',
					contactEmail: remoteLinkData.contactEmail,
				}}
			/>
		</LinusModal>
	) : (
		<FormikProvider value={formik}>
			{renderProps && renderProps()}
			<StyledForm
				style={{
					width: !hasRemoteAssessmentsFlag ? '548px' : undefined,
					height: !hasRemoteAssessmentsFlag
						? 'fit-content'
						: undefined,
				}}>
				{warningContent && <WarningLabel flavor={warningContent} />}
				<StyledRow>
					{hasRemoteAssessmentsFlag ? (
						<LinusInput
							name='batteryType'
							type='select'
							label='Battery Type'
							dropdownOptions={batteryTypeOptions}
							data-testid='batteryType'
						/>
					) : (
						<LinusInput
							name='battery'
							type='select'
							label={t`web.shared.battery`}
							error={warningContent as string}
							dropdownOptions={clinicBatteriesOptions.current}
							width='100%'
							data-testid='battery-input'
						/>
					)}
				</StyledRow>
				{hasRemoteAssessmentsFlag && (
					<BatteryConfiguration
						participantData={participantDetails}
						errors={errors}
						values={formik?.values}
						batteries={batteries}
					/>
				)}
				{isVisitConfigurationVisible && <VisitConfiguration />}
				<InfoMessage
					messageEnum={serverSideMessage}
					showIf={!!serverSideMessage}
				/>
			</StyledForm>
			<StyledActionRow>
				<ButtonSm
					text={t`web.shared.close`}
					width='158px'
					onClick={onCancel}
					dataId='add-battery-cancel-button'
				/>
				<StyledSpacer />
				<ButtonSm
					disabled={isSubmitButtonDisabled}
					text={t`web.patients.forms.assignBattery`}
					loading={loading}
					type='submit'
					width='158px'
					primary
					dataId='add-battery-submit-button'
					onClick={formik.submitForm}
				/>
			</StyledActionRow>
		</FormikProvider>
	);
};

export { AddBatteryForParticipant };

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;
const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledActionRow = styled.div`
	display: flex;
	justify-content: center;
	padding: 16px 0;
	flex-grow: 1;
	align-items: flex-end;
`;
const StyledSpacer = styled.span(
	({ theme: { spacing } }) => `
	width: ${spacing.xl};
`
);

const StyledSuccessMessage = styled.div`
	width: 548px;
`;
