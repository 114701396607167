import { Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Typography, theme } from '@lh/eng-web-mosaic-common';
import styled from 'styled-components';

const PHQ8Header = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<CenteredRow>
				<Typography.H4 color={theme.color.bodyText} weight='500'>
					{t('web.report.phq8.header.heading')}
				</Typography.H4>
				<Space w={'xs'} />
				<Typography.C color={theme.color.bodyTextSecondary}>
					{t('web.report.phq8.header.headingExplanation', {
						unscoredQuestionNumber: 9,
					})}
				</Typography.C>
			</CenteredRow>
			<Space h={'sm'} />
			<CenteredRow>
				<Typography.P1 color={theme.color.bodyText}>
					{t('web.report.phq8.header.subText')}
				</Typography.P1>
				<Space w={'xs'} />
				<Typography.P1 color={theme.color.bodyText} weight='500'>
					{t('web.report.phq8.header.subTextTimePeriod')}
					{'.'}
				</Typography.P1>
			</CenteredRow>
		</Container>
	);
};

export { PHQ8Header };

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const CenteredRow = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
`;
