import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { H4, P1 } from '../../../../../../shared/designSystem';
import { Star } from '../../../../../../shared/designSystem/Icons';
import { theme } from '../../../../../../../providers/styleProvider';
import styled from 'styled-components';

const generateTestId = (elementName: string) =>
	`participant-responses-${elementName}`;

function ParticipantResponsesHeader() {
	const { t } = useTranslation();

	const titleText = t(`research.participantsWrittenResponse.titleText`);
	const titleSecondaryText = t(
		`research.participantsWrittenResponse.titleSecondaryText`
	);
	const subtitleText = t(`research.participantsWrittenResponse.subtitleText`);

	return (
		<div data-testid={generateTestId('title-subtitle-container')}>
			<Flex
				direction={'row'}
				align={'center'}
				data-testid={generateTestId('title-text-container')}>
				{/*  Primary Title Text (Heading) */}
				<H4
					data-testid={generateTestId('title-text')}
					style={{ fontWeight: theme.weight.medium }}>
					{titleText}
				</H4>
				<Flex
					direction={'row'}
					align={'center'}
					data-testid={generateTestId('title-description-container')}>
					{/* Title Description SVG */}
					<div
						data-testid={generateTestId(
							'title-description-svg-icon'
						)}>
						<Star color={theme.colors.blue} />
					</div>

					{/* Title Description text */}
					<StyledP1RegularText
						data-testid={generateTestId('title-description-text')}>
						{titleSecondaryText}
					</StyledP1RegularText>
				</Flex>
			</Flex>
			<br />
			{/* Subtitle Text */}
			<StyledP1SecondaryText
				data-testid={generateTestId('subtitle-text')}>
				{subtitleText}
			</StyledP1SecondaryText>
		</div>
	);
}

export { ParticipantResponsesHeader };

const StyledP1RegularText = styled(P1)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.bodyText};
`
);

const StyledP1SecondaryText = styled(P1)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.bodyTextSecondary};
`
);
