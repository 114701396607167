import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { UpdateUserEvents } from 'components/team/events';
import { DEBUG, ERROR } from 'logging/linusLogger';
import { OrganizationUser, UpdateOrgUserInput } from './types';

export async function updateOrgUser(
	input: UpdateOrgUserInput
): Promise<OrganizationUser> {
	if (!input?.userUpdate || !input?.userId || !input.organizationId) {
		throw new Error('updateOrgUser: invalid API request params');
	}
	const service = await getOrganizationService();
	const { data } = await service.updateUser(input);
	return data;
}

export function useUpdateOrgUser() {
	const queryClient = useQueryClient();
	return useMutation({
		meta: {
			errorMessage: 'Error updating org user',
		},
		mutationFn: updateOrgUser,
		onMutate: () => {
			DEBUG(UpdateUserEvents.INIT);
		},
		onSuccess: () => {
			DEBUG(UpdateUserEvents.SUCCESS);
			queryClient.invalidateQueries({ queryKey: [QueryKey.Users] });
		},
		onError: (error) => {
			ERROR(UpdateUserEvents.FAILURE, error);
		},
	});
}
