// GENERATED BY ./scripts/iconScript.ts

import { IconProps, flipFunction } from '../flipFunction';

export const Relevant = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
	horizontalFlip = false,
	verticalFlip = false,
}: IconProps): JSX.Element => {
	const { scaleX, scaleY, translateX, translateY } = flipFunction({
		horizontalFlip,
		verticalFlip,
	});
	return (
		<svg
			viewBox='0 0 24 22'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='Relevant-svg-component'
			transform={`rotate(${rotate}) translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`}
			fill={color}
			width={width}
			height={height}>
			<title>{title || 'Relevant'}</title>
			<path
				d='M11.2477 3.8905C11.2477 5.76296 9.72982 7.28081 7.85736 7.28081C5.98489 7.28081 4.46704 5.76296 4.46704 3.8905C4.46704 2.018 5.98492 0.5 7.85736 0.5C9.72979 0.5 11.2477 2.018 11.2477 3.8905Z'
				stroke='#087DAE'
			/>
			<path
				d='M13.0461 11.4884L13.0524 11.5059L13.06 11.5228C13.0801 11.5674 13.1032 11.6199 13.1291 11.6785C13.2409 11.9321 13.4036 12.3012 13.5867 12.6431C13.7002 12.855 13.8296 13.0724 13.9704 13.2578C14.1022 13.4314 14.2825 13.6294 14.5123 13.7389C15.0258 13.9874 15.7305 14.0777 16.34 14.1035C16.9595 14.1297 17.562 14.0918 17.9277 14.0365C18.4755 13.9703 18.9571 14.3454 19.005 14.8744L19.0064 14.8905L19.0089 14.9066C19.0907 15.4294 18.7144 15.9288 18.1861 15.9995C18.1664 16.0013 18.1407 16.0038 18.1097 16.0068C17.8506 16.0321 17.2134 16.0945 16.4296 16.0759C15.5231 16.0543 14.4804 15.9245 13.6511 15.5383L13.6495 15.5376C12.8143 15.1519 12.2226 14.361 11.7851 13.5345L10.8434 13.7835L11.0455 20.4528L11.0455 20.4528L11.0459 20.4615C11.0569 20.6929 10.87 20.8977 10.6107 20.8977H5.10346C4.84436 20.8977 4.65723 20.6929 4.66823 20.4614L4.66833 20.4614L4.66861 20.4513L4.84553 13.9841L3.89429 13.7555C3.6462 14.2765 3.37322 14.8717 3.25859 15.2733L3.25631 15.2812L3.2543 15.2893C3.13752 15.7565 2.93955 16.6961 2.77274 17.5113C2.68885 17.9212 2.61207 18.3035 2.55623 18.5851C2.52832 18.7259 2.50555 18.8419 2.48971 18.9238C2.48181 18.9646 2.47548 18.9978 2.47104 19.0216L2.46531 19.0531L2.46473 19.0566C2.35621 19.5373 1.94827 19.8619 1.49107 19.8619C1.45192 19.8619 1.42333 19.8617 1.39474 19.8598C1.36699 19.858 1.34756 19.8549 1.33424 19.8515L1.31987 19.848L1.30531 19.8452C0.771773 19.7452 0.417175 19.227 0.516825 18.6881L0.519759 18.6733C0.574284 18.3991 1.10197 15.7456 1.34343 14.8035C1.57769 13.9142 2.19944 12.7349 2.86537 11.5054L2.86927 11.4982L2.87294 11.4909L3.02088 11.195C3.62871 10.0736 4.45694 9.56951 5.50775 9.56951H5.50777L10.4592 9.56934C10.4592 9.56934 10.4592 9.56934 10.4592 9.56934C11.1734 9.56934 11.6732 9.75726 12.0295 10.0024C12.3847 10.2467 12.6241 10.5667 12.7862 10.8756C12.8115 10.9342 12.8364 10.9893 12.8613 11.0443C12.9212 11.1769 12.9809 11.3089 13.0461 11.4884Z'
				stroke='#087DAE'
			/>
			<path
				d='M21.6253 1.26318H16.6486C15.3349 1.26318 14.2739 2.3242 14.2739 3.63789V6.59359C14.2739 7.88193 15.3349 8.9683 16.6486 8.9683H17.2802C17.255 9.34716 17.2044 9.82717 17.0023 10.3578C16.9013 10.6609 16.9771 10.9641 17.2044 11.1914C17.356 11.3178 17.5075 11.3684 17.6845 11.3684C17.8108 11.3684 17.9371 11.3432 18.0381 11.2674C19.0739 10.6864 19.9328 9.72638 20.5138 8.96846H21.6254C22.9391 8.96846 24.0001 7.90744 24.0001 6.59375V3.6127C24.0001 2.32423 22.9391 1.26321 21.6254 1.26321L21.6253 1.26318ZM21.2717 4.52212L19.0991 6.69468C18.9981 6.79566 18.8464 6.87161 18.6949 6.87161C18.5433 6.87161 18.3918 6.79583 18.2906 6.69468L17.0023 5.40635C16.775 5.17903 16.775 4.82536 17.0023 4.59786C17.2296 4.37054 17.5833 4.37054 17.8108 4.59786L18.7202 5.50731L20.4885 3.71359C20.7159 3.48627 21.0695 3.48627 21.297 3.71359C21.499 3.96628 21.499 4.31993 21.2716 4.52207L21.2717 4.52212Z'
				fill='#087DAE'
			/>
		</svg>
	);
};
