import styled, { useTheme } from 'styled-components';
import i18n from '../../../i18n';
import { icons } from '../../../enums/icons';
import { Icon } from '../designSystem';
import { Notification } from './columnComponents/Notification';

export const ConcerningResult = ({ text }: { text: string }) => {
	const theme = useTheme();
	return (
		<StyledWrapper>
			<Notification text={i18n.t(text)}>
				<Icon
					icon={icons.AlertSolid}
					color={theme.color.alertError}
					title={i18n.t`web.shared.notification`}
					width={18}
					height={18}
				/>
			</Notification>
		</StyledWrapper>
	);
};

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
