import { Trans } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

import { BatteryResult, BatteryResultState, MetricItem } from 'api/battery';
import { useGetUser } from 'api/organization';
import { ConcerningResult } from '../../components/shared/DataTable/ConcerningResult';
import { TableColumn } from '../../components/shared/DataTable/DataTable';
import { Notification } from '../../components/shared/DataTable/columnComponents/Notification';
import { ViewResultButton } from '../../components/shared/DataTable/columnComponents/ViewResultButton';
import { Icon } from '../../components/shared/designSystem';
import { icons } from '../../enums/icons';
import i18n from '../../i18n';
import { dateFormatter } from '../../stringStrategy/dateFormatStringStrategy';

export type TableData = {
	batteryId: string;
	battery: string;
	date: string;
	administratorId: string;
	// administrator?: string;
	organizationId: string;
	id: string;
	notification: boolean;
	participantId: string;
	processing: boolean;
	delayed: boolean;
	status: BatteryResultState;
	visitType: string;
};

export const METRIC_ITEM_CONCERNS = 'hasConcerns';

export const showNotification = (batteryResult: BatteryResult): boolean => {
	if (!batteryResult) return false;
	return (
		(!isProcessing(batteryResult) &&
			// TODO: Remove once PLT puts metric items in the shallow battery result (WIP)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(batteryResult as any)?.metricItems?.some(
				(mi: MetricItem) =>
					mi.key === METRIC_ITEM_CONCERNS && mi.value === 'true'
			)) ||
		false
	);
};

export const isProcessing = (batteryResult: BatteryResult): boolean => {
	if (!batteryResult) return false;
	return (
		batteryResult.statusContext.state !==
		BatteryResultState.AnalysisComplete
	);
};

const columns = (
	dateFormat: string,
	theme: DefaultTheme,
	showVisitTypes = false
): TableColumn<TableData>[] => {
	const tableColumns: TableColumn<TableData>[] = [
		{
			propertyName: 'battery',
			headerDisplay: i18n.t`web.shared.battery`,
			minWidth: '199.5px',
		},
		{
			propertyName: 'date',
			headerDisplay: i18n.t`web.shared.dateTaken`,
			minWidth: '162px',
			formatProperty: ({ value }) =>
				dateFormatter(value as string, dateFormat),
		},
		{
			propertyName: 'administrator',
			headerDisplay: i18n.t`web.shared.administrator`,
			minWidth: '199.5px',
			rowColumnComponent: ({ row }) => {
				if (!row) return null;
				return (
					<AdministratorById
						id={row.administratorId}
						orgId={row.organizationId}
					/>
				);
			},
		},
		{
			propertyName: 'report',
			headerDisplay: i18n.t`web.shared.report`,
			minWidth: '155px',
			rowColumnComponent: ({ row }) => {
				return row?.delayed ? (
					<StyledResultsDelayed>
						<Notification
							text={i18n.t`web.shared.resultsTable.resultsDelayedTip`}>
							<Icon
								icon={icons.InfoOutlined}
								title={i18n.t`web.shared.notification`}
								color={theme.color.alertError}
							/>
						</Notification>
						<StyledDelayed>
							<Trans i18nKey='web.shared.resultsTable.resultsDelayed' />
						</StyledDelayed>
					</StyledResultsDelayed>
				) : (
					<ViewResultButton
						disabled={row?.processing}
						text={
							row?.processing
								? i18n.t`web.shared.resultsTable.processing`
								: i18n.t`web.shared.view`
						}
						batteryResultId={row?.id}
						participantId={row?.participantId}
					/>
				);
			},
		},
		{
			propertyName: 'notification',
			headerDisplay: ' ',
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ value }) => {
				return value ? (
					<ConcerningResult text='web.shared.resultsTable.concerns' />
				) : (
					<StyledBlank>{` `}</StyledBlank>
				);
			},
		},
	];
	if (showVisitTypes === true) {
		// Add Visit Types column right after the battery column
		const batteryColumnIndex = tableColumns.findIndex(
			(column) => column?.propertyName === 'battery'
		);
		const visitTypeColumn = {
			propertyName: 'visitType',
			headerDisplay: i18n.t`web.visitTypes.visit`,
			minWidth: '162px',
		};
		tableColumns.splice(batteryColumnIndex + 1, 0, visitTypeColumn);
	}
	return tableColumns;
};

export { columns };

const StyledBlank = styled.span`
	width: 24px;
`;

const StyledDelayed = styled.span(({ theme: { color, spacing } }) => {
	return `
    color: ${color.deactivatedText};
    padding-left: ${spacing.xs};
    `;
});

const StyledResultsDelayed = styled.div`
	display: flex;
	align-items: center;
`;

function AdministratorById({ id, orgId }: { id: string; orgId: string }) {
	const { data, isPending } = useGetUser({
		userId: id,
		organizationId: orgId,
	});
	if (isPending) return null;
	const display =
		data?.firstName && data.lastName
			? `${data.firstName} ${data.lastName}`
			: '';
	return <>{display}</>;
}
