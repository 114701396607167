import { SimpleGrid } from '@mantine/core';
import styled from 'styled-components';
import { P1 } from '../../../../../../shared/designSystem';
import {
	CategoryPattern,
	EpsomAnswer,
} from '../../../../../../../types/epsomTypes';
import {
	getCategoryNameTransKey,
	getCategoryTooltipTextTransKey,
	isOptionalCategory,
} from '../../../../common/helpers/epsomDataFormatters';
import { AnswerHeaderText } from './AnswerHeaderText';
import { CategoryNameWithTooltip } from './CategoryNameWithTooltip';
import { StarredAnswer } from './StarredAnswer';
import { useTranslation } from 'react-i18next';

type CategoryRowProps = {
	categoryPattern: CategoryPattern;
	listOfAnswers: EpsomAnswer[];
	isDividerVisible: boolean;
};

export default function CategoryRow(props: Readonly<CategoryRowProps>) {
	const { t } = useTranslation();

	const { categoryPattern, listOfAnswers, isDividerVisible } = props;

	const hasNoAnswers = listOfAnswers.length === 0;

	// Show nothing if a mandatory category is missing answers' data
	if (!isOptionalCategory(categoryPattern) && hasNoAnswers) return null;

	const isOptionalCategoryWithNoAnswers =
		isOptionalCategory(categoryPattern) && hasNoAnswers;

	return (
		<div key={categoryPattern}>
			{/* Category Name */}
			<CategoryNameWithTooltip
				category={{
					displayKey: t(getCategoryNameTransKey(categoryPattern)),
					tooltipText: t(
						getCategoryTooltipTextTransKey(categoryPattern)
					),
				}}
			/>
			<br />
			{/* Text for Optional Categories with no answers */}
			{isOptionalCategoryWithNoAnswers ? (
				<StyledP1SecondaryText>
					{'Participant did not add any answers'}
				</StyledP1SecondaryText>
			) : (
				<SimpleGrid cols={3} spacing='lg'>
					{listOfAnswers.map((answer, index) => {
						// Show nothing if there's no answer input
						if (!answer?.input) return null;
						return (
							<div key={`${answer?.input}-${index}`}>
								<AnswerHeaderText index={index} />
								<StarredAnswer answer={answer} />
							</div>
						);
					})}
				</SimpleGrid>
			)}

			{/* Horizontal Row Separator */}
			{isDividerVisible && (
				<StyledSeparator data-testid='category-row-separator' />
			)}
		</div>
	);
}

const StyledP1SecondaryText = styled(P1)(
	({ theme: { color, weight } }) => `
    margin: 0 0 0 0;
    font-weight: ${weight.regular};
    color: ${color.bodyTextSecondary};
`
);

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	position: relative;
	width: 100%;
	border-bottom: 1px solid ${color.infoSeparator};
	margin-top: 25px;
	margin-bottom: 25px;
`
);
