import { theme } from '../../../../providers/styleProvider';
import { AmyloidInterpretation, DualColor } from '../types';

export const getAmyloidInterpretationTransKeyByValue = (
	interpretation: AmyloidInterpretation | string
) => {
	const commonPrefix = 'web.report.amyloidLikelihood.interpretationRanges';
	switch (interpretation) {
		case AmyloidInterpretation.HIGH_LIKELIHOOD:
			return `${commonPrefix}.highLikelihood.rangeDescription`;

		case AmyloidInterpretation.MODERATE_LIKELIHOOD:
			return `${commonPrefix}.moderateLikelihood.rangeDescription`;

		case AmyloidInterpretation.LOW_LIKELIHOOD:
			return `${commonPrefix}.lowLikelihood.rangeDescription`;

		default:
			return AmyloidInterpretation.UNANALYZABLE;
	}
};

export function getAmyloidColorForInterpretation(
	interpretation: AmyloidInterpretation,
	isUnanalyzable = false
): DualColor {
	const { color, colors } = theme;
	const unanalyzableColors: DualColor = {
		background: colors.gray_80,
		text: colors.gray_40,
	};
	if (isUnanalyzable) {
		return unanalyzableColors;
	}
	switch (interpretation) {
		case AmyloidInterpretation.LOW_LIKELIHOOD:
			return {
				background: theme.colors.green_lightest,
				text: theme.colors.green_darkest,
			};
		case AmyloidInterpretation.MODERATE_LIKELIHOOD:
			return {
				background: colors.yellow_lightest,
				text: colors.yellow_darkest,
			};
		case AmyloidInterpretation.HIGH_LIKELIHOOD:
			return {
				background: colors.orange_lightest,
				text: color.textAlert,
			};
		case AmyloidInterpretation.UNANALYZABLE:
		default:
			return unanalyzableColors;
	}
}
