/* eslint-disable react-hooks/exhaustive-deps */
import { useDebouncedValue } from '@mantine/hooks';
import React, {
	RefObject,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import styled, { css } from 'styled-components';
import { EnumItem } from '../../../../enums/enumeration';
import { SortDir } from '../../../../generated/graphql';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import { StyledCard } from '../../StyledCard';
import { TableColumn } from '../DataTable';
import { DataTableHeader } from '../DataTableHeader';
import { PaginatedDataTableSearchBar } from '../DataTableSearchBar';
import { PaginatedDataTable } from './PaginatedDataTable';
import { PaginatedDataTableFooter } from './PaginatedDataTableFooter';
import { PaginatedPagerInfo } from '../../../../types';

const SEARCH_INPUT_DEBOUNCE_TIME = 500;

export type LinusPaginatedDataTableProps<T> = {
	/**
	 * Used to expose internal methods through ref
	 */
	_ref?: RefObject<{ clearSearchInput(): void }>;
	title: string;
	titleIcon?: EnumItem;
	columns: TableColumn<T>[];
	tableData: T[];
	buttonText?: string;
	dataId?: string;
	onHeaderButtonClick?: (event: React.MouseEvent) => void;
	operations?: OperationToken[];
	buttonIcon?: EnumItem;
	rowsPerPage?: number;
	maxPage?: number;
	hideFooter?: boolean;
	deviantWidth?: string;
	longText?: string;
	noDataIcon?: EnumItem;
	emptyTableComponent?: () => React.ReactNode;
	searchBarPlaceholder?: string;
	notFoundTitle?: string;
	notFoundSubtitle?: string;
	count: number;
	total: number;
	currentPage: number;
	setCurrentPage: (pageNumber: number) => void;
	onSort?: (dir: SortDir | undefined, prop: string) => void;
	onFilter?: (predicate: string) => void;
	loading: boolean;
	hasInitialData: boolean;
	activeHeader?: string;
};

export const LinusPaginatedDataTable = <T,>({
	_ref,
	title,
	titleIcon,
	columns,
	tableData = [],
	buttonText,
	dataId,
	onHeaderButtonClick,
	operations,
	buttonIcon,
	rowsPerPage = 5,
	maxPage = 5,
	hideFooter,
	deviantWidth,
	longText,
	noDataIcon,
	searchBarPlaceholder,
	notFoundTitle,
	notFoundSubtitle,
	// TODO: not being used, consider removal of it
	// count,
	total,
	currentPage,
	setCurrentPage,
	onSort,
	onFilter,
	loading,
	hasInitialData = false,
	activeHeader,
}: LinusPaginatedDataTableProps<T>): JSX.Element => {
	const [searchInput, setSearchInput] = useState('');
	const [_searchInput] = useDebouncedValue(
		searchInput,
		SEARCH_INPUT_DEBOUNCE_TIME
	);

	const tableHasData = tableData.length > 0;
	const isFilteringData = searchInput.length > 0;

	const pagerInfo = useMemo<PaginatedPagerInfo>(
		() => ({
			totalRows: total,
			currentPage,
			setCurrentPage,
			rowsPerPage,
			maxPage,
		}),
		[total, currentPage, rowsPerPage, maxPage]
	);

	/**
	 * Debounce `searchInput` value to
	 * - optimise UX
	 * - avoid multiple API calls during user interaction with the search input
	 */
	useEffect(() => handleChangeSearch(_searchInput), [_searchInput]);

	/**
	 * Expose internal methods through ref
	 */
	useImperativeHandle(
		_ref,
		() => ({
			clearSearchInput: handleClearSearch,
		}),
		[]
	);

	const handleChangeSearch = (value: string) => {
		onFilter && onFilter(value);

		if (value) {
			setCurrentPage(1);
			setSearchInput(value);
		}
	};

	const handleClearSearch = () => {
		setSearchInput('');
		handleChangeSearch('');
	};

	return (
		<StyledContainer data-id={title + ' Data Table'}>
			<DataTableHeader
				title={title}
				titleIcon={titleIcon}
				buttonText={buttonText}
				dataId={dataId}
				onHeaderButtonClick={onHeaderButtonClick}
				operations={operations}
				buttonIcon={buttonIcon}
			/>
			{onFilter && (
				<PaginatedDataTableSearchBar
					value={searchInput}
					placeholder={searchBarPlaceholder}
					onChange={setSearchInput}
					onClear={handleClearSearch}
				/>
			)}
			<PaginatedDataTable
				columns={columns}
				tableData={tableData}
				paginatedSort={onSort}
				fallbackText={title}
				longText={longText}
				deviantWidth={deviantWidth}
				noDataIcon={noDataIcon}
				isFilteringData={isFilteringData}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				loading={loading}
				hasInitialData={hasInitialData}
				activeHeader={activeHeader}
			/>
			{tableHasData && !hideFooter && (
				<PaginatedDataTableFooter pagerInfo={pagerInfo} />
			)}
		</StyledContainer>
	);
};

const StyledContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.md};
		box-sizing: border-box;
	`
);
