import { linusStringStrategy } from './linusStringStrategy';
import { CurrentUser } from '../types';
import { organizationType } from '../enums/organizationEnums/organizationType';
import { researchStringStrategy } from './researchStringStrategy';

const orgTypes = {
	[organizationType.Linus.value]: linusStringStrategy,
	[organizationType.Research.value]: researchStringStrategy,
	[organizationType.Clinical.value]: researchStringStrategy,
};
export const orgSpecificStringStrategy = (
	key: string,
	currentUser?: CurrentUser,
	orgType?: string
): string => {
	if (currentUser && !currentUser.organizationType) {
		return key;
	}
	const index = currentUser
		? currentUser.organizationType.valueOf()
		: orgType
		? organizationType.fromValue(orgType)?.value
		: undefined;
	if (!index) return key;

	const strategy = orgTypes[index];
	return strategy[key] || key;
};
