import {
	Battery,
	DeepBattery,
} from '@lh/eng-platform-battery-service-rest-client';
import { isEmpty } from 'lodash';

export type BatteryRefData = Pick<
	Battery,
	| 'id'
	| 'name'
	| 'displayKey'
	| 'batteryType'
	| 'mobileEnabled'
	| 'webEnabled'
>;

// Takes incoming IDs and returns the corresponding Battery ref data
const getBatteryDataTransformer = <T extends Battery, R extends BatteryRefData>(
	transformer: (battery: T) => R
) => {
	return (ids: string[], batteries: T[]): R[] => {
		if (isEmpty(ids) || isEmpty(batteries)) return [];

		return batteries
			.filter((battery) => ids.some((id) => id === battery.id))
			.map(transformer);
	};
};

export const batteryDataTransformer = getBatteryDataTransformer((battery) => ({
	id: battery.id,
	name: battery.name,
	displayKey: battery.displayKey,
	batteryType: battery.batteryType,
	webEnabled: battery.webEnabled,
	mobileEnabled: battery.mobileEnabled,
}));
export const deepBatteryDataTransformer = getBatteryDataTransformer(
	(battery: DeepBattery) => battery
);
